import { PlatformLocation } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ActionsHttpService, DocumentDetailsService, DocumentsService2 } from '@ctel/gaw30-commons';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { EditKeysRequest } from 'app/entities/ui-config/action/edit-keys';
import 'moment/locale/it';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMPTY, Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

/**
 * Componente modal per la visualizzazione del tasto conferma quando il totale degli elementi supera il massimo supportato
 */
@Component({
	templateUrl: './edit-keys-modal.component.html',
	styleUrls: ['./edit-keys-modal.component.scss']
})
export class EditKeysModalComponent implements AfterViewInit {

	@Input() title: string;
	@Input() modal: BsModalRef;
	@Input() data: {
		action: ActionObject,
		keys: any;
		refreshDocuments: boolean,
		graphic$: Observable<string>,
		documentDetails$: Observable<any>;
	};

	documentDetails$: Observable<any>;
	graphic: string;
	formGroup = new UntypedFormGroup({});
	metadata: { label: string, keyCode: string, value: string }[] = [];

	constructor(
		private location: PlatformLocation,
		private actionsHttpService: ActionsHttpService,
		private documentsService: DocumentsService2,
		private documentDetailsService: DocumentDetailsService,
		private companiesService: CompaniesService,
		private notificationService: NotificationService,
		private router: Router
	) {
		location.onPopState(() => this.modal.hide());
	}

	ngAfterViewInit(): void {
		this.data.graphic$
			.pipe(take(1))
			.subscribe(
				graphic => this.graphic = graphic
			);
		this.documentDetails$ = this.data.documentDetails$;

		this.documentDetails$
			.pipe(take(1))
			.subscribe(
				metadata => {
					this.metadata = this.fixMetadata(metadata);

					for (const item of this.metadata)
						this.formGroup.addControl(item.keyCode, new UntypedFormControl(item.value));

				}
			);
	}

	onSubmit() {
		this.actionsHttpService.whenEditKeys(this.data.action.url, this.buildPayload())
			.pipe(
				catchError((err: unknown) => {
					if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
						//this.router.navigate(['/unauthorized']).then();
					} else
						this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore imprevisto', 'Metadati non aggiornati');

					return EMPTY;
				}),
				take(1)
			)
			.subscribe(
				res => {
					if (this.data.refreshDocuments)
						this.documentsService.refreshDocuments();
					else
						this.documentDetailsService.sendRefreshDocumentDetails();

					this.modal.hide();
					this.notificationService.showSweetAlert(NotificationType.SUCCESS, res['message'], '');
				}
			);
	}

	buildPayload(): EditKeysRequest {
		return {
			progSpool: this.data.keys.progSpool,
			progBusta: this.data.keys.progBusta,
			hashDocKey: this.data.keys.hashDocKey,
			docSeriesId: this.data.keys.ctelDocSeriesId,
			metadata: this.formGroup.value,
			id: this.companiesService.getCurrentCompanyValue().licenseId,
			sia: this.companiesService.getCurrentCompanyValue().siaCode,
			grafica: this.graphic
		};
	}

	private fixMetadata(metadata): { label: string, keyCode: string, value: string }[] {
		const fixedMetadata = [];
		for (const item of metadata)
			fixedMetadata.push(
				{
					keyCode: item.keyCode,
					label: item.description,
					value: item.valueAsString
				}
			);

		return fixedMetadata;
	}

}
