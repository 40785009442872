export interface FiltersUrl {
	metadata?: string;
	filterType?: string;
	type?: string;
	checkboxName?: string;
}

export interface DateFiltersHomepageUrl {
	metadata?: string;
	filterType?: string;
	dateRange?: DateRangeFilters;
}

export interface DateRangeFilters {
	invoiceFrom?: Date;
	invoiceTo?: Date;
	insertionFrom?: Date;
	insertionTo?: Date;
}

