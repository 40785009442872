import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { Company } from 'app/entities/companies/company';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable } from 'rxjs';

/**
 * Servizio per la gestione degli endpoint e delle chiamate http
 */
@Injectable({
	providedIn: 'root',
})
export class CompaniesHttpService {

	private jsonContentType = 'application/json';

	private accountHost;
	private companies;

	constructor(
		private http: HttpClient,
		public configService: ConfigService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	// inizializzazione endpoint string
	httpHostInit(appConfig: AppConfig) {
		/* ENDPOINTS AUTH --------------------------------------------------------------------------------------------------------------- */
		this.accountHost = appConfig.account.http.host;
		this.companies = `${this.accountHost}/v3/users/me/companies`;
	}

	whenCompanies(): Observable<Company[]> {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		const url = this.companies;
		return this.http.get<Company[]>(url, options);
	}

	private getHttpOptions(contentType: string, responseType: string): object {
		const headers = new HttpHeaders().set('Content-Type', contentType);
		let options;
		switch (responseType) {
			case 'blob':
				options = {
					headers,
					responseType: 'blob' as const
				};
				break;
			case 'json':
			default:
				options = {
					headers,
					responseType: 'json' as const
				};
				break;
		}
		return options;
	}
}
