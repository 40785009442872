export class Row<T = unknown> {
	readonly data: T;
	private _isSelected = false;
	private _isHovered = false;

	constructor(private _data: T, init?: {
		selection: boolean
	}) {
		this.data = _data;

		// Parametri iniziali della Row
		if (init)
			this.setSelected(init.selection ? init.selection : this.isSelected);

	}

	get isSelected() {
		return this._isSelected;
	}

	/**
	 * Permette di impostare lo stato di selezione di una Row
	 * @param value
	 */
	setSelected = (value: boolean) => this._isSelected = value;
}
