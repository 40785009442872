export class ElementAttributes {
	constructor(
		public x: number,
		public y: number,
		public width: number,
		public height: number,
		public marginBottom: number,
		public marginLeft: number,
	) {}
}
