import { Injectable, OnDestroy } from '@angular/core';
import {
	FilterActions,
	FilterState,
	getFullTextSearchValueWithUserValuesForSearch,
	getFullTextSearchWithUserValuesForSearch
} from '@ctel/search-filter-store';
import { Store, select } from '@ngrx/store';
import { FullTextSearch } from '@ctel/gaw-commons';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { FilterService } from '../filters/filter.service';

/**
 * Servizio che mantiene lo stato del valore cercato nella searchbarchange
 **/
@Injectable({
	providedIn: 'root'
})
export class FullTextSearchService implements OnDestroy {

	private destroy$ = new Subject<void>();
	private readonly fullTextSearch$: Observable<FullTextSearch>;
	private readonly fullTextSearchValue$: Observable<string>;
	private fullTextSearch: FullTextSearch;

	constructor(protected filterService: FilterService, private store: Store<FilterState>) {
		this.fullTextSearch$ = this.store.pipe(select(getFullTextSearchWithUserValuesForSearch));
		this.fullTextSearchValue$ = this.store.pipe(select(getFullTextSearchValueWithUserValuesForSearch));
		this.fullTextSearch$.pipe(
			tap(value => {
				this.fullTextSearch = value;
			}),
			takeUntil(this.destroy$)
		).subscribe();
	}

	private static isCurrency(value: string): boolean {
		const reg = new RegExp('^(((\\d{1,3})((\\.\\d{3})*)|(\\d*))(,\\d{1,2})?)$');
		return value ? reg.test(value.trim()) : false;
	}

	private static sanitizeImport(value: string) {
		return value.trim().split('.').join('').replace(',', '.');
	}

	getFullTextSearchValue(): string {
		return this.fullTextSearch.value;
	}

	whenFullTextSearchValue(): Observable<string> {
		return this.fullTextSearchValue$;
	}

	sendFullTextSearchValue(value: string, urlCommands: string[] = null) {
		if (value && FullTextSearchService.isCurrency(value))
			value = FullTextSearchService.sanitizeImport(value);

		this.store.dispatch(FilterActions.changeFilterRequested({
			kind: FilterActions.ChangeFilterRequestedKind.FullTextSearchChanged,
			metadata: '',
			fullTextSearchValue: value ? value : '',
			urlCommands
		}));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	isEqual(value: string) {
		// Valore attualmente presente nel subject
		const val = this.getFullTextSearchValue();

		// Se in ingresso ho una currency prendo il valore sanitize
		if (FullTextSearchService.isCurrency(value))
			value = FullTextSearchService.sanitizeImport(value);

		return value.trim() === val;
	}
}
