import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationComponentInput } from 'app/shared/components/pagination/pagination-component-input';
import { Pagination } from './pagination';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements PaginationComponentInput {

	// Totale elementi
	@Input() totalItems: number;

	// Elementi per pagina, default 10
	@Input() itemsPerPage = 10;

	@Output() pageChanged: EventEmitter<Pagination> = new EventEmitter<Pagination>();
	@Input() actualPage = 1;

	constructor(
		private changeDetectorRef: ChangeDetectorRef
	) { }

	changePage($event) {
		this.actualPage = $event.page;
		this.pageChanged.emit(<Pagination>{
			itemsPerPage: this.itemsPerPage,
			totalItems: this.totalItems,
			actualPage: this.actualPage
		});
		this.changeDetectorRef.detectChanges();
	}
}
