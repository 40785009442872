import { PageContent } from 'app/core/common/utilities/contents/page-content';

export class ErrorPages {

	public static notFound = new PageContent(
		'404-not-found',
		'404',
		'Spiacenti, ma la pagina a cui si vuole accedere non è stata trovata. Controllare l\'URL ed eseguire il refresh dal browser.',
		'Not Found'
	);

	public static unauthorized = new PageContent(
		'401-unauthorized',
		'401',
		'Spiacenti, ma la pagina a cui si vuole accedere è protetta.',
		'Unauthorized'
	);

	public static forbidden = new PageContent(
		'403-forbidden',
		'403',
		'Spiacenti, ma la pagina a cui si vuole accedere è protetta.',
		'Forbidden'
	);

	public static pages: Map<string, PageContent> = new Map([
		[ErrorPages.notFound.id, ErrorPages.notFound],
		[ErrorPages.unauthorized.id, ErrorPages.unauthorized],
		[ErrorPages.forbidden.id, ErrorPages.forbidden]
	]);

}
