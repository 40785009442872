import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { DocSeriesMetadataDesc, DocSeriesMetadataDescSearch, DocumentDetails, DocumentsResponse, FiltersResponse, GraphicInfoResponse, InfoByDocSeries } from '@ctel/gaw-commons';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { SearchBarTypeahead } from 'app/entities/search-bar/search-bar-typeahead';
import { RelatedDocSeries } from 'app/entities/sections/related-doc-series';
import { Sections } from 'app/entities/sections/sections';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { DocSeries } from 'app/entities/settings/doc-series';


/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative ai workflow
 */
@Injectable({
	providedIn: 'root'
})
export class DocumentsHttpService implements OnDestroy {

	private jsonContentType = 'application/json';
	// host
	private searchHost: string;
	private configHost: string;
	private docSeriesHost: string;
	private updateHost: string;
	private docDetails: string;
	private uiConfigHost: string;
	private gawTreConfigurationsHost: string;
	// endpoints
	private search: string;
	private favoriteFilters: string;
	private sections: string;
	private docSeriesInfo: string;
	private graphic: string;
	private docDetail: string;
	private updateTags: string;
	private typeAhead: string;
	private favoritesSections: string;
	private sectionRelatedDocSeries: string;
	private metadataDesc: string;
	private docSeriesCodeInfo: string;
	private docListDetails: string;
	private getDocListWithSections: string;

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
		public spinnerService: FullScreenSpinnerService
	) {
		this.configService.whenAppConfig()
			.pipe(takeUntil(this.destroy$))
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	httpHostInit(appConfig: AppConfig) {
		/* ENDPOINTS MAGELLANO ---------------------------------------------------------------------------------------------------------- */
		this.searchHost = appConfig.search.http.host;

		this.search = `${this.searchHost}/v3/search?clientid=GAW30`;
		this.docDetail = `${this.searchHost}/v3/documents/{licenseId}/{siaCode}/{docSeriesId}/{elasticDocumentId}/details?clientid=GAW30`;
		this.typeAhead = `${this.searchHost}/v3/search/typeahead?clientid=GAW30`;
		this.docListDetails = `${this.searchHost}/v3/documents/details?clientid=GAW30`;
		this.docDetails = appConfig.docDetails.http.host;

		this.updateHost = appConfig.update.http.host;
		this.updateTags = `${this.updateHost}/v1/document/update`;

		/* ENDPOINTS DOCSERIES API ------------------------------------------------------------------------------------------------------ */
		this.docSeriesHost = appConfig.docSeries.http.host;
		this.docSeriesInfo = `${this.docSeriesHost}/v1/docseries/{licenseId}/{siaCode}/public`;
		this.graphic = `${this.docSeriesHost}/v1/docseries/{docSeriesId}/public`;
		this.metadataDesc = `${this.docSeriesHost}/v3/docseries/metadata/descriptions`;
		this.docSeriesCodeInfo = `${this.docSeriesHost}/v4/docseries/{docSeriesId}`;

		/* ENDPOINTS UI CONFIGURATION --------------------------------------------------------------------------------------------------- */
		this.configHost = appConfig.uiConfiguration.http.host;
		this.sections = `${this.configHost}/v1/favorites/ui/GAW30/sections?licenseId={licenseId}&siaCode={siaCode}`;
		this.uiConfigHost = appConfig.uiConfiguration.http.host;
		this.favoriteFilters = `${this.uiConfigHost}/v1/favorites/ui/GAW30/filters?sectionCode={sectionCode}&licenseId={licenseId}&siaCode={siaCode}`;
		this.favoritesSections = `${this.uiConfigHost}/v1/favorites/ui/GAW30/sections?licenseId={licenseId}&siaCode={siaCode}`;
		this.sectionRelatedDocSeries = `${this.uiConfigHost}/v1/sections/{uiCode}/{sectionCode}/relatedDocSeries?licenseId={licenseId}&siaCode={siaCode}`;

		/* ENDPOINTS GAWTRECONFIGURATIONS------------------------------------------------------------------------------------------------ */
		this.gawTreConfigurationsHost = appConfig.gawTreConfigurations.http.host;
		this.getDocListWithSections = `${this.gawTreConfigurationsHost}/v1/docseries/{licenseId}/{siaCode}?sections=true`;
	}

	/* HTTP REQUESTS A DICKENS---------------------------------------------------------------------------------------------- */
	whenTagsUpdate(body: string): Observable<unknown> {
		const url = this.updateTags;
		const options = this.getHttpOptionsForLog(this.jsonContentType);
		return this.http.post<unknown>(url, body, options);
	}

	/* HTTP REQUESTS A DOC SERIES API ------------------------------------------------------------------------------------------ */
	// ottengo la grafica data una serie doc
	whenGraphic(docSeriesId: string): Observable<GraphicInfoResponse> {
		const url = this.graphic
			.replace('{docSeriesId}', docSeriesId);
		const options = this.getHttpOptions(this.jsonContentType);
		return this.http.get<GraphicInfoResponse>(url, options);
	}

	/* HTTP REQUESTS A MAGELLANO ---------------------------------------------------------------------------------------------- */

	// FAVORITES FILTERS
	whenFavoriteFilters(sectionCode: string, licenseId: string, siaCode: string): Observable<FiltersResponse> {
		return this.configService.whenAppConfig().pipe(
			filter(value => value !== null),
			switchMap(() => {
				const options = this.getHttpOptions(this.jsonContentType);
				const url = this.favoriteFilters
					.replace('{sectionCode}', sectionCode)
					.replace('{licenseId}', licenseId)
					.replace('{siaCode}', siaCode);
				return this.http.get<FiltersResponse>(url, options);
			})
		);
	}

	// GET tutti i tipi documento per quel cliente

	// GET tutte le szioni per quel cliente
	whenSections(licenseId: string, siaCode: string): Observable<Sections> {
		const options = this.getHttpOptions(this.jsonContentType);
		const url = this.favoritesSections
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<Sections>(url, options);
	}

	// Ritorna la lista delle serie documentali associate alla sezione
	getRelatedDocSeriesId(sectionCode: string, uiCode: string, licenseId: string, siaCode: string): Observable<RelatedDocSeries[]> {
		const options = this.getHttpOptions(this.jsonContentType);
		const url = this.sectionRelatedDocSeries
			.replace('{uiCode}', uiCode)
			.replace('{sectionCode}', sectionCode)
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<RelatedDocSeries[]>(url, options);
	}

	// RITORNA LISTA DOCUMENTI
	whenAllDocuments(body: string): Observable<DocumentsResponse> {
		const options = this.getHttpOptions(this.jsonContentType);
		const url = this.search;
		return this.http.post<DocumentsResponse>(url, body, options);
	}

	// DETTAGLIO METADATI DEL DOCUMENTO
	whenDocumentDetails(
		licenseId: string,
		siaCode: string,
		docSeriesId: string,
		elasticDocumentId: string,
		includeTechnical: boolean
	): Observable<DocumentDetails> {
		const options = this.getHttpOptionsForLog(this.jsonContentType);
		let url = this.docDetail
			.replace('{docSeriesId}', docSeriesId)
			.replace('{elasticDocumentId}', elasticDocumentId)
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		if (includeTechnical)
			url = url + '&includeTechnicalMetadata=true';

		return this.http.get<DocumentDetails>(url, options);
	}

	// Typeahead su ragione sociale nel bandone di ricerca
	whenSearchTypeahead(body: unknown): Observable<SearchBarTypeahead> {
		const url = this.typeAhead;
		return this.spinnerService.inhibitSpinnerHeaders().pipe(
			switchMap(headers => this.http.post<SearchBarTypeahead>(url, body, {
				headers
			}))
		);
	}

	// -----------------------------------------------------------------------------------------------------------------------

	whenDocSeries(licenseId: string, siaCode: string): Observable<InfoByDocSeries[]> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.docSeriesInfo
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<InfoByDocSeries[]>(url, options);
	}

	whenMetadataDescriptions(body: DocSeriesMetadataDescSearch): Observable<DocSeriesMetadataDesc[]> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.metadataDesc;
		return this.http.post<DocSeriesMetadataDesc[]>(url, body, options);
	}

	whenDocSeriesCodeInfo(docseriesId: string): Observable<InfoByDocSeries> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.docSeriesCodeInfo
			.replace('{docSeriesId}', docseriesId);
		return this.http.get<InfoByDocSeries>(url, options);
	}

	/**
	 * Ritorna il dettaglio dei documenti dato le chiavi
	 * @param documentsKeys
	 * @param includeTechnicalMetadata
	 * @param includeDocumentMetadata
	 */
	getDocumentsDetails(
		documentsKeys: { ctelElasticDocumentId: string; ctelDocSeriesId: string }[],
		includeTechnicalMetadata: boolean,
		includeDocumentMetadata: boolean
	): Observable<DocumentDetails[]> {
		const url = this.docListDetails;

		const body = { documentsKeys, includeTechnicalMetadata, includeDocumentMetadata };
		return this.http.post<DocumentDetails[]>(url, body);
	}

	private getHttpOptions(contentType: string): { headers: HttpHeaders, responseType: 'json' } {
		return {
			headers: new HttpHeaders().set('Content-Type', contentType),
			responseType: 'json' as const
		};
	}

	private getHttpOptionsForLog(contentType: string): { headers: HttpHeaders, responseType: 'json' } {
		return {
			headers: new HttpHeaders({ 'Content-Type': contentType, 'log': 'true' }),
			responseType: 'json' as const
		};
	}

	//Ritorna lista delle serie documentali con le loro sezioni
	public getDocSeriesWithSections(licenseId: string, siaCode: string): Observable<DocSeries[]> {
		const url = this.getDocListWithSections
				.replace('{licenseId}', licenseId)
				.replace('{siaCode}', siaCode);
		return this.http.get<any[]>(url).pipe(
				map(value => value.map(serie => {
								return {
										...serie,
										idSerieDoc: +serie.id
								};
						}
				))
		);
	}
}
