import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

/**
 * Default GAW route reuse strategy.
 * It works by reading route config data to know if a given key (i.e., route/component tree) must be reused,
 * otherwise it falls back to standard route reuse strategy (i.e., same route config) with no component cache.
 * Example:
 *
 * ```json
 * 	{
 *		path: 'types/:license/:siacode/:doctype',
 *		component: DocumentsTypeComponent,
 *		data: {
 *			shouldReuse: true,
 *			shouldReuseAfter: 'gaw30_documentDetailRoot',
 *			shouldReuseKey: 'gaw30_documentListRoot'
 *		}
 *	},
 *	{
 *		path: 'types/:license/:siacode/:section/:doctype/doc/:elasticid',
 *		component: DocumentDetailComponent,
 *		data: {
 *			shouldReuse: false,
 *			shouldReuseKey: 'gaw30_documentDetailRoot'
 *		}
 *	},
 * ```
 *
 * `shouldReuse`: means the route should be reused and its component should be cached (always and forever).
 * `shouldReuseKey`: the key in which to store the cached component tree (as a DetachedRouteHandle).
 * `shouldReuseAfter`: means the route should be reused only after visiting a specific given route key,
 * and removed from the cache, otherwise.
 */

const REUSE_KEY = 'shouldReuseKey';
const REUSE_AFTER = 'shouldReuseAfter';
const REUSE = 'shouldReuse';

@Injectable()
export class GawReuseStrategy implements RouteReuseStrategy {

	private handlers: { [key: string]: DetachedRouteHandle } = {};
	private future: ActivatedRouteSnapshot;

	shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		this.future = future;
		return future.routeConfig === curr.routeConfig;
	}

	shouldDetach(route: ActivatedRouteSnapshot): boolean {
		let shouldReuse = !!route.data[REUSE];
		if (!shouldReuse)
			return shouldReuse;

		const shouldReuseAfter = route.data[REUSE_AFTER];
		if (shouldReuseAfter) {
			// If we have a shouldReuseAfter, we should cache and reuse iif we are going to a shouldReuseAfter key.
			// Otherwise, we never (and clean the) cache.
			shouldReuse = shouldReuseAfter === this.getShouldReuseKey(this.future);
			if (!shouldReuse && !!this.handlers[this.getShouldReuseKey(route)])
				delete this.handlers[this.getShouldReuseKey(route)];

		}
		return shouldReuse;
	}

	shouldAttach(route: ActivatedRouteSnapshot): boolean {
		return !!route.data[REUSE] && !!this.handlers[this.getShouldReuseKey(route)];
	}

	store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
		if (!handle || !route.data[REUSE])
			return;

		this.handlers[this.getShouldReuseKey(route)] = handle;
	}

	retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
		if (!route.data[REUSE])
			return null;

		return this.handlers[this.getShouldReuseKey(route)] as DetachedRouteHandle;
	}

	private getShouldReuseKey(route: ActivatedRouteSnapshot): string {
		return route.data[REUSE_KEY] || null;
	}
}
