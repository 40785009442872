import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';

@Injectable({
	providedIn: 'root',
})
export class MultiActionsCatalog {

	public readonly exportExcelMultiple = new ActionBuilder(MultiActionCode.exportExcelMultiple, ActionCode.DOWNLOAD_EXCEL)
		.setActionURL('/v2/documents/export/excel?clientId=GAWPEC')
		.build();
}

export enum MultiActionCode {
	exportExcelMultiple = 'exportExcelMultiple'
}
