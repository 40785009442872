import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable, ReplaySubject } from 'rxjs';
import { AnomalyStatusHistoryItem } from 'app/entities/anomalies/anomaly-status-history-item';

@Injectable({
	providedIn: 'root'
})
export class AnomaliesStatusHistoryHttpService implements OnDestroy {
	private destroy$ = new ReplaySubject<void>(1);

	private gawpassHost!: string;
	private statusHistoryUrl!: string;

	constructor(private http: HttpClient, private configService: ConfigService) {
		this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.gawpassHost = appConfig.gawPassRead.http.host;

		this.statusHistoryUrl = `${this.gawpassHost}/api/v1/Anomalie/StoricoStatiAnomalie/{idSospeso}`;
	}

	whenStatusHistory(idSospeso: string): Observable<AnomalyStatusHistoryItem[]> {
		const url = this.statusHistoryUrl
			.replace('{idSospeso}', idSospeso);
		return this.http.get<AnomalyStatusHistoryItem[]>(url);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
