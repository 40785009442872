import { Directive, Input, OnDestroy, ViewChild } from '@angular/core';
import { AsyncValidator, AsyncValidatorFn, ControlContainer, NgModel, Validator, ValidatorFn } from '@angular/forms';
import { ValidatorMessage } from 'app/shared/components/dry/validator/validator-message';
import { AbstractControlValueAccessorComponent } from 'app/shared/components/form-validation/abstract-control-value-accessor.component';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { BehaviorSubject, ReplaySubject, Subject, combineLatest, merge, of } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';

/**
 * Classe generica utilizzata per la creazione di FormControl custom.
 * @class CustomControlComponent
 * @param T Il type del form control che implementa questa classe
 * @extends AbstractControlValueAccessorComponent<T>
 * @implements OnDestroy
 */
@Directive()
export class CustomControlComponent<T> extends AbstractControlValueAccessorComponent<T> implements OnDestroy {

	// Validatori generico di required utilizzato per logica di template
	@Input() required = false;
	@Input() disabled = false;
	@Input() readonly = false;

	// Valori del control
	@Input() placeholder = '';
	@Input() name: string;
	@Input() ngModelOptions: {
		name?: string;
		standalone?: boolean;
		updateOn?: 'change' | 'blur' | 'submit';
	} = {
			updateOn: 'change'
		};
	// Style
	@Input() style: {
		[p: string]: string
	};

	// Lista dei validatori
	_validators: Array<Validator | ValidatorFn>;
	_asyncValidators: Array<AsyncValidator | AsyncValidatorFn>;

	protected _id: string;

	protected readonly model$: ReplaySubject<NgModel> = new ReplaySubject<NgModel>(1);
	protected readonly popover$: ReplaySubject<PopoverDirective> = new ReplaySubject<PopoverDirective>(1);
	protected readonly focus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	protected destroy$ = new Subject<void>();

	constructor(
		validators: Array<Validator | ValidatorFn>,
		asyncValidators: Array<AsyncValidator | AsyncValidatorFn>,
		private controlContainer: ControlContainer
	) {
		super();
		this._validators = validators;
		this._asyncValidators = asyncValidators;

		// Gestisco manualmente l'apertura e la chiusura del popover.
		// Chiusura quando focus === false & model === valid
		// Apertura quando focus === true & model === invalid
		combineLatest([this.model$, this.popover$, this.focus$])
			.pipe(
				switchMap(([model, popover, isFocused]) =>
					// Uso model e statusChange perchè finchè non viene modificato, lo statusChange non emette valore
					merge([of(model), model.statusChanges, model.valueChanges])
						.pipe(
							map(() => `Valore attuale "${model.value ? model.value : ''}"`),
							tap(val => popover.popoverTitle = val),
							tap(() => {
								if (model.invalid && isFocused) {
									// Per aggiornare il title del popover devo chiudere e riaprire
									popover.hide();
									popover.show();
								} else
									popover.hide();
							})
						)
				),
				takeUntil(this.destroy$)
			).subscribe();

		// Setto gli errori del model al form padre.
		this.model$.pipe(
			switchMap(model => {
				const valueChanges$ = model.valueChanges.pipe(map(() => model));
				const statusChanges$ = model.statusChanges.pipe(map(() => model));
				return merge(of(model), valueChanges$, statusChanges$)
					.pipe(
						takeUntil(this.destroy$)
					);
			}),
			debounceTime(100),
			takeUntil(this.destroy$)
		).subscribe((model) => {
			if (this.controlContainer && this.controlContainer.control) {
				const control = this.controlContainer.control.get(this.name);
				if (control && model)
					control.setErrors(model.errors);

			}
		});
	}

	get value(): T {
		return this._value;
	}

	@Input() set value(value: T) {
		this._value = value;
	}

	@ViewChild(NgModel, { static: true }) set model(model: NgModel) {
		this.model$.next(model);
	}

	@ViewChild(PopoverDirective, { static: true }) set popover(popover: PopoverDirective) {
		this.popover$.next(popover);
	}

	onFocusIn() {
		this.focus$.next(true);
	}

	onFocusOut() {
		this.focus$.next(false);
	}

	getErrorMessage(model: NgModel): string[] {
		return ValidatorMessage.getErrorMessage(model.control);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
