import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { AppConfig } from 'app/entities/config/app-config';
import { UiUserSessionRead } from 'app/entities/ui-user-session/ui-user-session-read';
import { Moment } from 'moment';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class UiUserSessionHttpService implements OnDestroy {

	private sessionWrite: string;
	private sessionRead: string;

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
		private fullScreenSpinnerService: FullScreenSpinnerService,
	) {
		this.configService.whenAppConfig()
			.pipe(
				takeUntil(this.destroy$)
			).subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		const apiUiUserSession = appConfig.uiUserSession.http.host;
		this.sessionWrite = `${apiUiUserSession}/v1/session/{sessionKeyCode}/Write`;
		this.sessionRead = `${apiUiUserSession}/v1/session/{sessionKeyCode}/Read`;
	}

	getValue<T>(key: string): Observable<UiUserSessionRead<T>> {
		const url = this.sessionRead
			.replace('{sessionKeyCode}', key);
		return this.http.get<UiUserSessionRead<T>>(url);
	}

	setValue<T>(key: string, value: T, expDate: Moment): Observable<any> {
		const url = this.sessionWrite
			.replace('{sessionKeyCode}', key);

		const body = {
			configuration: value,
			expirationDate: expDate.toISOString()
		};

		return this.http.put(url, body);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

}
