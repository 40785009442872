<!--<ng-container *hubNewError="statusHistoryError$ | async; options: tileErrorPlaceholderErrorOption; style: { height: '100px' }">-->
<gaw-infinite-scroll (scrolled)='loadDetails(isFirstLoading)' style='height: 100px'>
	<!--PANEL HISTORY STATI CANALE-->
	<div class='card-status-history'>
		<div class='banner-container' *ngIf='lottoDetailService.error, else detail'>
			<strong>{{lottoDetailService.error}}</strong>
		</div>
		<ng-template #detail>
			<ng-container *ngIf='lottoDetail$ | async as lottoDetail'>
				<table class='table table-responsive table-striped' *ngIf='lottoDetail.details?.length > 0 else noResult'>
					<thead>
						<tr>
							<th *ngFor='let lottoDetailItem of lottoDetail.details[0]'>
								{{ lottoDetailItem.headerLabel }}
							</th>
						</tr>
					</thead>
					<tbody>
						<ng-container *ngFor='let lottoDetailItem of lottoDetail.details; let rowIndex = index'>
							<tr>
								<td *ngFor='let cellItem of lottoDetailItem'>
									<span *ngIf='cellItem.type === "datetime" else rowString'>{{ cellItem.value | utcToLocalDate:
										"DD/MM/YYYY HH:mm:ss" }}</span>
									<ng-template #rowString>
										<span>{{ cellItem.value }}</span>
									</ng-template>
								</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</ng-container>
		</ng-template>
	</div>
</gaw-infinite-scroll>

<ng-template #noResult>
	<div class='banner-container'>
		<strong>Nessun Risultato</strong>
	</div>
</ng-template>
