import { Component } from '@angular/core';

@Component({
	selector: 'app-large-placeholder-doc-list',
	templateUrl: './large-placeholder-doc-list.component.html',
	styleUrls: ['./large-placeholder-doc-list.component.scss']
})
export class LargePlaceholderDocListComponent  {

}
