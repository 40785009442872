import { Injectable } from '@angular/core';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { NGXLogger } from 'ngx-logger';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { KPISection, UserKpi } from '../../entities/kpi/kpi-service';
import { KpiSectionsHttpService } from './kpi-sections-http.service';

@Injectable({
	providedIn: 'root'
})
export class KpiSectionsService {
	private sections$ = new ReplaySubject<Array<KPISection>>(1);
	private kpiList$ = new Subject<any>();
	private kpiList: Array<any> = [];

	constructor(
		private kpiSectionsHttpService: KpiSectionsHttpService,
		private notificationService: NotificationService,
		private logger: NGXLogger
	) { }

	public getKpiSections(licenseId: string, siaCode: string): void {
		this.kpiSectionsHttpService
			.getKPISections(licenseId, siaCode)
			.pipe(
				catchError((err: unknown) => {
					this.logger.error('Errore durante il caricamento delle sezioni kpi ', err);
					return of([]);
				})
			)
			.subscribe((data) => {
				this.setSections(data);
			});
	}

	public whenSections(): Observable<Array<KPISection>> {
		return this.sections$.asObservable();
	}

	public setKpiSections(licenseId: string, siaCode: string, section: Array<KPISection>) {
		return this.kpiSectionsHttpService.setKPISections(licenseId, siaCode, section);
	}

	public getKpiList(licenseId: string, siaCode: string, sectionId: number): void {
		// return this.kpiSectionsHttpService.getKPIs(licenseId, siaCode, sectionId.toString());
		this.kpiSectionsHttpService
			.getKPIs(licenseId, siaCode, sectionId.toString())
			.pipe(
				tap((data) => {
					this.kpiList = [
						...this.kpiList.filter((item) => item.section !== data.sectionId),
						{ section: data.sectionId, data },
					];
					this.setKpiListValue(this.kpiList);
				})
			)
			.subscribe();
	}

	public setKpiList(licenseId: string, siaCode: string, userKpi: UserKpi): Observable<any> {
		return this.kpiSectionsHttpService.setKPIs(licenseId, siaCode, userKpi).pipe(
			catchError((err: unknown) => {

				if (!err?.['cause'].ok && err?.['cause'].status === 409) {
					this.notificationService.showSweetAlert(
						NotificationType.ERROR,
						'ATTENZIONE',
						err?.['cause'].error
					);
					return of(undefined);
				}

				throw (err);
			})
		);
	}

	public whenKpiListValue(): Observable<any> {
		return this.kpiList$.asObservable();
	}

	private setSections(value: Array<KPISection>) {
		this.sections$.next(value);
	}

	private setKpiListValue(value: any) {
		this.kpiList$.next(value);
	}
}
