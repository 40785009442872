import { Directive, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { FormControlDirective, FormControlName, FormGroupDirective, NgControl, NgModel, UntypedFormControl } from '@angular/forms';
import { ValidatorMessage } from 'app/shared/components/dry/validator/validator-message';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, merge, of } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import { AbstractControlValueAccessorComponent } from '../abstract-control-value-accessor.component';

/**
 * Classe generica utilizzata per la creazione di FormControl custom.
 * @class CustomControlComponent
 * @param T Il type del form control che implementa questa classe
 * @extends AbstractControlValueAccessorComponent<T>
 * @implements OnDestroy
 */
@Directive()
export class CustomFormControlComponent<T> extends AbstractControlValueAccessorComponent<T> implements OnInit, OnDestroy {
	// Style
	@Input() style: {
		[p: string]: string
	};
	// Valori del control
	@Input() placeholder = '';
	@Input() id: string;
	@Input() name: string;
	@Input() ngModelOptions: {
		name?: string;
		standalone?: boolean;
		updateOn?: 'change' | 'blur' | 'submit';
	} = {
			updateOn: 'change'
		};
	@Input() type: 'text' | 'password';

	@Output() focusInEvent = new EventEmitter<boolean>();

	control: UntypedFormControl;
	controlErrors$: Observable<string[]>;

	protected readonly popover$ = new ReplaySubject<PopoverDirective>(1);
	protected readonly focus$ = new BehaviorSubject(false);
	protected destroy$ = new Subject<void>();

	constructor(
		@Optional() @Self() public ngControl: NgControl,
		@Optional() public controlName: FormControlName
	) {
		super();

		if (ngControl)
			ngControl.valueAccessor = this;

	}

	get value(): T {
		return this._value;
	}

	@Input() set value(value: T) {
		this._value = value;
	}

	@ViewChild(PopoverDirective, { static: true }) set popover(popover: PopoverDirective) {
		this.popover$.next(popover);
	}

	ngOnInit(): void {
		this.initControl();

		this.controlErrors$ = merge(of(this.control), this.control.valueChanges, this.control.statusChanges).pipe(
			// debounceTime(100),
			map(() => this.control),
			map(ctrl => this.getErrorMessage(ctrl))
		);

		this.handlePopover();
	}

	onFocusIn() {
		this.focus$.next(true);
		this.focusInEvent.emit(true);
	}

	onFocusOut() {
		this.focus$.next(false);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	// Gestisco manualmente l'apertura e la chiusura del popover.
	private handlePopover() {
		// Chiusura quando focus === false & model === valid
		// Apertura quando focus === true & model === invalid

		const ctrl$ = merge(of(this.control), this.control.statusChanges, this.control.valueChanges).pipe(
			debounceTime(100),
			map(() => this.control)
		);

		combineLatest([ctrl$, this.popover$, this.focus$]).pipe(
			debounceTime(100),
			tap(([control, popover, focus]) => {
				popover.popoverTitle = `Valore attuale: "${control.value !== null ? control.value : ''}"`;
				if (control.invalid && focus) {
					// Per aggiornare il title del popover devo chiudere e riaprire
					popover.hide();
					popover.show();
				} else
					popover.hide();

			}),
			takeUntil(this.destroy$)
		).subscribe();
	}

	private getErrorMessage(control: UntypedFormControl): string[] {
		return ValidatorMessage.getErrorMessage(control);
	}

	private initControl() {
		if (this.controlName)
			// Se passo formControlName
			this.control = this.controlName.control;

		if (this.ngControl instanceof FormControlName) {
			const formGroupDirective = this.ngControl.formDirective as FormGroupDirective;
			if (formGroupDirective) {
				const ctrl = formGroupDirective.form.controls[this.ngControl.name];
				if (ctrl)
					this.control = formGroupDirective.form.controls[this.ngControl.name] as UntypedFormControl;

			}
		} else if (this.ngControl instanceof FormControlDirective)
			this.control = this.ngControl.control;
		else if (this.ngControl instanceof NgModel) {
			this.control = this.ngControl.control;
			this.control.valueChanges.subscribe(() => this.ngControl.viewToModelUpdate(this.control.value));
		} else if (!this.ngControl)
			this.control = new UntypedFormControl();

	}
}
