import { HomeFilter } from '@ctel/gaw-commons';
import { IFilter, IFilterStatus, IMetric } from '@ctel/search-filter-store';
import { createAction, props } from '@ngrx/store';

export const homeFiltersError = createAction(
	'[HomeFilter Effect HUBFE] homeFiltersError',
	props<{ error: any }>()
);

export const outsideLanding = createAction(
	'[OutsideFilter Effect HUBFE] outsideLanding');

export const homeMetricsRequested = createAction(
	'[Account Effect HUBFE] homeMetricsRequested');

export const homeMetricsFetched = createAction(
	'[HomeFilter Effect HUBFE] homeMetricsFetched',
	(metrics: IMetric[] = null, totalDocs = 0) =>
		({ payload: { metrics, totalDocs } })
);

export const changeHomeDateFilterRequested = createAction(
	'[Filter Component HUBFE] changeHomeDateFilterRequested',
	(homeFilters: HomeFilter[], section: 'receivable' | 'payable', doNavigate = true) =>
		({ payload: { homeFilters, section, doNavigate } })
);

export const switchHomeDateFilterAccount = createAction(
	'[Home Component HUBFE] switchHomeDateFilterAccount',
	(filters: IFilter[], metrics: IMetric[], doNavigate = true, urlCommands: string[] = []) =>
		({ payload: { filters, metrics, doNavigate, urlCommands } })
);

export const resetHomeDateFilterPayload = createAction(
	'[AccountCard Service HUBFE] resetHomeDateFilterPayload',
	(filterPayload: IFilterStatus, section: 'receivable' | 'payable', firstLoad: boolean) =>
		({ payload: { filterPayload, section, firstLoad } })
);

