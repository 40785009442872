import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, Type } from '@angular/core';
import { Field, FieldType } from 'app/shared/components/dry/field';
import { GridApi } from 'app/shared/components/grid/interface/grid-api';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { DateRangeFilter } from '../table/interface/date-range-filter';
import { Column } from './interface/column';
import { DoubleClickEvent, Filter, Filters, Selection, Sort, Sorting } from './interface/events';
import { GridOptions, Options } from './interface/options';
import { Row } from './interface/row';

@Component({
	template: ''
})
export class AbstractGridComponent<T1, T2 extends Field> implements AfterViewInit, OnDestroy {

	// GridInput
	@Input() columns: Column<T2>[];
	@Input() options: Options<T1> = new GridOptions();
	@Input() noDataAvailableComponent: Type<unknown>;
	@Output() filterChange = new EventEmitter<Filters>();
	@Output() sortChange = new EventEmitter<Sort>();
	@Output() selectionChange = new EventEmitter<Selection>();
	@Output() dblClick = new EventEmitter<DoubleClickEvent>();

	rows: Row<T1>[];
	// GridOutput
	gridApi: GridApi<T1>;

	public readonly _actualFilter: { [p: string]: unknown } = {};
	public _actualSort: Sort = {
		columnId: undefined,
		order: Sorting.ASCENDING
	};

	protected readonly allRowSelected$ = new BehaviorSubject<boolean>(false);

	private _selectionChange$ = new ReplaySubject(1);
	private _destroy$ = new Subject<void>();

	constructor(protected logger: NGXLogger) {
		this.initializeGridApi();

		// Ogni volta che cambia il valore di una checkbox lancio la funzione
		this._selectionChange$.pipe(
			debounceTime(100),
			tap(() => this.checkSelectedStatusRows()),
			takeUntil(this._destroy$)
		).subscribe();
	}

	@Input() set data(data: T1[]) {
		/*
			Creo un view-model dei dati di input.
			Passo alla Row le opzioni per l'inizializzazione in base al fatto che tutte siano selezionate o meno
		*/
		this.rows = data ? data.map(d => new Row(d)) : [];
		this._selectionChange$.next(true);
	}

	ngAfterViewInit(): void {
		this.options.whenGridReady(this.gridApi);
	}

	public whenAllRowsSelected(): Observable<boolean> {
		return this.allRowSelected$.asObservable();
	}

	/**
	 * Funzione che gestisce l'ordinamento della tabella
	 * @param column Colonna su cui viene richiesto l'ordinamento
	 */
	setSorting(column: Column<Field>) {

		if (!this.options.sorting)
			return;

		// Ordine di default
		const defaultOrder = Sorting.ASCENDING;

		// Creo un oggetto di ordinamento contenente il nuovo campo e l'ordinamento di default
		const newSort: Sort = {
			columnId: column.field.id,
			order: defaultOrder
		};

		// Se sto ordinando per un campo già ordinato in precedenza, ciclo sul tipo di ordinamento
		if (column.field.id === this._actualSort.columnId)
			switch (this._actualSort.order) {
				case Sorting.ASCENDING:
					newSort.order = Sorting.DESCENDING;
					break;
				case Sorting.DESCENDING:
					newSort.order = Sorting.NOT_SET;
					break;
				default:
					newSort.order = Sorting.ASCENDING;
			}

		this._actualSort = newSort;
		this.sortChange.emit(newSort);
	}

	/**
	 * Aggiunge un filtro
	 * @param c Colonna su cui viene applicato il filtro
	 * @param value Valore del filtro applicato
	 */
	addFilter(c: Column<Field>, value: unknown) {
		this._actualFilter[c.field.id] = value;
		this.setFormFilter();
	}

	/**
	 * Rimuove un filtro tra quelli applciati
	 * @param c
	 */
	removeFilter(c: Column<Field>) {
		this._actualFilter[c.field.id] = undefined;
		this.setFormFilter();
	}

	/**
	 * Seleziona un oggetto
	 * @param item Oggetto selezionato
	 */
	toggleSelection(item: Row) {
		// Se non è settata l'opzione o se già presente nell'Array esco
		if (this.options.rowSelection === 'none')
			return;

		item.setSelected(!item.isSelected);

		// Se la selezione è singola ripulisco tutte le altre selezioni
		if (this.options.rowSelection === 'single')
			this.rows.filter(r => r !== item).forEach(r => r.setSelected(false));

		this.emitSelectionChange(item);
		this._selectionChange$.next(true);
	}

	/**
	 * Setta la selezione di tutte le Row a un determinato valore
	 * @param value
	 */
	setAllRowSelection(value: boolean) {

		if (this.options.rowSelection === 'none')
			return;

		this.rows.forEach(r => {
			if (r.isSelected !== value) {
				r.setSelected(value);
				this.emitSelectionChange(r);
			}
		});
		this._selectionChange$.next(true);
	}

	/**
	 * Evento di doppio click
	 * @param item Row su cui viene eseguito il doppio click
	 * @event dblClick Evento di tipo DoubleClickEvent
	 */
	doubleClick(item: Row) {
		if (this.options.dblClick)
			this.dblClick.emit(<DoubleClickEvent>{
				item,
			});

	}

	ngOnDestroy(): void {
		this._destroy$.next();
		this._destroy$.complete();
	}

	private setFormFilter() {

		if (!this.options.filter)
			return;

		const filters: Filters = [];

		this.columns.forEach(c => {
			let value = this._actualFilter[c.field.id];
			// Se il valore è presente, faccio un parse in base al tipo (attualmente gestito solo date-range)
			if (value && c.field.type === FieldType.DATE_RANGE && value instanceof Array)
				// Se il valore è un Array, corrisponde a un range di date
				value = new DateRangeFilter(value[0], value[1]);

			filters.push(<Filter>{
				value,
				column: c
			});
		});

		this.filterChange.emit(filters);
	}

	private checkSelectedStatusRows() {
		// Notifico al subject se tutte le row sono selezionate o meno
		this.allRowSelected$.next(this.rows.length > 0 && this.rows.find(r => r.isSelected === false) === undefined);
	}

	private emitSelectionChange(row: Row) {
		// Emetto l'evento di selezione
		this.selectionChange.emit({
			item: row,
			selected: row.isSelected
		});
	}

	private initializeGridApi() {
		this.gridApi = {
			getRows: () => this.rows,
			getSelectedRows: () => this.rows.filter(r => r.isSelected),
			selectRows: () => this.setAllRowSelection(true),
			unselectRows: () => this.setAllRowSelection(false)
		};
	}
}
