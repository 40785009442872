import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { DecimalUtilities } from 'app/core/common/utilities/number/decimal-utilities';
import { ErrorMessage } from 'app/entities/errors/errors';
import { CustomValidationError } from 'app/shared/components/dry/validator/custom-validation-error';

/**
 * Validatori relativi ai form di tipo Number
 */
export class NumberValidators {

	/**
	 * Validator that requires controls to have a value greater than a number.
	 * @param n
	 * @return ValidatorFn
	 */
	public static min(n: number): ValidatorFn {
		return Validators.min(n);
	}

	/**
	 * Validator that requires controls to have a value less than a number.
	 * @param n
	 * @return ValidatorFn
	 */
	public static max(n: number): ValidatorFn {
		return Validators.max(n);
	}

	public static hasDecimal(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: ErrorMessage } => {
			if (control.value || control.value === 0)
				return DecimalUtilities.hasDecimal(control.value) ? null : <CustomValidationError>{
					'decimal': {
						message: 'Il valore non è un numero decimale'
					}
				};

			return null;
		};
	}

	/**
	 * Validatore per la parte decimale compostra tra 0 e 'n' valori.
	 * @param n
	 * @return ValidatorFn
	 */

	// const regex = new RegExp(`^[\\-]?[0-9]+([.][0-9]{0,${n}})?$`);
	public static maxDecimal(n: number): ValidatorFn {

		if (!n)
			n = 0;

		return (control: AbstractControl): { [key: string]: ErrorMessage } => {

			const regex = new RegExp(`^[0-9]+([.][0-9]{0,${n}})?$`);

			if (control.value && control.value !== '')
				return regex.test(control.value.toString()) ? null : <CustomValidationError>{
					'maxDecimal': {
						message: `Supera il limite di ${n} valori decimali`
					}
				};

			return null;
		};
	}

	/**
	 * Validatore per la parte decimale compostra tra 0 e 'n' valori anche negativi.
	 * @param n
	 * @return ValidatorFn
	 */
	public static negativeDecimalReg(n: number): ValidatorFn {

		if (!n)
			n = 0;

		return (control: AbstractControl): { [key: string]: ErrorMessage } => {

			const regex = new RegExp(`^[\\-]?[0-9]+([.][0-9]{0,${n}})?$`);

			if (control.value && control.value !== '')
				return regex.test(control.value.toString()) ? null : <CustomValidationError>{
					'maxDecimal': {
						message: `Supera il limite di ${n} valori decimali`
					}
				};

			return null;
		};
	}

	/**
	 * Validatore che controlla se il valore è un numerico
	 * @return ValidatorFn
	 */
	public static isNumber(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: ErrorMessage } => {

			if (control.value && control.value !== '')

				return !isNaN(Number(control.value)) ? null : <CustomValidationError>{
					'number': {
						message: 'Il campo richiede un valore numerico'
					}
				};

			return null;
		};
	}

	public static decimalLength(n: number): ValidatorFn {

		if (!n)
			n = 0;

		return (control: AbstractControl): { [key: string]: ErrorMessage } => {
			if (control.value !== null && !isNaN(Number(control.value)))
				if (!DecimalUtilities.hasDecimalLength(control.value, n) || !DecimalUtilities.hasDecimal(control.value))
					return <CustomValidationError>{
						'maxDecimal': {
							message: `Il valore non rispecchia ${n} valori decimali`
						}
					};

			return null;
		};
	}
}
