import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Document } from '@ctel/gaw-commons';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { ActionsConfigRequest, DocMetadata } from 'app/entities/ui-config/action/actions-configuration';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { FRLottiActionsService2 } from '../actions/actions.service';
import { FRLottiDocumentDetailsService } from '../document-details/document-details.service';
import { FRLottiDocumentsService2 } from '../documents/documents.service';

/**
 * Servizio che gestisce gli stati delle checkbox
 */
@Injectable({
	providedIn: 'root'
})
export class FRLottiCheckboxService implements OnDestroy {

	currentMultiActionStatus$: Observable<boolean>;
	public checkboxMap$: BehaviorSubject<Map<string, boolean>> = new BehaviorSubject(new Map<string, boolean>());
	private activateMultiAction$ = new BehaviorSubject<boolean>(false);

	// Booleano che indica se tutte le checkbox sono selezionate o meno
	private areCheckedAll$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private actionsService: FRLottiActionsService2,
		private documentsService: FRLottiDocumentsService2,
		private companiesService: CompaniesService,
		private route: ActivatedRoute,
		private documentDetailsService: FRLottiDocumentDetailsService
	) {

		this.currentMultiActionStatus$ = this.activateMultiAction$.asObservable();

		// chiedo le azioni multiple
		this.whenCheckboxMap()
			.pipe(
				switchMap(checkboxMap => combineLatest([
					this.documentsService.whenDocuments(),
					this.companiesService.whenCurrentCompany()
				]).pipe(
					take(1),
					map(([documents, company]) => {
						const request: ActionsConfigRequest[] = [];
						for (const [serializedDocKeys, checked] of checkboxMap)
							if (checked === true) {
								const docKeys = JSON.parse(serializedDocKeys);
								const document = documents.find(d => d.keys['progSpool'] === docKeys.progSpool && d.keys['progBusta'] === docKeys.progBusta);
								if (document) {
									const docMetadata: DocMetadata[] = [];
									for (let l = 0; l < document.display.length; l++)
										docMetadata.push({
											keyCode: document.display[l].metadata.replace('.keyword', ''),
											value: document.display[l].value,
											type: document.display[l].type
										});

									Object.keys(docKeys).forEach(value => docMetadata.push({
										keyCode: value,
										value: docKeys[value]
									})
									);

									// Eliminazione ID_SERIE_DOC
									request.push({
										progSpool: docKeys.progSpool,
										progBusta: docKeys.progBusta,
										docMetadata
									});
								}
							}

						if (request.length > 0)
							this.actionsService.requestMultiActionsConfig(
								company.licenseId, company.siaCode, 'gcons_FirmaRemotaLotti', request
							);

						return request;
					})
				)),
				takeUntil(this.destroy$)
			).subscribe();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	areCheckedAll(): boolean {
		return this.areCheckedAll$.value;
	}

	whenCheckedAll(): Observable<boolean> {
		return this.areCheckedAll$.asObservable();
	}

	// Aggiorno il valore di una checkbox oppure lo aggiungo se non è presente nel dictionary
	sendCheckboxValue(doc: Document, key: string, checked: boolean) {
		this.actionsService.sendMultiActionsList([]);
		this.checkboxMap$.pipe(take(1)).subscribe(map => {
			if (map.has(key)) {
				// Se chiave presente aggiorno il valore
				map.delete(key);
				map.set(key, checked);
			} else
				// Se la chiave non esiste l'aggiungo
				map.set(key, checked);

			if (checked === false)
				// Se setto una checkbox a false, aggiorno il subject di checkedAll a false
				this.sendCheckedAll(false);

			// Aggiorno il map
			this.sendCheckboxMap(map);
		});
	}

	// Setto il valore per tutte le checkbox
	setAllCheckbox(value: boolean) {
		this.actionsService.sendMultiActionsList([]);
		this.checkboxMap$.pipe(take(1))
			.subscribe((map: Map<string, boolean>) => {
				const newMap = new Map<string, boolean>();

				map.forEach((_, key) => {
					newMap.set(key, value);
				});

				// Aggiorno il map
				this.sendCheckboxMap(newMap);

				// Quando setto tutti i checkbox, aggiorno anche il subject di CheckedAll
				this.sendCheckedAll(value);
			});
	}

	resetCheckboxes() {
		this.sendCheckboxMap(new Map());
		this.sendCheckedAll(false);
	}

	getCheckedKeys(): string[] {

		const keys = [];
		this.checkboxMap$.value.forEach((value, key) => {
			if (value === true)
				keys.push(key);

		});

		return keys;
	}

	whenCheckboxMap(): Observable<Map<string, boolean>> {
		return this.checkboxMap$.asObservable();
	}

	// Aggiorno il valore per indicare se tutte le checkbox sono selezionate
	private sendCheckedAll(status: boolean) {
		this.areCheckedAll$.next(status);
	}

	private sendCheckboxMap(checkboxMap: Map<string, boolean>) {
		this.checkboxMap$.next(checkboxMap);
	}

}
