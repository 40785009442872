import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'currency-eur'
})
export class CurrencyEurPipe implements PipeTransform {

	constructor(
		private currencyPipe: CurrencyPipe,
	) { }

	transform(value: string | number): string {
		return this.currencyPipe.transform(Number(value), 'EUR', 'symbol', '1.2-2');
	}

}
