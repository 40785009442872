// actions.ts
import {
	FilterState,
	getFiltersWithUserValuesForSearch,
	getFullTextSearchWithUserValuesForSearch,
	getOrderByFiltersWithUserValuesForSearch,
	getPagingFiltersWithUserValuesForSearch,
	IFilter,
	IFullTextSearch,
	IMetric,
	IOrderBy,
	IPaging,
	selectFilterState
} from '@ctel/search-filter-store';
import { Action, createAction, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { Column } from 'app/constants/column-configuration/ui-configuration-columns';
import { RelatedSectionData } from 'app/entities/sections/related-section-data';
import { produce } from 'immer';
import { documentsSearchModuleFeatureKey } from '../../filters/store/feature-key';

export const sectionColumnsFetchedGAWPOST = createAction(
	'[Filter Effect GAWPOST] sectionColumnsFetched',
	(primaryConfig: Column[] = [], secondaryConfig: Column[]) =>
		({ payload: { primaryConfig, secondaryConfig } })
);

export const relatedSearchDataFetchedGAWPOST = createAction(
	'[Filter Effect GAWPOST] relatedSectionDataFetched',
	(relatedSectionData: RelatedSectionData[] = []) => ({
		payload: {
			relatedSectionData
		}
	})
);

// reducer.ts
export function extendedDocumentStateReducer(state: ExtendedDocumentState | undefined, action: Action) {
	return _extededDocumentStateReducer(state, action);
}

export function extendedRelatedSectionDataStateReducer(state: RelatedSectionData[] | undefined, action: Action) {
	return _extendedRelatedSectionDataStateReducer(state, action);
}

export interface ExtendedDocumentState {
	primaryColumnConfig: Column[];
	secondaryColumnConfig: Column[];
}

export const initialState: ExtendedDocumentState = {
	primaryColumnConfig: [],
	secondaryColumnConfig: []
};

const _onSectionColumnsFetched = (currentState, { payload }) =>
	produce<ExtendedDocumentState, ExtendedDocumentState>(currentState, draftState => {
		draftState.primaryColumnConfig = payload.primaryConfig;
		draftState.secondaryColumnConfig = payload.secondaryConfig;
		return draftState;
	});

const _extededDocumentStateReducer = createReducer(
	initialState,
	on(sectionColumnsFetchedGAWPOST, _onSectionColumnsFetched)
);

export const relatedSearchDataInitialState: RelatedSectionData[] = [{
	columnMetadata: [],
	docSeriesIds: [],
	textSearchMetadata: []
}];

const _onRelatedSearchDataFetched = (currentState, { payload }) =>
	produce<RelatedSectionData[], RelatedSectionData[]>(currentState, draftState => {
		draftState = payload.relatedSectionData;
		return draftState;
	});

const _extendedRelatedSectionDataStateReducer = createReducer(
	relatedSearchDataInitialState,
	on(relatedSearchDataFetchedGAWPOST, _onRelatedSearchDataFetched)
);

// index.ts
export interface ExtendedDocumentsSearchModuleState {
	extendedDocumentState: ExtendedDocumentState;
}

export interface ExtendedRelatedSectionDataState {
	extendedRelatedSectionDataState: RelatedSectionData[];
}

export const extendedDocumentsSearchModuleReducers = {
	extendedDocumentState: extendedDocumentStateReducer
};

export const extendedRelatedSectionDataModuleReducers = {
	extendedRelatedSectionDataState: extendedRelatedSectionDataStateReducer
};

export const selectDocumentsSearchModuleState = createFeatureSelector<ExtendedDocumentsSearchModuleState>(documentsSearchModuleFeatureKey);
export const selectSectionRelatedDataState = createFeatureSelector<ExtendedRelatedSectionDataState>(documentsSearchModuleFeatureKey);

export const selectExtendedDocumentState = createSelector(
	selectDocumentsSearchModuleState,
	(state: ExtendedDocumentsSearchModuleState) => state.extendedDocumentState
);

export const selectExtendedSectionRelatedData = createSelector(
	selectSectionRelatedDataState,
	(state: ExtendedRelatedSectionDataState) => state.extendedRelatedSectionDataState
);

export const getColumnConfig = createSelector(
	selectExtendedDocumentState,
	(value: ExtendedDocumentState) => ({
		primaryConfig: value.primaryColumnConfig,
		secondaryConfig: value.secondaryColumnConfig
	})
);

export const getRelatedSectionData = createSelector(
	selectExtendedSectionRelatedData,
	(value: RelatedSectionData[]) => value
);

export interface Gaw3FilterState {
	licenseId: string;
	siaCode: string;
	search: IFullTextSearch;
	filters: IFilter[];
	orderBy?: IOrderBy[];
	paging?: IPaging;
	metrics?: IMetric[];
	requested?: boolean;
}

export const getFiltersStateWithUserValuesGawpost = (props) => createSelector(
	selectFilterState,
	getFiltersWithUserValuesForSearch(props),
	getPagingFiltersWithUserValuesForSearch(props),
	getOrderByFiltersWithUserValuesForSearch,
	getFullTextSearchWithUserValuesForSearch,
	getRelatedSectionData,
	getColumnConfig,
	(filterState: FilterState, filtersWithValues: IFilter[], pagingWithValues: IPaging, orderByWithValues: IOrderBy[],
		fullTextSearchWithValues: IFullTextSearch, relatedSectionData: RelatedSectionData[]
	) => {
		const gaw3FilterState: Gaw3FilterState = {
			licenseId: filterState.licenseId,
			filters: filterState.filters,
			metrics: filterState.metrics,
			orderBy: filterState.orderBy,
			paging: filterState.paging,
			search: filterState.search,
			siaCode: filterState.siaCode,
			requested: filterState.requested,
		};

		const searchDataInfo = [];

		relatedSectionData.forEach(el => {
			// cablo "doclabel"
			let columnMetadataCabled: string[];
			if (el.columnMetadata.includes('docLabel'))
				columnMetadataCabled = el.columnMetadata;
			else
				columnMetadataCabled = el.columnMetadata.concat(['docLabel']);

			searchDataInfo.push({
				selectMetadata: columnMetadataCabled,
				textSearchMetadata: el.textSearchMetadata,
				docSeriesIds: el.docSeriesIds
			});
		});

		return {
			...gaw3FilterState,
			filters: filtersWithValues,
			paging: pagingWithValues || null,
			orderBy: orderByWithValues || null,
			search: fullTextSearchWithValues,
			searchDataInfo,
		};
	}
);
