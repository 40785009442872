import { Component, Host, HostBinding, Inject, Input, Optional, SkipSelf, forwardRef } from '@angular/core';
import {
	AsyncValidator,
	AsyncValidatorFn, ControlContainer,
	NG_ASYNC_VALIDATORS,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	Validator,
	ValidatorFn
} from '@angular/forms';
import { CustomControlComponent } from '../custom-control.component';

@Component({
	selector: 'select-input',
	templateUrl: './select-input.component.html',
	styleUrls: ['./select-input.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => SelectInputComponent),
		multi: true,
	}],
})
export class SelectInputComponent extends CustomControlComponent<unknown> {

	/** Array di valori per il select, possono essere valori primitivi o oggetti */
	@Input() selectValues: Array<unknown>;

	/** Booleano che indica se si utilizzano degli object come valori */
	@Input() useComplexObject = false;
	/** Valore da prendere in considerazione nel caso si tratti di oggetti */
	@Input() valueId: string;
	/** Valore da visualizzare all'interno del select se si utilizzano degli oggetti */
	@Input() valueName: string;

	@HostBinding('attr.id')
	externalId = '';

	// TODO: Vedere se spostare le variabili all'interno di un Options

	constructor(
		@Optional() @Inject(NG_VALIDATORS) validators: Array<Validator | ValidatorFn>,
		@Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<AsyncValidator | AsyncValidatorFn>,
		@Optional() @Host() @SkipSelf() controlContainer: ControlContainer
	) {
		super(validators, asyncValidators, controlContainer);
	}

	get id() {
		return this._id;
	}

	@Input()
	set id(value: string) {
		this._id = value;
		this.externalId = null;
	}

	onChangeUpdate() {
		super.updateChanges();
	}
}
