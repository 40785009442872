import { Injectable } from '@angular/core';
import { AllDocumentsCallback } from './callbacks/all-documents-callback';
import { ActionBuilder } from '../../../../../../../src/app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from '../../../../../../../src/app/entities/ui-config/action/action-code.enum';
import { ActionObject } from '../../../../../../../src/app/entities/ui-config/action/action-object';

@Injectable({
	providedIn: 'root'
})
export class MassiveActionsCatalog {

	public readonly removeMassive = new ActionBuilder(MassiveActionCode.removeMassive, ActionCode.REMOVE)
		.setCallback((action: ActionObject, extraParams?: unknown) => this.allDocumentsCallback.removeMassive(action, extraParams))
		.build();

	constructor(private allDocumentsCallback: AllDocumentsCallback) {
	}

}

export enum MassiveActionCode {
removeMassive= 'removeMassive'
}
