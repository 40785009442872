import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConfig} from 'app/entities/config/app-config';
import {UiConfigurationContactUsResponse} from 'app/entities/contact-us/contact-us';
import {Faq} from 'app/entities/help/faq';
import {Observable} from 'rxjs';
import {ConfigService} from '../config/config.service';
import {ConfigurationGroup} from './configuration-group.enum';
import {UiConfigurationColumnsResponse} from 'app/constants/column-configuration/ui-configuration-columns';
import {RelatedSectionData} from 'app/entities/sections/related-section-data';

@Injectable()
export class UiConfigurationHttpService {

	private uiConfigurationHost: string;

	// Endpoints
	private getConfigurationUrl: string;
	private getFiltersUrl: string;
	private relatedDataSearch: string;

	constructor(
		private httpClient: HttpClient,
		public configService: ConfigService,
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.uiConfigurationHost = appConfig.uiConfiguration.http.host;
		this.getFiltersUrl = `${this.uiConfigurationHost}/v1/favorites/filters?sectionCode={sectionCode}&licenseId={licenseId}&siaCode={siaCode}`;
		this.getConfigurationUrl = `${this.uiConfigurationHost}/v2/my/configurations/{gruppoconfigurazione}/GAW?licenseId={licenseId}&siaCode={siaCode}`;
		this.relatedDataSearch = `${this.uiConfigurationHost}/v2/sections/GAW30/{sectionCode}/relatedSectionData?licenseId={licenseId}&siaCode={siaCode}`;
	}

	public getSectionColumns(licenseId: string, siaCode: string, section?: string): Observable<UiConfigurationColumnsResponse> {
		return this.getConfiguration(ConfigurationGroup.COLUMN_CONFIGURATION, licenseId, siaCode, section);
	}

	public getConfiguration(configurationGroup: string, licenseId: string, siaCode: string, section?: string): Observable<any> {
		let url = this.getConfigurationUrl
			.replace('{gruppoconfigurazione}', configurationGroup)
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		if (section !== '')
			url = url + '&sectionCode=' + section;

		return this.httpClient.get<any>(url);
	}

	public getContactUsConfiguration(licenseId: string, siaCode: string): Observable<UiConfigurationContactUsResponse> {
		return this.getConfiguration(ConfigurationGroup.CONTACT_US_CONFIGURATION, licenseId, siaCode, '');
	}

	public getFaqConfiguration(faq: string): Observable<Faq[]> {
		return this.getMockFaqConfiguration(faq);
	}

	public getMockFaqConfiguration<T>(faq: string): Observable<any> {
		return this.httpClient.get<T>(faq);
	}

	public getRelatedSearchData(licenseId: string, siaCode: string, sectionId: string): Observable<RelatedSectionData[]> {
		const url = this.relatedDataSearch
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{sectionCode}', sectionId);
		return this.httpClient.get<RelatedSectionData[]>(url);
	}
}
