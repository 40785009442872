<form novalidate (ngSubmit)="onFormSubmit(form)" #form="ngForm">
  <div class="modal-body">
    <div class="main-div">
      <div class="inner-div">
        <fieldset>
          <div class="choose-channel-form">
            <label class="form-label" for="service">Servizio</label>
            <select
              id="service"
              name="service_id_send_copy_analog"
              class="form-control form-control-sm service-selector"
              (ngModelChange)="onSelectedChannel($event)"
              ngModel
              required
            >
              <option></option>
              <ng-container *ngFor="let channel of channels$ | async">
                <option *ngIf="channel?.name?.toUpperCase() !== 'GEDINVOICE'" value="{{ channel.name.toLowerCase() }}">
                  {{ channel?.name?.toUpperCase() }}
                </option>
              </ng-container>
            </select>
          </div>

          <div class="row">
            <div class="col-5" id="metadataPremulti">
              <div class="metadati-section">
                <label class="col-form-label metadatiLabel" id="metadati-label"> Metadati documento </label>
              </div>
              <div class="card">
                <div class="card-body">
                  <ng-container *ngFor="let metaDato of premultiMetadataList$ | async; let z = index">
                    <div class="row">
                      <ng-container *ngIf="metaDato.listaValoriDropDown === null">
                        <div class="col-12">
                          <label class="form-label" class="col-form-label" for="premultiMetadato">
                            {{ metaDato.labelChiave }}
                          </label>
                        </div>
                        <div class="col-12">
                          <input
                            class="form-control form-control-sm"
                            id="premultiMetadato"
                            type="{{ metaDato.metadataType }}"
                            name="{{ metaDato.nomeCampo }}"
                            [(ngModel)]="metaDato.valore"
                            [required]="metaDato.required"
                          />
                        </div>
                      </ng-container>

                      <!-- Metadato dropdownlist -->
                      <ng-container *ngIf="metaDato.listaValoriDropDown !== null">
                        <div class="row">
                          <div class="col-12">
                            <label class="col-form-label" for="metadato">
                              {{ metaDato.labelChiave }}
                            </label>
                          </div>
                          <div class="col-12">
                            <select-input
                              name="{{ 'Metadati: ' + metaDato.labelChiave }}"
                              [(ngModel)]="metaDato.valore"
                              [required]="metaDato.required"
                              [selectValues]="metaDato.listaValoriDropDown"
                            ></select-input>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="col-7" id="metadataChannel">
              <div class="metadatiSection">
                <label class="col-form-label metadatiLabel">
                  <ng-container *ngIf="selectedChannel$ | async as channel">
                    <ng-container *ngIf="channel">
                      {{ channel?.name?.toUpperCase() }}
                    </ng-container>
                  </ng-container>
                </label>
              </div>
              <div class="card w-100">
                <div class="card-body">
                  <ng-container *ngFor="let metaDato of metadataList$ | async; let z = index">
                    <div class="row">
                      <div class="col-12">
                        <label class="form-label" class="col-form-label" for="metadato">
                          {{ metaDato.labelChiave }}
                        </label>
                      </div>
                      <div class="col-12">
                        <input
                          class="form-control form-control-sm"
                          id="metadato"
                          type="{{ metaDato.metadataType }}"
                          name="{{ metaDato.nomeCampo }}"
                          [(ngModel)]="metaDato.valore"
                          [required]="metaDato.required"
                        />
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" id="send-copy-confirm-button" class="btn btn-primary" [disabled]="form.invalid">
      <b>Conferma</b>
    </button>
    <button type="button" class="btn btn-danger" (click)="modal.hide()">Annulla</button>
  </div>
</form>
