import { Injectable } from '@angular/core';
import { InvoiceMultiValues } from 'app/entities/invoice-pa/invoice-multi-values';

const tipiRegimiFiscali = [
	{ value: 'RF01', label: 'Ordinario' },
	{ value: 'RF02', label: 'Contribuenti minimi (art.1, c.96-117, L. 244/07) ' },
	{ value: 'RF04', label: 'Agricoltura e attività connesse e pesca (artt.34 e 34-bis, DPR 633/72)' },
	{ value: 'RF05', label: 'Vendita sali e tabacchi (art.74, c.1, DPR. 633/72)' },
	{ value: 'RF06', label: 'Commercio fiammiferi (art.74, c.1, DPR 633/72)' },
	{ value: 'RF07', label: 'Editoria (art.74, c.1, DPR 633/72)' },
	{ value: 'RF08', label: 'Gestione servizi telefonia pubblica (art.74, c.1, DPR 633/72)' },
	{ value: 'RF09', label: 'Rivendita documenti di trasporto pubblico e di sosta (art.74, c.1, DPR 633/72) ' },
	{
		value: 'RF10', label: 'Intrattenimenti, giochi e altre attività di cui alla tariffa allegata al DPR 640/72 (art.74, c.6, DPR' +
			' 633/72)'
	},
	{ value: 'RF11', label: 'Agenzie viaggi e turismo (art.74-ter, DPR 633/72)' },
	{ value: 'RF12', label: 'Agriturismo (art.5, c.2, L. 413/91)' },
	{ value: 'RF13', label: 'Vendite a domicilio (art.25-bis, c.6, DPR 600/73)' },
	{ value: 'RF14', label: 'Rivendita beni usati, oggetti d’arte, d’antiquariato o da collezione (art.36, DL 41/95)' },
	{ value: 'RF15', label: 'Agenzie di vendite all’asta di oggetti d’arte, antiquariato o da collezione (art.40-bis, DL 41/95) ' },
	{ value: 'RF16', label: 'IVA per cassa P.A. (art.6, c.5, DPR 633/72)' },
	{ value: 'RF17', label: 'IVA per cassa (art. 32-bis, DL 83/2012) ' },
	{ value: 'RF18', label: 'Altro' },
	{ value: 'RF19', label: 'Regime forfettario (art.1, c.54-89, L. 190/2014)' },
];

@Injectable()
export class RegimeFiscaleService {

	public getList(): InvoiceMultiValues[] {
		return tipiRegimiFiscali.slice();
	}
}

