import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { ActionsConfigRequest, ActionsConfigResponse } from 'app/entities/ui-config/action/actions-configuration';
import { Observable, ReplaySubject, takeUntil } from 'rxjs';

/**
 * Servizio http per la gestione delle chiamate alla ui-configuration per ottenere le azioni
 */
@Injectable({
	providedIn: 'root'
})
export class ActionsConfigurationHttpService implements OnDestroy {

	private uiConfigurationHost: string;
	private jsonContentType = 'application/json';
	private readonly jsonOptions: object;

	// Endpoints
	private getSingleActionsConfigurationUrl: string;
	private getMultipleActionsConfigurationUrl: string;
	private getMassiveActionsConfigurationUrl: string;

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private httpClient: HttpClient,
		public configService: ConfigService,
	) {
		this.configService.whenAppConfig().pipe(takeUntil(this.destroy$))
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
		this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	httpHostInit(appConfig: AppConfig) {
		this.uiConfigurationHost = appConfig.uiConfiguration.http.host;
		this.getSingleActionsConfigurationUrl = `${this.uiConfigurationHost}/v1/actions/document/ui/GAW30/singleActions?licenseId={licenseId}&siaCode={siaCode}&sectionCode={sectionCode}`;
		this.getMultipleActionsConfigurationUrl = `${this.uiConfigurationHost}/v1/actions/document/ui/GAW30/multipleActions?licenseId={licenseId}&siaCode={siaCode}&sectionCode={sectionCode}`;
		this.getMassiveActionsConfigurationUrl = `${this.uiConfigurationHost}/v1/actions/document/ui/GAW30/massiveActions?licenseId={licenseId}&siaCode={siaCode}&sectionCode={sectionCode}`;
	}

	public getSingleActions(
		licenseId: string, siaCode: string, sectionCode: string, body: ActionsConfigRequest
	): Observable<ActionsConfigResponse> {
		const url = this.getSingleActionsConfigurationUrl
			.replace('{sectionCode}', sectionCode)
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.httpClient.post<ActionsConfigResponse>(url, body, this.jsonOptions);
	}

	public getMultipleActions(
		licenseId: string, siaCode: string, sectionCode: string, body?: ActionsConfigRequest[]
	): Observable<ActionsConfigResponse> {
		const url = this.getMultipleActionsConfigurationUrl
			.replace('{sectionCode}', sectionCode)
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.httpClient.post<ActionsConfigResponse>(url, body, this.jsonOptions);
	}

	public getMassiveActions(licenseId: string, siaCode: string, sectionCode: string): Observable<ActionsConfigResponse> {
		let url = this.getMassiveActionsConfigurationUrl;
		// if (sectionCode === 'ALL') {
		// 	url = url.replace('&serieDoc={docSeriesId}', '');
		// }
		url = url
			.replace('{sectionCode}', sectionCode)
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.httpClient.get<ActionsConfigResponse>(url, this.jsonOptions);
	}

}
