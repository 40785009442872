<ver-show-version
	[appName]="appName"
	[repoName]="'GAW'"
	[tfsProjectBaseUri]="'https://azuredevops.credemtel.it/DefaultCollection/GEDONWEB3'"
	[versionObject]="versionObject | async"
	[featureFlags]="featureFlags$ | async"
></ver-show-version>
<a routerLink="/gaw/home">
	<button class="btn btn-primary">Homepage</button>
</a>
