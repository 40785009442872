/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative al dettaglio documento
 */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { IDetailsComponent } from '@ctel/gaw-commons';
import { Transfer, TransferService, transferHandler } from '@ctel/transfer-manager';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ActionsHttpService implements OnDestroy {
	private jsonContentType = 'application/json';
	// host
	private responsabiliHost: string;
	private pacchettiDiDistribuzioneHost: string;
	private documentiHost: string;

	private actionLoading$ = new BehaviorSubject<boolean>(false);

	private downloadPdfUrl: string;
	private downloadPddUrl: string;

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
		private transferService: TransferService,
		private fullScreenSpinnerService: FullScreenSpinnerService
	) {
		this.configService.whenAppConfig().pipe(takeUntil(this.destroy$))
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	httpHostInit(appConfig: AppConfig) {
		this.responsabiliHost = appConfig.gawConsResponsabiliRead.http.host;
		this.documentiHost = appConfig.gawConsDocumentiRead.http.host;
		this.pacchettiDiDistribuzioneHost = appConfig.webConsOrchestrator.http.host;

		this.downloadPddUrl = `${this.pacchettiDiDistribuzioneHost}/v1/PacchettiDistribuzione/scaricapdd?IdLicenza={licenseId}&CodSia={siaCode}&IdPdd={idDoc}`;
		this.downloadPdfUrl = `${this.responsabiliHost}/v1/responsabili/scaricaPdf/{idUtenteRdc}`;
	}

	public whenLoadingAction(): Observable<boolean> {
		return this.actionLoading$.asObservable();
	}

	public setLoadingAction(value: boolean) {
		this.actionLoading$.next(value);
	}

	whenSinglePdf(idUtenteRdc: string): Observable<Transfer> {
		const url = this.downloadPdfUrl
			.replace('{idUtenteRdc}', idUtenteRdc);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts).pipe(transferHandler(this.transferService));
			})
		);
	}

	whenExcelAll(actionUrl: string, body: unknown): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		// TODO da inserire url corretto
		const url = this.responsabiliHost + actionUrl;
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.post(url, body, opts).pipe(transferHandler(this.transferService));
			})
		);
	}

	whenPdfZip(actionUrl: string, data: IDetailsComponent) {
		const url = this.documentiHost + actionUrl
			.replace('{licenseId}', data.licenseId)
			.replace('{siaCode}', data.siaCode)
			.replace('{idDoc}', data.keys['idDocumento'])
			.replace('{idVersion}', data.keys['idVersione'])
			.replace('{download}', `${true}`);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts).pipe(transferHandler(this.transferService));
			})
		);
	}

	whenPdd(data: unknown) {
		const url = this.downloadPddUrl
			.replace('{licenseId}', data?.['licenseId'])
			.replace('{siaCode}', data?.['siaCode'])
			.replace('{idDoc}', data?.['documentId']);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts).pipe(transferHandler(this.transferService));
			})
		);
	}
}
