import { Injectable } from '@angular/core';
import { BoxDetails } from '@ctel/gaw-commons';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BoxDetailsHttpService } from './box-details-http.service';

@Injectable({
	providedIn: 'root'
})
export class BoxDetailsService {
	private boxDetails$ = new ReplaySubject<BoxDetails>(1);
	private boxDetailsError$ = new BehaviorSubject<boolean>(false);

	constructor(private boxHttpService: BoxDetailsHttpService) { }

	whenBoxDetails(licenseId: string, siaCode: string, boxId: string) {
		return this.boxHttpService.whenBoxDetails$(licenseId, siaCode, boxId).pipe(
			catchError(() => {
				this.sendStatusHistoryError(true);
				return EMPTY;
			}),
			tap((boxDetails) => {
				this.sendStatusHistoryError(false);
				this.sendStatusHistory(boxDetails);
			})
		);
	}

	sendStatusHistory(boxDetails: BoxDetails) {
		this.boxDetails$.next(boxDetails);
	}

	sendStatusHistoryError(value: boolean) {
		this.boxDetailsError$.next(value);
	}

	whenBoxDetailsError(): Observable<boolean> {
		return this.boxDetailsError$.asObservable();
	}
}
