import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ChangePasswordComponent } from './change-password/change-password.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatSlideToggleModule
	],
	declarations: [
		ChangePasswordComponent
	],
	exports: [
		ChangePasswordComponent
	]
})
export class UserModule {
}
