/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NumberValidators } from 'app/shared/components/dry/validator/number-validators';

@Directive({
	selector: '[decimalLength][ngModel]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: DecimalLengthDirective, multi: true }
	]
})
export class DecimalLengthDirective {

	validator: ValidatorFn;

	@Input() set decimalLength(decimalLength: number | string) {

		let val = typeof decimalLength === 'string' ? Number(decimalLength) : decimalLength;

		if (!val || isNaN(val))
			val = 0;

		this.validator = NumberValidators.decimalLength(val);
	}

	registerOnValidatorChange(fn: () => void): void { }

	validate(c: AbstractControl): ValidationErrors | null {
		return this.validator(c);
	}

}
