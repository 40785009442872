export interface StatusNoteHistory {
	storicoItem: StatusNoteItem[];
}

export interface StatusNoteItem {
	stato: string;
	dataOra: string;
	note: string;
}

export interface StatusHistory {
	storicoItem: StatusHistoryItem[];
}

export interface StatusHistoryItem {
	value: string;
	headerLabel: string;
	tooltipLabel: string;
	type: string;
}

export interface HistoricalChannelState {
	stato: {
		value: string;
		headerLabel: string;
		tooltipLabel: string;
		type: string;
	};
	note: {
		value: string;
		headerLabel: string;
		tooltipLabel: string;
		type: string;
	};
	dataOra: {
		value: string;
		headerLabel: string;
		tooltipLabel: string;
		type: string;
	}
}

export interface TrackingStatusHistory {
	stato: number;
	dataStato: string;
	azienda: string;
	utente: string;
	statoScatola: string;
	descrizioneStato: string;
}
