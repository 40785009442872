import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from '@ctel/block-ui';
import { LargePlaceholderComponent } from 'app/core/common/placeholder/loading-placeholder/templates/spinner/large-placeholder/large-placeholder.component';
import { PlaceholderService } from 'app/core/common/placeholder/placeholder.service';
import { DocumentAttachment } from 'app/shared/components/display-document/document-attachment';
import { LoadingStatus } from 'app/shared/components/display-document/viewers/pdf-viewer/loading-status.enum';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class AbstractViewerComponent<T extends DocumentAttachment<unknown>> implements OnInit, OnDestroy {
	// Blocco dell'interfaccia
	@BlockUI('document-content') pdfContentBlockUi: NgBlockUI;
	// TODO: rendere configurabile dall'esterno
	public blockTemplate = LargePlaceholderComponent;
	public documentAttachment$ = new ReplaySubject<T>(1);

	// Stato del caricamento
	public loadingStatuse$ = new BehaviorSubject<LoadingStatus>(LoadingStatus.waiting);
	public lastLoadingFailed = false;
	public loading$: Observable<boolean> = this.loadingStatuse$.pipe(
		map((status) => status === LoadingStatus.loading),
		distinctUntilChanged()
	);
	private destroy$ = new Subject<void>();

	protected constructor(protected placeHolderService: PlaceholderService
	) { }

	// Contenuto del documento
	@Input()
	set documentAttachment(value: T) {
		if (value) this.sendDocumentAttachment(value);
	}
	ngOnInit() {
		// Gestisci l'interazione utente in base allo stato di caricamento del documento
		this.loadingStatuse$.pipe(takeUntil(this.destroy$)).subscribe((status) => {
			switch (status) {
				case LoadingStatus.waiting:
					this.releaseUI();
					break;

				case LoadingStatus.loading:
					this.blockUI();
					break;

				case LoadingStatus.loaded:
					this.lastLoadingFailed = false;
					this.releaseUI();
					break;

				case LoadingStatus.error:
					this.lastLoadingFailed = true;
					break;
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
	public refreshContent() {
		this.initDocumentContent();
	}
	public sendDocumentAttachment(value: T) {
		this.documentAttachment$.next(value);
	}
	protected setLoadingStatus(value: LoadingStatus) {
		this.loadingStatuse$.next(value);
	}
	// Impedisci all'utente l'interazione
	private blockUI() {
		this.placeHolderService.startBlockUI(this.pdfContentBlockUi);
	}

	// Permetti all'utente l'interazione
	private releaseUI() {
		this.placeHolderService.stopBlockUI(this.pdfContentBlockUi);
	}

	/**
	 * L'implementazione del caricamento del documento è delegata alle implementazioni concrete
	 */
	protected abstract initDocumentContent();
}
