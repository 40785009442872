/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative al dettaglio documento
 */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { TransferService, transferHandler } from '@ctel/transfer-manager';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { BehaviorSubject, Observable, ReplaySubject, switchMap, takeUntil } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ActionsHttpService implements OnDestroy {
	private jsonContentType = 'application/json';
	// host

	private readonly jsonOptions: object;
	private actionLoading$ = new BehaviorSubject<boolean>(false);
	private orchestratorHost: string;
	private confirmUrl: string;

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
		private transferService: TransferService,
		private fullScreenSpinnerService: FullScreenSpinnerService
	) {
		this.configService.whenAppConfig().pipe(takeUntil(this.destroy$))
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));

		this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	httpHostInit(appConfig: AppConfig) {
		this.orchestratorHost = appConfig.webConsOrchestrator.http.host;
		this.confirmUrl = `${this.orchestratorHost}/v1/FirmaRemota/documenti/conferma`;

	}

	public whenLoadingAction(): Observable<boolean> {
		return this.actionLoading$.asObservable();
	}

	public setLoadingAction(value: boolean) {
		this.actionLoading$.next(value);
	}

	whenDownload(actionUrl: string, progSpool, progBusta) {
		const url = this.orchestratorHost + actionUrl
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob');
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts).pipe(
					transferHandler(this.transferService)
				);
			})
		);
	}

	cancel(actionUrl: string, licenseId: string, siaCode: string, documenti: { progBusta: number; progSpool: number }[]) {
		const url = this.orchestratorHost + actionUrl;
		const body = {
			idLicenza: licenseId,
			codSia: siaCode,
			documenti
		};
		return this.http.delete(url, { body });
	}

	confirm(nota: string, licenseId, siaCode, documenti: { progBusta: number; progSpool: number }[]) {
		const body = {
			idLicenza: licenseId,
			codSia: siaCode,
			nota,
			documenti
		};
		return this.http.post(this.confirmUrl, body);
	}
}
