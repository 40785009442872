import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EventService } from './event.service';

@NgModule({
	imports: [
		CommonModule,
	],
})
export class EventModule {

	static forRoot(): ModuleWithProviders<EventModule> {
		return {
			ngModule: EventModule,
			providers: [
				EventService
			]
		};
	}
}
