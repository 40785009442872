import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable, ReplaySubject, takeUntil } from 'rxjs';
import { CustomHttpOptions } from '../../../../../../src/app/core/common/utilities/custom-http-options';
import { ValidateResponseModel } from '../entities/validate-response-model';
import { InfoResponseModel } from '../entities/info-response-model';

@Injectable({
	providedIn: 'root'
})
export class ScartoHttpService implements OnDestroy {
	private scartoHost: string;
	private jsonContentType = 'application/json';
	private readonly jsonOptions: object;
	private validateUrl: string;
	private infoUrl: string;

	private destroy$ = new ReplaySubject<void>(1);
	private requestUrl: string;

	constructor(
		private http: HttpClient,
		private configService: ConfigService
	) {
		this.configService.whenAppConfig().pipe(takeUntil(this.destroy$))
			.subscribe((appconfig: AppConfig) => this.httpHostInit(appconfig));
		this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');

	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	httpHostInit(appconfig: AppConfig) {
		this.scartoHost = appconfig.webConsOrchestrator.http.host;
		this.validateUrl = `${this.scartoHost}/v1/Scarti/supporti/validastato`;
		this.infoUrl = `${this.scartoHost}/v1/Scarti/supporti/infocosti/massiva`;
		this.requestUrl = `${this.scartoHost}/v1/Scarti/supporti/richiesta/massiva?copyRequested={copyRequested}`;
	}

	private getHttpOptions(contentType: string, responseType: string, log = false): object {
		let headers = new HttpHeaders().set('Content-Type', contentType);

		if (log) headers = headers.set('log', 'true');

		let options;
		switch (responseType) {
			case 'blob':
				options = {
					headers,
					responseType: 'blob' as const
				};
				break;
			case 'text':
				options = {
					headers,
					responseType: 'text' as 'json'
				};
				break;
			default:
				options = {
					headers,
					responseType: 'json' as const
				};
				break;
		}

		return options;
	}

	validate(body: string): Observable<ValidateResponseModel> {
		return this.http.post<ValidateResponseModel>(this.validateUrl, body, this.jsonOptions);
	}

	info(body: string): Observable<InfoResponseModel> {
		return this.http.post<InfoResponseModel>(this.infoUrl, body, this.jsonOptions);
	}

	request(body: string, copyRequested:boolean)	:Observable<void> {
		const url = this.requestUrl.replace('{copyRequested}' , copyRequested.toString());
		return this.http.post<void>(url, body , this.jsonOptions);
	}
}
