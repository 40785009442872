export interface StatusHistory {
	storicoItem: StatusHystoryItem[];
}

export interface StatusHystoryItem {
	value: string;
	headerLabel: string;
	tooltipLabel: string;
	type: string;
}

