<div *hubLoading="historyPanelLoading; style: { height: '200px' }" class="tab-box p-3">
  <!--PANEL HISTORY STATI CANALE-->
  <ng-container *ngIf="statusHistory$ | async as statusHistory">
    <table class="table table-responsive table-striped">
      <thead>
        <tr>
          <th [style.width]="'20%'">Data</th>
          <th [style.width]="'20%'">Azione</th>
          <th [style.width]="'20%'">Stato</th>
          <th [style.width]="'20%'">Utente</th>
          <th [style.width]="'20%'">Dettagli</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let history of statusHistory">
          <td>
            <span>{{ history.data_stato | utcToLocalDate : 'DD-MM-YYYY HH:mm' }}</span>
          </td>
          <td>
            <span>{{ history.azione }}</span>
          </td>
          <td>
            <span>{{ history.stato }}</span>
          </td>
          <td>
            <span>{{ history.utente }}</span>
          </td>
          <td>
            <button
              *ngIf="history.descrizione"
              class="btn btn-primary"
              type="button"
              (click)="onDetails(history.data_stato, history.descrizione)"
            >
              Dettagli
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
