<div id="cf-change-text">
	<p> Il Codice Fiscale associato al Codice Univoco Ufficio non corrisponde al Codice Fiscale che è stato inserito in
		precedenza. Desideri sostituire il Codice Fiscale inserito con quello corrispondente al Codice Univoco
		Ufficio?</p>
	<div class="text-center">
		<button type="button" class="btn submit-btn" (click)="changeCf()">
			Modifica Codice Fiscale
		</button>
	</div>
</div>
