<div class="main-div container-fluid">
	<form [formGroup]="formGroup">
		<div *ngFor="let item of metadata" class="form-container">

			<label class="form-label" for="{{item.keyCode}}">{{item.label}}:</label>

			<input [formControlName]="item.keyCode"
						 id="{{item.keyCode}}"
						 type="text"
						 class="form-control">
		</div>
	</form>

	<div>
		<button type="button" (click)="onSubmit()" class="btn btn-add-contact float-end">
			Conferma
		</button>
	</div>
</div>
