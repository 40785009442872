import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VersionComponent } from 'app/modules/error/version/version.component';
import { VersioningModule } from '@ctel/versioning';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [
		VersionComponent
	],
	imports: [
		CommonModule,
		PipesModule,
		RouterModule,
		VersioningModule
	]
})
export class VersionModule {}
