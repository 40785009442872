import { PlatformLocation } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActionsService } from '@ctel/hubfe-commons';
import { HistoryObject } from 'app/core/common/entities/documents/actions/history-item';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { OutcomePreviewModalComponent } from '../outcome-preview-modal/outcome-preview-modal.component';

/**
 * Componente modal storico esiti
 */
@Component({
	selector: 'hub-history-outcomes-modal',
	templateUrl: './history-outcomes-modal.component.html',
	styleUrls: ['./history-outcomes-modal.component.scss']
})
export class HistoryOutcomesModalComponent {

	@Input() modal: BsModalRef;
	public faIcons = FaIcons;
	public history$: Observable<HistoryObject[]>;

	constructor(
		private actionsService: ActionsService,
		private notificationService: NotificationService,
		private location: PlatformLocation
	) {
		location.onPopState(() => {
			this.modal.hide();
		});
		this.history$ = this.actionsService.whenHistory();
	}

	// true: scarica, false: visualizza in nuova modal
	openOutcome(url: string) {
		this.actionsService.downloadOutcome(url).pipe(
			take(1)
		).subscribe(FileSaver.save);
	}

	outcomePreview(url: string) {
		this.notificationService.showModal(NotificationType.EXTRA_LARGE, {
			title: 'Anteprima PDF esito',
			customFooter: false,
			childComponent: OutcomePreviewModalComponent,
			childData: { url },
			disableClickOutside: true
		});
		this.modal.hide();
	}

}
