import { Component, Input, OnInit } from '@angular/core';
import { BarcodeService } from '@ctel/gawpass-commons';
import { Observable } from 'rxjs';
import { Barcode } from '../../../../entities/barcode/barcode';
import { FileSaver } from '../../utilities/file-saver/file-saver';
import { FileSaverExtension } from '../../utilities/file-saver/file-saver-extension.enum';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'gaw-print-barcode-modal',
	templateUrl: './print-barcode-modal.component.html',
	styleUrls: ['./print-barcode-modal.component.scss']
})
export class PrintBarcodeModalComponent implements OnInit {
	@Input() modal: BsModalRef;
	@Input() data: {
		graphic: string,
		licenseId: string,
		siaCode: string,
		code: string,
		note: string,
		idScatola: string
	};

	public barcodes$: Observable<Barcode[]>;

	constructor(
		private barcodeService: BarcodeService
	) { }

	ngOnInit(): void {
		this.barcodes$ = this.barcodeService
			.whenBarcode(this.data.licenseId, this.data.siaCode, this.data.code, this.data.graphic, this.data.note, this.data.idScatola);
	}

	downloadBarcode(barcode: Barcode) {
		const blobFile = b64toBlob(barcode.fileBytes, 'application/pdf');
		new FileSaver(blobFile).saveAs(barcode.fileName, FileSaverExtension.PDF);
	}
}
const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++)
			byteNumbers[i] = slice.charCodeAt(i);

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
};
