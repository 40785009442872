import { ModuleWithProviders, NgModule } from '@angular/core';
import { SearchPagesHttpService } from './search-pages-http.service';
import { SearchPagesService } from './search-pages.service';

@NgModule({
	imports: [],
	providers: [],
	exports: []
})
export class SearchPagesModule {

	static forRoot(): ModuleWithProviders<SearchPagesModule> {
		return {
			ngModule: SearchPagesModule,
			providers: [
				SearchPagesService,
				SearchPagesHttpService
			]

		};
	}
}
