import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ChangePasswordPayload } from '../interfaces/change-password-payload';
import { User } from '../interfaces/user';
import { AuthService } from './auth.service';
import { UserHttpService } from './user-http.service';

interface IUserService {
	getUser(): Observable<User>;

	getRoles(): Observable<Set<string>>;

	hasAccessPermission(): Observable<boolean>;

	hasAtLeastOneRole(roles: Iterable<string>): Observable<boolean>;

	changePassword(payload: ChangePasswordPayload): Observable<boolean>;
}

/**
 * Servizio per recuperare le informazioni dell'utente.
 */
@Injectable({ providedIn: 'root' })
export class UserService implements IUserService {

	private user$ = new ReplaySubject<User>(1);

	constructor(
		private userHttpService: UserHttpService,
		private authService: AuthService
	) {
		this.userHttpService.getCurrentUser().pipe(
			take(1),
			catchError((err: unknown) => EMPTY)
		).subscribe(response => this.user$.next(response));
	}

	/** Ottiene le informazioni dell'utente corrente. */
	getUser(): Observable<User> {
		return this.user$.asObservable();
	}

	/** Indica se l'utente ha almeno uno dei ruoli richiesti. */
	hasAccessPermission(): Observable<boolean> {
		return this.userHttpService.userHasAccessPermission();
	}

	/** Indica se l'utente ha accesso o meno all'applicativo. */
	hasAtLeastOneRole(roles: Iterable<string>): Observable<boolean> {
		const roleSet: Set<string> = new Set(roles);

		return this.getRoles()
			.pipe(
				map(currentUserRoles => {
					if (roleSet.size < 1)
						return true;

					for (const item of Array.from(currentUserRoles.values()))
						if (roleSet.has(item))
							return true;

					return false;
				})
			);
	}

	/** Ottiene la lista dei ruoli dell'utente. */
	getRoles(): Observable<Set<string>> {
		return this.decodedToken()
			.pipe(
				map(decodedToken => decodedToken.role),
				map(rolesArray => new Set<string>(rolesArray)),
			);
	}

	/**
	 * Permette di cambiare la password del proprio utente
	 * @param payload Payload di richiesta contenente la password attuale e quella con cui si vuole cambiare
	 * @return Obsevable<boolean>
	 */
	changePassword(payload: ChangePasswordPayload): Observable<boolean> {
		return this.userHttpService.changePassword(payload);
	}

	private decodedToken() {
		return this.authService.whenToken().pipe(
			map(token => {
				const helper = new JwtHelperService();
				return helper.decodeToken(token);
			})
		);
	}
}
