import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomEncoder } from 'app/core/common/utilities/custom-encoder/custom-encoder';
import { AppConfig } from 'app/entities/config/app-config';
import { InvoiceModelList } from 'app/entities/invoice-pa/invoice-model/invoice-model-list';
import { ModelUploadBody } from 'app/entities/invoice-pa/invoice-model/model-upload-body';
import { HttpMessage } from 'app/entities/invoice-pa/upload-lotto-message/upload-lotto-message';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { OrderField, OrderType } from './order-field-type.enum';

@Injectable()
export class InvoiceModelsHttpService {
	// endpoints
	private listModelsUrl: string;
	private inovoiceModelsUrl: string;
	private gedInvoiceHost: string;
	private deafultModelUrl: string;

	constructor(private http: HttpClient, public configService: ConfigService, private fullScreenSpinnerService: FullScreenSpinnerService) {
		this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.gedInvoiceHost = appConfig.gedInvoice.http.host;
		this.listModelsUrl = `${this.gedInvoiceHost}/v1/GedInvoice/Model/ListModels`;
		this.inovoiceModelsUrl = `${this.gedInvoiceHost}/v1/GedInvoice/Model/Model`;
		this.deafultModelUrl = `${this.gedInvoiceHost}/v1/GedInvoice/Model/DefaultModel`;
	}

	public getListModelsFromDB(
		license: string,
		sia: string,
		pageIndex: number,
		orderField?: OrderField,
		orderType?: OrderType
	): Observable<InvoiceModelList> {
		const url = this.listModelsUrl;
		const headers = new HttpHeaders().set('Content-Type', 'text/json');

		return this.http.get<InvoiceModelList>(url, {
			headers,
			params: {
				idLicense: license,
				codSia: sia,
				orderField,
				orderType,
				pageIndex: pageIndex.toString(),
			},
		});
	}

	public saveModel(model: ModelUploadBody) {
		const url = this.inovoiceModelsUrl;
		const modelPayload = JSON.stringify(model);

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
		};
		return this.http.post(url, modelPayload, httpOptions);
	}

	public deleteModel(license: string, sia: string, modelId: string, modelName: string): Observable<HttpMessage> {
		const url = this.inovoiceModelsUrl;
		const params = new HttpParams({ encoder: new CustomEncoder() })
			.append('idLicense', license)
			.append('codSia', sia)
			.append('idModello', modelId)
			.append('nomeModello', modelName);
		return this.http.delete<HttpMessage>(url, { params });
	}

	public getModel(license: string, sia: string, modelId: string, modelName: string): Observable<ModelUploadBody> {
		const url = this.inovoiceModelsUrl;
		// Utilizzo del HttpParamas con CustomEncoder necessario per issue Angular #18261 (ancora open al giorno 13/11/2019) - Karandip
		// Singh
		const params = new HttpParams({ encoder: new CustomEncoder() })
			.append('idLicense', license)
			.append('codSia', sia)
			.append('idModello', modelId)
			.append('nomeModello', modelName);

		return this.http.get<ModelUploadBody>(url, { params }).pipe(take(1));
	}

	public getDefaultModel(license: string, sia: string): Observable<ModelUploadBody> {
		const url = this.deafultModelUrl;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			params: {
				idLicense: license,
				codSia: sia,
			},
		};
		// return EMPTY;
		return this.http.get<ModelUploadBody>(url, httpOptions);
	}
}
