<div *ngIf="history$ | async as history">
	<ng-container *ngIf="history.length === 0">
		<strong>Storico esiti non disponibile per il documento selezionato.</strong>
	</ng-container>
	<ng-container *ngFor="let historyItem of history; let i = index">
		<table class="table-hover table table-striped">
			<thead>
				<tr *ngIf="i === 0">
					<ng-container *ngFor="let header of historyItem.storicoItem; let headerIndex = index">
						<ng-container *ngIf="historyItem.storicoItem.length > 2 && headerIndex === 0">
							<ng-container *ngIf="historyItem.storicoItem[2].value !== ''">
								<!-- disegno una cella vuota all'inizio: se le colonne sono più di 2, significa che mi vengono restituite anche l'azione di download esito -->
								<!-- controllo anche che il valore dell'url sia popolato - historyItem.storicoItem[2].value-->
								<th class="td-10">Azioni</th>
							</ng-container>
						</ng-container>
						<!-- prima e secondo valore sono quelli delle due colonne sempre presenti -->
						<th class="td-20" *ngIf="headerIndex === 0">{{header.headerLabel}}</th>
						<th *ngIf="headerIndex === 1">{{header.headerLabel}}</th>
					</ng-container>
				</tr>
			</thead>
			<tbody>
				<tr>
					<ng-container *ngFor="let cell of historyItem.storicoItem; let cellIndex = index">
						<!-- se c'è l'azione Download -->
						<ng-container *ngIf="cellIndex === 0 && historyItem.storicoItem.length > 2">
							<!-- controllo che l'url sia popolato -->
							<ng-container *ngIf="historyItem.storicoItem[2].value !== ''">
								<!-- disegno button del Download -->
								<td>
									<i [ngClass]="faIcons.FAR_EYE" data-bs-toggle="tooltip"
										 title="Visualizza"
										 (click)="outcomePreview(historyItem.storicoItem[3].value)">
									</i>
									<i [ngClass]="faIcons.FAS_DOWNLOAD"
										 data-bs-toggle="tooltip"
										 title="Download"
										 (click)="openOutcome(historyItem.storicoItem[2].value)">
									</i>
								</td>
							</ng-container>
						</ng-container>
						<!-- campi delle 2 colonne -->
						<td *ngIf="cellIndex < 2">
							<!-- se è un datetime-->
							<ng-container *ngIf="cell.type === 'datetime'">
								{{cell.value | isoDatetimeToDatetime}}
							</ng-container>
							<!-- altro type -->
							<ng-container *ngIf="cell.type !== 'datetime'" class="break-value">
								{{cell.value | removeAsciiPipe}}
							</ng-container>
						</td>
					</ng-container>
				</tr>
			</tbody>
		</table>
	</ng-container>
</div>
