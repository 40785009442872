import { createAction } from '@ngrx/store';
import { Column } from 'app/constants/column-configuration/ui-configuration-columns';
import { FiltersResponse } from '@ctel/gaw-commons';

	export const sectionColumnsRequested = createAction(
		'[Filter Effect GAWCONS] sectionColumnsRequestedRss',
		(filterResponse: FiltersResponse = null) => ({ payload: { filterResponse } })
	);

	export const relatedSearchDataRequested = createAction(
		'[Filter Effect GAWCONS] relatedSearchDataRequestedRss',
		(filterResponse: FiltersResponse = null, primaryConfig: Column[] = [], secondaryConfig: Column[]) => ({
			payload: {
				filterResponse,
				primaryConfig,
				secondaryConfig
			}
		})
	);

