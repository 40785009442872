import { Component, Input } from '@angular/core';
import { BoxDetails } from '@ctel/gaw-commons';
import { Observable } from 'rxjs';
import { BoxDetailsService } from '../../../services/box-details.service';

@Component({
	selector: 'gaw-gawpass-notes',
	templateUrl: './gawpass-notes.component.html',
	styleUrls: ['./gawpass-notes.component.scss']
})
export class GawpassNotesComponent {
	@Input() boxDetails$: Observable<BoxDetails>;
	tileErrorPlaceholderErrorOption = { message: 'Errore imprevisto.' };

	constructor(private boxDetailsService: BoxDetailsService) { }

}
