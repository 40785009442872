import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Nations } from 'app/entities/nation/nations';
import { Observable } from 'rxjs';

@Injectable()
export class NationsHttpService {

	// endpoints
	private nations: string;
	private nationsHost: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.nationsHost = appConfig.gedInvoice.http.host;
		this.nations = `${this.nationsHost}/v1/GedInvoice/Resources/Nazioni`;
	}

	public getNations(): Observable<Nations> {
		// const nazione: Nations = {};
		// return of (nazione);
		const url_ = this.nations;
		return this.http.get<Nations>(url_);
	}
}
