<div class='modal-body'>
	<div class='main-div'>
		<h5>Seleziona il formato del barcode da generare nel documento PDF</h5>
		<div *ngIf='barcodes$ | async as barcodes'>

			<div *ngFor='let barcode of barcodes'>
				<div>
					<p class='text-badge'><img class='barcode' (click)='downloadBarcode(barcode)' src='data:image/png;base64,{{barcode.barCodeBytes}}'>{{barcode.description}}</p>
				</div>
			</div>

		</div>
	</div>
</div>
<div class='modal-footer'>
	<button type='button' class='btn btn-danger' (click)='modal.hide()'>Annulla</button>
</div>
