/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { SearchPagesHttpService } from 'app/core/business/search-pages/search-pages-http.service';
import { SearchFolderAndDocumentResult } from 'app/entities/folder/folder-response';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, shareReplay, switchMap, toArray } from 'rxjs/operators';
import { Pages, SectionsTilesEndpoint } from './pages';
import { Folder } from 'app/entities/folder/folder.interface';

@Injectable({
	providedIn: 'root',
})
export class SearchPagesService {

	private selectedFolderId = new BehaviorSubject('');
	currentSelectedFolderId = this.selectedFolderId.asObservable();

	private selectedFolder = new BehaviorSubject<Folder>(null);
	currentSelectedFolder = this.selectedFolder.asObservable();

	private resultSearchForGrid = new BehaviorSubject(Array<SearchFolderAndDocumentResult>());
	currentResultSearchForGrid = this.resultSearchForGrid.asObservable();

	private showResultSearch = new BehaviorSubject(false);
	currentShowResultSearch = this.showResultSearch.asObservable();

	private searchText = new BehaviorSubject('');
	currentSearchText = this.searchText.asObservable();

	private loadingFolderGrid = new BehaviorSubject(false);
	currentLoadingFolderGrid = this.loadingFolderGrid.asObservable();

	constructor(
		private companiesService: CompaniesService,
		private searchPageHttpService: SearchPagesHttpService,
	) { }

	loadStaticPagesList(staticPageUrl: string): Observable<Pages[]> {
		return this.searchPageHttpService.getStaticPages(staticPageUrl).pipe(
			shareReplay(({ bufferSize: 1, refCount: true })),
			switchMap(pages => from(pages).pipe(
				map(page => new Pages(
					page.name,
					`${page.link}`,
					page.homeFilter
				)),
				toArray(),
			))
		);
	}

	loadSectionsPagesList(areaCode: string): Observable<Pages[]> {
		return this.companiesService.whenCurrentCompany().pipe(
			switchMap(companyInfo => this.searchPageHttpService.getSections(companyInfo.licenseId, companyInfo.siaCode, areaCode).pipe(
				shareReplay(({ bufferSize: 1, refCount: true })),
				switchMap((sections) => {
					const tiles: SectionsTilesEndpoint[] = [];
					sections.forEach(section => {
						section.tiles.map(tile => tile.service = section.serviceName);
						tiles.push(...section.tiles);
					});
					return from(tiles).pipe(
						map(tile => new Pages(
							tile.name,
							tile.link,
							tile.homeFilter,
							tile.subtitle,
							null,
							tile.service
						)
						),
						toArray(),
					);
				})
			))
		);
	}

	getSelectedFolderId(): string {
		return this.selectedFolderId.value;
	}

	setSelectedFolderId(folderId: string) {
		this.selectedFolderId.next(folderId);
	}

	setSelectedFolder(folder: Folder) {
		this.selectedFolder.next(folder);
	}

	setResultSearchForGrid(resultSearchForGrid: SearchFolderAndDocumentResult[]) {
		this.resultSearchForGrid.next(resultSearchForGrid);
	}

	setShowResultSearch(showResultSearch: boolean) {
		this.showResultSearch.next(showResultSearch);
	}

	setSearchText(searchText: string) {
		this.searchText.next(searchText);
	}

	setLoadingFolderGrid(loadingFolderGrid: boolean) {
		this.loadingFolderGrid.next(loadingFolderGrid);
	}
}

