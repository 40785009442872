/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { CheckboxValidator } from 'app/shared/components/dry/validator/checkbox-validator';

@Directive({
	selector: '[isBoolean][ngModel]',
	providers: [
		{provide: NG_VALIDATORS, useExisting: IsBooleanValidatorDirective, multi: true}
	]
})
export class IsBooleanValidatorDirective implements Validator {

	private readonly validator: ValidatorFn;

	constructor() {
		this.validator = CheckboxValidator.isBoolean();
	}

	registerOnValidatorChange(fn: () => void): void {	}

	validate(c: AbstractControl): ValidationErrors | null {
		return this.validator(c);
	}
}
