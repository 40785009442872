export interface IDetailsComponent {
	docSeriesId: string;
	docHash: string;
	progSpool: string;
	progBusta: string;
	elasticId: string;
	licenseId: string;
	siaCode: string;
	section: string;
	keys: any;
}
