export interface PDVDetailsResponse {
	idPdv: string;
	metadati: Metadata[];
}

export interface Metadata {
	keyCode: string;
	value: string;
	description: string;
}
