export interface ReceivableCardsConfig {
	smallCardHeight: string;
	mediumCardHeight: string;
	largeCardHeight: string;
}

export interface PayableCardsConfig {
	serviceCardHeight: string;
	largeCardHeight: string;
}
