<form [formGroup]="parentForm">

		<div class="row">
			<div class="col-12">
				<div class="invoice-modal-subtitle">
					<p>{{field.sectionName}}</p>
				</div>
				<ng-container *ngFor="let input of field.fields">
					<div class="col-md-6">
						<div class="row">
							<div class="col-md-6">
								<label [attr.for]="input.id + field.modalButtonId">{{input.label}}</label>
							</div>
							<div class="col-md-6">
								<ng-container *ngIf="input.fieldType.selectList">
									<app-select-form-control id="{{input.id + field.modalButtonId}}"
															 name="{{input.id + field.modalButtonId}}"
															 navigable
															 formControlName="{{input.formControlname}}"
															 [values]="input.fieldType.selectList"
															 objectId="value"
															 objectValue="label">
									</app-select-form-control>
								</ng-container>
								<ng-container *ngIf="input.fieldType.text">
									<app-text-form-control
										id="{{input.id + field.modalButtonId}}"
										placeholder="{{input.label}}"
										name="{{input.id + field.modalButtonId}}"
										navigable formControlName="{{input.formControlname}}">
									</app-text-form-control>
								</ng-container>
								<ng-container *ngIf="input.fieldType.number">
									<app-number-form-control
										id="{{input.id + field.modalButtonId}}"
										placeholder="{{input.label}}"
										name="{{input.id + field.modalButtonId}}"
										navigable formControlName="{{input.formControlname}}"
										sanitize
										[decimal]="input.fieldType.number.decimals"
									>
									</app-number-form-control>
								</ng-container>
								<ng-container *ngIf="input.fieldType.date">
									<app-date-form-control
										id="{{input.id + field.modalButtonId}}"
										placeholder="{{input.label}}"
										name="{{input.id + field.modalButtonId}}"
										navigable formControlName="{{input.formControlname}}"
									>
									</app-date-form-control>
								</ng-container>
							</div>
						</div>
					</div>
				</ng-container>

			</div>
		</div>
</form>
