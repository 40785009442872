<ng-container *ngIf="workflowsByDocSeries$ | async as workflowsByDocSeries">
  <div class="d-flex flex-column gap-2">
    <ng-container *ngFor="let workflow of workflowsByDocSeries">
      <button class="btn btn-lg btn-info" type="button" (click)="openWorkflowModal(workflow)">
        <ng-container *ngIf="workflow.description !== null && workflow.description !== ''; else noDescription">
          <b>{{ workflow.description }}</b>
        </ng-container>
        <ng-template #noDescription>
          <b>{{ workflow.name }}</b>
        </ng-template>
      </button>
    </ng-container>
    <ng-container *ngIf="workflowsByDocSeries.length === 0">
      <div class="no-wf-message">
        <b>Nessun workflow disponibile per questa serie documentale.</b>
      </div>
    </ng-container>
  </div>
</ng-container>
