import { DisplayType, Filter, Metric } from '@ctel/gaw-commons';
import { IDocument } from '@ctel/search-filter-store';
import { Action } from './actions/action';

export interface DocumentsResponse {
	docs: Document[];
	filters: Filter[];
	metrics: Metric[];
	totalDocs: number;
	success: boolean;
	statusCode: number;
	error?: Error;
}

export interface ReducedDocumentResponse {
	metrics: Metric[];
	totalDocs: number;
}

export interface Document extends IDocument {
	display: Display[];
	fullText: FullText;
	docSeriesDescription: string;
	keys: object;
	actions: Action[];
	documentId: string;
	tags?: string[];
	grey?: boolean;
	stato?: string;
	hidden?: boolean;
}

export interface Display {
	type: DisplayType;
	value: string;
	metadata: string;
	metadataDescription: string;
	highlights: string[];
	format?: string;
}

export interface FullText {
	metadataDescription: string;
	highlights: string[];
}
