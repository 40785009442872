<div *ngFor="let linkSection of linkSectionsCopy" class="docs-toc-container">

	<nav [attr.aria-label]="linkSection.name + ' Table of Contents'" class="nav d-flex flex-column">
		<div *ngFor="let link of linkSection.links; let i = index" class="nav-item">
			<a (click)="navigateTo(link)" class="docs-level-{{link.type}} docs-link" id="nav-link-{{i}}"
				 (mouseover)="changeBorder(i, true)" (mouseout)="changeBorder(i, false)"
				 [ngClass]="{ 'blue-border': (blueBorder && selectedIndex === i), 'grey-border': !blueBorder || selectedIndex !== i }"
				 [class.docs-active]="link.active">
				{{link.name}}
			</a>
		</div>
	</nav>
</div>
