import * as moment from 'moment';
import { isMoment, Moment } from 'moment';

export class DateRangeFilter {
	private readonly _start: Moment;
	private readonly _end: Moment;

	constructor(start: string | Date | Moment, end: string | Date | Moment) {
		this._start = isMoment(start) ? start : moment(start);
		this._end = isMoment(end) ? end : moment(end);
	}

	public get start(): Moment {
		return this._start;
	}

	public get end(): Moment {
		return this._end;
	}
}
