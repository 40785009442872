import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AutoLoginGuard {

	constructor(
		private readonly authService: AuthService,
		private router: Router,
		private autoLoginPartial: AutoLoginPartialRoutesGuard
	) { }

	public canActivate(
		next: ActivatedRouteSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		/** Richiamiamo saveActualPath per assicurare il redirect all'url
				 * corretto post-login, altrimenti la AutoLoginPartialRoutesGuard
				 * redirecta sempre alla home.
				 */
		return this.authService.isAuthorized().pipe(
			tap(() => this.authService.saveActualPath(next.queryParams)),
			map((isAuth) => {
				if (!isAuth) {
					const path = this.authService.defaultRoutePath.replace(/\?.*/, '');
					this.router.navigate([path], { queryParams: next.queryParams });
					return false;
				} else
					return true;
			})
		);
	}

}

export const canActivateAutoLogin = (route: ActivatedRouteSnapshot) =>
	inject(AutoLoginGuard).canActivate(route);
