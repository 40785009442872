<ng-container *hubError="lastLoadingFailed; loading: (loading$ | async); options:errorOptions">
	<div class="block-ui-placeholder height-100" *blockUI="'document-content'; template: blockTemplate">
		<div class="content-container">
			<ng-container *ngIf="!isInternetExplorer; else internetExplorerViewer">
				<ng-container [ngSwitch]="displayMode">
					<ng-container *ngSwitchCase="pdfDisplayMode.IFRAME">
						<iframe class="content-iframe" [attr.src]="safeContentUrl" [width]="width" [height]="height"></iframe>
					</ng-container>

					<ng-container *ngSwitchCase="pdfDisplayMode.EMBED">
						<embed [attr.src]="safeContentUrl" [type]="mimeType" [width]="width" [height]="height">
					</ng-container>

					<ng-container *ngSwitchCase="pdfDisplayMode.OBJECT">
						<object [attr.data]="safeContentUrl" [type]="mimeType" [width]="width" [height]="height"></object>
					</ng-container>

					<ng-container *ngSwitchCase="pdfDisplayMode.PDF_VIEWER">
						<ng-container *ngTemplateOutlet="internetExplorerViewer"></ng-container>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
		<ng-template #internetExplorerViewer>
			<div class="row">
				<div class="col-md-6 buttons-container zoom-buttons-container">
					<div class="btn-group zoom-buttons" role="group">
						<button #decZoomButton class="btn btn-primary" tabindex="0" [disabled]="zoom <= minZoom"
										(click)="decreaseZoom(decZoomButton)">
							<i [ngClass]="faIcons.FAS_SEARCH_MINUS"></i> Riduci
						</button>
						<button #incZoomButton class="btn btn-primary" tabindex="1" [disabled]="zoom >= maxZoom"
										(click)="increaseZoom(incZoomButton)">
							<i [ngClass]="faIcons.FAS_SEARCH_PLUS"></i>
							Ingrandisci
						</button>
					</div>
					<div *ngIf="zoom !== initialZoom" class="status-label">
						{{zoom * 100 | number:'2.0-0'}}%
						<span class="reset-zoom" role="button" tabindex="2" (click)="resetZoom()"> reset</span>
					</div>
				</div>
				<div class="col-md-6 buttons-container page-buttons-container">
					<div class="status-label">
						Pagina {{page}}/{{lastPage}}
					</div>
					<div class="btn-group buttons" role="group">
						<button #firstPageButton class="btn btn-primary" tabindex="1" [disabled]="page === firstPage"
										(click)="goToFirstPage(firstPageButton)">
							{{firstPage}}
						</button>
						<button #bigDecPageButton class="btn btn-primary" tabindex="0" [disabled]="page === firstPage"
										[hidden]="lastPage < firstPage + bigPageGap" (click)="decreasePage(bigPageGap, bigDecPageButton)">
							<i [ngClass]="faIcons.FAS_ANGLE_LEFT"></i>
							<i [ngClass]="faIcons.FAS_ANGLE_LEFT"></i>
						</button>
						<button #decPageButton class="btn btn-primary" tabindex="0" [disabled]="page === firstPage"
										(click)="decreasePage(smallPageGap, decPageButton)">
							<i [ngClass]="faIcons.FAS_ANGLE_LEFT"></i>
						</button>
						<button #incPageButton class="btn btn-primary" tabindex="1" [disabled]="page === lastPage"
										[hidden]="lastPage < firstPage + bigPageGap" (click)="increasePage(smallPageGap, incPageButton)">
							<i [ngClass]="faIcons.FAS_ANGLE_RIGHT"></i>
						</button>
						<button #bigIncPageButton class="btn btn-primary" tabindex="1" [disabled]="page === lastPage"
										(click)="increasePage(bigPageGap, bigIncPageButton)">
							<i [ngClass]="faIcons.FAS_ANGLE_RIGHT"></i>
							<i [ngClass]="faIcons.FAS_ANGLE_RIGHT"></i>
						</button>
						<button #lastPageButton class="btn btn-primary" tabindex="1" [disabled]="page === lastPage"
										(click)="goToLastPage(lastPageButton)">
							{{lastPage}}
						</button>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="pdf-viewer-container">
					<pdf-viewer [src]="documentContent$ | async" [zoom]="zoom" [render-text]="false" [show-all]="showAll"
											[stick-to-page]="stickToPage" [page]="page" (after-load-complete)="afterLoad($event)">
					</pdf-viewer>
				</div>
			</div>
		</ng-template>
	</div>
</ng-container>
