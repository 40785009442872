import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable, ReplaySubject, of, takeUntil } from 'rxjs';
import { PDDRequestModel } from '../entities/PDD-request-model';
import { PDDResponseModel } from '../entities/PDD-response-model';
import { StatusCheckResponseModel } from '../entities/status-check-response-model';
import mockActions from './mock-actions-pdd.json';

@Injectable({
	providedIn: 'root'
})
export class PacchettiDiDistribuzioneHttpService implements OnDestroy {
	private pacchettiDiDistribuzioneHost: string;
	private jsonContentType = 'application/json';
	private validaDocumentiMultiploUrl: string;
	private validaDocumentiMassivoUrl: string;
	private richiestaPddMultiplaUrl: string;
	private verificaStatoDocuemntiUrl: string;
	private richiestaPddMassivaUrl: string;

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private http: HttpClient,
		private configService: ConfigService
	) {
		this.configService.whenAppConfig().pipe(takeUntil(this.destroy$))
			.subscribe((appconfig: AppConfig) => this.httpHostInit(appconfig));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	httpHostInit(appconfig: AppConfig) {
		this.pacchettiDiDistribuzioneHost = appconfig.webConsOrchestrator.http.host;
		this.validaDocumentiMultiploUrl = `${this.pacchettiDiDistribuzioneHost}/v1/ArchivioFiscale/validadocumenti/multiplo`;
		this.validaDocumentiMassivoUrl = `${this.pacchettiDiDistribuzioneHost}/v1/ArchivioFiscale/validadocumenti/massivo`;
		this.richiestaPddMultiplaUrl = `${this.pacchettiDiDistribuzioneHost}/v1/ArchivioFiscale/richiesta/multipla`;
		this.richiestaPddMassivaUrl = `${this.pacchettiDiDistribuzioneHost}/v1/ArchivioFiscale/richiesta/massiva`;
		this.verificaStatoDocuemntiUrl = `${this.pacchettiDiDistribuzioneHost}/v1/ArchivioFiscale/verifica-stato-documenti/massivo`;
	}

	validaDocumentiMultiplo(pddRequestModel: PDDRequestModel): Observable<string> {
		const body = {
			idLicenza: pddRequestModel.idLicenza,
			codSia: pddRequestModel.codSia,
			annoFiscale: +pddRequestModel.annoFiscale,
			idRegistro: +pddRequestModel.idRegistro,
			documentKeys: pddRequestModel.documentForPdds
		};
		const options = this.getHttpOptions(this.jsonContentType, 'text');
		return this.http.post<string>(this.validaDocumentiMultiploUrl, body, options);
	}

	validaDocumentiMassivo(body: string): Observable<string> {
		const options = this.getHttpOptions(this.jsonContentType, 'text');
		return this.http.post<string>(this.validaDocumentiMassivoUrl, body, options);

	}

	richiestaPddMultipla(pddModel: PDDRequestModel): Observable<PDDResponseModel> {
		const options = this.getHttpOptions(this.jsonContentType, 'json', true);
		return this.http.post<PDDResponseModel>(this.richiestaPddMultiplaUrl, pddModel, options);
	}

	richiestaPddMassiva(body: {
		searchRequest: any; idRegistro: string; annoFiscale?: string; flagAttestato?: boolean;
		flagIPDA?: boolean; flagRDV?: boolean; flagSuperimpronte?: boolean; flagComunicazioniAde?: boolean; flagRicevuteAde?: boolean
	}): Observable<PDDResponseModel> {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		return this.http.post<PDDResponseModel>(this.richiestaPddMassivaUrl, body, options);
	}

	verificaStatoDocumenti(body: string): Observable<StatusCheckResponseModel> {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		return this.http.post<StatusCheckResponseModel>(this.verificaStatoDocuemntiUrl, body, options);
	}

	getActions() {
		return of(mockActions);
	}

	private getHttpOptions(contentType: string, responseType: string, log = false): object {
		let headers = new HttpHeaders().set('Content-Type', contentType);

		if (log) headers = headers.set('log', 'true');

		let options;
		switch (responseType) {
			case 'blob':
				options = {
					headers,
					responseType: 'blob' as const
				};
				break;
			case 'text':
				options = {
					headers,
					responseType: 'text' as 'json'
				};
				break;
			default:
				options = {
					headers,
					responseType: 'json' as const
				};
				break;
		}

		return options;
	}
}
