/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { SectionCode } from 'app/entities/ui-config/classification-code.enum';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { DocumentDetails } from '../../entities/documents/details/document-details';
import { ServicesAndColors } from '../../entities/documents/details/services-and-colors';
import { StatusHistory } from '../../entities/documents/details/status-history-response';
import { TagsUpdateRequest, TagsValue } from '../../entities/documents/details/tags-update-request';
import { DocumentDetailsHttpService } from './document-details-http.service';

/**
 * Servizio che gestisce lo stato dei dettagli del documento (elenco metadati)
 */
@Injectable({
	providedIn: 'root'
})
export class DocumentDetailsService {

	private documentDetails$ = new ReplaySubject<DocumentDetails>(1);
	private isDocSigned$ = new ReplaySubject<boolean>(1);
	private statusHistory$ = new ReplaySubject<StatusHistory[]>(1);
	private pdfResult$ = new ReplaySubject<SafeResourceUrl>(1);
	private xmlResult$ = new ReplaySubject<SafeResourceUrl>(1);
	private documentPecResult$ = new ReplaySubject<any>(1);
	private documentPostResult$ = new ReplaySubject<any>(1);
	private messagePecResult$ = new ReplaySubject<any>(1);
	private documentMailResult$ = new ReplaySubject<any>(1);
	private messageMailResult$ = new ReplaySubject<any>(1);
	private tags$ = new ReplaySubject<string[]>(1);
	private serviceName$ = new ReplaySubject<string>(1);
	private progSpoolAndBusta$ = new ReplaySubject<string[]>(1);
	private keys$ = new BehaviorSubject<object>(null);
	private documentListUrl$ = new BehaviorSubject<string>(null);
	private docMetadataError$ = new BehaviorSubject<boolean>(false);
	private statusHistoryError$ = new BehaviorSubject<boolean>(false);
	private refresh$: BehaviorSubject<any> = new BehaviorSubject(0);

	constructor(
		private documentDetailsHttpService: DocumentDetailsHttpService,
		private domSanitizer: DomSanitizer,
	) { }

	// elenco metadati documento
	whenDocumentDetails(licenseId: string, siaCode: string, elasticId: string, includeTechnical: boolean): Observable<DocumentDetails> {

		return this.documentDetailsHttpService.whenDocumentDetails(licenseId, siaCode, elasticId, includeTechnical)
			.pipe(
				catchError((err: unknown) => {
					if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
						//this.router.navigate(['/unauthorized']).then();
					} else
						this.sendMetadataError(true);

					return EMPTY;
				}),
				tap(details => {
					this.sendMetadataError(false);
					this.sendKeys(details.keys);
				})
			);
	}

	// dopo un'azione dispositiva nel dettaglio, serve aggiornamento dei details di magellano per avere le azioni aggiornate
	sendRefreshDocumentDetails() {
		this.refresh$.next(this.refresh$.getValue() + 1);
	}

	resetRefreshDocumentDetails() {
		this.refresh$.next(0);
	}

	whenRefreshDocumentDetails(): Observable<number> {
		return this.refresh$.asObservable();
	}

	sendMetadataError(value: boolean) {
		this.docMetadataError$.next(value);
	}

	whenMetadataError(): Observable<boolean> {
		return this.docMetadataError$.asObservable();
	}

	sendDocumentDetails(details: DocumentDetails) {
		this.documentDetails$.next(details);
	}

	// se il documento è firmato
	whenIsDocumentSigned(progSpool: string, progBusta: string, docHash: string): Observable<boolean> {
		return this.documentDetailsHttpService.whenIsDocSigned(progSpool, progBusta, docHash)
			.pipe(
				catchError((err: unknown) => {
					if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND)
						return EMPTY;

					if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED)
						//this.router.navigate(['/unauthorized']).then();
						return EMPTY;

				}),
				tap(isDocSigned => {
					this.isDocSigned$.next(isDocSigned);
				})
			);
	}

	sendIsDocumentSigned(isDocSigned: boolean) {
		this.isDocSigned$.next(isDocSigned);
	}

	// elenco canali ciclo attivo per un documento e colori ultimo stato
	whenReceivableServicesAndColor(progSpool: string, progBusta: string, docHash: string): Observable<ServicesAndColors[]> {
		return this.documentDetailsHttpService.whenReceivableServicesAndColor(progSpool, progBusta, docHash)
			.pipe(
				catchError((err: unknown) => {
					// if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND) {
					// 	return of(null);
					// }
					if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED)
						//this.router.navigate(['/unauthorized']).then();
						return of(null);

					this.sendMetadataError(true);
					return EMPTY;
				}),
				map(value => value.channels),
				tap(serviceList => {
					if (serviceList.length > 0) {
						this.sendMetadataError(false);
						this.sendCurrentService(serviceList[0].channelName.toUpperCase());
					}
				})
			);
	}

	// elenco canali ciclo passivo per un documento e colori ultimo stato
	whenPayableServicesAndColor(progSpool: string, progBusta: string, docHash: string): Observable<ServicesAndColors[]> {
		return this.documentDetailsHttpService.whenPayableServicesAndColor(progSpool, progBusta, docHash)
			.pipe(
				catchError(() => {
					this.sendMetadataError(true);
					return of([]);
				}),
				tap(serviceList => {
					if (serviceList.length > 0) {
						this.sendMetadataError(false);
						this.sendCurrentService(serviceList[0].channelName.toUpperCase());
					}
				})
			);
	}

	// prog spool e prog busta
	whenCurrentProgSpoolAndBustaService(): Observable<string[]> {
		return this.progSpoolAndBusta$.asObservable();
	}

	sendCurrentProgSpoolAndBustaService(values: string[]) {
		this.progSpoolAndBusta$.next(values);
	}

	// canale selezionato nel dettaglio (button cliccato o primo di default al caricamento pagina)
	whenCurrentService(): Observable<string> {
		return this.serviceName$.asObservable();
	}

	sendCurrentService(serviceName: string) {
		this.serviceName$.next(serviceName);
	}

	// history degli stati per canale
	whenStatusHistory(
		serviceName: string, account: string, progSpool: string, progBusta: string, docHash: string
	): Observable<StatusHistory[]> {
		const accountType = account === SectionCode.RECEIVABLE ? 'ATTIVO' : 'PASSIVO';
		this.documentDetailsHttpService.whenStatusHistory(serviceName, accountType, progSpool, progBusta, docHash).pipe(
			catchError(() => {
				this.sendStatusHistoryError(true);
				return EMPTY;
			})
		)
			.subscribe(
				statusHistory => {
					this.sendStatusHistoryError(false);
					this.sendStatusHistory(statusHistory);
				}
			);
		return this.statusHistory$.asObservable();
	}

	sendStatusHistory(statusHistory: StatusHistory[]) {
		this.statusHistory$.next(statusHistory);
	}

	public sendStatusHistoryError(value: boolean) {
		this.statusHistoryError$.next(value);
	}

	public whenStatusHistoryError(): Observable<boolean> {
		return this.statusHistoryError$.asObservable();
	}

	// pdf generico premulticanalità
	// whenGenericPdf(progSpool: string, progBusta: string, docHash: string, download: boolean): Observable<any> {
	// 	this.documentDetailsHttpService.whenPdfGeneric(progSpool, progBusta, docHash, download)
	// 		.subscribe(result => {
	// 				if (result.hasOwnProperty('message')) {
	// 					return;
	// 				}
	// 				const blob = new Blob([result], { type: 'application/pdf' });
	// 				this.sendGenericPdf(this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)));
	// 			}
	// 		);
	// 	return this.pdfGeneric$.asObservable();
	// }
	//
	// sendGenericPdf(safeUrl: SafeResourceUrl) {
	// 	this.pdfGeneric$.next(safeUrl);
	// }

	// pdf visualizza/download attivo
	whenShowPdfReceivable(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean, docType: string
	): Observable<any> {
		this.documentDetailsHttpService.whenDisplayDocumentReceivable(serviceName, progSpool, progBusta, docHash, download, docType)
			.subscribe(result => {

				let arrayBuffer;
				let uint8Array: Uint8Array;
				const blob = new Blob([result], { type: 'application/pdf' });
				const fileReader = new FileReader();
				const current = this;
				fileReader.onload = function () {
					arrayBuffer = this.result;
					uint8Array = new Uint8Array(arrayBuffer);
					current.sendPdfResultReceivable(arrayBuffer);
				};
				fileReader.readAsArrayBuffer(blob);

			}
			);
		return this.pdfResult$.asObservable();
	}

	sendPdfResultReceivable(safeUrl: SafeResourceUrl) {
		this.pdfResult$.next(safeUrl);
	}

	// pdf visualizza/download passivo
	whenShowPdfPayable(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean, docType: string
	): Observable<any> {
		this.documentDetailsHttpService.whenDisplayDocumentPayable(serviceName, progSpool, progBusta, docHash, download, docType)
			.subscribe(result => {

				let arrayBuffer;
				let uint8Array: Uint8Array;
				const blob = new Blob([result], { type: 'application/pdf' });
				const fileReader = new FileReader();
				const current = this;
				fileReader.onload = function () {
					arrayBuffer = this.result;
					uint8Array = new Uint8Array(arrayBuffer);
					current.sendPdfResultReceivable(arrayBuffer);
				};
				fileReader.readAsArrayBuffer(blob);
			}
			);
		return this.pdfResult$.asObservable();
	}

	sendPdfResultPayable(safeUrl: SafeResourceUrl) {
		this.pdfResult$.next(safeUrl);
	}

	// xml visualizza/download (solo per canale gedinvoice) attivo
	whenXmlResultReceivable(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean
	): Observable<any> {
		this.documentDetailsHttpService.whenDisplayDocumentReceivableXml(serviceName, progSpool, progBusta, docHash, download)
			.subscribe(result => this.sendXmlResultReceivable(result));
		return this.xmlResult$.asObservable();
	}

	sendXmlResultReceivable(safeUrl: SafeResourceUrl) {
		this.xmlResult$.next(safeUrl);
	}

	// xml visualizza/download (solo per canale gedinvoice) passivo
	whenXmlResultReceivablePayable(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean
	): Observable<any> {
		this.documentDetailsHttpService.whenDisplayDocumentPayableXml(serviceName, progSpool, progBusta, docHash, download)
			.subscribe(result => this.sendXmlResultPayable(result));
		return this.xmlResult$.asObservable();
	}

	sendXmlResultPayable(safeUrl: SafeResourceUrl) {
		this.xmlResult$.next(safeUrl);
	}

	// pdf documento per post
	whenDocumentPost(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean, docType: string
	): Observable<any> {
		this.documentDetailsHttpService.whenDisplayDocumentReceivable(serviceName, progSpool, progBusta, docHash, download, docType)
			.subscribe(result => {
				const blob = new Blob([result], { type: 'application/pdf' });
				this.sendDocumentPost(this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)));
			}
			);
		return this.documentPostResult$.asObservable();
	}

	sendDocumentPost(safeUrl: any) {
		this.documentPostResult$.next(safeUrl);
	}

	// pdf documento pec
	whenDocumentPec(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean, docType: string
	): Observable<any> {
		this.documentDetailsHttpService.whenDisplayDocumentReceivable(serviceName, progSpool, progBusta, docHash, download, docType)
			.subscribe(result => {
				const blob = new Blob([result], { type: 'application/pdf' });
				this.sendDocumentPec(this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)));
			}
			);
		return this.documentPecResult$.asObservable();
	}

	sendDocumentPec(safeUrl: any) {
		this.documentPecResult$.next(safeUrl);
	}

	// pdf messaggio pec
	whenMessagePec(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean, docType: string
	): Observable<any> {
		this.documentDetailsHttpService.whenDisplayDocumentReceivable(serviceName, progSpool, progBusta, docHash, download, docType)
			.subscribe(result => {
				const blob = new Blob([result], { type: 'application/pdf' });
				this.sendMessagePec(this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)));
			}
			);
		return this.messagePecResult$.asObservable();
	}

	sendMessagePec(value: any) {
		this.messagePecResult$.next(value);
	}

	// pdf documento mail
	whenDocumentMail(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean, docType: string
	): Observable<any> {
		this.documentDetailsHttpService.whenDisplayDocumentReceivable(serviceName, progSpool, progBusta, docHash, download, docType)
			.subscribe(result => {
				const blob = new Blob([result], { type: 'application/pdf' });
				this.sendDocumentMail(this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)));
			}
			);
		return this.documentMailResult$.asObservable();
	}

	sendDocumentMail(safeUrl: any) {
		this.documentMailResult$.next(safeUrl);
	}

	// pdf messaggio mail
	whenMessageMail(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean, docType: string
	): Observable<any> {
		this.documentDetailsHttpService.whenDisplayDocumentReceivable(serviceName, progSpool, progBusta, docHash, download, docType)
			.subscribe(result => {
				const blob = new Blob([result], { type: 'application/pdf' });
				this.sendMessageMail(this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)));
			}
			);
		return this.messageMailResult$.asObservable();
	}

	sendMessageMail(value: any) {
		this.messageMailResult$.next(value);
	}

	// keys prese dai dettagli documento
	getKeys(): object {
		return this.keys$.value;
	}

	sendKeys(keys: object) {
		this.keys$.next(keys);
	}

	// salvo la url della lista documenti da cui vengo (per edit fattura)
	getDocumentListUrl(): string {
		return this.documentListUrl$.value;
	}

	whenDocumentListUrl(): Observable<string> {
		return this.documentListUrl$.asObservable();
	}

	sendDocumentListUrl(url: string) {
		this.documentListUrl$.next(url);
	}

	resetDocumentListUrl() {
		this.documentListUrl$.next(null);
	}

	// tags del documento (condivisi con gaw30)
	whenTags(): Observable<string[]> {
		return this.tags$.asObservable();
	}

	sendTags(tags: string[]) {
		this.tags$.next(
			tags.filter(function (elem, index, self) {
				return index === self.indexOf(elem);
			})
		);
		const payload = this.buildTagsUpdateRequest(tags);
		this.documentDetailsHttpService.whenTagsUpdate(JSON.stringify(payload))
			.subscribe();
	}

	resetTags() {
		this.tags$.next([]);
	}

	buildTagsUpdateRequest(tags: string[]): TagsUpdateRequest {
		const tagsValue: TagsValue = {
			value: tags,
			keyCode: MetadataEnum.GAW30_TAGS
		};
		return {
			keys: this.getKeys(),
			metadataList: [tagsValue]
		};
	}

	/*
	 * Nuovi metodi leggeri temporanei per visualizzazione contenuto, rispecchiano fedelmente quelli già presenti
	 */
	whenPdfReceivable(
		serviceName: string,
		progSpool: string,
		progBusta: string,
		docHash: string,
		download: boolean,
		docType: string
	): Observable<Blob> {
		return this.documentDetailsHttpService.whenDisplayDocumentReceivable(serviceName, progSpool, progBusta, docHash, download, docType);
	}

	whenXmlReceivable(
		serviceName: string,
		progSpool: string,
		progBusta: string,
		docHash: string,
		download: boolean
	): Observable<string> {
		return this.documentDetailsHttpService.whenDisplayDocumentReceivableXml(serviceName, progSpool, progBusta, docHash, download);
	}

	whenPdfPayable(
		serviceName: string,
		progSpool: string,
		progBusta: string,
		docHash: string,
		download: boolean,
		docType: string
	): Observable<Blob> {
		return this.documentDetailsHttpService.whenDisplayDocumentPayable(serviceName, progSpool, progBusta, docHash, download, docType);
	}

	whenXmlPayable(
		serviceName: string,
		progSpool: string,
		progBusta: string,
		docHash: string,
		download: boolean
	): Observable<string> {
		return this.documentDetailsHttpService.whenDisplayDocumentPayableXml(serviceName, progSpool, progBusta, docHash, download);
	}

	whenXml(
		progSpool: string,
		progBusta: string,
		docHash: string,
		allegatoType: number,
		download: boolean
	): Observable<any> {
		return this.documentDetailsHttpService.whenDisplayDocumentGeneric(progSpool, progBusta, docHash, download, allegatoType);
	}
}
