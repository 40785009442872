<form novalidate (ngSubmit)="onFormSubmit(supplierForm)" #supplierForm="ngForm">
  <div class="modal-body">
    <div class="main-div">
      <div class="inner-div">
        <fieldset>
          <div>
            <label class="form-label" for="ragione-sociale">
              <strong>Ragione sociale</strong>
              <span *ngIf="isRequiredRagioneSociale" class="error">*</span>
            </label>
            <input
              type="text"
              id="ragione-sociale"
              name="ragioneSociale"
              class="form-control"
              [ngModel]="data?.ragioneSociale"
              ngModel
            />
          </div>
        </fieldset>
        <fieldset>
          <div>
            <label class="form-label" for="piva">
              <strong>Partita IVA</strong>
              <span *ngIf="isErrorPiva || isRequiredPivaOrCf" class="error">*</span>
            </label>
            <input type="text" id="piva" name="piva" class="form-control" [ngModel]="data?.partitaIva" ngModel />
          </div>
        </fieldset>
        <fieldset>
          <div>
            <label class="form-label" for="cf">
              <strong>Codice fiscale</strong>
              <span *ngIf="isErrorCf || isRequiredPivaOrCf" class="error">*</span>
            </label>
            <input type="text" id="cf" name="cf" class="form-control" [ngModel]="data?.codiceFiscale" ngModel />
          </div>
        </fieldset>

        <!-- errori -->
        <div *ngIf="isRequiredRagioneSociale" class="error-msg">
          <b>*La ragione sociale è obbligatoria</b>
        </div>
        <div *ngIf="isRequiredPivaOrCf" class="error-msg">
          <b>*Partita IVA o codice fiscale obbligatorio</b>
        </div>
        <div *ngIf="isErrorPiva" class="error-msg">
          <b>*Formato partita IVA non valido</b>
        </div>
        <div *ngIf="isErrorCf" class="error-msg">
          <b>*Formato codice fiscale non valido</b>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" id="regime-minimi-confirm-button" [disabled]="!supplierForm.dirty">
      <b>Conferma</b>
    </button>
    <button type="button" class="btn btn-danger" (click)="modal.hide()">Annulla</button>
  </div>
</form>
