import { Injectable, OnDestroy } from '@angular/core';
import { OrderBy } from '@ctel/gaw-commons';
import { FilterActions, FilterState, getOrderByFiltersWithUserValuesForSearch } from '@ctel/search-filter-store';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { FilterService } from '../filters/filter.service';
import { Order } from './order';

/**
 * Servizio che gestisce lo stato dell'order by dei documenti
 * e dei documenti visualizzati per pagina (al momento non utilizzati perchè sono fissi a 25)
 */
@Injectable({
	providedIn: 'root'
})
export class OrderByService implements OnDestroy {

	private destroy$ = new Subject<void>();
	private readonly orderBy$: Observable<OrderBy[]>;
	private orderByArray: OrderBy[];

	constructor(protected filterService: FilterService, private store: Store<FilterState>) {
		this.orderBy$ = this.store.pipe(select(getOrderByFiltersWithUserValuesForSearch));
		this.orderBy$.pipe(
			tap(value => {
				this.orderByArray = value;
			}),
			takeUntil(this.destroy$)
		).subscribe();
	}

	whenMetadataOrder(metadata: string): Observable<Order> {
		return this.whenOrderByValues().pipe(
			map(orderByArray => orderByArray.find(el => el.metadata === metadata)),
			map(foundOrderBy => {
				if (foundOrderBy) {
					if (foundOrderBy.order === 'asc')
						return Order.ASCENDING;

					if (foundOrderBy.order === 'desc')
						return Order.DESCENDING;

				} else
					return Order.NOT_SET;

			})
		);
	}

	createOrderByValues(metadata: string, initialOrder: Order = Order.ASCENDING) {
		const newValue = this.getNewOrderByValue(metadata, initialOrder);
		this.store.dispatch(FilterActions.changeFilterRequested({
			kind: FilterActions.ChangeFilterRequestedKind.OrderByChanged,
			metadata: newValue.metadata,
			orderByOrder: newValue.order
		}));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private getOrderByValues(): OrderBy[] {
		return this.orderByArray;
	}

	private whenOrderByValues(): Observable<OrderBy[]> {
		return this.orderBy$;
	}

	private getNewOrderByValue(metadata: string, initialOrder: Order) {
		const defaultOrder = Order.ASCENDING;
		let newValue: OrderBy;

		if (initialOrder === Order.NOT_SET)
			initialOrder = defaultOrder;

		// se non ci sono ordinamenti impostati nei filtri lo assegna
		if (this.getOrderByValues().length === 0)
			newValue = {
				metadata,
				order: initialOrder
			};
		else
			// se c'è un ordinamento per lo stesso metadato, inverte asc/desc
			if (this.getOrderByValues()[0].metadata === metadata)
				switch (this.getOrderByValues()[0].order) {
					case 'asc': {
						newValue = {
							metadata,
							order: 'desc'
						};
						break;
					}
					case 'desc': {
						newValue = {
							metadata,
							order: 'asc'
						};
						break;
					}
				}
			else
				// se l'ordinamento è per un metadato diverso, lo sostituisco a quello esistente
				newValue = {
					metadata,
					order: 'asc'
				};

		return newValue;
	}
}
