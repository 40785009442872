import { Injectable } from '@angular/core';
import { ActionsConfigRequest, ConfigurationsGroup } from 'app/entities/ui-config/action/actions-configuration';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ActionsConfigurationHttpService } from './actions-configuration-http.service';

@Injectable({
	providedIn: 'root'
})
export class ActionsConfigurationService {

	constructor(public actionsConfigurationHttpService: ActionsConfigurationHttpService) { }
	public getSingleActionsByDocSeries(
		licenseId: string, siaCode: string, sectionCode: string, body: ActionsConfigRequest
	): Observable<ConfigurationsGroup[]> {
		return this.actionsConfigurationHttpService.getSingleActions(licenseId, siaCode, sectionCode, body)
			.pipe(
				take(1),
				map(response => response.configurationsGroup)
			);
	}
	public getMultipleActionsByDocSeries(
		licenseId: string, siaCode: string, sectionCode: string, body: ActionsConfigRequest[]
	): Observable<ConfigurationsGroup[]> {
		return this.actionsConfigurationHttpService.getMultipleActions(licenseId, siaCode, sectionCode, body)
			.pipe(
				take(1),
				map(response => response.configurationsGroup)
			);
	}

	public getMassiveActionsByDocSeries(licenseId: string, siaCode: string, sectionCode: string): Observable<ConfigurationsGroup[]> {
		// TODO rimuovere definitivamente 'gaw30_docSeries'. Al momento usato solo nella ALL per multiple e massive per poterle configurare
		// liberamente, sebbene solo da swagger. Per le singole nella ALL, l'id serie doc serve per reperire la sezione di appartenenza,
		// quindi è possibile passare ALL all'endpoint.
		sectionCode = sectionCode === 'ALL' ? 'gaw30_docSeries' : sectionCode;

		return this.actionsConfigurationHttpService.getMassiveActions(licenseId, siaCode, sectionCode)
			.pipe(
				take(1),
				map(response => response.configurationsGroup)
			);
	}

}
