import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Utilità di lettura e conversione file e contenuti
 */
export class DryFileReader {

	/**
	 * Leggi un blob tramite FileReader e produci un ArrayBuffer asincrono
	 * @param blob contenuto da leggere
	 */
	public asArrayBuffer(blob: Blob): Observable<ArrayBuffer> {

		const result$ = new ReplaySubject<ArrayBuffer>(1);
		const fileReader = new FileReader();

		// Quando il file è stato correttamente letto, carico il contenuto nel subject
		fileReader.onload = function () {
			result$.next(this.result as ArrayBuffer);
		};

		// Riga che attualmente legge il blob e lo trasforma in un arraybuffer
		fileReader.readAsArrayBuffer(blob);

		return result$.asObservable();
	}

	/**
	 * Leggi un blob tramite FileReader e produci un Uint8Array asincrono
	 * @param blob contenuto da leggere
	 */
	public asUint8Array(blob: Blob): Observable<Uint8Array> {
		return this.asArrayBuffer(blob).pipe(
			map(arrayBuffer => new Uint8Array(arrayBuffer))
		);
	}

}
