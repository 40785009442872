import { Component, Input } from '@angular/core';
import { PageContent } from 'app/core/common/utilities/contents/page-content';

@Component({
	selector: 'dry-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {

	@Input() pageContent: PageContent;

}
