import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { DocumentsResponse } from '../entities/documents/documents-response';
import { Observable } from 'rxjs';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { SearchBarTypeahead } from 'app/entities/search-bar/search-bar-typeahead';
import { DocSeriesMetadataDesc, DocSeriesMetadataDescSearch } from '@ctel/gaw-commons';

/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative ai documenti
 */
@Injectable({
	providedIn: 'root'
})
export class DocumentsHttpService {

	private jsonContentType = 'application/json';
	// host
	private searchHost: string;
	private docSeriesHost: string;
	// endpoints
	private search: string;
	private docSeries: string;
	private graphic: string;
	private metadataDesc: string;
	private typeAhead: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
		private fullScreenSpinnerService: FullScreenSpinnerService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		/* ENDPOINTS MAGELLANO ---------------------------------------------------------------------------------------------------------- */
		this.searchHost = appConfig.search.http.host;
		this.search = `${this.searchHost}/v1/search?clientid=HUBFE`;
		this.typeAhead = `${this.searchHost}/v1/search/typeahead?clientid=HUBFE`;

		/* ENDPOINTS DOCSERIES ---------------------------------------------------------------------------------------------------------- */
		this.docSeriesHost = appConfig.docSeries.http.host;
		this.docSeries = `${this.docSeriesHost}/v1/docseries/{licenseId}/{siaCode}/public`;
		this.graphic = `${this.docSeriesHost}/v1/docseries/{docSeriesId}/public`;
		this.metadataDesc = `${this.docSeriesHost}/v3/docseries/metadata/descriptions`;
	}

	/* HTTP REQUESTS ---------------------------------------------------------------------------------------------- */

	// GET tutti i tipi documento per quel cliente
	whenDocSeries(licenseId: string, siaCode: string): Observable<any> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.docSeries
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<any>(url, options);
	}

	whenAllDocuments(body: string): Observable<DocumentsResponse> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.search;
		return this.http.post<DocumentsResponse>(url, body, options);
	}

	// Typeahead su ragione sociale nel bandone di ricerca
	whenSearchTypeahead(body: any): Observable<SearchBarTypeahead> {
		const url = this.typeAhead;
		return this.http.post<SearchBarTypeahead>(url, body);
	}

	whenMetadataDescriptions(body: DocSeriesMetadataDescSearch): Observable<DocSeriesMetadataDesc[]> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.metadataDesc;
		return this.http.post<DocSeriesMetadataDesc[]>(url, body, options);
	}
}
