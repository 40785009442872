import { Component } from '@angular/core';

@Component({
	selector: 'app-channel-btn-component',
	templateUrl: './channel-button.component.html',
	styleUrls: ['./channel-button.component.scss']
})
export class ChannelButtonComponent {

}
