import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NavigationHttpService } from './navigation-http.service';
import { NavigationService } from './navigation.service';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [],
	exports: []
})
export class NavigationModule {
	static forRoot(): ModuleWithProviders<NavigationModule> {
		return {
			ngModule: NavigationModule,
			providers: [
				NavigationService,
				NavigationHttpService
			]
		};
	}
}
