<div class='modal-body'>
	<p> <strong>Progressivo:</strong> {{data.progressivo}}</p>
	<p> <strong>Progressivo lotto:</strong> {{data.progressivoLotto}}</p>
	<div *blockUI="'uploading-docs-loader'; template: loadingTemplate">
		<div data-value='1' draggable='true'>
			<div class='dropzone'
					 fileDrop
					 (filesDropped)='handleDrop($event)'
					 (filesHovered)='dropzoneState($event)'
					 type='file'>
				<a class='over-icon'>
					<div class='jumbotron upload-page'>
						<ng-container *ngIf='!fileLoaded'>
							<p class='lead white-space-normal dropzone-paragraph'>
								Puoi importare documenti trascinando qui i file o cliccando su seleziona file
							</p>
							<p class='lead dropzone-paragraph'>
								File accettati: {{formato}}
							</p>
							<label for='files-lotto' class='btn btn-primary btn-lg'>
								<i class='{{faIcons.FAS_UPLOAD}}'></i>
								Seleziona file
							</label>
							<input id='files-lotto' type='file' style='display: none' accept='.{{formato}}'
										 (change)='onFileChange($event)'>
						</ng-container>
						<ng-container *ngIf='fileLoaded'>
							<p class='lead dropzone-paragraph'>
								{{fileName}} pronto per essere caricato
							</p>
							<button type='button' class='btn btn-danger btn-lg' (click)='removeFile()'>
								<i class='{{faIcons.FAR_TRASH}} icon-remove'></i> Rimuovi
							</button>
						</ng-container>
					</div>
				</a>
			</div>
		</div>
	</div>
	<small *ngIf='errorMessage' class='danger'>{{errorMessage}}</small>
</div>
<div class='modal-footer'>
	<button type='button' class='btn btn-primary btn-back'
					style='float: left'
					(click)='goBack()'>Indietro
	</button>

	<button
					href='#myModal' class='btn-upload btn btn-primary' data-bs-toggle='modal'
					(click)='uploadDocument()'
					type='button'
					style='float: right'
					[disabled]="!fileLoaded">Importa
	</button>
</div>
