import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FullScreenSpinnerComponent } from './full-screen-spinner/full-screen-spinner.component';
import { FullScreenSpinnerService } from './full-screen-spinner/full-screen-spinner.service';
import { SpinnerHttpInterceptorService } from './full-screen-spinner/spinner-http-interceptor.service';
import { TopProgressBarComponent } from './top-progress-bar/top-progress-bar.component';
import { TopProgressBarService } from './top-progress-bar/top-progress-bar.service';

export function spinnerConfigInit(fullScreenSpinnerService: FullScreenSpinnerService) {
	return () => fullScreenSpinnerService.initConfig();
}

@NgModule({
	imports: [
		CommonModule,
		NgxSpinnerModule.forRoot()
	],
	declarations: [
		FullScreenSpinnerComponent,
		TopProgressBarComponent
	],
	exports: [
		FullScreenSpinnerComponent,
		TopProgressBarComponent
	],
	providers: []
})
export class AppSpinnerModule {
	static forRoot(): ModuleWithProviders<AppSpinnerModule> {
		return {
			ngModule: AppSpinnerModule,
			providers: [
				FullScreenSpinnerService,
				TopProgressBarService,
				{
					provide: HTTP_INTERCEPTORS,
					useClass: SpinnerHttpInterceptorService,
					multi: true
				},
				{
					provide: APP_INITIALIZER,
					useFactory: spinnerConfigInit,
					deps: [FullScreenSpinnerService],
					multi: true
				},
			]
		};
	}
}
