<form novalidate
			(ngSubmit)="onFormSubmit(form)"
			#form="ngForm">
	<div class="main-div">
		<div class="modal-body">
			<div class="alert alert-danger" *ngIf="docCount > 10000">
				<a href="#" class="close" data-bs-dismiss="alert" aria-label="close">&times;</a>
				<strong>Attenzione!</strong> Il numero massimo di documenti firmabili è <strong>10000</strong>.
				Se ne hai selezionati di più, verranno firmati solo i primi <strong>10000</strong> ignorando i documenti
				che
				sono già in fase di firma.
				Inserire i dati del firmatario per procedere:
			</div>

			<div class="alert alert-warning" *ngIf="docCount <= 10000">
				<a href="#" class="close" data-bs-dismiss="alert" aria-label="close">&times;</a>
				<p>Si
					<span *ngIf="docCount === 1;else moreDocs"> sta per firmare <strong>1 documento</strong>.<br><br></span>
					<ng-template #moreDocs> stanno per firmare <strong>{{docCount}} documenti</strong>.<br><br>
					</ng-template>
					Se tra i documenti selezionati ci sono documenti già in fase di firma, questi ultimi verranno
					ignorati.<br><br>
					Compilare i dati sottostanti per procedere:
				</p>
			</div>

			<div class="inner-div">
				<fieldset>
					<div class="form-group mb-2">
						<label class="form-label" for="user">
							<strong>Utente firmatario</strong>
							<span *ngIf="isErrorUser" class="error">*</span></label>
						<input type="text"
									 id="user"
									 name="user"
									 class="form-control"
									 placeholder=""
									 ngModel>
					</div>
					<div class="form-group mb-2">
						<label class="form-label" for="password">
							<strong>Password</strong>
							<span *ngIf="isErrorPwd" class="error">*</span></label>
						<input type="password"
									 id="password"
									 name="password"
									 class="form-control"
									 placeholder=""
									 ngModel>
					</div>
				</fieldset>
				<div *ngIf="isErrorPwd" class="error-msg">
					<b>*Password obbligatoria</b>
				</div>
				<div *ngIf="isErrorUser" class="error-msg">
					<b>*Utente obbligatorio</b>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="submit"
						id="sign-doc-confirm-button"
						class="btn btn-primary">
			Conferma
		</button>
		<button type="button" class="btn btn-danger" (click)="close()">
			Annulla
		</button>
	</div>
</form>
