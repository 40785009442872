import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalContent } from 'app/core/common/notification/modal/containers/modal-content';
import { environment } from 'environments/environment';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DocumentAttachmentService } from './document-attachments/document-attachment.service';
import { UserAttachmentService } from './user-attachments/user-attachment.service';

/**
 * Componente modal per preview del work item prima della scelta di un'azione (tasto Visualizza)
 */
@Component({
	selector: 'gaw-workitem-detail',
	templateUrl: './workitem-detail.component.html',
	styleUrls: ['./workitem-detail.component.scss'],
})
export class WorkitemDetailComponent implements ModalContent, OnDestroy {

	readonly outletName = 'workitem-detail';
	readonly documentPreviewUrl = 'document-preview';
	readonly documentAttachmentsUrl = 'document-attachments';
	readonly userAttachmentsUrl = 'user-attachments';
	readonly statusHistoryUrl = 'status-history';

	documentAttachmentCount$: Observable<string>;
	userAttachmentCount$: Observable<string>;

	docAttachmentId: string;
	userAttachmentId: string;

	env = environment;
	close: () => void;

	onHide: () => Observable<void>;

	constructor(
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly documentAttachmentService: DocumentAttachmentService,
		private readonly userAttachmentService: UserAttachmentService,
	) {
		// Init route
		this.routing(this.documentPreviewUrl);

		this.documentAttachmentCount$ = this.documentAttachmentService.attachmentList$.pipe(
			catchError(() => EMPTY),
			map(list => list.length || 0),
			map(val => val.toString())
		);

		this.userAttachmentCount$ = this.userAttachmentService.attachmentList$.pipe(
			catchError(() => EMPTY),
			map(list => list.length || 0),
			map(val => val.toString())
		);
	}

	routing(url: string) {
		const outlet = {};
		outlet[this.outletName] = url;
		const navigation = {
			outlets: outlet
		};
		// Extra per eliminare l'aggiunta del routing nella barra degli indirizzi
		this.router.navigate([navigation], { relativeTo: this.route, skipLocationChange: true }).then();
	}

	ngOnDestroy(): void {
		this.routing(null);
	}

}
