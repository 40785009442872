/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, Input, OnInit } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { NumberValidators } from 'app/shared/components/dry/validator/number-validators';

@Directive({
	selector: '[negativeDecimal][ngModel], [negativeDecimal][formControlName]',
	providers: [
		{provide: NG_VALIDATORS, useExisting: NegativeValueValidatorDirective, multi: true}
	]
})
export class NegativeValueValidatorDirective implements Validator, OnInit {

	@Input('negativeDecimal') decimalValue = 0;
	validator: ValidatorFn;

	ngOnInit(): void {
		this.validator = NumberValidators.negativeDecimalReg(this.decimalValue);
	}

	registerOnValidatorChange(fn: () => void): void {	}

	validate(c: AbstractControl): ValidationErrors | null {
		return this.validator(c);
	}

}
