import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidatorModule } from 'app/shared/components/dry/validator/validator.module';
import { NativeElementControlDirective } from 'app/shared/components/form-validation/specialization/text-input/native-element-control.directive';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ComposeAsyncValidatorDirective } from './compose-async-validator.directive';
import { ComposeValidatorDirective } from './compose-validator.directive';
import { ReactiveFormValidationModule } from './reactive-form/reactive-form-validation.module';
import { CheckboxInputComponent } from './specialization/checkbox-input/checkbox-input.component';
import { DateInputComponent } from './specialization/date-input/date-input.component';
import { DateTimeInputComponent } from './specialization/date-time-input/date-time-input.component';
import { DecimalInputComponent } from './specialization/decimal-input/decimal-input.component';
import { NumberInputComponent } from './specialization/number-input/number-input.component';
import { SelectInputComponent } from './specialization/select-input/select-input.component';
import { TextInputComponent } from './specialization/text-input/text-input.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PopoverModule,
		ValidatorModule,
		ReactiveFormValidationModule
	],
	declarations: [
		ComposeValidatorDirective,
		ComposeAsyncValidatorDirective,
		TextInputComponent,
		NumberInputComponent,
		SelectInputComponent,
		DateInputComponent,
		DateTimeInputComponent,
		CheckboxInputComponent,
		DecimalInputComponent,
		NativeElementControlDirective,
	],
	exports: [
		TextInputComponent,
		NumberInputComponent,
		SelectInputComponent,
		DateInputComponent,
		DateTimeInputComponent,
		CheckboxInputComponent,
		DecimalInputComponent,
		NativeElementControlDirective,
	]
})
export class FormValidationModule {
}
