import { Injectable } from '@angular/core';
import { EMPTY, ReplaySubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Barcode } from 'app/entities/barcode/barcode';
import { BarcodeHttpService } from './barcode-http.service';

@Injectable({
	providedIn: 'root'
})
export class BarcodeService {
	private barcodes$ = new ReplaySubject<Barcode[]>(1);
	constructor(
		private barcodeHttpService: BarcodeHttpService
	) { }
	whenBarcode(licenseId, siaCode, code, graphic, note, idScatola) {
		return this.barcodeHttpService.whenBarcode(licenseId, siaCode, code, graphic, note, idScatola).pipe(
			catchError(() => EMPTY),
			tap((barcodes) => {
				this.sendBarcodes(barcodes);
			})
		);
	}
	sendBarcodes(barcodes: Barcode[]) {
		this.barcodes$.next(barcodes);
	}
}
