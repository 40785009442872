export enum SectionCode {
	// ciclo attivo e passivo generici (non sono metadati elastic!)
	RECEIVABLE = 'receivable',
	PAYABLE = 'payable',
	// card grandi attivo
	RICEVUTO = 'hubfe_ricevuto',
	INERROREPREELAB = 'hubfe_inErrorePreElab',
	INFIRMA = 'hubfe_inFirma',
	INSPEDIZIONE = 'hubfe_inSpedizione',
	CONSEGNATO = 'hubfe_consegnato',
	ANNULLATO = 'hubfe_annullato',
	// todo Evo in corso - modificare il valore - Karandip Singh
	INCONSERVAZIONE = 'hubfe_inconservazione',
	// card piccole attivo (canali)
	INVIATOSDI35 = 'hubfe_inviatoSDI35',
	ERRORECONTROLLISDI35 = 'hubfe_erroreControlliSDI35',
	NONCONSEGNATO35 = 'hubfe_nonConsegnato35',
	NONCONSEGNATO35CA = 'hubfe_nonConsegnato35ca',
	ANNULLATO35 = 'hubfe_annullato35',
	CONSEGNATO35 = 'hubfe_consegnato35',
	INVIATOSTAMPATORE06 = 'hubfe_inviatoStampatore06',
	STAMPATO06 = 'hubfe_stampato06',
	RESO06 = 'hubfe_reso06',
	ANNULLATO06 = 'hubfe_annullato06',
	CONSEGNATO06 = 'hubfe_consegnato06',
	DASPEDIRE21 = 'hubfe_daSpedire21',
	INERRORE21 = 'hubfe_inErrore21',
	ANNULLATO21 = 'hubfe_annullato21',
	SPEDITO21 = 'hubfe_spedito21',
	SPEDITO22 = 'hubfe_spedito22',
	NONCONSEGNATO22 = 'hubfe_nonConsegnato22',
	ANNULLATO22 = 'hubfe_annullato22',
	CONSEGNATO22 = 'hubfe_consegnato22',
	// card passivo
	CP_RICEVUTO35 = 'hubfe_cp_ricevuto35',
	CP_RICEVUTO36 = 'hubfe_cp_ricevuto36',
	CP_RICEVUTO18 = 'hubfe_cp_ricevuto18',
	CP_RICEVUTO10 = 'hubfe_cp_ricevuto10',
	CP_NONACCOPPIATO = 'hubfe_cp_nonAccoppiato',
	CP_RICEVUTO = 'hubfe_cp_ricevuto',
	CP_SQUADRATO = 'hubfe_cp_squadrato',
	CP_ESPORTATO = 'hubfe_cp_esportato',
	CP_ATTESAACCETTRIFIUTO = 'hubfe_cp_inAttesaAccettRifiuto',
	CP_REGISTRATO = 'hubfe_cp_registrato',
	CP_SQUADRATOPOSTELAB = 'hubfe_cp_squadratoPostElab',
	// todo Evo in corso - modificare il valore - Karandip Singh
	CP_INCONSERVAZIONE = 'hubfe_cp_inconservazione',
	CP_ESCLUSAPROTOCOLLAZIONE = 'hubfe_cp_esclusoDaProtocol',

	// sezioni per i canali nella pagina di dettaglio doc
	GEDFATT = 'hubfe_gedinvoice',
	GEDMAIL = 'hubfe_gedmail',
	GEDPEC = 'hubfe_gedpec',
	GEDPOST = 'hubfe_gedpost',
	GEDEDI = 'hubfe_cp_gededi',
	GEDPASSPLUS = 'hubfe_cp_gedpassplus',
	GEDPASS = 'hubfe_cp_gedpass',
	GEDFATT_CP = 'hubfe_cp_gedinvoice',
}
