import { Component, Host, HostBinding, Inject, Input, Optional, SkipSelf, forwardRef } from '@angular/core';
import { AsyncValidator, AsyncValidatorFn, ControlContainer, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, ValidatorFn } from '@angular/forms';
import { CustomControlComponent } from '../custom-control.component';

@Component({
	selector: 'date-input',
	templateUrl: './date-input.component.html',
	styleUrls: ['./date-input.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => DateInputComponent),
		multi: true,
	}],
})
export class DateInputComponent extends CustomControlComponent<Date> {

	@HostBinding('attr.id') externalId = '';

	// TODO: Vedere come specificare il formato, pare non possibile con HTML5
	@Input() format = 'YYYY-MM-DD';

	constructor(
		@Optional() @Inject(NG_VALIDATORS) validators: Array<Validator | ValidatorFn>,
		@Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<AsyncValidator | AsyncValidatorFn>,
		@Optional() @Host() @SkipSelf() controlContainer: ControlContainer
	) {
		super(validators, asyncValidators, controlContainer);
	}

	get id() {
		return this._id;
	}

	@Input()
	set id(value: string) {
		this._id = value;
		this.externalId = null;
	}

	onChangeUpdate() {
		super.updateChanges();
	}
}
