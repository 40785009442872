import { DocumentNote } from './document-notes-response';

export class Note {
	noteId: number;
	nickName: string;
	lastUpdateDate: string;
	note: string;
	authUserId: string;

	constructor(note: DocumentNote) {
		this.noteId = +note.noteId.value;
		this.nickName = note.nickName.value.toString();
		this.lastUpdateDate = note.lastUpdateDate.value.toString();
		this.note = note.note.value.toString();
		this.authUserId = note.authUserId.value.toString();
	}
}
