export interface InfoByDocSeries {
	id: string;
	startDate: Date;
	endDate: Date;
	description: string;
	elasticIndexName: string;
	elasticMappingTypeName: string;
	blendQueue?: string;
	persistQueue: string;
	licenseId: string;
	siaCode: string;
	graphicsCode: string;
	classificationId: string;
	docClassCode?: string;
}

export interface GraphicInfoResponse {
	id: string;
	description: string;
	licenseId: string;
	siaCode: string;
	graphicsCode: string;
}
