export class JavaScriptError {

	constructor(
		public name: string,
		public description: string
	) { }

	toString() {
		return this.name + ': ' + this.description;
	}

}
