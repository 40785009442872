export enum ActionCode {
	DOWNLOAD_MISSING_DELIVERY = 'DOWNLOAD_MISSING_DELIVERY',
	DOWNLOAD_ACCEPTANCE = 'DOWNLOAD_ACCEPTANCE',
	EDIT_ADDRESS_AND_RESEND = 'EDIT_ADDRESS_AND_RESEND',
	DOWNLOAD_DELIVERY = 'DOWNLOAD_DELIVERY', // scarica consegna (ex visualizza avvenuta consegna) (single) DA VERIFICARE !!!
	DOWNLOAD_ORIGINAL_PDF = 'DOWNLOAD_ORIGINAL_PDF', // scarica pdf originale (ex visualizza originale) (single) DA VERIFICARE !!!
	EDIT_KEYS = 'EDIT_KEYS', // apre modal per modifica delle chiavi (single)
	EDIT_KEYS_DISABLED = 'EDIT_KEYS_DISABLED', // indica che l'azione modifica delle chiavi è disabilitata perchè il documento è in
	// modifica sul workflow
	DOWNLOAD_EDI = 'DOWNLOAD_EDI',
	SHOW_PDF = 'SHOW_PDF',	// apre pagina di dettaglio (single)
	DOWNLOAD_PDF = 'DOWNLOAD_PDF', // scarica pdf (single)
	DOWNLOAD_DELEGA = 'DOWNLOAD_DELEGA', // scarica pdf (single)
	DOWNLOAD_ZIP = 'DOWNLOAD_ZIP', // scarica zip di documenti (multi)
	DOWNLOAD_EXCEL = 'DOWNLOAD_EXCEL', // scarica excel (multi/massive)
	DOWNLOAD_MERGED_PDF = 'DOWNLOAD_MERGED_PDF ', // scarica documenti uniti (multi)
	START_WORKFLOW = 'START_WORKFLOW', // scarica pdf mergiati (multi)
	DOWNLOAD_ATTACHMENTS_CA = 'DOWNLOAD_ATTACHMENTS_CA', // scarica zip di allegati attivo (single)
	DOWNLOAD_ATTACHMENTS_CP = 'DOWNLOAD_ATTACHMENTS_CP', // scarica zip di allegati passivo (single)
	SHOW_ORIGINAL_PDF = 'SHOW_ORIGINAL_PDF', // apre modal pdf originale / custom / ade (single)
	DOWNLOAD_PDF_ZIP = 'DOWNLOAD_PDF_ZIP', // scarica zip di pdf (multi/massive)
	DOWNLOAD_XML_ZIP = 'DOWNLOAD_XML_ZIP', // scarica zip di xml (multi/massive)
	DOWNLOAD_XML_ZIP_GENERIC = 'DOWNLOAD_XML_ZIP_GENERIC', // scarica zip di xml che funziona sia per gedinvoice che premulti (multi/massive)
	HIDE_DOCUMENTS = 'HIDE_DOCUMENTS', // visibile solo a un ruolo specifico
	RESTORE_DOCUMENTS = 'RESTORE_DOCUMENTS', // visibile solo a un ruolo specifico
	EDIT_INVOICE = 'EDIT_INVOICE', // modifica fattura (single)
	EDIT_INVOICE_WITHOUT_CANCEL = 'EDIT_INVOICE_WITHOUT_CANCEL', // modifica fattura senza annullare il doc di partenza (single)
	SIGN_DOC = 'SIGN_DOC',	// firma (single, multi, massive)
	CANCEL_ELAB = 'CANCEL_ELAB', // annulla (single, multi, massive)
	DOWNLOAD_ATTACHMENTS = 'DOWNLOAD_ATTACHMENTS', // scarica attachments-allegati (single)
	DOWNLOAD_ATTACHMENTS_RECEIVABLE = 'DOWNLOAD_ATTACHMENTS_RECEIVABLE', // scarica attachments-allegati (single)
	DOWNLOAD_ATTACHMENTS_PAYABLE = 'DOWNLOAD_ATTACHMENTS_PAYABLE', // scarica attachments-allegati (single)
	SHOW_STATUS_HISTORY = 'SHOW_STATUS_HISTORY',	// apre modal storico stati (single)
	SHOW_OUTCOMES_HISTORY_PAYABLE = 'SHOW_OUTCOMES_HISTORY_PAYABLE',	// apre modal storico esiti passivo (single) - cliente SNAM-Italgas
	SHOW_CHANGES_HISTORY = 'SHOW_CHANGES_HISTORY', // apre modal storico modifiche (single)
	SHOW_OUTCOMES_HISTORY = 'SHOW_OUTCOMES_HISTORY', // apre modal storico esiti (single)
	DOWNLOAD_XML = 'DOWNLOAD_XML',	// scarica xml (single, ma diventerà anche multi e massive)
	MERGE_PDF = 'MERGE_PDF', // scarica documenti uniti (multi per card gedpost stampate e annullate)
	SEND_ANALOGIC_COPY = 'SEND_ANALOGIC_COPY', // invia copia analogica
	CHECK_SDI_AND_RESEND = 'CHECK_SDI_AND_RESEND', // applica controlli sdi e reinvia (multi per card errori sdi)
	// AZIONI PRESENTI SOLO NEL PASSIVO
	FORWARD_WITH_COPIES = 'FORWARD_WITH_COPIES',	// avanza con copie
	FORWARD_WITHOUT_COPIES = 'FORWARD_WITHOUT_COPIES', // avanza senza copie
	FORWARD_ADD_SUPPLIER = 'FORWARD_ADD_SUPPLIER', // avanza con fornitore
	FORWARD_NO_SUPPLIER = 'FORWARD_NO_SUPPLIER', // avanza senza fornitore
	EXPORT_TO_MANAGEMENT = 'EXPORT_TO_MANAGEMENT', // esporta verso gestionale (single, multi, massive)
	RE_EXPORT_TO_MANAGEMENT = 'RE_EXPORT_TO_MANAGEMENT',
	PROTOCOL = 'PROTOCOL',	// inserisci dati protocollo (single)
	EDIT_PROTOCOL = 'EDIT_PROTOCOL',	// inserisci dati protocollo (single)
	CONFIRM_REGISTRATION = 'CONFIRM_REGISTRATION', // conferma registrazione su gestionale (single)
	PROTOCOL_RC_FILE = 'PROTOCOL_RC_FILE', // inserisci dati protocollo + pdf RC (single)
	PROTOCOL_RC_METADATA = 'PROTOCOL_RC_METADATA', // inserisci dati protocollo + metadati RC (single)
	RC_FILE = 'RC_FILE', // carica pdf RC
	RC_METADATA = 'RC_METADATA', // inserisci metadati RC (single)
	// EDIT_PROTOCOL = 'EDIT_PROTOCOL',	// inserisci dati protocollo (single)
	// EDIT_PROTOCOL_RC_FILE = 'EDIT_PROTOCOL_RC_FILE', // inserisci dati protocollo + pdf RC (single)
	// EDIT_PROTOCOL_RC_METADATA = 'EDIT_PROTOCOL_RC_METADATA', // inserisci dati protocollo + metadati RC (single)
	// EDIT_RC_FILE = 'EDIT_RC_FILE', // carica pdf RC
	// EDIT_RC_METADATA = 'EDIT_RC_METADATA', // inserisci metadati RC (single)
	// azioni aggiuntive
	ADD_NOTES = 'ADD_NOTES', // aggiunge una nota a qualsiasi tipo di documento in qualsiasi stato (single)
	VIEW_MAP = 'VIEW_MAP', // visualizza mappa
	TRACKING_PROVIDER = 'TRACKING_PROVIDER', // ricerca stato raccomandata
	DOWNLOAD_HISTORY_STATES = 'DOWNLOAD_HISTORY_STATES',
	PRINT_BARCODE = 'PRINT_BARCODE',
	EDIT_BOX = 'EDIT_BOX',
	CHECK_AS_RECEIVED = 'CHECK_AS_RECEIVED',
	CHECK_AS_FORWARDED = 'CHECK_AS_FORWARDED',
	CHECK_AS_DELETED = 'CHECK_AS_DELETED',
	CHECK_AS_REJECTED = 'CHECK_AS_REJECTED',
	CANCEL_STATE_SELECTION = 'CANCEL_STATE_SELECTION',
	SPOOL_HISTORY = 'SPOOL_HISTORY',
	OPEN_DETAIL = 'OPEN_DETAIL',
	EXPORT_TO_FTP = 'EXPORT_TO_FTP',
	EXCLUDE_FROM_CONS = 'EXCLUDE_FROM_CONS',
	UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
	PROCESS_DOCUMENT = 'PROCESS_DOCUMENT',
	EDIT_DATA = 'EDIT_DATA',
	CANCEL_DOCUMENT = 'CANCEL_DOCUMENT',
	OPEN_ANOMALY_DETAIL = 'OPEN_ANOMALY_DETAIL',
	OPEN_BOX_DETAIL = 'OPEN_BOX_DETAIL',
	ANOMALY_HISTORY = 'ANOMALY_HISTORY',
	EDIT_ANOMALY = 'EDIT_ANOMALY',
	DOWNLOAD_MESSAGES = 'DOWNLOAD_MESSAGES',
	CREATE_PDD = 'CREATE_PDD',
	DOWNLOAD_PRESERVED_DOCUMENT = 'DOWNLOAD_PRESERVED_DOCUMENT',
	OPEN_PREVIOUS_VERSIONS = 'OPEN_PREVIOUS_VERSIONS',
	DOWNLOAD_PDD = 'DOWNLOAD_PDD',
	DOWNLOAD_ESITI_PDF = 'DOWNLOAD_ESITI_PDF',
	DOWNLOAD_ESITI_XML = 'DOWNLOAD_ESITI_XML',
	DOWNLOAD_ESITI_EXCEL = 'DOWNLOAD_ESITI_EXCEL',
	ASSOCIA_AUTOFATTURA = 'ASSOCIA_AUTOFATTURA',
	EXPORT_TO_MANAGEMENT_FROM_UNPRESERVED = 'EXPORT_TO_MANAGEMENT_FROM_UNPRESERVED',
	DELETE_EMPLOYEE = 'DELETE_EMPLOYEE',
	ASSIGN_PROFILE = 'ASSIGN_PROFILE',
	RESET_PASSWORD = 'RESET_PASSWORD',
	EDIT_EMPLOYEE = 'EDIT_EMPLOYEE',
	DELETE_PROFILE = 'DELETE_PROFILE',
	VIEW_PROFILE = 'VIEW_PROFILE',
	VIEW_ACCOUNTS = 'VIEW_ACCOUNTS',
	EDIT_PROFILE = 'EDIT_PROFILE',
	DISABLE_EMPLOYEE = 'DISABLE_EMPLOYEE',
	ENABLE_EMPLOYEE = 'ENABLE_EMPLOYEE',
	EDIT_MIN_PSW = 'EDIT_MIN_PSW',
	EDIT_MAX_PSW = 'EDIT_MAX_PSW',
	MOVE_TO_TRASH = 'MOVE_TO_TRASH',
	DOWNLOAD_LOG = 'DOWNLOAD_LOG',
	DELETE_LOG = 'DELETE_LOG',
	RESTORE_FROM_TRASH = 'RESTORE_FROM_TRASH',
	DELETE_FROM_TRASH = 'DELETE_FROM_TRASH',
	SIGN = 'SIGN',
	CANCEL = 'CANCEL',
	EDIT_USERNAME = 'EDIT_USERNAME',
	RENEW = 'RENEW',
	CONFIRM = 'CONFIRM',
	DOWNLOAD =  'DOWNLOAD',
	EDIT_REGISTRY = 'EDIT_REGISTRY',
	DOWNLOAD_MASSIVE_XML = 'downloadMassiveXml',
	DOWNLOAD_ESITI = 'DOWNLOAD_ESITI',
	CANCEL_REQUEST = 'CANCEL_REQUEST',
	UPLOAD = 'UPLOAD',
	REMOVE = 'REMOVE',
}
