import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AccountEffects } from './store/account.effects';
import { accountModuleFeatureKey, accountModuleReducers } from './store/account.reducer';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(accountModuleFeatureKey, accountModuleReducers),
		EffectsModule.forFeature([
			AccountEffects
		])
	],
	declarations: [],
	exports: []
})
export class AccountModule {}
