<form *ngIf='data' #form='ngForm' (ngSubmit)='onFormSubmit(form.form)'>
	<div class='modal-body'>
		<div class='contact-detail-container container-fluid px-0'>
			<h3 class='mb-3'>Anagrafica</h3>

			<input id='persona-fisica'
						 type='checkbox'
						 [checked]='personaFisicaFlag'
						 (change)='personaFisicaChecked($event)'>
			<label for='persona-fisica'> Persona fisica</label>

			<div class='row m-0'>
				<label class="form-label" for='rubrica-id'></label>
				<input type='hidden'
							 class='form-control form-control-sm'
							 id='rubrica-id'
							 name='contactId'
							 [(ngModel)]='contact.contactId'>

				<div class='col-12'>
					<label class="form-label" for='rubrica-alias'>Alias<span> *</span></label>
					<input type='text'
								 class='form-control form-control-sm'
								 id='rubrica-alias'
								 name='contactAlias'
								 maxlength='50'
								 (ngModelChange)='onAliasChange($event)'
								 [(ngModel)]='contact.contactAlias'>
				</div>
				<ng-container *ngIf='!denominazioneDisabled'>
					<div class='col-12'>
						<label class="form-label" for='rubrica-denominazione'>Denominazione<span
										*ngIf='!personaFisicaFlag'> *</span></label>
						<input type='text'
									 class='form-control form-control-sm'
									 id='rubrica-denominazione'
									 name='cessionarioCommittente.DatiAnagrafici.Anagrafica.Denominazione'
									 [disabled]='denominazioneDisabled'
									 (ngModelChange)='onDenominazioneChanged($event)'
									 [(ngModel)]='contact.contactConfiguration.cessionarioCommittente.DatiAnagrafici.Anagrafica.Denominazione'>
					</div>
				</ng-container>
				<ng-container *ngIf='!nomeCognomeDisabled'>
					<div class='col-md-2 col-no-padding-left'>
						<label class="form-label" for='rubrica-titolo'>Titolo</label>
						<input type='text'
									 class='form-control form-control-sm'
									 id='rubrica-titolo'
									 name='cessionarioCommittente.DatiAnagrafici.Anagrafica.Titolo'
									 [disabled]='nomeCognomeDisabled'
									 [(ngModel)]='contact.contactConfiguration.cessionarioCommittente.DatiAnagrafici.Anagrafica.Titolo'>
					</div>
					<div class='col-md-5'>
						<label class="form-label" for='rubrica-nome'>Nome<span *ngIf='personaFisicaFlag'> *</span></label>
						<input type='text'
									 class='form-control form-control-sm'
									 id='rubrica-nome'
									 name='cessionarioCommittente.DatiAnagrafici.Anagrafica.Nome'
									 [disabled]='nomeCognomeDisabled'
									 [(ngModel)]='contact.contactConfiguration.cessionarioCommittente.DatiAnagrafici.Anagrafica.Nome'>
					</div>
					<div class='col-md-5 col-no-padding-right'>
						<label class="form-label" for='rubrica-cognome'>Cognome<span *ngIf='personaFisicaFlag'> *</span></label>
						<input type='text'
									 class='form-control form-control-sm'
									 id='rubrica-cognome'
									 name='cessionarioCommittente.DatiAnagrafici.Anagrafica.Cognome'
									 [disabled]='nomeCognomeDisabled'
									 [(ngModel)]='contact.contactConfiguration.cessionarioCommittente.DatiAnagrafici.Anagrafica.Cognome'>
					</div>

				</ng-container>

				<div class='col-md-5 col-no-padding-left'>
					<label class="form-label" for='rubrica-piva'>Partita IVA</label>
					<input type='text'
								 class='form-control form-control-sm'
								 id='rubrica-piva'
								 name='cessionarioCommittente.DatiAnagrafici.IdFiscaleIVA.IdCodice'
								 [(ngModel)]='contact.contactConfiguration.cessionarioCommittente.DatiAnagrafici.IdFiscaleIVA.IdCodice'>
				</div>
				<div class='col-md-4'>
					<label class="form-label" for='rubrica-id-paese'>Paese IVA</label>
					<select class='form-control form-control-sm'
									id='rubrica-id-paese'
									name='cessionarioCommittente.DatiAnagrafici.IdFiscaleIVA.IdPaese'
									[(ngModel)]='contact.contactConfiguration.cessionarioCommittente.DatiAnagrafici.IdFiscaleIVA.IdPaese'>
						<option></option>
						<option *ngFor='let nation of (nationsSortedByName$ | async)'>
							{{nation.labelWithValue}}
						</option>
					</select>
				</div>
				<div class='col-md-3 col-no-padding-right'>
					<label class="form-label" for='rubrica-eori'>Codice EORI</label>
					<input type='text'
								 class='form-control form-control-sm'
								 id='rubrica-eori'
								 name='cessionarioCommittente.DatiAnagrafici.Anagrafica.CodEORI'
								 [(ngModel)]='contact.contactConfiguration.cessionarioCommittente.DatiAnagrafici.Anagrafica.CodEORI'>
				</div>

				<div class='col-md-6 col-no-padding-left'>
					<label class="form-label" for='rubrica-cf'>Codice fiscale</label>
					<input type='text'
								 class='form-control form-control-sm'
								 id='rubrica-cf'
								 name='cessionarioCommittente.DatiAnagrafici.CodiceFiscale'
								 [(ngModel)]='contact.contactConfiguration.cessionarioCommittente.DatiAnagrafici.CodiceFiscale'>
				</div>
				<div class='col-md-6 col-no-padding-right'>
					<label class="form-label" for='rubrica-formato'>Formato trasmissione</label>
					<select class='form-control form-control-sm'
									id='rubrica-formato'
									name='datiTrasmissione.FormatoTrasmissione'
									(ngModelChange)='onFormatoTrasmissioneChanged($event)'
									[(ngModel)]='contact.contactConfiguration.datiTrasmissione.FormatoTrasmissione'>
						<option title='Fattura PA'></option>
						<option title='Fattura PA'>FPA12</option>
						<option title='Fattura B2B'>FPR12</option>
					</select>
				</div>

				<div class='col-md-6 col-no-padding-left'>
					<label class="form-label" for='rubrica-pec'>PEC destinatario</label>
					<input type='text'
								 class='form-control form-control-sm'
								 id='rubrica-pec'
								 name='datiTrasmissione.PECDestinatario'
								 [disabled]='pecDisabled'
								 [(ngModel)]='contact.contactConfiguration.datiTrasmissione.PECDestinatario'>
					<sub *ngIf='pecDisabled' class='float-end'>Per abilitare, modificare "Formato trasmissione"</sub>
				</div>
				<div class='col-md-6 col-no-padding-right'>
					<label class="form-label" for='rubrica-email'>Email</label>
					<input type='text'
								 class='form-control form-control-sm'
								 id='rubrica-email'
								 name='datiTrasmissione.Email'
								 [(ngModel)]='contact.contactConfiguration.datiTrasmissione.Email'>
				</div>

				<div class='col-md-9 col-no-padding-left'>
					<label class="form-label" for='rubrica-indirizzo'>Indirizzo</label>
					<input type='text'
								 class='form-control form-control-sm'
								 id='rubrica-indirizzo'
								 name='cessionarioCommittente.Sede.Indirizzo'
								 [(ngModel)]='contact.contactConfiguration.cessionarioCommittente.Sede.Indirizzo'>
				</div>
				<div class='col-md-3 col-no-padding-right'>
					<label class="form-label" for='rubrica-civico'>Numero civico</label>
					<input type='text'
								 class='form-control form-control-sm'
								 id='rubrica-civico'
								 name='cessionarioCommittente.Sede.NumeroCivico'
								 [(ngModel)]='contact.contactConfiguration.cessionarioCommittente.Sede.NumeroCivico'>
				</div>

				<div class='col-md-9 col-no-padding-left'>

					<label class="form-label" for='rubrica-comune'>Comune</label>
					<input type='text'
								 class='form-control form-control-sm'
								 id='rubrica-comune'
								 name='cessionarioCommittente.Sede.Comune'
								 [(ngModel)]='contact.contactConfiguration.cessionarioCommittente.Sede.Comune'>
				</div>
				<div class='col-md-3 col-no-padding-right'>
					<label class="form-label" for='rubrica-cap'>CAP</label>
					<input type='text'
								 class='form-control form-control-sm'
								 id='rubrica-cap'
								 name='cessionarioCommittente.Sede.CAP'
								 [(ngModel)]='contact.contactConfiguration.cessionarioCommittente.Sede.CAP'>
				</div>
				<div class='col-md-6 col-no-padding-left'>
					<label class="form-label" for='rubrica-provincia'>Provincia</label>
					<select class='form-control form-control-sm'
									id='rubrica-provincia'
									name='cessionarioCommittente.Sede.Provincia'
									[(ngModel)]='contact.contactConfiguration.cessionarioCommittente.Sede.Provincia'>
						<option></option>
						<option *ngFor='let city of cities'>
							{{city.label}}
						</option>
					</select>
				</div>
				<div class='col-md-6 col-no-padding-right'>
					<label class="form-label" for='rubrica-nazione'>Nazione</label>
					<select class='form-control form-control-sm'
									id='rubrica-nazione'
									name='cessionarioCommittente.Sede.Nazione'
									[(ngModel)]='contact.contactConfiguration.cessionarioCommittente.Sede.Nazione'>
						<option></option>
						<option *ngFor='let nation of (nationsSortedByName$ | async)'>
							{{nation.label}}
						</option>
					</select>
				</div>
				<div class='col-md-6 col-no-padding-left'>
					<label class="form-label" for='rubrica-codice-destinatario'>Codice destinatario</label>
					<input type='text'
								 class='form-control form-control-sm'
								 id='rubrica-codice-destinatario'
								 name='datiTrasmissione.CodiceDestinatario'
								 [(ngModel)]='contact.contactConfiguration.datiTrasmissione.CodiceDestinatario'>
				</div>
				<div *ngIf='codVeicolazioneEnabled' class='col-md-6 col-no-padding-right'>
					<label class="form-label" for='codice-veicolazione'>Codice Veicolazione</label>
					<input type='text'
								 class='form-control form-control-sm'
								 id='codice-veicolazione'
								 name='datiTrasmissione.CodiceVeicolazione'
								 [(ngModel)]='contact.contactConfiguration.datiTrasmissione.CodiceVeicolazione'
								 [readonly]='!!lastCodiceVeicolazione'>
				</div>
				<div class='col-12'>
					<div *ngIf='mandatoryDataErrorMessage' class='error-msg'>Mancano dati obbligatori (*)</div>
					<div *ngIf='aliasAlreadyExistsMessage' class='error-msg'>Alias già esistente in rubrica</div>
					<div *ngIf='codiceVeicolazioneError' class='error-msg'>{{this.codiceVeicolazioneErrorMessage}}</div>
				</div>
			</div>
		</div>
	</div>
	<div class='modal-footer mt-5'>
		<button type='submit' class='btn btn-primary'>
			Conferma
		</button>
		<button type='button' class='btn btn-danger' (click)='onCancel()'>
			{{modal ? 'Torna alla Rubrica' : 'Annulla'}}
		</button>
	</div>
</form>
