import { Injectable } from '@angular/core';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { Navigation } from 'app/entities/navigation/navigation';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { CompaniesService } from '../companies/companies.service';
import { NavigationHttpService } from './navigation-http.service';

/**
 * Componente del filtro calendario
 */
@Injectable()
export class NavigationService {

	// private currentCompany$ = new BehaviorSubject<Company>(null);
	private navigation$ = new ReplaySubject<Navigation[]>(1);

	private sidebarOpen$ = new BehaviorSubject<boolean>(false);

	constructor(
		private navigationHttpService: NavigationHttpService, private company: CompaniesService
	) {
		this.company.whenCurrentCompany().subscribe(value => this.callNavigationMenu(value.licenseId, value.siaCode));
	}

	private static arrayMove(arr: any[], from: number, to: number) {
		arr.splice(to, 0, arr.splice(from, 1)[0]);
		return arr;
	}

	public callNavigationMenu(licenseId: string, siaCode: string) {
		this.navigationHttpService.whenNavigation(licenseId, siaCode).pipe(
			take(1),
			catchError((err: unknown) => {
				if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED)
					return EMPTY;

			}),
			map(navigations => {
				let navMenu = navigations;
				// Sposto determinate voci di menù in fondo all'array
				const fattureAtteseIndex = navMenu.findIndex(n => n.url === '/fatture-attese');
				if (fattureAtteseIndex !== -1)
					navMenu = NavigationService.arrayMove(navMenu, fattureAtteseIndex, navMenu.length - 1);

				const ricercaFattureIndex = navMenu.findIndex(n => n.url === '/ricerca-fatture');
				if (ricercaFattureIndex !== -1)
					navMenu = NavigationService.arrayMove(navMenu, ricercaFattureIndex, navMenu.length - 1);

				return navMenu;
			})
		).subscribe(
			result => {
				if (result)
					this.sendNavigation(result);

			});
	}

	whenNavigation(): Observable<Navigation[]> {
		return this.navigation$.asObservable();
	}

	sendNavigation(value: Navigation[]) {
		this.navigation$.next(value);
	}

	whenSidebar(): Observable<boolean> {
		return this.sidebarOpen$.asObservable();
	}

	sendSidebar(value: boolean) {
		this.sidebarOpen$.next(value);
	}
}
