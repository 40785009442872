import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Field, FieldType } from 'app/shared/components/dry/field';
import { AsyncTableAction } from 'app/shared/components/table/actions/async-table-action';
import { TableAction } from 'app/shared/components/table/actions/table-action';
import { environment } from 'environments/environment';
import { Observable, Subject, from, of } from 'rxjs';
import { filter, map, mergeMap, reduce, take } from 'rxjs/operators';
import { AbstractTableComponent } from '../abstract-table.component';
import { AsyncRowAction } from '../actions/async-row-action';
import { RowAction } from '../actions/row-action';
import { RowActionsShowMode } from '../actions/row-action-show-mode.enum';

@Component({
	selector: 'app-material-table',
	templateUrl: './material-table.component.html',
	styleUrls: ['./material-table.component.scss']
})
export class MaterialTableComponent<T> extends AbstractTableComponent<T> implements OnDestroy {

	// Actions
	@Input() rowActions: RowAction[] = [];
	@Input() rowActionsPosition: RowActionsShowMode = RowActionsShowMode.NONE;

	// Table
	@ViewChild(MatTable, { static: false }) table: MatTable<T>;
	@Input() title = '';

	env = environment;
	public readonly fieldType = FieldType;

	data$: Observable<MatTableDataSource<T>>;

	// Fields
	fieldsOrder$: Observable<string[]>;
	fields$: Observable<Field[]>;
	readonly ROW_ACTIONS_COLUMN_ID = '_rowActions';

	private destroy$ = new Subject<void>();

	constructor() {
		super();
	}

	@Input() set fields(fields: Field[]) {

		if (!fields || !(fields instanceof Array))
			return;

		this._fields = fields; // Inizializzo anche i fields dell'AbstractTable
		this.fields$ = of(fields);
		this.setFieldOrder();
	}

	@Input() set data(data: T[]) {

		const dataSource = new MatTableDataSource<T>();
		dataSource.data = data;

		this._data = data;
		this.data$ = of(dataSource);

		// Call renderRows per aggiornare la view
		if (this.table)
			this.table.renderRows();

	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	executeAction(rowAction: RowAction, row: unknown) {
		if (rowAction instanceof AsyncRowAction)
			rowAction.action(row)
				.pipe(take(1))
				.subscribe();
		else
			rowAction.action(row);

	}

	executeRowAction(rowAction: RowAction, row: unknown) {
		if (rowAction instanceof AsyncRowAction)
			rowAction.action(row)
				.pipe(take(1))
				.subscribe();
		else
			rowAction.action(row);

	}

	executeTableAction(tableAction: TableAction) {
		if (tableAction instanceof AsyncTableAction)
			tableAction.action().subscribe();
		else
			tableAction.action();

	}

	private setFieldOrder() {
		// Creo l'array di stringhe con l'id dei campi per indicare alla MaterialTable l'ordine.
		// Attualmente tengo un ordine basate sull'ordinamento in input, tranne per il campo delle azioni
		const fieldStream$ = (field: Field[]) => from(field)
			.pipe(
				filter(f => f.visible),
				map(f => f.id)
			);

		this.fieldsOrder$ = this.fields$
			.pipe(
				mergeMap(fieldStream$),
				reduce((acc: string[], val: string) => [...acc, val], []),
				map(fieldArray => {

					switch (this.rowActionsPosition) {
						case RowActionsShowMode.RIGHT:
							fieldArray.push(this.ROW_ACTIONS_COLUMN_ID);
							break;

						case RowActionsShowMode.LEFT:
							fieldArray.unshift(this.ROW_ACTIONS_COLUMN_ID);
							break;

						default:
					}
					return fieldArray;
				})
			);
	}
}
