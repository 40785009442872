<div id="mySidenav" class="sidenav container">
	<div class="close-sidebar-block" placement="bottom" tooltip="Chiudi il menu">
		<button (click)="onCloseSidebar()"
		type="button" id="sidebarCollapseBtn"
		aria-label="Apri menù laterale"
		class="btn sidebar-button">
			<img alt="Chiudi sidebar" src="/assets/homepage/path_2.svg" class="nav-item" />
		</button>
	</div>

	<mat-accordion class="main-sidebar-accordion">
		<section>
			<ng-container *ngFor="let navigationItem of (navigation$ | async); let i = index">
				<div class="flex-top">
					<gaw-nav-item [navigationItem]="navigationItem" [attr.data-testid]="navigationItem.description"></gaw-nav-item>
				</div>
			</ng-container>
		</section>
		<hr>
		<section>
			<ng-container *ngFor="let navigationItem of (navigationActions$ | async);  let i = index">
				<gaw-nav-item [navigationItem]="navigationItem"></gaw-nav-item>
			</ng-container>
		</section>
		<hr>
		<section>
			<ng-container *ngFor="let navigationItem of navigationHelp; let i = index">
				<gaw-nav-item [navigationItem]="navigationItem"></gaw-nav-item>
			</ng-container>
		</section>
	</mat-accordion>
</div>

