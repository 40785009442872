import { Component, Input } from '@angular/core';

@Component({
	selector: 'dry-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent  {

	@Input() classStyle = 'btn-primary';
	@Input() label = 'Button';
	@Input() tooltipPosition = 'top';
	@Input() tooltip = '';

}
