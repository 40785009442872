import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UpdateSearchModel } from '../../entities/favorite-search/favorite-search';

@Injectable({
	providedIn: 'root'
})
export class FavoriteSearchesHttpService {

	private favoriteSearchHost: string;
	private save: string;
	private load: string;
	private update: string;
	private delete: string;
	private saveOrder: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.favoriteSearchHost = appConfig.gawUserPortalConfiguration.http.host;

		this.save = `${this.favoriteSearchHost}/api/v1/FavoriteSearch/saveuserfavoritesearch`;
		this.load = `${this.favoriteSearchHost}/api/v1/FavoriteSearch/loaduserfavoritesearch?IdLicenza={idLicenza}&Sia={codsia}`;
		this.update = `${this.favoriteSearchHost}/api/v1/FavoriteSearch/updateuserfavoritesearch/{idLicenza}/{codsia}/{guidricerca}`;
		this.delete = `${this.favoriteSearchHost}/api/v1/FavoriteSearch/removeuserfavoritesearch/{idLicenza}/{codsia}/{guidricerca}`;
		this.saveOrder = `${this.favoriteSearchHost}/api/v1/FavoriteSearch/orderuserfavoritesearch/{idLicenza}/{codsia}`;
	}

	getFavoriteSearchesList(idlicenza, codsia): Observable<any> {
		const url = this.load.replace('{idLicenza}', idlicenza).replace('{codsia}', codsia);

		return this.http.get(url).pipe(
			catchError((err: unknown) => {
				// Nel caso in cui non ci siano ricerche salvate per questo utente,
				// viene ritornato 400 Bad request con una string di errore nella response
				// Al di la della filosofia, ritorno un array vuoto, almeno la gestione UX rimane di competenza dell'interfaccia
				if (!err?.['cause'].ok && (err?.['cause'].status === 400 || err?.['cause'].status === 404))
					return of([]);

				throw (err);
			}));
	}
	public saveFavoriteSearchesList(search: UpdateSearchModel): Observable<any> {
		return this.http.post(this.save, search);
	}
	public updateFavoriteSearches(idlicenza: string, codsia: string, guidricerca: string, search: UpdateSearchModel): Observable<any> {
		const url = this.update
			.replace('{idLicenza}', idlicenza)
			.replace('{codsia}', codsia)
			.replace('{guidricerca}', guidricerca);
		return this.http.put(url, search);
	}
	public deleteUserFavoriteSearch(idlicenza: string, codsia: string, guidricerca: string): Observable<any> {
		const url = this.delete
			.replace('{idLicenza}', idlicenza)
			.replace('{codsia}', codsia)
			.replace('{guidricerca}', guidricerca);
		return this.http.delete(url);
	}

	orderUserFavoriteSearch(idlicenza: string, codsia: string, ids: string[]): Observable<any> {
		const url = this.saveOrder
			.replace('{idLicenza}', idlicenza)
			.replace('{codsia}', codsia);
		return this.http.put(url, [...ids]);
	}
}
