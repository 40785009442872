import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';

@Injectable()
export class BridgeGedconsHttpService {

	private bridgeGedconsHost: string;

	constructor(
		private http: HttpClient,
		private configService: ConfigService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.bridgeGedconsHost = appConfig.account.http.host;
	}

	redirectToDnn() {
		const url = `${this.bridgeGedconsHost}/v1/dnnBridge`;
		return this.http.get<string>(url);
	}
}
