import { Injectable } from '@angular/core';
import { UploadEditedHttpService } from './upload-edited-http.service';

@Injectable()
export class UploadEditedService {

	constructor(private uploadEditedInvoice: UploadEditedHttpService) { }

	public uploadInvoice(dochash, progSpool, progBusta, document) {
		return this.uploadEditedInvoice.uploadInvoice(dochash, progSpool, progBusta, document);
	}
}
