import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceType } from '@ctel/gaw-commons';
import { Transfer, TransferService, TransferUtils } from '@ctel/transfer-manager';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { AppError } from 'app/core/common/error/app-error';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { ExcelFormatModalComponent } from 'app/core/common/modals/excel-format-modal/excel-format-modal.component';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { ActionConfigItem } from 'app/entities/ui-config/action/actions-config';
import { Observable, of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { ConfigService } from 'app/core/common/config/config.service';
import { CheckPassModalComponent } from 'app/core/common/modals/check-pass-modal/check-pass-modal.component';
import { PrintBarcodeModalComponent } from 'app/core/common/modals/print-barcode-modal/print-barcode-modal.component';
import { SectionEnum } from 'app/entities/anomalies/section-enum';
import { Company } from 'app/entities/companies/company';
import { AppConfig } from 'app/entities/config/app-config';
import { ChangeStatusEnum } from 'app/entities/ui-config/action/ChangeStatusEnum';
import { DetailsService } from 'app/modules/details/services/details.service';
import { AnomalyHistoryActionModalComponent } from '../../modals/anomaly-history-action-modal.component/anomaly-history-action-modal.component';
import { HistoryActionModalComponent } from '../../modals/history-action-modal.component/history-action-modal.component';
import { AnomaliesService } from '../../services/anomalies.service';
import { UploadDocumentModalComponent } from '../../upload-document-modal/upload-document-modal.component';
import { CheckboxService } from '../checkbox/checkbox.service';
import { DocumentDetailsService } from '../document-details/document-details.service';
import { DocumentsSeriesService } from '../documents-series/documents-series.service';
import { DocumentsService2 } from '../documents/documents.service';
import { ActionsHttpService } from './actions-http.service';
import { ActionsService2 } from './actions.service';

/**
 * Servizio che gestisce l'esecuzione delle azioni sui documenti
 */
@Injectable({
	providedIn: 'root'
})
export class ExecuteActionsService {

	currentCompany: Company;
	private appConfig: AppConfig;

	constructor(
		private actionsHttpService: ActionsHttpService,
		private notificationService: NotificationService,
		private actionsService: ActionsService2,
		private documentDetailsService: DocumentDetailsService,
		private documentsService: DocumentsService2,
		private documentsSeriesService: DocumentsSeriesService,
		private checkboxService: CheckboxService,
		private companiesService: CompaniesService,
		private router: Router,
		private transferService: TransferService,
		private detailsService: DetailsService,
		private anomaliesService: AnomaliesService,
		confService: ConfigService
	) {
		this.currentCompany = companiesService.getCurrentCompanyValue();
		confService.whenAppConfig().subscribe(value => this.appConfig = value);
	}

	public whenLoadingAction(): Observable<boolean> {
		return this.actionsHttpService.whenLoadingAction();
	}

	// ESECUZIONE AZIONE SINGOLA
	public executeSingleAction(action: ActionObject | ActionConfigItem, keys: any) {
		let actionCode = '';
		let actionUrl = '';

		if (action instanceof ActionObject) {
			actionCode = action.actionCode;
			actionUrl = action.url;
		} else {
			actionCode = action.actionCode;
			actionUrl = action.actionUrl;
		}

		let body: any;

		const basicPayload = {
			progSpool: keys.progSpool,
			progBusta: keys.progBusta,
			docHash: keys.hashDocKey,
			ctelElasticDocumentId: keys.ctelElasticDocumentId,
			ctelDocSeriesId: keys.ctelDocSeriesId,
			ultimoStato22: keys.ultimoStato22,
			idComunicazione22: keys.idComunicazione22
		};

		switch (actionCode) {
			// GAWPASS SINGLE ACTIONS
			case ActionCode.EDIT_BOX:
				this.router.navigate(['gawpass/new', keys.idScatola]).then();
				break;

			case ActionCode.CHECK_AS_RECEIVED:
			case ActionCode.CHECK_AS_DELETED:
			case ActionCode.CHECK_AS_FORWARDED:
			case ActionCode.CANCEL_STATE_SELECTION:
			case ActionCode.CHECK_AS_REJECTED: {
				const checkPassAction$ = (nextStatus: ChangeStatusEnum, notes: string) => {

					body = {
						idLicenza: this.currentCompany.licenseId,
						codSia: this.currentCompany.siaCode,
						idScatola: keys.idScatola,
						ultimoStato: +keys.ultimoStato,
						changeStato: nextStatus,
						changeStatoNote: notes,
						dataUltimoStato: keys.dataUltimoStato,
						codiceScatola: keys.codice
					};

					return this.actionsHttpService.whenCheckPass(actionUrl, body).pipe(
						catchError((err: unknown) => this.catchError(err as AppError)),
						tap(() => {
							this.detailsService.sendRefreshDetails();
							setTimeout(() => {
								this.documentsService.refreshDocuments();
							}, 3000);
						}
						)
					);
				};

				this.notificationService.showModal(NotificationType.GENERAL, {
					title: action.name,
					childComponent: CheckPassModalComponent,
					customFooter: true,
					childData: {
						action: checkPassAction$,
						title: action.name,
						actionParams: actionCode
					},
					disableClickOutside: false
				});

				break;
			}

			case ActionCode.PROCESS_DOCUMENT:
			case ActionCode.CANCEL_DOCUMENT: {
				const changeStatusAction$ = () => {

					body = [{
						idLicenza: this.currentCompany.licenseId,
						codSia: this.currentCompany.siaCode,
						codGrafica: keys.codGrafica,
						idSospeso: keys.idSospeso,
						idStato: keys.idStato
					}];

					return this.actionsHttpService.whenChangeStatusAnomaly(actionUrl, body);
				};

				this.notificationService.showSweetAlert(NotificationType.QUESTION,
					actionCode === ActionCode.CANCEL_DOCUMENT
						? 'Si desidera eseguire l\'operazione di annullo?'
						: 'Si desidera procedere con l\'operazione di elaborazione documento?',
					'',
					() => changeStatusAction$().subscribe({
						next: () => {
							this.notificationService.showSweetAlert(NotificationType.SUCCESS, 'Operazione eseguita correttamente', '');
							setTimeout(() => {
								this.detailsService.sendRefreshDetails();
								this.documentsService.refreshDocuments();
							}, 3000);
						},
						error: (error: unknown) => {
							this.notificationService.showSweetAlert(
								NotificationType.ERROR,
								'Errore',
								error['additionalInfo'].find(value => value.t === 'response').u || 'Errore generico');
						}
					}));

				break;
			}

			case ActionCode.SPOOL_HISTORY:
				this.actionsHttpService.whenChangesHistory(
					actionUrl, keys.idScatola, this.currentCompany.licenseId, this.currentCompany.siaCode
				).pipe(
					take(1),
					catchError((err: unknown) => this.catchError(err as AppError))
				).subscribe((result) => {
					if (result) {
						const title = 'Storico stati';
						this.actionsService.sendHistory(result);
						this.notificationService.showModal(NotificationType.LARGE, {
							title,
							customFooter: false,
							childComponent: HistoryActionModalComponent,
							childData: basicPayload,
							disableClickOutside: false
						});
					} else
						this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Storico non trovato');

				});
				break;

			case ActionCode.ANOMALY_HISTORY:
				this.actionsHttpService.whenChangesAnomalyHistory(actionUrl, keys.idSospeso)
					.pipe(
						take(1),
						catchError((err: unknown) => this.catchError(err as AppError))
					).subscribe((result) => {
						if (result) {
							const title = 'Storico stati';
							this.actionsService.sendAnomalyHistory(result);
							this.notificationService.showModal(NotificationType.LARGE, {
								title,
								customFooter: false,
								childComponent: AnomalyHistoryActionModalComponent,
								childData: basicPayload,
								disableClickOutside: false
							});
						} else
							this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Storico non trovato');

					});
				break;

			case ActionCode.PRINT_BARCODE:

				this.notificationService.showModal(NotificationType.CUSTOM,
					{
						title: 'Stampa Barcode',
						childComponent: PrintBarcodeModalComponent,
						customFooter: true,
						childData: {
							graphic: 'A',
							licenseId: this.currentCompany.licenseId,
							siaCode: this.currentCompany.siaCode,
							code: keys.codice,
							note: keys.note,
							idScatola: keys.idScatola
						}
					});

				break;

			case ActionCode.OPEN_BOX_DETAIL: {
				const boxUrl = 'details/gawpass/'
					+ this.currentCompany.licenseId + '/'
					+ this.currentCompany.siaCode + '/'
					+ SectionEnum.SHIPMENTS + '/'
					+ this.appConfig.passDocSeriesId + '/'
					+ ServiceType.GAWPASS + '/'
					+ keys.codiceScatola;
				this.router.navigate([boxUrl]).then();
				break;
			}

			case ActionCode.EDIT_ANOMALY: {
				this.documentDetailsService.anomalyEdit.next(true);
				const editUrl = 'details/gawpass/'
					+ this.currentCompany.licenseId + '/'
					+ this.currentCompany.siaCode + '/'
					+ SectionEnum.ANOMALIES + '/'
					+ keys.ctelDocSeriesId + '/'
					+ ServiceType.GAWPASS + '/'
					+ keys.idSospeso;
				this.router.navigate([editUrl]).then();
				break;
			}

			case ActionCode.DOWNLOAD_PDF:
				this.actionsHttpService.whenDownloadPdf(keys.progSpool, keys.progBusta, true, keys.idFilePathPdf)
					.pipe(
						take(1),
						catchError((err: unknown) => this.catchError(err as AppError))
					).subscribe((result) => {
						if (result !== null) {
							const r = result as Transfer;
							if (TransferUtils.isHttpResponse(r.originatingEvent)) {
								const date = formatDate(new Date(), 'yyyyMMdd hh:mm:ss', 'en-US');
								const filename = `GAWPASS_${keys.progSpool}${keys.progBusta}_${date}`;
								const blob = new Blob([r.originatingEvent.body], { type: 'application/pdf' });
								new FileSaver(blob).saveAs(filename, FileSaverExtension.PDF);
							} else
								this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');

						} else
							this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');

					});
				break;

			case ActionCode.UPLOAD_DOCUMENT:
				this.notificationService.showModal(NotificationType.CUSTOM, {
					title: 'Carica file',
					customFooter: true,
					disableClickOutside: true,
					childComponent: UploadDocumentModalComponent,
					childData: {
						selection: '.pdf',
						progressivoLotto: basicPayload.progSpool,
						progressivo: basicPayload.progBusta,
						idFilePath: keys.idFilePathPdf,
						nomeFlusso: keys.nomeFlusso,
						idSospeso: keys.idSospeso
					}
				});
				break;

			default:
				console.warn('ACTION non configurata', actionCode);
				break;
		}
	}

	// ESECUZIONE AZIONI MULTIPLE
	public executeMultiAction(action: ActionObject | ActionConfigItem | any, keys: any, sectionCode?: string) {

		let body: any;
		const docCount = keys.length;

		let actionCode = '';
		let actionUrl = '';

		if (action instanceof ActionObject) {
			actionCode = action.actionCode;
			actionUrl = action.url;
		} else {
			actionCode = action.actionCode;
			actionUrl = action.actionUrl;
		}

		switch (actionCode) {
			// massive/multi
			case ActionCode.DOWNLOAD_EXCEL: {
				// Modal della scelta del formato dell'excel
				const showModal = () => {
					this.notificationService.showModal(NotificationType.GENERAL, {
						title: 'Esporta risultati in Excel',
						customFooter: true,
						childComponent: ExcelFormatModalComponent,
						childData: {
							backButton: true,
							action,
							actionType: 'multiple',
							docSeriesDescription: this.documentsSeriesService.getDocSeriesLabel(),
							keys,
							service: 'GAWPASS',
							sectionCode
						}
					});
				};

				if (docCount > 50000)
					this.notificationService.showSweetAlert(
						NotificationType.QUESTION,
						'Numero massimo di documenti superato.',
						'Procedere all\'esportazione dei primi 50000 documenti?',
						showModal
					);
				else
					showModal();

				break;
			}

			case ActionCode.CANCEL_DOCUMENT:
			case ActionCode.PROCESS_DOCUMENT: {
				const changeStatusAction$ = () => {
					body = keys.map(key => ({
						idLicenza: this.currentCompany.licenseId,
						codSia: this.currentCompany.siaCode,
						codGrafica: key.codGrafica,
						idSospeso: key.idSospeso,
						idStato: key.idStato
					}));

					return this.actionsHttpService.whenChangeStatusAnomaly(actionUrl, body);
				};

				this.notificationService.showSweetAlert(NotificationType.QUESTION,
					actionCode === ActionCode.CANCEL_DOCUMENT
						? 'Si desidera eseguire l\'operazione di annullo?'
						: 'Si desidera procedere con l\'operazione di elaborazione documento?',
					'',
					() => changeStatusAction$().subscribe({
						next: () => {
							this.detailsService.sendRefreshDetails();
							setTimeout(() => {
								this.documentsService.refreshDocuments();
							}, 3000);
						},
						error: (error: unknown) => {
							this.notificationService.showSweetAlert(
								NotificationType.ERROR,
								'Errore',
								error['additionalInfo'].find(value => value.t === 'response').u || 'Errore generico'
							);
						}
					}));

				break;
			}
		}
	}

	private catchError(err: AppError): Observable<null> {
		if (err.type === ErrorTypes.HTTP_UNAUTHORIZED)
			//this.router.navigate(['/unauthorized']).then();
			return of(null);
		else
			return of(null);

	}
}
