import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArchivioFiscaleService, ExecuteActionsService } from '@ctel/gawcons-commons';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { Observable, Subject, takeUntil } from 'rxjs';
import { IGawconsDetailsComponentInterface } from '../../../entities/gawcons-details-component.interface';
import { PreviousVersion } from '../../../entities/previous-version';
import { ActionsConfigurationService } from '../../../ui-configuration/actions-configuration/actions-configuration.service';

@Component({
	selector: 'gaw-previous-version',
	templateUrl: './previous-version.component.html',
	styleUrls: ['./previous-version.component.scss']
})
export class PreviousVersionComponent implements OnInit, OnDestroy {
	@Input() data: { docDetails: IGawconsDetailsComponentInterface, action: ActionObject };
	previousVersions: Observable<PreviousVersion[]>;
	documentId: string;
	licenseId: string;
	siaCode: string;
	fiscalYear: string;
	registryId: string;
	faIcons = FaIcons;
	icon;

	private destroy$ = new Subject<void>();

	constructor(
		private archivioFiscaleService: ArchivioFiscaleService,
		private executeActionService: ExecuteActionsService,
		private actionsConfigurationService: ActionsConfigurationService,
		private route: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
			this.documentId = (params.elasticid || this.data.docDetails.documentId).split('_')[0];
			this.licenseId = params.license || this.data.docDetails.licenseId;
			this.siaCode = params.siacode || this.data.docDetails.siaCode;
			this.fiscalYear = params.fiscalYear || this.data.docDetails.keys['annoFiscale'];
			this.registryId = params.registryId || this.data.docDetails.registryId;
		});
		this.previousVersions = this.archivioFiscaleService.getPreviousVersions(
			this.documentId,
			this.licenseId,
			this.siaCode,
			this.fiscalYear,
			this.registryId
		);
		this.icon = this.actionsConfigurationService.getExtensionIcon(this.data.docDetails.keys['estensioneFile']);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	downloadPdf(version: PreviousVersion) {
		this.executeActionService.executeSingleAction(this.data.action, {
			...this.data.docDetails, keys:
			{
				...this.data.docDetails.keys,
				idVersione: version.versione,
				annoFiscale: this.fiscalYear,
			},
			registryId: this.registryId
		});
	}

	getIcon(extension: string) {
		return this.actionsConfigurationService.getExtensionIcon(extension);
	}
}
