import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FullScreenSpinnerConfig } from 'app/entities/config/common/full-screen-spinner-config';
import { Observable, combineLatest, of } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { HttpRequestEditor } from '../../utilities/http-request-editor';
import { FullScreenSpinnerService } from './full-screen-spinner.service';

@Injectable()
export class SpinnerHttpInterceptorService implements HttpInterceptor {

	private interceptorName = 'interceptor-spinner';

	private spinnerConfig$: Observable<FullScreenSpinnerConfig> = this.fullScreenSpinnerService.whenConfig();

	private activateOnHttpRequest$: Observable<boolean> = this.spinnerConfig$
		.pipe(
			map(c1 => c1.activateOnHttpRequest)
		);

	private httpHeaderKey$: Observable<string> = this.spinnerConfig$
		.pipe(
			map(c2 => c2.httpHeaderKey)
		);

	constructor(private fullScreenSpinnerService: FullScreenSpinnerService) { }

	intercept(inputRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const inputRequest$: Observable<HttpRequest<any>> = of(inputRequest).pipe(take(1));

		const setFullScreenSpinner = ([, activateOnHttpRequest, httpHeaderKey]) => {

			const isHttpHeaderSet = !(inputRequest.headers.get(httpHeaderKey) === 'true');
			//REMEMBER ME next line disables loaders
			//const isHttpHeaderSet = (inputRequest.headers.get(httpHeaderKey) === 'true');

			const activateFullScreenSpinner = activateOnHttpRequest && isHttpHeaderSet;

			if (activateFullScreenSpinner) {
				// Ad ogni richiesta http invio una notifica di richiesta in caricamento allo spinner full text
				this.fullScreenSpinnerService.sendLoadingText('');
				this.fullScreenSpinnerService.loadingRequest();
			}
			inputRequest = HttpRequestEditor.deleteHttpHeader(inputRequest, httpHeaderKey);
			return inputRequest;
		};

		return combineLatest([inputRequest$, this.activateOnHttpRequest$, this.httpHeaderKey$])
			.pipe(
				take(1),
				map(setFullScreenSpinner),
				map(request => next.handle(request)),
				mergeMap(handle => of(handle)
					.pipe(
						mergeMap(handle => handle),
						tap({
							next: (event: HttpEvent<any>) => {

								if (event instanceof HttpResponse)

									// Ricevo una response, diminuisco il contatore
									this.loadedRequest(inputRequest);

							},
							error: (err: unknown) => {

								this.setSpinnerErrorMessage(err);

								if (err instanceof HttpErrorResponse)
									// Ricevo una response di errore, diminuisco il contatore
									this.loadedRequest(inputRequest);

							},
							complete: () => {
								// Una richiesta è stata cancellata, diminuisco il contatore
								this.loadedRequest(inputRequest);
							}
						}),
					)
				)
			);

	}

	private setSpinnerErrorMessage(err: any) {

		// Aggiorno il testo sullo spinner di caricamento (in attesa di una gestione dell'errore piu' puntuale)
		if (err instanceof HttpErrorResponse) {
			let message = '';
			switch (err.status) {
				case 0:
					message = 'Impossibile connettersi.';
					break;
				case 404:
					message = 'Oggetto non trovato.';
					break;
				case 401:
					message = 'Richiesta non autorizzata.';
					break;
				default:
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					message = 'Errore Http non gestito: ' + err.status;
			}

		}
	}

	private loadedRequest(inputRequest: HttpRequest<any>) {

		this.httpHeaderKey$.subscribe(headerKey => {
			const isHttpHeaderSet = !(inputRequest.headers.get(headerKey) === 'true');

			if (isHttpHeaderSet)
				this.fullScreenSpinnerService.loadedRequest();

		});
	}
}
