import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorPlaceholderModule } from '@ctel/placeholders';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { WorkflowModalComponent } from './workflow-modal.component';
import { WorkitemActivityModule } from './workitem-activity/workitem-activity.module';
import { WorkitemFullFrameComponent } from './workitem-full-frame/workitem-full-frame.component';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { CreateWorkitemModalComponent } from './create-workitem-modal/create-workitem-modal.component';
import { CreateNewWorkitemModalComponent } from './create-new-workitem-modal/create-new-workitem-modal.component';
import { WorkitemDetailModule } from './workitem-preview/workitem-detail.module';
import { WorkflowListForModalComponent } from '../documents-type-item/workflow-list-for-modal/workflow-list-for-modal.component';
import { DocumentsWorkflowModalComponent } from '../documents-type-item/documents-workflow-modal/documents-workflow-modal.component';
import { WorkitemCreationFeedbackModalComponent }
	from '../documents-type-item/workitem-creation-feedback/workitem-creation-feedback-modal.component';

@NgModule({
	imports: [
		CommonModule,
		TabsModule,
		ErrorPlaceholderModule,
		PipesModule,
		TooltipModule,
		// Workitem detail routing
		WorkitemDetailModule,
		// WorkitemHistoryModule.forRoot(),
		WorkitemActivityModule
	],
	declarations: [
		WorkflowModalComponent,
		WorkitemFullFrameComponent,
		CreateWorkitemModalComponent,
		CreateNewWorkitemModalComponent,
		DocumentsWorkflowModalComponent,
		WorkflowListForModalComponent,
		WorkitemCreationFeedbackModalComponent
	],
	exports: [
		WorkflowModalComponent,
		CreateWorkitemModalComponent,
		CreateNewWorkitemModalComponent,
		DocumentsWorkflowModalComponent,
		WorkflowListForModalComponent,
		WorkitemCreationFeedbackModalComponent,
		// Modules
		WorkitemDetailModule
	]
})
export class WorkflowModalModule {
}
