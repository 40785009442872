import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { Spool } from 'app/core/common/entities/spool/spool-data';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { SpoolType } from 'app/core/common/modals/spool-modal/spool-type.enum';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, debounceTime, share, switchMap, take, tap } from 'rxjs/operators';
import { CardsHttpService } from './cards-http.service';

/**
 * Servizio che gestisce lo stato delle cards spool in elaborazione / in errore (totali spool e lista spool)
 */
@Injectable({
	providedIn: 'root'
})
export class SpoolCardsService {

	public readonly SPOOL_PER_PAGE = 10;
	public readonly SPOOL_DEFAULT_PAGE_INDEX = 1;
	public readonly DEFAULT_ORDER: 'asc' | 'desc' = 'desc';

	/** Lista degli spool in attesa di elaborazione */
	public spoolProcessingList$: Observable<Spool[]>;
	/** Lista degli spool in errore */
	public spoolErrorList$: Observable<Spool[]>;
	/** Totale spool in attesa di elaborazione */
	public spoolProcessingCount$: Observable<number>;
	/** Totale spool in errore */
	public spoolErrorCount$: Observable<number>;

	private currentDates$ = new ReplaySubject<[string, string]>(1);

	private pageIndex$: BehaviorSubject<number> = new BehaviorSubject(this.SPOOL_DEFAULT_PAGE_INDEX);
	private pageDimension$: BehaviorSubject<number> = new BehaviorSubject(this.SPOOL_PER_PAGE);
	private order$: BehaviorSubject<'asc' | 'desc'> = new BehaviorSubject(this.DEFAULT_ORDER);

	private spoolLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	private refreshErrorSpool$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private refreshInElabSpool$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	constructor(
		private cardsHttpService: CardsHttpService,
		private companiesService: CompaniesService,
		private router: Router
	) {
		// Inizializzo la data
		this.initializeSpoolCount();

		this.spoolProcessingCount$ = this.whenSpoolCount(SpoolType.SPOOL_ELAB)
			.pipe(
				share({ connector: () => new ReplaySubject(1), resetOnError: false, resetOnComplete: false, resetOnRefCountZero: false })
			);

		this.spoolErrorCount$ = this.whenSpoolCount(SpoolType.SPOOL_ERROR)
			.pipe(
				share({ connector: () => new ReplaySubject(1), resetOnError: false, resetOnComplete: false, resetOnRefCountZero: false })
			);

		// Lista degli spool
		this.spoolProcessingList$ = this.whenSpoolList(SpoolType.SPOOL_ELAB)
			.pipe(
				share({ connector: () => new ReplaySubject(1) })
			);

		this.spoolErrorList$ = this.whenSpoolList(SpoolType.SPOOL_ERROR)
			.pipe(
				share({ connector: () => new ReplaySubject(1) })
			);
	}

	initializeSpoolCount() {
		const dates = this.getDefaultDateFilter();
		this.sendCurrentDates(dates);
	}

	downloadSpool(progSpool: string): Observable<Blob> {
		return this.cardsHttpService.downloadSpool(progSpool)
			.pipe(
				take(1)
			);
	}

	cancelSpool(progSpool: string): Observable<any> {
		return this.cardsHttpService.annullaSpool(progSpool)
			.pipe(
				take(1)
			);
	}

	// l'ultima modal aperta
	whenCurrentDates(): Observable<[string, string]> {
		return this.currentDates$.asObservable();
	}

	sendCurrentDates(dates: [string, string]) {
		this.currentDates$.next(dates);
	}

	// spool loading per expressionHasChanged (spinner)
	whenSpoolLoading(): Observable<boolean> {
		return this.spoolLoading$.asObservable();
	}

	public refreshSpools() {
		this.refreshErrorSpool$.next(this.refreshErrorSpool$.getValue() + 1);
		this.refreshInElabSpool$.next(this.refreshInElabSpool$.getValue() + 1);
	}

	public refreshInElabSpool() {
		this.refreshInElabSpool$.next(this.refreshInElabSpool$.getValue() + 1);
	}

	public refreshErrorSpool() {
		this.refreshErrorSpool$.next(this.refreshErrorSpool$.getValue() + 1);
	}

	public setPageIndex(page: number) {
		this.pageIndex$.next(page);
	}

	public whenPageIndex(): Observable<number> {
		return this.pageIndex$.asObservable();
	}

	public whenPageDimension(): Observable<number> {
		return this.pageDimension$.asObservable();
	}

	public setPageDimension(dimension: number) {
		this.pageDimension$.next(dimension);
	}

	// ritorna la lista spool facendo la chiamata http
	private whenSpoolList(spoolType: SpoolType): Observable<Spool[]> {

		const currentCompany$ = this.companiesService.whenCurrentCompany();
		const currentDates$ = this.whenCurrentDates();
		const refreshSpool$ = this.refreshSpoolEvent(spoolType);

		return combineLatest([currentCompany$, currentDates$, refreshSpool$])
			.pipe(
				debounceTime(500),
				tap(() => this.spoolLoading$.next(true)),
				// L'ordinamento non è attualmente utilizzato
				switchMap(([currentCompany, currentDates]) => combineLatest([this.pageIndex$, this.pageDimension$, this.order$])
					.pipe(
						// debounceTime(500)
						switchMap(([index, dimension, order]) =>
							this.cardsHttpService.whenSpools(
								currentCompany.licenseId,
								currentCompany.siaCode,
								spoolType,
								index.toString(),
								dimension.toString(),
								currentDates[0],
								currentDates[1],
								order
							).pipe(
								catchError((err: unknown) => {
									if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
										//this.router.navigate(['/unauthorized']).then();
									}
									return EMPTY;
								})
							)
						)
					)
				),
				tap({
					next: () => this.spoolLoading$.next(false),
					error: () => this.spoolLoading$.next(false)
				}),
			);
	}

	// Ritorna il totale degli spool
	private whenSpoolCount(spoolType: SpoolType): Observable<number> {

		const currentCompany$ = this.companiesService.whenCurrentCompany();
		const currentDates$ = this.whenCurrentDates();
		const refreshSpool$ = this.refreshSpoolEvent(spoolType);

		// richiedo entrambi gli spool count da inizio anno ad oggi
		return combineLatest([currentCompany$, currentDates$, refreshSpool$])
			.pipe(
				switchMap(([currentCompany, currentDates]) =>
					this.cardsHttpService.whenSpoolCount(
						currentCompany.licenseId, currentCompany.siaCode, spoolType, currentDates[0], currentDates[1]
					)),
			);
	}

	private refreshSpoolEvent(spoolType: SpoolType): Observable<any> {
		return spoolType === SpoolType.SPOOL_ERROR ? this.refreshErrorSpool$ : this.refreshInElabSpool$;
	}

	// noinspection JSMethodCanBeStatic
	private getDefaultDateFilter(): [string, string] {
		// ultimo trimestre
		return [
			// moment().startOf('year').toISOString(),
			// moment().endOf('day').set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toISOString()
			moment().subtract(3, 'month').utcOffset(0).set({
				hour: 0,
				minute: 0,
				second: 0,
				millisecond: 0
			}).toISOString(),
			moment().endOf('day').set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toISOString()
		];
	}

}
