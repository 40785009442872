import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/it';

/**
 * Pipe che converte un datetime iso in datetime dd/mm/yyyy hh:mm:ss
 */
@Pipe({
	name: 'isoDatetimeToDatetime'
})
export class IsoDatetimeToDatetimePipe implements PipeTransform {

	transform(value: string): string {

		if (value !== null && value !== '' && value !== undefined) {
			const isoFormat = 'YYYY-MM-DDThh:mm:ss.SSSZ';
			const italianFormat = 'DD/MM/YYYY HH:mm:ss';
			return moment(value, isoFormat).format(italianFormat);
		} else
			return value;

	}
}

