import { Injectable } from '@angular/core';
import { DocumentsResponse } from '@ctel/gaw-commons';
import { AbstractDocumentEffects, DocumentActions, DocumentState, FilterActions, getRouterInfo } from '@ctel/search-filter-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DocumentsHttpService } from '../../documents-http.service';
import { PddDocumentsService2 } from '../documents.service';
import { getFiltersStateWithUserValuesGawcons } from './document.extended';

const PATH = 'gawcons/gcons_pacchettiDistribuzione/';

@Injectable({
	providedIn: 'root'
})
export class DocumentEffects extends AbstractDocumentEffects {

	/**
	 * Effetto che gestisce il side effect di aver ricevuto i nuovi documenti,
	 * e per i quali è necessario eventualmente raffinare i filtri.
	 */
	onDocumentsFetched$ = createEffect(() => this.actions$.pipe(
		ofType(DocumentActions.documentsFetched),
		withLatestFrom(this.store.pipe(select(getRouterInfo))),
		filter(([action, routerInfo]) => !action.payload.chunked && routerInfo.state.url.indexOf(PATH) !== -1),
		map(([{ payload }]) => {
			if (this.raffinamentoIterativo)
				return FilterActions.refineFiltersRequested({ filters: payload.filters });

			return FilterActions.doNotRefineFiltersRequested({ filters: payload.filters });
		})
	));

	/**
	 * Effetto che gestisce il side effect di aver richiesto nuovi documenti.
	 */
	onFetchDocumentsRequired$ = createEffect(() => this.actions$.pipe(
		ofType(DocumentActions.fetchDocuments),
		mergeMap(payload =>
			of(payload).pipe(
				withLatestFrom(
					this.store.pipe(select(getFiltersStateWithUserValuesGawcons({
						fromFavourites: payload.payload.fromFavourites,
						technicalFilters: []
					}))),
					this.store.pipe(select(getRouterInfo))
				))
		),
		filter(([, filterState, routerInfo]) => !filterState.requested && routerInfo.state.url.indexOf(PATH) !== -1),
		switchMap(([, filterState]) => {
			const chunked = filterState.paging && filterState.paging.offset > 0;
			this.documentsService.setLoadingDocs(true);
			this.documentsService.setErrorLoadingDocs(false);
			// TODO spostare al BE quando viene effettuato il refactoring
			return this.documentsHttpService.whenAllDocuments(JSON.stringify(filterState)).pipe(
				map((documentsResponse: DocumentsResponse) =>
					DocumentActions.documentsFetched(
						documentsResponse.docs,
						documentsResponse.totalDocs,
						documentsResponse.filters.map(value => {
							if (value.metadata === 'annoFiscale')
								value.configData.buckets.sort((a, b) => {
									if (a.key > b.key)
										return -1;

									return 1;
								});

							return value;
						}),
						documentsResponse.metrics,
						chunked
					)
				),
				tap(() => {
					this.documentsService.setLoadingDocs(false);
					this.documentsService.setLoadingDocsAfterFilterApplication(false);
					this.documentsService.setLoadingDocsOnPaging(false);
					this.documentsService.setLoadingDocsOnSectionChange(false);
				}),
				catchError((error: unknown) => {
					this.documentsService.setLoadingDocs(false);
					this.documentsService.setLoadingDocsAfterFilterApplication(false);
					this.documentsService.setLoadingDocsOnPaging(false);
					this.documentsService.setLoadingDocsOnSectionChange(false);
					this.documentsService.setErrorLoadingDocs(true);
					return this.handleError(error);
				})
			);
		})
	));

	constructor(
		protected actions$: Actions,
		protected store: Store<DocumentState>,
		private documentsHttpService: DocumentsHttpService,
		private documentsService: PddDocumentsService2
	) {
		super(actions$, store);
	}
}
