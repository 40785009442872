import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from '@ctel/block-ui';
import { DocumentsService } from '@ctel/hubfe-commons';
import { LargePlaceholderComponent } from '@ctel/placeholders';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { DocSeriesService } from 'app/core/business/doc-series/doc-series.service';
import { GraficaValueService } from 'app/core/business/grafiche/shared/grafica-value.service';
import { UploadLottoService } from 'app/core/business/invoice-pa/upload-documents/upload-zip/upload-lotto.service';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { Classification } from 'app/entities/classification/classification';
import { Company } from 'app/entities/companies/company';
import { DocClass } from 'app/entities/doc-class/doc-class.interface';
import { Upload } from 'app/entities/drag-and-drop-upload/upload';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { DocGraphic } from 'app/entities/grafiche/doc-graphic';
import { UploadLottoMessage } from 'app/entities/invoice-pa/upload-lotto-message/upload-lotto-message';
import * as _ from 'lodash';
import { EMPTY, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { FoldersService } from '../gaw30/modules/folders/folders.service';

declare type ActionType = 'upload' | 'data-entry' | undefined;
declare type Path = undefined | 'multiple' | 'single';

interface DocClassColor extends DocClass {
  color: string;
}

interface ClassificationColor extends Classification {
  color: string;
}

@Component({
  selector: 'hub-new',
  templateUrl: './upload-lotto-zip.component.html',
  styleUrls: ['./upload-lotto-zip.component.scss'],
  animations: [
    trigger('slide', [
      state('*', style({ width: '0px', transform: 'translateX(-100%)' })),
      state('in', style({ width: '120px', transform: 'translateX(0%)' })),
      state('out', style({ width: '0px', transform: 'translateX(-100%)' })),
      transition('* => *', [animate('1s 0s ease-in-out')]),
    ]),
  ],
})
export class UploadLottoZipComponent implements OnInit, OnDestroy {
  @BlockUI('uploading-docs-loader') uploadingDocsLoader: NgBlockUI;

  public title = 'Carica documenti';
  public faIcons = FaIcons;
  public folderId: undefined | null | string;
  public idSerieDoc: string;
  public showSeries;
  public showAction;
  public showUploadPage;
  public showBackButton;
  public animateBackButton;
  public path: Path;
  public graphics: DocGraphic[];
  public receivableGraphics: DocGraphic[] = [];
  public codGrafica: string;
  public channelType: number;
  public showClassification: boolean;
  public showDocSeries: boolean;
  public classifications: Array<ClassificationColor> = [];
  public docSeries: Array<DocClassColor> = [];
  public shouldGoBack: boolean;
  public company: Company;
  public file: File;
  public lottoName: string;
  public fileLoaded: boolean;
  public currentUpload: Upload;
  public dropzoneActive = false;
  public siaCode;
  public messageUploadLotto: UploadLottoMessage[] = [];
  public classHovered = 'jumbotron uploadPage';
  public colors = ['#FF9500', '#0099CC', '#009900', '#666666'];
  public fileUploaded: boolean;
  public maxFileSize = 131072000;
  public classificationLoading: boolean;
  public graphicsLoading: boolean;
  public tileErrorPlaceholderErrorOption = { message: 'Errore imprevisto.' };
  public graphicError = false;
  public classificationError = false;
  public action: ActionType;
  public classificationCode: string;
  public tilesLoaded: boolean;
  blockTemplate = LargePlaceholderComponent;
  loading = true;
  loadingTemplate = LargePlaceholderComponent;
  private upload: boolean;
  private edit: boolean;
  private classification: unknown;
  private destroy$ = new Subject<void>();

  private isSingleCompany: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private setGraficaService: GraficaValueService,
    private companiesService: CompaniesService,
    private lottoService: UploadLottoService,
    private notificationService: NotificationService,
    private documentsService: DocumentsService,
    private docSeriesService: DocSeriesService,
    private foldersService: FoldersService,
  ) {
    this.documentsService.sendCurrentAccountLabel('');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.company = {};
    this.companiesService
      .whenCurrentCompany()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.company = data;
        this.init();
      });

    this.companiesService
      .whenCompanies()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => (this.isSingleCompany = data.length === 1));

    this.route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
      this.folderId = paramMap.get('id');
      this.idSerieDoc = paramMap.get('idSerieDoc');

      if (paramMap.get('action') !== 'data-entry') {
        this.foldersService.ancestors = null;
      }

      if (paramMap.get('action') && this.action !== paramMap.get('action')) {
        this.setAction(paramMap.get('action') as ActionType);
      }
    });
  }

  init() {
    this.loading = false;
    this.classificationCode = undefined;
    this.action = undefined;
    this.showAction = true;
    this.classificationLoading = false;
    this.graphicsLoading = false;
    this.showSeries = false;
    this.showClassification = false;
    this.showDocSeries = false;
    this.showUploadPage = false;
    this.showBackButton = false;
    this.animateBackButton = false;
    this.path = undefined;

    this.docSeries = [];
    this.lottoName = null;
    this.fileLoaded = false;
    this.title = 'Carica documenti';

    if (this.classification && this.classification === 'fatture-attive') {
      this.upload = false;
      this.edit = true;
      this.classificationClick('fattureAttive');
    }
  }

  goBack() {
    if (this.showSeries) {
      this.setAction(this.action);
    } else if (this.showUploadPage) {
      this.classificationClick(this.classificationCode, true);
    }
  }

  public getClassification() {
    this.docSeriesService
      .getClassification(this.company.licenseId, this.company.siaCode)
      .pipe(
        take(1),
        tap(() => (this.tilesLoaded = false)),
      )
      .subscribe({
        next: (data) => {
          if (data.length === 1) {
            this.classificationClick(data[0].code);
            this.shouldGoBack = false;
          } else {
            this.manageClick();
            this.classificationLoading = false;
            this.classificationError = false;
            this.classifications = this.setColor(data);
            if (data.length !== 0) {
              this.shouldGoBack = true;
            }

            this.tilesLoaded = true;
          }
        },
        error: () => {
          this.manageClick();
          this.classificationLoading = false;
          this.classificationError = true;
        },
      });
  }

  manageClick() {
    if (this.classification && this.classification === 'fatture-attive') {
      this.upload = false;
      this.edit = true;
      this.classificationClick('fattureAttive');
    }
  }

  public onUploadFilesClick() {
    this.showAction = false;
    this.showUploadPage = true;
    this.showBackButton = false;
    this.showSeries = false;
    this.showClassification = false;

    this.upload = true;
    this.edit = false;
  }

  onBackClick() {
    this.init();
    this.router.navigate(this.idSerieDoc ? ['gaw30/folders'] : [], {
      relativeTo: this.idSerieDoc ? null : this.route,
      queryParams: this.idSerieDoc ? { id: this.folderId, idSerieDoc: this.idSerieDoc } : {},
      skipLocationChange: !this.idSerieDoc, // Non attiva la navigazione
    });
  }

  public classificationClick(code: string, goBack = false) {
    this.classificationCode = code;
    this.loading = true;
    this.tilesLoaded = false;
    this.docSeriesService
      .getDocClass(this.company.licenseId, this.company.siaCode, code)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.docSeries = this.setColor(data);
          this.showClassification = false;
          this.showSeries = true;
          this.showDocSeries = true;
          this.animateBackButton = true;
          this.showBackButton = true;
          this.showAction = false;
          this.graphicError = false;
          this.graphicsLoading = false;
          this.tilesLoaded = true;
          this.showUploadPage = false;

          // Nel caso di una singola serie documentale non visualizzo la lista ma vado direttamente alla stessa
          if (data.length === 1) {
            if (goBack) {
              this.setAction(this.action);
            } else {
              this.graficaClick(data[0].graphicsCode, data[0].docClassCode);
            }
          }
        },
        error: () => {
          this.graphicError = true;
          this.graphicsLoading = false;
        },
      });
  }

  public graficaClick(codGrafica: string, docClassCode: string) {
    this.channelType = docClassCode === 'fatturePassive' ? 2 : docClassCode === 'fattureAttive' ? 1 : 0;
    this.codGrafica = codGrafica;
    this.setGraficaService.newGrafica(this.codGrafica, this.channelType);
    this.showDocSeries = false;
    this.showSeries = false;
    this.showBackButton = true;
    if (this.upload) {
      this.showUploadPage = true;
    }

    if (this.edit) {
      const url = `createinvoice/${this.company.licenseId}/${this.company.siaCode}/${this.codGrafica}/${this.channelType}`;
      this.router.navigate([url], { queryParamsHandling: 'preserve' }).then();
    }
  }

  removeLotto() {
    this.lottoName = null;
    this.fileLoaded = false;
  }

  public uploadLotto(): void {
    if (this.isSingleCompany) {
      this.uploadLottoImpl();
    } else {
      this.notificationService.showSweetAlert(
        NotificationType.QUESTION,
        `Stai tentando di caricare dei documenti per conto dell'azienda ${this.company.companyDescription}`,
        "Verifica che l'azienda scelta sia quella corretta. Confermi il caricamento dei documenti?",
        () => this.uploadLottoImpl(),
      );
    }
  }

  dropzoneState($event: boolean) {
    if (!$event) {
      this.classHovered = 'jumbotron uploadPage';
    } else {
      this.classHovered = 'jumbotron boxHovered';
    }

    this.dropzoneActive = $event;
  }

  handleDrop(fileList: FileList) {
    const filesIndex = _.range(fileList.length);
    _.each(filesIndex, (idx) => {
      this.currentUpload = new Upload(fileList[idx]);
    });

    const file: File = fileList[0];
    if (!file) {
      return Promise.resolve();
    }

    this.createLotto(file);
  }

  // upload lotto
  public onFileChange(e: unknown): Promise<void> {
    const file = e['target']?.files[0];
    if (!file) {
      return Promise.resolve();
    }

    this.createLotto(file);
  }

  /*
		REDIRECT
	 */
  onHomeClick() {
    // se sono già sulla home, return
    if (window.location.href.indexOf('/home') !== -1) {
      return;
    }

    this.router.navigate(['/home']).then();
  }

  handleQueryParams(queryParams: { action: string }) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge', // Preserva i queryParams già esistenti
      skipLocationChange: true, // Non attiva la navigazione
    });
  }

  private uploadLottoImpl(): void {
    this.fileLoaded = false;
    if (!this.uploadingDocsLoader.isActive) {
      this.uploadingDocsLoader.start();
    }

    this.lottoService
      .uploadLotto(this.file, this.company.licenseId, this.company.siaCode, this.codGrafica)
      .pipe(
        catchError((err: unknown) => {
          this.uploadingDocsLoader.stop();
          if (Array.isArray(err['cause']?.error)) {
            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              'Rilevati file corrotti',
              err['cause']?.error,
            );
          } else {
            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              'Errore durante upload',
              err['cause']?.error?.message,
            );
          }

          this.fileUploaded = false;
          return EMPTY;
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.uploadingDocsLoader.stop();
          this.fileUploaded = true;
          let msg = 'Caricati con successo i seguenti lotti:' + '\n';
          const bulletPoint = '\u2022'; // Carattere per il punto (•)
          if (data.length < 2) {
            this.messageUploadLotto = data;
          } else {
            data.forEach((element) => {
              msg += bulletPoint + element.nomeFile + '\n';
            });
          }

          this.removeLotto();
          const selectedDocSeries = this.docSeries.find((ds) => ds.graphicsCode === this.codGrafica)?.description;
          if (data.length < 2) {
            this.notificationService.showSweetAlert(
              NotificationType.SUCCESS,
              'Upload eseguito con successo',
              this.messageUploadLotto[0].message + ` all'interno della Serie Documentale ${selectedDocSeries}`,
              () => this.onHomeClick(),
            );
          } else {
            this.notificationService.showSweetAlert(
              NotificationType.SUCCESS,
              'Upload eseguito con successo',
              msg + ` \n all'interno della Serie Documentale ${selectedDocSeries}`,
              () => this.onHomeClick(),
            );
          }
        },
        error: (err: unknown) => {
          this.notificationService.showSweetAlert(
            NotificationType.ERROR,
            'Errore durante upload',
            err?.['cause']?.error?.message,
          );
          this.fileUploaded = false;
        },
      });
  }

  private setColor(array) {
    return array.map((item, index) => {
      const z = this.getIndex(index, 3);
      return { ...item, color: this.colors[z] };
    });
  }

  private getIndex(index: number, max: number) {
    const z = index > max ? index - (max + 1) : index;
    if (z > max) {
      return this.getIndex(z, max);
    }

    return z;
  }

  private createLotto(file: File): void {
    if (this.checkFile(file)) {
      this.lottoName = file.name;
      this.file = file;
      this.fileLoaded = true;
    } else {
      this.lottoName = undefined;
      this.file = undefined;
      this.fileLoaded = false;
    }
  }

  private checkFile(file: File): boolean {
    // Check file size
    if (this.checkMaxFileSize(file.size)) {
      // Check file extension
      if (this.checkFileExt(file, 'zip')) {
        return true;
      } else {
        this.notificationService.showSweetAlert(NotificationType.ERROR, 'Il file deve avere estensione .zip', '');
        return false;
      }
    } else {
      this.notificationService.showSweetAlert(
        NotificationType.ERROR,
        'File troppo grande, la dimensione massima consentita è ' + this.maxFileSize / 1048576 + ' Mb',
        '',
      );
      return false;
    }
  }

  private checkMaxFileSize(fileSizeLoaded: number): boolean {
    return fileSizeLoaded <= this.maxFileSize;
  }

  private checkFileExt(file: File, ext: string): boolean {
    const fileEx = file.name.split('.').pop().toLowerCase();
    return fileEx === ext;
  }

  private setAction(action: ActionType) {
    this.loading = false;

    this.showAction = false;
    this.showSeries = false;
    this.showClassification = true;

    this.action = action;
    if (this.action === 'upload') {
      this.title = 'Inserisci documenti';
      this.showUploadPage = false;
      this.showBackButton = false;
      this.upload = true;
      this.edit = false;
      this.path = 'multiple';
    } else if (this.action === 'data-entry') {
      this.title = 'Crea documento';
      this.showBackButton = false;
      this.upload = false;
      this.edit = true;
      this.path = 'single';
    } else {
      this.path = undefined;
    }
    this.getClassification();
  }
}
