import { Component } from '@angular/core';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';

@Component({
	// selector: 'app-large-placeholder',
	templateUrl: './large-placeholder.component.html',
	styleUrls: ['./large-placeholder.component.scss']
})
export class LargePlaceholderComponent {
	public faIcons = FaIcons;
}
