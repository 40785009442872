import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceDisabledComponent } from './service-disabled.component';

@NgModule({
	declarations: [ServiceDisabledComponent],
	imports: [
		CommonModule
	],
	exports: [ServiceDisabledComponent]
})
export class ServiceDisabledModule {
}
