import { Component, Input, OnInit } from '@angular/core';
import { Company } from 'app/entities/companies/company';
import { KpiSectionsService } from 'app/modules/homepage/core/kpi-sections/kpi-sections.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

/**
 * Componente modal per l'azione Visualizza storico esiti del passivo
 * visibile solo con ruolo dedicato (richiesta cliente SNAM)
 */
@Component({
	selector: 'gaw-edit-kpi-modal',
	templateUrl: './edit-kpi-modal.component.html',
	styleUrls: ['./edit-kpi-modal.component.scss'],
})
export class EditKpiModalComponent implements OnInit {
	@Input() modal: BsModalRef;
	@Input() data: {
		company: Company,
		kpi: any,
		tables: Observable<any>,
		cards: Observable<any>,
		charts: Observable<any>,
		customKpi: Observable<any>
	};

	rowData = [];

	constructor(private kpiSectionsService: KpiSectionsService) { }

	ngOnInit(): void {
		this.handleData();
	}

	handleData() {
		combineLatest([this.data.cards, this.data.tables, this.data.charts, this.data.customKpi])
			.subscribe(
				([cards, tables, charts, customKpi]) => {
					this.rowData = [
						...this.manageData((cards as any[]).filter(card => card.type !== 'orphans')),
						...this.manageData(tables),
						...this.manageData(charts),
						...this.manageData(customKpi),
						...this.manageData((cards as any[]).filter(card => card.type === 'orphans')),
					];
				}
			);
	}

	manageData(list) {
		return list.map((item) => ({
			...item,
			isActive: item.visible,
		}));
	}

	toggleVisibility(kpi) {
		combineLatest([this.data.cards, this.data.tables, this.data.charts, this.data.customKpi])
			.pipe(
				switchMap(
					([cards, tables, charts, customKpi]) => {
						const cardsList = this.updateValues(cards, kpi);

						const tablesList = this.updateValues(tables, kpi);
						const chartsList = this.updateValues(charts, kpi);
						const customKpiList = this.updateValues(customKpi, kpi);

						const updated = {
							sectionId: this.data.kpi.sectionId,
							name: this.data.kpi.name,
							kpiType: {
								cards: cardsList,
								charts: chartsList,
								tables: tablesList,
								customKpi: customKpiList,
							},
						};

						return this.kpiSectionsService.setKpiList(this.data.company.licenseId, this.data.company.siaCode, updated)
							.pipe(map(() => updated));
					}),
				take(1)
			)
			.subscribe((updated) => {
				this.kpiSectionsService.getKpiList(this.data.company.licenseId, this.data.company.siaCode, updated.sectionId);
			});
	}

	updateValues(kpiList, kpi) {
		return kpiList.map((item) => {
			if (kpi.kpiID === item.kpiID && kpi.name === item.name)
				item.visible = !item.visible;

			return item;
		});
	}
}
