// response richiesta actions per workflow
export interface WorkItemAction {
	name: string;
	description: string;
	additionalProperties: Map<string, string>;	// è sempre presente la proprietà confirm che indica se l'utente deve dare una doppia
	// conferma per procecere
	direct: boolean;		// se true, l'azione è diretta e non richiede la visualizzazione di un form
	form: string;         // nome del form se direct è true, se no è null
	actionURL: string; 		// path relativo a cui puntare per l'azione diretta o per visualizzare il form
	// "/api/v1/workflows/NOME_WORKFLOW_1/items/1/ActionExecution?action_name=SMISTA"
}

export interface ActionExecutePayload {
	actionName: string;
	properties: ActionProperty[];
}

export interface ActionProperty {
	name: string;
	value: string;
}
