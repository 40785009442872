import { Injectable } from '@angular/core';
import { AllDocumentsCallback } from './callbacks/all-documents-callback';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';

@Injectable({
	providedIn: 'root'
})
export class MultiActionsCatalog {

	public readonly signDoc = new ActionBuilder(MultiActionCode.signDoc, ActionCode.SIGN_DOC)
		.setActionURL('/v1/Document/Sign?clientId=HUBFE')
		.build();

	public readonly multiHideDocumentsAction = new ActionBuilder(MultiActionCode.multiHideDocumentsAction, ActionCode.HIDE_DOCUMENTS)
		.setActionURL('/v1/Document/HideAndRestore?hideAndRestoreAction={hide}')
		.build();

	public readonly multiRestoreDocumentsAction =
		new ActionBuilder(MultiActionCode.multiRestoreDocumentsAction, ActionCode.RESTORE_DOCUMENTS)
			.setActionURL('/v1/Document/HideAndRestore?hideAndRestoreAction={hide}')
			.build();

	public readonly exportToManagement = new ActionBuilder(MultiActionCode.exportToManagement, ActionCode.EXPORT_TO_MANAGEMENT)
		.setActionURL('/v1/Document/GEDPASSJOIN/EsportaVersoGestionale')
		.build();

	public readonly reExportToManagment = new ActionBuilder(MultiActionCode.reExportToManagment, ActionCode.RE_EXPORT_TO_MANAGEMENT)
		.setActionURL('/v1/Document/GEDPASSJOIN/Reexport')
		.build();

	public readonly downloadAttachmentsMultiGedinvoice =
		new ActionBuilder(MultiActionCode.downloadAttachmentsMultiGedinvoice, ActionCode.DOWNLOAD_ATTACHMENTS_RECEIVABLE)
			.setActionURL('/v1/Document/GEDINVOICE/DownloadAttachmentsMulti')
			.build();

	// azione multipla di Applica controlli SDI e reinvia
	public readonly checkSdiAndResendGedinvoice =
		new ActionBuilder(MultiActionCode.checkSdiAndResendGedinvoice, ActionCode.CHECK_SDI_AND_RESEND)
			.setActionURL('/v1/Document/ResendDocuments')
			.build();

	public readonly downloadXmlZipGeneric = new ActionBuilder(MultiActionCode.downloadXmlZipGeneric, ActionCode.DOWNLOAD_XML_ZIP_GENERIC)
		.setActionURL('/v1/Document/HBFEDownloadDocuments')
		.build();

	public readonly downloadXmlZipGedinvoice = new ActionBuilder(MultiActionCode.downloadXmlZipGedinvoice, ActionCode.DOWNLOAD_XML_ZIP)
		.setActionURL('/v1/Document/GEDINVOICE/xmlFe/Download')
		.build();

	public readonly downloadXmlZipGedpassJoin = new ActionBuilder(MultiActionCode.downloadXmlZipGedpassJoin, ActionCode.DOWNLOAD_XML_ZIP)
		.setActionURL('/v1/Document/GEDPASSJOIN/xmlFe/Download')
		.build();

	public readonly downloadPdfZipGedinvoice = new ActionBuilder(MultiActionCode.downloadPdfZipGedinvoice, ActionCode.DOWNLOAD_PDF_ZIP)
		.setActionURL('/v1/Document/GEDINVOICE/Scarica')
		.build();

	public readonly downloadPdfZipGedmail = new ActionBuilder(MultiActionCode.downloadPdfZipGedmail, ActionCode.DOWNLOAD_PDF_ZIP)
		.setActionURL('/v1/Document/GEDMAIL/Scarica')
		.build();

	// azioni per il ciclo attivo generico (elenco documenti di tutte le attive)
	public readonly zipDownload = new ActionBuilder(MultiActionCode.zipDownload, ActionCode.DOWNLOAD_PDF_ZIP)
		.setActionURL('/v1/Document/Scarica')
		.build();

	public readonly downloadPdfZipMassive = new ActionBuilder(MultiActionCode.downloadPdfZipMassive, ActionCode.DOWNLOAD_PDF_ZIP)
		.setActionURL('/v1/Document/MassiveAction?clientid=HUBFE')
		.setCallback((action: ActionObject) => this.allDocumentsCallback.downloadPdfZip(action))
		.build();

	public readonly downloadZipGedPost = new ActionBuilder(MultiActionCode.downloadZipGedPost, ActionCode.DOWNLOAD_PDF_ZIP)
		.setActionURL('/v1/Document/GEDPOST/Scarica')
		.build();

	public readonly downloadZipGedPec = new ActionBuilder(MultiActionCode.downloadZipGedPec, ActionCode.DOWNLOAD_PDF_ZIP)
		.setActionURL('/v1/Document/GEDPEC/Scarica')
		.build();

	// GEDPASSJOIN
	public readonly downloadPdfZipGedPassJoin = new ActionBuilder(MultiActionCode.downloadPdfZipGedPassJoin, ActionCode.DOWNLOAD_PDF_ZIP)
		.setActionURL('/v1/Document/GEDPASSJOIN/Scarica')
		.build();

	public readonly mergePdfGedPost = new ActionBuilder(MultiActionCode.mergePdfGedPost, ActionCode.MERGE_PDF)
		.setActionURL('/v1/Document/GEDPOST/DisplayMergedDocuments?download=true')
		.build();

	public readonly exportExcel = new ActionBuilder(MultiActionCode.exportExcel, ActionCode.DOWNLOAD_EXCEL)
		.setActionURL('/v2/documents/export/excel?clientId=HUBFE')
		.build();

	public readonly cancelElabGedInvoice = new ActionBuilder(MultiActionCode.cancelElabGedInvoice, ActionCode.CANCEL_ELAB)
		.setActionURL('/v1/Document/AnnullaElaborazione')
		.setParams({
			channel: 'GedInvoice',
			clientId: 'HUBFE'
		})
		.build();

	public readonly cancelElabGedMail = new ActionBuilder(MultiActionCode.cancelElabGedMail, ActionCode.CANCEL_ELAB)
		.setActionURL('/v1/Document/AnnullaElaborazione')
		.setParams({
			channel: 'Gedmail',
			clientId: 'HUBFE'
		})
		.build();

	public readonly cancelElabGedPec = new ActionBuilder(MultiActionCode.cancelElabGedPec, ActionCode.CANCEL_ELAB)
		.setActionURL('/v1/Document/AnnullaElaborazione')
		.setParams({
			channel: 'Gedpec',
			clientId: 'HUBFE'
		})
		.build();

	public readonly cancelElabGedPost = new ActionBuilder(MultiActionCode.cancelElabGedPost, ActionCode.CANCEL_ELAB)
		.setActionURL('/v1/Document/AnnullaElaborazione')
		.setParams({
			channel: 'Gedpost',
			clientId: 'HUBFE'
		})
		.build();

	public readonly cancelElabPremu = new ActionBuilder(MultiActionCode.cancelElabPremu, ActionCode.CANCEL_ELAB)
		.setActionURL('/v1/Document/AnnullaElaborazione')
		.setParams({
			channel: 'Premu',
			clientId: 'HUBFE'
		})
		.build();

	public readonly exportToFtp = new ActionBuilder(MultiActionCode.exportToFtp, ActionCode.EXPORT_TO_FTP)
		.setActionURL('/v1/Document/ExportToFtp')
		.build();

	public readonly excludeFromCons = new ActionBuilder(MultiActionCode.excludeFromCons, ActionCode.EXCLUDE_FROM_CONS)
		.setActionURL('/v1/Document/GEDPASSJOIN/ExcludeProtocol')
		.build();

	public readonly downloadEsitiPdf = new ActionBuilder(MultiActionCode.downloadEsitiPdf, ActionCode.DOWNLOAD_ESITI_PDF)
		.setActionURL('/v1/Document/GEDINVOICE/DownloadAllEsitiSdi')
		.build();

	public readonly downloadEsitiXml = new ActionBuilder(MultiActionCode.downloadEsitiXml, ActionCode.DOWNLOAD_ESITI_XML)
		.setActionURL('/v1/Document/GEDINVOICE/DownloadAllEsitiSdi')
		.build();

	public readonly downloadEsitiExcel = new ActionBuilder(MultiActionCode.downloadEsitiExcel, ActionCode.DOWNLOAD_ESITI_EXCEL)
		.setActionURL('/v1/outcomes/export/excel')
		.build();

	public readonly exportToManagementFromUnpreserved =
		new ActionBuilder(MultiActionCode.exportToManagementFromUnpreserved, ActionCode.EXPORT_TO_MANAGEMENT_FROM_UNPRESERVED)
			.setActionURL('/v1/Document/GEDPASSJOIN/RevertExcludeProtocol')
			.build();

	constructor(private allDocumentsCallback: AllDocumentsCallback) { }
}

export enum MultiActionCode {
	signDoc = 'signDoc',
	multiHideDocumentsAction = 'multiHideDocumentsAction',
	multiRestoreDocumentsAction = 'multiRestoreDocumentsAction',
	downloadAttachmentsMultiGedinvoice = 'downloadAttachmentsMultiGedinvoice',
	exportToManagement = 'exportToManagement',
	reExportToManagment = 'reExportToManagment',
	checkSdiAndResendGedinvoice = 'checkSdiAndResendGedinvoice',
	downloadXmlZipGeneric = 'downloadXmlZipGeneric',
	downloadXmlZipGedinvoice = 'downloadXmlZipGedinvoice',
	downloadXmlZipGedpassJoin = 'downloadXmlZipGedpassJoin',
	downloadPdfZipGedinvoice = 'downloadPdfZipGedinvoice',
	downloadPdfZipGedmail = 'downloadPdfZipGedmail',
	zipDownload = 'zipDownload',
	downloadPdfZipMassive = 'downloadPdfZipMassive',
	downloadZipGedPost = 'downloadZipGedPost',
	downloadZipGedPec = 'downloadZipGedPec',
	downloadPdfZipGedPassJoin = 'downloadPdfZipGedPassJoin',
	mergePdfGedPost = 'mergePdfGedPost',
	exportExcel = 'exportExcel',
	cancelElabGedInvoice = 'cancelElabGedInvoice',
	cancelElabGedMail = 'cancelElabGedMail',
	cancelElabGedPec = 'cancelElabGedPec',
	cancelElabGedPost = 'cancelElabGedPost',
	cancelElabPremu = 'cancelElabPremu',
	exportToFtp = 'exportToFtp',
	downloadEsitiPdf = 'downloadEsitiPdf',
	downloadEsitiXml = 'downloadEsitiXml',
	downloadEsitiExcel = 'downloadEsitiExcel',
	excludeFromCons = 'excludeFromCons',
	exportToManagementFromUnpreserved = 'exportToManagementFromUnpreserved'
}
