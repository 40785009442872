<div class="modal-body">
<div class="main-div">
	<div class="alert alert-danger" *ngIf="data?.docCount > data?.maxDocCount">
		<a href="#" class="close" data-bs-dismiss="alert" aria-label="close">&times;</a>
		<strong>Attenzione!</strong> Hai selezionato <strong>{{data?.docCount}}</strong> documenti.
		L'azione verrà eseguita solo sui primi <strong>{{data?.maxDocCount}}</strong> documenti selezionati.
	</div>
	<div class="confirmation">
			<p *ngIf="data?.docCount <= data?.maxDocCount && data?.docCount > 1"><strong>Proseguire con l'azione
				"{{data?.title}}" sui {{data?.docCount}} documenti selezionati?</strong></p>
			<p *ngIf="data?.docCount <= 1"><strong>Proseguire con l'azione "{{data?.title}}" sul documento
				selezionato?</strong></p>
			<p *ngIf="data?.docCount > data?.maxDocCount"><strong>Proseguire con l'azione "{{data?.title}}" sui
				primi {{data?.maxDocCount}} documenti?</strong></p>
		</div>
	</div>
</div>
<div class="modal-footer">
		<button class="btn btn-success"
				id="action-confirm-button"
			(click)="executeAction()"><strong>Conferma</strong>
	</button>
	<button type="button" class="btn btn-danger" (click)="modal.hide()">Annulla</button>
</div>
