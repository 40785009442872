import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { AccessPermissionService, AuthGuardService, AutoLoginGuard, RolesAccessService } from '../public-api';
import { RolesAccessDirective } from './directives/roles-access.directive';
import { AuthHttpInterceptor } from './interceptors/auth-http.interceptor';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
	],
	declarations: [RolesAccessDirective],
	exports: [RolesAccessDirective]
})
export class AppAuthModule {
	static forRoot(authConfigService: Provider): ModuleWithProviders<AppAuthModule> {
		return ({
			ngModule: AppAuthModule,
			providers: [

				{
					provide: HTTP_INTERCEPTORS,
					useClass: AuthHttpInterceptor,
					multi: true
				},
				authConfigService,
				AuthGuardService,
				AccessPermissionService,
				RolesAccessService,
				AutoLoginGuard
			]
		});
	}
}
