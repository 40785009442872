<div class="d-flex flex-column highlight text-center my-2">
	<div class="align-self-stretch" *ngFor="let parentError of parentErrors; let i = index"
			 [popoverTitle]="parentError.type.code" [popover]="parentError.type.description" [placement]="'right'"
			 [triggers]="'mouseenter:mouseleave'" [container]="'body'">
		<div *ngIf="i>0">&uarr;</div>
		<div class="error-rectangle alert alert-danger text-center">
			<a href="javascript:" *ngIf="!isCurrentError(parentError); else noLink"
				 (click)="setCurrentErrorDetail(parentError)">
				<code>
					{{parentError.description | words : 30}}
				</code>
			</a>
			<ng-template #noLink>
				<code>
					{{parentError.description | words : 30}}
				</code>
			</ng-template>
			<div *ngIf="i === (parentErrors.length - 1) && parentError.cause">
				<div>&uarr;</div>
				<div class="error-rectangle alert alert-dark text-center">
					<a href="javascript:" *ngIf="!isCurrentError(parentError); else noLink"
						 (click)="setCurrentErrorDetail(parentError)">
						<code>
							{{parentError.cause.message | words : 30}}
						</code>
					</a>
					<ng-template #noLink>
						<code>
							{{parentError.cause.message | words : 30}}
						</code>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="mt-4 d-flex align-items-center">
	<h5 class="modal-title">{{currentErrorDetail.type.description}}
		<small><code>{{currentErrorDetail.type.code}}</code></small>
	</h5>
</div>
<hr class="mt-1" />
<dl>
	<dt>Descrizione:</dt>
	<dd>{{ currentErrorDetail.description}}</dd>
	<dt>Data:</dt>
	<dd>{{ currentErrorDetail.errorDate}}</dd>
	<dt>Report id:</dt>
	<dd><code>{{ currentErrorDetail.incidentReportId }}</code></dd>
	<div *ngFor="let info of currentErrorDetail.additionalInfo">
		<dt>{{info.t}}:</dt>
		<dd>{{info.u | json}}</dd>
	</div>
	<dt>Stack:</dt>
	<dd>
		<dry-code-markup-block [code]="currentErrorDetail.stack"></dry-code-markup-block>
	</dd>
</dl>
<div *ngIf="currentErrorDetail.hasCause" class="accordion" id="accordionExample">
	<div class="card">
		<div class="errore-originale">
			<div class="card-header">
				<h5 class="mb-0">
					<button class="btn btn-link" type="button" aria-expanded="true" aria-controls="collapseOne"
									(click)="showEncapsulatedError = !showEncapsulatedError">
						Errore originale
					</button>
				</h5>
			</div>
			<div [collapse]="!showEncapsulatedError">
				<div class="card-body">
					<dl>
						<dt>Errore</dt>
						<dd> {{ currentErrorDetail.cause.name }}</dd>
					</dl>
					<dl>
						<dt>Descrizione</dt>
						<dd> {{ currentErrorDetail.cause.message }}</dd>
					</dl>
					<dry-code-markup-block [code]="currentErrorDetail.cause.stack"></dry-code-markup-block>
				</div>
			</div>
		</div>
	</div>
</div>
