export interface ClientServiceConfigurationResponse {
	classeDoc?: string;
	id: string;
	codsia: string;
	codgrafica: string;
	tipoCanale: Service[];
}

export interface Service {
	channelId: string;
	channelName: string;
}
