export interface ServicesAndColors {
	idTile: number;
	tile: string;
	state: number;
	color: string;
	timestamp: string;
	channelName: string;
	channel: number;
	priorityChannel: boolean;
}
