import { InvoiceMultiValues } from 'app/entities/invoice-pa/invoice-multi-values';

export const Nations: InvoiceMultiValues[] = [
	{ value: 'AC', label: 'Isola Ascensione', labelWithValue: 'Isola Ascensione - AC' },
	{ value: 'AD', label: 'Andorra', labelWithValue: 'Andorra - AD' },
	{ value: 'AE', label: 'Emirati Arabi Uniti', labelWithValue: 'Emirati Arabi Uniti - AE' },
	{ value: 'AF', label: 'Afghanistan', labelWithValue: 'Afghanistan - AF' },
	{ value: 'AG', label: 'Antigua e Barbuda', labelWithValue: 'Antigua e Barbuda - AG' },
	{ value: 'AI', label: 'Anguilla', labelWithValue: 'Anguilla - AI' },
	{ value: 'AL', label: 'Albania', labelWithValue: 'Albania - AL' },
	{ value: 'AM', label: 'Armenia', labelWithValue: 'Armenia - AM' },
	{ value: 'AO', label: 'Angola', labelWithValue: 'Angola - AO' },
	{ value: 'AQ', label: 'Antartide', labelWithValue: 'Antartide - AQ' },
	{ value: 'AR', label: 'Argentina', labelWithValue: 'Argentina - AR' },
	{ value: 'AS', label: 'Samoa americane', labelWithValue: 'Samoa americane - AS' },
	{ value: 'AT', label: 'Austria', labelWithValue: 'Austria - AT' },
	{ value: 'AU', label: 'Australia', labelWithValue: 'Australia - AU' },
	{ value: 'AW', label: 'Aruba', labelWithValue: 'Aruba - AW' },
	{ value: 'AX', label: 'Isole Åland', labelWithValue: 'Isole Åland - AX' },
	{ value: 'AZ', label: 'Azerbaigian', labelWithValue: 'Azerbaigian - AZ' },
	{ value: 'BA', label: 'Bosnia ed Erzegovina', labelWithValue: 'Bosnia ed Erzegovina - BA' },
	{ value: 'BB', label: 'Barbados', labelWithValue: 'Barbados - BB' },
	{ value: 'BD', label: 'Bangladesh', labelWithValue: 'Bangladesh - BD' },
	{ value: 'BE', label: 'Belgio', labelWithValue: 'Belgio - BE' },
	{ value: 'BF', label: 'Burkina Faso', labelWithValue: 'Burkina Faso - BF' },
	{ value: 'BG', label: 'Bulgaria', labelWithValue: 'Bulgaria - BG' },
	{ value: 'BH', label: 'Bahrein', labelWithValue: 'Bahrein - BH' },
	{ value: 'BI', label: 'Burundi', labelWithValue: 'Burundi - BI' },
	{ value: 'BJ', label: 'Benin', labelWithValue: 'Benin - BJ' },
	{ value: 'BL', label: 'Saint-Barthélemy', labelWithValue: 'Saint- BL Barthélemy -' },
	{ value: 'BM', label: 'Bermuda', labelWithValue: 'Bermuda - BM' },
	{ value: 'BN', label: 'Brunei', labelWithValue: 'Brunei - BN' },
	{ value: 'BO', label: 'Bolivia', labelWithValue: 'Bolivia - BO' },
	{ value: 'BQ', label: 'Caraibi olandesi', labelWithValue: 'Caraibi olandesi - BQ' },
	{ value: 'BR', label: 'Brasile', labelWithValue: 'Brasile - BR' },
	{ value: 'BS', label: 'Bahamas', labelWithValue: 'Bahamas - BS' },
	{ value: 'BT', label: 'Bhutan', labelWithValue: 'Bhutan - BT' },
	{ value: 'BW', label: 'Botswana', labelWithValue: 'Botswana - BW' },
	{ value: 'BY', label: 'Bielorussia', labelWithValue: 'Bielorussia - BY' },
	{ value: 'BZ', label: 'Belize', labelWithValue: 'Belize - BZ' },
	{ value: 'CA', label: 'Canada', labelWithValue: 'Canada - CA' },
	{ value: 'CC', label: 'Isole Cocos (Keeling)', labelWithValue: 'Isole Cocos (Keeling) - CC' },
	{ value: 'CD', label: 'Congo - Kinshasa', labelWithValue: 'Congo - CD Kinshasa -' },
	{ value: 'CF', label: 'Repubblica Centrafricana', labelWithValue: 'Repubblica Centrafricana - CF' },
	{ value: 'CG', label: 'Congo-Brazzaville', labelWithValue: 'Congo- CG Brazzaville -' },
	{ value: 'CH', label: 'Svizzera', labelWithValue: 'Svizzera - CH' },
	{ value: 'CI', label: 'Costa d’Avorio', labelWithValue: 'Costa d’Avorio - CI' },
	{ value: 'CK', label: 'Isole Cook', labelWithValue: 'Isole Cook - CK' },
	{ value: 'CL', label: 'Cile', labelWithValue: 'Cile - CL' },
	{ value: 'CM', label: 'Camerun', labelWithValue: 'Camerun - CM' },
	{ value: 'CN', label: 'Cina', labelWithValue: 'Cina - CN' },
	{ value: 'CO', label: 'Colombia', labelWithValue: 'Colombia - CO' },
	{ value: 'CR', label: 'Costa Rica', labelWithValue: 'Costa Rica - CR' },
	{ value: 'CU', label: 'Cuba', labelWithValue: 'Cuba - CU' },
	{ value: 'CV', label: 'Capo Verde', labelWithValue: 'Capo Verde - CV' },
	{ value: 'CW', label: 'Curaçao', labelWithValue: 'Curaçao - CW' },
	{ value: 'CX', label: 'Isola Christmas', labelWithValue: 'Isola Christmas - CX' },
	{ value: 'CY', label: 'Cipro', labelWithValue: 'Cipro - CY' },
	{ value: 'CZ', label: 'Cèchia', labelWithValue: 'Cèchia - CZ' },
	{ value: 'DE', label: 'Germania', labelWithValue: 'Germania - DE' },
	{ value: 'DG', label: 'Diego Garcia', labelWithValue: 'Diego Garcia - DG' },
	{ value: 'DJ', label: 'Gibuti', labelWithValue: 'Gibuti - DJ' },
	{ value: 'DK', label: 'Danimarca', labelWithValue: 'Danimarca - DK' },
	{ value: 'DM', label: 'Dominica', labelWithValue: 'Dominica - DM' },
	{ value: 'DO', label: 'Repubblica Dominicana', labelWithValue: 'Repubblica Dominicana - DO' },
	{ value: 'DZ', label: 'Algeria', labelWithValue: 'Algeria - DZ' },
	{ value: 'EA', label: 'Ceuta e Melilla', labelWithValue: 'Ceuta e Melilla - EA' },
	{ value: 'EC', label: 'Ecuador', labelWithValue: 'Ecuador - EC' },
	{ value: 'EE', label: 'Estonia', labelWithValue: 'Estonia - EE' },
	{ value: 'EG', label: 'Egitto', labelWithValue: 'Egitto - EG' },
	{ value: 'EH', label: 'Sahara occidentale', labelWithValue: 'Sahara occidentale - EH' },
	{ value: 'ER', label: 'Eritrea', labelWithValue: 'Eritrea - ER' },
	{ value: 'ES', label: 'Spagna', labelWithValue: 'Spagna - ES' },
	{ value: 'ET', label: 'Etiopia', labelWithValue: 'Etiopia - ET' },
	{ value: 'EZ', label: 'Eurozone', labelWithValue: 'Eurozone - EZ' },
	{ value: 'FI', label: 'Finlandia', labelWithValue: 'Finlandia - FI' },
	{ value: 'FJ', label: 'Figi', labelWithValue: 'Figi - FJ' },
	{ value: 'FK', label: 'Isole Falkland', labelWithValue: 'Isole Falkland - FK' },
	{ value: 'FM', label: 'Micronesia', labelWithValue: 'Micronesia - FM' },
	{ value: 'FO', label: 'Isole Fær Øer', labelWithValue: 'Isole Fær Øer - FO' },
	{ value: 'FR', label: 'Francia', labelWithValue: 'Francia - FR' },
	{ value: 'GA', label: 'Gabon', labelWithValue: 'Gabon - GA' },
	{ value: 'GB', label: 'Regno Unito', labelWithValue: 'Regno Unito - GB' },
	{ value: 'GD', label: 'Grenada', labelWithValue: 'Grenada - GD' },
	{ value: 'GE', label: 'Georgia', labelWithValue: 'Georgia - GE' },
	{ value: 'GF', label: 'Guyana francese', labelWithValue: 'Guyana francese - GF' },
	{ value: 'GG', label: 'Guernsey', labelWithValue: 'Guernsey - GG' },
	{ value: 'GH', label: 'Ghana', labelWithValue: 'Ghana - GH' },
	{ value: 'GI', label: 'Gibilterra', labelWithValue: 'Gibilterra - GI' },
	{ value: 'GL', label: 'Groenlandia', labelWithValue: 'Groenlandia - GL' },
	{ value: 'GM', label: 'Gambia', labelWithValue: 'Gambia - GM' },
	{ value: 'GN', label: 'Guinea', labelWithValue: 'Guinea - GN' },
	{ value: 'GP', label: 'Guadalupa', labelWithValue: 'Guadalupa - GP' },
	{ value: 'GQ', label: 'Guinea Equatoriale', labelWithValue: 'Guinea Equatoriale - GQ' },
	{ value: 'GR', label: 'Grecia', labelWithValue: 'Grecia - GR' },
	{ value: 'GS', label: 'Georgia del Sud e Sandwich australi', labelWithValue: 'Georgia del Sud e Sandwich australi - GS' },
	{ value: 'GT', label: 'Guatemala', labelWithValue: 'Guatemala - GT' },
	{ value: 'GU', label: 'Guam', labelWithValue: 'Guam - GU' },
	{ value: 'GW', label: 'Guinea-Bissau', labelWithValue: 'Guinea- GW Bissau -' },
	{ value: 'GY', label: 'Guyana', labelWithValue: 'Guyana - GY' },
	{ value: 'HK', label: 'RAS di Hong Kong', labelWithValue: 'RAS di Hong Kong - HK' },
	{ value: 'HN', label: 'Honduras', labelWithValue: 'Honduras - HN' },
	{ value: 'HR', label: 'Croazia', labelWithValue: 'Croazia - HR' },
	{ value: 'HT', label: 'Haiti', labelWithValue: 'Haiti - HT' },
	{ value: 'HU', label: 'Ungheria', labelWithValue: 'Ungheria - HU' },
	{ value: 'IC', label: 'Isole Canarie', labelWithValue: 'Isole Canarie - IC' },
	{ value: 'ID', label: 'Indonesia', labelWithValue: 'Indonesia - ID' },
	{ value: 'IE', label: 'Irlanda', labelWithValue: 'Irlanda - IE' },
	{ value: 'IL', label: 'Israele', labelWithValue: 'Israele - IL' },
	{ value: 'IM', label: 'Isola di Man', labelWithValue: 'Isola di Man - IM' },
	{ value: 'IN', label: 'India', labelWithValue: 'India - IN' },
	{ value: 'IO', label: 'Territorio britannico dell’Oceano Indiano', labelWithValue: 'Territorio britannico dell’Oceano Indiano - IO' },
	{ value: 'IQ', label: 'Iraq', labelWithValue: 'Iraq - IQ' },
	{ value: 'IR', label: 'Iran', labelWithValue: 'Iran - IR' },
	{ value: 'IS', label: 'Islanda', labelWithValue: 'Islanda - IS' },
	{ value: 'IT', label: 'Italia', labelWithValue: 'Italia - IT' },
	{ value: 'JE', label: 'Jersey', labelWithValue: 'Jersey - JE' },
	{ value: 'JM', label: 'Giamaica', labelWithValue: 'Giamaica - JM' },
	{ value: 'JO', label: 'Giordania', labelWithValue: 'Giordania - JO' },
	{ value: 'JP', label: 'Giappone', labelWithValue: 'Giappone - JP' },
	{ value: 'KE', label: 'Kenya', labelWithValue: 'Kenya - KE' },
	{ value: 'KG', label: 'Kirghizistan', labelWithValue: 'Kirghizistan - KG' },
	{ value: 'KH', label: 'Cambogia', labelWithValue: 'Cambogia - KH' },
	{ value: 'KI', label: 'Kiribati', labelWithValue: 'Kiribati - KI' },
	{ value: 'KM', label: 'Comore', labelWithValue: 'Comore - KM' },
	{ value: 'KN', label: 'Saint Kitts e Nevis', labelWithValue: 'Saint Kitts e Nevis - KN' },
	{ value: 'KP', label: 'Corea del Nord', labelWithValue: 'Corea del Nord - KP' },
	{ value: 'KR', label: 'Corea del Sud', labelWithValue: 'Corea del Sud - KR' },
	{ value: 'KW', label: 'Kuwait', labelWithValue: 'Kuwait - KW' },
	{ value: 'KY', label: 'Isole Cayman', labelWithValue: 'Isole Cayman - KY' },
	{ value: 'KZ', label: 'Kazakistan', labelWithValue: 'Kazakistan - KZ' },
	{ value: 'LA', label: 'Laos', labelWithValue: 'Laos - LA' },
	{ value: 'LB', label: 'Libano', labelWithValue: 'Libano - LB' },
	{ value: 'LC', label: 'Saint Lucia', labelWithValue: 'Saint Lucia - LC' },
	{ value: 'LI', label: 'Liechtenstein', labelWithValue: 'Liechtenstein - LI' },
	{ value: 'LK', label: 'Sri Lanka', labelWithValue: 'Sri Lanka - LK' },
	{ value: 'LR', label: 'Liberia', labelWithValue: 'Liberia - LR' },
	{ value: 'LS', label: 'Lesotho', labelWithValue: 'Lesotho - LS' },
	{ value: 'LT', label: 'Lituania', labelWithValue: 'Lituania - LT' },
	{ value: 'LU', label: 'Lussemburgo', labelWithValue: 'Lussemburgo - LU' },
	{ value: 'LV', label: 'Lettonia', labelWithValue: 'Lettonia - LV' },
	{ value: 'LY', label: 'Libia', labelWithValue: 'Libia - LY' },
	{ value: 'MA', label: 'Marocco', labelWithValue: 'Marocco - MA' },
	{ value: 'MC', label: 'Monaco', labelWithValue: 'Monaco - MC' },
	{ value: 'MD', label: 'Moldavia', labelWithValue: 'Moldavia - MD' },
	{ value: 'ME', label: 'Montenegro', labelWithValue: 'Montenegro - ME' },
	{ value: 'MF', label: 'Saint Martin', labelWithValue: 'Saint Martin - MF' },
	{ value: 'MG', label: 'Madagascar', labelWithValue: 'Madagascar - MG' },
	{ value: 'MH', label: 'Isole Marshall', labelWithValue: 'Isole Marshall - MH' },
	{ value: 'MK', label: 'Repubblica di Macedonia', labelWithValue: 'Repubblica di Macedonia - MK' },
	{ value: 'ML', label: 'Mali', labelWithValue: 'Mali - ML' },
	{ value: 'MM', label: 'Myanmar (Birmania)', labelWithValue: 'Myanmar (Birmania) - MM' },
	{ value: 'MN', label: 'Mongolia', labelWithValue: 'Mongolia - MN' },
	{ value: 'MO', label: 'RAS di Macao', labelWithValue: 'RAS di Macao - MO' },
	{ value: 'MP', label: 'Isole Marianne settentrionali', labelWithValue: 'Isole Marianne settentrionali - MP' },
	{ value: 'MQ', label: 'Martinica', labelWithValue: 'Martinica - MQ' },
	{ value: 'MR', label: 'Mauritania', labelWithValue: 'Mauritania - MR' },
	{ value: 'MS', label: 'Montserrat', labelWithValue: 'Montserrat - MS' },
	{ value: 'MT', label: 'Malta', labelWithValue: 'Malta - MT' },
	{ value: 'MU', label: 'Mauritius', labelWithValue: 'Mauritius - MU' },
	{ value: 'MV', label: 'Maldive', labelWithValue: 'Maldive - MV' },
	{ value: 'MW', label: 'Malawi', labelWithValue: 'Malawi - MW' },
	{ value: 'MX', label: 'Messico', labelWithValue: 'Messico - MX' },
	{ value: 'MY', label: 'Malaysia', labelWithValue: 'Malaysia - MY' },
	{ value: 'MZ', label: 'Mozambico', labelWithValue: 'Mozambico - MZ' },
	{ value: 'NA', label: 'Namibia', labelWithValue: 'Namibia - NA' },
	{ value: 'NC', label: 'Nuova Caledonia', labelWithValue: 'Nuova Caledonia - NC' },
	{ value: 'NE', label: 'Niger', labelWithValue: 'Niger - NE' },
	{ value: 'NF', label: 'Isola Norfolk', labelWithValue: 'Isola Norfolk - NF' },
	{ value: 'NG', label: 'Nigeria', labelWithValue: 'Nigeria - NG' },
	{ value: 'NI', label: 'Nicaragua', labelWithValue: 'Nicaragua - NI' },
	{ value: 'XI', label: 'Irlanda del Nord', labelWithValue: 'Irlanda del Nord - XI' },
	{ value: 'NL', label: 'Paesi Bassi', labelWithValue: 'Paesi Bassi - NL' },
	{ value: 'NO', label: 'Norvegia', labelWithValue: 'Norvegia - NO' },
	{ value: 'NP', label: 'Nepal', labelWithValue: 'Nepal - NP' },
	{ value: 'NR', label: 'Nauru', labelWithValue: 'Nauru - NR' },
	{ value: 'NU', label: 'Niue', labelWithValue: 'Niue - NU' },
	{ value: 'NZ', label: 'Nuova Zelanda', labelWithValue: 'Nuova Zelanda - NZ' },
	{ value: 'OM', label: 'Oman', labelWithValue: 'Oman - OM' },
	{ value: 'PA', label: 'Panamá', labelWithValue: 'Panamá - PA' },
	{ value: 'PE', label: 'Perù', labelWithValue: 'Perù - PE' },
	{ value: 'PF', label: 'Polinesia francese', labelWithValue: 'Polinesia francese - PF' },
	{ value: 'PG', label: 'Papua Nuova Guinea', labelWithValue: 'Papua Nuova Guinea - PG' },
	{ value: 'PH', label: 'Filippine', labelWithValue: 'Filippine - PH' },
	{ value: 'PK', label: 'Pakistan', labelWithValue: 'Pakistan - PK' },
	{ value: 'PL', label: 'Polonia', labelWithValue: 'Polonia - PL' },
	{ value: 'PM', label: 'Saint Pierre e Miquelon', labelWithValue: 'Saint Pierre e Miquelon - PM' },
	{ value: 'PN', label: 'Isole Pitcairn', labelWithValue: 'Isole Pitcairn - PN' },
	{ value: 'PR', label: 'Portorico', labelWithValue: 'Portorico - PR' },
	{ value: 'PS', label: 'Territori palestinesi', labelWithValue: 'Territori palestinesi - PS' },
	{ value: 'PT', label: 'Portogallo', labelWithValue: 'Portogallo - PT' },
	{ value: 'PW', label: 'Palau', labelWithValue: 'Palau - PW' },
	{ value: 'PY', label: 'Paraguay', labelWithValue: 'Paraguay - PY' },
	{ value: 'QA', label: 'Qatar', labelWithValue: 'Qatar - QA' },
	{ value: 'RE', label: 'Riunione', labelWithValue: 'Riunione - RE' },
	{ value: 'RO', label: 'Romania', labelWithValue: 'Romania - RO' },
	{ value: 'RS', label: 'Serbia', labelWithValue: 'Serbia - RS' },
	{ value: 'RU', label: 'Russia', labelWithValue: 'Russia - RU' },
	{ value: 'RW', label: 'Ruanda', labelWithValue: 'Ruanda - RW' },
	{ value: 'SA', label: 'Arabia Saudita', labelWithValue: 'Arabia Saudita - SA' },
	{ value: 'SB', label: 'Isole Salomone', labelWithValue: 'Isole Salomone - SB' },
	{ value: 'SC', label: 'Seychelles', labelWithValue: 'Seychelles - SC' },
	{ value: 'SD', label: 'Sudan', labelWithValue: 'Sudan - SD' },
	{ value: 'SE', label: 'Svezia', labelWithValue: 'Svezia - SE' },
	{ value: 'SG', label: 'Singapore', labelWithValue: 'Singapore - SG' },
	{ value: 'SH', label: 'Sant’Elena', labelWithValue: 'Sant’Elena - SH' },
	{ value: 'SI', label: 'Slovenia', labelWithValue: 'Slovenia - SI' },
	{ value: 'SJ', label: 'Svalbard e Jan Mayen', labelWithValue: 'Svalbard e Jan Mayen - SJ' },
	{ value: 'SK', label: 'Slovacchia', labelWithValue: 'Slovacchia - SK' },
	{ value: 'SL', label: 'Sierra Leone', labelWithValue: 'Sierra Leone - SL' },
	{ value: 'SM', label: 'San Marino', labelWithValue: 'San Marino - SM' },
	{ value: 'SN', label: 'Senegal', labelWithValue: 'Senegal - SN' },
	{ value: 'SO', label: 'Somalia', labelWithValue: 'Somalia - SO' },
	{ value: 'SR', label: 'Suriname', labelWithValue: 'Suriname - SR' },
	{ value: 'SS', label: 'Sud Sudan', labelWithValue: 'Sud Sudan - SS' },
	{ value: 'ST', label: 'São Tomé e Príncipe', labelWithValue: 'São Tomé e Príncipe - ST' },
	{ value: 'SV', label: 'El Salvador', labelWithValue: 'El Salvador - SV' },
	{ value: 'SX', label: 'Sint Maarten', labelWithValue: 'Sint Maarten - SX' },
	{ value: 'SY', label: 'Siria', labelWithValue: 'Siria - SY' },
	{ value: 'SZ', label: 'Swaziland', labelWithValue: 'Swaziland - SZ' },
	{ value: 'TA', label: 'Tristan da Cunha', labelWithValue: 'Tristan da Cunha - TA' },
	{ value: 'TC', label: 'Isole Turks e Caicos', labelWithValue: 'Isole Turks e Caicos - TC' },
	{ value: 'TD', label: 'Ciad', labelWithValue: 'Ciad - TD' },
	{ value: 'TF', label: 'Terre australi francesi', labelWithValue: 'Terre australi francesi - TF' },
	{ value: 'TG', label: 'Togo', labelWithValue: 'Togo - TG' },
	{ value: 'TH', label: 'Thailandia', labelWithValue: 'Thailandia - TH' },
	{ value: 'TJ', label: 'Tagikistan', labelWithValue: 'Tagikistan - TJ' },
	{ value: 'TK', label: 'Tokelau', labelWithValue: 'Tokelau - TK' },
	{ value: 'TL', label: 'Timor Est', labelWithValue: 'Timor Est - TL' },
	{ value: 'TM', label: 'Turkmenistan', labelWithValue: 'Turkmenistan - TM' },
	{ value: 'TN', label: 'Tunisia', labelWithValue: 'Tunisia - TN' },
	{ value: 'TO', label: 'Tonga', labelWithValue: 'Tonga - TO' },
	{ value: 'TR', label: 'Turchia', labelWithValue: 'Turchia - TR' },
	{ value: 'TT', label: 'Trinidad e Tobago', labelWithValue: 'Trinidad e Tobago - TT' },
	{ value: 'TV', label: 'Tuvalu', labelWithValue: 'Tuvalu - TV' },
	{ value: 'TW', label: 'Taiwan', labelWithValue: 'Taiwan - TW' },
	{ value: 'TZ', label: 'Tanzania', labelWithValue: 'Tanzania - TZ' },
	{ value: 'UA', label: 'Ucraina', labelWithValue: 'Ucraina - UA' },
	{ value: 'UG', label: 'Uganda', labelWithValue: 'Uganda - UG' },
	{ value: 'UM', label: 'Altre isole americane del Pacifico', labelWithValue: 'Altre isole americane del Pacifico - UM' },
	{ value: 'UN', label: 'nazioni unite', labelWithValue: 'nazioni unite - UN' },
	{ value: 'US', label: 'Stati Uniti', labelWithValue: 'Stati Uniti - US' },
	{ value: 'UY', label: 'Uruguay', labelWithValue: 'Uruguay - UY' },
	{ value: 'UZ', label: 'Uzbekistan', labelWithValue: 'Uzbekistan - UZ' },
	{ value: 'VA', label: 'Città del Vaticano', labelWithValue: 'Città del Vaticano - VA' },
	{ value: 'VC', label: 'Saint Vincent e Grenadines', labelWithValue: 'Saint Vincent e Grenadines - VC' },
	{ value: 'VE', label: 'Venezuela', labelWithValue: 'Venezuela - VE' },
	{ value: 'VG', label: 'Isole Vergini Britanniche', labelWithValue: 'Isole Vergini Britanniche - VG' },
	{ value: 'VI', label: 'Isole Vergini Americane', labelWithValue: 'Isole Vergini Americane - VI' },
	{ value: 'VN', label: 'Vietnam', labelWithValue: 'Vietnam - VN' },
	{ value: 'VU', label: 'Vanuatu', labelWithValue: 'Vanuatu - VU' },
	{ value: 'WF', label: 'Wallis e Futuna', labelWithValue: 'Wallis e Futuna - WF' },
	{ value: 'WS', label: 'Samoa', labelWithValue: 'Samoa - WS' },
	{ value: 'XK', label: 'Kosovo', labelWithValue: 'Kosovo - XK' },
	{ value: 'YE', label: 'Yemen', labelWithValue: 'Yemen - YE' },
	{ value: 'YT', label: 'Mayotte', labelWithValue: 'Mayotte - YT' },
	{ value: 'ZA', label: 'Sudafrica', labelWithValue: 'Sudafrica - ZA' },
	{ value: 'ZM', label: 'Zambia', labelWithValue: 'Zambia - ZM' },
	{ value: 'ZW', label: 'Zimbabwe', labelWithValue: 'Zimbabwe - ZW' }
];
