import { Injectable } from '@angular/core';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { Company } from 'app/entities/companies/company';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { EMPTY, Observable } from 'rxjs';
import { RichiesteScartoDocumentsService2 } from '../documents/documents.service';
import { ActionsHttpService } from './actions-http.service';
import { TransferUtils } from '@ctel/transfer-manager';
import { saveAs } from 'file-saver';
import { catchError, take, tap } from 'rxjs/operators';
import { RemovePdaModalComponent } from '../../modals/remove-pda-modal/remove-pda-modal.component';
import { UploadRequestModalComponent } from '../../modals/upload-request-modal/upload-request-modal.component';

/**
 * Servizio che gestisce l'esecuzione delle azioni sui documenti
 */
@Injectable({
	providedIn: 'root'
})
export class RichiesteScartoExecuteActionsService {

	currentCompany: Company;

	constructor(
		private actionsHttpService: ActionsHttpService,
		private notificationService: NotificationService,
		private documentsService: RichiesteScartoDocumentsService2,
		private companiesService: CompaniesService
	) {
		this.currentCompany = companiesService.getCurrentCompanyValue();
	}

	public whenLoadingAction(): Observable<boolean> {
		return this.actionsHttpService.whenLoadingAction();
	}

	// ESECUZIONE AZIONE SINGOLA
	public executeSingleAction(
		action: ActionObject,
		data: any
	) {
		const actionCode = action.actionCode;
		switch (actionCode) {
			case ActionCode.DOWNLOAD:
				this.notificationService.showSweetAlert(NotificationType.QUESTION, '',
					'Confermi il download della richiesta?',
					() => this.download(data.keys.idRss));
				break;
			case ActionCode.CANCEL_REQUEST:
				this.notificationService.showSweetAlert(NotificationType.QUESTION, '',
					'Confermi l\'annullamento della richiesta?',
					() => this.cancelRequest(data.licenseId, data.siaCode, data.keys.idRss));
				break;
			case ActionCode.UPLOAD:
				this.notificationService.showModal(NotificationType.LARGE, {
					childComponent: UploadRequestModalComponent,
					customFooter: true,
					disableClickOutside: true,
					childData: {
						rssId: data.keys.idRss,
						licenseId: data.licenseId,
						siaCode: data.siaCode
					}
				});
				break;
			default:
				console.warn('ACTION non configurata', actionCode);
				break;
		}
	}

	private download(idRss: string) {
		this.actionsHttpService.download(idRss)
			.pipe(
				take(1),
				catchError(() => {
					this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante il download del file', '');
					return EMPTY;
				})
			)
			.subscribe(result => {
				if (TransferUtils.isHttpResponse(result.originatingEvent)) {
					const blob = new Blob([result.originatingEvent.body], { type: 'application/pdf' });
					saveAs(blob, result.name);
				}
			});
	}

	private cancelRequest(licenseId: string, siaCode: string, idRss: string) {
		this.actionsHttpService.cancelRequest(licenseId, siaCode, idRss).pipe(
			take(1),
			catchError(() => {
				this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante l\'annullamento', '');
				return EMPTY;
			})
		).subscribe(value => this.documentsService.refreshDocuments());
	}

	executeMultiAction(action: ActionObject, checkedKeysParsed: unknown[]) {

	}


	uploadRequest(file: File, licenseId: string, siaCode: string, rssId: string): Observable<void> {
		return this.actionsHttpService.uploadRequest(file, licenseId, siaCode, rssId).pipe(
			tap(value => this.documentsService.refreshDocuments()),
			catchError(err => {
				this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante l\'upload', '');
				return EMPTY;
			})
		);
	}
}
