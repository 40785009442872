import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { AllDocumentsCallback } from './callbacks/all-documents-callback';

@Injectable({
	providedIn: 'root'
})
export class MassiveActionsCatalog {

	public readonly cancelMassive = new ActionBuilder(MassiveActionCode.CancelMassive, ActionCode.CANCEL)
		.setActionURL('')
		.setCallback((action: ActionObject, extraParams?: unknown) => this.allDocumentsCallback.cancelMassive(action, extraParams))
		.build();

	public readonly confirmMassive = new ActionBuilder(MassiveActionCode.ConfirmMassive, ActionCode.CONFIRM)
		.setActionURL('')
		.setCallback(() => this.allDocumentsCallback.confirmMassive())
		.build();

	constructor(private allDocumentsCallback: AllDocumentsCallback) { }
}

export enum MassiveActionCode {
	CancelMassive = 'cancelMassive',
	ConfirmMassive = 'confirmMassive',
}
