import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OfficeDeskService } from 'app/modules/homepage/core/office-desk/office-desk.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMPTY, Subject, combineLatest } from 'rxjs';
import { catchError, switchMap, take, takeUntil } from 'rxjs/operators';
import { NotificationType } from '../../notification';
import { NotificationService } from '../../notification/notification.service';
import { Shortcut } from 'app/modules/homepage/modules/home/office-desk-shortcut/office-desk-shortcut.component';

@Component({
	selector: 'gaw-edit-office-desk-modal',
	templateUrl: './edit-office-desk-modal.component.html',
	styleUrls: ['./edit-office-desk-modal.component.scss']
})
export class EditOfficeDeskModalComponent implements OnInit, OnDestroy {

	@Input() modal: BsModalRef;
	@Input() data: any;

	rowData = [];

	public array: Array<any> = [];

	private destroy$ = new Subject<void>();

	constructor(
		private officeDeskService: OfficeDeskService,
		private notificationService: NotificationService
	) { }

	ngOnInit(): void {
		this.data.shortcutList
			.pipe(takeUntil(this.destroy$))
			.subscribe((list: Shortcut[]) => {
				this.rowData = list.map((item: Shortcut) => ({ ...item, isActive: item.visible })
				).sort((a, b) => {
					// Check prima il Service
					if (a.serviceDescription > b.serviceDescription) { return 1; }
					if (a.serviceDescription < b.serviceDescription) { return -1; }

					// Se il Service è lo stesso, controllo il nome
					if (a.name > b.name) { return 1; }
					if (a.name < b.name) { return -1; }

					// Shortcut duplicato
					return 0;
				});
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	toggleVisibility(shortcut: Shortcut) {
		const { siaCode, licenseId } = this.data.company;
		const shortcutList = this.rowData.filter(item => item.shortcutId !== shortcut.shortcutId);
		this.officeDeskService.setShortcut(licenseId, siaCode, [...shortcutList, { ...shortcut, visible: !shortcut.visible }]).pipe(
			catchError((err: unknown) => {
				this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', err?.['cause'].error);
				return EMPTY;
			}),
			switchMap(() => combineLatest([
				this.officeDeskService.getShortcutsForHomepage(licenseId, siaCode),
				this.officeDeskService.getShortcuts(licenseId, siaCode)
			])),
			take(1)
		).subscribe();
	}

}
