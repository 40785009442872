import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable } from 'rxjs';
import { DocClass } from '../../../entities/doc-class/doc-class.interface';
import { Classification } from 'app/entities/classification/classification';

@Injectable({
	providedIn: 'root',
})
export class DocSeriesHttpService {
	// endpoints
	private docSeriesHost: string;
	private docSeriesClassification: string;
	private docClass: string;
	private userPortalServiceHost: string;

	constructor(private http: HttpClient, public configService: ConfigService) {
		this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.docSeriesHost = appConfig.docSeries.http.host;
		this.userPortalServiceHost = appConfig.gawUserPortalService.http.host;
		this.docSeriesClassification = `${this.userPortalServiceHost}/api/v1/UserPortalServices/enabledClassifications/{licenseId}/{siaCode}`;
		this.docClass = `${this.userPortalServiceHost}/api/v1/UserPortalServices/enabledDocSeries/{licenseId}/{siaCode}/{docClassCode}`;
	}

	getClassification(licenseId: string, siaCode: string): Observable<Classification[]> {
		const url = this.docSeriesClassification
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<Classification[]>(url);
	}

	getDocClass(
		licenseId: string,
		siaCode: string,
		docClassCode: string
	): Observable<DocClass[]> {
		const url = this.docClass
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{docClassCode}', docClassCode);

		return this.http.get<DocClass[]>(url);
	}
}
