<div class='pv-container'>
	<table class='table table-responsive table-striped'>
		<thead>
			<tr>
				<th>
					Azioni
				</th>
				<th>
					Versione
				</th>
				<th>
					Codice documento
				</th>
				<th>
					Data documento
				</th>
				<th>
					Stato
				</th>
				<th>
					Data stato
				</th>
				<th>
					Data ricezione
				</th>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngIf='previousVersions | async as pvList'>
				<tr *ngFor='let version of pvList'>
					<td>
						<span class='action-span' tooltip='Scarica Documento' (click)='downloadPdf(version)'><i
								 [class]='getIcon(version.estensione)'></i></span>
					</td>
					<td>
						{{version.versione}}
					</td>
					<td>
						{{version.codiceDocumento}}
					</td>
					<td>
						<span>{{ version.dataDocumento | utcToLocalDate: "DD-MM-YYYY" }}</span>
					</td>
					<td>
						{{version.stato}}
					</td>
					<td>
						<span>{{ version.dataStato | utcToLocalDate: "DD-MM-YYYY HH:mm:ss" }}</span>
					</td>
					<td>
						<span>{{ version.dataRicezione | utcToLocalDate: "DD-MM-YYYY" }}</span>
					</td>
				</tr>
			</ng-container>
		</tbody>
	</table>
</div>
