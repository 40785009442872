import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from 'app/core/common/config/config.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { EditSpedizione } from 'app/entities/graphic-spedizioni/edit-spedizione';
import { GraphicSpedizioni } from 'app/entities/graphic-spedizioni/graphic-spedizioni';
import { LottoSpedizioni, Spools } from 'app/entities/lotto-spedizioni/lotto-spedizioni';

@Injectable({
	providedIn: 'root'
})
export class ShipmentHttpService {
	private readonly jsonContentType = 'application/json';

	private gawpassReadHost: string;
	private gawpassWriteHost: string;

	private getGraficheElabList: string;
	private generateTrakingCode: string;
	private insertTrackingUrl: string;
	private updateTrackingUrl: string;
	private searchBodexUnboxedSpools: string;
	private loadTrackingInfos: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService
	) {
		this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		/* ENDPOINTS GAWPASS --------------------------------------------------------------------------------------------------- */
		this.gawpassReadHost = appConfig.gawPassRead.http.host;
		this.gawpassWriteHost = appConfig.gawPassWrite.http.host;

		this.getGraficheElabList = `${this.gawpassReadHost}/api/v1/Action/GraficheElabList/{licenseId}/{siaCode}`;
		this.generateTrakingCode = `${this.gawpassReadHost}/api/v1/Action/GenerateTrakingCode/{licenseId}/{siaCode}/{Grafica}`;
		this.insertTrackingUrl = `${this.gawpassWriteHost}/api/v1/Action/InsertTracking`;
		this.updateTrackingUrl = `${this.gawpassWriteHost}/api/v1/Action/UpdateTracking/{idScatola}`;
		this.searchBodexUnboxedSpools = `${this.gawpassReadHost}/api/v1/Action/SearchBoxedAndUnboxedSpools/{licenseId}/{siaCode}`;
		this.loadTrackingInfos = `${this.gawpassReadHost}/api/v1/Action/LoadTrackingInfos/{idLicenza}/{codSia}/{idScatola}`;
	}

	whenCode(licenseId, siaCode, graphic) {
		const options = CustomHttpOptions.getHttpOptions('text/html', 'text');
		const url = this.generateTrakingCode
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{Grafica}', graphic);
		return this.http.get<string>(url, options);
	}

	whenLotti(licenseId, siaCode, grafica, offset, rows, parziale, dateFrom, dateTo, idScatola?): Observable<LottoSpedizioni[]> {
		const url = this.searchBodexUnboxedSpools
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		let params = new HttpParams();
		params = params.append('StartDate', dateFrom);
		params = params.append('EndDate', dateTo);
		params = params.append('CodGrafica', grafica);
		if (parziale !== null)
			params = params.append('Parziale', parziale);

		if (idScatola)
			params = params.append('IdScatola', idScatola);

		params = params.append('Offset', offset);
		params = params.append('Rows', rows);
		const options = {
			params
		};

		return this.http.get<Spools>(url, options).pipe(
			map(value => value.boxedUnboxedSpool)
		);
	}

	whenGraphics(licenseId, siaCode): Observable<GraphicSpedizioni[]> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.getGraficheElabList
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<GraphicSpedizioni[]>(url, options);
	}

	insertTracking(
		licenseId: string, siaCode: string, grafica: string, code: string,
		docCount: number, notes: string, tipiScatola: string, processoElaborazione: string,
		spools: any, nomiFlusso: string[], descTipoDocumento: string
	) {
		const url = this.insertTrackingUrl;
		const body = {
			idLicenza: licenseId,
			codSia: siaCode,
			codGrafica: grafica,
			codiceScatola: code,
			note: notes,
			tipoScatola: tipiScatola,
			numeroDocumenti: docCount,
			idProcessoElaborativo: processoElaborazione,
			progSpools: spools,
			nomiFlusso,
			descTipoDocumento
		};
		return this.http.post<any>(url, body, { observe: 'response' });
	}

	getTracking(licenseId: string, siaCode: string, idSpedizione: string): Observable<EditSpedizione> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.loadTrackingInfos
			.replace('{idLicenza}', licenseId)
			.replace('{codSia}', siaCode)
			.replace('{idScatola}', idSpedizione);
		return this.http.get<EditSpedizione>(url, options);
	}

	updateTracking(
		code: string, docCount: number, notes: string,
		tipiScatola: string, processoElaborazione: string, spools: any,
		boxId: string, totaleDocLavorati: number, nomiFlusso: string[]
	) {
		const url = this.updateTrackingUrl
			.replace('{idScatola}', boxId);
		const body = {
			codiceScatola: code,
			tipoScatola: tipiScatola,
			note: notes,
			numeroDocumenti: docCount,
			idProcessoElaborativo: processoElaborazione,
			progSpools: spools,
			totaleDocLavorati,
			nomiFlusso
		};
		return this.http.put<any>(url, body, { observe: 'response' });
	}
}
