<div class="row mx-0 px-3">
  <div class="col-md-10">
    <div class="details-title" *ngIf="documentDetails">
      <span class="badge doctype-label" [ngStyle]="{ 'background-color': color }">
        <span class="badge-text">{{ documentDetails.keys['descTipoDocumento'] || documentDetails.docSeriesId }}</span>
      </span>
      <div class="t nd">{{ docLabel }}</div>
    </div>
    <gaw-anomaly-details-main [data]="data"></gaw-anomaly-details-main>
  </div>
  <div class="col-md-2">
    <ng-container *ngIf="singleActionsList$ | async as actions">
      <ng-container *ngIf="documentDetails">
        <h4 class="action px-0">Azioni</h4>
        <div class="container1 results-content__actions_sidebars d-grid gap-2">
          <ng-container *ngFor="let action of actions">
            <button
              *ngIf="
                action.code !== singleActionsCode.openAnomalyDetail && action.code !== singleActionsCode.anomalyHistory
              "
              class="btn btn-primary btn-action"
              type="button"
              [ngClass]="{ yellow: action.highlighted === true }"
              (click)="executeAction(action, documentDetails.keys)"
            >
              <i class="btn-action-sidebar" [ngClass]="[action.icon]"></i>
              {{ action.name }}
            </button>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
