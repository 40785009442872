<input class="form-control form-control-sm"
	   type="text"
	   autocomplete="off"
	   [typeahead]="dataSource"
	   [typeaheadAsync]="typeaheadAsync"
	   [typeaheadOptionsLimit]="typeaheadOptionsLimit"
	   [typeaheadOptionField]="typeaheadOptionField"
	   (typeaheadOnSelect)="myFunc($event)"
	   [typeaheadScrollable]="typeaheadScrollable"
	   [typeaheadMinLength]="typeaheadMinLength"
	   [typeaheadItemTemplate]="customItemTemplate"
	   name="{{name}}"
	   [id]="id"
	   value="{{value}}"
		 [attr.aria-label]="ariaLabel"
	   [placeholder]="placeholder"
	   [ngStyle]="style"
	   [ngClass]="{
	   	'valid': control?.value && control?.valid,
	   	'invalid': control?.invalid
	   }"
	   [formControl]="control"
	   [popover]="InvalidFormMessage"
	   (focusin)="onFocusIn()"
	   (focusout)="onFocusOut()"
	   triggers=""/>
<ng-template #InvalidFormMessage>
	<ul *ngIf="control.invalid" class="invalid-form-msg">
		<ng-container *ngFor="let error of (controlErrors$ | async)">
			<li>
				{{error}}
			</li>
		</ng-container>
	</ul>
</ng-template>

<ng-template #customItemTemplate let-model="item" let-index="index">
	<ng-container *ngIf="!showOffice">
		<h5>{{model?.codiceFiscale}}
			- {{model?.denominazione}}</h5>
	</ng-container>
	<ng-container *ngIf="showOffice">
		<h5>{{model?.codUfficio}}
			- {{model?.nomeUfficio}}</h5>
	</ng-container>
</ng-template>

