<div
	cdkDropList
	#todoList="cdkDropList"
	[cdkDropListData]="rowData"
	class="section-list"
	(cdkDropListDropped)="dropped($event)"
>
	<div class="section-list-header">
		<div>
			<div class="drag-handle"></div>
			<div class="name header">Nome</div>
			<div class="description header">Descrizione</div>
			<div style="min-width: 100px" class="actions header">Azioni</div>
		</div>
	</div>
	<ng-template #noResult>
		<div>
			<p class="no-results">Nessuna scorciatoia.</p>
		</div>
	</ng-template>
	<ng-container *ngIf="rowData.length > 0; else noResult">
		<div *ngFor="let item of rowData" cdkDrag>
			<div class="drag-handle">
				<i [ngClass]="faIcons.FAS_BARS" cdkDragHandle></i>
			</div>
			<div class="name">La tua vista su {{ item.description }}</div>
			<div class="description">La tua vista su {{ item.description }}</div>
			<div class="action">
				<button
					(click)="toggleVisibility(item)"
					class="btn action"
					[ngClass]="{
						'btn-primary': !item.visible,
						'btn-danger': item.visible
					}"
				>
					{{ item.visible ? "Nascondi" : "Aggiungi" }}
				</button>
			</div>
		</div>
	</ng-container>
</div>
