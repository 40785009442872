import { Injectable } from '@angular/core';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { Copier } from 'app/core/common/utilities/copier';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DocumentsResponse, Filter, FullTextSearch, OrderBy, Paging, SearchRequest } from '@ctel/gaw-commons';
import { WorkflowHttpService } from '../../../services/workflow-http.service';

@Injectable()
export class WorkitemHistoryService {

	public readonly history$: Observable<DocumentsResponse>;
	private readonly loadingList$ = new BehaviorSubject<boolean>(false);

	private readonly order$ = new BehaviorSubject<OrderBy[]>([]);

	// TODO: Creare getter e setter
	private readonly paging$ = new BehaviorSubject<Paging>(
		{
			docsPerPages: 1000,
			offset: 0
		}
	);

	// TODO: Creare getter e setter
	private readonly selectMetadata$ = new BehaviorSubject<string[]>([
		'workflowDescription',
		'stageDescription',
		'notes',
		'startDate',
		'endDate',
		'username',
		'action',
		'targetStageDescription'
	]);

	private readonly filters$ = new BehaviorSubject<Filter[]>([]);

	private readonly search$ = new BehaviorSubject<FullTextSearch>(null);

	constructor(
		private companiesService: CompaniesService,
		private workflowHttpService: WorkflowHttpService
	) {

		const payloadObject$: Observable<SearchRequest> = this.companiesService.whenCurrentCompany().pipe(
			switchMap(company => combineLatest([this.order$, this.paging$, this.selectMetadata$, this.filters$, this.search$])
				.pipe(
					map(([order, paging, metadata, filters, search]) => <SearchRequest>{
						filters,
						licenseId: company.licenseId,
						orderBy: order,
						paging,
						selectMetadata: metadata,
						siaCode: company.siaCode,
						search
					})
				)
			)
		);

		this.history$ = payloadObject$.pipe(
			tap(() => this.loadingList$.next(true)),
			switchMap(payload => this.workflowHttpService.whenWorkItemHistory(payload)),
			tap(() => this.loadingList$.next(true)),
			catchError((err: unknown) => {
				this.loadingList$.next(true);
				return throwError(() => {
					if (typeof err === 'string') new Error(err);
					else new Error(err.toString());
				});
			})
		);
	}

	get isLoading$(): Observable<boolean> {
		return this.loadingList$.asObservable();
	}

	/**
	 * Aggiunge un ordinamento alla lista degli ordinamenti
	 * @param order
	 */
	addOrder(order: OrderBy) {
		const actualOrder = Copier.deepCopy(this.order$.getValue());
		let exist = actualOrder.find(o => o.metadata === order.metadata);

		if (exist)
			exist = order;
		else
			actualOrder.push(order);

		this.sendOrder(actualOrder);
	}

	/**
	 * Rimuovo un ordinamento dalla lista degli ordinamenti
	 * @param order Oggetto OrderBy o stringa del metadato
	 */
	removeOrder(order: OrderBy | string) {
		const actualOrder = Copier.deepCopy(this.order$.getValue());

		let metadata: string;
		if ((order as OrderBy).metadata)
			// Se l'oggetto passato è di tipo OrderBy elimino dall'array in base alla proprietà `metadata`
			metadata = (order as OrderBy).metadata;
		else
			// Elimino dall'array in base alla stringa passata
			metadata = (order as string);

		this.sendOrder(actualOrder.filter(o => o.metadata !== metadata));
	}

	/**
	 * Ripulisce l'array degli ordinamenti
	 */
	cleanOrder() {
		this.sendOrder([]);
	}

	/**
	 * Aggiunge un filtro alla lista dei filtri
	 * @param filter
	 */
	addFilters(filter: Filter) {
		const actualFilter: Filter[] = Copier.deepCopy(this.filters$.getValue());
		let exist = actualFilter.find(f => f.metadata === filter.metadata);

		if (exist)
			exist = filter;
		else
			actualFilter.push(filter);

		this.sendFilter(actualFilter);
	}

	/**
	 * Rimuovo un filtro dalla lista dei filtri
	 * @param filter Oggetto Filter o stringa del metadato
	 */
	removeFilter(filter: Filter | string) {
		const actualFilter = Copier.deepCopy(this.filters$.getValue());

		let metadata: string;
		if ((filter as Filter).metadata)
			// Se l'oggetto passato è di tipo OrderBy elimino dall'array in base alla proprietà `metadata`
			metadata = (filter as Filter).metadata;
		else
			// Elimino dall'array in base alla stringa passata
			metadata = (filter as string);

		this.sendFilter(actualFilter.filter(f => f.metadata !== metadata));
	}

	/**
	 * Ripulisce l'array dei filtri, è possibile settargli un array  per sovrascrivere i valori
	 */
	cleanFilters(array: Filter[] = []) {
		this.sendFilter(array);
	}

	sendFullTextSearch(search: FullTextSearch) {
		this.search$.next(search);
	}

	/**
	 * Ripulisce il campo search utilizzato per i filtri
	 */
	cleanSearch() {
		this.sendFullTextSearch(null);
	}

	/**
	 * Ripulisce filtri e ordinamento
	 */
	reset() {
		this.cleanOrder();
		this.cleanFilters();
		this.cleanSearch();
	}

	private sendOrder(orders: OrderBy[]) {
		this.order$.next(orders);
	}

	private sendFilter(filters: Filter[]) {
		this.filters$.next(filters);
	}

}
