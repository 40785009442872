<ng-container *hubError="lastLoadingFailed; loading: (loading$ | async); options:errorOptions">
	<!-- TODO: Decommentare per l'utilizzo dei placeholer -->
	<!-- <ng-container *hubLoading="loading$ | async; style:{'height': '600px'}"> -->
	<div class="block-ui-placeholder mx-sm-3 doc-content" *blockUI="'document-content'; template: blockTemplate">
		<!-- <div class="block-ui-placeholder mx-sm-3"> -->
		<div *ngIf="xmlContentTooLong" class="alert alert-warning" role="alert">
			{{truncatedFileWarning}}
		</div>
		<pre class="content-container">
			<code class="language-markup">
					{{prettyXmlContent}}
			</code>
		</pre>
	</div>
	<!-- </ng-container>-->
</ng-container>
