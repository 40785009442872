import { PlatformLocation } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import 'moment/locale/it';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 * Componente modal per la visualizzazione del tasto conferma quando il totale degli elementi supera il massimo supportato
 */
@Component({
	selector: 'hub-massive-action-confirm-modal',
	templateUrl: './massive-action-confirm-modal.component.html',
	styleUrls: ['./massive-action-confirm-modal.component.scss']
})
export class MassiveActionConfirmModalComponent {

	@Input() title: string;
	@Input() modal: BsModalRef;
	@Input() data: {
		action: Observable<any>,
		message: string,
		actionCode: string
	};

	public actionCode = ActionCode;

	constructor(private location: PlatformLocation) {
		location.onPopState(() => this.modal.hide());
	}

	executeAction() {
		this.data.action.pipe(take(1)).subscribe();
		this.modal.hide();
	}

	close() {
		this.modal.hide();
	}
}
