<input class="form-control form-control-sm"
	   name="{{name}}"
	   [id]="id"
	   value="{{control.value}}"
	   [placeholder]="placeholder"
		 [attr.aria-label]="ariaLabel"
	   type="date"
	   [ngStyle]="style"
	   [ngClass]="{
	   	'valid': control.value && control?.valid,
	   	'invalid': control?.invalid
	   }"
	   [popover]="InvalidFormMessage"
	   (focusin)="onFocusIn()"
	   (focusout)="onFocusOut()"
	   triggers=""
	   [formControl]="control"/>
<ng-template #InvalidFormMessage>
	<ul *ngIf="control.invalid" class="invalid-form-msg">
		<ng-container *ngFor="let error of (controlErrors$ | async)">
			<li>
				{{error}}
			</li>
		</ng-container>
	</ul>
</ng-template>

