import { PlatformLocation } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionsService, DocumentDetailsService } from '@ctel/hubfe-commons';
import { LargePlaceholderComponent } from '@ctel/placeholders';
import 'moment/locale/it';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Company } from '../../../../entities/companies/company';
import { CompaniesService } from '../../../business/companies/companies.service';
import { DocSeriesService } from '../../../business/doc-series/doc-series.service';

/**
 * Componente modal per selezionare una singola grafica.
 * aggiungere cosa fare alla pressione di "conferma" in keys.then come function
 * keys{
 *     ...
 *     "then" : () => {...}
 * }
 */
@Component({
	selector: 'docseries-selection-modal',
	templateUrl: './docseries-selection-modal.component.html',
	styleUrls: ['./docseries-selection-modal.component.scss']
})
export class DocSeriesSelectionModalComponent implements OnInit, OnDestroy {

	@Input() title: string;
	@Input() data: any;
	@Input() closeOnClickOutside: boolean;
	@Input() modal: BsModalRef;

	loading = true;
	public docSeries: Array<any>;
	public company: Company;
	public graphicError = false;
	public colors = [
		'#FF9500',
		'#0099CC',
		'#009900',
		'#666666'
	];

	public tileErrorPlaceholderErrorOption = { message: 'Errore imprevisto.' };
	loadingTemplate = LargePlaceholderComponent;

	private destroy$ = new Subject<void>();

	constructor(
		private documentDetailsService: DocumentDetailsService,
		private actionsService: ActionsService,
		private router: Router,
		private location: PlatformLocation,
		private docSeriesService: DocSeriesService,
		private companiesService: CompaniesService,

	) {
		location.onPopState(() => this.modal.hide());
	}

	ngOnInit(): void {
		this.company = {};
		this.docSeries = [];
		this.companiesService.whenCurrentCompany().pipe(
			switchMap(data => {
				this.company = data;
				return this.docSeriesService.getDocClass(this.company.licenseId, this.company.siaCode, 'fattureAttive');
			}),
			takeUntil(this.destroy$)
		).subscribe({
			next: data => {
				this.loading = false;
				this.docSeries = this.setColor(data);
				this.graphicError = false;
			},
			error: () => {
				this.graphicError = true;
				this.loading = false;
			}
		});
	}

	executeAction(data) {
		if (this.data.then)
			this.data.then(data);

		this.modal.hide();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public graficaClick(codGrafica: string) {
		this.modal.hide();
		this.executeAction(codGrafica);
	}

	private setColor(array) {
		return array.map((item, index) => {
			const z = this.getIndex(index, 3);
			return { ...item, color: this.colors[z] };
		});
	}

	private getIndex(index: number, max: number) {
		const z = index > max ? index - (max + 1) : index;
		if (z > max)
			return this.getIndex(z, max);

		return z;
	}

}
