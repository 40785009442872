import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ElementRefModule } from './element-ref/element-ref.module';
import { UiUserSessionModule } from './ui-user-session/ui-user-session.module';
import { BridgeGedconsModule } from './bridge-dnn/bridge-gedcons.module';
import { CompaniesModule } from './companies/companies.module';
import { GraficaModule } from './grafiche/grafica.module';
import { ChannelsModule } from './invoice-pa/channels/channels.module';
import { InvoicePaModule } from './invoice-pa/create-edit-invoice/invoice-pa.module';
import { FatturaElettronicaParserModule } from './invoice-pa/invoice-pa-parser/fattura-elettronica-parser.module';
import { ProvinceModule } from './invoice-pa/province/province.module';
import { UploadDocumentModule } from './invoice-pa/upload-documents/upload-document.module';
import { NavigationModule } from './navigation/navigation.module';
import { NewsAndNotificationsModule } from './news-and-notifications/news-and-notifications.module';
import { UserModule } from './user/user.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { SearchPagesModule } from 'app/core/business/search-pages/search-pages.module';
import { UploadsNotificationModule } from './uploads-notification/uploads-notification.module';

@NgModule({
	imports: [
		CommonModule,
		CompaniesModule,
		UiUserSessionModule,
		NewsAndNotificationsModule.forRoot(),
		UploadsNotificationModule.forRoot(),
		NavigationModule.forRoot(),
		UserModule,
		ElementRefModule.forRoot(),
		ConfigurationModule,
		FatturaElettronicaParserModule.forRoot(),
		InvoicePaModule.forRoot(),
		ProvinceModule.forRoot(),
		GraficaModule,
		ChannelsModule.forRoot(),
		UploadDocumentModule.forRoot(),
		BridgeGedconsModule.forRoot(),
		SearchPagesModule.forRoot()
	],
	declarations: [],
	exports: [],
	providers: []
})
export class CoreBusinessModule {
}
