import { PlatformLocation } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { FilterPayload } from '@ctel/gaw-commons';
import { ActionsHttpService, DocumentsService2 } from '@ctel/gaw30-commons';
import { ActionsHttpService as ActionsHttpServiceGAWCONS } from '@ctel/gawcons-commons';
import { ActionsHttpService as ActionsHttpServiceGAWEDI } from '@ctel/gawedi-commons';
import { ActionsHttpService as ActionsHttpServiceGAWLOTTI } from '@ctel/gawlotti-commons';
import { ActionsHttpService as ActionsHttpServiceGAWMAIL } from '@ctel/gawmail-commons';
import { ActionsHttpService as ActionsHttpServiceGAWPASS } from '@ctel/gawpass-commons';
import {
	ActionsHttpService as ActionsHttpServiceGAWPEC
} from '@ctel/gawpec-commons';
import { ActionsHttpService as ActionsHttpServiceGAWPOST } from '@ctel/gawpost-commons';
import { ActionsHttpService as ActionsHttpServiceHUBFE, DocumentsService } from '@ctel/hubfe-commons';
import { IFilterStatus } from '@ctel/search-filter-store';
import { Transfer, TransferService, TransferUtils } from '@ctel/transfer-manager';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { ExcelExtension, ExcelExtensions } from 'app/entities/ui-config/action/excel-extension.enum';
import { SectionCode } from 'app/entities/ui-config/classification-code.enum';
import { SearchContextService } from 'app/modules/homepage/core/search-context/search-context.service';
import moment from 'moment';
import 'moment/locale/it';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AsyncActionsHttpService } from '../../../business/actions/actions-http.service';
import { NotificationType } from '../../notification';
import { NotificationService } from '../../notification/notification.service';
import { Copier } from '../../utilities/copier';
import { HttpHeadersFilename } from '../../utilities/http-headers-filename';
import { Timezone } from '../../utilities/timezone';

/**
 * Componente modal per la visualizzazione del tasto conferma quando il totale degli elementi supera il massimo supportato
 */
@Component({
	selector: 'gaw-massive-action-confirm-modal',
	templateUrl: './excel-format-modal.component.html',
	styleUrls: ['./excel-format-modal.component.scss']
})
export class ExcelFormatModalComponent {
	@Input() title: string;
	@Input() modal: BsModalRef;
	@Input() data: {
		registryId: string;
		licenseId: string;
		siaCode: string;
		backButton: boolean;
		action: ActionObject;
		actionType: 'massive' | 'multiple' | 'single';
		docSeriesDescription: string;
		accountType: string;
		classification: string;
		keys: object; // Solo per azione multipla
		massivePayload: IFilterStatus; // Solo per azione massiva
		service: 'GAW30' | 'GAWFE' | 'GAWHOME' | 'GAWPEC' | 'GAWPOST' | 'GAWEDI' | 'GAWLOTTI' | 'GAWMAIL' | 'GAWLOTTIDetail' | 'GAWPASS' | 'GAWCONS' | 'HUBFE-Esiti';
		sectionCode?: string;
	};

	public actionCode = ActionCode;
	public excelExtensions = ExcelExtensions;
	public extension = ExcelExtension.XLSX;
	private urlConfig = {
		[ExcelExtension.XLS]: {
			GAW30: '/v2/documents/export/report/excel2003',
			GAWHOME: '/v2/documents/export/report/excel2003',
			GAWFE: '/v1/documents/export/report/excel2003',
			GAWPEC: '/api/v1/Action/ExportExcel',
			GAWMAIL: '/api/v1/Action/ExportExcel',
			GAWPOST: '/api/v1/Action/ExportExcel',
			GAWEDI: '/api/v1/Action/ExportExcel',
			GAWLOTTI: '/api/v1/Action/ExportExcel',
			GAWPASS: '/api/v1/Action/ExportExcel',
			GAWCONS: '/v1/archivioFiscale/export/massivo'
		},
		[ExcelExtension.CSV]: {
			GAW30: '/v2/documents/export/report/csv',
			GAWHOME: '/v2/documents/export/report/csv',
			GAWFE: '/v1/documents/export/report/csv',
			GAWPEC: '/api/v1/Action/ExportExcel',
			GAWMAIL: '/api/v1/Action/ExportExcel',
			GAWPOST: '/api/v1/Action/ExportExcel',
			GAWEDI: '/api/v1/Action/ExportExcel',
			GAWLOTTI: '/api/v1/Action/ExportExcel',
			GAWPASS: '/api/v1/Action/ExportExcel',
			GAWCONS: '/v1/archivioFiscale/export/massivo'

		}
	};

	constructor(
		private location: PlatformLocation,
		private actionsHttpService: ActionsHttpService,
		private actionsHttpServiceHUBFE: ActionsHttpServiceHUBFE,
		private asyncActionsHttpService: AsyncActionsHttpService,
		private actionsHttpServiceGAWPEC: ActionsHttpServiceGAWPEC,
		private actionsHttpServiceGAWPOST: ActionsHttpServiceGAWPOST,
		private actionsHttpServiceGAWEDI: ActionsHttpServiceGAWEDI,
		private actionsHttpServiceGAWMAIL: ActionsHttpServiceGAWMAIL,
		private actionsHttpServiceLOTTI: ActionsHttpServiceGAWLOTTI,
		private actionsHttpServiceGAWPASS: ActionsHttpServiceGAWPASS,
		private actionsHttpServiceGAWCONS: ActionsHttpServiceGAWCONS,
		private documentsService: DocumentsService,
		private documentsService2: DocumentsService2,
		private router: Router,
		private transferService: TransferService,
		private searchContextService: SearchContextService,
		private notificationService: NotificationService
	) {
		location.onPopState(() => this.modal.hide());
	}

	onSubmit(form: NgForm) {
		let applicationType = '';
		let ext: FileSaverExtension;

		switch (form.value.ext) {
			case ExcelExtension.XLSX:
				applicationType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
				ext = FileSaverExtension.XLSX;
				break;
			case ExcelExtension.XLS:
				applicationType = 'application/vnd.ms-excel';
				ext = FileSaverExtension.XLS;
				break;
			case ExcelExtension.CSV:
				applicationType = 'application/zip';
				ext = FileSaverExtension.ZIP;
				break;
		}

		const fileSaver = (result: Transfer) => {
			let filename = '';
			switch (this.data.service) {
				case 'GAWHOME':
					filename = 'cross';
					break;
				case 'GAW30':
					filename = this.data.docSeriesDescription;
					break;
				case 'GAWPEC':
				case 'GAWMAIL':
					filename = this.data.docSeriesDescription;
					break;
				case 'GAWPOST':
					filename = this.data.docSeriesDescription;
					break;
				case 'GAWEDI':
					filename = this.data.docSeriesDescription;
					break;
				case 'GAWLOTTI':
					filename = this.data.docSeriesDescription;
					break;
				case 'GAWPASS':
					filename = this.data.docSeriesDescription;
					break;
				case 'GAWLOTTIDetail':
					filename = `${this.data.keys['progSpool']}_${moment(new Date()).format('DD-MM-YYYY HH.mm.ss')}`;
					break;
				case 'HUBFE-Esiti':
				case 'GAWFE':
					filename = result.name;
					break;
				case 'GAWCONS':
					filename = HttpHeadersFilename.getFilenameFromHttpHeaders((result as Transfer).originatingEvent);
			}
			this.transferService.updateTransfer(result.key, filename, ext);

			// TODO: quando per le massive/multiple ci saranno dei filename sensati negli header,
			// si avrà tale filename già disponibile dentro r.name e non sarà più necessario fare un update
			// transfer.
			if (TransferUtils.isHttpResponse(result.originatingEvent)) {
				const blob = new Blob([result.originatingEvent.body], { type: applicationType });
				new FileSaver(blob).saveAs(filename, ext);
			}
		};
		if (this.data.service === 'HUBFE-Esiti') {
			let extension;
			switch (form.value.ext) {
				case ExcelExtension.XLS:
					extension = 'xls';
					break;
				case ExcelExtension.CSV:
					extension = 'csv';
					break;
				default:
					extension = 'xlsx';
					break;
			}
			if (this.data.actionType === 'massive')
				this.exportMassiveExcelEsiti(this.data.action.url, fileSaver, extension);
			else {
				const timezone = Timezone.getTimezone();
				const body = this.buildPayloadSingleMultipleEsitiExcel(this.data.actionType);
				this.actionsHttpServiceHUBFE.whenExcelEsiti(this.data.action.url, body, extension, timezone, this.data.sectionCode)
					.pipe(take(1))
					.subscribe(fileSaver);
			}

		} else if (this.data.actionType === 'multiple') {
			let extension;
			switch (form.value.ext) {
				case ExcelExtension.XLS:
					extension = 'xls';
					break;
				case ExcelExtension.CSV:
					extension = 'csv';
					break;
				default:
					extension = 'xlsx';
					break;
			}
			if (this.data.service === 'GAWCONS') {
				const annoFiscale = this.data.keys[0].annoFiscale;
				const documentIds = Array.isArray(this.data.keys) ? this.data.keys.map((key) => key.documentId) : [];
				this.actionsHttpServiceGAWCONS.whenExcelSingleMultiple(
					this.data.registryId, this.data.licenseId, this.data.siaCode, annoFiscale, documentIds, extension
				).pipe(take(1)).subscribe(fileSaver);
			} else
				this.actionsHttpService
					.whenExcel(this.data.action.url, this.data.keys, extension, this.data.sectionCode || this.data.classification)
					.pipe(take(1))
					.subscribe(fileSaver);

		} else if (this.data.actionType === 'single') {
			let extension;
			switch (form.value.ext) {
				case ExcelExtension.XLS:
					extension = 'xls';
					break;
				case ExcelExtension.CSV:
					extension = 'csv';
					break;
				default:
					extension = 'xlsx';
					break;
			}
			this.actionsHttpServiceGAWCONS.whenExcelSingleMultiple(
				this.data.registryId, this.data.licenseId, this.data.siaCode,
				this.data.keys['annoFiscale'], [this.data.keys['documentId']], extension
			).pipe(take(1)).subscribe(fileSaver);
		} else {
			// azione massiva
			let url = '';
			let extension = '';
			switch (form.value.ext) {
				case ExcelExtension.XLSX:
					extension = 'xlsx';
					url = this.data.action.url;
					break;
				case ExcelExtension.XLS:
					extension = 'xls';
					url = this.data.service === 'GAWLOTTI' || this.data.service === 'GAWCONS' ? this.data.action.url : this.urlConfig[form.value.ext][this.data.service];
					break;
				case ExcelExtension.CSV:
					extension = 'csv';
					url = this.data.service === 'GAWLOTTI' || this.data.service === 'GAWCONS' ? this.data.action.url : this.urlConfig[form.value.ext][this.data.service];

					break;
				default:
					url = this.data.service === 'GAWLOTTI' || this.data.service === 'GAWCONS' ? this.data.action.url : this.urlConfig[form.value.ext][this.data.service];
					break;
			}

			this.exportMassiveExcel(url, fileSaver, extension);
		}
		this.close();
	}

	close() {
		this.modal.hide();
	}

	private exportMassiveExcel(url, fileSaver, extension) {
		const sectionCode = this.data.sectionCode || this.data.classification;
		const clientId = this.data.service === 'GAWHOME' ? 'GAW' : this.data.service === 'GAWFE' ? 'HUBFE' : this.data.service;

		switch (this.data.service) {
			case 'GAWFE':
				this.exportMassiveExcelGAWFE(clientId, extension, sectionCode);
				break;
			case 'GAWHOME':
				url = `${url}?clientid=${clientId}`;
				if (sectionCode)
					url = `${url}&sectionCode=${sectionCode}`;

				this.exportMassiveExcelGAWHOME(url, fileSaver);
				break;
			case 'GAW30':
				this.defaultExportMassiveExcel(clientId, extension, sectionCode);
				break;
			case 'GAWPEC':
				url = `${url}/${extension}/${sectionCode}`;
				this.exportMassiveExcelGAWPEC(url, fileSaver);
				break;
			case 'GAWMAIL':
				url = `${url}/${extension}/${sectionCode}`;
				this.exportMassiveExcelGAWMAIL(url, fileSaver);
				break;
			case 'GAWLOTTI':
				url = `${url}/${extension}/${sectionCode}`;
				this.exportMassiveExcelGAWLOTTI(url, fileSaver);
				break;
			case 'GAWPOST':
				url = `${url}/${extension}/${sectionCode}`;
				this.exportMassiveExcelGAWPOST(url, fileSaver);
				break;
			case 'GAWPASS':
				url = `${url}/${extension}/${sectionCode}`;
				this.exportMassiveExcelGAWPASS(url, fileSaver);
				break;
			case 'GAWEDI':
				url = this.router.url.includes('gawedi_shipmentsSummary') ? `/api/v1/Action/ExportExcelRiepilogoSpedizioni/${extension}`
					: `${url}/${extension}/${sectionCode}`;
				this.exportMassiveExcelGAWEDI(url, fileSaver);
				break;
			case 'GAWLOTTIDetail':
				url = `${this.data.action.url}/${this.data.keys['tipoCanale']}/${extension}`;
				this.exportDetailExcelGAWLOTTI(url, fileSaver);
				break;
			case 'GAWCONS':
				this.exportMassiveExcelGAWCONS(url, extension);
				break;
		}
	}

	private exportMassiveExcelGAWFE(clientId, extension, sectionCode) {
		const callback$ = this.buildPayloadForExcel(this.data.massivePayload).pipe(
			switchMap(() => this.defaultAsyncExcelMassiveDownload(clientId, extension, sectionCode)),
			take(1)
		);
		this.defaultExportMassiveExcelConfirmation(callback$);
	}

	private exportMassiveExcelGAWHOME(url, fileSaver) {
		this.buildPayloadForCrossServiceExcel(this.data.massivePayload).pipe(
			switchMap((p) => this.actionsHttpService.whenExcelAll(url, p)),
			take(1)
		).subscribe(fileSaver);
	}

	private defaultExportMassiveExcel(clientId, extension, sectionCode) {
		this.defaultExportMassiveExcelConfirmation(this.defaultAsyncExcelMassiveDownload(clientId, extension, sectionCode));
	}

	private exportMassiveExcelGAWPEC(url, fileSaver) {
		this.actionsHttpServiceGAWPEC.whenExcelAll(url, this.data.massivePayload).pipe(
			catchError(() => EMPTY),
			take(1)
		).subscribe(fileSaver);
	}

	private exportMassiveExcelGAWMAIL(url, fileSaver) {
		this.actionsHttpServiceGAWMAIL.whenExcelAll(url, this.data.massivePayload).pipe(
			catchError(() => EMPTY),
			take(1)
		).subscribe(fileSaver);
	}

	private exportMassiveExcelGAWLOTTI(url, fileSaver) {
		this.actionsHttpServiceLOTTI.whenExcelAll(url, this.data.massivePayload).pipe(
			catchError(() => EMPTY),
			take(1)
		).subscribe(fileSaver);
	}

	private exportMassiveExcelGAWEDI(url, fileSaver) {
		this.actionsHttpServiceGAWEDI.whenExcelAll(url, this.data.massivePayload).pipe(
			catchError(() => EMPTY),
			take(1)
		).subscribe(fileSaver);
	}

	private exportMassiveExcelGAWPOST(url, fileSaver) {
		this.actionsHttpServiceGAWPOST.whenExcelAll(url, this.data.massivePayload).pipe(
			catchError(() => EMPTY),
			take(1)
		).subscribe(fileSaver);
	}

	private exportMassiveExcelGAWPASS(url, fileSaver) {
		this.actionsHttpServiceGAWPASS.whenExcelAll(url, this.data.massivePayload).pipe(
			catchError(() => EMPTY),
			take(1)
		).subscribe(fileSaver);
	}

	private exportMassiveExcelGAWCONS(url, extension) {
		this.actionsHttpServiceGAWCONS.whenExcelAll(url, this.data.massivePayload, extension).pipe(
			catchError(() => EMPTY),
			take(1)
		).subscribe();
	}

	private exportDetailExcelGAWLOTTI(url, fileSaver) {
		this.actionsHttpServiceLOTTI.whenExcelDetail(url, this.data.keys['progSpool']).pipe(
			catchError(() => EMPTY),
			take(1)
		).subscribe(fileSaver);
	}

	private buildPayloadForExcel(payload: FilterPayload): Observable<FilterPayload> {
		const newPayload = Copier.deepCopy(payload);

		return this.documentsService.whenCurrentClassification().pipe(
			take(1),
			map((classification) => {
				const service = classification.slice(-2);
				let detailedStatus = '';
				switch (service) {
					case '35':
						detailedStatus = MetadataEnum.DESCRIZIONE_STATO_35;
						break;
					case '21':
						detailedStatus = MetadataEnum.DESCRIZIONE_STATO_21;
						break;
					case '22':
						detailedStatus = MetadataEnum.DESCRIZIONE_STATO_22;
						break;
					case '06':
						detailedStatus = MetadataEnum.DESCRIZIONE_STATO_06;
						break;
					case '10':
					case '18':
					case '36':
						detailedStatus = MetadataEnum.DESCRIZIONE_STATO;
						break;
				}
				// aggiungo i metadati sempre presenti
				newPayload.selectMetadata = [
					MetadataEnum.INTESTAZIONE,
					MetadataEnum.NUMERO_FATTURA,
					MetadataEnum.DATA_FATTURA,
					MetadataEnum.IMPORTO,
					MetadataEnum.ID_FILE_SDI
				];

				if (detailedStatus !== '')
					newPayload.selectMetadata.push(detailedStatus);

				if (classification === SectionCode.CP_REGISTRATO) {
					newPayload.selectMetadata.push(MetadataEnum.NUMERO_PROTOCOLLO);
					newPayload.selectMetadata.push(MetadataEnum.DATA_PROTOCOLLO);
				}
				return newPayload;
			})
		);
	}

	private buildPayloadForCrossServiceExcel(payload: FilterPayload): Observable<IFilterStatus & {
		docSeriesIds: string[],
		selectMetadata: string[],
		textSearchMetadata: string[]
	}> {
		const newPayload = Copier.deepCopy(payload);
		return this.searchContextService.whenSearchContext().pipe(
			take(1),
			map((searchDataInfo) => ({
				...newPayload,
				searchDataInfo: searchDataInfo.map(dataInfo => ({
					docSeriesIds: dataInfo.docSeriesIds,
					selectMetadata: dataInfo.columnMetadata,
					textSearchMetadata: dataInfo.textSearchMetadata
				}))
			}))
		);
	}

	private defaultExportMassiveExcelConfirmation(callback: Observable<unknown>) {
		this.notificationService.showSweetAlert(
			NotificationType.QUESTION,
			'Attenzione:',
			`Risulta possibile esportare in Excel le informazioni di al massimo 250.000 documenti.<br>
				Se hai selezionato più di 250.000 documenti, i dati dei documenti eccedenti
				la soglia di 250.000 non saranno riportati nei report Excel.<br>
				Se hai selezionato più di 150.000 documenti, saranno generati due report Excel
				che verranno inseriti all'interno di un unico file compresso (ZIP).<br>
				In ogni caso l'operazione avverrà in differita: riceverai una notifica
				(campanella) e una email al termine del processo.`,
			() => callback.pipe(take(1)).subscribe()
		);
	}

	private defaultAsyncExcelMassiveDownload(clientId, extension, sectionCode): Observable<unknown> {
		return this.asyncActionsHttpService.asyncExcelMassiveDownload(this.data.massivePayload, extension, sectionCode, clientId).pipe(
			catchError(() => {
				this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante l\'esecuzione dell\'azione', '');
				return EMPTY;
			}),
			tap(() => {
				this.notificationService.showSweetAlert(
					NotificationType.INFO,
					'La richiesta di esportazione è stata presa in carico. Riceverai una notifica quando verrà completata.',
					''
				);
			}),
			take(1)
		);
	}

	private buildPayloadSingleMultipleEsitiExcel(actionType: 'massive' | 'multiple' | 'single') {
		switch (actionType) {
			case 'multiple':
				return this.data.keys['map'](key => ({
					ctelDocSeriesId: key.ctelDocSeriesId,
					ctelElasticDocumentId: key.ctelElasticDocumentId
				}));
			case 'single':
				return [{
					ctelDocSeriesId: this.data.keys['ctelDocSeriesId'],
					ctelElasticDocumentId: this.data.keys['ctelElasticDocumentId']
				}];

		}
	}

	private exportMassiveExcelEsiti(actionUrl: string, fileSaver: (result: Transfer) => void, extension) {
		const sectionCode = this.data.classification;
		const configurationGroup = 'GridColumns';
		const callback$ = this.buildPayloadForExcel(this.data.massivePayload).pipe(
			switchMap(() => this.asyncExcelMassiveDownloadEsiti(configurationGroup, extension, sectionCode, actionUrl)),
			take(1)
		);
		this.defaultExportMassiveExcelConfirmation(callback$);

	}

	private asyncExcelMassiveDownloadEsiti(configurationGroup: string, extension, sectionCode: string, actionUrl: string) {
		return this.actionsHttpServiceHUBFE.asyncExcelMassiveDownloadEsiti(
			this.data.massivePayload, configurationGroup, extension, sectionCode, actionUrl
		).pipe(
			catchError(() => {
				this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante l\'esecuzione del\'azione', '');
				return EMPTY;
			}),
			tap(() => {
				this.notificationService.showSweetAlert(
					NotificationType.INFO,
					'La richiesta di esportazione è stata presa in carico. Riceverai una notifica quando verrà completata.',
					''
				);
			}),
			take(1)
		);
	}
}
