import { Injectable, OnDestroy } from '@angular/core';
import { FilterStatus } from '@ctel/gaw-commons';
import { ArchivioFiscaleService, DocumentsSeriesService, DocumentsService2, FilterService2, PacchettiDiDistribuzioneService } from '@ctel/gawcons-commons';
import { Transfer, TransferService, TransferUtils } from '@ctel/transfer-manager';
import { AppErrorBuilder } from 'app/core/common/error/app-error-builder';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { ExcelFormatModalComponent } from 'app/core/common/modals/excel-format-modal/excel-format-modal.component';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { Copier } from 'app/core/common/utilities/copier';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { EMPTY, Observable, Subject, combineLatest, of } from 'rxjs';
import { count, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PDDRequestModel } from '../../../entities/PDD-request-model';
import { PDDOutcomeModalComponent } from '../../../modals/pdd-outcome-modal/pdd-outcome-modal.component';
import { PDDRequestModalComponent } from '../../../modals/pdd-request-modal/pdd-request-modal.component';

/**
 * Callback delle azioni massive
 */
@Injectable({
	providedIn: 'root'
})
export class AllDocumentsCallback implements OnDestroy {

	private readonly _filterPayload$: Observable<FilterStatus>;
	private readonly _totalDocuments$: Observable<number>;
	private concurrentDownloads = 0;
	private previous2014: boolean;
	private previousYear = 2014;
	private body: {
		searchRequest: any;
		idRegistro: string;
		annoFiscale?: string;
		flagAttestato?: boolean;
		flagIPDA?: boolean;
		flagRDV?: boolean;
		flagSuperimpronte?: boolean;
		flagComunicazioniAde?: boolean;
		flagRicevuteAde?: boolean;
	};

	private destroy$ = new Subject<void>();

	constructor(
		private documentsService: DocumentsService2,
		private filterService: FilterService2,
		private notificationService: NotificationService,
		private documentsSeriesService: DocumentsSeriesService,
		private transferService: TransferService,
		private pacchettiDiDistribuzioneService: PacchettiDiDistribuzioneService,
		private archivioFiscaleService: ArchivioFiscaleService
	) {
		this._totalDocuments$ = documentsService.whenTotalDocuments();
		this._filterPayload$ = filterService.whenFilterValue();
		this.transferService.whenTransfers().pipe(
			switchMap(value => of(Object.values<Transfer>(value)).pipe(
				mergeMap(value1 => value1),
				count(v => !TransferUtils.isComplete(v)),
				tap(v => this.concurrentDownloads = v)
			)),
			takeUntil(this.destroy$)
		).subscribe();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	/**
	 * Funzione di esporta excel massivo
	 * @param action
	 * @param extraParams
	 */
	public exportXLSX(action: ActionObject, extraParams?: any): Observable<any> {
		if (this.concurrentDownloads >= 2) {
			this.notificationService.showSweetAlert(
				NotificationType.INFO,
				'Attenzione',
				'Un massimo di due trasferimenti contemporanei sono supportati, nel caso di esportazione massiva');
			return EMPTY;
		}

		if (!this.checkActionInput(action))
			throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
				.description('Azione non valida')
				.info('action', action)
				.build();

		return this._totalDocuments$
			.pipe(
				take(1),
				mergeMap(docCount => combineLatest([this._filterPayload$])
					.pipe(
						take(1),
						switchMap(([payload]) => {
							const payloadCopy = Copier.deepCopy(payload);
							payloadCopy.filters.find(filterValue =>
								filterValue.metadata === 'annoFiscale'
							).value.terms = [this.archivioFiscaleService.getFiscalYear()];
							const body = {
								searchRequest: payloadCopy,
								sectionCode: extraParams.sectionCode,
								idRegistro: extraParams.idRegistro,
								annoFiscale: this.archivioFiscaleService.getFiscalYear()
							};

							// Modal della scelta del formato dell'excel
							const showModal = () => {
								this.notificationService.showModal(
									NotificationType.GENERAL,
									{
										title: 'Esporta risultati in Excel',
										customFooter: true,
										childComponent: ExcelFormatModalComponent,
										childData: {
											backButton: true,
											action,
											actionType: 'massive',
											docSeriesDescription: this.documentsSeriesService.getDocSeriesLabel(),
											massivePayload: body,
											service: 'GAWCONS',
											sectionCode: extraParams ? extraParams.sectionCode : null
										}
									}
								);
							};

							if (docCount > 50000)
								this.notificationService.showSweetAlert(
									NotificationType.QUESTION,
									'Numero massimo di documenti superato.',
									`Procedere all'esportazione dei primi 50000 documenti?
									\nL'operazione potrebbe richiedere alcuni minuti`,
									showModal);
							else
								showModal();

							return EMPTY;
						})
					)
				)
			);
	}

	executeRequest = (pddModel: PDDRequestModel) => {
		this.body = { ...this.body, ...pddModel };

		this.pacchettiDiDistribuzioneService.richiestaPddMassiva(this.body)
			.pipe(takeUntil(this.destroy$))
			.subscribe(value =>
				this.notificationService.showModal(NotificationType.SUCCESS, {
					title: 'Esito richiesta pacchetto di distribuzione',
					childComponent: PDDOutcomeModalComponent,
					customFooter: true,
					childData: {
						pddModel: value
					},
					disableClickOutside: false
				})
			);
	};

	public requestPDD(action: ActionObject, extraParams?: any): Observable<any> {
		return this._totalDocuments$
			.pipe(
				take(1),
				mergeMap(docCount => combineLatest([this._filterPayload$])
					.pipe(
						take(1),
						switchMap(([payload]) => {
							this.body = { searchRequest: payload, idRegistro: extraParams.idRegistro };
							return this.pacchettiDiDistribuzioneService.verificaStatoDocumenti(JSON.stringify(this.body))
								.pipe(map(status => ({
									status, payload
								})));
						}),
						switchMap((response) => {
							if (response.status.tuttiDocumentiNonConservati) {
								this.notificationService.showSweetAlert(NotificationType.ERROR, 'Impossibile eseguire l\'azione',
									response.status.message);
								return EMPTY;
							}
							this.body.annoFiscale = response.payload.filters
								.find(filter => filter.metadata === 'annoFiscale').value.terms[0];
							this.previous2014 = +this.body.annoFiscale <= this.previousYear;
							return this.pacchettiDiDistribuzioneService.validaDocumentiMassivo(JSON.stringify(this.body));
						}),
						tap(validationMessage => {
							if (validationMessage) {
								const pddmodel = new PDDRequestModel();

								pddmodel.docCount = docCount;
								pddmodel.previous2014 = this.previous2014;

								this.notificationService.showModal(NotificationType.CUSTOM, {
									title: 'Invia richiesta pacchetto di distribuzione',
									childComponent: PDDRequestModalComponent,
									customFooter: true,
									childData: {
										execute: this.executeRequest,
										validationMessage,
										pddModel: pddmodel
									},
									disableClickOutside: false
								});
							}
						})
					)
				)
			);
	}

	// Controllo sull'azione in input, ritorna true se l'url è valido
	// noinspection JSMethodCanBeStatic
	private checkActionInput(action): boolean {
		return !!(action && action.url);
	}

}
