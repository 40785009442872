import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class XmlParserService {

	public hasNode(xml: Document, xpath: string) {
		const node = xml.evaluate(xpath, xml, null, XPathResult.ANY_UNORDERED_NODE_TYPE, null);
		return node.singleNodeValue !== null;
	}

	public getDate(xml: Document, xpath: string) {
		const dateText = this.getText(xml, xpath);
		if (_.isUndefined(dateText))
			return undefined;

		const ticks = Date.parse(dateText);
		if (isNaN(ticks))
			return undefined;

		return new Date(ticks);
	}

	public getAtt(xml: Document) {
		const tag = xml.getElementsByTagName('FatturaElettronica')[0];
		const attr = tag.attributes;
		for (let i = 0; i < attr.length; i++)
			if (attr.item(i).nodeName.search('xmlns:') === 0)
				// missing implementation
				continue;

	}

	public getText(xml: Document, xpath: string) {
		xpath += '/text()';
		const value = xml.evaluate(xpath, xml, null, XPathResult.ORDERED_NODE_ITERATOR_TYPE, null);

		let textNode: Node = value.iterateNext();
		let result: string;
		while (textNode !== null) {
			if (typeof result === 'undefined')
				result = '';

			result += textNode.nodeValue;
			textNode = value.iterateNext();
		}
		return result;
	}

	public getArray(xml: Document, xpath: string): string[] {
		const array: string[] = [];
		let i = 0;
		const nodes = xml.evaluate(xpath, xml, null, XPathResult.ANY_TYPE, null);
		let result = nodes.iterateNext();
		while (result) {
			if (result.childNodes[0])
				array[i] = result.childNodes[0].nodeValue;

			result = nodes.iterateNext();
			i++;
		}
		return array;
	}

	public getEmptyAtt() {
		const att = [{}];
		return att;
	}

	public createDocument(xml: string): Document {
		if (_.isNil(xml))
			return null;

		const parser = new DOMParser();
		return parser.parseFromString(xml, 'text/xml');
	}
}
