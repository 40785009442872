<div id="mySidenav" class="sidenav container">
  <div class="close-sidebar-block" placement="bottom" tooltip="Chiudi il menu">
    <button
      aria-label="Chiudi menù laterale"
      type="button"
      id="sidebarCollapseBtn"
      class="btn sidebar-button"
      (click)="onCloseSidebar()"
    >
      <img alt="Chiudi sidebar" src="/assets/homepage/path_2.svg" class="nav-item" />
    </button>
  </div>

  <mat-accordion class="main-sidebar-accordion">
    <section>
      <nav aria-label="Servizi Abilitati">
        <ul>
          <ng-container *ngFor="let navigationItem of navigation$ | async; let i = index">
            <li class="flex-top">
              <gaw-nav-item
                [navigationItem]="navigationItem"
                [attr.data-testid]="navigationItem.description"
              ></gaw-nav-item>
            </li>
          </ng-container>
        </ul>
      </nav>
    </section>
    <hr />
    <section>
      <nav aria-label="Servizi Comuni">
        <ul>
          <ng-container *ngFor="let navigationItem of navigationActions$ | async; let i = index">
            <gaw-nav-item [navigationItem]="navigationItem"></gaw-nav-item>
          </ng-container>
        </ul>
      </nav>
    </section>
    <hr />
    <section>
      <nav aria-label="Contatti">
        <ul>
          <ng-container *ngFor="let navigationItem of navigationHelp; let i = index">
            <gaw-nav-item [navigationItem]="navigationItem"></gaw-nav-item>
          </ng-container>
        </ul>
      </nav>
    </section>
  </mat-accordion>
</div>
