import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { UploadLottoMessage } from 'app/entities/invoice-pa/upload-lotto-message/upload-lotto-message';
import { Observable } from 'rxjs';

@Injectable()
export class UploadLottoHttpService {

	// endpoints
	private validation: string;
	private validationHost: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.validationHost = appConfig.uploadDocument.http.host;
		this.validation = `${this.validationHost}/v2/Upload/UploadLotto`;
	}

	public uploadLotto(id: string, sia: string, grafica: string, body: File): Observable<UploadLottoMessage[]> {
		const url = this.validation;
		const formData: FormData = new FormData();
		formData.append('file', body);

		return this.http.post<UploadLottoMessage[]>(url, formData, {
			params: {
				id,
				sia,
				grafica,
				extractFiles: 'false',
				tipoFile: 'Zip'
			}
		});
	}

}
