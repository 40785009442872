import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingDirective } from 'app/core/common/placeholder/loading-placeholder/directives/loading.directive';
import { BaseLoadingPlaceholderComponent } from './base-loading-placeholder/base-loading-placeholder.component';
import { DropdownPlaceholderComponent } from './dropdown-placeholder/dropdown-placeholder.component';
import { ChannelButtonComponent } from './templates/custom/channel-button/channel-button.component';
import { DocumentsListPlaceholderComponent } from './templates/custom/documents-list-placeholder/documents-list-placeholder.component';
import { FilterTypePlaceholderComponent } from './templates/custom/filter-type-placeholder/filter-type-placeholder.component';
import { MetadataPlaceholderComponent } from './templates/custom/metadata-placeholder/metadata-placeholder.component';
import { ImagePlaceholderComponent } from './templates/image-placeholder/image-placeholder.component';
import { RefreshPlaceholderComponent } from './templates/refresh-placeholder/refresh-placeholder.component';
import { SimplePlaceholderComponent } from './templates/simple-placeholder/simple-placeholder.component';
import { LargePlaceholderComponent } from './templates/spinner/large-placeholder/large-placeholder.component';
import { TableRowPlaceholderComponent } from './templates/table-row-placeholder/table-row-placeholder.component';
import { TextPlaceholderComponent } from './templates/text-placeholder/text-placeholder.component';
/* REMEMBER ME library ??? */
import { ContentLoaderComponent } from '@ctel/placeholders';
import { ApplicationErrorPlaceholderComponent } from './templates/application-error-placeholder/application-error-placeholder.component';
import { DocumentListPlaceholderComponent } from './templates/document-list-placeholder/document-list-placeholder.component';
import { LargePlaceholderDocListComponent } from './templates/spinner/large-placeholder-doc-list/large-placeholder-doc-list.component';
/**/

@NgModule({
	imports: [
		CommonModule,
		ContentLoaderComponent,
	],
	declarations: [
		LoadingDirective,
		SimplePlaceholderComponent,
		LargePlaceholderComponent,
		TableRowPlaceholderComponent,
		ImagePlaceholderComponent,
		FilterTypePlaceholderComponent,
		DocumentsListPlaceholderComponent,
		ChannelButtonComponent,
		MetadataPlaceholderComponent,
		TextPlaceholderComponent,
		BaseLoadingPlaceholderComponent,
		RefreshPlaceholderComponent,
		DropdownPlaceholderComponent,
		DocumentListPlaceholderComponent,
		ApplicationErrorPlaceholderComponent,
		LargePlaceholderDocListComponent,
	],
	exports: [
		LoadingDirective,
		SimplePlaceholderComponent,
		LargePlaceholderComponent,
		TableRowPlaceholderComponent,
		ImagePlaceholderComponent,
		FilterTypePlaceholderComponent,
		DocumentsListPlaceholderComponent,
		ChannelButtonComponent,
		MetadataPlaceholderComponent,
		TextPlaceholderComponent,
		RefreshPlaceholderComponent,
		DropdownPlaceholderComponent,
		DocumentListPlaceholderComponent,
		ApplicationErrorPlaceholderComponent,
		LargePlaceholderDocListComponent,
	]
})
export class DryLoadingPlaceholderModule {
}
