<div #placeholderContainer class="placeholder-container "
	 [ngStyle]="style"
>

	<div class="animation-background loading"
		 [ngStyle]="{
		 	'clip-path': 'url(#maskingPath'+uniqueId+')'
		 }"
	>
		<svg xmlns="http://www.w3.org/2000/svg" class="text-placeholder ">
			<clipPath id="{{ 'maskingPath' + uniqueId }}">
				<ng-container *ngFor="let attributes of elementAttributes; let i = index">
					<svg:rect [attr.x]="attributes.x"
							  [attr.y]="attributes.y"
							  [attr.rx]="3"
							  [attr.ry]="3"
							  [attr.width]="attributes.width"
							  [attr.height]="attributes.height"/>
				</ng-container>
			</clipPath>
		</svg>
	</div>
</div>
