import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { AddressBookHttpService } from 'app/core/business/configuration/address-book/address-book-http.service';
import { ErrorTypes } from 'app/core/common/error';
import { Copier } from 'app/core/common/utilities/copier';
import { Contact, UpsertContact, emptyContact } from 'app/entities/contacts/contact';
import { saveAs } from 'file-saver';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { FormatEnum } from '../../../../entities/address-book/format-enum';
import { FlowConfigChannelsEnum } from '../../../../entities/flow-config/flow-config-channels';
import { GawMailFlowConfig } from '../../../../entities/flow-config/gaw-mail-flow-config';
import { GawPecFlowConfig } from '../../../../entities/flow-config/gaw-pec-flow-config';
import { Address, GawPostFlowConfig } from '../../../../entities/flow-config/gaw-post-flow-config';
import { FlowRules, FlowRulesStatus } from '../../../../entities/flow-rules/flow-rules';
import { HttpHeadersFilename } from '../../../common/utilities/http-headers-filename';
import { DocumentPermissions } from '../../user/permissions/document-permissions.enum';
import { PermissionService } from '../../user/permissions/permission.service';

/**
 * Service per la rubrica
 */
@Injectable({
	providedIn: 'root'
})
export class AddressBookService {
	licenseId: string;
	siaCode: string;
	selectedContact: {
		edit: boolean;
		contact: Contact;
	};

	private contacts$: ReplaySubject<Contact[]> = new ReplaySubject(1);
	private aliasList$: BehaviorSubject<string[]> = new BehaviorSubject([]);
	private ruleList$: BehaviorSubject<FlowRules[]> = new BehaviorSubject<FlowRules[]>([]);
	private activeLettersList$: ReplaySubject<string[]> = new ReplaySubject(1);
	private selectedContact$: ReplaySubject<Contact> = new ReplaySubject(1);
	private importFromAddressBook$: BehaviorSubject<number> = new BehaviorSubject(0);
	private isAddressBookLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	private refresh$: BehaviorSubject<any> = new BehaviorSubject(0);

	private gawPecConfigs: BehaviorSubject<GawPecFlowConfig[]> = new BehaviorSubject<GawPecFlowConfig[]>([]);
	private gawMailConfigs: BehaviorSubject<GawMailFlowConfig[]> = new BehaviorSubject<GawMailFlowConfig[]>([]);
	private gawPostConfigs: BehaviorSubject<GawPostFlowConfig[]> = new BehaviorSubject<GawPostFlowConfig[]>([]);

	constructor(
		private companiesService: CompaniesService,
		private addressBookHttpService: AddressBookHttpService,
		private userPermissionService: PermissionService
	) {
		combineLatest([
			this.refresh$,
			this.companiesService.whenCurrentCompany(),
			this.userPermissionService.whenDocumentsPermissions()
		]).pipe(
			switchMap(([, company, documentsPermissions]) => {
				this.licenseId = company.licenseId;
				this.siaCode = company.siaCode;
				if (documentsPermissions.has(DocumentPermissions.addressBook))
					return this.addressBookHttpService.whenContacts(company.licenseId, company.siaCode)
						.pipe(
							map(getContacts => getContacts.contacts)
						);

				return of([]);
			}),
			tap(contacts => {
				this.sendAliasList(contacts);
				this.sendContacts(contacts);
			}),
			catchError((err: unknown) => {
				if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
					//this.router.navigate(['/unauthorized']).then();
				}
				return EMPTY;
			})
		).subscribe();

	}

	whenContacts(): Observable<Contact[]> {
		return this.contacts$.asObservable();
	}

	sendContacts(contacts: Contact[]) {
		contacts.sort(this.customContactsSort('01234567989abcdefghijklmnopqrstuvwxyzÈÉÀÒÙÌ_-!"£$%&/()=?€[]*°<>\\|+#@§~`'));
		this.contacts$.next(contacts);
	}

	public sendIsAddressBookLoading(value: boolean) {
		this.isAddressBookLoading$.next(value);
	}

	public whenIsAddressBookLoading(): Observable<boolean> {
		return this.isAddressBookLoading$.asObservable();
	}

	sendAliasList(contacts: Contact[]) {
		const aliasList: string[] = [];
		for (let i = 0; i < contacts.length; i++)
			aliasList.push(contacts[i].contactAlias);

		this.findActiveLetters(aliasList.sort());
		this.aliasList$.next(aliasList.sort());
	}

	getAliasList() {
		return this.aliasList$.value;
	}

	getRuleList(newContact = false) {
		if (newContact || !this.selectedContact.contact.contactConfiguration.datiTrasmissione.CodiceVeicolazione)
			return;

		this.addressBookHttpService.getRuleList(this.licenseId,
			this.siaCode,
			this.selectedContact.contact.contactConfiguration.datiTrasmissione.CodiceVeicolazione
		)
			.subscribe(value => this.ruleList$.next(value));
	}

	whenRuleList(): Observable<FlowRules[]> {
		return this.ruleList$.asObservable();

	}

	enableServiceToGraphic(codGrafica: string, status: FlowRulesStatus, serviceId: string): Observable<any> {
		return this.addressBookHttpService.enableServiceToGraphic(this.licenseId,
			this.siaCode,
			this.selectedContact.contact.contactConfiguration.datiTrasmissione.CodiceVeicolazione,
			status,
			codGrafica,
			this.selectedContact.contact.contactId,
			serviceId).pipe(tap(() => this.getRuleList()));
	}

	whenActiveLetters(): Observable<string[]> {
		return this.activeLettersList$.asObservable();
	}

	whenDeleteContact(contactId: number): Observable<any> {
		const company = this.companiesService.getCurrentCompanyValue();
		return this.addressBookHttpService.whenDeleteContact(company.licenseId, company.siaCode, contactId)
			.pipe(
				take(1),
				tap(() => this.refresh()),
				catchError((err: unknown) => {
					if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
						//this.router.navigate(['/unauthorized']).then();
					}
					return EMPTY;
				})
			);
	}

	whenUpsertContact(contact: any): Observable<any> {
		const company = this.companiesService.getCurrentCompanyValue();
		const body: UpsertContact = {
			contactAlias: contact.contactAlias,
			configuration: {
				contactAlias: contact.contactAlias,
				cessionarioCommittente: contact.cessionarioCommittente,
				datiTrasmissione: contact.datiTrasmissione
			}
		};
		// insert
		if (contact.contactId === null)
			return this.addressBookHttpService.whenInsertContact(company.licenseId, company.siaCode, body)
				.pipe(
					take(1),
					catchError((err: unknown) => {
						if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
							//this.router.navigate(['/unauthorized']).then();
						}
						return EMPTY;
					})
				);
		else { //update
			body['idContact'] = contact.contactId;
			return this.addressBookHttpService.whenUpdateContact(company.licenseId, company.siaCode, body)
				.pipe(
					take(1),
					catchError((err: unknown) => {
						if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
							//this.router.navigate(['/unauthorized']).then();
						}
						return EMPTY;
					})
				);
		}
	}

	whenContactFromId(id: number): Observable<Contact> {
		return this.whenContacts().pipe(
			switchMap((contacts: Contact[]) => contacts.filter((contact) => contact.contactId === id)),
			catchError(() => {
				const pd: Contact = Copier.deepCopy(emptyContact);
				return of(pd);
			})
		);
	}

	whenImportFromAddressBook(): Observable<number> {
		return this.importFromAddressBook$.asObservable();
	}

	sendImportFromAddressBook(): void {
		this.importFromAddressBook$.next(this.importFromAddressBook$.getValue() + 1);
	}

	resetImportFromAddressBook(): void {
		this.importFromAddressBook$.next(0);
	}

	whenSelectedContact(): Observable<Contact> {
		return this.selectedContact$.asObservable();
	}

	sendSelectedContact(contact: Contact): void {
		this.selectedContact$.next(contact);
	}

	refresh(): void {
		this.refresh$.next(this.refresh$.getValue() + 1);
	}

	checkEnabledServices(): Observable<boolean> {
		return this.addressBookHttpService.checkEnabledServices(this.licenseId, this.siaCode);
	}

	getConfigByGraphicsGawPec() {
		this.addressBookHttpService.getConfigByGraphicsGawPec(
			this.licenseId, this.siaCode, this.selectedContact.contact.contactConfiguration.datiTrasmissione.CodiceVeicolazione
		).subscribe({
			next: value => this.gawPecConfigs.next(value),
			error: (error: unknown) => this.gawPecConfigs.error(error)
		});
	}

	whenGawPecConfigs(): Observable<GawPecFlowConfig[]> {
		return this.gawPecConfigs.asObservable();
	}

	getConfigByGraphicsGawMail() {
		return this.addressBookHttpService.getConfigByGraphicsGawMail(
			this.licenseId, this.siaCode, this.selectedContact.contact.contactConfiguration.datiTrasmissione.CodiceVeicolazione
		).subscribe({
			next: value => this.gawMailConfigs.next(value),
			error: (error: unknown) => this.gawMailConfigs.error(error as HttpErrorResponse)
		});
	}

	whenGawMailConfigs(): Observable<GawMailFlowConfig[]> {
		return this.gawMailConfigs.asObservable();
	}

	updateConfigsByGraphics(
		channel: FlowConfigChannelsEnum, address: string, extendCheck: boolean, codGrafica: string
	): Observable<boolean> {
		switch (channel) {
			case FlowConfigChannelsEnum.GedPec:
				return this.addressBookHttpService.updateConfigsByGraphicsGawPec(
					this.licenseId,
					this.siaCode,
					this.selectedContact.contact.contactConfiguration.datiTrasmissione.CodiceVeicolazione,
					address,
					extendCheck,
					codGrafica
				).pipe(
					tap(() => this.getConfigByGraphicsGawPec()));
			case FlowConfigChannelsEnum.GedMail:
				return this.addressBookHttpService.updateConfigsByGraphicsGawMail(
					this.licenseId,
					this.siaCode,
					this.selectedContact.contact.contactConfiguration.datiTrasmissione.CodiceVeicolazione,
					address,
					extendCheck,
					codGrafica
				).pipe(
					tap(() => this.getConfigByGraphicsGawMail()));
		}
	}

	getConfigByGraphicsGawPost() {
		return this.addressBookHttpService.getConfigByGraphicsGawPost(
			this.licenseId, this.siaCode, this.selectedContact.contact.contactConfiguration.datiTrasmissione.CodiceVeicolazione
		)
			.subscribe({
				next: value => this.gawPostConfigs.next(value),
				error: (error: unknown) => this.gawPostConfigs.error(error)
			});
	}

	whenGawPostConfigs(): Observable<GawPostFlowConfig[]> {
		return this.gawPostConfigs.asObservable();
	}

	// TODO aggiustare parametri e integrare con le api
	insertOrUpdatePostAddress(address: Address, alternativo: boolean, codGrafica: string, edit: boolean): Observable<boolean> {
		const payload = {
			codGrafica,
			riga1: address.riga1 ? address.riga1 : null,
			riga2: address.riga2 ? address.riga2 : null,
			riga3: address.riga3 ? address.riga3 : null,
			riga4: address.riga4 ? address.riga4 : null,
			riga5: address.riga5 ? address.riga5 : null
		};
		if (edit)
			return (
				alternativo ?
					this.addressBookHttpService.updateConfigsByGraphicsGawPostAlt(
						this.licenseId,
						this.siaCode,
						this.selectedContact.contact.contactConfiguration.datiTrasmissione.CodiceVeicolazione,
						payload
					)
					: this.addressBookHttpService.updateConfigsByGraphicsGawPostAgg(
						this.licenseId,
						this.siaCode,
						this.selectedContact.contact.contactConfiguration.datiTrasmissione.CodiceVeicolazione,
						payload
					)
			).pipe(
				tap(() => this.getConfigByGraphicsGawPost())
			);
		else
			return (
				alternativo ?
					this.addressBookHttpService.insertConfigsByGraphicsGawPostAlt(
						this.licenseId,
						this.siaCode,
						this.selectedContact.contact.contactConfiguration.datiTrasmissione.CodiceVeicolazione,
						payload
					)
					: this.addressBookHttpService.insertConfigsByGraphicsGawPostAgg(
						this.licenseId,
						this.siaCode,
						this.selectedContact.contact.contactConfiguration.datiTrasmissione.CodiceVeicolazione,
						payload
					)
			).pipe(
				tap(() => this.getConfigByGraphicsGawPost())
			);

	}

	whenDeleteAdress(codGrafica: string, address: Address, alternativo: boolean): Observable<boolean> {
		return (alternativo
			? this.addressBookHttpService.deleteConfigsByGraphicsGawPostAlt(
				this.licenseId,
				this.siaCode,
				this.selectedContact.contact.contactConfiguration.datiTrasmissione.CodiceVeicolazione,
				codGrafica
			)
			: this.addressBookHttpService.deleteConfigsByGraphicsGawPostAgg(address.progressivo))
			.pipe(
				tap(() => this.getConfigByGraphicsGawPost())
			);
	}

	export(selectedFormat: FormatEnum, checkedList: number[]): Observable<any> {
		return this.addressBookHttpService.export(selectedFormat, checkedList).pipe(
			tap(
				result => {
					const fileName = HttpHeadersFilename.getFilenameFromHttpHeaders(result);
					const blob = new Blob([result.body], { type: 'application/csv' });
					saveAs(blob, fileName);
				}
			)
		);
	}

	import(selection: FormatEnum, file: File, service: string, fileAppend: boolean) {
		return this.addressBookHttpService.import(this.licenseId, this.siaCode, selection, file, service, fileAppend)
			.pipe(tap(() => this.refresh()));
	}

	resetSubjects() {
		this.gawPecConfigs = new BehaviorSubject<GawPecFlowConfig[]>([]);
		this.gawMailConfigs = new BehaviorSubject<GawMailFlowConfig[]>([]);
		this.gawPostConfigs = new BehaviorSubject<GawPostFlowConfig[]>([]);
	}

	private customContactsSort(orderString: string) {
		/*
		*	Metodo per ordinamento dei contatti custom.
		*	Accetta una stringa che utilizza come indice per l'ordinamento dei contatti
		* 	L'ordinamento non è case sensitive
		*/
		return (objA: Contact, objB: Contact) => {
			const a: string = objA.contactAlias.toLocaleLowerCase(),
				b: string = objB.contactAlias.toLocaleLowerCase(),
				index_a: number = orderString.indexOf(a[0]),
				index_b: number = orderString.indexOf(b[0]);

			if (index_a === index_b) {
				// ordinamento standard
				if (a < b)
					return -1;
				else if (a > b)
					return 1;

				return 0;
			} else
				return index_a - index_b;

		};
	}

	private findActiveLetters(aliasList: string[]) {
		// ÈÉÀÒÙÌ
		const numbers = '0123456789'.split('');
		const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
		const others = 'ÈÉÀÒÙÌ_-!"£$%&/()=?€[]*°<>\\|+#@§~`'.split('');
		const activeLetters = [];
		let alreadyFoundOther = false;
		let alreadyFoundNumber = false;
		aliasList.forEach(alias => {
			const foundNumber = numbers.find(num => num === alias[0]);
			if (!alreadyFoundNumber && foundNumber) {
				activeLetters.push('0-9');
				alreadyFoundNumber = true;
			}

			const foundOther = others.find(other => other === alias[0]);
			if (!alreadyFoundOther && foundOther) {
				activeLetters.push('Altro');
				alreadyFoundOther = true;
			}

			const aliasToLowerCase = alias[0].toLowerCase();
			const foundLetter = alphabet.find(letter => letter.toLowerCase() === aliasToLowerCase);
			if (!activeLetters.includes(aliasToLowerCase) && foundLetter)
				activeLetters.push(aliasToLowerCase);

		}
		);
		this.activeLettersList$.next(activeLetters);
	}
}
