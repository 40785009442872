import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentState, getActions } from '@ctel/search-filter-store';
import { select, Store } from '@ngrx/store';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { ActionsConfigurationService } from 'app/core/common/ui-configuration/actions-configuration/actions-configuration.service';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { ConfigurationsGroup } from 'app/entities/ui-config/action/actions-configuration';
import { ActionsByType } from 'app/modules/homepage/core/documents-search/actions/actions-by-type';
import { DocumentsSeriesService } from 'app/modules/homepage/core/documents-search/documents-series/documents-series.service';
import * as _ from 'lodash';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { DocumentsHttpService } from '../documents-http.service';
import { DocumentsService2 } from '../documents/documents.service';

/**
 * Questa implementazione esiste unicamente per la nuova versione dei filtri che si
 * basa sugli stati applicativi (ngrx).
 */
@Injectable({
	providedIn: 'root'
})
export class ActionsService2 {

	private readonly massiveActionsList$ = new Observable<ActionObject[]>();
	private multiActionsList$ = new ReplaySubject<ActionObject[]>(1);
	private singleActionsList$ = new ReplaySubject<ActionObject[]>(1);
	private actionLoadingError$ = new BehaviorSubject<boolean>(false);

	constructor(
		private documentsHttpService: DocumentsHttpService,
		private actionsConfigurationService: ActionsConfigurationService,
		private documentsSeriesService: DocumentsSeriesService,
		private documentsService: DocumentsService2,
		private companiesService: CompaniesService,
		private router: Router,
		private store: Store<DocumentState>,
		private actionsByType: ActionsByType
	) {
		this.massiveActionsList$ = this.store
			.pipe(
				select(getActions),
				map(actions => <ActionObject[]>actions)
			);
	}

	// azioni massive
	whenMassiveActionsList(): Observable<ActionObject[]> {
		return this.massiveActionsList$;
	}

	// azioni multiple
	whenMultiActionsList(): Observable<ActionObject[]> {
		return this.multiActionsList$.asObservable();
	}

	sendLoadingActionError(value: boolean) {
		this.actionLoadingError$.next(value);
	}

	whenLoadingActionError(): Observable<boolean> {
		return this.actionLoadingError$.asObservable();
	}

	sendMultiActionsList(actions: ActionObject[]) {
		this.multiActionsList$.next(actions);
	}

	// azioni singole
	whenSingleActionsList(): Observable<ActionObject[]> {
		return this.singleActionsList$.asObservable();
	}

	sendSingleActionsList(actions: ActionObject[]) {
		this.singleActionsList$.next(actions);
	}

	// doc � facoltativo solo quando richiamiamo il metodo per ottenere l'endpoint per la visualizzazione anteprima del pdf nel dettaglio
	requestSingleActionsConfig(isOnlyView = false) {
		this.actionsConfigurationService.getSingleActionsByDefault()
			.pipe(
				tap((config) => {
					if (isOnlyView)
						this.sendSingleActionsList(this.mergeActionConfig(config, 'onlyView'));
					else
						this.sendSingleActionsList(this.mergeActionConfig(config, 'single'));

					this.sendLoadingActionError(false);
				}),
				catchError(() => {
					this.sendLoadingActionError(true);
					return EMPTY;
				}),
				take(1)
			).subscribe();
	}

	requestMultiActionsConfig() {
		//richiesta azioni multiple spostata da BE a FE
		// licenseId: string, siaCode: string, body: ActionsConfigRequest[]
		// this.actionsConfigurationService.getMultipleActionsByDocSeries(licenseId, siaCode, body)
		this.actionsConfigurationService.getMultipleActionsByDefault()
			.pipe(
				tap(config => {
					this.sendMultiActionsList(this.mergeActionConfig(config, 'multi'));
				}),
				take(1)
			)
			.subscribe();
	}

	mergeActionConfig(configuration: ConfigurationsGroup[], actionsType: 'single' | 'multi' | 'massive' | 'onlyView'): ActionObject[] {
		let actionsCatalog = [];
		switch (actionsType) {
			case 'onlyView':
				actionsCatalog = _.cloneDeep(this.actionsByType.onlyViewActions) as ActionObject[];
				break;
			case 'single':
				actionsCatalog = _.cloneDeep(this.actionsByType.singleActions) as ActionObject[];
				break;
			case 'multi':
				actionsCatalog = _.cloneDeep(this.actionsByType.multiActions) as ActionObject[];
				break;
			case 'massive':
				actionsCatalog = _.cloneDeep(this.actionsByType.massiveActions) as ActionObject[];
				break;
		}

		const actionsList: ActionObject[] = [];
		if (configuration)
			for (let i = 0; i < configuration.length; i++) {
				const action: ActionObject = actionsCatalog.find(obj => obj.code === configuration[i].code);
				if (action !== undefined) {
					Object.keys(configuration[i]).forEach(key => {
						action[key] = configuration[i][key];
					});
					actionsList.push(action);
				}
			}

		return actionsList;
	}
}
