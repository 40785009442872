import { Injectable, OnDestroy } from '@angular/core';
import { LottoDetail } from '@ctel/gaw-commons';
import { BehaviorSubject, Observable, ReplaySubject, zip } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LottoDetailHttpService } from './lotto-detail-http.service';

const PAGING_DEFAULT = { offset: 0, docsPerPages: 25 };

@Injectable({
	providedIn: 'root'
})

export class LottoDetailService implements OnDestroy {
	public error: any;
	public tipoCanale: string;
	private lottoDetail$ = new BehaviorSubject<LottoDetail>({});
	private paging = { ...PAGING_DEFAULT };
	private isFirstLoading: boolean;
	private destroy$ = new ReplaySubject<void>(1);

	constructor(private lottoDetailHttpService: LottoDetailHttpService) { }

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	whenLottoDetails(): Observable<LottoDetail> {
		return this.lottoDetail$.asObservable();
	}

	sendLottoDetails(progSpool: string, tipoCanale: string, isFirstLoading) {

		this.error = undefined;
		if (isFirstLoading || !this.lottoDetail$.value || !this.lottoDetail$.value.details)
			this.lottoDetailHttpService.whenLottoDetail(progSpool, this.tipoCanale ? this.tipoCanale : tipoCanale, this.paging)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: value => this.lottoDetail$.next(value),
					error: () => this.error = 'Errore nel recupero lotti'
				});
		else {
			this.paging.offset += this.paging.docsPerPages;
			zip(
				this.lottoDetail$,
				this.lottoDetailHttpService.whenLottoDetail(progSpool, this.tipoCanale ? this.tipoCanale : tipoCanale, this.paging))
				.pipe(
					map(value => ({ details: [...value[0].details, ...value[1].details] })),
					takeUntil(this.destroy$)
				).subscribe({
					next: value => this.lottoDetail$.next(value),
					error: () => 'Errore nel recupero lotti'
				});
		}
	}

	resetLottoDetailList() {
		this.paging = { ...PAGING_DEFAULT };
		this.isFirstLoading = true;
		this.lottoDetail$.next(undefined);
		this.tipoCanale = null;
	}

}
