import { Component } from '@angular/core';

@Component({
	selector: 'gaw-main-menu-button-block',
	templateUrl: './main-menu-button-block.component.html',
	styleUrls: ['./main-menu-button-block.component.scss']
})
export class MainMenuButtonBlockComponent {

}
