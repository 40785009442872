import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ScartoHttpService } from './scarto-http.service';
import { ValidateResponseModel } from '../entities/validate-response-model';
import { InfoResponseModel } from '../entities/info-response-model';

@Injectable({
	providedIn: 'root'
})
export class ScartoService {
	private actions$ = new ReplaySubject(1);

	constructor(
		private scartoHttp: ScartoHttpService
	) {
	}

	validate(body: string): Observable<ValidateResponseModel> {
		return this.scartoHttp.validate(body);
	}

	info(body: string): Observable<InfoResponseModel> {
		return this.scartoHttp.info(body);
	}

	request(body: string, copyRequested:boolean): Observable<void> {
		return this.scartoHttp.request(body, copyRequested);
	}
}
