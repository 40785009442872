import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// REMEMBER ME
// import { AppCommonModule } from 'app/core/common/app-common.module';
// import { CoreCommonModule } from 'app/core/common/core-common.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PlaceholderModule } from '@ctel/placeholders';
import { DryLoadingPlaceholderModule } from 'app/core/common/placeholder/loading-placeholder/dry-loading-placeholder.module';
import { PaginationModule } from 'app/shared/components/pagination/pagination.module';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule as NgxPaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AbstractTableComponent } from './abstract-table.component';
import { ClickDirective } from './click.directive';
import { ActionsBarComponent } from './material-table/actions-bar/actions-bar.component';
import { MaterialTableComponent } from './material-table/material-table.component';

@NgModule({
	imports: [
		// CoreCommonModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BsDatepickerModule,
		NgxPaginationModule.forRoot(),
		TooltipModule,
		PipesModule,
		// Pagination
		PaginationModule,

		// Material
		MatTableModule,
		MatInputModule,
		MatIconModule,
		MatButtonModule,
		MatTooltipModule,
		DryLoadingPlaceholderModule,
		PipesModule,
		// MatSortModule,

		PlaceholderModule
	],
	declarations: [
		AbstractTableComponent,
		MaterialTableComponent,
		ActionsBarComponent,
		ClickDirective
	],
	exports: [
		MaterialTableComponent,
		ClickDirective
	]
})
export class TableModule {
}
