import { Component } from '@angular/core';

@Component({
	selector: 'hub-dropdown-placeholder',
	templateUrl: './dropdown-placeholder.component.html',
	styleUrls: ['./dropdown-placeholder.component.scss']
})
export class DropdownPlaceholderComponent  {

}
