import { Component } from '@angular/core';
import { TextField } from 'app/shared/components/dry/field';
import { AbstractCellComponent } from '../abstract-cell.component';

@Component({
	templateUrl: './text-cell.component.html',
	styleUrls: ['./text-cell.component.scss']
})
export class TextCellComponent extends AbstractCellComponent<TextField> {
	value: string;
}
