/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { NumberValidators } from 'app/shared/components/dry/validator/number-validators';

@Directive({
	selector: '[isNumberValidator][ngModel]',
	providers: [
		{provide: NG_VALIDATORS, useExisting: IsNumberValidatorDirective, multi: true}
	]
})
export class IsNumberValidatorDirective implements Validator {

	registerOnValidatorChange(fn: () => void): void {	}

	validate(c: AbstractControl): ValidationErrors | null {
		return NumberValidators.isNumber()(c);
	}

}
