<div class="d-flex block-ui-light-template">
	<div class="row align-self-center w-100 mx-auto">
		<div class="col-6 mx-auto" id="container">
			<i class="error-icon" [ngClass]="faIcons.FAS_EXCLAMATION_TRIANGLE"></i>
			<p id="error-text">
				Impossibile caricare l'applicazione
			</p>
		</div>
	</div>
</div>
