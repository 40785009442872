import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DocumentAttachmentPdf } from 'app/shared/components/display-document/document-attachment-pdf';
import { DocumentAttachmentXml } from 'app/shared/components/display-document/document-attachment-xml';
import { Observable } from 'rxjs';

@Component({
	selector: 'hub-display-document',
	templateUrl: './display-document.component.html',
	styleUrls: ['./display-document.component.scss']
})
export class DisplayDocumentComponent implements OnInit {

	public documentAttachments: (DocumentAttachmentXml | DocumentAttachmentPdf)[];

	constructor(
		public http: HttpClient
	) { }

	ngOnInit() {

		const samplePdf$: Observable<Blob> = this.loadSamplePdf('/assets/config/dev/dry/sample.pdf');
		const sampleXml$: Observable<string> = this.loadSampleXml('/assets/config/dev/dry/sample.xml');

		this.documentAttachments = [
			new DocumentAttachmentPdf('PDF di esempio', samplePdf$, '/assets/config/dev/dry/sample.pdf'),
			new DocumentAttachmentXml('XML di esempio', sampleXml$)
		];
	}

	loadSamplePdf(url: string): Observable<Blob> {
		return this.http.get(
			url,
			{
				headers: new HttpHeaders()
					.set('Content-Type', 'application/json')
				, responseType: 'blob'
			}).pipe(
		);
	}

	loadSampleXml(url: string): Observable<string> {
		return this.http.get(url, {
			headers: new HttpHeaders()
				.set('Content-Type', 'application/xml')
			, responseType: 'text'
		}).pipe();

	}

}
