import { HttpRequest } from '@angular/common/http';

export class HttpRequestEditor {

	public static deleteHttpHeader(request: HttpRequest<any>, headerKey: string) {
		return request.clone({
			headers: request.headers.delete(headerKey)
		});
	}
}
