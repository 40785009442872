import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { DocumentsResponse, SearchRequest } from '@ctel/gaw-commons';
import { Transfer, TransferService, transferHandler } from '@ctel/transfer-manager';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { ExportExcelSelectedItemPayload } from '../entities/action/export-excel-selected-item-payload';
import { WorkItem, WorkItemCreationResponse, WorkItemDetail, WorkItemsResponse } from '../entities/work-item/work-item';
import { ActionExecutePayload, WorkItemAction } from '../entities/work-item/work-item-action';
import { WorkItemsSearchBody } from '../entities/work-item/work-items-search-body';
import { Workflow } from '../entities/workflow';

/**
 * Service che si interfaccia con gli endpoint del workflow
 */
@Injectable({
	providedIn: 'root'
})
export class WorkflowHttpService implements OnDestroy {

	private readonly jsonContentType = 'application/json';

	private workflowHost: string;
	private workflowIntegrationHost: string;
	private beFlowHost: string;
	private searchHost: string;

	private hasWorkflowUrl: string;
	private workflows: string;
	private workItemsCount: string;
	private workItemsSearch: string;

	private exportExcelWorkItems: string;
	private exportExcelSelectedWorkItems: string;

	private workItemHistory: string;

	private workflowsByDocSeries: string;
	private workflowRoles: string;
	private workflowStages: string;

	private workItems: string;
	private workItem: string;
	private workItemDetail: string;
	private workItemCreate: string;
	private workItemActions: string;
	private workItemActionForm: string;
	private workItemPreviewFormLink: string;

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
		public fullScreenSpinnerService: FullScreenSpinnerService,
		private transferService: TransferService
	) {
		this.configService.whenAppConfig()
			.pipe(takeUntil(this.destroy$))
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	httpHostInit(appConfig: AppConfig) {
		this.workflowHost = appConfig.workflow.http.host;
		this.workflowIntegrationHost = appConfig.wfIntegration.http.host;
		this.beFlowHost = appConfig.beFlow.http.host;
		this.searchHost = appConfig.search.http.host;

		this.hasWorkflowUrl = `${this.workflowIntegrationHost}/v1/licenses/{licenseId}/sia/{codSia}/workflowEnabled?clientid=GAW30`;
		this.workflows = `${this.workflowIntegrationHost}/v2/licenses/{licenseId}/sia/{codSia}/workflowSections?clientid=GAW30`;
		this.workItemDetail = `${this.workflowIntegrationHost}/v2/licenses/{licenseId}/sia/{codSia}/workflowSections/{workflowSectionName}/workitems/{workitemId}/details?clientid=GAW30`;
		this.workItemsCount = `${this.workflowIntegrationHost}/v2/licenses/{licenseId}/sia/{codSia}/workflowSections/{workflowSectionName}/workitems/Count?clientid=GAW30`;
		this.workItemsSearch = `${this.workflowIntegrationHost}/v2/licenses/{licenseId}/sia/{codSia}/workflowSections/{workflowSectionName}/workitems/Search?clientid=GAW30`;
		this.exportExcelWorkItems = `${this.workflowIntegrationHost}/v2/licenses/{licenseId}/sia/{codSia}/workflowSections/{workflowSectionName}/workitems/ExportExcel?clientid=GAW30&docSeries={docSeries}`;
		this.exportExcelSelectedWorkItems = `${this.workflowIntegrationHost}/v2/licenses/{licenseId}/sia/{codSia}/workflowSections/{workflowSectionName}/workitems/ExportSelectedInExcel?clientid=GAW30&docSeries={docSeries}`;

		this.workItemHistory = `${this.searchHost}/v2/search/logauditwkf?clientid=GAW30`;

		this.workflowsByDocSeries = `${this.workflowHost}/api/v1/licenses/{licenseId}/workflows/SearchByDocumentSeries?document_series={docSeriesId}`;

		this.workflowStages = `${this.workflowHost}/api/v1/licenses/{licenseId}/workflows/{workflowName}/stages`;
		this.workflowRoles = `${this.workflowHost}/api/v1/licenses/{licenseId}/workflows/{workflowName}/roles`;

		this.workItems = `${this.workflowHost}/api/v1/licenses/{licenseId}/workflows/{workflowName}/searches`;	// post

		// TODO: /v2/licenses/{licenseId}/sia/{codSia}/workflowSections/{workflowSectionName}/workitems/{workitemId}
		this.workItem = `${this.workflowHost}/api/v1/licenses/{licenseId}/workflows/{workflowName}/items/{itemId}`;
		this.workItemCreate = `${this.workflowHost}/api/v1/licenses/{licenseId}/workflows/{workflowName}/items/ItemCreation`;	// post
		this.workItemActions = `${this.workflowHost}/api/v1/licenses/{licenseId}/workflows/{workflowName}/items/{workItemId}/actions`;
		this.workItemActionForm = `${this.workflowHost}/api/v1/licenses/{licenseId}/workflows/{workflowName}/items/{workItemId}/actions/{actionName}/formlinks/{formName}`;

		this.workItemPreviewFormLink = `${this.workflowHost}/api/v1/licenses/{licenseId}/workflows/{workflowName}/items/{workItemId}/stages/{stageName}/formlinks/ReadonlyDetailLink`;
	}

	/**
		 * Data una licenza e un sia indica se ci sono dei workflow attivi
		 *
		 * @param licenseId
		 * @param siaCode
		 * @return Observable<boolean> Booleano che indica se ci sono o meno dei workflow attivi
		 */
	hasWorkflow(licenseId: string, siaCode: string): Observable<boolean> {
		const url = this.hasWorkflowUrl
			.replace('{licenseId}', licenseId)
			.replace('{codSia}', siaCode);

		return this.http.get<boolean>(url);
	}

	/**
		 * Data una licenza e un sia ritorna la lista dei workflow configurati
		 * @param licenseId
		 * @param siaCode
		 * @return Observable<Workflow[]> Lista dei workflow disponibili
		 */
	getWorkflows(licenseId: string, siaCode: string): Observable<Workflow[]> {
		const url = this.workflows
			.replace('{licenseId}', licenseId)
			.replace('{codSia}', siaCode);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((headers) => this.http.get<Workflow[]>(url, {
				headers
			}))
		);
	}

	/**
		 * Data una licenza, un sia e un workflowId ritorna il totale dei workitems per i filtri applicati
		 * @param licenseId
		 * @param siaCode
		 * @param workflowSectionName
		 * @param body Payload contenente i filtri applicati
		 * @return Observable<boolean> Totale dei workitem
		 */
	getWorkItemsCount(licenseId: string, siaCode: string, workflowSectionName: string, body: WorkItemsSearchBody): Observable<number> {
		const url = this.workItemsCount
			.replace('{licenseId}', licenseId)
			.replace('{codSia}', siaCode)
			.replace('{workflowSectionName}', workflowSectionName);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap(() => {
				this.fullScreenSpinnerService.hide();
				return this.http.post<number>(url, body);
			})
		);
	}

	/**
		 * Restituisce la lista dei workitems
		 * @param licenseId
		 * @param siaCode
		 * @param workflowSectionName
		 * @param body
		 * @param pageIndex
		 * @param pageSize
		 * @return Observable<WorkItem[]> Lista dei workitem
		 */
	getWorkItemsList(
		licenseId: string,
		siaCode: string,
		workflowSectionName: string,
		body: WorkItemsSearchBody,
		pageIndex: number,
		pageSize: number
	): Observable<WorkItemsResponse> {
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap(headers => {
				const url = this.workItemsSearch
					.replace('{licenseId}', licenseId)
					.replace('{codSia}', siaCode)
					.replace('{workflowSectionName}', workflowSectionName);

				const params = {
					'page_index': pageIndex,
					'page_dimension': pageSize
				};

				return this.http.post<WorkItemsResponse>(url, body, {
					params,
					headers
				});
			})
		);
	}

	/**
		 * Ritorna il dettaglio di un workitem
		 * @param licenseId
		 * @param siaCode
		 * @param workflowSectionName
		 * @param workItemId
		 * @return Observable<WorkItemDetail[]>
		 */
	getWorkItemDetails(licenseId: string, siaCode: string, workflowSectionName: string, workItemId: string): Observable<WorkItemDetail[]> {
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap(headers => {

				const url = this.workItemDetail
					.replace('{licenseId}', licenseId)
					.replace('{codSia}', siaCode)
					.replace('{workflowSectionName}', workflowSectionName)
					.replace('{workitemId}', workItemId);

				return this.http.get<WorkItemDetail[]>(url, {
					headers
				});
			})
		);
	}

	/**
		 * Ritorna l'array delle azioni disponibili per un workitem
		 * @param licenseId
		 * @param workflowName
		 * @param workItemId
		 * @return Array delle azini disponibili per il workitem
		 */
	whenWorkItemActions(licenseId: string, workflowName: string, workItemId: string): Observable<WorkItemAction[]> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.workItemActions
			.replace('{licenseId}', licenseId)
			.replace('{workflowName}', workflowName)
			.replace('{workItemId}', workItemId);
		return this.http.get<WorkItemAction[]>(url, options);
	}

	/**
		 * Ritorna l'excel dei workitems filtrati
		 * @param licenseId
		 * @param codSia
		 * @param workflowSectionName
		 * @param docSeries
		 * @param body
		 * @param timezone
		 * @return Excel
		 */
	exportExcel(
		licenseId: string,
		codSia: string,
		workflowSectionName: string,
		docSeries: string,
		body: WorkItemsSearchBody,
		timezone?: string
	): Observable<Blob> {
		let params = new HttpParams();

		if (timezone)
			params = params.set('tz_timezone', timezone);

		const url = this.exportExcelWorkItems
			.replace('{licenseId}', licenseId)
			.replace('{codSia}', codSia)
			.replace('{docSeries}', docSeries)
			.replace('{workflowSectionName}', workflowSectionName);

		return this.http.post<Blob>(url, body, {
			responseType: 'blob' as 'json',
			params
		});
	}

	/**
		 * Ritorna l'excel dei workitem selezionati
		 * @param licenseId
		 * @param codSia
		 * @param workflowSectionName
		 * @param docSeries
		 * @param body
		 * @param timezone
		 */
	exportExcelSelectedItem(
		licenseId: string,
		codSia: string,
		workflowSectionName: string,
		docSeries: string,
		body: ExportExcelSelectedItemPayload,
		timezone?: string
	): Observable<Transfer> {
		let params = new HttpParams();

		if (timezone)
			params = params.set('tz_timezone', timezone);

		const url = this.exportExcelSelectedWorkItems
			.replace('{licenseId}', licenseId)
			.replace('{codSia}', codSia)
			.replace('{docSeries}', docSeries)
			.replace('{workflowSectionName}', workflowSectionName);

		const options = {
			observe: 'events' as const,
			params,
			reportProgress: true,
			responseType: 'blob' as const
		};
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.post(url, body, opts).pipe(
					transferHandler(this.transferService)
				);
			})
		);
	}

	/**
		 * Chiamata (a Magellano) per ottenre lo storico delle azioni di un workitem
		 * @param body
		 */
	whenWorkItemHistory(body: SearchRequest): Observable<DocumentsResponse> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.workItemHistory;
		return this.http.post<DocumentsResponse>(url, body, options);
	}

	// ritorna un array di oggetti di tipo Workflow filtrati per id serie documentale
	whenWorkflowsByDocSeries(licenseId: string, docSeriesId: string): Observable<Workflow[]> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.workflowsByDocSeries
			.replace('{licenseId}', licenseId)
			.replace('{docSeriesId}', docSeriesId);
		return this.http.get<{ workflows: Workflow[] }>(url, options).pipe(map(res => res?.workflows ?? []));
	}

	/**
		 * Esecuzione di un azione diretta
		 * @param licenseId
		 * @param actionUrl
		 * @param body
		 * @param queryBody
		 */
	whenWorkItemActionExecute(licenseId: string, actionUrl: string, body: ActionExecutePayload, queryBody: boolean): Observable<unknown> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		let url = this.workflowHost + actionUrl;
		url = url.replace('{licenseId}', licenseId);
		if (queryBody)
			if (this.hasQuestionMark(url))
				url += '&_body=true';
			else
				url += '?_body=true';

		return this.http.post<unknown>(url, body, options);
	}

	/**
		 * Dato l'azione indiretta mi ritorna l'url per la visualizzazione del form nel frame
		 * @param actionUrl
		 */
	whenWorkItemActionForm(actionUrl: string): Observable<{ link: string }> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.workflowHost + actionUrl;
		return this.http.get<{ link: string }>(url, options);
	}

	// passando la url dell'azione, ritorna il link del form per per la creazione nuovo item (url per iframe)
	whenWorkItemCreationForm(creationItemUrl: string): Observable<{ link: string }> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.workflowHost + creationItemUrl;
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap(() => {
				this.fullScreenSpinnerService.hide();
				return this.http.get<{ link: string }>(url, options);
			}
			)
		);
	}

	// richiede il link per la form nel modal di anteprima work item
	whenWorkItemPreviewLink(
		licenseId: string, workflowName: string, workItemId: string, stageName: string, action?: string): Observable<{ link: string }> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		let url = this.workItemPreviewFormLink
			.replace('{licenseId}', licenseId)
			.replace('{workflowName}', workflowName)
			.replace('{workItemId}', workItemId)
			.replace('{stageName}', stageName);
		if (action)
			url = url + '&action=' + action;

		return this.http.get<{ link: string }>(url, options);
	}

	// passando l'id dell'item, ritorna un work item
	whenWorkItem(licenseId: string, workflowName: string, workItemId: string): Observable<WorkItem> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.workItem
			.replace('{licenseId}', licenseId)
			.replace('{workflowName}', workflowName)
			.replace('{itemId}', workItemId);
		return this.http.get<WorkItem>(url, options);
	}

	getBeFlowHost() {
		return this.beFlowHost;
	}

	// crea un work item. In query string _body=true ritorna l'oggetto creato (default), con false ritorna solo l'id
	whenCreateWorkItem(licenseId: string, workflowName: string, body: unknown): Observable<WorkItemCreationResponse> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		let url = this.workItemCreate
			.replace('{licenseId}', licenseId)
			.replace('{workflowName}', workflowName);
		if (this.hasQuestionMark(url))
			url += '&_body=true';
		else
			url += '?_body=true';

		return this.http.post<WorkItemCreationResponse>(url, body, options);
	}

	/**
	 * Ritorna il dettaglio di un workitem
	 * @param licenseId
	 * @param workflowName
	 * @param body
	 * @return Observable<WorkItem[]>
	 */
	getWorkItemByWorkflowName(licenseId: string, workflowName: string, body: WorkItemsSearchBody): Observable<WorkItem[]> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.workItems
			.replace('{licenseId}', licenseId)
			.replace('{workflowName}', workflowName);

		return this.http.post<WorkItem[]>(url, body, options);
	}

	private hasQuestionMark(url: string): boolean {
		return url.indexOf('?') !== -1;
	}

}
