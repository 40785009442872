import { Component, Input, OnDestroy } from '@angular/core';
import { FRDocumentiDocumentsService2, FRDocumentiExecuteActionsService } from '@ctel/gawcons-commons';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'gaw-confirm-anomaly',
	templateUrl: './confirm-anomaly.component.html',
	styleUrls: ['./confirm-anomaly.component.scss']
})
export class ConfirmAnomalyComponent implements OnDestroy {
	@Input() modal: BsModalRef;
	@Input() data: {
		action: unknown;
		actionParams?: unknown;
		title: string;
	};
	note: unknown;
	destroy$ = new Subject<void>();


	constructor(
		private documentsService: FRDocumentiDocumentsService2,
		private actionsService: FRDocumentiExecuteActionsService,
		private notificationService: NotificationService
	) { }

	executeAction() {
		this.confirm(
			this.data['actionParams']?.['licenseId'],
			this.data['actionParams']?.['siaCode'],
			[this.data['actionParams']?.['keys']]
		);
		this.modal.hide();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private confirm(
		licenseId: string,
		siaCode: string,
		documenti: {
			progBusta: number;
			progSpool: number
		}[]
	) {
		this.actionsService.confirm(licenseId, siaCode, documenti).pipe(
			catchError(() => {
				this.notificationService.showSweetAlert(NotificationType.ERROR, '', 'Nessun documento anomalo trovato');
				return EMPTY;
			}),
			takeUntil(this.destroy$)
		)
			.subscribe(() => this.documentsService.refreshDocuments());
	}

}
