<div class="blockpage-main">
	<div class="card my-2">

		<div class="card-header text-center">
			<div class="fa fa-ban fa-6x text-white" aria-hidden="true"></div>
		</div>

		<div class="card-body">

			<div class="col text-center text-dark fs-3 my-2">
				Accesso Bloccato per Motivi di Sicurezza
			</div>

			<div class="col text-center text-dark my-3 fs-dot9rem">
				L’accesso alla pagina web richiesta è stato bloccato per questioni di sicurezza.
				Se ritenete questo blocco un errore, potete contattare l’Assistenza di Credemtel
			</div>

			<div class="col text-center text-dark fs-5">Credemtel Spa</div>

		</div>
	</div>

</div>