import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { Observable } from 'rxjs';

export class ActionBuilder {

	private readonly actionVM: ActionObject = new ActionObject();

	/**
	 * @param code è il codice azione del catalogo azioni su db
	 * @param actionCode è il codice che identifica il codice da eseguire per quell'azione
	 */
	constructor(code: string, actionCode: ActionCode) {
		this.actionVM.actionCode = actionCode;
		this.actionVM.code = code;
	}

	public setActionURL(url: string): ActionBuilder {
		this.actionVM.url = url;
		return this;
	}

	public setCallback(callback: (...params) => Observable<any>): ActionBuilder {
		this.actionVM.execute = callback;
		return this;
	}

	public setParams(params: any): ActionBuilder {
		this.actionVM.params = params;
		return this;
	}

	public setName(name: string): ActionBuilder {
		this.actionVM.name = name;
		return this;
	}
	public setIcon(icon: string): ActionBuilder {
		this.actionVM.icon = icon;
		return this;
	}

	//
	// public disabledCallback(func: () => any): ActionBuilder {
	// 	this.actionVM.model.disabledChecks = func;
	// 	return this;
	// }

	public build(): ActionObject {
		return this.actionVM;
	}

}
