<!--<div *ngIf="pdfOutcome$ | async as pdfResult" class="resp-container">-->
<!--	<iframe [src]="pdfResult" frameborder="0" scrolling="no"></iframe>-->
<!--</div>-->

<div class="resp-container">
	<div class="document-container">
		<dry-pdf-viewer
			[documentAttachment]="pdfOutcome">
		</dry-pdf-viewer>
	</div>
</div>
