import { Injectable, OnDestroy } from '@angular/core';
import { TransferUtils } from '@ctel/transfer-manager';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { ConfigService } from 'app/core/common/config/config.service';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { Company } from 'app/entities/companies/company';
import { AppConfig } from 'app/entities/config/app-config';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { saveAs } from 'file-saver';
import { EMPTY, Observable, ReplaySubject, takeUntil } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { FRDocumentiDocumentsService2 } from '../documents/documents.service';
import { ActionsHttpService } from './actions-http.service';
import { FRDocumentiActionsService2 } from './actions.service';

/**
 * Servizio che gestisce l'esecuzione delle azioni sui documenti
 */
@Injectable({
	providedIn: 'root'
})
export class FRDocumentiExecuteActionsService implements OnDestroy {

	currentCompany: Company;
	private appConfig: AppConfig;
	private downloadAction?: ActionObject;

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private actionsHttpService: ActionsHttpService,
		private notificationService: NotificationService,
		private actionsService: FRDocumentiActionsService2,
		private documentsService: FRDocumentiDocumentsService2,
		private companiesService: CompaniesService,
		confService: ConfigService
	) {
		this.currentCompany = companiesService.getCurrentCompanyValue();
		confService.whenAppConfig().pipe(takeUntil(this.destroy$))
			.subscribe(value => this.appConfig = value);
		this.actionsService.whenSingleActionsList().pipe(takeUntil(this.destroy$))
			.subscribe(
				value => this.downloadAction = value?.find(value1 => value1.actionCode === ActionCode.DOWNLOAD_PRESERVED_DOCUMENT));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public whenLoadingAction(): Observable<boolean> {
		return this.actionsHttpService.whenLoadingAction();
	}

	// ESECUZIONE AZIONE SINGOLA
	public executeSingleAction(action: ActionObject, data: unknown) {
		const actionCode = action.actionCode;
		switch (actionCode) {
			case ActionCode.DOWNLOAD:
				this.download(action, data);
				break;
			case ActionCode.CANCEL:
				this.cancel(action.url, data['licenseId'], data['siaCode'], [{
					progSpool: +data['keys']?.progSpool,
					progBusta: +data['keys']?.progBusta
				}]);
				break;
			case ActionCode.CONFIRM:
				this.confirm(data['licenseId'], data['siaCode'], [data['keys']])
					.subscribe({
						error: () => this.notificationService.showSweetAlert(NotificationType.ERROR, '',
							'Nessun documento anomalo trovato')
					});
				break;
		}
	}

	public executeMultiAction(action: ActionObject, keys: { progBusta: number; progSpool: number }[]) {
		const actionCode = action.actionCode;
		const siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
		const licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
		const data = { keys, licenseId, siaCode };
		switch (actionCode) {
			case ActionCode.CONFIRM:
				this.confirm(licenseId, siaCode, keys)
					.subscribe({
						error: () => this.notificationService.showSweetAlert(NotificationType.ERROR, '',
							'Nessun documento anomalo trovato')
					});
				break;
			case ActionCode.CANCEL:
				this.cancel(action.url, data.licenseId, data.siaCode, keys);
				break;
		}
	}

	confirm(licenseId: string, siaCode: string, documenti: { progBusta: number; progSpool: number }[]) {
		return this.actionsHttpService.confirm('Conferma automatica', licenseId, siaCode, documenti).pipe(
			tap(() => {
				this.notificationService.showSweetAlert(NotificationType.SUCCESS, '',
					'Conferma dei documenti con anomalia avvenuta con successo');
				this.documentsService.refreshDocuments();
			})
		);
	}

	private download(action: ActionObject, data: unknown) {
		const actionUrl = action.url;
		this.actionsHttpService.whenDownload(actionUrl, data['keys']?.progSpool, data['keys']?.progBusta)
			.pipe(
				take(1),
				catchError(() => {
					this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante il download del file .zip', '');
					return EMPTY;
				})
			)
			.subscribe(result => {
				if (TransferUtils.isHttpResponse(result.originatingEvent)) {
					const blob = new Blob([result.originatingEvent.body], { type: 'application/pdf' });
					saveAs(blob, result.name);
				}
			});
	}


	private cancel(actionUrl: string, licenseId, siaCode, documenti: { progBusta: number; progSpool: number }[]) {
		this.notificationService.showSweetAlert(NotificationType.QUESTION, 'Cancella',
			'Confermi la cancellazione?', () => {
				this.actionsHttpService.cancel(actionUrl, licenseId, siaCode, documenti)
					.subscribe(() => {
						this.notificationService.showSweetAlert(NotificationType.SUCCESS, '',
							documenti.length > 1 ? 'I documenti sono stati cancellati con successo'
								: 'Il documento è stato cancellato con successo');
						this.documentsService.refreshDocuments();
					});
			});
	}
}
