import { Injectable } from '@angular/core';

const provincie = [
	{ value: 'AG', label: 'Agrigento', region: 'Sicilia' },
	{ value: 'AL', label: 'Alessandria', region: 'Piemonte' },
	{ value: 'AN', label: 'Ancona', region: 'Marche' },
	{ value: 'AO', label: 'Aosta', region: 'Valle d\'Aosta' },
	{ value: 'AQ', label: 'Aquila', region: 'Abruzzo' },
	{ value: 'AR', label: 'Arezzo', region: 'Toscana' },
	{ value: 'AP', label: 'Ascoli-Piceno', region: 'Marche' },
	{ value: 'AT', label: 'Asti', region: 'Piemonte' },
	{ value: 'AV', label: 'Avellino', region: 'Campania' },
	{ value: 'BA', label: 'Bari', region: 'Puglia' },
	{ value: 'BT', label: 'Barletta-Andria-Trani', region: 'Puglia' },
	{ value: 'BL', label: 'Belluno', region: 'Veneto' },
	{ value: 'BN', label: 'Benevento', region: 'Campania' },
	{ value: 'BG', label: 'Bergamo', region: 'Lombardia' },
	{ value: 'BI', label: 'Biella', region: 'Piemonte' },
	{ value: 'BO', label: 'Bologna', region: 'Emilia Romagna' },
	{ value: 'BZ', label: 'Bolzano', region: 'Trentino Alto Adige' },
	{ value: 'BS', label: 'Brescia', region: 'Lombardia' },
	{ value: 'BS', label: 'Brescia', region: 'Lombardia' },
	{ value: 'BR', label: 'Brindisi', region: 'Puglia' },
	{ value: 'CA', label: 'Cagliari', region: 'Sardegna' },
	{ value: 'CL', label: 'Caltanissetta', region: 'Sicilia' },
	{ value: 'CB', label: 'Campobasso', region: 'Molise' },
	{ value: 'CI', label: 'Carbonia Iglesias', region: 'Sardegna' },
	{ value: 'CE', label: 'Caserta', region: 'Campania' },
	{ value: 'CT', label: 'Catania', region: 'Sicilia' },
	{ value: 'CZ', label: 'Catanzaro', region: 'Calabria' },
	{ value: 'CH', label: 'Chieti', region: 'Abruzzo' },
	{ value: 'CO', label: 'Como', region: 'Lombardia' },
	{ value: 'CS', label: 'Cosenza', region: 'Calabria' },
	{ value: 'CR', label: 'Cremona', region: 'Lombardia' },
	{ value: 'KR', label: 'Crotone', region: 'Calabria' },
	{ value: 'CN', label: 'Cuneo', region: 'Piemonte' },
	{ value: 'EN', label: 'Enna', region: 'Sicilia' },
	{ value: 'FM', label: 'Fermo', region: 'Marche' },
	{ value: 'FE', label: 'Ferrara', region: 'Emilia Romagna' },
	{ value: 'FI', label: 'Firenze', region: 'Toscana' },
	{ value: 'FG', label: 'Foggia', region: 'Puglia' },
	{ value: 'FC', label: 'Forli-Cesena', region: 'Emilia Romagna' },
	{ value: 'FR', label: 'Frosinone', region: 'Lazio' },
	{ value: 'GE', label: 'Genova', region: 'Liguria' },
	{ value: 'GO', label: 'Gorizia', region: 'Friuli Venezia Giulia' },
	{ value: 'GR', label: 'Grosseto', region: 'Toscana' },
	{ value: 'IM', label: 'Imperia', region: 'Liguria' },
	{ value: 'IS', label: 'Isernia', region: 'Molise' },
	{ value: 'SP', label: 'La-Spezia', region: 'Liguria' },
	{ value: 'LT', label: 'Latina', region: 'Lazione' },
	{ value: 'LE', label: 'Lecce', region: 'Puglia' },
	{ value: 'LC', label: 'Lecco', region: 'Lombardia' },
	{ value: 'LI', label: 'Livorno', region: 'Toscana' },
	{ value: 'LO', label: 'Lodi', region: 'Lombardia' },
	{ value: 'LU', label: 'Lucca', region: 'Toscana' },
	{ value: 'MC', label: 'Macerata', region: 'Marche' },
	{ value: 'MN', label: 'Mantova', region: 'Lombardia' },
	{ value: 'MS', label: 'Massa-Carrara', region: 'Toscana' },
	{ value: 'MT', label: 'Matera', region: 'Basilicata' },
	{ value: 'VS', label: 'Medio Campidano', region: 'Sardegna' },
	{ value: 'ME', label: 'Messina', region: 'Sicilia' },
	{ value: 'MI', label: 'Milano', region: 'Lombardia' },
	{ value: 'MO', label: 'Modena', region: 'Emilia Romagna' },
	{ value: 'MB', label: 'Monza-Brianza', region: 'Lombardia' },
	{ value: 'NA', label: 'Napoli', region: 'Campania' },
	{ value: 'NO', label: 'Novara', region: 'Piemonte' },
	{ value: 'NU', label: 'Nuoro', region: 'Sardegna' },
	{ value: 'OG', label: 'Ogliastra', region: 'Sardegna' },
	{ value: 'OT', label: 'Olbia Tempio', region: 'Sardegna' },
	{ value: 'OR', label: 'Oristano', region: 'Sardegna' },
	{ value: 'PD', label: 'Padova', region: 'Veneto' },
	{ value: 'PA', label: 'Palermo', region: 'Sicilia' },
	{ value: 'PR', label: 'Parma', region: 'Emilia Romagna' },
	{ value: 'PV', label: 'Pavia', region: 'Lombardia' },
	{ value: 'PG', label: 'Perugia', region: 'Umbria' },
	{ value: 'PS', label: 'Pesaro-Urbino (PS)', region: 'Marche' },
	{ value: 'PU', label: 'Pesaro-Urbino (PU)', region: 'Marche' },
	{ value: 'PE', label: 'Pescara', region: 'Abruzzo' },
	{ value: 'PC', label: 'Piacenza', region: 'Emilia Romagna' },
	{ value: 'PI', label: 'Pisa', region: 'Toscana' },
	{ value: 'PT', label: 'Pistoia', region: 'Toscana' },
	{ value: 'PN', label: 'Pordenone', region: 'Friuli Venezia Giulia' },
	{ value: 'PZ', label: 'Potenza', region: 'Basilicata' },
	{ value: 'PO', label: 'Prato', region: 'Toscana' },
	{ value: 'RG', label: 'Ragusa', region: 'Sicilia' },
	{ value: 'RA', label: 'Ravenna', region: 'Emilia Romagna' },
	{ value: 'RC', label: 'Reggio-Calabria', region: 'Calabria' },
	{ value: 'RE', label: 'Reggio-Emilia', region: 'Emilia Romagna' },
	{ value: 'RI', label: 'Rieti', region: 'Lazio' },
	{ value: 'RN', label: 'Rimini', region: 'Emilia Romagna' },
	{ value: 'RM', label: 'Roma', region: 'Lazio' },
	{ value: 'RO', label: 'Rovigo', region: 'Veneto' },
	{ value: 'SA', label: 'Salerno', region: 'Campania' },
	{ value: 'SS', label: 'Sassari', region: 'Sardegna' },
	{ value: 'SV', label: 'Savona', region: 'Liguria' },
	{ value: 'SI', label: 'Siena', region: 'Toscana' },
	{ value: 'SR', label: 'Siracusa', region: 'Sicilia' },
	{ value: 'SO', label: 'Sondrio', region: 'Lombardia' },
	{ value: 'SU', label: 'Sud Sardegna', region: 'Sardegna' },
	{ value: 'TA', label: 'Taranto', region: 'Puglia' },
	{ value: 'TE', label: 'Teramo', region: 'Abruzzo' },
	{ value: 'TR', label: 'Terni', region: 'Umbria' },
	{ value: 'TO', label: 'Torino', region: 'Piemonte' },
	{ value: 'TP', label: 'Trapani', region: 'Sicilia' },
	{ value: 'TN', label: 'Trento', region: 'Trentino Alto Adige' },
	{ value: 'TV', label: 'Treviso', region: 'Veneto' },
	{ value: 'TS', label: 'Trieste', region: 'Friuli Venezia Giulia' },
	{ value: 'UD', label: 'Udine', region: 'Friuli Venezia Giulia' },
	{ value: 'VA', label: 'Varese', region: 'Lombardia' },
	{ value: 'VE', label: 'Venezia', region: 'Veneto' },
	{ value: 'VB', label: 'Verbania', region: 'Piemonte' },
	{ value: 'VC', label: 'Vercelli', region: 'Piemonte' },
	{ value: 'VR', label: 'Verona', region: 'Veneto' },
	{ value: 'VV', label: 'Vibo-Valentia', region: 'Calabria' },
	{ value: 'VI', label: 'Vicenza', region: 'Veneto' },
	{ value: 'VT', label: 'Viterbo', region: 'Lazio' }
];

@Injectable()
export class CitiesService {

	public getList(): City[] {
		return provincie.slice();
	}
}

export interface City {
	value: string;
	label: string;
	region: string;
}

