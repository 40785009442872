import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { repeat, retry } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PollingService {

	static readonly PERIOD: number = 60000;

	private readonly polling$: Observable<number>;
	private readonly askedRestart = new Subject<void>();

	constructor() {
		this.polling$ = timer(0, PollingService.PERIOD).pipe(
			retry(3),
			repeat({ delay: () => this.askedRestart })
		);
	}

	public restart() {
		this.askedRestart.next();
	}

	public whenPolling() {
		return this.polling$;
	}
}
