import { Injectable } from '@angular/core';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { MassiveActionsCatalog } from './massive-actions-catalog';
import { MultiActionsCatalog } from './multi-actions-catalog';
import { SingleActionsCatalog } from './single-actions-catalog';

@Injectable({
	providedIn: 'root'
})
export class ActionsByType {

	public readonly massiveActions: ActionObject[] = [
		this.massiveActionsCatalog.exportExcelMassive,
		this.massiveActionsCatalog.cancelElabPremuMassive,
		this.massiveActionsCatalog.cancelElabMassive,
		this.massiveActionsCatalog.cancelElabGedPecMassive,
		this.massiveActionsCatalog.cancelElabGedPostMassive,
		this.massiveActionsCatalog.cancelElabGedMailMassive,
		this.massiveActionsCatalog.downloadPdfZipMassive,
		this.massiveActionsCatalog.downloadXmlZipGedinvoiceMassive,
		this.massiveActionsCatalog.downloadXmlZipGedpassjoinMassive,
		this.massiveActionsCatalog.exportToManagementMassive,
		this.massiveActionsCatalog.massiveHideDocumentsAction,
		this.massiveActionsCatalog.massiveRestoreDocumentsAction,
		this.massiveActionsCatalog.signDocMassive,
		this.massiveActionsCatalog.downloadEsitiPdfMassive,
		this.massiveActionsCatalog.downloadEsitiXmlMassive,
		this.massiveActionsCatalog.downloadEsitiExcelMassive,
		this.massiveActionsCatalog.exportToManagementFromUnpreserved,
		this.massiveActionsCatalog.excludeFromConsMassive
	];

	public readonly multiActions: ActionObject[] = [
		this.multiActionsCatalog.multiHideDocumentsAction,
		this.multiActionsCatalog.multiRestoreDocumentsAction,
		this.multiActionsCatalog.exportToManagement,
		this.multiActionsCatalog.reExportToManagment,
		this.multiActionsCatalog.downloadAttachmentsMultiGedinvoice,
		this.multiActionsCatalog.checkSdiAndResendGedinvoice,
		this.multiActionsCatalog.downloadXmlZipGeneric,
		this.multiActionsCatalog.downloadXmlZipGedinvoice,
		this.multiActionsCatalog.downloadXmlZipGedpassJoin,
		this.multiActionsCatalog.downloadPdfZipGedinvoice,
		this.multiActionsCatalog.downloadPdfZipGedmail,
		this.multiActionsCatalog.zipDownload,
		this.multiActionsCatalog.downloadPdfZipMassive,
		this.multiActionsCatalog.downloadZipGedPost,
		this.multiActionsCatalog.downloadZipGedPec,
		this.multiActionsCatalog.downloadPdfZipGedPassJoin,
		this.multiActionsCatalog.mergePdfGedPost,
		this.multiActionsCatalog.exportExcel,
		this.multiActionsCatalog.cancelElabGedInvoice,
		this.multiActionsCatalog.cancelElabGedMail,
		this.multiActionsCatalog.cancelElabGedPec,
		this.multiActionsCatalog.cancelElabGedPost,
		this.multiActionsCatalog.cancelElabPremu,
		this.multiActionsCatalog.signDoc,
		this.multiActionsCatalog.exportToFtp,
		this.multiActionsCatalog.downloadEsitiPdf,
		this.multiActionsCatalog.downloadEsitiXml,
		this.multiActionsCatalog.downloadEsitiExcel,
		this.multiActionsCatalog.excludeFromCons,
		this.multiActionsCatalog.exportToManagementFromUnpreserved
	];

	public readonly singleActions: ActionObject[] = [
		this.singleActionsCatalog.viewDocument,
		this.singleActionsCatalog.signDoc,
		this.singleActionsCatalog.showOriginalPdf,
		this.singleActionsCatalog.sendAnalogicCopyGedinvoice,
		this.singleActionsCatalog.insertProtocol,
		this.singleActionsCatalog.confirmRegistration,
		this.singleActionsCatalog.insertRCFile,
		this.singleActionsCatalog.insertRCMetadata,
		this.singleActionsCatalog.insertProtocolAndRCFile,
		this.singleActionsCatalog.insertProtocolAndRCMetadata,
		this.singleActionsCatalog.editProtocolData,
		this.singleActionsCatalog.showStatusHistoryGedPost,
		this.singleActionsCatalog.showStatusHistoryGedMail,
		this.singleActionsCatalog.showOutComesHistoryGedPost,
		this.singleActionsCatalog.showStatusHistoryGedPec,
		this.singleActionsCatalog.showOutComesHistoryGedPec,
		this.singleActionsCatalog.showChangesHistoryGedinvoice,
		this.singleActionsCatalog.showOutcomesHistoryGedinvoice,
		this.singleActionsCatalog.showStatusHistoryGedinvoice,
		this.singleActionsCatalog.showOutcomesHistoryPayable,
		this.singleActionsCatalog.multiHideDocumentsAction,
		this.singleActionsCatalog.multiRestoreDocumentsAction,
		this.singleActionsCatalog.forwardWithCopies,
		this.singleActionsCatalog.forwardWithoutCopies,
		this.singleActionsCatalog.forwardWithSupplier,
		this.singleActionsCatalog.forwardWithoutSupplier,
		this.singleActionsCatalog.exportToManagement,
		this.singleActionsCatalog.editAndResendInvoice,
		this.singleActionsCatalog.editAndResendInvoicePremuSign,
		this.singleActionsCatalog.editAndResendInvoicePremuError,
		this.singleActionsCatalog.editInvoicePecMail,
		this.singleActionsCatalog.editInvoiceWithoutCancelGedinvoice,
		this.singleActionsCatalog.downloadXml,
		this.singleActionsCatalog.downloadXmlGedpassjoin,
		this.singleActionsCatalog.pdfDownloadGedInvoice,
		this.singleActionsCatalog.pdfDownload,
		this.singleActionsCatalog.pdfDownloadImporting,
		this.singleActionsCatalog.pdfDownloadDelivered,
		this.singleActionsCatalog.pdfDownloadShipping,
		this.singleActionsCatalog.pdfDownloadSign,
		this.singleActionsCatalog.pdfDownloadError,
		this.singleActionsCatalog.pdfDownloadCanceled,
		this.singleActionsCatalog.downloadPdfGedPost,
		this.singleActionsCatalog.downloadPdfGedPec,
		this.singleActionsCatalog.downloadPdfGedMail,
		this.singleActionsCatalog.singlePdfDownload,
		this.singleActionsCatalog.downloadAttachmentsGedinvoice,
		this.singleActionsCatalog.downloadAttachmentsGedPassJoin,
		this.singleActionsCatalog.cancelElabGedInvoice,
		this.singleActionsCatalog.cancelElabGedMail,
		this.singleActionsCatalog.cancelElabGedPec,
		this.singleActionsCatalog.cancelElabGedPost,
		this.singleActionsCatalog.cancelElabPremuSign,
		this.singleActionsCatalog.cancelElabPremuError,
		this.singleActionsCatalog.exportToFtp,
		this.singleActionsCatalog.downloadEsitiPdf,
		this.singleActionsCatalog.downloadEsitiXml,
		this.singleActionsCatalog.downloadEsitiExcel,
		this.singleActionsCatalog.excludeFromCons,
		this.singleActionsCatalog.exportToManagementFromUnpreserved
	];

	constructor(
		private massiveActionsCatalog: MassiveActionsCatalog,
		private multiActionsCatalog: MultiActionsCatalog,
		private singleActionsCatalog: SingleActionsCatalog,
	) { }

}
