import { Type } from '@angular/core';
import { IRow } from '@ctel/gaw-table';
import { GridApi } from 'app/shared/components/grid/interface/grid-api';
import { CheckboxCellAddition } from '../../checkbox-select-addition/checkbox-cell-addition';
import { GridOptions, RowStyle } from './options';

export class GridOptionsBuilder<T> {

	private options = new GridOptions();

	/**
	 * Abilità la selezione delle row
	 * @param selectionType Parametro opzionale per scegliere la tipologia di selezione, default 'multi'
	 * @return GridOptionsBuilder
	 */
	enableRowSelection(selectionType?: 'single' | 'multi'): GridOptionsBuilder<T> {
		this.options.rowSelection = selectionType ? selectionType : 'multi';
		return this;
	}

	/**
	 * Abilita la funzione di filtro nella griglia
	 * @return GridOptionsBuilder
	 */
	enableFilter(): GridOptionsBuilder<T> {
		this.options.filter = true;
		return this;
	}

	/**
	 * Abilita la funzione di ordinamento in griglia
	 * @return GridOptionsBuilder
	 */
	enableSorting(): GridOptionsBuilder<T> {
		this.options.sorting = true;
		return this;
	}

	/**
	 * Abilita la funzione di doppio click sulla row
	 * @return GridOptionsBuilder
	 */
	enableDoubleClick(): GridOptionsBuilder<T> {
		this.options.dblClick = true;
		return this;
	}

	/**
	 * Permette di specificare l'id della row
	 * @param func Funzione che accetta in ingresso il model della row e ne ritorna l'id
	 * @return GridOptionsBuilder
	 */
	rowId(func: (item: unknown) => number): GridOptionsBuilder<T> {
		this.options.getRowId = func;
		return this;
	}

	/**
	 * Permette di specificare degli stili di una riga in base a determinate condizioni
	 * @param func
	 */
	setRowStyles(func: (row: IRow) => RowStyle): GridOptionsBuilder<T> {
		this.options.setRowStyle = func;
		return this;
	}

	/**
	 * Permette di specificare una callback che verrà richiamata alla renderizzazione della griglia
	 * @param func Funzione di callback a cui viene passata un istanza di GridApi
	 * @return GridOptionsBuilder
	 */
	whenGridReady(func: (gridApi: GridApi<T>) => void): GridOptionsBuilder<T> {
		this.options.whenGridReady = func;
		return this;
	}

	/**
	 * Permette di renderizzare un componente in aggiunta alle checkbox di selezione
	 * @param component Componente che implementa CheckboxCellAddition
	 * @return GridOptionsBuilder
	 */
	addCellToCheckboxSelection(component: Type<CheckboxCellAddition>): GridOptionsBuilder<T> {
		this.options.checkboxAddCellComponent = component;
		return this;
	}

	build(): GridOptions<T> {
		return this.options;
	}
}
