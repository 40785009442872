<div class="notes-list row" *ngIf="hasNotes === true">
  <div *ngFor="let note of notes" [ngStyle]="style" [ngClass]="klass" class="col-12 note-box">
    <div class="note-header">
      <div class="info">
        <h4>
          {{ note.nickName }} <br />
          <span>{{ note.lastUpdateDate | date : 'medium' }}</span>
        </h4>
      </div>
      <div class="action d-flex gap-1" *ngIf="user !== undefined">
        <button class="btn-ghost" (click)="onNoteClick(note)">
          <i
            class="fa notes-list-view"
            [ngClass]="{
              'fa-pencil fas fa-pencil-alt': user === +note.authUserId,
              'far fa-eye': user !== +note.authUserId
            }"
          ></i>
        </button>
        <ng-container *ngIf="user === +note.authUserId">
          <button class="btn-ghost" (click)="onNoteDelete(note)">
            <i class="fa fa-trash far fa-trash-alt notes-list-remove"></i>
          </button>
        </ng-container>
      </div>
    </div>
    <div class="note-body">
      <div class="note-info">{{ note.note.length > 150 ? (note.note | slice : 0 : 150) + '...' : note.note }}</div>
    </div>
  </div>
</div>
