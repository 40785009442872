import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
	DocSeriesMetadataDesc,
	DocSeriesMetadataDescSearch,
	DocumentDetails,
	DocumentsResponse,
	FiltersResponse,
	GraphicInfoResponse
} from '@ctel/gaw-commons';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { SearchBarTypeahead } from 'app/entities/search-bar/search-bar-typeahead';
import { Sections } from 'app/entities/sections/sections';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative ai workflow
 */
@Injectable({
	providedIn: 'root'
})
export class DocumentsHttpService implements OnDestroy {

	private jsonContentType = 'application/json';
	// host
	private searchHost: string;
	private configHost: string;
	private docSeriesHost: string;
	private updateHost: string;
	private docDetails: string;
	private uiConfigHost: string;
	private gawpecHost: string;
	private gawmailHost: string;
	private docDetailsHost: string;
	// endpoints
	private search: string;
	private favoriteFilters: string;
	private sections: string;
	private docSeriesInfo: string;
	private graphic: string;
	private docDetail: string;
	private updateTags: string;
	private typeAhead: string;
	private favoritesSections: string;
	private displayDocument: string;
	private displayMessage: string;
	private metadataDesc: string;

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
		public spinnerService: FullScreenSpinnerService
	) {
		this.configService.whenAppConfig()
			.pipe(takeUntil(this.destroy$))
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	httpHostInit(appConfig: AppConfig) {
		/* ENDPOINTS MAGELLANO ---------------------------------------------------------------------------------------------------------- */
		this.searchHost = appConfig.search.http.host;

		this.docDetail = `${this.searchHost}/v3/documents/{licenseId}/{siaCode}/{docSeriesId}/{elasticDocumentId}/details?clientid=GAWMAIL`;
		this.typeAhead = `${this.searchHost}/v3/search/typeahead?clientid=GAWMAIL`;

		this.docDetails = appConfig.docDetails.http.host;

		this.updateHost = appConfig.update.http.host;
		this.updateTags = `${this.updateHost}/v1/document/update`;

		/* ENDPOINTS DOCUMENT GED ------------------------------------------------------------------------------------------------------- */
		this.docDetailsHost = appConfig.docDetails.http.host;

		/* ENDPOINTS DOCSERIES API ------------------------------------------------------------------------------------------------------ */
		this.docSeriesHost = appConfig.docSeries.http.host;
		this.docSeriesInfo = `${this.docSeriesHost}/v1/docseries/{licenseId}/{siaCode}/public`;
		this.graphic = `${this.docSeriesHost}/v1/docseries/{docSeriesId}/public`;
		this.metadataDesc = `${this.docSeriesHost}/v3/docseries/metadata/descriptions`;

		/* ENDPOINTS UI CONFIGURATION --------------------------------------------------------------------------------------------------- */
		this.configHost = appConfig.uiConfiguration.http.host;
		this.sections = `${this.configHost}/v1/favorites/ui/GAWMAIL/sections?licenseId={licenseId}&siaCode={siaCode}`;
		this.uiConfigHost = appConfig.uiConfiguration.http.host;
		this.favoriteFilters = `${this.uiConfigHost}/v1/favorites/ui/GAWMAIL/filters?sectionCode={sectionCode}&licenseId={licenseId}&siaCode={siaCode}`;
		this.favoritesSections = `${this.uiConfigHost}/v1/favorites/ui/GAWMAIL/sections?licenseId={licenseId}&siaCode={siaCode}`;

		/* ENDPOINTS GAWPEC --------------------------------------------------------------------------------------------------- */
		this.gawpecHost = appConfig.gawPecRead.http.host;
		this.gawmailHost = appConfig.gawMailRead.http.host;
		this.search = `${this.gawmailHost}/api/v1/Filter/SearchMail`;
		this.displayDocument = `${this.gawpecHost}/api/v1/Action/viewpdfdocument?channel={serviceName}&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType={docType}&download={download}`;
		this.displayMessage = `${this.gawpecHost}/api/v1/Action/viewmessagedocument?channel={serviceName}&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType={docType}&download={download}`;
	}

	/* HTTP REQUESTS A DICKENS---------------------------------------------------------------------------------------------- */
	whenTagsUpdate(body: string): Observable<any> {
		const url = this.updateTags;
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		return this.http.post<any>(url, body, options);
	}

	/* HTTP REQUESTS A DOC SERIES API ------------------------------------------------------------------------------------------ */
	// ottengo la grafica data una serie doc
	whenGraphic(docSeriesId: string): Observable<GraphicInfoResponse> {
		const url = this.graphic
			.replace('{docSeriesId}', docSeriesId);
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		return this.http.get<GraphicInfoResponse>(url, options);
	}

	/* HTTP REQUESTS A MAGELLANO ---------------------------------------------------------------------------------------------- */

	// FAVORITES FILTERS
	whenFavoriteFilters(sectionCode: string, licenseId: string, siaCode: string): Observable<FiltersResponse> {
		return this.configService.whenAppConfig().pipe(
			filter(value => value !== null),
			switchMap(() => {
				const options = this.getHttpOptions(this.jsonContentType, 'json');
				const url = this.favoriteFilters
					.replace('{sectionCode}', sectionCode)
					.replace('{licenseId}', licenseId)
					.replace('{siaCode}', siaCode);
				return this.http.get<FiltersResponse>(url, options);
			})
		);
	}

	// GET tutti i tipi documento per quel cliente

	// GET tutte le szioni per quel cliente
	whenSections(licenseId: string, siaCode: string): Observable<Sections> {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		const url = this.favoritesSections
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<Sections>(url, options);
	}

	// RITORNA LISTA DOCUMENTI
	whenAllDocuments(body: string): Observable<DocumentsResponse> {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		const url = this.search;
		return this.http.post<DocumentsResponse>(url, body, options);
	}

	// DETTAGLIO METADATI DEL DOCUMENTO
	whenDocumentDetails(
		licenseId: string, siaCode: string, docSeriesId: string, elasticDocumentId: string, includeTechnical: boolean
	): Observable<DocumentDetails> {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		let url = this.docDetail
			.replace('{docSeriesId}', docSeriesId)
			.replace('{elasticDocumentId}', elasticDocumentId)
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		if (includeTechnical)
			url = url + '&includeTechnicalMetadata=true';

		return this.http.get<DocumentDetails>(url, options).pipe(shareReplay({ bufferSize: 1, refCount: true }));
	}

	// Typeahead su ragione sociale nel bandone di ricerca
	whenSearchTypeahead(body: any): Observable<SearchBarTypeahead> {
		const url = this.typeAhead;
		return this.spinnerService.inhibitSpinnerHeaders().pipe(
			switchMap(headers => this.http.post<SearchBarTypeahead>(url, body, {
				headers
			}))
		);
	}

	whenDisplayDocument(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean, docType: string
	): Observable<any> {

		const url = this.displayDocument
			.replace('{serviceName}', serviceName)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{docHash}', docHash === '' ? null : docHash)
			.replace('{docType}', docType)
			.replace('{download}', String(download));

		let options;
		if (download)
			options = CustomHttpOptions.getHttpOptionsObserveResponse(this.jsonContentType, 'blob');
		else
			options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'blob');

		return this.http.get<any>(url, options);
	}

	whenDisplayMessage(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean, docType: string
	): Observable<any> {

		const url = this.displayMessage
			.replace('{serviceName}', serviceName)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{docHash}', docHash === '' ? null : docHash)
			.replace('{docType}', docType)
			.replace('{download}', String(download));

		let options;
		if (download)
			options = CustomHttpOptions.getHttpOptionsObserveResponse(this.jsonContentType, 'blob');
		else
			options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'blob');

		return this.http.get<any>(url, options);
	}

	whenMetadataDescriptions(body: DocSeriesMetadataDescSearch): Observable<DocSeriesMetadataDesc[]> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.metadataDesc;
		return this.http.post<DocSeriesMetadataDesc[]>(url, body, options);
	}

	private getHttpOptions(contentType: string, responseType: string): object {
		const headers = new HttpHeaders().set('Content-Type', contentType);
		let options;
		switch (responseType) {
			case 'blob':
				options = {
					headers,
					responseType: 'blob' as const
				};
				break;
			case 'json':
			default:
				options = {
					headers,
					responseType: 'json' as const
				};
				break;
		}
		return options;
	}
}
