/**
 * Interfaccia di configurazione del modulo di autenticazione.
 */
import { OpenIdConfiguration } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

export interface IAuthModuleConfig {
	config: Promise<IAuthConfig>;
}

export interface IAuthConfig {
	defaultRoute: string;

	/** Path di default dell'applicativo post login */
	defaultAfterLoginRoute: string;

	/** Path per la pagina di accesso vietato */
	accessDeniedRoute: string;

	/** Configurazioni OIDC */
	oidc: {
		config: OpenIdConfiguration
	};

	/** Lista degli indirizzi su cui aggiungere l'header di autenticazione */
	whiteList: string[];

	/** Lista degli indirizzi su cui non agigungere l'header di autenticazione */
	blackList: string[];

	/** URL per ottenere le informazioni utente */
	userProfileHost: string;
}

export abstract class AuthConfigService {
	abstract getAuthConfig(): Observable<IAuthConfig>;
}
