import { Injectable } from '@angular/core';
import * as channels from 'app/constants/channels-invoice/channels-invoice';
import { ChannelsInvoice } from 'app/constants/channels-invoice/channels-invoice';
import { Copier } from 'app/core/common/utilities/copier';
import { Channels, ListChannelGed, ListMetadatiGed } from 'app/entities/channels/channels';
import { EnabledChannels } from 'app/entities/invoice-pa/enabled-channels/enabled-channels';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChannelsHttpService } from './channels-http.service';

@Injectable()
export class ChannelsService {
	currentButtons!: Observable<EnabledChannels[]>;

	public test = '{\n' +
		'  "channelType": 0,\n' +
		'  "listChannelGed": [\n' +
		'    {\n' +
		'      "channelName": {\n' +
		'        "value": "6",\n' +
		'        "headerLabel": "GEDPOST",\n' +
		'        "tooltipLabel": null,\n' +
		'        "position": 1,\n' +
		'        "type": null\n' +
		'      },\n' +
		'      "channelId": 6\n' +
		'    },\n' +
		'    {\n' +
		'      "channelName": {\n' +
		'        "value": "21",\n' +
		'        "headerLabel": "GEDMAIL",\n' +
		'        "tooltipLabel": null,\n' +
		'        "position": 1,\n' +
		'        "type": null\n' +
		'      },\n' +
		'      "channelId": 21\n' +
		'    },\n' +
		'    {\n' +
		'      "channelName": {\n' +
		'        "value": "35",\n' +
		'        "headerLabel": "GEDINVOICE",\n' +
		'        "tooltipLabel": null,\n' +
		'        "position": 1,\n' +
		'        "type": null\n' +
		'      },\n' +
		'      "channelId": 35\n' +
		'    }\n' +
		'  ],\n' +
		'  "listMetadatiGed": [\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "string"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "GEDPOST",\n' +
		'          "headerLabel": "GEDPOST",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 6\n' +
		'      },\n' +
		'      "idChiave": -1,\n' +
		'      "labelChiave": "Ragione Sociale",\n' +
		'      "nomeCampo": "PRIMARIGA",\n' +
		'      "required": true,\n' +
		'      "listaValoriDropDown": [],\n' +
		'      "xPathExpression": null\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "string"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "GEDPOST",\n' +
		'          "headerLabel": "GEDPOST",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 6\n' +
		'      },\n' +
		'      "idChiave": -2,\n' +
		'      "labelChiave": "Informazioni sul destinatario o edificio",\n' +
		'      "nomeCampo": "SECONDARIGA",\n' +
		'      "required": false,\n' +
		'      "listaValoriDropDown": [],\n' +
		'      "xPathExpression": null\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "string"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "GEDPOST",\n' +
		'          "headerLabel": "GEDPOST",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 6\n' +
		'      },\n' +
		'      "idChiave": -3,\n' +
		'      "labelChiave": "Indirizzo con civico",\n' +
		'      "nomeCampo": "TERZARIGA",\n' +
		'      "required": true,\n' +
		'      "listaValoriDropDown": [],\n' +
		'      "xPathExpression": null\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "string"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "GEDPOST",\n' +
		'          "headerLabel": "GEDPOST",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 6\n' +
		'      },\n' +
		'      "idChiave": -4,\n' +
		'      "labelChiave": "CAP, città e city",\n' +
		'      "nomeCampo": "QUARTARIGA",\n' +
		'      "required": true,\n' +
		'      "listaValoriDropDown": [],\n' +
		'      "xPathExpression": null\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "string"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "GEDPOST",\n' +
		'          "headerLabel": "GEDPOST",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 6\n' +
		'      },\n' +
		'      "idChiave": -5,\n' +
		'      "labelChiave": "Stato",\n' +
		'      "nomeCampo": "QUINTARIGA",\n' +
		'      "required": true,\n' +
		'      "listaValoriDropDown": [],\n' +
		'      "xPathExpression": null\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "string"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "GEDMAIL",\n' +
		'          "headerLabel": "GEDMAIL",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 21\n' +
		'      },\n' +
		'      "idChiave": 2,\n' +
		'      "labelChiave": "Indirizzo mail",\n' +
		'      "nomeCampo": "T0700001_CAMPO2",\n' +
		'      "required": true,\n' +
		'      "listaValoriDropDown": [],\n' +
		'      "xPathExpression": null\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "decimal"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "PREMULTICANALITA",\n' +
		'          "headerLabel": "PREMULTICANALITA",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 0\n' +
		'      },\n' +
		'      "idChiave": 1,\n' +
		'      "labelChiave": "Numero",\n' +
		'      "nomeCampo": "T0700001_CAMPO1",\n' +
		'      "required": false,\n' +
		'      "listaValoriDropDown": null,\n' +
		'      "xPathExpression": "//FatturaElettronicaBody/DatiGenerali/DatiGeneraliDocumento/Numero"\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "string"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "PREMULTICANALITA",\n' +
		'          "headerLabel": "PREMULTICANALITA",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 0\n' +
		'      },\n' +
		'      "idChiave": 12,\n' +
		'      "labelChiave": "Bollo",\n' +
		'      "nomeCampo": "T0700001_CAMPO12",\n' +
		'      "required": false,\n' +
		'      "listaValoriDropDown": null,\n' +
		'      "xPathExpression": null\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "string"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "PREMULTICANALITA",\n' +
		'          "headerLabel": "PREMULTICANALITA",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 0\n' +
		'      },\n' +
		'      "idChiave": 14,\n' +
		'      "labelChiave": "Owner",\n' +
		'      "nomeCampo": "T0700001_CAMPO14",\n' +
		'      "required": false,\n' +
		'      "listaValoriDropDown": null,\n' +
		'      "xPathExpression": null\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "string"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "PREMULTICANALITA",\n' +
		'          "headerLabel": "PREMULTICANALITA",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 0\n' +
		'      },\n' +
		'      "idChiave": 2,\n' +
		'      "labelChiave": "Licenza",\n' +
		'      "nomeCampo": "T0700001_CAMPO2",\n' +
		'      "required": false,\n' +
		'      "listaValoriDropDown": null,\n' +
		'      "xPathExpression": null\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "decimal"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "PREMULTICANALITA",\n' +
		'          "headerLabel": "PREMULTICANALITA",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 0\n' +
		'      },\n' +
		'      "idChiave": 3,\n' +
		'      "labelChiave": "Importo",\n' +
		'      "nomeCampo": "T0700001_CAMPO3",\n' +
		'      "required": true,\n' +
		'      "listaValoriDropDown": null,\n' +
		'      "xPathExpression": "//FatturaElettronicaBody/DatiGenerali/DatiGeneraliDocumento/ImportoTotaleDocumento"\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "string"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "PREMULTICANALITA",\n' +
		'          "headerLabel": "PREMULTICANALITA",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 0\n' +
		'      },\n' +
		'      "idChiave": 4,\n' +
		'      "labelChiave": "Ragione sociale",\n' +
		'      "nomeCampo": "T0700001_CAMPO4",\n' +
		'      "required": false,\n' +
		'      "listaValoriDropDown": null,\n' +
		'      "xPathExpression": null\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "date"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "PREMULTICANALITA",\n' +
		'          "headerLabel": "PREMULTICANALITA",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 0\n' +
		'      },\n' +
		'      "idChiave": 5,\n' +
		'      "labelChiave": "Data",\n' +
		'      "nomeCampo": "T0700001_CAMPO5",\n' +
		'      "required": false,\n' +
		'      "listaValoriDropDown": null,\n' +
		'      "xPathExpression": "//FatturaElettronicaBody/DatiGenerali/DatiGeneraliDocumento/Data"\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "string"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "PREMULTICANALITA",\n' +
		'          "headerLabel": "PREMULTICANALITA",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 0\n' +
		'      },\n' +
		'      "idChiave": 6,\n' +
		'      "labelChiave": "Incasso",\n' +
		'      "nomeCampo": "T0700001_CAMPO6",\n' +
		'      "required": false,\n' +
		'      "listaValoriDropDown": null,\n' +
		'      "xPathExpression": null\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "string"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "PREMULTICANALITA",\n' +
		'          "headerLabel": "PREMULTICANALITA",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 0\n' +
		'      },\n' +
		'      "idChiave": 7,\n' +
		'      "labelChiave": "CAP cliente",\n' +
		'      "nomeCampo": "T0700001_CAMPO7",\n' +
		'      "required": false,\n' +
		'      "listaValoriDropDown": null,\n' +
		'      "xPathExpression": null\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "decimal"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "PREMULTICANALITA",\n' +
		'          "headerLabel": "PREMULTICANALITA",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 0\n' +
		'      },\n' +
		'      "idChiave": 8,\n' +
		'      "labelChiave": "Imponibile",\n' +
		'      "nomeCampo": "T0700001_CAMPO8",\n' +
		'      "required": false,\n' +
		'      "listaValoriDropDown": null,\n' +
		'      "xPathExpression": null\n' +
		'    },\n' +
		'    {\n' +
		'      "metadataType": {\n' +
		'        "name": "string"\n' +
		'      },\n' +
		'      "channelReference": {\n' +
		'        "channelName": {\n' +
		'          "value": "PREMULTICANALITA",\n' +
		'          "headerLabel": "PREMULTICANALITA",\n' +
		'          "tooltipLabel": null,\n' +
		'          "position": 0,\n' +
		'          "type": null\n' +
		'        },\n' +
		'        "channelId": 0\n' +
		'      },\n' +
		'      "idChiave": 9,\n' +
		'      "labelChiave": "P. IVA cliente",\n' +
		'      "nomeCampo": "T0700001_CAMPO9",\n' +
		'      "required": false,\n' +
		'      "listaValoriDropDown": null,\n' +
		'      "xPathExpression": "//FatturaElettronicaHeader/DatiTrasmissione/PECDestinatario"\n' +
		'    }\n' +
		'  ]\n' +
		'}';

	private readonly CHANNEL = 'btn btn-primary btn-group btn-service';
	private readonly SELECTEDCHANNEL = 'btn btn-primary btn-arrow-right btn-service';
	private initialButtonState: EnabledChannels[];
	private enabledButtons = new BehaviorSubject<object[]>([{}]);
	// public loading = new BehaviorSubject<boolean>(true);
	private premultiMetadataFields$ = new BehaviorSubject<ListMetadatiGed[]>([]);
	private gedPostMetadataFields$ = new BehaviorSubject<ListMetadatiGed[]>([]);
	private gedPecMetadataFields$ = new BehaviorSubject<ListMetadatiGed[]>([]);
	private gedMailMetadataFields$ = new BehaviorSubject<ListMetadatiGed[]>([]);

	constructor(private channelService: ChannelsHttpService) {
		this.currentButtons = this.enabledButtons.asObservable();
	}

	changeButtons(channelName: string) {
		const newButtons: EnabledChannels[] = Copier.deepCopy(this.initialButtonState);
		const indexToUpdate = newButtons.map(element => element.name).indexOf(channelName);
		newButtons[indexToUpdate].clicked = true;
		newButtons[indexToUpdate].class = this.SELECTEDCHANNEL;
		this.enabledButtons.next(newButtons);
	}

	public getChannels(id: string, sia: string, grafica: string): Observable<Channels> {
		// return of(JSON.parse(this.test));
		return this.channelService.getChannelsGaw(id, sia, grafica);
	}

	public getEnabledChannels(arrayChannels: ListChannelGed[]) {
		const buttonsEnabled: EnabledChannels[] = [];
		let button: EnabledChannels = {};
		if (arrayChannels)
			for (let i = 0; i < arrayChannels.length; i++)
				if (arrayChannels[i].channelName.headerLabel.toUpperCase() in ChannelsInvoice) {
					if (arrayChannels[i].channelName.headerLabel.toLowerCase() === ChannelsInvoice.GEDFATT ||
						arrayChannels[i].channelName.headerLabel.toLowerCase() === ChannelsInvoice.GEDINVOICE)
						arrayChannels[i].channelName.headerLabel = ChannelsInvoice.GEDFATT.toUpperCase();

					button.name = arrayChannels[i].channelName.headerLabel;
					button.class = this.CHANNEL;
					button.clicked = false;
					buttonsEnabled.push(button);
					button = {};
				}

		// salvo lo stato iniziale dei bottoni
		this.initialButtonState = buttonsEnabled;
		if (buttonsEnabled.length > 0) {
			this.sortFirstGedFatt(buttonsEnabled);
			// attivo il primo canale
			this.changeButtons(buttonsEnabled[0].name);
		} else
			this.enabledButtons.next([]);

	}

	public getChannelsHub(id: string, sia: string, grafica: string, documentChannelType: string): Observable<Channels> {
		// return of(JSON.parse(this.test));
		return this.channelService.getChannelsHub(id, sia, grafica, documentChannelType);
	}

	public getEnabledChannelsHub(arrayChannels: ListChannelGed[]) {
		const buttonsEnabled: EnabledChannels[] = [];
		let button: EnabledChannels = {};
		if (arrayChannels)
			for (let i = 0; i < arrayChannels.length; i++) {
				if (arrayChannels[i].channelName.headerLabel.toLowerCase() === channels.GEDINVOICE ||
					arrayChannels[i].channelName.headerLabel.toLowerCase() === channels.GEDFATT) {
					arrayChannels[i].channelName.headerLabel = channels.GEDFATT.toUpperCase();
					button.name = arrayChannels[i].channelName.headerLabel;
					button.class = this.CHANNEL;
					button.clicked = false;
					buttonsEnabled.push(button);
				} else if (arrayChannels[i].channelName.headerLabel.toLowerCase() === channels.GEDPEC) {
					button.name = arrayChannels[i].channelName.headerLabel;
					button.class = this.CHANNEL;
					buttonsEnabled.push(button);
					button.clicked = false;
				} else if (arrayChannels[i].channelName.headerLabel.toLowerCase() === channels.GEDPOST) {
					button.name = arrayChannels[i].channelName.headerLabel;
					button.class = this.CHANNEL;
					buttonsEnabled.push(button);
					button.clicked = false;
				} else if (arrayChannels[i].channelName.headerLabel.toLowerCase() === channels.GEDMAIL) {
					button.name = arrayChannels[i].channelName.headerLabel;
					button.class = this.CHANNEL;
					buttonsEnabled.push(button);
					button.clicked = false;
				} else if (arrayChannels[i].channelName.headerLabel.toLowerCase() === channels.GEDPASS) {
					button.name = arrayChannels[i].channelName.headerLabel;
					button.class = this.CHANNEL;
					buttonsEnabled.push(button);
					button.clicked = false;
				}
				button = {};
			}

		// salvo lo stato iniziale dei bottoni
		this.initialButtonState = buttonsEnabled;
		if (buttonsEnabled.length > 0) {
			this.sortFirstGedFatt(buttonsEnabled);
			// attivo il primo canale
			this.changeButtons(buttonsEnabled[0].name);
		} else
			this.enabledButtons.next([]);

	}

	public isGivenChannelPresent(listChannels: Channels, channel: string): boolean {
		for (let i = 0; i < listChannels.listMetadatiGed.length; i++)
			if (listChannels.listMetadatiGed[i].channelReference.channelName.headerLabel.toLowerCase() === channel)
				return true;

		return false;
	}

	public isGedMailPresent(listChannels: Channels): boolean {
		// check if gedmail is present -> in rhe HTML check if it is defined
		for (let i = 0; i < listChannels.listMetadatiGed.length; i++)
			if (listChannels.listMetadatiGed[i].channelReference.channelName.headerLabel.toLowerCase() === channels.GEDMAIL)
				return true;

		return false;
	}

	public setPremultiMetadata(data: ListMetadatiGed[]) {
		this.premultiMetadataFields$.next(data);
	}

	public whenPremultiMetadata(): Observable<ListMetadatiGed[]> {
		return this.premultiMetadataFields$.asObservable();
	}

	private sortFirstGedFatt(buttonsEnabled: EnabledChannels[]) {
		// ordino i canali PRIMO gedafatt
		buttonsEnabled.sort(function (x, y) {
			return x.name.toLowerCase() === channels.GEDFATT ? -1 : y.name.toLowerCase() === channels.GEDFATT ? 1 : 0;
		});
	}
}
