import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UploadLottoZipComponent } from './upload-lotto-zip.component';

@NgModule({
	imports: [RouterModule.forChild([
		{ path: '', component: UploadLottoZipComponent }
	])],
	exports: [RouterModule]
})
export class UploadLottoZipRoutingModule {

}
