export enum Extension {
	TXT = 'txt',
	PDF = 'pdf',
	XML = 'xml',
	ZIP = 'zip',
	DOC = 'doc',
	DOCX = 'docx',
	ODT = 'odt',
	ODM = 'odm',
	OTT = 'ott',
	ODS = 'ods',
	OTS = 'ots',
	XLS = 'xls',
	XLSX = 'xlsx'
}

export class File {

	public static getExtension(filename: string): Extension {
		const re = /(?:\.([^.]+))?$/;
		const extension = re.exec(filename)[1];
		return extension ? Extension[extension.toUpperCase()] : undefined;
	}
}
