import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WorkflowService } from '@ctel/gaw-workflow';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';

/**
 * Componente che rappresenta il modal che si apre al click del tasto "Attiva workflow"
 */
@Component({
	selector: 'gaw-wf-documents-workflow-modal',
	templateUrl: 'documents-workflow-modal.component.html',
	styleUrls: ['documents-workflow-modal.component.scss']
})

export class DocumentsWorkflowModalComponent implements OnInit, OnDestroy {

	@Input() data: object;
	@Input() modal: BsModalRef;

	public creationSuccess: string;

	private destroy$ = new Subject<void>();

	constructor(
		private cdRef: ChangeDetectorRef,
		private workflowService: WorkflowService,
		private notificationService: NotificationService
	) { }

	ngOnInit() {
		this.workflowService.whenCreationSuccess()
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				creation => {
					this.creationSuccess = creation;

					if (creation === 'close')
						this.modal.hide();

					if (creation === 'error') {
						this.modal.hide();
						this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante l\'attivazione del workflow', '');
					}
					if (creation === 'success') {
						this.modal.hide();
						const selectedWorkflow = this.workflowService.getSelectedWorkflow();
						this.notificationService.showSweetAlert(
							NotificationType.SUCCESS,
							`Documento aggiunto all'attività ${selectedWorkflow.description || selectedWorkflow.name} con successo`,
							''
						);
					}
				}
			);
		this.cdRef.detectChanges();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

}
