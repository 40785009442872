import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { Spool } from 'app/core/common/entities/spool/spool-data';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { TilesMapping } from 'app/entities/ui-config/tiles-mapping';
import { Observable } from 'rxjs';
import { ClientServiceConfigurationResponse } from '../entities/cards/client-configuration';

/**
 * Servizio per la gestione degli endpoint relativi al contenuto delle cards
 */
@Injectable({
	providedIn: 'root'
})
export class CardsHttpService {

	private jsonContentType = 'application/json';
	// host
	private spoolHost: string;
	private docDetailsHost: string;
	private clientConfigurationHost: string;

	// endpoints
	private tilesMapping: string;
	private tileBlueOrOrange: string;
	private spool: string;
	private spoolCount: string;
	private clientServices: string;
	private getChannels: string;
	private e_download_spool: string;
	private e_annulla_spool: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
		private fullScreenSpinnerService: FullScreenSpinnerService,
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		/* ENDPOINTS DOCUMENTGED ------------------------------------------------------------------------------------------------ */
		this.docDetailsHost = appConfig.docDetails.http.host;
		this.tilesMapping = `${this.docDetailsHost}/v1/Document/TilesMapping`;
		this.tileBlueOrOrange = `${this.docDetailsHost}/v1/Document/GEDPASSJOIN/TileColorMapping?idLicenza={licenseId}&codSia={siaCode}`;

		/* ENDPOINTS FLUSSI ------------------------------------------------------------------------------------------------ */
		this.spoolHost = appConfig.spool.http.host;
		this.spool = `${this.spoolHost}/v1/Flusso?id={licenseId}&sia={siaCode}&tile={spoolType}&dateTo={dateTo}&dateFrom={dateFrom}&pageIndex={pageIndex}&pageSize={pageSize}&order={order}`;
		this.spoolCount = `${this.spoolHost}/v1/Flusso/Count?id={licenseId}&sia={siaCode}&tile={spoolType}&dateFrom={dateFrom}&dateTo={dateTo}`;
		this.e_download_spool = `${this.spoolHost}/v1/Flusso/Download?progSpool={progSpool}`;
		this.e_annulla_spool = `${this.spoolHost}/v1/Flusso/Cancel?progSpool={progSpool}`;

		/* ENDPOINTS CONFIGURAZIONE CLIENTE ----------------------------------------------------------------------------------- */
		this.clientConfigurationHost = appConfig.clientConfiguration.http.host;
		this.clientServices = `${this.clientConfigurationHost}/v1/ClientConfiguration/HubfeChannels?id={licenseId}&sia={siaCode}&documentChannelType={accountType}`;
		this.getChannels = `${this.clientConfigurationHost}/v1/ClientConfiguration/HubfeChannels?id={licenseId}&sia={siaCode}&grafica={grafica}&documentChannelType={accountType}`;
	}

	/* HTTP REQUESTS COLORI STATO PER CANALE ------------------------------------------------------------------------------------------- */
	whenTilesMapping(): Observable<TilesMapping[]> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.tilesMapping;
		return this.http.get<TilesMapping[]>(url, options);
	}

	/* HTTP REQUESTS COLORE ORANGE/BLUE PER TILE RICEVUTI DEL PASSIVO
	 ------------------------------------------------------------------------------------------- */
	whenTileBlueOrOrange(licenseId: string, siaCode: string): Observable<any> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.tileBlueOrOrange
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<any>(url, options);
	}

	/* HTTP REQUESTS FLUSSI --------------------------------------------------------------------------------------------------- */

	// GET lista spool in elaborazione o in errore
	whenSpools(
		licenseId: string, siaCode: string, spoolType: string, pageIndex: string,
		pageSize: string, dateFrom: string, dateTo: string, order: string
	): Observable<Spool[]> {

		let url = this.spool
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{spoolType}', spoolType)
			.replace('{pageIndex}', pageIndex)
			.replace('{pageSize}', pageSize)
			.replace('{dateFrom}', dateFrom)
			.replace('{dateTo}', dateTo);
		if (order)
			url = url.replace('{order}', order);
		else
			url = url.replace('&order={order}', '');

		return this.http.get<Spool[]>(url);

		// const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		// let url = this.spool
		// 	.replace('{licenseId}', licenseId)
		// 	.replace('{siaCode}', siaCode)
		// 	.replace('{spoolType}', spoolType)
		// 	.replace('{pageIndex}', pageIndex)
		// 	.replace('{pageSize}', pageSize)
		// 	.replace('{dateFrom}', dateFrom)
		// 	.replace('{dateTo}', dateTo);
		// if (order) {
		// 	url = url.replace('{order}', order);
		// } else {
		// 	url = url.replace('&order={order}', '');
		// }
		// return this.http.get<Spool[]>(url, options);
	}

	whenSpoolCount(licenseId: string, siaCode: string, spoolType: string, dateFrom: string, dateTo: string): Observable<number> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.spoolCount
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{spoolType}', spoolType)
			.replace('{dateFrom}', dateFrom)
			.replace('{dateTo}', dateTo);

		return this.http.get<number>(url, options);
	}

	downloadSpool(progSpool: string): Observable<Blob> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'blob');
		const url = this.e_download_spool
			.replace('{progSpool}', progSpool);

		return this.http.get<Blob>(url, options);
	}

	annullaSpool(progSpool: string): Observable<any> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'blob');
		const url = this.e_annulla_spool
			.replace('{progSpool}', progSpool);

		return this.http.post<any>(url, options);
	}

	/* HTTP REQUEST CONFIGURAZIONI CLIENTE (CANALI, ecc.) -------------------------------------------------------------- */

	whenServices(licenseId: string, siaCode: string, accountType: string): Observable<ClientServiceConfigurationResponse> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.clientServices
			.replace('{accountType}', accountType)
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<ClientServiceConfigurationResponse>(url, options);
	}

	whenChannels(licenseId: string, siaCode: string, graphic: string, accountType: string): Observable<ClientServiceConfigurationResponse> {

		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');

		const url = this.getChannels
			.replace('{accountType}', accountType)
			.replace('{licenseId}', licenseId)
			.replace('{grafica}', graphic)
			.replace('{siaCode}', siaCode);

		return this.http.get<ClientServiceConfigurationResponse>(url, options);
	}

}
