import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/it';

/**
 * Pipe che controlla se il valore è una data utc e la formatta in DD/MM/YYYY HH:mm:ss
 */
@Pipe({
	name: 'utcToLocalDate'
})
export class UtcToLocalDatePipe implements PipeTransform {

	transform(value: moment.MomentInput, format: string): string {

		if (value) {
			// Value è un valore UTC
			const date = moment.utc(value).local(false);

			if (date.isValid())
				return format ? date.format(format) : date.toISOString(false);

			return value.toString();
		}

		return '';
	}
}
