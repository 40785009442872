import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TableModule } from '../table/table.module';
import { AbstractGridComponent } from './abstract-grid.component';
import { CellRendererDirective } from './cell/cell-renderer.directive';
import { DateCellComponent } from './cell/date-cell/date-cell.component';
import { DecimalCellComponent } from './cell/decimal-cell/decimal-cell.component';
import { NumberCellComponent } from './cell/number-cell/number-cell.component';
import { TextCellComponent } from './cell/text-cell/text-cell.component';
import { CheckboxSelectAdditionRendererDirective } from './checkbox-select-addition/checkbox-select-addition-renderer.directive';
import { GridComponent } from './grid.component';
import { HeaderRendererDirective } from './header/header-renderer.directive';
import { SimpleHeaderComponent } from './header/simple-header/simple-header.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		PopoverModule,
		BsDatepickerModule,
		// TODO: Per direttiva del click, rimodulare
		TableModule,
		PipesModule,
	],
	declarations: [
		AbstractGridComponent,
		GridComponent,
		// Renderer - Cell
		TextCellComponent,
		NumberCellComponent,
		DecimalCellComponent,
		DateCellComponent,
		// Renderer - Header
		SimpleHeaderComponent,
		// Directive
		CellRendererDirective,
		HeaderRendererDirective,
		CheckboxSelectAdditionRendererDirective
	],
	exports: [
		GridComponent
	]
})
export class GridModule {
}
