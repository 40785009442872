import { Injectable } from '@angular/core';
import { MassiveActionsCatalog } from './massive-actions-catalog';
import { MultiActionsCatalog } from './multi-actions-catalog';
import { SingleActionsCatalog } from './single-actions-catalog';
import { ActionObject } from 'app/entities/ui-config/action/action-object';

@Injectable({
	providedIn: 'root'
})
export class ActionsByType {

	public readonly massiveActions: ActionObject[] = [
		this.massiveActionsCatalog.exportExcel,
		this.massiveActionsCatalog.massiveHideDocumentsAction,
		this.massiveActionsCatalog.massiveRestoreDocumentsAction,
		this.massiveActionsCatalog.moveToTrash,
		this.massiveActionsCatalog.downloadPdfZipMassive
	];

	public readonly multiActions: ActionObject[] = [
		this.multiActionsCatalog.downloadMergedPdf,
		this.multiActionsCatalog.downloadZip,
		this.multiActionsCatalog.exportExcel,
		this.multiActionsCatalog.multiHideDocumentsAction,
		this.multiActionsCatalog.multiRestoreDocumentsAction,
		// this.multiActionsCatalog.startWorkflow
		this.multiActionsCatalog.moveToTrash,
		this.multiActionsCatalog.downloadMultiXml
	];

	public readonly singleActions: ActionObject[] = [
		this.singleActionsCatalog.editKeys,
		this.singleActionsCatalog.editKeysDisabled,
		this.singleActionsCatalog.editKeysWithoutCheck,
		this.singleActionsCatalog.viewDocument,
		this.singleActionsCatalog.pdfDownload,
		this.singleActionsCatalog.xmlDownload,
		this.singleActionsCatalog.startWorkflow,
		this.singleActionsCatalog.downloadAttachmentReceivable,
		this.singleActionsCatalog.downloadAttachmentPayable,
		this.singleActionsCatalog.multiHideDocumentsAction,
		this.singleActionsCatalog.multiRestoreDocumentsAction,
		this.singleActionsCatalog.addNotes,
		this.singleActionsCatalog.downloadHistoryStates,
		this.singleActionsCatalog.associaAutofattura,
		this.singleActionsCatalog.moveToTrash
	];

	constructor(
		private massiveActionsCatalog: MassiveActionsCatalog,
		private multiActionsCatalog: MultiActionsCatalog,
		private singleActionsCatalog: SingleActionsCatalog
	) { }

}
