import { Component, ElementRef, HostListener, Input, OnDestroy, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { AuthService } from '@ctel/auth';
import { AppErrorBuilder } from 'app/core/common/error/app-error-builder';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { EventService } from 'app/core/common/event/event.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { WorkflowHttpService } from '../../../services/workflow-http.service';
import { WorkflowService } from '../../../services/workflow.service';
import { WorkflowEvent } from '../../../workflow-event';

/**
 * Componente modal per creazione nuovo item scelto dalla dropdown "Nuovo"
 */
@Component({
	selector: 'gaw-create-new-workitem-modal',
	templateUrl: './create-new-workitem-modal.component.html',
	styleUrls: ['./create-new-workitem-modal.component.scss'],
})
export class CreateNewWorkitemModalComponent implements OnDestroy {

	@Input() title: string;

	data: {
		formLink: SafeUrl
	};
	frame$: ReplaySubject<Element> = new ReplaySubject<Element>(1);
	public token$: Observable<string>;

	private destroy$ = new Subject<void>();

	constructor(
		public bsCreateWorkItemModalRef: BsModalRef,
		private workflowService: WorkflowService,
		private workflowHttpService: WorkflowHttpService,
		private authService: AuthService,
		private eventService: EventService
	) {
		this.token$ = this.authService.whenToken();
	}

	@ViewChild('createNew', { static: false }) set form(form: ElementRef) {
		if (form) {
			this.destroy$.next();
			form.nativeElement.submit();

			this.whenFrame().pipe(
				tap((frame: HTMLIFrameElement) => frame.onload = () => this.sendHostMessage(frame)),
				takeUntil(this.destroy$)
			).subscribe();
		}
	}

	@ViewChild('frame', { static: false }) set frame(frame: ElementRef) {
		if (frame)
			this.sendFrame(frame.nativeElement);
	}

	@HostListener('window:message', ['$event'])
	onMessage(event) {
		if (event.origin === this.workflowService.getBEHost())
			switch (event.data.action) {
				case 'abort':
				case 'failed':
				case 'success':
					this.closeModalAndUpdate();
					break;
				default:
					throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
						.description('Errore durante la lettura del payload da BE')
						.info('value', event.data.action)
						.build();
			}

	}

	sendHostMessage(frame) {
		const beflowUrl = this.workflowHttpService.getBeFlowHost();
		const receiver = frame.contentWindow;
		receiver.postMessage(window.location.origin, beflowUrl);
	}

	closeModalAndUpdate() {
		this.eventService.emit(WorkflowEvent.E_ACTION_COMPLETED);
		this.bsCreateWorkItemModalRef.hide();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private sendFrame(frame) {
		this.frame$.next(frame);
	}

	private whenFrame() {
		return this.frame$.asObservable();
	}
}
