import { Filter, FullTextSearch, OrderBy, Paging } from '../filter/filter-payload';

export interface FiltersResponse {
	success: boolean;
	statusCode: number;
	error?: Error;
	filters: Filter[];
	search: FullTextSearch;
	paging?: Paging;
	orderBy?: OrderBy[];
}
