<div class='modal-body'>
	<div class='row'>
		<div class='col-12'>
			<p>La richiesta di un nuovo Pacchetto Di Distribuzione <span
				*ngIf='data.pddModel.totDocRichiestiPerPDD'>({{data.pddModel.totDocRichiestiPerPDD}}  documenti)</span>
				è stata inserita correttamente. Sarà ora avviato il processo di creazione. Non appena il pacchetto sarà
				disponibile per il download sarà inviata una mail di conferma all'indirizzo:
				<strong>{{data.pddModel.indirizzoDestinatario}}</strong></p>
			<hr>
			<p><strong>Descrizione: </strong> {{this.data.pddModel.descrizione}}</p>
			<p><strong>Data richiesta: </strong> {{this.date | utcToLocalDate: "DD/MM/YYYY hh:mm:ss" }}</p>
			<p *ngIf='this.data.pddModel.flagAttestato !== null'><strong>Attestato di
				conservazione: </strong> {{this.data.pddModel.flagAttestato | SiNo}}</p>
			<p *ngIf='this.data.pddModel.flagIPDA !== null'><strong>Indice pacchetto di
				archiviazione: </strong> {{this.data.pddModel.flagIPDA | SiNo}}</p>
			<p *ngIf='this.data.pddModel.flagRDV !== null'><strong>Rapporto di
				versamento: </strong> {{this.data.pddModel.flagRDV | SiNo}}</p>
			<p *ngIf='this.data.pddModel.flagSuperimpronte !== null'><strong>Super impronta Agenzia delle
				Entrate: </strong> {{this.data.pddModel.flagSuperimpronte | SiNo}}            </p>
			<p *ngIf='this.data.pddModel.flagComunicazioniAde !== null'><strong>Comunicazioni Agenzia delle
				Entrate: </strong> {{this.data.pddModel.flagComunicazioniAde | SiNo}}</p>
			<p *ngIf='this.data.pddModel.flagRicevuteAde !== null'><strong>Ricevute Agenzia delle
				Entrate: </strong> {{this.data.pddModel.flagRicevuteAde | SiNo}}
			</p>

		</div>
	</div>
	<div class='modal-footer'>
		<button type='button' class='btn btn-success' (click)='modal.hide()'>Chiudi</button>
	</div>
</div>
