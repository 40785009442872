import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'gaw-status-history-details-modal',
	templateUrl: './status-history-details-modal.component.html',
	styleUrls: ['./status-history-details-modal.component.scss'],
	// eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
	encapsulation: ViewEncapsulation.None
})
export class StatusHistoryDetailsModalComponent {
	@Input() modal: BsModalRef;
	@Input() data: {
		innerHtml: string;
	};

}
