<div id="favorite-search" class="main-div container-fluid">
	<form [formGroup]="formGroup">
		<label class="form-label" for="favorite-search-name">Nome Ricerca</label>
		<input type="text"
					 class="form-control"
					 formControlName="favoriteSearchName"
					 name="favoriteSearchName"
					 id="favorite-search-name"
					 #inputName
					 required>
		<div class="form-errors"
				 *ngIf="favoriteSearchName.invalid && (favoriteSearchName.dirty || favoriteSearchName.touched)">
			<p *ngIf="favoriteSearchName.errors.required">Inserire un nome</p>
			<p *ngIf="favoriteSearchName.errors.maxlength">Massimo 50 caratteri</p>
		</div>
	</form>
	<div class="d-flex justify-content-end">
		<button type="submit" (click)="onSubmit()" class="btn btn-primary btn-add-contact float-end" [disabled]="!formGroup.valid">
			Salva
		</button>
	</div>
</div>
