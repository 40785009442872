import { Component, OnInit } from '@angular/core';
import { DocumentAttachmentPdf } from 'app/shared/components/display-document/document-attachment-pdf';
import { filter, switchMap, take } from 'rxjs/operators';
import { EcmService } from '../../../../ecm/ecm.service';
import { WorkflowService } from '../../../../services/workflow.service';

@Component({
	selector: 'gaw-wf-doc-preview',
	templateUrl: './document-preview.component.html',
	styleUrls: ['./document-preview.component.scss']
})
export class DocumentPreviewComponent implements OnInit {

	public documentPdf: DocumentAttachmentPdf;

	constructor(
		private readonly workflowService: WorkflowService,
		private readonly ecmService: EcmService
	) { }

	ngOnInit() {
		const getDocumentPdf$ = this.workflowService.selectedWorkItem$.pipe(
			filter(wi => wi !== undefined),
			switchMap((wi) => this.ecmService.whenDocumentPdf(wi.documentId, wi.documentSeries)),
			take(1)
		);

		this.documentPdf = new DocumentAttachmentPdf(
			'Preview',
			getDocumentPdf$
		);
	}
}
