import { Injectable } from '@angular/core';
import { DocumentDetails, TagsUpdateRequest, TagsValue } from '@ctel/gaw-commons';
import { WorkflowHttpService } from '@ctel/gaw-workflow';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { DocumentsHttpService } from '../documents-http.service';

/**
 * Servizio che gestisce lo stato dei dettagli del documento (elenco metadati)
 */
@Injectable({
	providedIn: 'root'
})
export class RichiesteScartoDocumentDetailsService {
	private sectionCode$ = new BehaviorSubject<string>('');
	private documentDetails$ = new BehaviorSubject<DocumentDetails>(undefined);

	constructor() {
	}

	getSectionCode(): string {
		return this.sectionCode$.value;
	}

	sendSectionCode(sectionCode: string) {
		this.sectionCode$.next(sectionCode);
	}

	whenCurrentDocumentDetails(): Observable<DocumentDetails> {
		return this.documentDetails$.asObservable();
	}

	sendRealDocSeriesId(s: string) {

	}
}
