class FolderRequest {
	parentFolderId: number;
	name: string;
	description: string;
	//metadataAssociation: string;
	idSerieDoc: number;
	idSerieDocFascicolo: number;
	licenseId: string;
	siaCode: string;
}

export class InsertFolder extends FolderRequest {
}

export class UpdateFolder extends FolderRequest {
	folderId: number;
}

export class DeleteFolder {
	folderId: number;
	idSerieDocFascicolo: number;
	licenseId: string;
	siaCode: string;
}

export class InsertDocumentInFolder {
	documents: {
		progSpool: number,
		progBusta: number,
		idSerieDoc: number
	}[];
	folderId: number;
	expiryDate: Date;
	licenseId: string;
	siaCode: string;
}

export class UpdateDocumentInFolder {
	folderDocumentId: number;
	progSpool: number;
	progBusta: number;
	folderId: number;
	expiryDate: string;
	idSerieDoc: number;
	licenseId: string;
	siaCode: string;
}

// Single Action
export class DeleteDocumentInFolder {
	folderDocumentId: number;
	licenseId: string;
	siaCode: string;
}

// Multiple Action
export class RemoveMultipleDocuments {
	folderDocumentId: number[];
	licenseId: string;
	siaCode: string;
}

// Multiple Action
export class DeleteMultipleFolder {
	folderId_IdSerieDocFascicolo: object;
	licenseId: string;
	siaCode: string;
}

export class SearchInFolderContext {
	idSerieDocFascicolo: number[];
	searchDataInfos: any;
	idFascicolo?: number;
	textToSearch: string;
	behavior: BehaviorDetails;
	paging: PagingDetails;
	licenseId: string;
	siaCode: string;
}

export class BehaviorDetails {
	onlyFolders: boolean;
	maxItemsNumber: number;
}

export class PagingDetails {
	startIndex: number;
	//itemsNumber: number;
}

export class FolderHasDocuments {
	idSerieDoc: [];
	folderId: number;
	licenseId: string;
	siaCode: string;
}

export class ExportExcel {
	items: ExportExcelItem[];
	licenseId: string;
	siaCode: string;
}

export class ExportExcelItem {
	type: number;
	id: number;
	progSpool: number;
	progBusta: number;
	idSerieDoc: number;
}

export class ExportZip {
	items: ExportZipItem[];
	idSerieDocs: number[];
	licenseId: string;
	siaCode: string;
}

export class ExportZipItem {
	idFascicolo: number;
	idSerieDocFascicolo: number;
}
