import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, from, Observable, ReplaySubject } from 'rxjs';
import { filter, find, map, mergeMap, share, switchMap, tap } from 'rxjs/operators';
import { EcmService } from '../../../../ecm/ecm.service';
import { Attachment, EcmDocumentHierarchy } from '../../../../entities/ecm/ecm';
import { WorkflowService } from '../../../../services/workflow.service';

@Injectable({
	providedIn: 'root'
})
export class UserAttachmentService {

	/** Lista degli allegati al documento di un workitem */
	public readonly attachmentList$: Observable<Attachment[]>;

	private readonly _attachmentListLoading$ = new BehaviorSubject<boolean>(false);
	private readonly _refresh$ = new BehaviorSubject<number>(0);

	constructor(
		private readonly workflowService: WorkflowService,
		private readonly ecmService: EcmService,
	) {
		this.attachmentList$ = combineLatest([this.workflowService.selectedWorkItem$, this._refresh$]).pipe(
			filter(([wi]) => wi !== undefined),
			tap(() => this._attachmentListLoading$.next(true)),
			switchMap(([wi]) => this.ecmService.whenDocumentHierarchy(wi.documentId)),
			map((hierarchy: EcmDocumentHierarchy) => hierarchy.attachments),
			// Se il valore è undefined lo inizializzo ad array vuoto altrimenti nel componente passa per il catchError
			map(value => !value ? [] : value),
			tap(() => this._attachmentListLoading$.next(false)),
			share({ connector: () => new ReplaySubject<Attachment[]>(1) })
		);
	}

	/**
	 * Observable che notifica quando la lista degli allegati al documento di un workitem sta caricando
	 * @return Observable<boolean>
	 */
	get attachmentListLoading$(): Observable<boolean> {
		return this._attachmentListLoading$.asObservable();
	}

	/**
	 * Forza il refresh della lista
	 */
	refreshItems() {
		this._refresh$.next(this._refresh$.getValue() + 1);
	}

	/**
	 * Elimina un allegato utente (ECM) dato l'id
	 * @param attachmentId
	 */
	deleteAttachment(attachmentId: string): Observable<void> {
		return this.ecmService.whenDeleteAttachment(attachmentId);
	}

	downloadAttachment(attachment: Attachment): Observable<Blob | string> {
		return this.ecmService.whenAttachmentFile(attachment.attachmentId, attachment.values.fileName, attachment.values.mimeType);
	}

	/**
	 * Ottiene il dettaglio dell'attachment dato l'id
	 * @param attachmentId
	 * @return Observable<Attachment>
	 */
	getAttachmentById(attachmentId: string): Observable<Attachment> {
		return this.attachmentList$.pipe(
			mergeMap(array => from(array).pipe(
				find(a => a.attachmentId === attachmentId)
			)),
			filter(att => att !== undefined)
		);
	}

	/**
	 * Lista degli allegati al documento utente dato elasticId
	 * @param idDocument
	 * @return Observable<Attachment[]>
	 */
	getHierarchyById(idDocument: string): Observable<Attachment[]> {
		return combineLatest([this.ecmService.whenDocumentHierarchy(idDocument), this._refresh$])
			.pipe(
				tap(() => this._attachmentListLoading$.next(true)),
				switchMap(([wi]) => this.ecmService.whenDocumentHierarchy(wi.documentId)),
				map((hierarchy: EcmDocumentHierarchy) => hierarchy.attachments),
				// Se il valore è undefined lo inizializzo ad array vuoto altrimenti nel componente passa per il catchError
				map(value => !value ? [] : value),
				tap(() => this._attachmentListLoading$.next(false)),
				share({ connector: () => new ReplaySubject<Attachment[]>(1) })
			);
	}

	/**
	 * Ottiene il dettaglio dell'attachment dato l'id e dato elasticId
	 * @param attachmentId
	 * @param idDocument
	 * @return Observable<Attachment>
	 */
	whenGetAttachmentId(attachmentId: string, idDocument: string): Observable<Attachment> {
		return this.getHierarchyById(idDocument).pipe(
			mergeMap(array => from(array).pipe(
				find(a => a.attachmentId === attachmentId)
			)),
			filter(att => att !== undefined)
		);
	}

	retrieveAttachmentById(idAttachment: string): Observable<Attachment> {
		return this.getHierarchyById(idAttachment).pipe(
			mergeMap(array => from(array).pipe(
				find(a => a.attachmentId === idAttachment)
			)),
			filter(att => att !== undefined)
		);

	}
}
