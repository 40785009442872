import { Injectable } from '@angular/core';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { EnabledServicesService } from 'app/core/business/user/enabled-services/enabled-services.service';
import { RelatedDocSeries } from 'app/entities/sections/related-doc-series';
import { SingleSection } from 'app/entities/sections/sections';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { DocumentsHttpService } from '../documents-http.service';

/**
 * Servizio che gestisce lo stato delle serie documentali
 * (tutto l'array di serie documentali, l'id della serie documantale "All",
 * le serie documentali preferite, le serie documentali "Altri"
 * e la serie documentale corrente).
 * La serie documentale "All" viene utilizzata come serie di default
 * ed è sempre la prima dell'array di SingleSection "visible"
 */
@Injectable({
	providedIn: 'root'
})
export class DocumentsSeriesService {

	// Array di tutte le serie documentali
	private documentSeriesList$ = new ReplaySubject<SingleSection[]>(1);
	private visibleDocumentSeriesList$ = new ReplaySubject<SingleSection[]>(1);
	private otherDocumentSeriesList$ = new ReplaySubject<SingleSection[]>(1);

	// Serie documentale attuale
	private currentDocumentSeries$ = new ReplaySubject<SingleSection>(1);
	// serie documentale "All"
	private allDocSeries$ = new BehaviorSubject<SingleSection>(null);
	private selectedDocSeries$ = new BehaviorSubject<string>('');
	private docSeriesLabel$ = new BehaviorSubject<string>('');
	private otherDocSeriesLabel$ = new BehaviorSubject<string>('Altri documenti');

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private documentsHttpService: DocumentsHttpService,
		private companiesService: CompaniesService,
		private enabledServicesService: EnabledServicesService
	) {
		// prendo tutti i tipi documento (visible e other) e aggiorno i relativi observables
		// il primo id dell'array visible corrisponde alla serie documentale "All"
		// this.documentsHttpService.whenSections('T0700001', '43390').subscribe();
		const whenServiceEnabled$ = this.enabledServicesService.whenEnabledServices().pipe(
			filter(services => services.includes('GAW30'))
		);

		combineLatest([this.companiesService.whenCurrentCompany(), whenServiceEnabled$]).pipe(
			switchMap(([company]) => this.documentsHttpService.whenSections(company.licenseId, company.siaCode)),
			takeUntil(this.destroy$)
		).subscribe(docTypes => {
			const allSectionIndex = docTypes.sections.findIndex(x => x.sectionCode === 'ALL');
			// Sposto la sezione "ALL" come primo elemento dell'array
			if (allSectionIndex >= 0) {
				docTypes.sections.unshift(docTypes.sections[allSectionIndex]);
				docTypes.sections.splice((allSectionIndex + 1), 1);
			}
			this.sendAllDocSeries(docTypes[0]);
			this.sendDocumentsSeries(docTypes.sections);
			const visibleSections: SingleSection[] = [];
			const otherSections: SingleSection[] = [];
			docTypes.sections.forEach(section => {
				if (section.visible)
					visibleSections.push(section);
				else
					otherSections.push(section);

			});
			this.sendVisibleDocumentsSeries(visibleSections);
			this.sendOtherDocumentsSeries(otherSections);
		});
	}

	// TUTTE LE SERIE DOC
	sendDocumentsSeries(docSeries: SingleSection[]) {
		this.documentSeriesList$.next(docSeries);
	}

	whenDocumentsSeries(): Observable<SingleSection[]> {
		return this.documentSeriesList$.asObservable();
	}

	// TUTTE LE SERIE DOC PREFERITE
	sendVisibleDocumentsSeries(docSeries: SingleSection[]) {
		this.visibleDocumentSeriesList$.next(docSeries);
	}

	whenVisibleDocumentsSeries(): Observable<SingleSection[]> {
		return this.visibleDocumentSeriesList$.asObservable();
	}

	// TUTTE LE SERIE DOC "ALTRI"
	sendOtherDocumentsSeries(docSeries: SingleSection[]) {
		this.otherDocumentSeriesList$.next(docSeries);
	}

	whenOtherDocumentsSeries(): Observable<SingleSection[]> {
		return this.otherDocumentSeriesList$.asObservable();
	}

	// SERIE DOC ATTUALE
	sendCurrentDocumentsSeries(docSeries: SingleSection) {
		this.currentDocumentSeries$.next(docSeries);
	}

	whenCurrentDocumentsSeries(): Observable<SingleSection> {
		return this.currentDocumentSeries$.asObservable();
	}

	updateCurrentDocumentsSeries(docSeriesId: string) {
		// Aggiorno la current docSeries prendendo nell'elenco di docSeries disponibili la prima con l'id uguale a quello in ingresso
		this.whenDocumentsSeries().pipe(take(1)).subscribe(docSeriesList => {
			const documentSeries = docSeriesList.find(docSeries => docSeries.sectionCode === docSeriesId);
			this.sendCurrentDocumentsSeries(documentSeries);
		});

		// capisco se la serie attuale è visible o other (serve per avere la label in caso di F5 sulla pagina documenti)
		this.whenVisibleDocumentsSeries().pipe(take(1)).subscribe(docSeriesList => {
			const visibleOrOther = docSeriesList.find(docSeries => docSeries.sectionCode === docSeriesId);
			if (visibleOrOther !== undefined && visibleOrOther.description)
				this.sendVisibleDocSeriesLabel(visibleOrOther.description);

		});
	}

	whenAllDocSeriesId(): Observable<string> {
		return this.whenDocumentsSeries().pipe(
			map(documentSeries => documentSeries[0]?.sectionCode)
		);
	}

	sendAllDocSeries(docSeries: SingleSection): void {
		this.allDocSeries$.next(docSeries);
	}

	whenSelectedDocSeries(): Observable<string> {
		return this.selectedDocSeries$.asObservable();
	}

	sendVisibleDocSeriesLabel(label: string): void {
		this.docSeriesLabel$.next(label);
		this.selectedDocSeries$.next(label);
	}

	resetSelectedDocSeries(): void {
		this.selectedDocSeries$.next('');
	}

	getDocSeriesLabel() {
		return this.docSeriesLabel$.value;
	}

	whenOtherDocSeriesLabel(): Observable<string> {
		return this.otherDocSeriesLabel$.asObservable();
	}

	sendOtherDocSeriesLabel(label: string): void {
		this.docSeriesLabel$.next(label);
		this.otherDocSeriesLabel$.next(label);
	}

	resetOtherDocSeriesLabel(): void {
		this.otherDocSeriesLabel$.next('Altri documenti');
	}

	getRelatedDocSeriesId(sectionCode: string, uiCode: string, licenseId: string, siaCode: string): Observable<RelatedDocSeries[]> {
		return this.documentsHttpService.getRelatedDocSeriesId(sectionCode, uiCode, licenseId, siaCode);
	}
}
