import { Injectable } from '@angular/core';
import { AllDocumentsCallback } from './callbacks/all-documents-callback';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';

@Injectable({
	providedIn: 'root',
})
export class MassiveActionsCatalog {

	public readonly exportExcel = new ActionBuilder(MassiveActionCode.exportExcel, ActionCode.DOWNLOAD_EXCEL)
		.setActionURL('/api/v1/Action/ExportExcel')
		.setCallback((action: ActionObject, extraParams?: any) => this.allDocumentsCallback.exportXLSX(action, extraParams))
		.build();
	constructor(private allDocumentsCallback: AllDocumentsCallback) {}

}

export enum MassiveActionCode {
	exportExcel = 'exportExcelMassive',
}
