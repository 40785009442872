import { animate, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, User, UserService } from '@ctel/auth';
import { TransferService } from '@ctel/transfer-manager';
import { NavigationService } from 'app/core/business/navigation/navigation.service';
import { NewsAndNotificationsService } from 'app/core/business/news-and-notifications/news-and-notifications.service';
import { AlertNewsModalComponent } from 'app/core/common/modals/alert-news-modal/alert-news-modal.component';
import { NotificationModalComponent } from 'app/core/common/modals/notification-modal/notification-modal.component';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { News, Notification } from 'app/entities/news-and-notifications/news-and-notifications';
import { NewsNotification } from 'app/shared/components/header/news-notification';
import { Observable, ReplaySubject, Subject, combineLatest, from, of } from 'rxjs';
import { concatMap, delay, filter, map, retry, share, switchMap, take, takeUntil } from 'rxjs/operators';
import { UploadsNotificationService } from '../../../core/business/uploads-notification/uploads-notification.service';
import { EnabledServicesService } from '../../../core/business/user/enabled-services/enabled-services.service';
import { ConfigService } from '../../../core/common/config/config.service';
import { UploadResults } from '../../../entities/uploads-notification/uploads-notification';

/**
 * Componente che rappresenta il container dell'intera pagina
 */
@Component({
  selector: 'gaw-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.5s ease-in', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('0.5s ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('inner', { static: false }) inner: ElementRef;
  public showSidebar = false;
  public sidebarOpen$: Observable<boolean>;
  user: User;
  route = '';
  public hasActiveUploads$: Observable<boolean>;
  public uploadResults$: Observable<UploadResults[]>;
  public news$: Observable<News[]>;
  public notifications$: Observable<Notification[]>;
  public newsAndNotifications$: Observable<NewsNotification[]>;
  public unreadNotificationsCount$: Observable<number>;
  public unreadDownloads$: Observable<boolean>;
  public _opened = false;
  downloadHighlighted$: Observable<boolean>;
  notificationCountHighlighted$: Observable<boolean>;
  public faIcons = FaIcons;
  showUploadIcon$: Observable<boolean>;
  public changePasswordUrl$: Observable<string>;

  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private newsAndNotificationsService: NewsAndNotificationsService,
    public notificationService: NotificationService,
    private transferService: TransferService,
    private uploadsNotificationService: UploadsNotificationService,
    private userService: UserService,
    private enabledService: EnabledServicesService,
    private router: Router,
    private locationUrl: Location,
    private configService: ConfigService,
  ) {
    this.userService
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => (this.user = user));

    router.events.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (locationUrl.path() !== '') {
        this.route = locationUrl.path();
      } else {
        this.route = '/home';
      }
    });

    // Gestione delle notizie importanti: si guarda nel localStorage quali sono già state inserite e vengono mostrate solo quelle
    // non presenti. Dopodiché viene aggiornato il localStorage con tutte le notizie importanti mostrate finora.
    this.newsAndNotificationsService
      .whenNews()
      .pipe(take(1))
      .subscribe((list) => {
        const alreadyShownNews: News[] = JSON.parse(localStorage.getItem('alreadyShownNews'));
        list
          .filter(
            (news) =>
              (news.urgent && !news.read) || (news.alwaysShow && !alreadyShownNews?.find((n) => n.id === news.id)),
          )
          .forEach((news) => {
            this.notificationService.showModal(NotificationType.GENERAL, {
              title: news.title,
              childComponent: AlertNewsModalComponent,
              childData: {
                news,
              },
            });
          });
        localStorage.setItem('alreadyShownNews', JSON.stringify(list.filter((news) => news.alwaysShow)));
      });

    // NEWS
    this.news$ = this.newsAndNotificationsService.whenNews();
    this.newsAndNotifications$ = combineLatest([
      this.newsAndNotificationsService.whenNotifications(),
      this.newsAndNotificationsService.whenNews(),
    ]).pipe(
      map(([notifications, news]) => {
        const notificationsList = notifications.map(
          (n) =>
            ({
              type: 'notification',
              startDate: n.startDate,
              endDate: n.endDate,
              message: n.message,
              id: n.id,
              read: n.read,
              readDate: n.readDate,
            } as NewsNotification),
        );

        const newsList = news.map(
          (n) =>
            ({
              type: 'news',
              startDate: n.startDate,
              endDate: n.endDate,
              message: n.message,
              id: n.id,
              urgent: n.urgent,
              title: n.title,
              read: n.read,
              readDate: n.readDate,
            } as NewsNotification),
        );

        return [...notificationsList, ...newsList].sort((a, b) => (a.startDate > b.startDate ? -1 : 1));
      }),
      share({ connector: () => new ReplaySubject<NewsNotification[]>(1) }),
    );

    const timedHighlight$ = from([false, true]).pipe(
      // Tolgo e aggiungo una classe css: imposto 10 ms per finire nella finestra di render successiva.
      concatMap((v) => of(v).pipe(delay(10))),
    );

    // NOTIFICHE
    this.notifications$ = this.newsAndNotificationsService.whenNotifications();
    this.unreadNotificationsCount$ = this.newsAndNotifications$.pipe(
      map((newsNotifications) => {
        let count = 0;
        for (const n of newsNotifications) {
          if (n.read === false) {
            count++;
          }
        }

        return count;
      }),
    );

    this.notificationCountHighlighted$ = this.unreadNotificationsCount$.pipe(
      filter((count) => count > 0),
      switchMap(() => timedHighlight$),
    );

    // TRASFERIMENTI
    this.unreadDownloads$ = this.transferService.whenUnreadTransfers();
    this.downloadHighlighted$ = this.unreadDownloads$.pipe(
      filter((v) => !!v),
      switchMap(() => timedHighlight$),
    );

    // Uploads
    this.uploadResults$ = this.uploadsNotificationService.getScheduledUploadNotifications$.pipe(retry(3));
    this.hasActiveUploads$ = this.uploadResults$.pipe(map((uploads) => uploads.some((u) => !u.complete && !u.error)));

    this.showUploadIcon$ = this.enabledService.isEnabledService('GAW30').pipe(map((value) => !!value));
  }

  public ngOnInit(): void {
    this.showSidebar = true;
    this.navigationService
      .whenSidebar()
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => (this._opened = val));
    const returnUrl = window.location.href;
    this.changePasswordUrl$ = this.configService.whenDryConfig().pipe(
      take(1),
      map((config) => config.auth.oidc.config.authority),
      map((stsServerBaseUrl) => `${stsServerBaseUrl}/account/cambiopassword?returnUrl=${returnUrl}`),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setNewsNotificationRead(newsNotification: NewsNotification) {
    // faccio il post e la get del nuovo valore solo se la notifica non è già stata letta
    if (newsNotification.read === false) {
      if (newsNotification.type === 'notification') {
        this.newsAndNotificationsService.setNotificationRead(newsNotification.id);
      } else {
        this.newsAndNotificationsService.setNewsRead(newsNotification.id).pipe(take(1)).subscribe();
      }
    }

    const title = newsNotification.type === 'notification' ? 'Notifica' : newsNotification.title;

    this.notificationService.showModal(NotificationType.GENERAL, {
      title,
      customFooter: true,
      childComponent: NotificationModalComponent,
      childData: { message: newsNotification.message },
    });
  }

  public _toggleSidebar() {
    this._opened = !this._opened;
    this.showSidebar = true;
    this.navigationService.sendSidebar(true);
  }

  logout() {
    this.authService.logout();
  }
}
