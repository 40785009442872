import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ChannelsHttpService } from './channels-http.service';
import { ChannelsService } from './channels.service';

@NgModule({
	imports: [
		CommonModule,
	],
	providers: [],
	exports: []
})
export class ChannelsModule {

	static forRoot(): ModuleWithProviders<ChannelsModule> {
		return {
			ngModule: ChannelsModule,
			providers: [
				ChannelsService,
				ChannelsHttpService,
			]

		};
	}
}
