<table class='table' *ngIf='statusHistory$ | async as statusHistory'>
	<tr>
		<th [style.width]='"16.66%"'>
			Stato
		</th>
		<th [style.width]='"16.66%"'>
			Data/ora
		</th>
		<th [style.width]='"16.66%"'>
			Check point
		</th>
		<th [style.width]='"16.66%"'>
			Utente
		</th>
		<th [style.width]='"16.66%"'>
			Descrizione scatola
		</th>
		<th [style.width]='"16.66%"'>
			Note
		</th>
	</tr>
	<tr *ngFor='let history of statusHistory'>
		<td>
			<span>{{ history.stato }}</span>
		</td>
		<td>
			<span>{{ history.dataStato | date:'d/M/yy, H:mm' }}</span>
		</td>
		<!-- <td>
			<span>{{ history.checkpoint }}</span>
		</td> -->
		<td>
			<span>{{ history.utente }}</span>
		</td>
		<!-- <td>
			<span>{{ history.statoScatolaDescrizione }}</span>
		</td> -->
		<!-- <td>
			<span>{{ history.note }}</span>
		</td> -->
	</tr>
</table>
