import { PlatformLocation } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { ChangeStatusEnum } from '../../../../entities/ui-config/action/ChangeStatusEnum';
import { ActionCode } from '../../../../entities/ui-config/action/action-code.enum';

@Component({
	templateUrl: './check-pass-modal.component.html',
	styleUrls: ['./check-pass-modal.component.scss']
})
export class CheckPassModalComponent {

	@Input() data: {
		action: any;
		actionParams?: ActionCode;
		title: string;
	};
	@Input() modal: BsModalRef;
	notes: string;

	constructor(
		private location: PlatformLocation
	) {
		location.onPopState(() => this.modal.hide());
	}

	executeAction() {
		let nextStatus: ChangeStatusEnum;
		switch (this.data.actionParams) {
			case ActionCode.CHECK_AS_FORWARDED:
				nextStatus = ChangeStatusEnum.Passivo_StatoSpedizioneScatola;
				break;
			case ActionCode.CHECK_AS_RECEIVED:
				nextStatus = ChangeStatusEnum.Passivo_StatoRicezioneScatola;
				break;
			case ActionCode.CHECK_AS_DELETED:
				nextStatus = ChangeStatusEnum.Passivo_StatoEliminazioneScatola;
				break;
			case ActionCode.CHECK_AS_REJECTED:
				nextStatus = ChangeStatusEnum.Passivo_StatoRifiutoScatola;
				break;
			case ActionCode.CANCEL_STATE_SELECTION:
				nextStatus = ChangeStatusEnum.Passivo_StatoAnnulla;
				break;
		}

		if (typeof this.data.action === 'function')
			this.data.action(nextStatus, this.notes).pipe(
				take(1)
			).subscribe();
		else
			this.data.action.pipe(
				take(1)
			).subscribe();

		this.modal.hide();
	}
}
