<div class="action-toolbar">
  <div class="actions-list">
    <!-- Lista delle azioni disponibili per il workflow-->
    <ng-container *ngFor="let actionStatus of actions$ | async">
      <!-- Visualizzo l'azione solo se la callback `hidden` ritorna false -->
      <div class="action-container" *ngIf="(actionStatus.action.hidden() | async) === false">
        <button
          class="btn-ghost p-0"
          *hubLoading="actionStatus.status === ACTION_STATUS.LOADING; component: loadingTemplate"
          [attr.aria-label]="'Esegui azione: ' + actionStatus.action.label"
          [matTooltip]="actionStatus.action.label"
          (click)="executeAction(actionStatus)"
        >
          <i [class]="actionStatus.action.icon" aria-hidden="true"></i>
        </button>
      </div>
    </ng-container>
  </div>
</div>
