<div class="preview h-100 pb-3">
	<tabset [justified]='true'>
		<tab class='tabs'>
			<ng-template tabHeading>
				<div class='tab-header'>
					<b>Dettaglio Lotto</b>
				</div>
			</ng-template>
			<gaw-gawlotti-lotto-detail [data]='data'></gaw-gawlotti-lotto-detail>
		</tab>
		<tab class='tabs'>
			<ng-template tabHeading>
				<div class='tab-header document-detail-body'>
					<b>Storico Stati</b>
				</div>
			</ng-template>
			<gaw-gawlotti-status-history class='document-detail-body'
																	 [data]='data'></gaw-gawlotti-status-history>
		</tab>
	</tabset>
</div>
