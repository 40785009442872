import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';

@Injectable({
	providedIn: 'root'
})
export class MultiActionsCatalog {

	public readonly exportExcelMultiple = new ActionBuilder(MultiActionCode.exportExcelMultiple, ActionCode.DOWNLOAD_EXCEL)
		.setActionURL('/v2/documents/export/excel?clientId=GAWPASS')
		.build();

	public readonly cancelDocumentMultiple = new ActionBuilder(MultiActionCode.cancelDocumentMultiple, ActionCode.CANCEL_DOCUMENT)
		.setActionURL('/api/v1/Anomalie/AnnullaDocumenti')
		.build();

	public readonly processDocumentMultiple = new ActionBuilder(MultiActionCode.processDocumentMultiple, ActionCode.PROCESS_DOCUMENT)
		.setActionURL('/api/v1/Anomalie/ElaboraDocumenti')
		.build();

}

export enum MultiActionCode {
	exportExcelMultiple = 'exportExcelMultiple',
	cancelDocumentMultiple = 'cancelDocumentMultiple',
	processDocumentMultiple = 'processDocumentMultiple',
}
