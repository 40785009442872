import { Component, Input } from '@angular/core';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';

/**
 * Componente modal che conferma il successo di creazione item diretta
 */
@Component({
	selector: 'gaw-workitem-creation-feedback-modal',
	templateUrl: 'workitem-creation-feedback-modal.component.html',
	styleUrls: ['workitem-creation-feedback-modal.component.scss']
})

export class WorkitemCreationFeedbackModalComponent {
	@Input() text: string;
	@Input() title: string;
	public faIcons = FaIcons;

}
