import { PlatformLocation } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ActionsHttpService, ActionsService, DocumentDetailsService, DocumentsService } from '@ctel/hubfe-commons';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { ProtocolFields, ReverseChargeData } from 'app/core/common/modals/action-protocol-modal/reverse-charge-data';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import * as moment from 'moment';
import 'moment/locale/it';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMPTY, Subject, of } from 'rxjs';
import { catchError, delay, switchMap, take } from 'rxjs/operators';
// import { NotificationType } from '../../../dry/notification';
// import { NotificationService } from '../../../dry/notification/notification.service';

/**
 * Componente modal con data entry dati protocollo
 */
@Component({
	selector: 'hub-action-protocol-modal',
	templateUrl: './action-protocol-modal.component.html',
	styleUrls: ['./action-protocol-modal.component.scss']
})
export class ActionProtocolModalComponent implements OnDestroy {

	@Input() title: string;
	@Input() data: {
		refreshDocuments?: boolean,
		basicPayload?: any
	};
	@Input() modal: BsModalRef;
	@ViewChildren('emptyInput') emptyInputList: QueryList<ElementRef>;
	@ViewChild('fileUpload') fileInputElem: ElementRef;
	isGreen = false;

	mode: number;
	public faIcons = FaIcons;
	isErrorDate = false;
	isErrorNumber = false;
	isErrorRow = false;
	isResettable = false;
	inputFilename = 'Nessun PDF caricato';
	isFileFormatError = false;
	base64string = '';
	metadata = [];

	apiData: ProtocolFields[] = [
		{ idTipoCbi: 40, label: 'Numero protocollo' },
		{ idTipoCbi: 41, label: 'Data protocollo' },
		{ idTipoCbi: 39, label: 'Imponibile' },
		{ idTipoCbi: 51, label: 'Aliquota' },
		{ idTipoCbi: 52, label: 'Divisa' },
		{ idTipoCbi: 53, label: 'Imposta' }
	];

	reverseChargeColumns: ProtocolFields[] = [];

	// array di rows (array di array di colonne)
	insertedTableData: ReverseChargeData[][] = [];
	isSubmitLocked = false;

	private destroy$ = new Subject<void>();

	constructor(
		private actionsHttpService: ActionsHttpService,
		private notificationService: NotificationService,
		private companiesService: CompaniesService,
		private documentsService: DocumentsService,
		private documentDetailsService: DocumentDetailsService,
		private actionsService: ActionsService,
		private router: Router,
		private location: PlatformLocation
	) {
		location.onPopState(() => this.modal.hide());

		this.actionsService.whenProtocolMode()
			.pipe(
				take(1),
				switchMap(mode => {
					this.mode = mode;
					// usare per cablare valore configurazione insert protocol: this.mode = 3;
					return this.actionsService.whenProtocolFields()
						.pipe(take(1));
				})
			).subscribe(fields => {
				this.apiData = fields;

				if (this.mode === 4 || this.mode === 2) {
					// estraggo solo i 4 tipi di metadati che vanno in tabella
					for (let i = 0; i < this.apiData.length; i++) {
						const idTipoCbi = this.apiData[i].idTipoCbi;
						if (idTipoCbi !== 40 && idTipoCbi !== 41 && idTipoCbi !== 57)
							this.reverseChargeColumns.push(this.apiData[i]);

					}
					// se ce ne sono, aggiungo una prima riga vuota alla tabella
					if (this.reverseChargeColumns.length > 0)
						this.pushNewEmptyRow();
				}
			});
	}

	public onFileChange(event: any) {
		this.isFileFormatError = false;
		this.isResettable = true;
		if (event.target.files && event.target.files.length > 0) {
			const files = event.target.files;
			const file = files[0];
			this.inputFilename = file.name;
			if (!file.name.endsWith('.pdf') && !file.name.endsWith('.PDF')) {
				this.isFileFormatError = true;
				return;
			}

			if (files && file) {
				const reader = new FileReader();
				reader.onload = this.handleFile.bind(this);
				reader.readAsBinaryString(file);
			}
			// 	const file = event.target.files[0] as Blob;
			// 	// controllo size 10 MB
			// 	if (file.size < 10) {
			// 		const reader = new FileReader();
			// 		reader.readAsDataURL(file);
			// 		reader.onloadend = function () {
			// 			const base64data = reader.result;
			// 		};
			// 	}
		}
	}

	// celle input della tabella metadati
	inputChange() {
		const inputElements = document.getElementsByClassName('empty-input');
		let count = 0;
		for (let i = 0; i < inputElements.length; i++) {
			const elem = <HTMLInputElement>inputElements[i];
			if (elem.value === '')
				count++;

		}
		// input tutti vuoti, disabilito il tasto check
		this.isGreen = count !== inputElements.length;
	}

	unlockSubmitting() {
		this.isSubmitLocked = false;
	}

	onFormSubmit(form?: NgForm) {
		//onFormSubmit viene eseguito N volte consecutivamente se causato da pressione continua del tasto INVIO
		//bloccarlo è necessario per farlo eseguire solo una volta, sbloccarlo in ogni caso alla fine della chiamata
		if (this.isSubmitLocked || this.isFileFormatError)
			return;

		this.isSubmitLocked = true;
		this.isErrorRow = false;

		let protocolDate = '';
		let protocolNumber = '';
		// solo per i mode che includono i dati protocollo
		if (this.data.basicPayload.mode < 3) {
			protocolDate = form.form.value.protocolDate;
			const isValid = moment(protocolDate, 'DD/MM/YYYY', true).isValid();

			protocolNumber = form.form.value.protocolNumber;

			if (!isValid) {
				this.isErrorDate = true;
				this.isErrorNumber = protocolNumber === '';
				this.unlockSubmitting();
				return;
			}
			if (protocolNumber === '') {
				this.isErrorNumber = true;
				this.isErrorDate = !isValid;
				this.unlockSubmitting();
				return;
			}
			const dateParts = protocolDate.split('/');
			protocolDate = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0] + ' 00:00:00';
			this.isErrorDate = false;
			this.isErrorNumber = false;
		}

		// mode con i metadati
		if (this.data.basicPayload.mode === 2 || this.data.basicPayload.mode === 4) {
			// se l'ultima riga della tabella contiene almeno un valore ma non è stata confermata con la spunta, non procedo e mostro
			// messaggio di errore
			const dirtyInput = this.emptyInputList.find(elem => elem.nativeElement.value !== undefined && elem.nativeElement.value !== '');
			if (dirtyInput) {
				this.isErrorRow = true;
				this.unlockSubmitting();
				return;
			}

			const metadati = [];
			// per ogni riga
			for (let i = 0; i < this.insertedTableData.length; i++) {
				const row = [];
				let valueCount = 0;
				// per ogni cella
				for (let y = 0; y < this.insertedTableData[i].length; y++) {
					const cell = {
						name: this.insertedTableData[i][y].label,
						value: this.insertedTableData[i][y].value
					};
					// tengo il conto dei value vuoti
					if (cell.value === '')
						valueCount++;

					row.push(cell);
				}
				// se la riga che provo a inserire è vuota, non la inserisco nell'array finale per il payload
				if (valueCount !== this.insertedTableData[i].length)
					metadati.push(row);

			}
			this.metadata = metadati;
		}

		switch (this.data.basicPayload.mode) {
			case 0: // PROTOCOLLO SEMPLICE
				this.actionsHttpService.whenInsertProtocolData(
					this.data.basicPayload.actionUrl, this.data.basicPayload.progSpool, this.data.basicPayload.progBusta,
					this.data.basicPayload.docHash, protocolNumber, protocolDate, this.data.basicPayload.ctelDocSeriesId,
					this.data.basicPayload.ctelElasticDocumentId, {}
				).pipe(
					take(1),
					catchError((err: unknown) => {
						this.unlockSubmitting();
						return this.protocolError(err);
					}),
					delay(1000)
				)
					.subscribe(
						result => {
							this.refreshDocumentOrDetails();
							if (this.data.basicPayload.edit)
								this.showNotification(
									result,
									'Dati protocollo modificati correttamente',
									'Dati protocollo non modificati'
								);
							else
								this.showNotification(
									result,
									'Dati protocollo inseriti correttamente',
									'Dati protocollo non inseriti'
								);

							this.unlockSubmitting();
						}
					);
				break;
			case 1: // PROTOCOLLO + RC FILE
				this.actionsHttpService.whenInsertProtocolAndRCFile(
					this.data.basicPayload.actionUrl, this.data.basicPayload.progSpool, this.data.basicPayload.progBusta,
					this.data.basicPayload.docHash, protocolNumber, protocolDate, this.data.basicPayload.ctelDocSeriesId,
					this.data.basicPayload.ctelElasticDocumentId, this.base64string
				).pipe(
					take(1),
					catchError((err: unknown) => {
						this.unlockSubmitting();
						return this.protocolError(err);
					}),
					delay(1000)
				)
					.subscribe(
						result => {
							this.refreshDocumentOrDetails();
							if (this.data.basicPayload.edit)
								this.showNotification(
									result,
									'Dati protocollo modificati correttamente',
									'Dati protocollo non modificati'
								);
							else
								this.showNotification(
									result,
									'Dati protocollo e file caricati correttamente',
									'Dati protocollo e file non caricati'
								);

							this.unlockSubmitting();
						}
					);
				break;
			case 2: // PROTOCOLLO + METADATI RC
				this.actionsHttpService.whenInsertProtocolAndRCMetadata(this.data.basicPayload.actionUrl, this.data.basicPayload.progSpool,
					this.data.basicPayload.progBusta, this.data.basicPayload.docHash, protocolNumber, protocolDate,
					this.data.basicPayload.ctelDocSeriesId, this.data.basicPayload.ctelElasticDocumentId, this.metadata)
					.pipe(
						take(1),
						catchError((err: unknown) => {
							this.unlockSubmitting();
							return this.protocolError(err);
						}),
						delay(1000)
					)
					.subscribe(
						result => {
							this.refreshDocumentOrDetails();
							if (this.data.basicPayload.edit)
								this.showNotification(
									result,
									'Dati protocollo modificati correttamente',
									'Dati protocollo non modificati'
								);
							else
								this.showNotification(
									result,
									'Dati protocollo e metadati di Reverse Charge inseriti correttamente',
									'Dati non inseriti'
								);

							this.unlockSubmitting();
						}
					);
				break;
			case 3: // FILE RC
				this.actionsHttpService.whenInsertRCFile(
					this.data.basicPayload.actionUrl, this.data.basicPayload.progSpool, this.data.basicPayload.progBusta,
					this.data.basicPayload.docHash, this.data.basicPayload.ctelDocSeriesId, this.data.basicPayload.ctelElasticDocumentId,
					this.base64string
				).pipe(
					catchError((err: unknown) => {
						this.unlockSubmitting();
						return this.protocolError(err);
					}),
					delay(1000)
				)
					.subscribe(
						result => {
							this.refreshDocumentOrDetails();
							this.showNotification(
								result,
								'File di Reverse Charge caricato correttamente',
								'File non caricato'
							);
							this.unlockSubmitting();
						}
					);
				break;
			case 4: // DATI RC
				this.actionsHttpService.whenInsertRCMetadata(this.data.basicPayload.actionUrl,
					this.data.basicPayload.progSpool, this.data.basicPayload.progBusta, this.data.basicPayload.docHash,
					this.data.basicPayload.ctelDocSeriesId, this.data.basicPayload.ctelElasticDocumentId, this.metadata)
					.pipe(
						take(1),
						catchError((err: unknown) => {
							this.unlockSubmitting();
							return this.protocolError(err);
						}),
						delay(1000)
					)
					.subscribe(
						result => {
							this.refreshDocumentOrDetails();
							this.showNotification(
								result,
								'Metadati di Reverse Charge caricati correttamente',
								'Metadati RC non caricati'
							);
							this.unlockSubmitting();
						}
					);
				break;
			case 5: // CONFERMA SENZA DATI
				this.actionsHttpService.whenConfirmation(
					this.data.basicPayload.actionUrl, this.data.basicPayload.progSpool, this.data.basicPayload.progBusta,
					this.data.basicPayload.docHash, this.data.basicPayload.ctelDocSeriesId, this.data.basicPayload.ctelElasticDocumentId, {}
				).pipe(
					take(1),
					catchError((err: unknown) => {
						this.unlockSubmitting();
						return this.protocolError(err);
					}),
					delay(1000)
				)
					.subscribe(
						result => {
							this.refreshDocumentOrDetails();
							this.showNotification(
								result,
								'Registrazione su gestionale avvenuta con successo',
								'Registrazione su gestionale fallita'
							);
							this.unlockSubmitting();
						}
					);
				break;
		}
	}

	// cancella riga dalla tabella dei dati RC
	deleteRow(index: number): void {
		this.isErrorRow = false;
		this.insertedTableData.splice(index, 1);
	}

	// conferma l'inserimento di una riga di dati RC
	insertData(row: ReverseChargeData[], index: number): void {
		if (!this.isGreen)
			return;

		this.isErrorRow = false;
		const addedRow: ReverseChargeData[] = [];
		for (let i = 0; i < row.length; i++) {
			const inputValue = (<HTMLInputElement>document.getElementById('cell' + row[i].name + index)).value;
			const obj: ReverseChargeData = {
				name: row[i].name,
				label: row[i].label,
				value: inputValue
			};
			addedRow.push(obj);
		}
		this.insertedTableData.splice(this.insertedTableData.length - 1, 0, addedRow);
		this.insertedTableData.splice(-1, 1);
		this.pushNewEmptyRow();
	}

	resetInputFile() {
		this.fileInputElem.nativeElement.value = '';
		this.base64string = '';
		this.inputFilename = 'Nessun PDF caricato';
		this.isResettable = false;
		this.isFileFormatError = false;
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private handleFile(event) {
		const binaryString = event.target.result;
		this.base64string = btoa(binaryString);
	}

	private refreshDocumentOrDetails() {
		if (this.data.refreshDocuments)
			this.documentsService.refreshDocuments();
		else
			this.documentDetailsService.sendRefreshDocumentDetails();

	}

	private protocolError(err: any) {
		if (err.type === ErrorTypes.HTTP_UNAUTHORIZED)
			//this.router.navigate(['/unauthorized']).then();
			return of(null);
		else {
			this.notificationService.showSweetAlert(
				NotificationType.ERROR,
				'Errore nell\'inserimento dei dati',
				'');
			return EMPTY;
		}
	}

	private showNotification(result: any, successMsg: string, errorMsg: string): void {
		if (result !== null) {
			this.notificationService.showSweetAlert(NotificationType.SUCCESS, successMsg, '');
			this.modal.hide();
		} else
			this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore inatteso', errorMsg);

	}

	// inserisce una prima riga con valori vuoti
	private pushNewEmptyRow(): void {
		const row = [];
		for (let i = 0; i < this.reverseChargeColumns.length; i++)
			row.push({
				name: this.reverseChargeColumns[i].idTipoCbi,
				label: this.reverseChargeColumns[i].label,
				value: ''
			}
			);

		this.isGreen = false;
		this.insertedTableData.push(row);
	}

}
