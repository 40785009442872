import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ArrayPathStructure, FormError } from 'app/entities/invoice-pa/all-validation-errors/form-error';
import { FatturaElettronica } from 'app/entities/invoice-pa/invoice-pa-model';
import { CustomValidationError } from 'app/shared/components/dry/validator/custom-validation-error';
import { BehaviorSubject, Observable } from 'rxjs';
import { FatturaElettronicaParserService } from '../../invoice-pa-parser/fattura-elettronica-parser.service';
import { XmlPaValidatorHttpService } from './xml-pa-validator-http.service';

@Injectable()
export class XmlPaValidatorService {

	private readonly js2xmlparser = require('js2xmlparser');
	private invoiceErrors$ = new BehaviorSubject<FormError[]>([]);
	private metadataErrors$ = new BehaviorSubject<FormError[]>([]);
	private readonly cleaner = require('clean-deep');

	constructor(
		private xmlPaHttpService: XmlPaValidatorHttpService,
		private invoiceParserService: FatturaElettronicaParserService,
	) { }

	public deepCleanObj(obj) {
		return this.cleaner(obj);
	}

	// api per validatore la fattura in json (validazione BACKEND)
	public validateInvoiceInJson(invoice: string, id: string, sia: string, grafica: string) {
		return this.xmlPaHttpService.validateInvoice(invoice, id, sia, grafica);
	}

	/**
	 * metodo che trova tutti gli errori all'interno del form dato
	 * @param parentForm corrisponde al formGroup da validare
	 * @param isTemplateDrivenForm parametro che identifica un template driven form o reactive form
	 * @returns array di FormError
	 */
	public getValidationErrors(parentForm: UntypedFormGroup | UntypedFormArray, isTemplateDrivenForm = false): FormError[] {
		let mainFormErrors: FormError[];
		if (parentForm)
			mainFormErrors = this.createErrorsObject(parentForm, isTemplateDrivenForm);

		return mainFormErrors;
	}

	// in base alla tiplogia di errori, mi creo dei messaggi d'errore
	public createErrorsObject(form, templateDrivenError) {
		const messages: FormError[] = [];
		const error: FormError[] = this.getFormValidationErrors(form.controls, form, templateDrivenError);
		if (error)
			for (let i = 0; i < error.length; i++) {
				const singleError = error[i];
				switch (error[i].errorName) {
					case 'required':
						singleError.message = `${error[i].controlName} è obbligatorio.`;
						messages.push(singleError);
						break;
					case 'pattern':
						singleError.message = `${error[i].controlName} non è valido`;
						messages.push(singleError);
						break;
					case 'email':
						singleError.message = `${error[i].controlName} ha un formato email errato.`;
						messages.push(singleError);
						break;
					case 'maxlength':
					case 'minlength':
						singleError.message = `${error[i].controlName}
						ha una lunghezza errata.`;
						messages.push(singleError);
						break;
					case 'causaleInvalid': {
						const values: string[] = error[i].errorValue?.errors;
						singleError.message = `${error[i].controlName}: Limite di 200 caratteri superato per elementi ${values.join(', ')}`;
						messages.push(singleError);
						break;
					}
					case 'naturaInvalid':
						singleError.message = 'Natura popolata di fronte ad un\'aliquota IVA diversa da zero';
						messages.push(singleError);
						break;
					default:
						singleError.message = `${error[i].controlName}: ${error[i].errorValue}`;
						messages.push(singleError);
				}
			}

		return messages;
	}

	getControlName(c: AbstractControl): string | null {
		if (!c.parent)
			return null;

		const formGroup = c.parent.controls;
		return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
	}

	getControlPath(c: AbstractControl, path: string, errorObj: ArrayPathStructure[]) {
		const controlName = this.getControlName(c);
		// ritorna false se la stringa non contiene un numero perciò lo nego
		const pathContainsIndex = !isNaN(Number(controlName));
		if (pathContainsIndex) {
			const arrayPath = this.getControlName(c.parent);
			const pathArrayIndex = Number(controlName);
			errorObj.push({
				arrayName: arrayPath,
				arrayIndex: pathArrayIndex
			});
		}
		path = controlName + path;
		if (c.parent && this.getControlName(c.parent)) {
			path = '.' + path;
			return this.getControlPath(c.parent, path, errorObj);
		}
		return {
			fullPath: path,
			pathArray: errorObj
		};
	}

	public createStringForValidation(invoice: FatturaElettronica, isModel: boolean) {
		let newInvoice: FatturaElettronica = {};
		if (isModel)
			this.invoiceParserService.createInvoiceBody(newInvoice, invoice);
		else
			newInvoice = this.invoiceParserService.createInvoiceToSend(
				invoice,
				invoice.FatturaElettronicaHeader.DatiTrasmissione.FormatoTrasmissione,
				false);

		// clean object
		this.cleaner(newInvoice);
		// convert json in string
		return JSON.stringify(JSON.stringify(newInvoice, this.replacer));
	}

	public isEmpty(obj) {
		for (const key in obj)
			if (Object.prototype.hasOwnProperty.call(obj, key))
				return false;

		return true;
	}

	public replacer(key, value) {
		if (Array.isArray(value) && value.every(o => JSON.stringify(o) === '{}'))
			return undefined;

		if (Array.isArray(value) && value.every(o => JSON.stringify(o) === null))
			return undefined;

		if (JSON.stringify(value) === '{}')
			return undefined;

		if (JSON.stringify(value) === '' || JSON.stringify(value) === null)
			return undefined;

		if (value && JSON.stringify(value).length === 0)
			return undefined;

		if (value === null || value === '')
			return undefined;

		return value;
	}

	public json2xml(obj) {
		return this.js2xmlparser.parse('FatturaElettronica', obj);
	}

	public setInvoiceErrors(data: FormError[]) {
		this.invoiceErrors$.next(data);
	}

	public whenInvoiceErrors(): Observable<FormError[]> {
		return this.invoiceErrors$.asObservable();
	}

	public setMetadataErrors(data: FormError[]) {
		this.metadataErrors$.next(data);
	}

	public whenMetadataErrors(): Observable<FormError[]> {
		return this.metadataErrors$.asObservable();
	}

	private getFormValidationErrors(controls, mainForm, tempplateDrivenError = false): FormError[] {
		let errors: FormError[] = [];
		Object.keys(controls).forEach((key) => {
			const control = controls[key];
			if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray)
				errors = errors.concat(this.getFormValidationErrors(control.controls, mainForm));

			const controlErrors: ValidationErrors = controls[key].errors;
			if (controlErrors !== null)
				Object.keys(controlErrors).forEach(keyError => {

					let errMessage = controlErrors[keyError];

					// Se si tratta di un validatore Custom prendo il messaggio da `message`
					if (controlErrors[keyError].message)
						errMessage = (<CustomValidationError>controlErrors)[keyError].message;

					const errorNavigationStructure = this.getControlPath(controls[key], '', []);
					// il control name è composto da nome + nomeModal, quindi è necessario fare lo split
					const controlName = key.split('-')[0];
					// nel caso di template driven form, IMPOSTARE json_path uguale a control_name
					errors.push({
						errorForm: mainForm,
						controlName,
						errorName: keyError,
						errorValue: errMessage,
						templateDrivenForm: tempplateDrivenError,
						errorNavigationStructure: {
							errorPath: errorNavigationStructure.fullPath,
							arraysInPath: errorNavigationStructure.pathArray
						}
					});
				});

		});
		return errors;
	}

}
