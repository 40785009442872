<div class="company-container">
  <!-- Azienda singola -->
  <ng-container *ngIf="singleCompany; else multipleCompanies">
    <span class="first-company">
      <span
        *ngIf="currentCompany?.companyDescription as companyDescription"
        class="current-company-name"
        id="current-company-name"
        [tooltip]="companyDescription.length > cdMaxLength ? companyDescription : null"
        [tooltipAnimation]="true"
        placement="bottom"
      >
        <svg-icon src="/assets/homepage/group.svg" [svgStyle]="{ 'width.px': 30 }"></svg-icon>
        {{ companyDescription }}
      </span>
      <div *ngIf="currentCompany.companyDescription.length > 0" id="tooltip-id" role="tooltip" class="sr-only">
        {{ currentCompany.companyDescription }}
      </div>
    </span>
  </ng-container>

  <!-- Aziende multiple -->
  <ng-template #multipleCompanies>
    <div class="btn-group companies-group">
      <!-- Span (al posto del button), rappresentante la prima azienda -->
      <span class="first-company dropdown-toggle" data-bs-toggle="dropdown" tabindex="0" role="button">
        <i [ngClass]="faIcons.FAS_CHEVRON_DOWN" aria-hidden="true"></i>
        <span
          *ngIf="currentCompany?.companyDescription as companyDescription"
          class="current-company-name"
          id="current-company-name"
          [tooltip]="companyDescription.length > cdMaxLength ? companyDescription : null"
          [tooltipAnimation]="true"
          placement="bottom"
        >
          <svg-icon src="/assets/homepage/group.svg" [svgStyle]="{ 'width.px': 30 }"></svg-icon>
          {{ companyDescription }}
        </span>
        <div *ngIf="currentCompany?.companyDescription.length > 0" class="sr-only">
          {{ currentCompany?.companyDescription }}
        </div>
      </span>

      <!-- Contenuto select, elenco aziende successive alla prima-->
      <ul *ngIf="companies?.length > 0" class="dropdown-menu" role="menu">
        <li
          *ngFor="let company of companies | companiesOrderByAlphabetical; let i = index"
          class="company"
          id="company-name-{{ i }}"
          role="menuitem"
        >
          <button
            class="active dropdown-item"
            [ngClass]="{ active: companyComparator(company, currentCompany) }"
            (click)="changeCompany(company)"
          >
            <svg-icon
              src="/assets/homepage/group.svg"
              [svgClass]="companyComparator(company, currentCompany) ? 'active' : ''"
              [svgStyle]="{ 'width.px': 30 }"
            ></svg-icon>
            <span class="company-name">{{ company?.companyDescription }}</span>
          </button>
        </li>
      </ul>
    </div>
  </ng-template>
</div>
