import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ActionsConfigurationHttpService } from './actions-configuration-http.service';
import { ActionsConfigRequest, ConfigurationsGroup } from 'app/entities/ui-config/action/actions-configuration';
import { FileExtension } from '../../entities/file-extension';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';

@Injectable({
	providedIn: 'root'
})
export class ActionsConfigurationService {

	faIcons = FaIcons;

	constructor(public actionsConfigurationHttpService: ActionsConfigurationHttpService) { }

	public getSingleActionsByDocSeries(
		licenseId: string,
		siaCode: string,
		sectionCode: string,
		body: ActionsConfigRequest
	): Observable<ConfigurationsGroup[]> {
		return this.actionsConfigurationHttpService.getSingleActions(licenseId, siaCode, sectionCode, body).pipe(
			take(1),
			map((response) => response.configurationsGroup)
		);
	}

	public getMultipleActionsByDocSeries(
		licenseId: string,
		siaCode: string,
		sectionCode: string,
		body: ActionsConfigRequest[]
	): Observable<ConfigurationsGroup[]> {
		return this.actionsConfigurationHttpService.getMultipleActions(licenseId, siaCode, sectionCode, body).pipe(
			take(1),
			map((response) => response.configurationsGroup)
		);
	}

	public getMassiveActionsByDocSeries(licenseId: string, siaCode: string, sectionCode: string): Observable<ConfigurationsGroup[]> {
		return this.actionsConfigurationHttpService.getMassiveActions(licenseId, siaCode, sectionCode).pipe(
			take(1),
			map((response) => response.configurationsGroup)
		);
	}

	public getExtensionIcon(extension: string) {
		let icon;
		switch (extension) {
			case FileExtension.PDF:
				icon = this.faIcons.FAR_PDF;
				break;
			case FileExtension.DOCX:
				icon = this.faIcons.FAR_FILE_WORD;
				break;
			case FileExtension.EML:
				icon = this.faIcons.FAR_ENVELOPE;
				break;
			case FileExtension.JPG:
			case FileExtension.JPEG:
			case FileExtension.TIF:
			case FileExtension.TIFF:
				icon = this.faIcons.FAR_FILE_IMAGE;
				break;
			case FileExtension.ODB:
				icon = this.faIcons.FAR_FILE_ARCHIVE;
				break;
			case FileExtension.ODG:
				icon = this.faIcons.FAR_FILE_ALT;
				break;
			case FileExtension.ODS:
				icon = this.faIcons.FAR_CHART_BAR;
				break;
			case FileExtension.ODP:
				icon = this.faIcons.FAS_FILE_CONTRACT;
				break;
			case FileExtension.PPTX:
				icon = this.faIcons.FAR_FILE_POWERPOINT;
				break;
			case FileExtension.TXT:
				icon = this.faIcons.FAR_STICKY_NOTE;
				break;
			case FileExtension.XLSX:
				icon = this.faIcons.FAR_FILE_CODE;
				break;
			case FileExtension.XML:
				icon = this.faIcons.FAR_FILE_CODE;
				break;
			case FileExtension.P7M:
			case FileExtension.CADES:
				icon = this.faIcons.FAS_FILE_SIGNATURE;
				break;
			default:
				icon = this.faIcons.FAR_FILE;
				break;
		}
		return icon;
	}
}
