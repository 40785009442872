<div *blockUI="'spool-modal-loader'; template: blockTemplate">
	<div class="modal-body">
		<ng-container *ngIf="inputData$ | async">
			<ng-container *ngIf="spoolList$ | async as spoolList">
				<ng-container *ngIf="spoolCount$ | async as count">
					<div class="alert alert-warning" *ngIf="count > 10000">
						<a href="#" class="close" data-bs-dismiss="alert" aria-label="close">&times;</a>
						<strong>Attenzione!</strong> Il numero di lotti per il periodo selezionato è
						<strong>{{count}}</strong>.
						Verranno visualizzati solo i <strong>10000</strong> lotti più recenti.
						Per visualizzare lotti più vecchi, impostare un range di date differente.
					</div>
				</ng-container>

				<table class="table table-striped table-sm">
					<thead>
						<tr>
							<th class="icon-header"> <!-- Scarica --> </th>
							<ng-container *ngIf="isSpoolErrorModal$ | async">
								<th class="icon-header"> <!-- Elimina --> </th>
							</ng-container>
							<th>{{spoolList[0].progspool.headerLabel}}</th>
							<th>{{spoolList[0].nomeFileOriginale.headerLabel}}</th>
							<th>{{spoolList[0].nomeflussoCredemtel.headerLabel}}</th>
							<th>{{spoolList[0].dataOraRicezione.headerLabel}}</th>
							<!--<th>{{spoolList[0].contieneDocumentiInErrore.headerLabel}}</th>-->
							<ng-container *ngIf="isSpoolElabModal$ | async">
								<th>{{spoolList[0].stato?.headerLabel}}</th>
							</ng-container>
							<ng-container *ngIf="isSpoolErrorModal$ | async">
								<th>{{spoolList[0].errore?.headerLabel}}</th>
								<th>{{spoolList[0].dettaglioErrore?.headerLabel}}</th>
							</ng-container>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let spool of spoolList">
							<td (click)="downloadSpool(spool)" class="curson-pointer">
								<i class="icon-green" [ngClass]="[faIcons.FAS_ARROW_CIRCLE_DOWN, faIcons.FA_LG]" aria-hidden="true"
									 [tooltip]="'Scarica'"></i>
							</td>
							<ng-container *ngIf="isSpoolErrorModal$ | async">
								<td class="cursor-pointer" (click)="cancelSpool(spool)">
									<i class="icon-red" [ngClass]="[faIcons.FAS_TIMES_CIRCLE, faIcons.FA_LG]" aria-hidden="true"
										 [tooltip]="'Annulla'"></i>
								</td>
							</ng-container>
							<td><span>{{spool.progspool.value}}</span></td>
							<td><span>{{spool.nomeFileOriginale.value}}</span></td>
							<td><span>{{spool.nomeflussoCredemtel.value}}</span></td>
							<td><span>{{spool.dataOraRicezione.value | isoDatetimeToDatetime}}</span></td>
							<!--<td>{{spool.contieneDocumentiInErrore.value | booleanToItalian}}</td>-->
							<ng-container *ngIf="isSpoolElabModal$ | async">
								<td>
									<span class="truncate" [tooltip]="spool.stato?.value">{{ spool.stato?.value }}</span>
								</td>
							</ng-container>
							<ng-container *ngIf="isSpoolErrorModal$ | async">
								<td>
									<p class="truncate" [tooltip]="spool.errore?.value">{{ spool.errore?.value }}</p>
								</td>
								<td class="dettaglio-errore" [tooltip]="spool.dettaglioErrore?.value">
									<p>{{ spool.dettaglioErrore?.value }}</p>
								</td>
							</ng-container>
						</tr>
					</tbody>
				</table>

				<div class="row spool-modal-footer">
					<div class="spool-modal-pagination">
						<ng-container *ngIf="spoolCount$ | async as spoolCount">
							<app-pagination [totalItems]="spoolCount <= 10000 ? spoolCount : 10000" [itemsPerPage]="10"
															(pageChanged)="pageChanged($event)">
							</app-pagination>
						</ng-container>
					</div>
					<div class="total-count">{{displayedItemLabel$ | async}} di {{ spoolCount$ | async }}</div>
				</div>
			</ng-container>
		</ng-container>
	</div>
	<div class="modal-footer">
		<button class="btn gaw-default-btn" (click)="modal.hide()">Chiudi</button>
	</div>
</div>
