import { Injectable } from '@angular/core';
import { DocumentDetails, TagsUpdateRequest, TagsValue } from '@ctel/gaw-commons';
import { WorkItemCreationResponse, WorkflowHttpService, WorkflowVM } from '@ctel/gaw-workflow';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ActionsHttpService } from '../actions/actions-http.service';
import { ActionsService2 } from '../actions/actions.service';
import { DocumentsHttpService } from '../documents-http.service';

/**
 * Servizio che gestisce lo stato dei dettagli del documento (elenco metadati)
 */
@Injectable({
	providedIn: 'root'
})
export class DocumentDetailsService {
	private documentDetails$ = new ReplaySubject<DocumentDetails>(1);
	private graphic$ = new ReplaySubject<string>(1);
	private documentId$ = new BehaviorSubject<string>('');
	private sectionCode$ = new BehaviorSubject<string>('');
	private docSeriesId$ = new BehaviorSubject<string>('');
	private realDocSeriesId$ = new BehaviorSubject<string>('');
	private tags$ = new ReplaySubject<string[]>(1);
	private keys$ = new BehaviorSubject<object>(null);
	private refresh$: BehaviorSubject<number> = new BehaviorSubject(0);

	// prog spool e prog busta
	private progSpoolAndBusta$ = new ReplaySubject<string[]>(1);

	// salvo la url della lista documenti da cui vengo (per edit fattura)
	private documentListUrl$ = new BehaviorSubject<string>(null);

	constructor(
		private documentsHttpService: DocumentsHttpService,
		private actionsHttpService: ActionsHttpService,
		private actionsService: ActionsService2,
		private companiesService: CompaniesService,
		private workflowHttpService: WorkflowHttpService
	) { }

	whenDocumentDetails(
		licenseId: string,
		siaCode: string,
		docSeriesId: string,
		elasticDocumentId: string,
		includeTechnical: boolean
	): Observable<DocumentDetails> {
		return this.documentsHttpService.whenDocumentDetails(licenseId, siaCode, docSeriesId, elasticDocumentId, includeTechnical).pipe(
			tap((details) => {
				const gawTags = details.tags.find((tagsArray) => tagsArray.keyCode === 'gawcons_tags');
				if (gawTags !== undefined)
					this.tags$.next(gawTags.value);

				this.sendKeys(details.keys);
			})
		);
	}

	whenCurrentDocumentDetails(): Observable<DocumentDetails> {
		return this.documentDetails$.asObservable();
	}

	whenMetadati(
		licenseId: string, siaCode: string, docSeriesId: string, elasticId: string,
		docId: string, versionId: string, registryId: string, fiscalYear: string,
		includeTechnical: boolean
	): Observable<DocumentDetails> {
		return this.documentsHttpService.whenMetadata(
			licenseId, siaCode, docSeriesId, elasticId, docId, versionId, registryId, fiscalYear, includeTechnical
		);
	}

	sendGraphic(graphic: string) {
		this.graphic$.next(graphic);
	}

	whenGraphicValue(): Observable<string> {
		return this.graphic$.asObservable();
	}

	whenGraphic(docSeriesId: string): Observable<string> {
		return this.documentsHttpService.whenGraphic(docSeriesId).pipe(
			map((docSeriesInfo) => docSeriesInfo.graphicsCode),
			tap((graphic) => this.sendGraphic(graphic))
		);
	}

	sendDocumentDetails(details: DocumentDetails) {
		this.documentDetails$.next(details);
	}

	whenDocumentId(): Observable<string> {
		return this.documentId$.asObservable();
	}

	getDocumentId(): string {
		return this.documentId$.value;
	}

	sendDocumentId(documentId: string) {
		this.documentId$.next(documentId);
	}

	whenSectionCode(): Observable<string> {
		return this.sectionCode$.asObservable();
	}

	getSectionCode(): string {
		return this.sectionCode$.value;
	}

	sendSectionCode(sectionCode: string) {
		this.sectionCode$.next(sectionCode);
	}

	getDocSeriesId(): string {
		return this.docSeriesId$.value;
	}

	getRealDocSeriesId(): string {
		return this.realDocSeriesId$.value;
	}

	sendDocSeriesId(docSeriesId: string) {
		this.docSeriesId$.next(docSeriesId);
	}

	// doc series id reale del singolo documento quando sono su ALL e apro lista WF
	whenRealDocSeriesId(): Observable<string> {
		return this.realDocSeriesId$.asObservable();
	}

	sendRealDocSeriesId(docSeriesId: string) {
		this.realDocSeriesId$.next(docSeriesId);
	}

	getKeys(): object {
		return this.keys$.value;
	}

	sendKeys(keys: object) {
		this.keys$.next(keys);
	}

	// tags
	whenTags(): Observable<string[]> {
		return this.tags$.asObservable();
	}

	sendTags(tags: string[]) {
		this.tags$.next(
			tags.filter(function (elem, index, self) {
				return index === self.indexOf(elem);
			})
		);
		const payload = this.buildTagsUpdateRequest(tags);
		this.documentsHttpService.whenTagsUpdate(JSON.stringify(payload))
			.pipe(
				take(1),
				catchError((err: unknown) => {
					if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
						//this.router.navigate(['/unauthorized']).then();
					}
					return EMPTY;
				})
			).subscribe();
	}

	resetTags() {
		this.tags$.next([]);
	}

	buildTagsUpdateRequest(tags: string[]): TagsUpdateRequest {
		const tagsValue: TagsValue = {
			value: tags,
			keyCode: 'gawcons_tags'
		};
		return {
			keys: this.getKeys(),
			metadataList: [tagsValue]
		};
	}

	// dopo un'azione dispositiva nel dettaglio, serve aggiornamento dei details di magellano per avere le azioni aggiornate
	sendRefreshDocumentDetails() {
		this.refresh$.next(this.refresh$.getValue() + 1);
	}

	resetRefreshDocumentDetails() {
		this.refresh$.next(0);
	}

	whenRefreshDocumentDetails(): Observable<number> {
		return this.refresh$.asObservable();
	}

	// link form crea nuovo item
	whenCreateWorkItem(workflow: WorkflowVM): Observable<WorkItemCreationResponse> {
		return this.whenDocumentDetails(
			this.companiesService.getCurrentCompanyValue().licenseId,
			this.companiesService.getCurrentCompanyValue().siaCode,
			this.getRealDocSeriesId(),
			this.getDocumentId(),
			false
		).pipe(
			switchMap((details) => {
				const properties = [
					{
						name: 'progSpool',
						value: details.keys['progSpool']
					},
					{
						name: 'progBusta',
						value: details.keys['progBusta']
					},
					{
						name: 'codSia',
						value: this.companiesService.getCurrentCompanyValue().siaCode
					}
				];

				for (let i = 0; i < details.metadataList.length; i++)
					properties.push({
						name: details.metadataList[i].keyCode,
						value: details.metadataList[i].valueAsString
					});

				const body = {
					documentId: details.documentId,
					documentSeries: details.docSeriesId,
					folder: false,
					properties
				};

				return this.workflowHttpService.whenCreateWorkItem(
					this.companiesService.getCurrentCompanyValue().licenseId,
					workflow.name,
					body
				);
			})
		);
	}

	// whenPdf(
	// 	serviceName: string,
	// 	progSpool: string,
	// 	progBusta: string,
	// 	download: boolean,
	// 	idFilePathPdf: string
	// ): Observable<Blob> {
	// 	return this.documentsHttpService.whenDisplayDocument(serviceName, progSpool, progBusta, download, idFilePathPdf);
	// }

	whenMessage(
		serviceName: string,
		progSpool: string,
		progBusta: string,
		docHash: string,
		download: boolean,
		docType: string
	): Observable<Blob> {
		return this.documentsHttpService.whenDisplayMessage(serviceName, progSpool, progBusta, docHash, download, docType);
	}

	whenCurrentProgSpoolAndBustaService(): Observable<string[]> {
		return this.progSpoolAndBusta$.asObservable();
	}

	sendCurrentProgSpoolAndBustaService(values: string[]) {
		this.progSpoolAndBusta$.next(values);
	}

	getDocumentListUrl(): string {
		return this.documentListUrl$.value;
	}

	whenDocumentListUrl(): Observable<string> {
		return this.documentListUrl$.asObservable();
	}

	sendDocumentListUrl(url: string) {
		this.documentListUrl$.next(url);
	}

	resetDocumentListUrl() {
		this.documentListUrl$.next(null);
	}

	whenPdf(
		licenseId: string,
		siaCode: string,
		idDocumento: string,
		idVersione: string,
		download: boolean
	): Observable<Blob> {
		return this.documentsHttpService.whenDisplayDocument(licenseId, siaCode, idDocumento, idVersione, download);
	}
}
