/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative al dettaglio documento
 */
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterPayload, FilterStatus, HideShowDocumentsResponse } from '@ctel/gaw-commons';
import { Transfer, TransferService, transferHandler } from '@ctel/transfer-manager';
import { ConfigService } from 'app/core/common/config/config.service';
import { ProtocolConfig } from 'app/core/common/modals/action-protocol-modal/reverse-charge-data';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { Extension } from 'app/core/common/utilities/file/file';
import { AppConfig } from 'app/entities/config/app-config';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { MassiveActionRequest } from 'app/entities/ui-config/action/massive-action-request';
import { HasAttachments } from 'app/entities/ui-config/actions-config';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { CheckSdiAndResend } from '../../entities/actions/check-sdi-and-resend/check-sdi-and-resend';
import { HistoryObject, HistoryOutcomesPayableItem } from 'app/core/common/entities/documents/actions/history-item';
import { ActionCrudResponse } from 'app/entities/ui-config/action/action-crud-response';

@Injectable({
	providedIn: 'root'
})
export class ActionsHttpService {

	private jsonContentType = 'application/json';
	// host
	private docDetailsHost: string;
	private gedinvoiceHost: string;
	private documentsExportHost: string;
	private clientConfigurationHost: string;
	private e_asyncMassiveDownload: string;

	// endpoints
	private pdfDownload: string;
	private xmlDownload: string;
	private protocolConfig: string;
	private displayOutcome: string;
	private hasAttachments: string;
	//private esitoDownload: string;
	private downloadAttachmentsMultiUri: string;
	private isPdfOriginalOrCustomPresent: string;
	private displayAdeDocument: string;
	private readonly jsonOptions: object;
	private actionLoading$ = new BehaviorSubject<boolean>(false);

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
		private fullScreenSpinnerService: FullScreenSpinnerService,
		private transferService: TransferService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));

		this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json', null, true);
	}

	public whenLoadingAction(): Observable<boolean> {
		return this.actionLoading$.asObservable();
	}

	public setLoadingAction(value: boolean) {
		this.actionLoading$.next(value);
	}

	httpHostInit(appConfig: AppConfig) {
		/* ENDPOINTS DOCUMENTS EXPORT---------------------------------------------------------------------------------------------------- */
		this.documentsExportHost = appConfig.documentsExport.http.host;
		/* ENDPOINTS CLIENT CONFIGURATION ----------------------------------------------------------------------------------------------- */
		this.clientConfigurationHost = appConfig.clientConfiguration.http.host;
		this.protocolConfig = `${this.clientConfigurationHost}/v1/ClientConfiguration/ProtocolloSettings`;
		/* ENDPOINTS GEDINVOICE ------------------------------------------------------------------------------------------------------- */
		this.gedinvoiceHost = appConfig.gedInvoice.http.host;
		/* ENDPOINTS DOCUMENT GED ------------------------------------------------------------------------------------------------------- */
		this.docDetailsHost = appConfig.docDetails.http.host;
		this.pdfDownload = `${this.docDetailsHost}/v1/09/GetDocumentGedonweb?progSpool={progSpool}&progBusta={progBusta}&download={download}`;
		// this.hideAndRestoreDocuments
		this.xmlDownload = `${this.docDetailsHost}/v1/Document/GEDINVOICE/ScaricaXml?progSpool={progSpool}&progBusta={progBusta}&download={download}`;
		this.displayOutcome = `${this.docDetailsHost}/v1/Document/GEDINVOICE/ScaricaXml?progSpool={progSpool}&progBusta={progBusta}&download={download}`;
		this.hasAttachments = `${this.docDetailsHost}/v1/Document/GEDINVOICE/HasAttachments?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&documentChannelType={account}`;
		this.downloadAttachmentsMultiUri = `${this.docDetailsHost}/v1/Document/GEDINVOICE/DownloadAttachmentsMulti`;
		this.isPdfOriginalOrCustomPresent = `${this.docDetailsHost}/v1/Document/CheckOriginalDocument?canale={serviceName}&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&documentCycleType={account}`;
		this.displayAdeDocument = `${this.docDetailsHost}/v1/Document/PdfFromXslAde?channel={serviceName}&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&documentCycleType={account}&download={download}`;
		this.e_asyncMassiveDownload = `${this.docDetailsHost}/v1/Document/export/massive?clientId={clientId}`;
	}

	// se ci sono o meno allegati per gedinvoice
	whenHasAttachments(progSpool: string, progBusta: string, docHash: string, account: string): Observable<HasAttachments> {
		const url = this.hasAttachments
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{account}', account);
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json', null, true);
		return this.http.get<HasAttachments>(url, options);
	}

	// AZIONI -------------------------------------------------------------------------------------------------------
	whenSinglePdf(actionUrl: string, progSpool: string, progBusta: string, docHash: string): Observable<Transfer> {
		const url = this.docDetailsHost + actionUrl
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.http.get(url, options).pipe(
			transferHandler(this.transferService)
		);
	}

	// download xml
	whenXml(
		actionUrl: string, progSpool: string, progBusta: string, docHash: string, docType: string, account: string
	): Observable<Transfer> {
		const url = this.docDetailsHost + actionUrl
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{docHash}', docHash)
			.replace('{docType}', docType)
			.replace('{account}', account);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.http.get(url, options).pipe(
			transferHandler(this.transferService)
		);
	}

	// download pdf zip
	whenPdfZip(actionUrl: string, body: unknown): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.docDetailsHost + actionUrl;
		return this.http.post<unknown>(url, JSON.stringify(body), options).pipe(
			transferHandler(this.transferService)
		);
	}

	// download xml zip
	whenXmlZip(actionUrl: string, body: unknown): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.docDetailsHost + actionUrl;
		return this.http.post<unknown>(url, JSON.stringify(body), options).pipe(
			transferHandler(this.transferService)
		);
	}

	// download excel doc selezionati
	whenExcel(actionUrl: string, body: unknown): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.documentsExportHost + actionUrl;
		return this.http.post(url, body, options).pipe(
			transferHandler(this.transferService)
		);
	}

	// download excel di tutto
	whenExcelAll(actionUrl: string, body: unknown): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.documentsExportHost + actionUrl;
		return this.http.post(url, body, options).pipe(
			transferHandler(this.transferService)
		);
	}

	// esporta verso gestionale massivo
	whenExportManagementMassive(actionUrl: string, body: unknown): Observable<{ esitoOK: boolean, message: string }> {
		const options = CustomHttpOptions.getHttpOptions('text/json', 'json', null, true);
		const url = this.docDetailsHost + actionUrl;
		return this.http.post<{ esitoOK: boolean, message: string }>(url, body, options);
	}

	whenCancelAll(actionUrl: string, body: unknown): Observable<ActionCrudResponse> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl;
		return this.http.post<ActionCrudResponse>(url, body, this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// cancel invoice
	whenCancelInvoice(actionUrl: string, body: unknown[], channel: string, client?: string): Observable<ActionCrudResponse[]> {
		this.setLoadingAction(true);
		const headers: HttpHeaders = new HttpHeaders();
		const options: unknown = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json', headers, true);
		const url = this.docDetailsHost + actionUrl;
		return this.http.post<ActionCrudResponse[]>(url + '?ChannelEnum=' + channel + '&clientId=' + client, body, options).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// cancel invoice
	whenSignDocument(actionUrl: string, body: unknown): Observable<unknown> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl;
		return this.http.post<unknown>(url, JSON.stringify(body), this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}
	// download allegati
	whenAttachments(actionUrl: string, progSpool: string, progBusta: string, docHash: string): Observable<Transfer> {
		const url = this.docDetailsHost + actionUrl
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.http.get(url, options).pipe(
			transferHandler(this.transferService)
		);
	}

	whenChangesHistory(
		actionUrl: string, progSpool: string, progBusta: string, docHash: string
	): Observable<HistoryObject[] | HistoryOutcomesPayableItem[]> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		return this.http.get<HistoryObject[] | HistoryOutcomesPayableItem[]>(url, this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	whenOutcomesHistoryPayable(
		actionUrl: string, progSpool: string, progBusta: string, licenseId: string, siaCode: string
	): Observable<HistoryOutcomesPayableItem[]> {
		this.setLoadingAction(true);
		const url = this.gedinvoiceHost + actionUrl
			.replace('{siaCode}', siaCode)
			.replace('{licenseId}', licenseId)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		return this.http.get<HistoryOutcomesPayableItem[]>(url, this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	whenShowOutcome(actionUrl: string): Observable<BlobPart> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl;
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'blob', null, true);
		return this.http.get<BlobPart>(url, options).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	/**
	 * Ottiene una Response col contenuto del file
	 * @param actionUrl Indirizzo per il download del file
	 * @return Observable<HttpResponse<Blob>>
	 */
	whenDownloadOutcome(actionUrl: string): Observable<HttpResponse<Blob>> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl;
		return this.http.get(url, {
			observe: 'response',
			responseType: 'blob',
			headers: new HttpHeaders({ 'log': 'true' })
		}).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	whenCheckAndResendInvoice(actionUrl: string, body: CheckSdiAndResend): Observable<CheckSdiAndResend> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'log': 'true'
			}),
			params: {
				forcedChannel: '35'
			}
		};
		return this.http.post<CheckSdiAndResend>(url, body, httpOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// PASSIVO
	whenForwardAddSupplier(actionUrl: string, body: unknown): Observable<unknown> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl;
		return this.http.post<unknown>(url, JSON.stringify(body), this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	whenForwardNoSupplier(actionUrl: string, body: unknown): Observable<unknown> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl;
		return this.http.post<unknown>(url, JSON.stringify(body), this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	whenExportToManagementSW(actionUrl: string, body: unknown[]): Observable<ActionCrudResponse> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl;
		return this.http.post<ActionCrudResponse>(url, body, this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	whenReExportToManagementSW(actionUrl: string, body: unknown[]): Observable<unknown> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl;
		return this.http.post<unknown>(url, body, this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	whenExportToManagementFromUnpreserved(actionUrl: string, body: unknown[]): Observable<unknown> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl;
		const revertExcludeInput = {
			'excludeDocuments': body.map(doc => ({
				'progSpool': doc?.['progSpool'],
				'progBusta': doc?.['progBusta'],
				'documentId': doc?.['ctelElasticDocumentId'],
				'idSerieDoc': doc?.['ctelDocSeriesId']
			}))
		};
		return this.http.post<unknown>(url, revertExcludeInput, this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}
	whenExportToManagementFromUnpreservedMassive(actionUrl: string, body: unknown): Observable<unknown> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl;

		return this.http.post<unknown>(url, body, this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// inserisce dati protocollo per il mode 0
	whenInsertProtocolData(
		actionUrl: string, progSpool: string, progBusta: string, docHash: string, protocolNumber: string,
		protocolDate: string, docSeriesId: string, documentId: string, body: unknown
	): Observable<ActionCrudResponse> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl
			.replace('{protocolDate}', protocolDate)
			.replace('{protocolNumber}', protocolNumber)
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{docSeriesId}', docSeriesId)
			.replace('{documentId}', documentId);
		return this.http.post<ActionCrudResponse>(url, JSON.stringify(body), this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// inserisce dati protocollo per il mode 1
	whenInsertProtocolAndRCFile(actionUrl: string, progSpool: string, progBusta: string, docHash: string, protocolNumber: string,
		protocolDate: string, docSeriesId: string, documentId: string, body: unknown
	): Observable<ActionCrudResponse> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl
			.replace('{protocolDate}', protocolDate)
			.replace('{protocolNumber}', protocolNumber)
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{docSeriesId}', docSeriesId)
			.replace('{documentId}', documentId);
		return this.http.post<ActionCrudResponse>(url, JSON.stringify(body), this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// inserisce dati protocollo per il mode 2
	whenInsertProtocolAndRCMetadata(actionUrl: string, progSpool: string, progBusta: string, docHash: string, protocolNumber: string,
		protocolDate: string, docSeriesId: string, documentId: string, body: unknown
	): Observable<ActionCrudResponse> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl
			.replace('{protocolDate}', protocolDate)
			.replace('{protocolNumber}', protocolNumber)
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{docSeriesId}', docSeriesId)
			.replace('{documentId}', documentId);
		return this.http.post<ActionCrudResponse>(url, JSON.stringify(body), this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// inserisce dati protocollo per il mode 3
	whenInsertRCFile(
		actionUrl: string, progSpool: string, progBusta: string, docHash: string, docSeriesId: string, documentId: string, body: unknown
	): Observable<ActionCrudResponse> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{docSeriesId}', docSeriesId)
			.replace('{documentId}', documentId);
		return this.http.post<ActionCrudResponse>(url, JSON.stringify(body), this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// inserisce dati protocollo per il mode 4
	whenInsertRCMetadata(
		actionUrl: string, progSpool: string, progBusta: string, docHash: string, docSeriesId: string, documentId: string, body: unknown
	): Observable<ActionCrudResponse> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{docSeriesId}', docSeriesId)
			.replace('{documentId}', documentId);
		return this.http.post<ActionCrudResponse>(url, JSON.stringify(body), this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// conferma registrazione su gestionale (mode 5)
	whenConfirmation(
		actionUrl: string, progSpool: string, progBusta: string, docHash: string, docSeriesId: string, documentId: string, body: unknown
	): Observable<unknown> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{docSeriesId}', docSeriesId)
			.replace('{documentId}', documentId);
		return this.http.post<unknown>(url, JSON.stringify(body), this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// chiede per quella grafica quale modalità di dati protocollo è configurata (mode da 0 a 5)
	whenProtocolConfig(licenseId: string, siaCode: string, graphic: string): Observable<ProtocolConfig> {
		this.setLoadingAction(true);
		const body = {
			idLicenza: licenseId,
			codSia: siaCode,
			codGrafica: graphic
		};
		const url = this.protocolConfig;
		return this.http.post<ProtocolConfig>(url, JSON.stringify(body), this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// chiede per quella grafica quale modalità di dati protocollo è configurata (mode da 0 a 5)
	whenProtocolMetadataConfig(licenseId: string, siaCode: string, graphic: string): Observable<unknown> {
		const body = {
			idLicenza: licenseId,
			codSia: siaCode,
			codGrafica: graphic
		};
		const url = this.protocolConfig;
		return this.http.post<unknown>(url, JSON.stringify(body), this.jsonOptions);
	}

	whenModifyMailOrPecAddress(
		actionUrl: string, progSpool: string, progBusta: string, docHash: string, address: string, body: unknown
	): Observable<ActionCrudResponse> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl
			.replace('{mail}', address)
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		return this.http.post<ActionCrudResponse>(url, JSON.stringify(body), this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	whenForwardWithCopies(actionUrl: string, progSpool: string, progBusta: string, docHash: string, body: unknown): Observable<unknown> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		return this.http.post<unknown>(url, JSON.stringify(body), this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	whenForwardWithoutCopies(actionUrl: string, progSpool: string, progBusta: string, docHash: string, body: unknown): Observable<unknown> {
		this.setLoadingAction(true);
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json', null, true);
		const url = this.docDetailsHost + actionUrl
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		return this.http.post<unknown>(url, JSON.stringify(body), options).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// CHECK PRESENZA PDF ORIGINALE O CUSTOM PER L'ATTIVO (nel passivo c'è sempre l'originale)
	whenIsPdfOriginalOrCustomPresent(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, account: string
	): Observable<ActionCrudResponse> {
		this.setLoadingAction(true);
		const url = this.isPdfOriginalOrCustomPresent
			.replace('{account}', account)
			.replace('{serviceName}', serviceName)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{docHash}', docHash);
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json', null, true);
		return this.http.get<ActionCrudResponse>(url, options).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// Scarica / visualizza pdf AdE per attivo e passivo
	whenDisplayAdeDocument(
		account: string, serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean
	): Observable<Blob> {
		this.setLoadingAction(true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders()
			.pipe(
				take(1),
				switchMap(headers => {
					const url = this.displayAdeDocument
						.replace('{account}', account)
						.replace('{serviceName}', serviceName)
						.replace('{progSpool}', progSpool)
						.replace('{progBusta}', progBusta)
						.replace('{docHash}', docHash)
						.replace('{download}', String(download));
					let options: unknown;
					if (download)
						options = CustomHttpOptions.getHttpOptionsObserveResponse(this.jsonContentType, 'blob', headers, true);
					else
						options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'blob', headers, true);

					return this.http.get<Blob>(url, options).pipe(
						tap({
							next: () => this.setLoadingAction(false),
							error: () => this.setLoadingAction(false)
						})
					);
				})
			);
	}

	// nascondi/mostra documenti
	public whenHideAndShowDocuments(actionUrl: string, body: unknown, hide: boolean): Observable<HideShowDocumentsResponse> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl
			.replace('{hide}', JSON.stringify(hide));
		return this.http.post(url, body, this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// scarica allegati multiplo
	public downloadAttachmentsMulti(body: { documents: { progSpool: string, progBusta: string }[] }): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.http.post(this.downloadAttachmentsMultiUri, body, options).pipe(
			transferHandler(this.transferService)
		);
	}

	// nasdondi/mostra documenti massivo
	public whenHideAndShowDocumentsMassive(actionUrl: string, body: unknown, hide: boolean): Observable<ActionCrudResponse> {
		const url = this.docDetailsHost + actionUrl
			.replace('{hide}', JSON.stringify(hide));

		return this.http.post<ActionCrudResponse>(url, body, this.jsonOptions);
	}

	// invia via ftp
	public exportToFtp(actionUrl: string, body: unknown): Observable<Transfer> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl; // TODO: Define host
		return this.http.post<Transfer>(url, JSON.stringify(body), this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// escludi da conservazione
	public excludeFromCons(actionUrl: string, body: unknown): Observable<{
		documentId: string; esitoOK: boolean; idSerieDoc: string; message: string; progBusta: number; progSpool: number
	}[]> {
		this.setLoadingAction(true);
		const url = this.docDetailsHost + actionUrl;
		return this.http.post<unknown>(url, JSON.stringify(body), this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			}),
			map(res => res?.['exclusionDocumentsResponses'] ?? [])
		);
	}

	asyncPDFMassiveDownload(elasticRequest: FilterPayload, docType: string, channel: string): Observable<unknown> {
		return this.massiveDownload(elasticRequest, docType, channel, 'pdf');
	}

	asyncXMLMassiveDownload(elasticRequest: FilterPayload, docType: string, channel: string): Observable<unknown> {
		return this.massiveDownload(elasticRequest, docType, channel, 'Xml');
	}

	downloadEsiti(actionUrl: string, body: string[], format: Extension) {
		const url = this.docDetailsHost + actionUrl;
		const params = new HttpParams().set('fileFormat', format);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.http.post(url, body, { ...options, params }).pipe(
			transferHandler(this.transferService)
		);
	}

	asyncDownloadMassiveEsiti(action: ActionObject, body: MassiveActionRequest) {
		const url = this.docDetailsHost + action.url;
		const params = {
			clientId: 'HUBFE'
		};
		return this.http.post(url, body, { params, headers: new HttpHeaders({ 'log': 'true' }) });
	}

	public whenExcelEsiti(actionUrl: string, body, extension, timezone: string, sectionCode: string) {
		const url = this.documentsExportHost + actionUrl;
		const params = {
			timezone,
			format: extension,
			sectionCode,
			clientId: 'HUBFE'
		};
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.http.post(url, body, { ...options, params }).pipe(
			transferHandler(this.transferService)
		);
	}

	asyncExcelMassiveDownloadEsiti(
		elasticRequest: FilterStatus, configurationGroup: string, extension, sectionCode: string, actionUrl: string
	) {
		const url = this.documentsExportHost + actionUrl;
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap(fullscreenHeaders => {
				const body = {
					elasticRequest, configurationGroup, extension, sectionCode
				};
				const headers = fullscreenHeaders.set('log', 'true');
				return this.http.post(url, body, { headers, params: { clientId: 'HUBFE' } });
			})
		);
	}

	private massiveDownload(elasticRequest: FilterPayload, docType: string, channel: string, fileType: string): Observable<unknown> {
		const url = this.e_asyncMassiveDownload.replace('{clientId}', 'HUBFE');
		const body = { elasticRequest, docType, channel, fileType };
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap(fullscreenHeaders => {
				const headers = fullscreenHeaders.set('log', 'true');
				return this.http.post(url, body, { headers });
			})
		);
	}
}
