<div *ngIf="currentUpload">
	<progress class="progress is-success" min=1 max=100 value="{{ currentUpload?.progress }}"></progress>
	Progress: {{currentUpload?.name}} | {{currentUpload?.progress}}% Complete
</div>
<div class="box">

	<h2>Drop File</h2>

	<div class="dropzone"
			 fileDrop
			 (filesDropped)="handleDrop($event)"
			 (filesHovered)="dropzoneState($event)"
			 [ngClass]="{'active': dropzoneActive}">

		<i [ngClass]="[faIcons.FAS_CLOUD, faIcons.FA_2X]"></i>

	</div>
</div>
