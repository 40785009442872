<div class="modal-body">
  <div data-value="1" draggable="true">
    <div
      class="dropzone"
      fileDrop
      (filesDropped)="handleDrop($event)"
      (filesHovered)="dropzoneState($event)"
      type="file"
    >
      <a class="over-icon">
        <div class="jumbotron upload-page">
          <div *ngIf="!fileLoaded">
            <p class="lead white-space-normal dropzone-paragraph" style="text-align: center">
              Puoi allegare una richiesta trascinando il file qua o cliccando su allega file
            </p>
            <p class="lead dropzone-paragraph" style="text-align: center">File accettati: pdf</p>

            <label for="file" class="btn gaw-btn btn-primary">
              <i class="{{ faIcons.FAS_UPLOAD }}"></i>
              Allega file
            </label>
            <input id="file" type="file" style="display: none" accept=".pdf" (change)="onFileChange($event)" />
          </div>
          <div *ngIf="fileLoaded">
            <p class="lead dropzone-paragraph" style="text-align: center">{{ fileName }} pronto per essere caricato</p>
            <button type="button" class="btn gaw-btn btn-light" (click)="removeUpload()">
              <i class="{{ faIcons.FAR_TRASH }} icon-remove"></i> Rimuovi
            </button>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn gaw-btn btn-light" (click)="modal.hide()">Annulla</button>
  <button type="button" class="btn gaw-btn btn-primary" (click)="onUpload()" [disabled]="!fileLoaded">Conferma</button>
</div>
