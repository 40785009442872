<ng-container *ngIf="attachments?.length > 0; else EmptyArray">
	<div class="attachments-list py-3">
		<div class="badge-container">
			<span class="badge">Nome</span>
		</div>
		<div class="list-content">
			<ng-container *dryError="_areInError; loading: _areLoading; options: errorOptions">

				<ng-container *ngFor="let attachment of attachments; trackBy: trackByFn">
					<div class="attachment">
						<div (click)="onclick(attachment)" class="pointer">
							<div class="attachment-icon">
								<img [src]="attachment.filename | attachmentIcon" alt="">
							</div>
							<div class="attachment-name">
								<span>{{attachment.filename}}</span>
							</div>
						</div>
						<div class="actions">
							<div class="action" *ngFor="let action of actions"
								 (click)="executeAction(attachment, action)">
								<i class="{{ action.icon }}" aria-hidden="true"></i>
							</div>
						</div>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</div>
</ng-container>
<ng-template #EmptyArray>
	<span class="no-attachments">Nessun allegato disponibile</span>
</ng-template>

