import { Group } from './group';

export class Registry {
	tuttiRegistri?: boolean;
	idRegistro: number;
	docLabel: string;
	codiceSezione?: string;
	idSerieDoc?: string;
	idGruppo?: number;
	gruppiMetadatiRegistro?: any[];
	page?: number;
	group?: Group;
}
