import { Column } from 'app/constants/column-configuration/ui-configuration-columns';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';

// Colonne dei canali in più che vengono applicate a tutte le card dell'attivo
export const metadataReceivableServicesColumns: Column[] = [
	{
		id: MetadataEnum.DESCRIZIONE_STATO_35_KW,
		value: 'GEDFATT'
	},
	{
		id: MetadataEnum.DESCRIZIONE_STATO_21_KW,
		value: 'GEDMAIL'
	},
	{
		id: MetadataEnum.DESCRIZIONE_STATO_22_KW,
		value: 'GEDPEC'
	},
	{
		id: MetadataEnum.DESCRIZIONE_STATO_06_KW,
		value: 'GEDPOST'
	},
];

// Colonne dei canali in più che vengono applicate a tutte le card del passivo
export const metadataPayableServicesColumns: Column[] = [
	{
		id: MetadataEnum.DESCRIZIONE_STATO_KW,
		value: 'GEDFATT'
	},
	{
		id: MetadataEnum.DESCRIZIONE_STATO_KW,
		value: 'GEDPASS'
	},
	{
		id: MetadataEnum.DESCRIZIONE_STATO_KW,
		value: 'GEDPASS+'
	},
	{
		id: MetadataEnum.DESCRIZIONE_STATO_KW,
		value: 'GEDEDI'
	}
];

