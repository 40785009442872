<!-- entrambi i tab presenti -->
<tabset *ngIf="isPdfOriginalOrCustomPresent.esitoOK === true; else onlyAdE" [justified]="true" class="resp-container">
  <tab>
    <ng-template tabHeading>
      <div class="tab-header">
        <b>PDF ORIGINALE / DA FOGLIO DI STILE CUSTOM</b>
      </div>
    </ng-template>
    <br />
    <div class="resp-container">
      <button class="btn btn-primary" (click)="downloadOriginalPdf()">Scarica PDF</button>
      <dry-pdf-viewer [documentAttachment]="originalPdf"> </dry-pdf-viewer>
    </div>
  </tab>
  <tab>
    <ng-template tabHeading>
      <div class="tab-header" (click)="openAdePdf()">
        <b>PDF DA FOGLIO DI STILE ADE</b>
      </div>
    </ng-template>
    <br />
    <div class="resp-container">
      <button class="btn btn-primary" (click)="downloadAdePdf()">Scarica PDF</button>
      <dry-pdf-viewer [documentAttachment]="adePdf"> </dry-pdf-viewer>
    </div>
  </tab>
  <br />
</tabset>

<!-- se c'è solo il tab dell'ade-->
<ng-template #onlyAdE>
  <tabset [justified]="true" class="resp-container">
    <tab>
      <ng-template tabHeading>
        <div class="tab-header" (click)="openAdePdf()">
          <b>PDF DA FOGLIO DI STILE ADE</b>
        </div>
      </ng-template>
      <br />
      <div class="resp-container">
        <button class="btn btn-primary" (click)="downloadAdePdf()">Scarica PDF</button>
        <dry-pdf-viewer [documentAttachment]="adePdf"> </dry-pdf-viewer>
      </div>
    </tab>
    <br />
  </tabset>
</ng-template>
