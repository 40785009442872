import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DocumentsSearchModule } from './documents-search/documents-search.module';
import { GAWPECDetailComponent } from './gawpec-detail/gawpec-detail.component';
import { GAWPECDetailsMainComponent } from './gawpec-detail/gawpec-details-main/gawpec-details-main.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { GAWPECStatusHistoryComponent } from './gawpec-detail/gawpec-details-main/gawpec-status-history/gawpec-status-history.component';
import { ErrorPlaceholderModule, PlaceholderModule } from '@ctel/placeholders';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { GAWPECDocumentPreviewComponent } from './gawpec-detail/gawpec-details-main/gawpec-document-preview/gawpec-document-preview.component';
import { DisplayDocumentModule } from 'app/shared/components/display-document/display-document.module';
import { DryComponentsModule } from 'app/shared/components/dry/dry-components.module';
import { HistoryActionModalComponent } from './modals/history-action-modal.component/history-action-modal.component';

@NgModule({
	declarations: [
		HistoryActionModalComponent,
		GAWPECDetailComponent,
		GAWPECDetailsMainComponent,
		GAWPECStatusHistoryComponent,
		GAWPECDocumentPreviewComponent
	],
	imports: [
		CommonModule,
		DryComponentsModule,
		DisplayDocumentModule,
		PipesModule,
		ErrorPlaceholderModule,
		PlaceholderModule,
		TabsModule,
		DocumentsSearchModule,
	],
	exports: [GAWPECDetailComponent, GAWPECDetailsMainComponent, GAWPECStatusHistoryComponent, GAWPECDocumentPreviewComponent],
})
export class GawpecCommonsModule {}
