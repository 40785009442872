/* eslint-disable @typescript-eslint/no-empty-function */
import { ControlValueAccessor } from '@angular/forms';

export abstract class AbstractControlValueAccessorComponent<T> implements ControlValueAccessor {

	_value: T = null;

	get value(): T {
		return this._value;
	}

	set value(val: T) {
		this._value = val;
		this.onChange(val);
		this.onTouched();
	}

	onChange: (val: T) => unknown = () => { };
	onTouched: () => unknown = () => { };

	// Callback richiamata quando il valore del control cambia nella UI
	registerOnChange(fn: (val: T) => unknown): void {
		if (typeof fn === 'function')
			this.onChange = fn;

	}

	// Callback richiamata quando il control viene 'toccato' (blur)
	registerOnTouched(fn: () => unknown): void {
		if (typeof fn === 'function')
			this.onTouched = fn;

	}

	// Assegna un nuovo valore all'elemento
	writeValue(value: T): void {
		this.value = value;
		this.updateChanges();
	}

	updateChanges() {
		this.onChange(this.value);
	}

}
