import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConfigService } from '../../../common/config/config.service';

@Component({
	selector: 'gaw-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

	public changePasswordUrl$: Observable<string>;

	constructor(private configService: ConfigService) { }

	ngOnInit(): void {
		const returnUrl = window.location.href;
		this.changePasswordUrl$ = this.configService.whenDryConfig().pipe(
			take(1),
			map(config => config.auth.oidc.config.authority),
			map(stsServerBaseUrl => `${stsServerBaseUrl}/account/cambiopassword?returnUrl=${returnUrl}`)
		);
	}

	gotoChangePassword() {
		this.changePasswordUrl$
			.pipe(take(1))
			.subscribe(url => window.open(url, '_blank'));
	}
}
