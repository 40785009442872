<div class="attachment-detail" *ngIf="attachmentPreview$ | async as attachment">
	<div class="attachment-info">
		<div class="back-button" (click)="back()" *ngIf="hasBackButton">
			<button class="btn btn-primary">
				<i [ngClass]="faIcons.FAS_ARROW_LEFT"></i>
			</button>
		</div>
		<div class="detail">
			<img [src]="attachment.filename | attachmentIcon" alt="" class="attachment-icon">
			<span class="attachment-name"><b>{{ attachment?.filename }}</b></span>
			<div class="actions">
				<div class="action" *ngFor="let action of actions" (click)="executeAction(attachment, action)">
					<i [ngClass]="[action.icon, faIcons.FA_2X]"></i>
				</div>
			</div>
		</div>
	</div>
	<div class="attachment-preview">
		<ng-container [ngSwitch]="previewType$ | async">
			<!-- PDF -->
			<ng-template [ngSwitchCase]="EXTENSION.PDF">
				<dry-pdf-viewer [documentAttachment]="(documentAttachment$ | async) | asPdfDoc"></dry-pdf-viewer>
			</ng-template>
			<!-- XML -->
			<ng-template [ngSwitchCase]="EXTENSION.XML">
				<dry-xml-viewer [documentAttachment]="(documentAttachment$ | async) | asXmlDoc"></dry-xml-viewer>
			</ng-template>
			<!-- Default -->
			<ng-container *ngSwitchDefault>
				<p class="no-preview" #NoPreview>
					Anteprima non disponibile.
					<br/>
					Il file verrà scaricato.
				</p>
			</ng-container>
		</ng-container>
	</div>
</div>
