import { Injectable } from '@angular/core';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Details } from '../entities/details';
import { DetailsHttpService } from './details-http.service';
import { ChannelStateMapping } from '../entities/channel-state-mapping';

/**
 * Servizio che gestisce lo stato dei dettagli del documento (elenco metadati)
 */
@Injectable({
	providedIn: 'root',
})
export class DetailsService {
	public errorMessage: string;
	private documentDetails$ = new ReplaySubject<Details>(1);
	private serviceName$ = new ReplaySubject<string>(1);
	private keys$ = new BehaviorSubject<object>(null);
	private docMetadataError$ = new BehaviorSubject<boolean>(false);
	private refresh$: BehaviorSubject<number> = new BehaviorSubject(0);

	constructor(
		private detailsHttpService: DetailsHttpService
	) { }

	whenDetailsGawcons(
		licenseId: string, siaCode: string, elasticId: string, docSeriesId: string, includeTechnical: boolean
	): Observable<Details> {
		return this.detailsHttpService.whenDetailsGawcons(licenseId, siaCode, elasticId, docSeriesId, includeTechnical).pipe(
			catchError((err: unknown) => {
				if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
					//this.router.navigate(['/unauthorized']).then();
				} else
					this.sendMetadataError(true);

				return EMPTY;
			}),
			tap((details) => {
				this.sendMetadataError(false);
				this.sendKeys(details.keys);
			})
		);
	}
	// elenco metadati documento
	whenDetails(
		licenseId: string, siaCode: string, elasticId: string, docSeriesId: string,
		includeTechnical: boolean, clientId: string, channelType?: string
	): Observable<Details> {
		this.errorMessage = undefined;
		if (clientId === 'GAWLOTTI')
			return this.detailsHttpService.whenLottoDetails(
				this.getProgSpool(elasticId),
				channelType ? channelType : this.getTipoCanale(elasticId),
				licenseId,
				siaCode,
				elasticId,
				docSeriesId,
				includeTechnical
			).pipe(
				catchError((err: unknown) => {
					if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND)
						return EMPTY;
					else
						this.sendMetadataError(true);

					return EMPTY;
				}),
				tap((details) => {
					this.sendMetadataError(false);
					this.sendKeys(details.keys);
				})
			);

		return this.detailsHttpService.whenDetails(licenseId, siaCode, elasticId, docSeriesId, includeTechnical, clientId).pipe(
			catchError((err: unknown) => {
				if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
					//this.router.navigate(['/unauthorized']).then();
				} else
					this.sendMetadataError(true);

				return EMPTY;
			}),
			tap((details) => {
				this.sendMetadataError(false);
				this.sendKeys(details.keys);
			})
		);
	}

	// dopo un'azione dispositiva nel dettaglio, serve aggiornamento dei details di magellano per avere le azioni aggiornate
	sendRefreshDetails() {
		this.refresh$.next(this.refresh$.getValue() + 1);
	}

	resetRefreshDetails() {
		this.refresh$.next(0);
	}

	whenRefreshDetails(): Observable<number> {
		return this.refresh$.asObservable();
	}

	sendMetadataError(value: boolean) {
		this.docMetadataError$.next(value);
	}

	whenMetadataError(): Observable<boolean> {
		return this.docMetadataError$.asObservable();
	}

	sendDetails(details: Details) {
		this.documentDetails$.next(details);
	}

	// elenco canali ciclo attivo per un documento e colori ultimo stato
	whenServiceList(progSpool: string, progBusta: string, docHash: string): Observable<ChannelStateMapping[]> {
		return this.detailsHttpService.whenServiceList(progSpool, progBusta, docHash).pipe(
			catchError((err: unknown) => {
				// if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND) {
				// 	return of(null);
				// }
				if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED)
					//this.router.navigate(['/unauthorized']).then();
					return of(null);

				this.sendMetadataError(true);
				return EMPTY;
			}),
			tap((serviceList) => {
				if (serviceList.length > 0) {
					this.sendMetadataError(false);
					this.sendCurrentService(serviceList[0]?.channelName.toUpperCase());
				}
			})
		);
	}

	// canale selezionato nel dettaglio (button cliccato o primo di default al caricamento pagina)
	whenCurrentService(): Observable<string> {
		return this.serviceName$.asObservable();
	}

	sendCurrentService(serviceName: string) {
		this.serviceName$.next(serviceName);
	}

	// keys prese dai dettagli documento
	getKeys(): object {
		return this.keys$.value;
	}

	sendKeys(keys: object) {
		this.keys$.next(keys);
	}

	whenServiceListGawlotti(progSpool: string, licenseId: string, siaCode: string) {
		return this.detailsHttpService.whenServiceListGawlotti(progSpool, licenseId, siaCode)
			.pipe(
				catchError((err: unknown) => {
					if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED)
						return of(null);

					this.sendMetadataError(true);
					return EMPTY;
				}),
				map(data => data.channels),
				tap((serviceList) => {
					if (Object.values(serviceList).length > 0) {
						this.sendMetadataError(false);
						this.sendCurrentService(serviceList[0]?.label.toUpperCase());
					}
				})
			);
	}

	private getProgSpool(elasticId: string): string {
		return elasticId.split('_')[0];
	}
	private getTipoCanale(elasticId: string): string {
		return elasticId.split('_')[1];
	}
}
