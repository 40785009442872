import { Injectable } from '@angular/core';
import { DocumentDetails, TagsUpdateRequest, TagsValue } from '@ctel/gaw-commons';
import { WorkItemCreationResponse, WorkflowHttpService, WorkflowVM } from '@ctel/gaw-workflow';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ActionsHttpService } from '../actions/actions-http.service';
import { ActionsService2 } from '../actions/actions.service';
import { SingleActionsCode } from '../actions/single-actions-catalog';
import { DocumentsHttpService } from '../documents-http.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';

/**
 * Servizio che gestisce lo stato dei dettagli del documento (elenco metadati)
 */
@Injectable({
	providedIn: 'root'
})
export class DocumentDetailsService {

	private documentDetails$ = new ReplaySubject<DocumentDetails>(1);
	private graphic$ = new ReplaySubject<string>(1);
	private documentId$ = new BehaviorSubject<string>('');
	private sectionCode$ = new BehaviorSubject<string>('');
	private docSeriesId$ = new BehaviorSubject<string>('');
	private realDocSeriesId$ = new BehaviorSubject<string>('');
	private tags$ = new ReplaySubject<string[]>(1);
	private keys$ = new BehaviorSubject<object>(null);
	private refresh$: BehaviorSubject<number> = new BehaviorSubject(0);

	constructor(
		private documentsHttpService: DocumentsHttpService,
		private actionsHttpService: ActionsHttpService,
		private actionsService: ActionsService2,
		private companiesService: CompaniesService,
		private workflowHttpService: WorkflowHttpService,
		private fullScreenSpinnerService : FullScreenSpinnerService
	) { }

	getDocumentsDetails(documentsKeys: { ctelElasticDocumentId: string; ctelDocSeriesId: string }[],
		includeTechnicalMetadata: boolean,
		includeDocumentMetadata: boolean):  Observable<DocumentDetails[]>{
			return this.documentsHttpService.getDocumentsDetails(documentsKeys,includeTechnicalMetadata,includeDocumentMetadata);
	}


	whenDocumentDetails(
		licenseId: string,
		siaCode: string,
		docSeriesId: string,
		elasticId: string,
		includeTechnical: boolean
	): Observable<DocumentDetails> {
		return this.documentsHttpService.whenDocumentDetails(licenseId, siaCode, docSeriesId, elasticId, includeTechnical)
			.pipe(
				tap(details => {
					const gawTags = details.tags.find(tagsArray => tagsArray.keyCode === 'gaw30_tags');
					if (gawTags !== undefined)
						this.tags$.next(gawTags.value);

					this.sendKeys(details.keys);
				})
			);
	}

	whenCurrentDocumentDetails(): Observable<DocumentDetails> {
		return this.documentDetails$.asObservable();
	}

	sendGraphic(graphic: string) {
		this.graphic$.next(graphic);
	}

	whenGraphicValue(): Observable<string> {
		return this.graphic$.asObservable();
	}

	whenGraphic(docSeriesId: string): Observable<string> {
		return this.documentsHttpService.whenGraphic(docSeriesId)
			.pipe(
				map(docSeriesInfo => docSeriesInfo.graphicsCode),
				tap(graphic => this.sendGraphic(graphic))
			);
	}

	sendDocumentDetails(details: DocumentDetails) {
		this.documentDetails$.next(details);
	}

	whenDocumentId(): Observable<string> {
		return this.documentId$.asObservable();
	}

	getDocumentId(): string {
		return this.documentId$.value;
	}

	sendDocumentId(documentId: string) {
		this.documentId$.next(documentId);
	}

	whenSectionCode(): Observable<string> {
		return this.sectionCode$.asObservable();
	}

	getSectionCode(): string {
		return this.sectionCode$.value;
	}

	sendSectionCode(sectionCode: string) {
		this.sectionCode$.next(sectionCode);
	}

	getDocSeriesId(): string {
		return this.docSeriesId$.value;
	}

	getRealDocSeriesId(): string {
		return this.realDocSeriesId$.value;
	}

	sendDocSeriesId(docSeriesId: string) {
		this.docSeriesId$.next(docSeriesId);
	}

	// doc series id reale del singolo documento quando sono su ALL e apro lista WF
	whenRealDocSeriesId(): Observable<string> {
		return this.realDocSeriesId$.asObservable();
	}

	sendRealDocSeriesId(docSeriesId: string) {
		this.realDocSeriesId$.next(docSeriesId);
	}

	getKeys(): object {
		return this.keys$.value;
	}

	sendKeys(keys: object) {
		this.keys$.next(keys);
	}

	// tags
	whenTags(): Observable<string[]> {
		return this.tags$.asObservable();
	}

	sendTags(tags: string[]) {
		this.tags$.next(
			tags.filter(function (elem, index, self) {
				return index === self.indexOf(elem);
			})
		);
		const payload = this.buildTagsUpdateRequest(tags);
		this.documentsHttpService.whenTagsUpdate(JSON.stringify(payload))
			.pipe(
				take(1),
				catchError(() => EMPTY)
			).subscribe();
	}

	resetTags() {
		this.tags$.next([]);
	}

	buildTagsUpdateRequest(tags: string[]): TagsUpdateRequest {
		const tagsValue: TagsValue = {
			value: tags,
			keyCode: 'gaw30_tags'
		};
		return {
			keys: this.getKeys(),
			metadataList: [tagsValue]
		};
	}

	public retrieveDocumentPdf(
		licenseId: string,
		siaCode: string,
		docSeriesId: string,
		sectionCode: string,
		elasticId: string
	): Observable<Blob> {

		const documentDetails$: Observable<DocumentDetails> = this.whenDocumentDetails(licenseId, siaCode, docSeriesId, elasticId, false);

		const actions$ = this.whenDocumentDetails(licenseId, siaCode, docSeriesId, elasticId, true).pipe(
			map(details => {
				const docKeys = details.keys;
				const hubfeSezione = details.metadataList.find(x => x.keyCode === 'hubfe_sezione');
				if (hubfeSezione)
					docKeys['hubfe_sezione'] = hubfeSezione.value;

				return docKeys;
			}),
			switchMap(keys => this.actionsService.requestSingleActionsConfig(licenseId, siaCode, sectionCode, keys))
		);

		return combineLatest([documentDetails$, actions$])
			.pipe(
				switchMap(([details, actions]) => {
					const progSpool = details.keys['progSpool'];
					const progBusta = details.keys['progBusta'];
					const hashDocKey = details.keys['hashDocKey'];
					const action = actions.find(actionArr => actionArr.code === SingleActionsCode.viewDocument);
					return this.actionsHttpService.whenPreviewSinglePdf(action.url, progSpool, progBusta, hashDocKey);
				}),
				take(1),
				map(result => result?.['body'])
			);
	}

	public retrieveDocumentXml(progSpool: string, progBusta: string): Observable<string> {
		return this.actionsHttpService.whenPreviewXml(progSpool, progBusta)
			.pipe(
				map(result => {
					this.fullScreenSpinnerService.hide();
					return result?.['body'];
				}),
				catchError(() => {
					this.fullScreenSpinnerService.hide();
					return EMPTY;
				})
			);
	}

	// dopo un'azione dispositiva nel dettaglio, serve aggiornamento dei details di magellano per avere le azioni aggiornate
	sendRefreshDocumentDetails() {
		this.refresh$.next(this.refresh$.getValue() + 1);
	}

	resetRefreshDocumentDetails() {
		this.refresh$.next(0);
	}

	whenRefreshDocumentDetails(): Observable<number> {
		return this.refresh$.asObservable();
	}

	// link form crea nuovo item
	whenCreateWorkItem(workflow: WorkflowVM): Observable<WorkItemCreationResponse> {
		return this.whenDocumentDetails(
			this.companiesService.getCurrentCompanyValue().licenseId,
			this.companiesService.getCurrentCompanyValue().siaCode,
			this.getRealDocSeriesId(),
			this.getDocumentId(),
			false
		).pipe(
			switchMap(details => {

				const properties = [
					{
						name: 'progSpool',
						value: details.keys['progSpool']
					},
					{
						name: 'progBusta',
						value: details.keys['progBusta']
					},
					{
						name: 'codSia',
						value: this.companiesService.getCurrentCompanyValue().siaCode
					}
				];

				for (let i = 0; i < details.metadataList.length; i++)
					properties.push(
						{
							name: details.metadataList[i].keyCode,
							value: details.metadataList[i].valueAsString
						});

				const body = {
					documentId: details.documentId,
					documentSeries: details.docSeriesId,
					folder: false,
					properties
				};

				return this.workflowHttpService.whenCreateWorkItem(
					this.companiesService.getCurrentCompanyValue().licenseId,
					workflow.name,
					body
				);
			})
		);
	}

}
