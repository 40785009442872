<div class='modal-body'>
	<div class='tipo-documento-list'>
		<ul>
			<li *ngFor='let t of fiscalYear; let i = index'>
				<input class='single-checkbox' type='radio' value='{{ t.key }}' id='chk-{{ t.key }}'
							 [checked]='t.key === selected' (change)='selected = t.key' />
				<label for='chk-{{ t.key }}'>{{t.key}} ({{+t.docCount | toLocaleString: false}})</label>
			</li>
		</ul>
	</div>
</div>
<div class='modal-footer'>
	<button class='btn btn-success'
					id='action-confirm-button'
					(click)='applyFilters()'><strong>Applica</strong>
	</button>
	<button type='button' class='btn btn-danger' (click)='modal.hide()'>Annulla</button>
</div>
