import { Component, Input } from '@angular/core';
import { IDetailsComponent } from '@ctel/gaw-commons';

@Component({
	selector: 'gaw-gawlotti-details-main',
	templateUrl: './gawlotti-details-main.component.html',
	styleUrls: ['./gawlotti-details-main.component.scss']
})
export class GAWLOTTIDetailsMainComponent  {
	@Input() data: IDetailsComponent;

}
