import { Injectable, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TransferService, TransferUtils } from '@ctel/transfer-manager';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { EnabledServicesService } from 'app/core/business/user/enabled-services/enabled-services.service';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { EventService } from 'app/core/common/event/event.service';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { DateRange } from 'app/core/common/utilities/date/date-range';
import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { Timezone } from 'app/core/common/utilities/timezone';
import { FieldType } from 'app/shared/components/dry/field';
import { Filters, Sort, Sorting } from 'app/shared/components/grid/interface/events';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, from, iif, merge, of } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, reduce, share, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ExportExcelSelectedItemPayload } from '../entities/action/export-excel-selected-item-payload';
import { OrderField, SearchField, SearchOperator, WorkItem, WorkItemDetail, WorkItemVM, WorkItemsRequest, WorkItemsResponse, WorkflowAndItemInfo } from '../entities/work-item/work-item';
import { ActionExecutePayload, WorkItemAction } from '../entities/work-item/work-item-action';
import { WorkItemsSearchBody } from '../entities/work-item/work-items-search-body';
import { Workflow, WorkflowProperty } from '../entities/workflow';
import { WorkflowEvent } from '../workflow-event';
import { WorkflowVM } from '../workflowVM';
import { WorkflowVmCount } from '../workflowVmCount';
import { WorkflowHttpService } from './workflow-http.service';

/**
 * Servizio che gestisce i workflows.
 */
@Injectable({
	providedIn: 'root'
})
export class WorkflowService implements OnDestroy {

	public readonly FIRST_PAGE_INDEX = 0;
	public readonly DEFAULT_ITEMS_PER_PAGE = 10;

	/**
		 * Indica se sono disponibili dei workflow per l'attuale azienda selezionata
		 * @type Observable<boolean>
		 * @readonly
		 */
	readonly hasWorkflow$: Observable<boolean>;

	/**
		 * Indica su quanti workflow è possibile creare un nuovo workitem
		 * in base alla proprietà del workflow "canCreateItems"
		 * @type Observable<number>
		 * @readonly
		 */
	// readonly canCreateItemsCount$: Observable<number>;

	/**
		 * Array del view-model del workflow contenente anche il totale degli items
		 * @type Observable<WorkflowVM[]>
		 * @readonly
		 */
	readonly workflows$: Observable<WorkflowVM[]>;

	/**
		 * Array del view-model dei workflow che possono creare nuovi workitems
		 * @type Observable<WorkflowVM[]>
		 * @readonly
		 */
	readonly workflowsWithCreateProperty$: Observable<WorkflowVM[]>;

	/**
		 * Array contenente la lista delle proprietà del workflow
		 * @type Observable<WorkflowProperty[]>
		 * @readonly
		 */
	readonly workFlowProperties$: Observable<WorkflowProperty[]>;

	/**
		 * Lista dei workitems
		 * @type Observable<WorkItem[]>
		 * @readonly
		 */
	readonly workItems$: Observable<WorkItemVM[]>;

	/**
		 * Total dei workitems filtrati
		 * @type Observable<number>
		 * @readonly
		 */
	readonly workItemsTotal$: Observable<number>;

	/**
		 * Workitem selezionato
		 * @type Observable<number>
		 * @readonly
		 */
	readonly selectedWorkItem$: Observable<WorkItem>;

	/**
		 * Array delle azioni disponibili per il workitem selezionato
		 * @type Observable<WorkItemAction[]>
		 * @readonly
		 */
	readonly workItemAction$: Observable<WorkItemAction[]>;

	public createWorkItemFormLink$ = new ReplaySubject<SafeUrl>(1);

	private readonly selectedWorkflow$: BehaviorSubject<WorkflowVM> = new BehaviorSubject<WorkflowVM>(undefined);
	private readonly currentWorkItemId$ = new BehaviorSubject<number>(undefined);

	private readonly workflowsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private readonly workItemsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private readonly filteredWorkItemsCount$: ReplaySubject<number> = new ReplaySubject<number>(1);

	private readonly isWorkItemDetailInMixedMode$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

	private workflowsByDocSeries$ = new ReplaySubject<Workflow[]>(1);
	private workflowFrameToggle$ = new BehaviorSubject<boolean>(false);
	private createWorkItem$ = new ReplaySubject<unknown>(1);
	private creationSuccess$ = new ReplaySubject<string>(1);

	/////////////////////////
	///  Filtri WorkItems ///
	////////////////////////
	private readonly pageIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(this.FIRST_PAGE_INDEX);
	private readonly pageDimension$: BehaviorSubject<number> = new BehaviorSubject<number>(this.DEFAULT_ITEMS_PER_PAGE);
	private readonly filters$: BehaviorSubject<Filters> = new BehaviorSubject([]);
	private readonly order$: BehaviorSubject<Sort> = new BehaviorSubject<Sort>(undefined);

	private readonly refreshWorkItems$: BehaviorSubject<number> = new BehaviorSubject(0);
	private readonly refreshCurrentWorkItem$: BehaviorSubject<number> = new BehaviorSubject(0);

	private destroy$ = new ReplaySubject<void>(1);

	// Eventi che scatenano il refreshWorkItem dell'item
	private readonly refreshWorkItemsEvents = [
		this.eventService.get(WorkflowEvent.E_MODAL_CLOSE),
		this.eventService.get(WorkflowEvent.E_ACTION_COMPLETED),
		this.eventService.get(WorkflowEvent.E_NEW_WORKITEM)
	];

	private readonly refreshActualWorkItemEvents = [
		this.eventService.get(WorkflowEvent.E_MODAL_CLOSE),
		this.eventService.get(WorkflowEvent.E_ACTION_COMPLETED)
	];
	constructor(
		private workflowHttpService: WorkflowHttpService,
		private companiesService: CompaniesService,
		private eventService: EventService,
		private notificationService: NotificationService,
		private domSanitizer: DomSanitizer,
		private router: Router,
		private enabledServicesService: EnabledServicesService,
		private transferService: TransferService
	) {
		// Gestisco tutte le casistiche di refresh
		this.refreshEvents();

		this.hasWorkflow$ = this.companiesService.whenCurrentCompany()
			.pipe(
				switchMap(company =>
					combineLatest([
						this.workflowHttpService.hasWorkflow(company.licenseId, company.siaCode),
						this.enabledServicesService.isEnabledService('GAW30')
					]).pipe(
						map(([hasWorkflow, enabledService]) => hasWorkflow && !!enabledService)
					)
				),
				share({ connector: () => new ReplaySubject<boolean>(1) })
			);

		this.workflows$ = this.getWorkflowList().pipe(
			share({ connector: () => new ReplaySubject<WorkflowVM[]>(1) })
		);

		this.workflowsWithCreateProperty$ = this.workflows$
			.pipe(
				map(wfs => wfs.filter(wf => wf.canCreateItems === true && wf.standaloneCreation === true)),
				map(list => {
					// Raggruppo gli accordion per wkfName e visualizzo solo il primo
					const wkfNew: WorkflowVM[] = [];
					list.forEach((accordion: WorkflowVM) => {
						if (
							wkfNew.find(
								el => el.workflowName === accordion.workflowName || el.workflowName === accordion.workflowSectionName
							) === undefined
						)
							wkfNew.push(accordion);

					});
					return wkfNew;
				})
			);

		this.workFlowProperties$ = this.selectedWorkflow$
			.pipe(
				switchMap(wf =>
					iif(() => !wf,
						of([]),
						of(wf).pipe(
							map(() => wf.properties)
						))
				)
			);

		const workItemResponse$ = this.refreshWorkItems$
			.pipe(
				switchMap(() => this.selectedWorkflow$),
				switchMap(wf => iif(() => !wf,
					of({ total: 0, workItems: [] }),
					of(wf)
						.pipe(
							switchMap(() => combineLatest([this.pageIndex$, this.pageDimension$, this.filters$, this.order$])),
							debounceTime(150),
							tap(() => this.workItemsLoading$.next(true)),
							switchMap(([page, dimension, filters, sort]) => this.workItemRequest(wf, page, dimension, filters, sort)
								.pipe(
									take(1),
									tap(res => {
										// Se sono applicati filtri, prendo il totale e lo inserisco nei totali workitems filtrati
										if (this.isFormFilled(filters))
											this.filteredWorkItemsCount$.next(res.total);
										else
											this.filteredWorkItemsCount$.next(undefined);

										// Se ottengo zero elementi e il pageIndex è maggiore di 1, riduco il pageIndex
										if (page > this.FIRST_PAGE_INDEX && res.total === 0)
											this.sendCurrentPage(this.pageIndex$.getValue() - 1);

									})
								)
							),
							// Notifico al subject che i workitem hanno finito di caricare
							tap({
								next: () => this.workItemsLoading$.next(false),
								error: () => this.workItemsLoading$.next(false)
							})
						)
				)),
				share({ connector: () => new ReplaySubject<WorkItemsResponse>(1) })
			);

		this.workItems$ = combineLatest([workItemResponse$, this.selectedWorkflow$])
			.pipe(
				// Aggiungere get delle config
				map(([results, wkf]) => results.workItems.map(item => {

					let showAlert = false;
					let dataCreazione = undefined;
					if (wkf.expiredItemDate) {
						dataCreazione = item.properties.find(i => i.name === 'dataFattura');
						if (!dataCreazione)
							dataCreazione = item.properties.find(i => i.name === 'dataInserimento');

						showAlert = !!dataCreazione &&
							moment(dataCreazione.value).add(wkf.expiredItemDate, 'days').isSameOrBefore(moment());
					}

					return {
						...item,
						showAlert
					};
				}))
			);

		this.workItemsTotal$ = workItemResponse$
			.pipe(
				map(res => res.total)
			);

		this.selectedWorkItem$ = this.refreshCurrentWorkItem$.pipe(
			// ignoreElements(),
			switchMap(() => this.currentWorkItemId$),
			switchMap(id =>
				iif(() => id === undefined,
					of(undefined),
					this.selectedWorkflow$.pipe(
						filter(wf => wf !== undefined),
						switchMap(wf =>
							this.getWorkItem(wf.workflowName, id.toString())
						)
					)
				)
			),
			share({ connector: () => new ReplaySubject<WorkItem>(1) })
		);

		this.workItemAction$ = combineLatest([this.companiesService.whenCurrentCompany(), this.selectedWorkItem$])
			.pipe(
				switchMap(([company, workItems]) =>
					// Se il workitems non è presente ritorno un array vuoto, altrimenti faccio la request per le azioni
					iif(() => !workItems,
						of([]),
						of(workItems).pipe(
							switchMap(wi => this.workflowHttpService.whenWorkItemActions(company.licenseId, wi.workflowName, String(wi.id)))
						)
					)
				)
			);
	}

	whenFilteredItemsCount(): Observable<number> {
		return this.filteredWorkItemsCount$.asObservable();
	}

	/**
		 * Ritorna il workflow selezionato
		 */
	whenSelectedWorkflow(): Observable<WorkflowVM> {
		return this.selectedWorkflow$.asObservable();
	}

	getSelectedWorkflow(): WorkflowVM {
		return this.selectedWorkflow$.value;
	}

	/**
		 * @return Observable<boolean> Ritorna true o false nel caso in cui i workItems stanno caricando
		 */
	areWorkItemsLoading(): Observable<boolean> {
		return this.workItemsLoading$.asObservable();
	}

	/**
		 * @return Observable<boolean> Ritorna true o false nel caso in cui i workflows stanno caricando
		 */
	areWorkflowsLoading(): Observable<boolean> {
		return this.workflowsLoading$.asObservable();
	}

	/**
		 * Esporta excel massivo di tutti gli elementi filtrati
		 * @return Observable<Blob>
		 */
	exportMassiveExcel(): Observable<Blob> {
		return combineLatest([this.companiesService.whenCurrentCompany(), this.selectedWorkflow$, this.filters$, this.order$])
			.pipe(
				take(1),
				switchMap(([company, wf, filters, sort]) =>
					this.workflowHttpService.exportExcel(
						company.licenseId,
						company.siaCode,
						wf.workflowSectionName,
						wf.docSeries,
						<WorkItemsSearchBody>{
							orderFields: this.buildSortField(sort),
							returnArchived: false,
							returnProperties: true,
							searchFields: this.buildSearchFields(filters),
							selectFields: this.buildSelectField(wf)
						},
						Timezone.getTimezone())
						.pipe(
							tap(
								result => {
									const blob = new Blob(
										[result],
										{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
									);
									saveAs(blob, wf.workflowSectionName + '.xlsx');
								}
							)
						)
				)
			);
	}

	/** Esporta excel dei workitem selezionati
		 * @return Observable<Transfer>
		 */
	expertExcelSelectedItem(selectedItem: Array<number>) {
		return combineLatest([this.companiesService.whenCurrentCompany(), this.selectedWorkflow$, this.filters$, this.order$])
			.pipe(
				take(1),
				switchMap(([company, wf, filters, sort]) =>
					this.workflowHttpService.exportExcelSelectedItem(
						company.licenseId,
						company.siaCode,
						wf.workflowSectionName,
						wf.docSeries,
						<ExportExcelSelectedItemPayload>{
							orderFields: this.buildSortField(sort),
							returnArchived: false,
							returnProperties: true,
							searchFields: this.buildSearchFields(filters),
							selectFields: this.buildSelectField(wf),
							requestedIds: selectedItem
						},
						Timezone.getTimezone())
						.pipe(
							tap(
								result => {
									if (TransferUtils.isHttpResponse(result.originatingEvent)) {
										const fileName = wf.workflowSectionName + '.xlsx';
										this.transferService.updateTransfer(result.key, fileName, FileSaverExtension.XLSX);
										const blob = new Blob(
											[result.originatingEvent.body],
											{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
										);
										saveAs(blob, fileName);
									}
								}
							)
						)
				)
			);
	}

	// quando aggiorno il workflow selezionato, faccio già la richiesta dei relativi work items e aggiorno l'eventuale link di creazione
	// item
	sendSelectedWorkflow(workflow: WorkflowVM) {
		this.selectedWorkflow$.next(workflow);

		// TODO: Rifattorizzare
		if (workflow)
			if (workflow.itemCreationLink)
				this.workflowHttpService.whenWorkItemCreationForm(workflow.itemCreationLink)
					.pipe(take(1))
					.subscribe(formLink =>
						this.sendCreateWorkItemFormLink(this.workflowHttpService.getBeFlowHost() + '/ctel/' + formLink.link)
					);
	}

	/**
		 * Indica il WI selezionato
		 * @param id
		 */
	sendCurrentWorkItemId(id: number) {
		this.currentWorkItemId$.next(id);
	}

	/**
		 * Indica il mode della modal di dettaglio WI in base al WF selezionato
		 * @param mixedMode
		 */
	sendMixedMode(mixedMode: boolean) {
		this.isWorkItemDetailInMixedMode$.next(mixedMode);
	}

	whenMixedMode(): Observable<boolean> {
		return this.isWorkItemDetailInMixedMode$.asObservable();
	}

	getBEHost() {
		return this.workflowHttpService.getBeFlowHost();
	}

	// LISTA WORKFLOW FILTRATI PER DOC SERIES
	whenWorkflowsByDocSeries(licenseId: string, docSeriesId: string): Observable<Workflow[]> {
		// this.workflowHttpService.whenWorkflowsByDocSeries(licenseId, 'GAW_SERIES_TEST')
		return this.workflowHttpService.whenWorkflowsByDocSeries(licenseId, docSeriesId)
			.pipe(
				catchError((err: unknown) => {
					if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
						//this.router.navigate(['/unauthorized']).then();
					}
					return of([] as Workflow[]);
				})
			);
	}

	/**
		 * Lista delle azioni disponibili per un workitem
		 * @param workItem
		 * @return Observable<WorkItemAction[]>
		 */
	whenWorkItemActions(workItem: WorkItem): Observable<WorkItemAction[]> {
		return this.companiesService.whenCurrentCompany()
			.pipe(
				switchMap(company =>
					this.workflowHttpService.whenWorkItemActions(company.licenseId, workItem.workflowName, String(workItem.id))
				)
			);
	}

	/**
		 * Ritorna il dettaglio di un workitem
		 * @param licenseId
		 * @param siaCode
		 * @param workflowName
		 * @param workItemId
		 * @return Observable<WorkItemDetail[]>
		 */
	getWorkItemDetails(licenseId: string, siaCode: string, workflowName: string, workItemId: string): Observable<WorkItemDetail[]> {
		return this.workflowHttpService.getWorkItemDetails(licenseId, siaCode, workflowName, workItemId);
	}

	// WORK ITEM CREATO
	sendCreationSuccess(value: string) {
		this.creationSuccess$.next(value);
	}

	whenCreationSuccess(): Observable<string> {
		return this.creationSuccess$.asObservable();
	}

	// ESEGUI AZIONE DIRETTA
	executeAction(action: WorkItemAction, returnBody: boolean) {
		return this.selectedWorkItem$
			.pipe(
				map(wi => <ActionExecutePayload>{
					actionName: action.name,
					properties: wi.properties
				}),
				switchMap(payload =>
					this.companiesService.whenCurrentCompany().pipe(
						take(1),
						switchMap(company =>
							this.workflowHttpService.whenWorkItemActionExecute(company.licenseId, action.actionURL, payload, returnBody)
						)
					)
				),
				take(1)
			);
	}

	// ------------------------------------------------------------------------------------------------

	// FORM LINK PREVIEW WORK ITEM
	retrievePreviewFormLink(wfInfo: WorkflowAndItemInfo): Observable<string> {
		return this.workflowHttpService.whenWorkItemPreviewLink(
			this.companiesService.getCurrentCompanyValue().licenseId,
			wfInfo.workflowName,
			'' + wfInfo.workitemId, wfInfo.workItemStage
		)
			.pipe(
				map(formLink => this.workflowHttpService.getBeFlowHost() + '/ctel/' + formLink.link
				)
			);
	}

	// recupera il link del form di un'azione indiretta
	retrieveFormLink(action: WorkItemAction): Observable<string> {
		return this.workflowHttpService.whenWorkItemActionForm(action.actionURL)
			.pipe(
				map(formLink => this.workflowHttpService.getBeFlowHost() + '/ctel/' + formLink.link)
			);
	}

	// ------------------------------------------------------------------------------------------------

	// link form crea nuovo item
	whenCreateWorkItemFormLink(): Observable<SafeUrl> {
		return this.createWorkItemFormLink$.asObservable();
	}

	sendCreateWorkItemFormLink(link: string) {
		const safeUrl = this.domSanitizer.bypassSecurityTrustUrl(link);
		this.createWorkItemFormLink$.next(safeUrl);
	}

	// -------------------------------------------
	// CREAZIONE DIRETTA WORK ITEM

	sendCreateWorkItem(value: unknown) {
		this.createWorkItem$.next(value);
	}

	// GESTIONE TOGGLE ACCORDION WF
	whenWorkflowFrameToggle(): Observable<boolean> {
		return this.workflowFrameToggle$.asObservable();
	}

	// ------------------------------------------------------------------------------------------------

	sendWorkflowFrameToggle(frameOpen: boolean) {
		this.workflowFrameToggle$.next(frameOpen);
	}

	/**
		 * Forza il refreshWorkItems dei workItem
		 */
	public refreshWorkItems() {
		this.refreshWorkItems$.next(this.refreshWorkItems$.getValue() + 1);
	}

	// private refreshActualWorkItem() {
	// 	// Aggiorno il currentWorkitem
	//
	// 	this.workflowHttpService.whenWorkItem()
	// }

	/**
		 * Setta la pagina visualizzata
		 * @param page
		 */
	public sendCurrentPage(page: number) {
		this.pageIndex$.next(page);
	}

	public whenCurrentPage(): Observable<number> {
		return this.pageIndex$.asObservable();
	}

	/**
		 * Setta i filtri di ricerca
		 * @param filters Array dei filtriò
		 */
	public sendFilters(filters: Filters) {
		this.filters$.next(filters);
	}

	public whenFilters(){
		return this.filters$.asObservable();
	}

	/**
		 * Setta gli elementi visualizzati per pagina
		 * @param dimension Elementi visualizzati per pagina
		 */
	public sendPageDimension(dimension: number) {
		this.pageDimension$.next(dimension);
	}

	/**
		 * Setta l'ordinamento per i workItems
		 * @param order
		 */
	public sendOrder(order: Sort) {
		this.order$.next(order);
	}

	/**
		 * Resetta ai valori di default i filtri e la paginazione per la ricerda dei workitems:
		 */
	resetFilters(): void {
		this.sendFilters([]);
		this.sendCurrentPage(this.FIRST_PAGE_INDEX);
		this.sendPageDimension(this.DEFAULT_ITEMS_PER_PAGE);
		this.sendCurrentWorkItemId(undefined);
		this.sendMixedMode(undefined);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	getWorkItem(workflowName: string, workItemId: string): Observable<WorkItem> {
		return this.companiesService.whenCurrentCompany().pipe(
			switchMap(company => this.workflowHttpService.whenWorkItem(company.licenseId, workflowName, workItemId))
		);
	}

	public workItemsCount(workFlows: WorkflowVmCount[]): Observable<WorkflowVmCount[]> {
		return this.companiesService.whenCurrentCompany().pipe(
			// Notifico al subject che i workflows stanno caricando
			tap(() => this.workflowsLoading$.next(true)),
			switchMap((company) => this.refreshWorkItems$
				.pipe(
					// Creo uno stream dalla lista di workflow
					mergeMap(() => from(workFlows)
						.pipe(
							// Memorizzo l'ordine dei workflow tramite la proprietà index
							mergeMap((wf, index) => this.workflowHttpService.getWorkItemsCount(
								company.licenseId,
								company.siaCode,
								wf.workflowSectionName,
								<WorkItemsSearchBody>{
									orderFields: [],
									returnArchived: false,
									returnProperties: false,
									searchFields: [],
									selectFields: []
								})
								.pipe(
									take(1),
									map(count => ({
										order: index,
										value: new WorkflowVmCount(wf, count)
									}))
								)),
							// Riduco a un array
							reduce<{ order: number, value: WorkflowVmCount }, { order: number, value: WorkflowVmCount }[]>(
								(acc, val) => [...acc, val], []
							),
							// Faccio il sort dell'array e ritorno solo la proprietà value contenente il workflow
							map(values => values.sort((a, b) => a.order - b.order).map(v => v.value))
						)
					)
				)
			)
		);
	}

	private workItemRequest(
		workflow: Workflow,
		pageIndex: number,
		pageSize: number,
		filters?: Filters,
		sort?: Sort
	): Observable<WorkItemsResponse> {

		const workItemsRequest: WorkItemsRequest = {
			returnArchived: false,
			returnProperties: true,
			selectFields: this.buildSelectField(workflow),
			searchFields: this.buildSearchFields(filters),
			orderFields: this.buildSortField(sort)
		};

		return this.workflowHttpService.getWorkItemsList(
			this.companiesService.getCurrentCompanyValue().licenseId,
			this.companiesService.getCurrentCompanyValue().siaCode,
			workflow.workflowSectionName,
			workItemsRequest,
			pageIndex,
			pageSize
		);
	}

	private buildSelectField(workflow: Workflow) {
		return workflow.properties.map(prop => ({ 'column': prop.name }));
	}

	private buildSortField(sort?: Sort) {
		const orderField: Array<OrderField> = [];

		if (sort && sort.order !== Sorting.NOT_SET)
			orderField.push({
				orderProperty: sort.columnId,
				orderType: sort.order === Sorting.ASCENDING ? 'Ascending' : 'Descending'
			});

		return orderField;
	}

	private buildSearchFields(searchValues: Filters): SearchField[] {
		const searchFields: SearchField[] = [
			// {
			// 	property: 'codSia',
			// 	searchOperator: SearchOperator.EQUALS,
			// 	value: this.companiesService.getCurrentCompanyValue().siaCode
			// }
		];

		if (searchValues)
			// Per ogni filtro di ricerca
			searchValues.forEach(search => {

				// Controllo che il valore non sia null, undefined o stringa vuota
				if (search.value) {

					let searchField: SearchField;

					switch (search.column.field.type) {

						case FieldType.DATE: {
							if (!(search.value instanceof DateRange)) break;

							// Setto il time al minimo per data start
							const dataStart = moment(search.value.getStart()).set({
								hour: 0,
								minute: 0,
								second: 0,
								millisecond: 0
							});
							// Setto il time al minimo per il giorno dopo di data end
							const dataEnd = moment(search.value.getEnd()).set({
								hour: 0,
								minute: 0,
								second: 0,
								millisecond: 0
							}).add(1, 'd');

							// Filtro per range di date
							searchField = <SearchField>{
								property: search.column.field.id,
								searchOperator: SearchOperator.GREATER_OR_EQUAL_THAN,
								value: dataStart.toISOString(false)
							};

							searchFields.push(<SearchField>{
								property: search.column.field.id,
								searchOperator: SearchOperator.LESS_THAN,
								value: dataEnd.toISOString(false)
							});
							break;
						}
						case FieldType.TEXT:
							if (typeof search.value !== 'string') break;

							searchField = <SearchField>{
								property: search.column.field.id,
								searchOperator: SearchOperator.LIKE,
								value: '%' + search.value.trim() + '%'
							};
							break;
						case FieldType.NUMBER:
						case FieldType.DECIMAL:
							searchField = <SearchField>{
								property: search.column.field.id,
								searchOperator: SearchOperator.EQUALS,
								value: search.value
							};
							break;
						default:
							searchField = <SearchField>{
								property: search.column.field.id,
								searchOperator: SearchOperator.LIKE,
								value: '%' + search.value + '%'
							};
					}
					searchFields.push(searchField);
				}
			});

		return searchFields;
	}

	private getWorkflowList(): Observable<WorkflowVM[]> {
		return this.companiesService.whenCurrentCompany()
			.pipe(
				// Notifico al subject che i workflows stanno caricando
				tap(() => this.workflowsLoading$.next(true)),
				switchMap((company) => this.workflowHttpService.getWorkflows(company.licenseId, company.siaCode)
					.pipe(
						take(1),
						// Mi collego al subject di refresh dei workItems
						switchMap(wfs => this.refreshWorkItems$
							.pipe(
								// Creo uno stream dalla lista di workflow
								mergeMap(() => from(wfs)
									.pipe(
										map((wf, index) => ({
											order: index,
											value: new WorkflowVM(wf)
										})),
										// Riduco a un array
										reduce<{ order: number, value: WorkflowVM }, { order: number, value: WorkflowVM }[]>(
											(acc, val) => [...acc, val], []
										),
										// Faccio il sort dell'array e ritorno solo la proprietà value contenente il workflow
										map(values => values.sort((a, b) => a.order - b.order).map(v => v.value))
									)
								)
							)
						)
					)
				),
				// In caso di chiamata terminata o errore dico al subject che ho finito di caricare
				tap({
					next: () => this.workflowsLoading$.next(false),
					error: () => this.workflowsLoading$.next(false)
				}),
				takeUntil(this.destroy$)
			);
	}

	private isFormFilled(filters: Filters): boolean {
		return filters.find(f => f.value !== null && f.value !== '') !== undefined;
	}

	private refreshEvents() {
		// Eventi di aggiornamento dei workitems
		merge(...this.refreshWorkItemsEvents)
			.pipe(
				debounceTime(500),
				takeUntil(this.destroy$)
			).subscribe(() => this.refreshWorkItems());

		merge(...this.refreshActualWorkItemEvents)
			.pipe(
				debounceTime(500),
				takeUntil(this.destroy$)
			).subscribe(() => this.refreshCurrentWorkItem$.next(this.refreshCurrentWorkItem$.getValue() + 1));

		// Al cambio di azienda resetto il selectedWorkflow
		this.companiesService.whenCurrentCompany()
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.sendSelectedWorkflow(undefined));

		// Al cambio di workflow risetto i filtri
		this.whenSelectedWorkflow()
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.resetFilters();
				// Azzero il totale dei workitem filtrati
				this.filteredWorkItemsCount$.next(undefined);
			});

		// Al cambio di workflow, pagina, elementi per pagina, filtri e ordinamento
		merge(this.whenSelectedWorkflow(), this.pageIndex$, this.pageDimension$, this.filters$, this.order$)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				// Setto undefined il workitems selezionato
				this.sendCurrentWorkItemId(undefined);
				this.sendMixedMode(undefined);
			});
	}

}
