import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ErrorMessage } from 'app/entities/errors/errors';
import { CustomValidationError } from 'app/shared/components/dry/validator/custom-validation-error';
import * as moment from 'moment';

export class DateValidators {

	/**
	 * Validatore che controlla se il valore è una data valida
	 * @param format Indica il formato con cui comparare la data
	 * @param strict Indica se il controllo deve essere restringente per il formato
	 * @return ValidatorFn
	 */
	public static isDate(format?: string, strict = true): ValidatorFn {
		return (control: AbstractControl): { [key: string]: ErrorMessage } => {
			if (control.value && control.value !== '') {
				const date = format ? moment(control.value, format, strict) : moment(control.value);
				return date.isValid() ? null : <CustomValidationError>{
					'date': {
						message: 'Non risulta una data valida'
					}
				};
			}
			return null;
		};
	}

	/**
	 * Validatore che controlla se il valore della  data è maggiore del valore massimo
	 * @return ValidatorFn
	 */
	public static maxDate(maxValue: string | Date | moment.Moment): ValidatorFn {
		return (control: AbstractControl): { [key: string]: ErrorMessage } => {

			if (!maxValue)
				return null;

			if (control.value && control.value !== '') {

				const max = moment(maxValue).utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
				const value = moment(control.value).utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
				const duration = moment.duration(max.diff(value)).asHours();

				return duration >= 0 ? null : <CustomValidationError>{
					'maxDate': {
						message: `Supera il valore massimo ${maxValue}`
					}
				};
			}
			return null;
		};
	}

	/**
	 * Validatore che controlla se la data è formattata correttamente
	 * @return ValidatorFn
	 */
	public static dateFormat(format: string): ValidatorFn {
		return (control: AbstractControl): { [key: string]: ErrorMessage } => {
			if (!format)
				return null;

			if (control.value && control.value !== '')
				return moment(control.value, format).format(format) === control.value ? null : <CustomValidationError>{
					'dateFormat': {
						message: `Il formato non è valido, è richiesto un formato ${format}`
					}
				};

			return null;
		};
	}
}
