import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionsHttpService } from '../actions-http.service';

@Injectable({
	providedIn: 'root'
})
export class SingleDocumentCallback {
	constructor(
		private actionsHttpService: ActionsHttpService,
		private router: Router
	) { }

}
