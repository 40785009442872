import { Injectable, OnDestroy } from '@angular/core';
import { EnabledServiceEndpoint } from 'app/core/business/user/enabled-services/enabled-service';
import { EnabledServicesHttpService } from 'app/core/business/user/enabled-services/enabled-services-http.service';
import { SearchContext } from 'app/entities/search-context/search-context';
import { Service } from 'app/entities/services/service.interface';
import { Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { catchError, switchMap, take, takeUntil } from 'rxjs/operators';
import { SearchContextHttpService } from './search-context-http.service';

const ALL_SERVICES: EnabledServiceEndpoint & Service = {
	uI_CODE: 'GAW',
	descrizionE_AREA: 'Tutti i Servizi',
	code: undefined,
	description: undefined
};

@Injectable({
	providedIn: 'root',
})
export class SearchContextService implements OnDestroy {
	private enabledSearchArea$ = new Subject<EnabledServiceEndpoint[]>();
	private searchContext$ = new ReplaySubject<SearchContext[]>(1);
	private selectedSearchArea$ = new ReplaySubject<EnabledServiceEndpoint>(1);

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private searchContextHttpService: SearchContextHttpService,
		private enabledServicesHttpService: EnabledServicesHttpService
	) { }

	whenEnabledSearchArea(): Observable<EnabledServiceEndpoint[]> {
		return this.enabledSearchArea$.asObservable();
	}

	sendEnabledSearchArea(value: EnabledServiceEndpoint[]) {
		this.enabledSearchArea$.next(value);
	}

	getEnabledSearchAreaList(licenseId: string, siaCode: string) {
		this.searchContextHttpService
			.getEnabledSearchArea(licenseId, siaCode)
			.pipe(
				catchError(() => {
					this.sendEnabledSearchArea([]);
					return of([]);
				}),
				switchMap((searchArea: EnabledServiceEndpoint[]) =>
					combineLatest([
						of(searchArea),
						this.enabledServicesHttpService.getServiceConfiguration().pipe(take(1))
					])
				),
				takeUntil(this.destroy$)
			)
			.subscribe(([searchArea, serviziConfigurati]) => {
				// faccio merge dei servizi del documento con la configurazione dei servizi contenente l'attributo order
				const serviceEndpoints = searchArea.map((item) => {
					const obj = Object.assign({}, item, serviziConfigurati.find(sc => sc.code === item['uI_CODE']));
					if (!obj.order) obj.order = 999;
					return obj;
				});

				// ordino i servizi in base all'attributo order
				const ordered = serviceEndpoints.sort((a, b) => (a.order && b.order && a.order > b.order) ? 1 : -1);

				if (ordered.length > 1 && ordered.find((a) => a.serviziO_ID === 'GAW30'))
					ordered.push(ALL_SERVICES);

				this.sendEnabledSearchArea(ordered);
			});
	}

	setAllServicesSearchArea() {
		this.selectSearchArea(ALL_SERVICES);
	}

	selectSearchArea(area: EnabledServiceEndpoint) {
		this.selectedSearchArea$.next(area);
	}

	getSelectSearchArea() {
		return this.selectedSearchArea$.asObservable();
	}

	whenSearchContext(): Observable<SearchContext[]> {
		return this.searchContext$.asObservable();
	}

	sendSearchContext(value: SearchContext[]) {
		this.searchContext$.next(value);
	}

	getSearchContextList(licenseId, siaCode, uiCode) {
		this.searchContextHttpService
			.getSearchContext(licenseId, siaCode, uiCode)
			.pipe(takeUntil(this.destroy$))
			.subscribe((searchContext) => {
				this.sendSearchContext(searchContext);
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
