import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutsModule } from 'app/shared/layouts/layouts.module';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		LayoutsModule
	],
	declarations: [
		ForbiddenComponent,
		ErrorPageComponent,
		NotFoundComponent
	],
	exports: [
		ForbiddenComponent,
	]
})
export class ErrorModule {
}
