<div class='row mx-0 px-3 w-100'>
	<div class='col-md-10'>
		<div class='details-title' *ngIf='documentDetails$ | async as documentDetails'>
			<span class='badge doctype-label'
						[ngStyle]="{ 'background-color': getColor(documentDetails.keys['descTipoDocumento']) }">
				<span class='badge-text'>Archivio Fiscale</span>
			</span>

			<div class='t nd'>{{ getDocLabel(documentDetails.metadataList) }}</div>
		</div>
		<gaw-archivio-fiscale-detail-main [data]='data' [action]='downloadAction'></gaw-archivio-fiscale-detail-main>
	</div>
	<div class='col-md-2'>
		<ng-container *ngIf='singleActionsList$ | async as actions'>
			<ng-container *ngIf='documentDetails$ | async as documentDetails'>
				<h4 class='action px-0'>Azioni</h4>
				<div class='container1 results-content__actions_sidebars d-grid gap-2'>
					<ng-container *ngIf="documentDetails$ | async as documentDetails">
						<ng-container *ngFor="let action of actions">
							<button
											*ngIf="
												action.code !== singleActionsCode.openDetail
												&& action.code !== singleActionsCode.openPreviousVersions
											"
											class="btn btn-primary btn-action"
											[ngClass]="{ yellow: action.highlighted === true }"
											type="button"
											(click)="executeAction(action, documentDetails.keys)">
								<i class="btn-action-sidebar" [ngClass]="[action.icon]" aria-hidden="true"></i>
								{{ action.name }}
							</button>
						</ng-container>
					</ng-container>
				</div>
			</ng-container>
		</ng-container>
	</div>
</div>
