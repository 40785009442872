<form novalidate (ngSubmit)="onSubmit(f)" #f="ngForm">
  <div class="modal-body">
    <div class="main-div">
      <div class="dropdown">
        <div class="dropdown-title mb-2"></div>
        <label class="form-label" for="extensionSelect">
          Scegli estensione:
          <select [(ngModel)]="extension" class="form-control" name="ext" id="extensionSelect">
            <option *ngFor="let ext of excelExtensions" [value]="ext" [attr.selected]="extension === ext ? true : null">
              {{ ext }}
            </option>
          </select>
        </label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" id="excel-format-confirm-button">Conferma</button>
    <button type="button" class="btn btn-danger" *ngIf="data.backButton" (click)="modal.hide()">Annulla</button>
  </div>
</form>
