import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, User, UserService } from '@ctel/auth';
import { NavigationService } from 'app/core/business/navigation/navigation.service';
import { Navigation } from 'app/entities/navigation/navigation';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

/**
 * Componente che rappresenta la sidebar
 */
@Component({
	selector: 'gaw-sidebar',
	templateUrl: 'sidebar.component.html',
	styleUrls: ['sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
	public user$: Observable<User>;
	public navigation$: Observable<Navigation[]>;
	public globalNavigation$: Observable<Navigation[]>;
	public navigationActions$: Observable<Navigation[]>;

	public navigationHelp: Array<Navigation> = [
		{
			description: 'FAQ, Release Notes, Contatti',
			url: '/help/faq',
			img: '/assets/icon/solid/circle/misc-sos-white.svg',
			isExternalLink: false,
			isEmbeddedLink: true,
			isNavigationHeader: false,
			children: [],
			hasLogo: true,
		},
	];

	step: number;

	public showCreateNewDocument: boolean;

	private destroy$ = new Subject<void>();

	constructor(
		private navigationService: NavigationService,
		private userService: UserService,
		private authService: AuthService,
		private router: Router
	) {
		this.user$ = this.userService.getUser();
	}

	ngOnInit(): void {
		this.globalNavigation$ = this.navigationService.whenNavigation().pipe(takeUntil(this.destroy$));
		this.navigation$ = this.globalNavigation$.pipe(
			map((arrayNav) =>
				/* TODO inserire gli altri loghi man mano che vengono aggiunti i servizi relativi,
				 * si potrebbe inserire un sistema centralizzato per legarli ai servizi (magari su EnabledServices)
				 */

				arrayNav
					.filter((value) => value.categoria === 'Servizi')
					.map((navObj) => {
						switch (navObj.url) {
							case '/gawfe': {
								navObj.img = '/assets/images/loghiWhite/gaw-icone-48x48_GawFe.svg';
								navObj.hasLogo = true; // utile a non visualizzare la description
								break;
							}
							case '/gawpec': {
								navObj.img = '/assets/images/loghiWhite/gaw-icone-48x48_GawPec.svg';
								navObj.hasLogo = true; // utile a non visualizzare la description
								break;
							}
							case '/gawpost': {
								navObj.img = '/assets/images/loghiWhite/gaw-icone-48x48_GawPost.svg';
								navObj.hasLogo = true; // utile a non visualizzare la description
								break;
							}
							case '/gawedi': {
								navObj.img = '/assets/images/loghiWhite/gaw-icone-48x48_GawEdi.svg';
								navObj.hasLogo = true; // utile a non visualizzare la description
								break;
							}
							case '/gawnotice': {
								navObj.img = '/assets/images/loghiWhite/gaw-icone-48x48_GawNotice.svg';
								navObj.hasLogo = true; // utile a non visualizzare la description
								break;
							}
							case '/gawpass': {
								navObj.img = '/assets/images/loghiWhite/gaw-icone-48x48_GawPass.svg';
								navObj.hasLogo = true; // utile a non visualizzare la description
								break;
							}
							case '/gawmail': {
								navObj.img = '/assets/images/loghiWhite/gaw-icone-48x48_GawMail.svg';
								navObj.hasLogo = true; // utile a non visualizzare la description
								break;
							}
							case '/gawbi': {
								navObj.img = '/assets/images/loghiWhite/gaw-icone-48x48_GawBI.svg';
								navObj.hasLogo = true; // utile a non visualizzare la description
								break;
							}
							case '/gawcons': {
								navObj.img = '/assets/images/loghiWhite/gaw-icone-48x48_GawCons.svg';
								navObj.hasLogo = true;
								break;
							}
							case '/gawcyber': {
								navObj.img = '/assets/images/loghiWhite/gaw-icone-48x48_GawCyb.svg';
								navObj.hasLogo = true;
								break;
							}
						}
						if (navObj.url === '/gaw30') {
							navObj.img = '/assets/images/loghiWhite/gaw-icone-48x48_Gaw30.svg';
							navObj.hasLogo = true; // utile a non visualizzare la description
						}
						if (navObj.url.includes('gaw-es')) {
							navObj.img = '/assets/images/loghiWhite/gaw-icone-48x48_GawEs.svg';
							navObj.hasLogo = true; // utile a non visualizzare la description
						}
						if (navObj.url === '/gawpec') {
							navObj.img = '/assets/images/loghiWhite/gaw-icone-48x48_GawPec.svg';
							navObj.hasLogo = true; // utile a non visualizzare la description
						}
						if (navObj.url === '/bridgeGedcons') {
							navObj.img = '/assets/images/loghiWhite/gaw-icone-48x48_GedCons.svg';
							navObj.hasLogo = true; // utile a non visualizzare la description
						}
						// TODO modificare la logica per renderla più robusta. Probabilmente necessaria modifica backend
						if (navObj.url === '/gaw30/configuration/fattureattese') {
							navObj.img = '/assets/icon/solid/circle/misc-fatture-attese-white.svg';
							navObj.hasLogo = true; // utile a non visualizzare la description
						}
						if (navObj.url === '/ricerca-fatture') {
							navObj.img = '/assets/icon/solid/circle/misc-ricerca-fatture-white.svg';
							navObj.hasLogo = true; // utile a non visualizzare la description
						}
						if (navObj.description === 'REGIME MINIMI') {
							navObj.img = '/assets/icon/solid/circle/misc-quadratura-flussi-white.svg';
							navObj.hasLogo = true; // utile a non visualizzare la description
						}
						// TODO a seguito della modifica del tipo del campo della tabella, la path
						// delle immagini delle icone arriverà su questo field
						navObj.icon = '';
						return navObj;
					})
			)
		);

		this.navigationActions$ = this.globalNavigation$.pipe(
			map((value) =>
				value
					.filter((nav) => nav.categoria === 'Comuni')
					.map((navItem) => ({
						...navItem,
						img: navItem.icon,
						hasLogo: !!navItem.icon,
					}))
			)
		);
	}

	onCloseSidebar() {
		this.navigationService.sendSidebar(false);
	}

	onMenuItemClick(navigation: Navigation) {
		if (navigation.isExternalLink) {
			const win = window.open(navigation.url, '_blank');
			win.focus();
		} else this.router.navigate([navigation.url]);
	}

	logout() {
		this.authService.logout();
	}

	ngAfterViewInit() {
		this.step = 0;
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

}
