import { Injectable } from '@angular/core';
import { DocumentsResponse } from '@ctel/gaw-commons';
import { ActionsService2 } from '@ctel/gawcons-commons';
import { AbstractDocumentEffects, DocumentActions, DocumentState, FilterActions, getRouterInfo } from '@ctel/search-filter-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ActionsConfigurationService } from '../../../ui-configuration/actions-configuration/actions-configuration.service';
import { DocumentsHttpService } from '../../documents-http.service';
import { DocumentsService2 } from '../documents.service';
import { getFiltersStateWithUserValuesGawcons } from './document.extended';

const PATH = 'gawcons/gcons_archivioFiscale';

@Injectable({
	providedIn: 'root'
})
export class DocumentEffects extends AbstractDocumentEffects {

	/**
	 * Effetto che gestisce il side effect di aver ricevuto i nuovi documenti,
	 * e per i quali è necessario eventualmente raffinare i filtri.
	 */
	onDocumentsFetched$ = createEffect(() => this.actions$.pipe(
		ofType(DocumentActions.documentsFetched),
		withLatestFrom(this.store.pipe(select(getRouterInfo))),
		filter(([action, routerInfo]) => !action.payload.chunked && routerInfo.state.url.indexOf(PATH) !== -1),
		map(([{ payload }]) => {
			if (this.raffinamentoIterativo)
				return FilterActions.refineFiltersRequested({ filters: payload.filters });

			return FilterActions.doNotRefineFiltersRequested({ filters: payload.filters });
		})
	));

	/**
	 * Effetto che gestisce il side effect di aver richiesto nuovi documenti.
	 */
	onFetchDocumentsRequired$ = createEffect(() => this.actions$.pipe(
		ofType(DocumentActions.fetchDocuments),
		mergeMap(payload =>
			of(payload).pipe(
				withLatestFrom(
					this.store.pipe(select(getFiltersStateWithUserValuesGawcons({
						fromFavourites: payload.payload.fromFavourites,
						technicalFilters: []
					}))),
					this.store.pipe(select(getRouterInfo))
				))
		),
		filter(([, filterState, routerInfo]) => !filterState.requested && routerInfo.state.url.indexOf(PATH) !== -1),
		switchMap(([, filterState, routerInfo]) => {
			const chunked = filterState.paging && filterState.paging.offset > 0;
			this.documentsService.setLoadingDocs(true);
			this.documentsService.setErrorLoadingDocs(false);
			const registryId = routerInfo.state.params['registry'];
			const idPdv = routerInfo.state.params['idPdv'];
			const body = { searchRequest: filterState, idRegistro: registryId };
			if (idPdv && !body.searchRequest.filters.find(f => f.metadata === 'idPdv'))
				body.searchRequest.filters = [...body.searchRequest.filters, {
					'configData': {},
					'filterType': 'term',
					'metadata': 'idPdv',
					'metadataDescription': '',
					'type': 'integer',
					'value': {
						'term': idPdv
					}
				}];

			return this.documentsHttpService.whenAllDocuments(JSON.stringify(body)).pipe(
				map((documentsResponse: DocumentsResponse) => {
					if (documentsResponse.totalDocs === 0)
						documentsResponse.filters.forEach(filtr => {
							filtr.configData = { buckets: [] };
							filtr.value = { terms: [] };
						});

					return DocumentActions.documentsFetched(
						documentsResponse.docs,
						documentsResponse.totalDocs,
						documentsResponse.filters,
						documentsResponse.metrics,
						chunked
					);
				}),
				tap(() => {
					this.documentsService.setLoadingDocs(false);
					this.documentsService.setLoadingDocsAfterFilterApplication(false);
					this.documentsService.setLoadingDocsOnPaging(false);
					this.documentsService.setLoadingDocsOnSectionChange(false);
				}),
				catchError((error: unknown) => {
					this.documentsService.setLoadingDocs(false);
					this.documentsService.setLoadingDocsAfterFilterApplication(false);
					this.documentsService.setLoadingDocsOnPaging(false);
					this.documentsService.setLoadingDocsOnSectionChange(false);
					this.documentsService.setErrorLoadingDocs(true);
					return this.handleError(error);
				})
			);
		})
	));

	/**
	 * Effetto che gestisce il side effect di aver richiesto le azioni su una data serie documentale.
	 */
	onFetchDocumentsActionsRequired$ = createEffect(() => this.actions$.pipe(
		ofType(DocumentActions.fetchDocumentsActions),
		withLatestFrom(this.store.pipe(select(getRouterInfo))),
		filter(([, routerInfo]) => routerInfo.state.url.indexOf('gcons_archivioFiscale/') !== -1),
		switchMap(([, routerInfo]) => {
			const idPdv = routerInfo.state.params['idPdv'];
			const docSeriesId = idPdv ? 'gcons_archivioFiscalePdv' : 'gcons_archivioFiscale';
			const licenseId = routerInfo.state.params['license'];
			const siaCode = routerInfo.state.params['siacode'];

			return this.actionsConfigurationService.getMassiveActionsByDocSeries(licenseId, siaCode, docSeriesId)
				.pipe(
					map(config => DocumentActions.documentActionsFetched(this.actionsService.mergeActionConfig(config, 'massive'))),
					catchError((error: unknown) => this.handleError(error))
				);
		})
	));

	constructor(
		protected actions$: Actions,
		protected store: Store<DocumentState>,
		private documentsHttpService: DocumentsHttpService,
		private actionsService: ActionsService2,
		private actionsConfigurationService: ActionsConfigurationService,
		private documentsService: DocumentsService2
	) {
		super(actions$, store);
	}
}
