import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe per la conversione di valori boolean in italiano (true-false in Sì/No)
 */
@Pipe({
	name: 'SiNo'
})
export class SiNoPipe implements PipeTransform {

	transform(value: boolean): string {
		if (value === true)
			return 'Sì';

		if (value === false)
			return 'No';

	}
}
