import { Component } from '@angular/core';

@Component({
	selector: 'app-metadata-placeholder',
	templateUrl: './metadata-placeholder.component.html',
	styleUrls: ['./metadata-placeholder.component.scss']
})
export class MetadataPlaceholderComponent {

}
