import { Injectable } from '@angular/core';
import { DocumentDetails } from '@ctel/gaw-commons';
import { WorkflowHttpService } from '@ctel/gaw-workflow';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { ActionsHttpService } from '../actions/actions-http.service';
import { FRDocumentiActionsService2 } from '../actions/actions.service';
import { DocumentsHttpService } from '../documents-http.service';

/**
 * Servizio che gestisce lo stato dei dettagli del documento (elenco metadati)
 */
@Injectable({
	providedIn: 'root'
})
export class FRDocumentiDocumentDetailsService {
	private documentDetails$ = new ReplaySubject<DocumentDetails>(1);
	private graphic$ = new ReplaySubject<string>(1);
	private documentId$ = new BehaviorSubject<string>('');
	private sectionCode$ = new BehaviorSubject<string>('');
	private docSeriesId$ = new BehaviorSubject<string>('');
	private realDocSeriesId$ = new BehaviorSubject<string>('');
	private tags$ = new ReplaySubject<string[]>(1);
	private keys$ = new BehaviorSubject<object>(null);
	private refresh$: BehaviorSubject<any> = new BehaviorSubject(0);

	constructor(
		private documentsHttpService: DocumentsHttpService,
		private actionsHttpService: ActionsHttpService,
		private actionsService: FRDocumentiActionsService2,
		private companiesService: CompaniesService,
		private workflowHttpService: WorkflowHttpService
	) { }

	getSectionCode(): string {
		return this.sectionCode$.value;
	}

	sendSectionCode(sectionCode: string) {
		this.sectionCode$.next(sectionCode);
	}

	whenCurrentDocumentDetails(): Observable<DocumentDetails> {
		return this.documentDetails$.asObservable();
	}

	sendRealDocSeriesId(docSeriesId: string) {
		this.realDocSeriesId$.next(docSeriesId);
	}

	getHeading(progSpool: string): Observable<string> {
		return this.documentsHttpService.getHeading(progSpool);
	}
}
