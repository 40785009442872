import { Injectable, OnDestroy } from '@angular/core';
import { TrackingStatusHistory } from '@ctel/gaw-commons';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { StatusHistoryHttpService } from './status-history-http.service';

@Injectable({
	providedIn: 'root',
})
export class StatusHistoryService implements OnDestroy {
	private statusHistory$ = new ReplaySubject<TrackingStatusHistory[]>(1);
	private statusHistoryError$ = new BehaviorSubject<boolean>(false);
	private destroy$ = new ReplaySubject<void>(1);

	constructor(private historyHttpService: StatusHistoryHttpService) { }

	whenStatusHistory(boxId: string, licenseId: string, siaCode: string) {
		return this.historyHttpService.whenStatusHistory(boxId, licenseId, siaCode).pipe(
			catchError(() => {
				this.sendStatusHistoryError(true);
				return EMPTY;
			}),
			tap((statusHistory) => {
				this.sendStatusHistoryError(false);
				this.sendStatusHistory(statusHistory);
			})
		);
	}

	sendStatusHistory(statusHistory: TrackingStatusHistory[]) {
		this.statusHistory$.next(statusHistory);
	}

	sendStatusHistoryError(value: boolean) {
		this.statusHistoryError$.next(value);
	}

	whenStatusHistoryError(): Observable<boolean> {
		return this.statusHistoryError$.asObservable();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
