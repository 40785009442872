import { Injectable } from '@angular/core';
import { Document, DocumentKey, StatusNoteItem } from '@ctel/gaw-commons';
import { DocumentState, getActions } from '@ctel/search-filter-store';
import { Store, select } from '@ngrx/store';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { ActionsConfigRequest, ConfigurationsGroup, DocMetadata } from 'app/entities/ui-config/action/actions-configuration';
import * as _ from 'lodash';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { ActionsConfigurationService } from '../../ui-configuration/actions-configuration/actions-configuration.service';
import { DocumentsService2 } from '../documents/documents.service';
import { ActionsByType } from './actions-by-type';

/**
 * Questa implementazione esiste unicamente per la nuova versione dei filtri che si
 * basa sugli stati applicativi (ngrx).
 */
@Injectable({
	providedIn: 'root',
})
export class ActionsService2 {
	private history$ = new ReplaySubject<StatusNoteItem[]>(1);
	private readonly massiveActionsList$ = new Observable<ActionObject[]>();
	private multiActionsList$ = new ReplaySubject<ActionObject[]>(1);
	private singleActionsList$ = new ReplaySubject<ActionObject[]>(1);
	private actionLoadingError$ = new BehaviorSubject<boolean>(false);

	constructor(
		private actionsConfigurationService: ActionsConfigurationService,
		private documentsService: DocumentsService2,
		private store: Store<DocumentState>,
		private actionsByType: ActionsByType
	) {
		this.massiveActionsList$ = this.store.pipe(
			select(getActions),
			map((actions) => <ActionObject[]>actions)
		);
	}

	// azioni massive
	whenMassiveActionsList(): Observable<ActionObject[]> {
		return this.massiveActionsList$;
	}

	// azioni multiple
	whenMultiActionsList(): Observable<ActionObject[]> {
		return this.multiActionsList$.asObservable();
	}

	sendLoadingActionError(value: boolean) {
		this.actionLoadingError$.next(value);
	}
	whenLoadingActionError(): Observable<boolean> {
		return this.actionLoadingError$.asObservable();
	}
	sendMultiActionsList(actions: ActionObject[]) {
		this.multiActionsList$.next(actions);
	}
	// azione che apre un modal storico qualsiasi (esiti o modifiche)
	whenHistory(): Observable<StatusNoteItem[]> {
		return this.history$.asObservable();
	}

	sendHistory(historyObject: StatusNoteItem[]) {
		this.history$.next(historyObject);
	}

	// azioni singole
	whenSingleActionsList(): Observable<ActionObject[]> {
		return this.singleActionsList$.asObservable();
	}

	sendSingleActionsList(actions: ActionObject[]) {
		this.singleActionsList$.next(actions);
	}

	// doc � facoltativo solo quando richiamiamo il metodo per ottenere l'endpoint per la visualizzazione anteprima del pdf nel dettaglio
	requestSingleActionsConfig(
		licenseId: string,
		siaCode: string,
		sectionCode: string,
		keys: DocumentKey,
		doc?: Document
	): Observable<ConfigurationsGroup[]> {
		// TODO rimuovere definitivamente 'gawedi_docSeries'
		const newMetadataList: DocMetadata[] = [];
		if (doc) {
			for (let i = 0; i < doc.display.length; i++) {
				const subObject: DocMetadata = (({ metadata, value, type }) => ({
					keyCode: metadata.replace('.keyword', ''),
					value,
					type,
				}))(doc.display[i]);
				newMetadataList.push(subObject);
			}
			// occorre passare questo metadato alla richiesta lista azioni per il check dell'azione modifica metadati
			if (Object.prototype.hasOwnProperty.call(keys, 'ultimoStato22'))
				newMetadataList.push({
					keyCode: 'ultimoStato22',
					value: keys['ultimoStato22']
				});

		}

		const body: ActionsConfigRequest = {
			progSpool: keys.progSpool,
			progBusta: keys.progBusta,
			docMetadata: newMetadataList,
		};

		return this.actionsConfigurationService.getSingleActionsByDocSeries(licenseId, siaCode, sectionCode, body).pipe(
			tap((config) => {
				this.sendSingleActionsList(this.mergeActionConfig(config, 'single'));
				this.sendLoadingActionError(false);
			}),
			catchError(() => {
				this.sendLoadingActionError(true);
				return EMPTY;
			}),
			take(1)
		);
	}

	requestMultiActionsConfig(licenseId: string, siaCode: string, sectionCode: string, body: ActionsConfigRequest[]) {
		// TODO rimuovere definitivamente 'gawedi_docSeries'
		this.actionsConfigurationService
			.getMultipleActionsByDocSeries(licenseId, siaCode, sectionCode, body)
			.pipe(
				tap((config) => this.sendMultiActionsList(this.mergeActionConfig(config, 'multi'))),
				take(1)
			).subscribe();
	}

	mergeActionConfig(configuration: ConfigurationsGroup[], actionsType: 'single' | 'multi' | 'massive'): ActionObject[] {
		let actionsCatalog = [];
		switch (actionsType) {
			case 'single':
				actionsCatalog = _.cloneDeep(this.actionsByType.singleActions) as ActionObject[];
				break;
			case 'multi':
				actionsCatalog = _.cloneDeep(this.actionsByType.multiActions) as ActionObject[];
				break;
			case 'massive':
				actionsCatalog = _.cloneDeep(this.actionsByType.massiveActions) as ActionObject[];
				break;
		}

		const actionsList: ActionObject[] = [];
		if (configuration)
			for (let i = 0; i < configuration.length; i++) {
				const action: ActionObject = actionsCatalog.find((obj) => obj.code === configuration[i].code);
				if (action !== undefined) {
					Object.keys(configuration[i]).forEach((key) => {
						action[key] = configuration[i][key];
					});
					actionsList.push(action);
				}
			}

		return actionsList;
	}
}
