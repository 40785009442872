/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative al dettaglio documento
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable } from 'rxjs';
import { DocumentDetails } from '../../entities/documents/details/document-details';

@Injectable({
	providedIn: 'root'
})
export class DocumentDetailsHttpService {

	private jsonContentType = 'application/json';
	// host
	private docDetailsHost: string;
	private searchHost: string;
	private updateHost: string;
	// endpoints
	private statusHistory: string;
	private displayDocumentReceivable: string;
	private displayDocumentPayable: string;
	private displayDocumentGeneric: string;
	private isDocSigned: string;

	private payableServicesAndColors: string;
	private receivableServicesAndColors: string;
	private docDetails: string;
	private updateTags: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		/* ENDPOINTS DOCUMENT GED ------------------------------------------------------------------------------------------------------- */
		this.docDetailsHost = appConfig.docDetails.http.host;
		this.statusHistory = `${this.docDetailsHost}/v1/Document/{serviceName}/VisualizzaStoricoStati?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&documentCycleType={account}`;
		this.displayDocumentReceivable = `${this.docDetailsHost}/v1/Document/HBFEDisplayDocument?channel={serviceName}&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType={docType}&download={download}`;
		this.displayDocumentPayable = `${this.docDetailsHost}/v1/Document/GEDPASSJOIN/DisplayDocument?canale={serviceName}&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType={docType}&download={download}`;
		this.displayDocumentGeneric = `${this.docDetailsHost}/v1/Document/DisplayDocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&allegatoType={allegatoType}&download={download}`;
		this.isDocSigned = `${this.docDetailsHost}`;

		// Dato un documento ne fornisce lo stato e il corrispondente colore sui vari canali (endpoint diverso in base al ciclo)
		this.receivableServicesAndColors = `${this.docDetailsHost}/v2/Document/DocumentChannelsStateMapping?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}`;
		this.payableServicesAndColors = `${this.docDetailsHost}/v1/Document/GEDPASSJOIN/DocumentsTilesMapping?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}`;

		/* ENDPOINTS MAGELLANO ---------------------------------------------------------------------------------------------------------- */
		this.searchHost = appConfig.search.http.host;
		this.docDetails = `${this.searchHost}/v2/documents/{licenseId}/{siaCode}/{docSeriesId}/{elasticDocumentId}/details?includeTechnicalMetadata={includeTechnical}&clientid=HUBFE`;

		/* ENDPOINTS DICKENS ------------------------------------------------------------------------------------------------------------ */
		this.updateHost = appConfig.update.http.host;
		this.updateTags = `${this.updateHost}/v1/document/update`;
	}

	// controlla se sul documento è presente la firma
	whenIsDocSigned(progSpool: string, progBusta: string, docHash: string): Observable<boolean> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		if (docHash === '')
			docHash = 'null';

		const url = this.isDocSigned
			.replace('{docHash}', docHash === '' ? null : docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		return this.http.get<boolean>(url, options);
	}

	whenStatusHistory(serviceName: string, account: string, progSpool: string, progBusta: string, docHash: string): Observable<any> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		if (docHash === '')
			docHash = 'null';

		const url = this.statusHistory
			.replace('{account}', account)
			.replace('{docHash}', docHash === '' ? null : docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{serviceName}', serviceName);
		return this.http.get<any>(url, options);
	}

	whenReceivableServicesAndColor(progSpool: string, progBusta: string, docHash: string): Observable<any> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		if (docHash === '')
			docHash = 'null';

		const url = this.receivableServicesAndColors
			.replace('{docHash}', docHash === '' ? null : docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		return this.http.get<any>(url, options);
		// TODO EVO IN CORSO CARD IN CONSERVAZIONE - KARANDIP SINGH MOCK DATI IN ATTESA DI ENDPOINT
		// return of(JSON.parse(this.test));
	}

	whenPayableServicesAndColor(progSpool: string, progBusta: string, docHash: string): Observable<any> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		if (docHash === '')
			docHash = 'null';

		const url = this.payableServicesAndColors
			.replace('{docHash}', docHash === '' ? null : docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		return this.http.get<any>(url, options);
		// TODO EVO IN CORSO CARD IN CONSERVAZIONE - KARANDIP SINGH MOCK DATI IN ATTESA DI ENDPOINT
		// return of(JSON.parse(this.test));
	}

	// AZIONI -------------------------------------------------------------------------------------------------------
	whenDisplayDocumentReceivable(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean, docType: string
	): Observable<any> {
		const url = this.displayDocumentReceivable
			.replace('{serviceName}', serviceName)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{docHash}', docHash === '' ? null : docHash)
			.replace('{docType}', docType)
			.replace('{download}', String(download));

		let options;
		if (download)
			options = CustomHttpOptions.getHttpOptionsObserveResponse(this.jsonContentType, 'blob', null, true);
		else
			options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'blob', null, true);

		return this.http.get<any>(url, options);
	}

	whenDisplayDocumentReceivableXml(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean
	): Observable<any> {
		const docType = '52';
		const url = this.displayDocumentReceivable
			.replace('{serviceName}', serviceName)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{docHash}', docHash === '' ? null : docHash)
			.replace('{docType}', docType)
			.replace('{download}', String(download));

		const options = CustomHttpOptions.getHttpOptions('application/xml', 'text');
		return this.http.get<any>(url, options);
	}

	whenDisplayDocumentPayable(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean, docType: string
	): Observable<any> {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const current = this;

		const url = current.displayDocumentPayable
			.replace('{serviceName}', serviceName)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{docHash}', docHash === '' ? null : docHash)
			.replace('{docType}', docType)
			.replace('{download}', String(download));
		let options;
		if (download)
			options = CustomHttpOptions.getHttpOptionsObserveResponse(this.jsonContentType, 'blob', null, true);
		else
			options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'blob', null, true);

		return current.http.get(url, options);
	}

	whenDisplayDocumentPayableXml(
		serviceName: string, progSpool: string, progBusta: string, docHash: string, download: boolean
	): Observable<any> {
		const docType = '52';
		const url = this.displayDocumentPayable
			.replace('{serviceName}', serviceName)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{docHash}', docHash === '' ? null : docHash)
			.replace('{docType}', docType)
			.replace('{download}', String(download));

		const options = CustomHttpOptions.getHttpOptions('application/xml', 'text');
		return this.http.get<any>(url, options);
	}

	whenDisplayDocumentGeneric(
		progSpool: string, progBusta: string, docHash: string, download: boolean, allegatoType: number
	): Observable<any> {
		const url = this.displayDocumentGeneric
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{docHash}', docHash === '' ? null : docHash)
			.replace('{allegatoType}', allegatoType.toString())
			.replace('{download}', String(download));

		const options = CustomHttpOptions.getHttpOptions('application/xml', 'text');
		return this.http.get<any>(url, options);
	}

	// DETTAGLIO METADATI DEL DOCUMENTO ------------------------------------------------------------------------------
	whenDocumentDetails(
		licenseId: string, siaCode: string, elasticDocumentId: string, includeTechnical: boolean
	): Observable<DocumentDetails> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.docDetails
			.replace('{docSeriesId}', 'ALL')
			.replace('{elasticDocumentId}', elasticDocumentId)
			.replace('{includeTechnical}', String(includeTechnical))
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<DocumentDetails>(url, options);
	}

	/* HTTP REQUESTS A DICKENS---------------------------------------------------------------------------------------------- */
	whenTagsUpdate(body: string): Observable<any> {
		const url = this.updateTags;
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		return this.http.post<any>(url, body, options);
	}

}
