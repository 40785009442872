/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative al dettaglio documento
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { HideShowDocumentsResponse, StatusNoteItem } from '@ctel/gaw-commons';
import { Transfer, TransferService, transferHandler } from '@ctel/transfer-manager';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { ActionCrudResponse } from 'app/entities/ui-config/action/action-crud-response';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ActionsHttpService implements OnDestroy {
	private jsonContentType = 'application/json';
	// host
	private documentGedHost: string;
	private documentsExportHost: string;
	private docDetailsHost: string;
	private gawediReadHost: string;
	private gawediWriteHost: string;

	private readonly jsonOptions: object;
	private actionLoading$ = new BehaviorSubject<boolean>(false);

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
		private transferService: TransferService,
		private fullScreenSpinnerService: FullScreenSpinnerService
	) {
		this.configService.whenAppConfig().pipe(takeUntil(this.destroy$))
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));

		this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json', null, true);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	httpHostInit(appConfig: AppConfig) {
		/* ENDPOINTS DOCUMENT GED ------------------------------------------------------------------------------------------------------- */
		this.docDetailsHost = appConfig.docDetails.http.host;
		/* ENDPOINTS DOCUMENT GED ------------------------------------------------------------------------------------------------------- */
		this.documentGedHost = appConfig.docDetails.http.host;
		/* ENDPOINTS DOCUMENT EXPORT ---------------------------------------------------------------------------------------------------- */
		this.documentsExportHost = appConfig.documentsExport.http.host;
		// this.hasAttachments = `${this.docDetailsHost}/v1/Document/GEDINVOICE/HasAttachments?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&documentChannelType={account}`;

		/* ENDPOINTS GAWEDI --------------------------------------------------------------------------------------------------- */
		this.gawediReadHost = appConfig.gawEdiRead.http.host;
		this.gawediWriteHost = appConfig.gawEdiWrite.http.host;
	}

	// AZIONI GAWEDI -------------------------------------------------------------------------------------------------------
	// SINGLE: Annulla sul canale
	whenCancelInvoice(actionUrl: string, body: unknown[]): Observable<unknown> {
		this.setLoadingAction(true);
		const headers: HttpHeaders = new HttpHeaders();
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json', headers, true);
		const url = this.gawediWriteHost + actionUrl;
		return this.http.post<unknown>(url, body, options).pipe(
			tap({
				next: () => {
					this.setLoadingAction(false);
				},
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// SINGOLA: download pdf
	whenSinglePdf(actionUrl: string, idLicenza: string, idFileOutput: string): Observable<Transfer> {
		const url =
			this.gawediReadHost +
			actionUrl.replace('{idLicenza}', idLicenza).replace('{idFileOutput}', idFileOutput);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts).pipe(transferHandler(this.transferService));
			})
		);
	}

	whenEdi(actionUrl: string, idLicenza: string, idFileOutput: string): Observable<Transfer> {
		const url =
			this.gawediReadHost +
			actionUrl.replace('{idLicenza}', idLicenza).replace('{idFileOutput}', idFileOutput);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts).pipe(transferHandler(this.transferService));
			})
		);
	}

	// SINGOLA: download allegati
	whenAttachments(actionUrl: string, progSpool: string, progBusta: string, docHash: string): Observable<Transfer> {
		const url =
			this.gawediReadHost +
			actionUrl.replace('{docHash}', docHash).replace('{progSpool}', progSpool).replace('{progBusta}', progBusta);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts).pipe(transferHandler(this.transferService));
			})
		);
	}

	whenOriginalPdf(actionUrl: string, progSpool: string, progBusta: string, docHash: string): Observable<Transfer> {
		const url =
			this.gawediReadHost +
			actionUrl.replace('{docHash}', docHash).replace('{progSpool}', progSpool).replace('{progBusta}', progBusta);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts).pipe(transferHandler(this.transferService));
			})
		);
	}

	whenDelivery(actionUrl: string, progSpool: string, progBusta: string, docHash: string): Observable<Transfer> {
		const url =
			this.gawediReadHost +
			actionUrl.replace('{docHash}', docHash).replace('{progSpool}', progSpool).replace('{progBusta}', progBusta);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts).pipe(transferHandler(this.transferService));
			})
		);
	}

	whenModifyMailOrPecAddress(
		actionUrl: string,
		body: unknown
	): Observable<ActionCrudResponse> {
		this.setLoadingAction(true);
		const url = this.gawediWriteHost + actionUrl;

		return this.http.post<ActionCrudResponse>(url, body, this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}
	// END AZIONI GAWEDI -------------------------------------------------------------------------------------------------------

	whenChangesHistory(actionUrl: string, idFileOutput: string): Observable<StatusNoteItem[]> {
		this.setLoadingAction(true);
		const url =
			this.gawediReadHost +
			actionUrl.replace('{idFileOutput}', idFileOutput);
		return this.http.get<StatusNoteItem[]>(url, this.jsonOptions).pipe(
			tap({
				next: () => this.setLoadingAction(false),
				error: () => this.setLoadingAction(false)
			})
		);
	}

	// da rivedere !!!

	whenPreviewSinglePdf(actionUrl: string, progSpool: string, progBusta: string, docHash: string): Observable<unknown> {
		const url =
			this.documentGedHost +
			actionUrl.replace('{docHash}', docHash).replace('{progSpool}', progSpool).replace('{progBusta}', progBusta);
		const options = CustomHttpOptions.getHttpOptionsObserveResponse(this.jsonContentType, 'blob', null, true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts);
			})
		);
	}

	// SINGOLA: edit chiavi
	whenEditKeys(actionUrl: string, body: unknown): Observable<unknown> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json', null, true);
		const url = this.documentGedHost + actionUrl;
		return this.http.post<unknown>(url, body, options);
	}

	// MULTI: download zip di documenti
	whenPdfZip(actionUrl: string, body: unknown): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.documentGedHost + actionUrl;
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.post<unknown>(url, body, opts).pipe(transferHandler(this.transferService));
			})
		);
	}

	// MULTI: download pdf mergiati
	whenMergedPdf(actionUrl: string, body: unknown): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.documentGedHost + actionUrl;
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.post<unknown>(url, body, opts).pipe(transferHandler(this.transferService));
			})
		);
	}

	// MULTI: download excel doc selezionati
	whenExcel(actionUrl: string, body: unknown, format?: 'xlsx' | 'xls' | 'csv', sectionCode?: string): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.documentsExportHost + actionUrl;
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				opts['params'] = {
					sectionCode,
					format,
				};
				return this.http.post(url, body, opts).pipe(transferHandler(this.transferService));
			})
		);
	}

	// MASSIVA: download excel di tutta la serie documentale/sezione
	whenExcelAll(actionUrl: string, body: unknown): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.gawediReadHost + actionUrl;
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.post(url, body, opts).pipe(transferHandler(this.transferService));
			})
		);
	}

	// nascondi/mostra documenti
	public whenHideAndShowDocuments(actionUrl: string, body: unknown, hide: boolean): Observable<HideShowDocumentsResponse> {
		const url = this.documentGedHost + actionUrl.replace('{hide}', JSON.stringify(hide));
		return this.http.post(url, body, this.jsonOptions);
	}

	// nasdondi/mostra documenti massivo
	public whenHideAndShowDocumentsMassive(actionUrl: string, body: unknown, hide: boolean): Observable<ActionCrudResponse> {
		const url = this.documentGedHost + actionUrl.replace('{hide}', JSON.stringify(hide));
		return this.http.post<ActionCrudResponse>(url, body, this.jsonOptions);
	}

	public whenLoadingAction(): Observable<boolean> {
		return this.actionLoading$.asObservable();
	}
	public setLoadingAction(value: boolean) {
		this.actionLoading$.next(value);
	}
}
