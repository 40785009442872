import { Component, Input, OnInit } from '@angular/core';
import { PDDResponseModel } from '../../entities/PDD-response-model';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'gaw-pdd-outcome-modal',
	templateUrl: './pdd-outcome-modal.component.html',
	styleUrls: ['./pdd-outcome-modal.component.scss']
})
export class PDDOutcomeModalComponent implements OnInit {
	@Input() data: { pddModel: PDDResponseModel};
	@Input() modal: BsModalRef;
	public date: number;

	ngOnInit(): void {
		this.date = Date.now();
	}
}
