import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { GetContactsResponse, UpsertContact } from 'app/entities/contacts/contact';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormatEnum } from '../../../../entities/address-book/format-enum';
import { GawMailFlowConfig } from '../../../../entities/flow-config/gaw-mail-flow-config';
import { GawPecFlowConfig } from '../../../../entities/flow-config/gaw-pec-flow-config';
import { Address, GawPostFlowConfig } from '../../../../entities/flow-config/gaw-post-flow-config';
import { FlowRules, FlowRulesStatus } from '../../../../entities/flow-rules/flow-rules';

/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative alla rubrica
 */
@Injectable({
	providedIn: 'root'
})
export class AddressBookHttpService {

	private readonly jsonContentType = 'application/json';

	private internalGedInvoiceHost: string;

	private getContacts: string;
	private getContactById: string;
	private updateContact: string;
	private deleteContact: string;
	private insertContact: string;
	private checkEnabledServicesUrl: string;
	private gawUtilReadHost: string;
	private getConfigByGraphicsGawPecUrl: string;
	private getConfigByGraphicsGawMailUrl: string;
	private updateConfigsByGraphicsGawPecUrl: string;
	private updateConfigsByGraphicsGawMailUrl: string;
	private ruleListUrl: string;
	private updateConfigsByGraphicsGawPostAltUrl: string;
	private insertConfigsByGraphicsGawPostAltUrl: string;
	private updateConfigsByGraphicsGawPostAggUrl: string;
	private insertConfigsByGraphicsGawPostAggUrl: string;
	private gawUtilWriteHost: string;
	private enableServiceToGraphicUrl: string;
	private exportEsclusiUrl: string;
	private exportAlternativiUrl: string;
	private exportAggiuntiviUrl: string;
	private exportContactsUrl: string;
	private exportDestinatariUrl: string;
	private importEsclusiUrl: string;
	private importAlternativiUrl: string;
	private importAggiuntiviUrl: string;
	private importContactsUrl: string;
	private importDestinatariUrl: string;
	private getConfigByGraphicsGawPostUrl: string;
	private deleteConfigsByGraphicsGawPostAltUrl: string;
	private deleteConfigsByGraphicsGawPostAggUrl: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.internalGedInvoiceHost = appConfig.gedInvoice.http.host;
		this.gawUtilReadHost = appConfig.gawUtilRead.http.host;
		this.gawUtilWriteHost = appConfig.gawUtilWrite.http.host;

		this.getContacts = `${this.internalGedInvoiceHost}/v1/AddressBook/Cessionario/Contacts?idLicense={licenseId}&codSia={siaCode}`;
		this.getContactById = `${this.internalGedInvoiceHost}/v1/AddressBook/Cessionario/Contact?idLicense={licenseId}&codSia={siaCode}&contactId={contactId}&contactAlias={contactAlias}`;
		this.updateContact = `${this.internalGedInvoiceHost}/v1/AddressBook/Cessionario/Update?idLicense={licenseId}&codSia={siaCode}`;
		this.deleteContact = `${this.internalGedInvoiceHost}/v1/AddressBook/Cessionario/Delete?idLicense={licenseId}&codSia={siaCode}&contactId={contactId}`;
		this.insertContact = `${this.internalGedInvoiceHost}/v1/AddressBook/Cessionario/Insert?idLicense={licenseId}&codSia={siaCode}`;
		this.checkEnabledServicesUrl = `${this.gawUtilReadHost}/api/v1/Flussi/CheckEnabledServices/{licenseId}/{siaCode}`;
		this.ruleListUrl = `${this.gawUtilReadHost}/api/v1/Flussi/RuleList/{licenseId}/{siaCode}/{codiceVeicolazione}`;
		this.enableServiceToGraphicUrl = `${this.gawUtilWriteHost}/api/v1/Flussi/EnableServiceToGraphic/{IdLicenza}/{CodSia}/{codGrafica}`;
		this.getConfigByGraphicsGawPecUrl = `${this.gawUtilReadHost}/api/v1/Flussi/GetConfigByGraphicsGawPec/{IdLicenza}/{CodSia}/{CodiceVeicolazione}`;
		this.getConfigByGraphicsGawMailUrl = `${this.gawUtilReadHost}/api/v1/Flussi/GetConfigByGraphicsGawMail/{IdLicenza}/{CodSia}/{CodiceVeicolazione}`;
		this.getConfigByGraphicsGawPostUrl = `${this.gawUtilReadHost}/api/v1/Flussi/GetConfigByGraphicsGawPost/{IdLicenza}/{CodSia}/{CodiceVeicolazione}`;
		this.updateConfigsByGraphicsGawPecUrl = `${this.gawUtilWriteHost}/api/v1/Flussi/UpdateConfigsByGraphicsGawPec/{IdLicenza}/{CodSia}/{CodiceVeicolazione}`;
		this.updateConfigsByGraphicsGawMailUrl = `${this.gawUtilWriteHost}/api/v1/Flussi/UpdateConfigsByGraphicsGawMail/{IdLicenza}/{CodSia}/{CodiceVeicolazione}`;
		this.updateConfigsByGraphicsGawPostAltUrl = `${this.gawUtilWriteHost}/api/v1/Flussi/UpdateConfigsByGraphicsGawPostAlt/{IdLicenza}/{CodSia}/{CodiceVeicolazione}`;
		this.insertConfigsByGraphicsGawPostAltUrl = `${this.gawUtilWriteHost}/api/v1/Flussi/InsertConfigsByGraphicsGawPostAlt/{IdLicenza}/{CodSia}/{CodiceVeicolazione}`;
		this.updateConfigsByGraphicsGawPostAggUrl = `${this.gawUtilWriteHost}/api/v1/Flussi/UpdateConfigsByGraphicsGawPostAgg/{IdLicenza}/{CodSia}/{CodiceVeicolazione}`;
		this.insertConfigsByGraphicsGawPostAggUrl = `${this.gawUtilWriteHost}/api/v1/Flussi/InsertConfigsByGraphicsGawPostAgg/{IdLicenza}/{CodSia}/{CodiceVeicolazione}`;
		this.exportEsclusiUrl = `${this.gawUtilWriteHost}/api/v1/Export/ExportEsclusi`;
		this.exportAlternativiUrl = `${this.gawUtilWriteHost}/api/v1/Export/ExportAlternativi`;
		this.exportAggiuntiviUrl = `${this.gawUtilWriteHost}/api/v1/Export/ExportAggiuntivi`;
		this.exportContactsUrl = `${this.gawUtilWriteHost}/api/v1/Export/ExportContacts`;
		this.exportDestinatariUrl = `${this.gawUtilWriteHost}/api/v1/Export/ExportDestinatari`;
		this.deleteConfigsByGraphicsGawPostAltUrl = `${this.gawUtilWriteHost}/api/v1/Flussi/DeleteConfigsByGraphicsGawPostAlt/{IdLicenza}/{CodSia}`;
		this.deleteConfigsByGraphicsGawPostAggUrl = `${this.gawUtilWriteHost}/api/v1/Flussi/DeleteConfigsByGraphicsGawPostAgg/{progressivo}`;
		this.importDestinatariUrl = `${this.gawUtilWriteHost}/api/v1/Import/ImportDestinatari/{IdLicenza}/{CodSia}`;
		this.importAlternativiUrl = `${this.gawUtilWriteHost}/api/v1/Import/ImportAlternativi/{IdLicenza}/{CodSia}`;
		this.importEsclusiUrl = `${this.gawUtilWriteHost}/api/v1/Import/ImportEsclusi/{IdLicenza}/{CodSia}/{service}`;
		this.importAggiuntiviUrl = `${this.gawUtilWriteHost}/api/v1/Import/ImportAggiuntivi/{IdLicenza}/{CodSia}`;
		this.importContactsUrl = `${this.gawUtilWriteHost}/api/v1/Import/ImportContacts/{IdLicenza}/{CodSia}`;
	}

	whenContacts(licenseId: string, siaCode: string): Observable<GetContactsResponse> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.getContacts
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<any>(url, options);
	}

	whenDeleteContact(licenseId: string, siaCode: string, contactId: number): Observable<any> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.deleteContact
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{contactId}', '' + contactId);
		return this.http.delete<any>(url, options);
	}

	whenInsertContact(licenseId: string, siaCode: string, body: UpsertContact): Observable<any> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.insertContact
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.post<any>(url, body, options);
	}

	whenUpdateContact(licenseId: string, siaCode: string, body: UpsertContact): Observable<any> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.updateContact
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.put<any>(url, body, options);
	}

	checkEnabledServices(licenseId: string, siaCode: string) {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.checkEnabledServicesUrl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<any>(url, options);
	}

	getConfigByGraphicsGawPec(licenseId: string, siaCode: string, codiceVeicolazione: string): Observable<GawPecFlowConfig[]> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.getConfigByGraphicsGawPecUrl
			.replace('{IdLicenza}', licenseId)
			.replace('{CodSia}', siaCode)
			.replace('{CodiceVeicolazione}', codiceVeicolazione);
		return this.http.get<{ flowConfigurations: GawPecFlowConfig[] }>(url, options).pipe(
			map(value => value.flowConfigurations)
		);
	}

	getConfigByGraphicsGawMail(licenseId: string, siaCode: string, codiceVeicolazione: string) {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.getConfigByGraphicsGawMailUrl
			.replace('{IdLicenza}', licenseId)
			.replace('{CodSia}', siaCode)
			.replace('{CodiceVeicolazione}', codiceVeicolazione);
		return this.http.get<{ flowConfigurations: GawMailFlowConfig[] }>(url, options).pipe(
			map(value => value.flowConfigurations)
		);
	}

	updateConfigsByGraphicsGawPec(
		licenseId: string,
		siaCode: string,
		codiceVeicolazione: string,
		address: string,
		extendCheck: boolean,
		codGrafica: string
	): Observable<boolean> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.updateConfigsByGraphicsGawPecUrl
			.replace('{IdLicenza}', licenseId)
			.replace('{CodSia}', siaCode)
			.replace('{CodiceVeicolazione}', codiceVeicolazione);
		return this.http.put<boolean>(url, {
			pecMailToUpdate: address,
			estendiModifica: extendCheck,
			codGrafica
		}, options);
	}

	updateConfigsByGraphicsGawMail(
		licenseId: string,
		siaCode: string,
		codiceVeicolazione: string,
		address: string,
		extendCheck: boolean,
		codGrafica: string
	): Observable<boolean> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.updateConfigsByGraphicsGawMailUrl
			.replace('{IdLicenza}', licenseId)
			.replace('{CodSia}', siaCode)
			.replace('{CodiceVeicolazione}', codiceVeicolazione);
		return this.http.put<boolean>(url, {
			pecMailToUpdate: address,
			estendiModifica: extendCheck,
			codGrafica
		}, options);
	}

	updateConfigsByGraphicsGawPostAlt(licenseId: string, siaCode: string, codiceVeicolazione: string, address: Address) {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.updateConfigsByGraphicsGawPostAltUrl
			.replace('{IdLicenza}', licenseId)
			.replace('{CodSia}', siaCode)
			.replace('{CodiceVeicolazione}', codiceVeicolazione);

		return this.http.put<boolean>(url, address, options);
	}

	updateConfigsByGraphicsGawPostAgg(licenseId: string, siaCode: string, codiceVeicolazione: string, address: Address) {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.updateConfigsByGraphicsGawPostAggUrl
			.replace('{IdLicenza}', licenseId)
			.replace('{CodSia}', siaCode)
			.replace('{CodiceVeicolazione}', codiceVeicolazione);

		return this.http.put<boolean>(url, address, options);
	}

	insertConfigsByGraphicsGawPostAlt(licenseId: string, siaCode: string, codiceVeicolazione: string, address: Address) {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.insertConfigsByGraphicsGawPostAltUrl
			.replace('{IdLicenza}', licenseId)
			.replace('{CodSia}', siaCode)
			.replace('{CodiceVeicolazione}', codiceVeicolazione);
		return this.http.post<boolean>(url, address, options);
	}

	insertConfigsByGraphicsGawPostAgg(licenseId: string, siaCode: string, codiceVeicolazione: string, address: Address) {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.insertConfigsByGraphicsGawPostAggUrl
			.replace('{IdLicenza}', licenseId)
			.replace('{CodSia}', siaCode)
			.replace('{CodiceVeicolazione}', codiceVeicolazione);
		return this.http.post<boolean>(url, address, options);
	}

	getRuleList(licenseId: string, siaCode: string, codiceVeicolazione: string): Observable<FlowRules[]> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.ruleListUrl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{codiceVeicolazione}', codiceVeicolazione);
		return this.http.get<{ rules: FlowRules[] }>(url, options).pipe(
			map(value => value.rules)
		);
	}

	enableServiceToGraphic(
		licenseId: string,
		siaCode: string,
		codiceVeicolazione: string,
		status: FlowRulesStatus,
		codGrafica: string,
		contactId: number,
		serviceId: string
	) {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const body = {
			idContatto: contactId,
			serviceId,
			codiceVeicolazione,
			serviceStatus: status
		};
		const url = this.enableServiceToGraphicUrl
			.replace('{IdLicenza}', licenseId)
			.replace('{CodSia}', siaCode)
			.replace('{codGrafica}', codGrafica);
		return this.http.post<any>(url, body, options);
	}

	export(selectedFormat: FormatEnum, idContatti: number[]): Observable<any> {
		let url: string;
		switch (selectedFormat) {
			case FormatEnum.CONTATTO:
				url = this.exportContactsUrl;
				break;
			case FormatEnum.DESTINATARI:
				url = this.exportDestinatariUrl;
				break;
			case FormatEnum.ESCLUSI:
				url = this.exportEsclusiUrl;
				break;
			case FormatEnum.ALTERNATIVI:
				url = this.exportAlternativiUrl;
				break;
			case FormatEnum.AGGIUNTIVI:
				url = this.exportAggiuntiviUrl;
				break;
		}
		return this.http.post(url, { idContatti }, { responseType: 'blob' as 'json', observe: 'response' });

	}

	getConfigByGraphicsGawPost(licenseId: string, siaCode: string, codiceVeicolazione: string): Observable<any> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.getConfigByGraphicsGawPostUrl
			.replace('{IdLicenza}', licenseId)
			.replace('{CodSia}', siaCode)
			.replace('{CodiceVeicolazione}', codiceVeicolazione);
		return this.http.get<{ flowConfigurations: GawPostFlowConfig[] }>(url, options).pipe(
			map(value => value.flowConfigurations)
		);
	}

	deleteConfigsByGraphicsGawPostAgg(progressivo: number) {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.deleteConfigsByGraphicsGawPostAggUrl
			.replace('{progressivo}', progressivo.toString());
		return this.http.delete(url, options);
	}

	deleteConfigsByGraphicsGawPostAlt(licenseId: string, siaCode: string, codiceVeicolazione: string, codGrafica: string): Observable<any> {
		const url = this.deleteConfigsByGraphicsGawPostAltUrl
			.replace('{IdLicenza}', licenseId)
			.replace('{CodSia}', siaCode);

		return this.http.request('delete', url, {
			body: {
				codiceVeicolazione,
				codGrafica
			}
		});
	}

	import(licenseId: string, siaCode: string, selection: FormatEnum, file: File, service: string, fileAppend: boolean): Observable<any> {
		let url: string;
		const formData: FormData = new FormData();
		formData.append('uploadedFile', file);
		switch (selection) {
			case FormatEnum.DESTINATARI:
				url = this.importDestinatariUrl;
				break;
			case FormatEnum.ESCLUSI:
				url = this.importEsclusiUrl;
				break;
			case FormatEnum.ALTERNATIVI:
				url = this.importAlternativiUrl;
				break;
			case FormatEnum.AGGIUNTIVI:
				url = this.importAggiuntiviUrl;
				break;
			case FormatEnum.CONTATTO:
				url = this.importContactsUrl;
				break;
		}
		url = url
			.replace('{IdLicenza}', licenseId)
			.replace('{CodSia}', siaCode)
			.replace('{service}', service);
		return this.http.post(url, formData, { params: { fileAppend: `${fileAppend}` }, observe: 'response' });
	}
}
