import { ElementRef, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable(
	{ providedIn: 'root' }
)
export class ElementRefService {

	private topPage$ = new ReplaySubject<ElementRef>(1);

	whenTopPage(): Observable<ElementRef> {
		return this.topPage$.asObservable();
	}

	sendTopPage(elementRef: ElementRef) {
		this.topPage$.next(elementRef);
	}
}
