import { Field } from 'app/shared/components/dry/field/field';
import { FieldType } from 'app/shared/components/dry/field/field-type';

export class DateField extends Field {

	type = FieldType.DATE;

	public startFromToday = false;
	public format = 'YYYY-MM-DDTHH:mm:ssZ';

	constructor(id: string, name: string) {
		super(id, name);
	}
}
