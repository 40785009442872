<input class="form-control form-control-sm"
	   type="date"
	   name="{{name}}"
	   [id]="id"
	   [ngClass]="{
	   	'valid': dateInputForm.value && dateInputForm.valid && required
	   }"
       #dateInputForm="ngModel"
	   [(ngModel)]="value"
	   (ngModelChange)="onChangeUpdate()"
	   [ngModelOptions]="ngModelOptions"
	   [popover]="InvalidFormMessage"
	   (focusin)="onFocusIn()"
	   (focusout)="onFocusOut()"
	   triggers=""
	   [required]="required"
	   [disabled]="disabled"
	   [readonly]="readonly"
	   [isDate]="format"
	   [composeValidators]="_validators"
	   [composeAsyncValidators]="_asyncValidators"/>
<ng-template #InvalidFormMessage>
	<ul *ngIf="dateInputForm.invalid" class="invalid-form-msg">
		<ng-container *ngFor="let error of getErrorMessage(dateInputForm)">
			<li>
				{{error}}
			</li>
		</ng-container>
	</ul>
</ng-template>
