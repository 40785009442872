import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExcelFormatModalComponent } from 'app/core/common/modals/excel-format-modal/excel-format-modal.component';
import { NotificationModalComponent } from 'app/core/common/modals/notification-modal/notification-modal.component';
import { ErrorPlaceholderModule } from 'app/core/common/placeholder/error-placeholder/error-placeholder.module';
import { ActionConfirmationModalComponent } from './modals/action-confirmation-modal/action-confirmation-modal.component';
import { ActionOriginalPdfModalComponent } from './modals/action-original-pdf/action-original-pdf-modal.component';
import { ActionProtocolModalComponent } from './modals/action-protocol-modal/action-protocol-modal.component';
import { AlertNewsModalComponent } from './modals/alert-news-modal/alert-news-modal.component';
import { ChangeCfModalComponent } from './modals/change-cf-modal/change-cf-modal.component';
import { EditKeysModalComponent } from './modals/edit-keys-modal/edit-keys-modal.component';
import { EditKpiModalComponent } from './modals/edit-kpi-modal/edit-kpi-modal.component';
import { EditOfficeDeskModalComponent } from './modals/edit-office-desk-modal/edit-office-desk-modal.component';
import { EditSectionsModalComponent } from './modals/edit-sections-modal/edit-sections-modal.component';
import { FavoriteSearchModalComponent } from './modals/favorite-search-modal/favorite-search-modal.component';
import { HistoryActionModalComponent } from './modals/history-action-modal.component/history-action-modal.component';
import { HistoryOutcomesModalComponent } from './modals/history-outcomes-modal/history-outcomes-modal.component';
import { HistoryOutcomesPayableModalComponent } from './modals/history-outcomes-payable-modal/history-outcomes-payable-modal.component';
import { InsertMailAddressModalComponent } from './modals/insert-mail-address-modal/insert-mail-address-modal.component';
import { MassiveActionConfirmModalComponent } from './modals/massive-action-confirm-modal/massive-action-confirm-modal.component';
import { OutcomePreviewModalComponent } from './modals/outcome-preview-modal/outcome-preview-modal.component';
import { RegimeMinimiModalComponent } from './modals/regime-minimi-modal/regime-minimi-modal.component';
import { SendAnalogicCopyModalComponent } from './modals/send-analogic-copy-modal/send-analogic-copy-modal.component';
import { SignDocModalComponent } from './modals/sign-doc-modal/sign-doc-modal.component';
import { SpoolModalComponent } from './modals/spool-modal/spool-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { BlockUIModule } from '@ctel/block-ui';
import { GawWorkflowModule } from '@ctel/gaw-workflow';
import { ContactDetailsModalComponent } from 'app/modules/address-book/contact-details-modal/contact-details-modal.component';
import { DisplayDocumentModule } from 'app/shared/components/display-document/display-document.module';
import { FormValidationModule } from 'app/shared/components/form-validation/form-validation.module';
import { PaginationModule } from 'app/shared/components/pagination/pagination.module';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CheckPassModalComponent } from './modals/check-pass-modal/check-pass-modal.component';
import { DocSeriesSelectionModalComponent } from './modals/docseries-selection-modal/docseries-selection-modal.component';
import { PrintBarcodeModalComponent } from './modals/print-barcode-modal/print-barcode-modal.component';
import { RoutingUtilityModule } from './utilities/routing/routing-utility.module';

@NgModule({
	imports: [
		CommonModule,
		PaginationModule,
		FormsModule,
		ReactiveFormsModule,
		BlockUIModule,
		TooltipModule,
		ErrorPlaceholderModule,
		PipesModule,
		GawWorkflowModule,
		TabsModule,
		// DryComponentsModule,
		PdfViewerModule,
		DisplayDocumentModule,
		FormValidationModule,
		DragDropModule,
		RouterModule,
		RoutingUtilityModule
	],
	declarations: [
		ActionProtocolModalComponent,
		ChangeCfModalComponent,
		HistoryActionModalComponent,
		HistoryOutcomesModalComponent,
		InsertMailAddressModalComponent,
		MassiveActionConfirmModalComponent,
		OutcomePreviewModalComponent,
		SendAnalogicCopyModalComponent,
		SignDocModalComponent,
		SpoolModalComponent,
		RegimeMinimiModalComponent,
		HistoryOutcomesPayableModalComponent,
		ActionOriginalPdfModalComponent,
		EditKeysModalComponent,
		ActionConfirmationModalComponent,
		NotificationModalComponent,
		ExcelFormatModalComponent,
		EditOfficeDeskModalComponent,
		EditKpiModalComponent,
		EditSectionsModalComponent,
		FavoriteSearchModalComponent,
		AlertNewsModalComponent,
		ContactDetailsModalComponent,
		CheckPassModalComponent,
		PrintBarcodeModalComponent,
		DocSeriesSelectionModalComponent
	],
	exports: [
		ActionProtocolModalComponent,
		ChangeCfModalComponent,
		HistoryActionModalComponent,
		HistoryOutcomesModalComponent,
		InsertMailAddressModalComponent,
		MassiveActionConfirmModalComponent,
		OutcomePreviewModalComponent,
		SendAnalogicCopyModalComponent,
		SignDocModalComponent,
		SpoolModalComponent,
		RegimeMinimiModalComponent,
		HistoryOutcomesPayableModalComponent,
		ActionOriginalPdfModalComponent,
		EditKeysModalComponent,
		ActionConfirmationModalComponent,
		NotificationModalComponent,
		ExcelFormatModalComponent,
		EditOfficeDeskModalComponent,
		EditKpiModalComponent,
		EditSectionsModalComponent,
		FavoriteSearchModalComponent,
		AlertNewsModalComponent,
		ContactDetailsModalComponent,
		PrintBarcodeModalComponent,
		DocSeriesSelectionModalComponent
	]
})
export class AppCommonModule {
}
