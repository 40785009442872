<div class="modal-body format-text">
  <div class="row" *ngIf="data.info.supportiInAltriRss">
    <div class="col-md-12">
      <p>
        <strong
          >Sono stati selezionati {{ data.info.supportiDaEliminare }} supporti da eliminare, di cui
          {{ data.info.supportiInAltriRss }} già contenuti in richieste di scarto; confermando l’operazione saranno
          inseriti in una richiesta di scarto solo i supporti selezionati disponibili per lo scarto</strong
        >
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <p>
        Gentile utente, i PdA selezionati sono contenuti in {{ data.info.supportiDaEliminare }} supporti logici. Se ha
        necessità di riceverli, si prega di selezionare la richiesta della copia:
      </p>
      <ng-container *ngIf="data.info.aziendaIntermediata; else noIntermediary">
        <div class="checkbox">
          <label for="richiediCopiaCosto">
            <input type="checkbox" [(ngModel)]="accept" id="richiediCopiaCosto" />
            Richiedi copia al costo stabilito con l’intermediario.
          </label>
        </div>
      </ng-container>
      <ng-template #noIntermediary>
        <ng-container *ngIf="data.info.prezzoAzienda > 0; else noPrice">
          <div class="checkbox">
            <label for="richiediCopiaPrezzo">
              <input type="checkbox" [(ngModel)]="accept" id="richiediCopiaPrezzo" /> Richiedi copia al prezzo di
              {{ data.info.prezzoTotale }} €.
            </label>
          </div>
        </ng-container>
        <ng-template #noPrice>
          <div class="checkbox">
            <label for="richiediCopiaConvenuto">
              <input type="checkbox" [(ngModel)]="accept" id="richiediCopiaConvenuto" />
              Richiedi copia al prezzo contrattualmente convenuto.
            </label>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn gaw-btn btn-light" (click)="modal.hide()">Annulla</button>
  <button type="submit" class="btn gaw-btn btn-primary" [disabled]="checkDisable()" (click)="executeAction()">
    Conferma
  </button>
</div>
