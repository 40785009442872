import { NotificationType } from '../notification-type';
import { SnackbarAction } from './snackbar-action';

export class SnackbarInfo {
	constructor(
		public type: NotificationType,
		public message: string,
		public action?: SnackbarAction,
		public clearBeforeShow = false
	) { }
}

