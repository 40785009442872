<div class="modal-body">
  <input
    class="form-control search-filter"
    type="text"
    placeholder="Inserisci Filtro..."
    (keyup)="searchFilters($event)"
  />
  <div class="tipo-documento-list">
    <ul>
      <li *ngFor="let t of filtered; let i = index">
        <input
          class="single-checkbox"
          type="checkbox"
          value="{{ t.key }}"
          id="chk-{{ t.key }}"
          [checked]="getChecked(t.key)"
          (click)="check(t.key)"
          [disabled]="disable && !checkedList[i]"
        />
        <label for="chk-{{ t.key }}">{{ t.key }}</label>
      </li>
    </ul>
  </div>
</div>
<div class="modal-footer">
  <h5 class="modal-footer-text">Limite filtri selezionabili: {{ filterLimit }}</h5>
  <button class="btn btn-success" id="action-confirm-button" (click)="applyFilters()"><strong>Applica</strong></button>
  <button type="button" class="btn btn-danger" (click)="modal.hide()">Annulla</button>
</div>
