import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe che evidenzia la stringa cercata nei risultati
 */
@Pipe({
  name: 'highlightSearch',
})
export class HighlightSearchPipe implements PipeTransform {
  transform(text: string, searchText: string): string {
    if (!text) {
      return '';
    }

    if (!searchText) {
      return text;
    }

    if (searchText.length < 2) {
      return text;
    }

    const regex = new RegExp(searchText, 'gi');

    const sub = text.match(regex);
    if (sub === null) {
      return text;
    }

    return text.replace(regex, `<mark>${sub[0]}</mark>`);

    // versione alternativa
    // const pos = text.toLowerCase().indexOf(searchText.toLowerCase());
    // const len = searchText.length;
    // const sub = text.substr(pos, len);
    // return text.replace(regex, `<mark>${sub}</mark>`);
  }
}
