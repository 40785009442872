import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FatturaElettronicaParserService } from './fattura-elettronica-parser.service';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [],
	exports: [],
})
export class FatturaElettronicaParserModule {
	static forRoot(): ModuleWithProviders<FatturaElettronicaParserModule> {
		return {
			ngModule: FatturaElettronicaParserModule,
			providers: [
				FatturaElettronicaParserService,
			]
		};
	}
}
