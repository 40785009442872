import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'app/core/common/config/config.service';
import { Observable } from 'rxjs';
import { AppConfig } from 'app/entities/config/app-config';
import { EnabledService, EnabledServiceEndpoint, EnabledServiceEndpointResponse } from './enabled-service';
import { Service } from 'app/entities/services/service.interface';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class EnabledServicesHttpService {

	private gawUserPortalServiceHost: string;
	private enabledServicesApi: string;

	constructor(
		private httpClient: HttpClient,
		public configService: ConfigService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	getServiceConfiguration(): Observable<Service[]> {
		const url = '/assets/homepage/mock/service-config.json';
		return this.httpClient.get<Service[]>(url);
	}

	public getHttpEnabledService(idlicenza, codsia): Observable<EnabledServiceEndpoint[]> {
		const url = this.enabledServicesApi.replace('{idLicenza}/{codsia}', `${idlicenza}/${codsia}`);
		return this.httpClient.get<EnabledServiceEndpointResponse[]>(url).pipe(map((v) => EnabledService.mapEnabledSearchEndPoint(v))
		);
	}

	private httpHostInit(appConfig: AppConfig) {
		this.gawUserPortalServiceHost = appConfig.gawUserPortalService.http.host;
		this.enabledServicesApi = `${this.gawUserPortalServiceHost}/api/v1/UserPortalServices/userenabledservices/{idLicenza}/{codsia}`;
	}

}
