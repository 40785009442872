import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentAttachmentPreviewComponent } from './document-attachments/document-attachment-preview/document-attachment-preview.component';
import { DocumentAttachmentsListComponent } from './document-attachments/document-attachments-list/document-attachments-list.component';
import { DocumentPreviewComponent } from './document-preview/document-preview.component';
import { StatusHistoryComponent } from './status-history/status-history.component';
import { UserAttachmentPreviewComponent } from './user-attachments/user-attachment-preview/user-attachment-preview.component';
import { UserAttachmentsListComponent } from './user-attachments/user-attachments-list/user-attachments-list.component';

const workitemPreviewOutlet = 'workitem-detail';

export const routes: Routes = [
	{ path: 'document-preview', outlet: workitemPreviewOutlet, component: DocumentPreviewComponent },
	{ path: 'document-attachments', outlet: workitemPreviewOutlet, component: DocumentAttachmentsListComponent },
	{
		path: 'document-attachment-preview/:documentAttachmentId',
		outlet: workitemPreviewOutlet,
		component: DocumentAttachmentPreviewComponent
	},
	{ path: 'user-attachments', outlet: workitemPreviewOutlet, component: UserAttachmentsListComponent },
	{ path: 'user-attachment-preview/:userAttachmentId', outlet: workitemPreviewOutlet, component: UserAttachmentPreviewComponent },
	{ path: 'status-history', outlet: workitemPreviewOutlet, component: StatusHistoryComponent },
];

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class WorkitemDetailRoutingModule {
}
