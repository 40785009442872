import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BlockUIModule } from '@ctel/block-ui';
import { ErrorPlaceholderModule, PlaceholderModule } from '@ctel/placeholders';
import { UploadLottoZipModule } from 'app/modules/upload-lotto-zip/upload-lotto-zip.module';
import { DisplayDocumentModule } from 'app/shared/components/display-document/display-document.module';
import { DryComponentsModule } from 'app/shared/components/dry/dry-components.module';
import { ReactiveFormValidationModule } from 'app/shared/components/form-validation/reactive-form/reactive-form-validation.module';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DocumentsSearchModule } from './af-documents-search/documents-search.module';
import { ArchivioFiscaleDetailMainComponent } from './archivio-fiscale-detail/archivio-fiscale-detail-main/archivio-fiscale-detail-main.component';
import { PreviousVersionComponent } from './archivio-fiscale-detail/archivio-fiscale-detail-main/previous-version/previous-version.component';
import { ArchivioFiscaleDetailComponent } from './archivio-fiscale-detail/archivio-fiscale-detail.component';
import { FRDocumentiDocumentsSearchModule } from './firma-remota-documenti-documents-search/f-r-documenti-documents-search.module';
import { FRLottiDocumentsSearchModule } from './firma-remota-lotti-documents-search/f-r-lotti-documents-search.module';
import { ConfirmAnomalyComponent } from './modals/confirm-anomaly/confirm-anomaly.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { PDDOutcomeModalComponent } from './modals/pdd-outcome-modal/pdd-outcome-modal.component';
import { PDDRequestModalComponent } from './modals/pdd-request-modal/pdd-request-modal.component';
import { SignReasonModalComponent } from './modals/sign-reason-modal/sign-reason-modal.component';
import { PddDocumentsSearchModule } from './pdd-documents-search/pdd-documents-search.module';
import { FiscalYearModalComponent } from './modals/fiscal-year-modal/fiscal-year-modal.component';
import { RemovePdaModalComponent } from './modals/remove-pda-modal/remove-pda-modal.component';
import { UploadRequestModalComponent } from './modals/upload-request-modal/upload-request-modal.component';
import { DirectivesModule } from '../../../../../src/app/shared/directives/directives.module';

@NgModule({
	declarations: [
		ArchivioFiscaleDetailComponent,
		ArchivioFiscaleDetailMainComponent,
		PreviousVersionComponent,
		PDDRequestModalComponent,
		PDDOutcomeModalComponent,
		SignReasonModalComponent,
		ConfirmModalComponent,
		ConfirmAnomalyComponent,
		FiscalYearModalComponent,
  RemovePdaModalComponent,
  UploadRequestModalComponent
	],
	imports: [
		DocumentsSearchModule,
		PddDocumentsSearchModule,
		FRLottiDocumentsSearchModule,
		FRDocumentiDocumentsSearchModule,
		CommonModule,
		DisplayDocumentModule,
		DryComponentsModule,
		PipesModule,
		ErrorPlaceholderModule,
		PlaceholderModule,
		FormsModule,
		RouterModule,
		PopoverModule,
		TooltipModule,
		UploadLottoZipModule,
		BlockUIModule,
		TabsModule,
		ReactiveFormValidationModule,
		ReactiveFormsModule,
		DirectivesModule
	],
	exports: [ArchivioFiscaleDetailComponent, ArchivioFiscaleDetailMainComponent]
})
export class GawconsCommonsModule {
}
