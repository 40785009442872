export enum FaIcons {
	FA_LG = 'fa-lg',
	FA_2X = 'fa-2x',
	FA_3X = 'fa-3x',
	FA_4X = 'fa-4x',
	FA_5X = 'fa-5x',
	FA_STACK = 'fa-stack',
	FA_STACK_1X = 'fa-stack-1x',
	FA_STACK_2X = 'fa-stack-2x',
	FA_SPIN = 'fa-spin',
	FAS_CHECK = 'fas fa-check',
	FAS_CHECK_CIRCLE = 'fas fa-check-circle',
	FAR_EYE = 'far fa-eye',
	FAR_EYE_SLASH = 'far fa-eye-slash',
	FAS_POWER_OFF = 'fas fa-power-off',
	FAR_FILE_CODE = 'far fa-file-code',
	FAS_INDUSTRY = 'fas fa-industry',
	FAS_UPLOAD = 'fas fa-upload',
	FAS_SEARCH = 'fas fa-search',
	FAS_SEARCH_PLUS = 'fas fa-search-plus',
	FAS_PAPER_CLIP = 'fas fa-paperclip',
	FAS_SEARCH_MINUS = 'fas fa-search-minus',
	FAS_ANGLE_DOWN = 'fas fa-angle-down',
	FAS_ANGLE_LEFT = 'fas fa-angle-left',
	FAS_ANGLE_RIGHT = 'fas fa-angle-right',
	FAS_ANGLE_UP = 'fas fa-angle-up',
	FAS_PENCIL = 'fas fa-pencil-alt',
	FAR_PENCIL_SQUARE = 'far fa-pencil-square',
	FAR_EXCEL = 'far fa-file-excel',
	FAS_ATTACHMENT = 'fas fa-paperclip',
	FAS_DOWNLOAD = 'fas fa-download',
	FAS_SHARE = 'fas fa-share',
	FAS_FORWARD = 'fas fa-forward',
	FAR_PDF = 'far fa-file-pdf',
	FAR_FILE = 'far fa-file',
	FAR_TEXT = 'far fa-file-alt',
	FAS_ARROW_LEFT = 'fas fa-arrow-left',
	FAS_ARROW_CIRCLE_DOWN = 'fas fa-arrow-circle-down',
	FAR_ZIP = 'far fa-file-archive',
	FAS_HISTORY = 'fas fa-history',
	FAR_CHANGES = 'far fa-edit',
	FAR_TRASH = 'far fa-trash-alt',
	FAS_QUESTION = 'fas fa-question',
	FAS_TRUCK = 'fas fa-truck',
	FAS_BAN = 'fas fa-ban',
	FAS_TIMES = 'fas fa-times',
	FAS_TIMES_CIRCLE = 'fas fa-times-circle',
	FAS_CLOUD = 'fas fa-cloud',
	FAS_CLOUD_UPLOAD = 'fas fa-cloud-upload-alt',
	FAS_MAGIC = 'fas fa-magic',
	FAS_COG = 'fas fa-cog',
	FAS_PLUS = 'fas fa-plus',
	FAS_MINUS = 'fas fa-minus',
	FAS_ELLIPSIS_H = 'fas fa-ellipsis-h',
	FAS_ELLIPSIS_V = 'fas fa-ellipsis-v',
	FAS_PLUS_SQUARE = 'fas fa-plus-square',
	FAS_PLUS_CIRCLE = 'fas fa-plus-circle',
	FAS_MINUS_CIRCLE = 'fas fa-minus-circle',
	FAS_BARS = 'fas fa-bars',
	FAS_HOME = 'fas fa-home',
	FAS_BELL = 'fas fa-bell',
	FAS_CERT = 'fas fa-certificate',
	FAS_SIGN = 'fas fa-sign',
	FAS_CHEVRON_UP = 'fas fa-chevron-up',
	FAS_CHEVRON_DOWN = 'fas fa-chevron-down',
	FAS_CHEVRON_LEFT = 'fas fa-chevron-left',
	FAS_CHEVRON_RIGHT = 'fas fa-chevron-right',
	FAS_SORT = 'fas fa-sort',
	FAS_SORT_ASC = 'fas fa-sort-asc',
	FAS_SORT_DESC = 'fas fa-sort-desc',
	FAS_REFRESH = 'fas fa-sync-alt',
	FAS_LINK = 'fas fa-link',
	FAS_EXCLAMATION_TRIANGLE = 'fas fa-exclamation-triangle',
	FAR_QUESTION_CIRCLE = 'far fa-question-circle',
	FAR_USER_CIRCLE = 'far fa-user-circle',
	FAS_CIRCLE_NOTCH = 'fas fa-circle-notch',
	FAR_CIRCLE = 'far fa-circle',
	FAS_TAGS = 'fas fa-tags',
	FAS_LIST = 'fas fa-list',
	FAS_FILTER = 'fas fa-filter',
	FAR_OUTCOME = 'far fa-comments',
	FAS_COMMENTING = 'fas fa-commenting',
	FAS_COMMENT_DOTS = 'fas fa-comment-dots',
	FAR_COPY = 'far fa-copy',
	FAS_RETWEET = 'fas fa-retweet',
	FAS_INBOX = 'fas fa-inbox',
	FAS_CARET_DOWN = 'fas fa-caret-down',
	FAR_FILE_WORD = 'far fa-file-word',
	FAR_ENVELOPE = 'far fa-envelope',
	FAR_FILE_IMAGE = 'far fa-file-image',
	FAR_FILE_ARCHIVE = 'far fa-file-archive',
	FAR_FILE_ALT = 'far fa-file-alt',
	FAR_CHART_BAR = 'far fa-chart-bar',
	FAS_FILE_CONTRACT = 'fas fa-file-contract',
	FAR_FILE_POWERPOINT = 'far fa-file-powerpoint',
	FAR_STICKY_NOTE = 'far fa-sticky-note',
	FAS_FILE_SIGNATURE = 'fas fa-file-signature',
	FAS_SLIDERS_H = 'fas fa-sliders-h'
}
