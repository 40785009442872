/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { TextValidators } from 'app/shared/components/dry/validator/text-validators';

@Directive({
	selector: '[atLeastNumber][ngModel]',
	providers: [
		{provide: NG_VALIDATORS, useExisting: AtLeastNumberValidatorDirective, multi: true}
	]
})
export class AtLeastNumberValidatorDirective implements Validator {

	private validator: ValidatorFn;

	constructor() {
		this.validator = TextValidators.atLeastOneNumber();
	}

	registerOnValidatorChange(fn: () => void): void {	}

	validate(c: AbstractControl): ValidationErrors | null {
		return this.validator(c);
	}
}
