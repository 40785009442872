import { Injectable } from '@angular/core';
import { Base64Service } from 'app/core/business/invoice-pa/create-edit-invoice/base64/base64.service';
import { Copier } from 'app/core/common/utilities/copier';
import { InvoiceModelList } from 'app/entities/invoice-pa/invoice-model/invoice-model-list';
import { ModelUploadBody } from 'app/entities/invoice-pa/invoice-model/model-upload-body';
import { HttpMessage } from 'app/entities/invoice-pa/upload-lotto-message/upload-lotto-message';
import * as moment from 'moment';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { XmlPaValidatorService } from '../xml-pa-validator/xml-pa-validator.service';
import { InvoiceModelsHttpService } from './invoice-models-http.service';
import { OrderField, OrderType } from './order-field-type.enum';

@Injectable()
export class InvoiceModelsService {
	public creationDate = new Date();
	private loadedModel$ = new BehaviorSubject<ModelUploadBody>(null);
	private canUpdateAndReloadModel$ = new BehaviorSubject<boolean>(false);
	private modelDefaultExist$ = new BehaviorSubject<boolean>(null);
	private cedentePrestatoreLoading$ = new BehaviorSubject<boolean>(true);

	constructor(
		private invoiceModelHttpService: InvoiceModelsHttpService,
		private xmlPaValidatorService: XmlPaValidatorService,
		private base64service: Base64Service,
	) { }

	public getListModels(
		license: string, sia: string, pageIndex: number, orderField?: OrderField, orderType?: OrderType
	): Observable<InvoiceModelList> {
		return this.invoiceModelHttpService.getListModelsFromDB(license, sia, pageIndex, orderField, orderType).pipe(map(
			x => {
				if (x.counter > 0)
					for (let i = 0; i < x.models.length; i++)
						x.models[i].dataUltimModifica.value = new Date(x.models[i].dataUltimModifica.value).toLocaleDateString('it-IT');

				return x;
			}
		));
	}

	public createModel(
		license: string, sia: string, modelName: string, modelDescription: string, invoiceModel: string,
		isDefaultModel: boolean, isUpdateModelAction: boolean, modelId?: string
	) {
		const modelPayload: ModelUploadBody = {};
		// se viene passato il model id, vuol dire che sto facendo l'update dalla lista modelli
		if (modelId) {
			modelPayload.idModello = modelId;
			modelPayload.isDefaultModel = isDefaultModel;
		} else if (isUpdateModelAction) {
			// se è un'azione di update, prendo l'id del modello caricato
			modelPayload.idModello = this.whenLoadedModel().value.idModello;
			modelPayload.isDefaultModel = isDefaultModel;
		} else
			// altrimento creo nuovo modello
			modelPayload.idModello = null;

		modelPayload.idLicenza = license;
		modelPayload.codSia = sia;
		modelPayload.nomeModello = modelName;
		modelPayload.descrizione = modelDescription;
		modelPayload.dataModello = moment(this.creationDate).utc().toISOString();
		modelPayload.fileBytes = invoiceModel;
		// upload invoice model
		return this.invoiceModelHttpService.saveModel(modelPayload).pipe(
			tap(() => {
				if (isUpdateModelAction) {
					const updatedModel: ModelUploadBody = {
						nomeModello: modelName,
						idModello: modelPayload.idModello,
						codSia: sia,
						descrizione: modelDescription,
						fileBytes: invoiceModel,
						idLicenza: license,
					};
					this.setLoadedModel(updatedModel);
				}
			})
		);
	}

	public deleteModel(license: string, sia: string, modelId: string, modelName: string): Observable<HttpMessage> {
		return this.invoiceModelHttpService.deleteModel(license, sia, modelId, modelName);
	}

	public loadModel(license: string, sia: string, modelId: string, modelName: string, isReloadModel = false): Observable<ModelUploadBody> {
		return this.invoiceModelHttpService.getModel(license, sia, modelId, modelName).pipe(
			tap((data: ModelUploadBody) => {
				if (data && !isReloadModel)
					this.setLoadedModel(data);

			})
		);
	}

	public loadModelToUpdateDefaultModel(license: string, sia: string, modelId: string, modelName: string): Observable<ModelUploadBody> {
		return this.invoiceModelHttpService.getModel(license, sia, modelId, modelName);
	}

	public getDefaultModel(license: string, sia: string): Observable<ModelUploadBody> {
		return this.invoiceModelHttpService.getDefaultModel(license, sia).pipe(
			tap((data: ModelUploadBody) => {
				if (data) {
					this.setLoadedModel(data);
					this.setModelDefaultExist(true);
					this.setCedentePrestatoreLoading(false);
				} else {
					this.setCedentePrestatoreLoading(true);
					this.setModelDefaultExist(false);
				}
			}),
			catchError(() => {
				this.setCedentePrestatoreLoading(true);
				this.setModelDefaultExist(false);
				return EMPTY;
			})
		);
	}

	public setCedentePrestatoreLoading(value: boolean) {
		this.cedentePrestatoreLoading$.next(value);
	}

	public whenCedentePrestatoreLoading(): Observable<boolean> {
		return this.cedentePrestatoreLoading$.asObservable();
	}

	public setModelDefaultExist(value: boolean) {
		this.modelDefaultExist$.next(value);
	}

	public setLoadedModel(data: ModelUploadBody) {
		this.canUpdateAndReloadModel$.next(true);
		this.loadedModel$.next(data);
		if (data)
			this.canUpdateAndReloadModel$.next(true);
		else
			this.canUpdateAndReloadModel$.next(false);

	}

	public whenLoadedModel() {
		return this.loadedModel$;
	}

	public whenCanUpdateAndReloadModel(): Observable<boolean> {
		return this.canUpdateAndReloadModel$.asObservable();
	}

	public createXmlModel(jsObj) {
		return Copier.deepCopy(this.base64service.encode(this.xmlPaValidatorService.json2xml(jsObj)));
	}

	public whenDefaultModelExist() {
		return this.modelDefaultExist$;
	}

	public reset() {
		this.canUpdateAndReloadModel$.next(false);
		this.loadedModel$.next(null);
		this.setModelDefaultExist(null);
	}
}
