/* eslint-disable no-console */
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
	AbstractFilterEffects,
	DocumentActions,
	FilterAction,
	FilterActions,
	FilterState,
	IAdvancedTextSearchUrl,
	IFilterStatus,
	IFilterUrl,
	IFullTextSearchUrl,
	IOrderByUrl,
	IOuterFilterUrl,
	IPagingUrl,
	IRouterStateUrl,
	getRouterInfo
} from '@ctel/search-filter-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { ErrorTypes } from 'app/core/common/error';
import { EMPTY } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { UiConfigurationService } from '../../../ui-configuration/ui-configuration.service';
import { DocumentsHttpService } from '../../documents-http.service';
import { PdvDocumentsService2 } from '../../documents/documents.service';
import { relatedSearchDataFetchedPDV, sectionColumnsFetchedPDV } from '../../documents/store/document.extended';
import { PdvFilterService2 } from '../filter.service';
import { relatedSearchDataRequestedPDV, sectionColumnsRequestedPDV } from './filter.actions';
import ChangeFilterRequestedKind = FilterActions.ChangeFilterRequestedKind;
import { NotificationType } from '../../../../../../../../src/app/core/common/notification';
import { FiscalYearModalComponent } from '../../../modals/fiscal-year-modal/fiscal-year-modal.component';
import { NotificationService } from '../../../../../../../../src/app/core/common/notification/notification.service';

const PATH = 'gawcons/gcons_pacchettiDiVersamento/';

@Injectable({
	providedIn: 'root'
})
export class FilterEffects extends AbstractFilterEffects {

	/**
	 * Effetto che gestisce il side effect di aver fetchato i filtri preferiti,
	 * e per i quali è necessario recuperare nuovi documenti.
	 */
	onFavoriteFiltersFetched$ = createEffect(() => this.actions$.pipe(
		ofType(FilterActions.favoriteFiltersFetched),
		withLatestFrom(this.store.pipe(select(getRouterInfo))),
		filter(([, routerInfo]) => routerInfo.state.url.indexOf(PATH) !== -1),
		switchMap(() => [
			DocumentActions.fetchDocuments(true),
			DocumentActions.fetchDocumentsActions()
		])
	));

	onRelatedSearchDataRequested$ = createEffect(() => this.actions$
		.pipe(
			ofType(relatedSearchDataRequestedPDV),
			withLatestFrom(this.store.pipe(select(getRouterInfo))),
			switchMap(([action, routerInfo]) =>
				this.uiConfigurationService.getRelatedData(
					routerInfo.state.params['license'],
					routerInfo.state.params['siacode'],
					'gcons_pacchettiDiVersamento'
				).pipe(
					switchMap(relatedSectionData => {
						const newFilterStatus: IFilterStatus = this.documentsService.buildFilterPayload(
							routerInfo.state.params['license'], routerInfo.state.params['siacode'],
							routerInfo.state.params['doctype'], action.payload.filterResponse
						);
						return [
							relatedSearchDataFetchedPDV(relatedSectionData),
							sectionColumnsFetchedPDV(action.payload.primaryConfig, action.payload.secondaryConfig),
							FilterActions.favoriteFiltersFetched({ filterStatus: newFilterStatus })
						];
					}),
					catchError((error: unknown) => this.handleError(error))
				)
			)));

	onMostraTutti$ = createEffect(() => this.actions$.pipe(
		ofType(FilterActions.editFilterRequested),
		withLatestFrom(this.store.pipe(select(getRouterInfo))),
		filter(([, routerInfo]) => routerInfo.state.url.indexOf(PATH) !== -1),
		tap(([action]) => {
			this.notificationService.showModal(NotificationType.GENERAL, {
				title: action.filter.metadataDescription,
				customFooter: true,
				childComponent: FiscalYearModalComponent,
				childData: action,
				disableClickOutside: false
			});
		})
	), { dispatch: false });

	onSectionColumnsRequested$ = createEffect(() => this.actions$
		.pipe(
			ofType(sectionColumnsRequestedPDV),
			withLatestFrom(this.store.pipe(select(getRouterInfo))),
			filter(([, routerInfo]) =>
				routerInfo.state.params['license'] !== undefined
				&& routerInfo.state.params['siacode'] !== undefined
			),
			switchMap(([action, routerInfo]) =>
				this.uiConfigurationService.getSectionColumns(
					routerInfo.state.params['license'],
					routerInfo.state.params['siacode'],
					'gcons_pacchettiDiVersamento'
				).pipe(
					switchMap(sectionColumns => [
						relatedSearchDataRequestedPDV(action.payload.filterResponse,
							sectionColumns.primaryConfig, sectionColumns.secondaryConfig)
					]),
					catchError((error: unknown) => {
						console.debug('Non trovato: ', error);
						if (error['type'] === ErrorTypes.HTTP_NOT_FOUND)
							return [
								relatedSearchDataRequestedPDV(action.payload.filterResponse,
									[], [])
							];

						return EMPTY;
					})
				)
			)
		));

	/**
	 * Effetto che gestisce il side effect della richiesta dei filtri preferiti,
	 * che è necessario recuperare.
	 */
	onFavouritesFiltersRequested$ = createEffect(() => this.actions$.pipe(
		ofType(FilterActions.favouritesFiltersRequested),
		withLatestFrom(this.store.pipe(select(getRouterInfo))),
		filter(([, routerInfo]) => routerInfo.state.url.indexOf(PATH) !== -1),
		switchMap(([, routerInfo]) => this.documentsHttpService.whenFavoriteFilters(
			'gcons_pacchettiDiVersamento',
			routerInfo.state.params['license'],
			routerInfo.state.params['siacode']).pipe(
			take(1),
			map(filtersResponse => {
				this.documentsService.setErrorLoadingDocs(false);
				// const newFilterStatus: IFilterStatus = {
				// 	docSeriesId: routerInfo.state.params['doctype'],
				// 	siaCode: routerInfo.state.params['siacode'],
				// 	licenseId: routerInfo.state.params['license'],
				// 	filters: filtersResponse.filters,
				// 	search: filtersResponse.search,
				// 	paging: filtersResponse.paging,
				// 	orderBy: filtersResponse.orderBy
				// };
				return sectionColumnsRequestedPDV(filtersResponse);
			}),
			catchError((error: unknown) => {
				this.documentsService.setErrorLoadingDocs(true);
				return this.handleError(error);
			}))
		)
	));

	constructor(
		protected actions$: Actions,
		protected store: Store<FilterState>,
							protected router: Router,
							protected activatedRoute: ActivatedRoute,
							private documentsHttpService: DocumentsHttpService,
							private documentsService: PdvDocumentsService2,
							protected filterService: PdvFilterService2,
		private uiConfigurationService: UiConfigurationService,
		protected ngZone: NgZone,
							private notificationService: NotificationService
	) {
		super(actions$, store, filterService, router, activatedRoute, ngZone);
	}

	protected doOnChangeFilterRequested(
		action: FilterAction, routerInfo: { state: IRouterStateUrl; navigationId: number; }, filtersUrl: IFilterUrl[],
		outerFiltersUrl: IOuterFilterUrl[], pagingFiltersUrl: IPagingUrl, orderByFiltersUrl: IOrderByUrl[],
		fullTextSearchUrl: IFullTextSearchUrl, advancedTextSearchUrl: IAdvancedTextSearchUrl[]
	): void {
		if (routerInfo.state.url.indexOf(PATH) === -1)
			return;

		if (action.kind === ChangeFilterRequestedKind.PagingChanged)
			this.documentsService.setLoadingDocsOnPaging(true);
		else {
			this.documentsService.setLoadingDocsAfterFilterApplication(true);
			this.documentsService.setLoadingDocsOnPaging(false);
		}
		super.doOnChangeFilterRequested(action, routerInfo, filtersUrl, outerFiltersUrl, pagingFiltersUrl, orderByFiltersUrl,
			fullTextSearchUrl, advancedTextSearchUrl);
	}

	protected doNavigationChangedOnDocTypeParamsChanged() {
		this.documentsService.setLoadingDocs(true);
		return super.doNavigationChangedOnDocTypeParamsChanged();
	}

	protected getDocumentDetailUrl(): string {
		return '/details/';
	}

	protected getDocumentsListUrl(): string {
		return '/gawcons/gcons_pacchettiDiVersamento';
	}

	protected getUrlCommands(routerInfo: { state: IRouterStateUrl; navigationId: number; }): string[] {
		const urlCommands: string[] = [];
		urlCommands.push(this.getDocumentsListUrl());
		urlCommands.push(routerInfo.state.params['license']);
		urlCommands.push(routerInfo.state.params['siacode']);
		urlCommands.push(routerInfo.state.params['section']);
		return urlCommands;
	}

	protected getParamsDistinctCriteria(prev: Params, succ: Params): boolean {
		if (this.router.url.indexOf(this.getDocumentsListUrl()) === -1)
			return false;

		return super.getParamsDistinctCriteria(prev, succ);
	}
}
