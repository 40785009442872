import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDetailsComponent } from '@ctel/gaw-commons';
import { DocumentDetailsService } from '@ctel/gawpass-commons';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { AnomalyDetails } from 'app/entities/anomalies/anomaly-details';
import { ModifyAnomaly } from 'app/entities/anomalies/modify-anomaly';
import { Observable, take } from 'rxjs';
import { AnomaliesService } from '../../../services/anomalies.service';

@Component({
	selector: 'gaw-view-edit-anomaly',
	templateUrl: './view-edit-anomaly.component.html',
	styleUrls: ['./view-edit-anomaly.component.scss'],
})
export class ViewEditAnomalyComponent implements OnInit {
	@Input() data: IDetailsComponent;
	@Input() edit: boolean;
	anomalyDetails$: Observable<AnomalyDetails>;
	disableButton = false;

	constructor(
		private notificationService: NotificationService,
		private anomaliesService: AnomaliesService,
		private router: Router,
		private route: ActivatedRoute,
		private documentDetailsService: DocumentDetailsService
	) { }

	ngOnInit(): void {
		this.anomalyDetails$ = this.anomaliesService.whenAnomalyDetails(
			this.data.licenseId,
			this.data.siaCode,
			this.data.keys['codGrafica'],
			this.data.keys['idSospeso']
		);
	}

	save(anomalyDetails: AnomalyDetails) {
		this.disableButton = true;
		const anomaly: ModifyAnomaly = {
			idSospeso: this.data.keys['idSospeso'],
			idLicenza: this.data.licenseId,
			codSia: this.data.siaCode,
			codGrafica: this.data.keys['codGrafica'],
			stato: this.data.keys['idStato'],
		};
		anomalyDetails.campi.forEach((value) => {
			anomaly[value.idCampo.split('_')[1].toLowerCase()] = value.value;
		});
		this.anomaliesService.modifyAnomaly(anomaly)
			.pipe(take(1))
			.subscribe({
				next: () => {
					this.documentDetailsService.anomalyEdit.next(false);
					this.disableButton = false;
				},
				// value => this.router.navigate(
				// 	['.'],
				// 	{ relativeTo: this.route }
				error: (error: unknown) => {
					this.disableButton = false;
					this.notificationService.showSweetAlert(
						NotificationType.ERROR,
						'Errore durante il salvataggio',
						error?.['additionalInfo'].find((value) => value.t === 'response').u.join('</br>') || 'Errore generico'
					);
				},
			});
	}
}
