export interface DocStatusHistoryOnService {
	serviceName: string;			// descrizione canale
	serviceId: number;					// codice canale
	documentStatus: DocumentStatus[];
}

export interface DocumentStatus {		// row della tabella log stati
	comunicationId: string;
	description: string;
	statusCode: number;
	serviceId: number;
	serviceName: string;
	isFinalStatus: true;
	statusDate: string;
	note: string;
}

export interface ComunicationAndService {
	serviceId: number;
	serviceName: string;
	comunicationId: string;
}

