import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Currency } from 'app/entities/invoice-pa/currency/currency';

@Injectable()
export class CurrencyHttpService {

	// endpoints
	private currency: string;
	private currencyHost: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.currencyHost = appConfig.gedInvoice.http.host;
		this.currency = `${this.currencyHost}/v1/GedInvoice/Resources/Divise`;
	}

	public getCurrency() {
		const url_ = this.currency;

		return this.http.get<Currency>(url_);
	}

}
