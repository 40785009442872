import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../common/config/config.service';
import { AppConfig } from '../../../entities/config/app-config';
import { Observable } from 'rxjs';
import { UploadResults } from '../../../entities/uploads-notification/uploads-notification';

@Injectable()
export class UploadsNotificationHttpService {

	private host: string;
	private getUploadNotificationsUri: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.host = appConfig.uploadDocument.http.host;
		this.getUploadNotificationsUri = `${this.host}/v1/Upload/UploadStatus`;
	}

	getUploadNotifications(): Observable<UploadResults[]> {
		const url = this.getUploadNotificationsUri;
		return this.http.get<UploadResults[]>(url);
	}
}
