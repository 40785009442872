<div
  class="navigation tooltip-350"
  placement="auto"
  container="body"
  tooltip="{{ navigationItem.description }}"
  [ngClass]="{ active: isActive }"
  [adaptivePosition]="false"
>
  <button
    class="nav-item btn-ghost w-100 fw-normal"
    [attr.aria-label]="navigationItem.description"
    (click)="onMenuItemClick(navigationItem)"
  >
    <img [alt]="navigationItem.description" *ngIf="navigationItem.img" [src]="navigationItem.img" />
    <i *ngIf="navigationItem.icon" [ngClass]="navigationItem.icon"></i>
    <p *ngIf="!navigationItem.hasLogo">{{ navigationItem.description }}</p>
  </button>
</div>
