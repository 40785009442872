<div class='modal-body'>
	<textarea class='form-control' placeholder='Inserisci nota' rows='3' [(ngModel)]='note'></textarea>
</div>
<div class="modal-footer">
	<button type="submit" class="btn gaw-default-blue-btn"
			id="excel-format-confirm-button"
	[disabled]='!note'
	(click)='executeAction()'>Conferma
	</button>
	<button type="button" class="btn gaw-danger-btn" (click)="modal.hide()">
		Annulla
	</button>
</div>
