import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { Field, FieldType } from 'app/shared/components/dry/field';
import { AbstractTableComponent } from 'app/shared/components/table/abstract-table.component';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
	selector: 'gaw-workitem-history-table',
	templateUrl: './workitem-history-table.component.html',
	styleUrls: ['./workitem-history-table.component.scss']
})
export class WorkitemHistoryTableComponent<T> extends AbstractTableComponent<T> {

	@ViewChild('f', { static: false }) form: NgForm;

	public faIcons = FaIcons;
	public readonly FIELD_TYPE = FieldType;

	filterColumn: string;

	formModel = {};

	constructor() {
		super();
	}

	get data() {
		return this._data;
	}

	get fields() {
		return this._fields;
	}

	@Input() set data(data: T[]) {
		this._data = data;
	}

	@Input() set fields(fields: Field[]) {
		this._fields = fields;
	}

	setFilter(fieldId: string, form: NgForm, popover: PopoverDirective) {
		popover.isOpen = false;
		// Se filtro per un valore nullo setto il `filterColumn` ad undefined
		this.filterColumn = form.value[fieldId] ? fieldId : undefined;
		this.formSubmit(form);
	}

	cleanForm(field: Field, f: NgForm, popover: PopoverDirective) {
		this.formModel = {};
		f.reset();
		this.setFilter(field.id, f, popover);
	}
}

