import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ConfigService } from 'app/core/common/config/config.service';
import { AnomalyDetails } from 'app/entities/anomalies/anomaly-details';
import { DocumentsDoubleKeys } from 'app/entities/anomalies/documents-double-keys';
import { ModifyAnomaly } from 'app/entities/anomalies/modify-anomaly';
import { AppConfig } from 'app/entities/config/app-config';

@Injectable({
	providedIn: 'root'
})
export class AnomaliesHttpService {
	private jsonContentType = 'application/json';
	private gawpassReadHost: string;
	private gawpassWriteHost: string;
	private anomalyDetail: string;
	private doubleKeys: string;
	private metadataList: string;
	private modifyAnomalyUrl: string;

	constructor(
		public configService: ConfigService,
		private http: HttpClient
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.gawpassReadHost = appConfig.gawPassRead.http.host;
		this.gawpassWriteHost = appConfig.gawPassWrite.http.host;

		this.anomalyDetail = `${this.gawpassReadHost}/api/v1/Anomalie/DettaglioAnomalia/{licenseId}/{siaCode}/{codGrafica}/{idSospeso}`;
		this.doubleKeys = `${this.gawpassReadHost}/api/v1/Anomalie/DocumentsByDoubleKeys/{idSospeso}`;
		this.metadataList = `${this.gawpassReadHost}/api/v1/Anomalie/MetadataList/{idSospeso}/{docSeriesId}`;
		this.modifyAnomalyUrl = `${this.gawpassWriteHost}/api/v1/Anomalie/ModificaAnomalia`;
	}

	whenDoubleKeys(idSospeso: any): Observable<DocumentsDoubleKeys[]> {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		const url = this.doubleKeys
			.replace('{idSospeso}', idSospeso);
		return this.http.get<any>(url, options).pipe(shareReplay({ bufferSize: 1, refCount: true }),
			map(value => value.docsByDoubleKeys));
	}

	whenAnomalyDetails(licenseId: string, siaCode: string, codGrafica, idSospeso: any): Observable<AnomalyDetails> {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		const url = this.anomalyDetail
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{codGrafica}', codGrafica)
			.replace('{idSospeso}', idSospeso);

		return this.http.get<AnomalyDetails>(url, options).pipe(shareReplay({ bufferSize: 1, refCount: true }));
	}

	whenMetadataList(idSospeso: string, docSeriesId: string) {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		const url = this.metadataList
			.replace('{docSeriesId}', docSeriesId)
			.replace('{idSospeso}', idSospeso);

		return this.http.get(url, options).pipe(shareReplay({ bufferSize: 1, refCount: true }));
	}

	modifyAnomaly(anomaly: ModifyAnomaly): Observable<any> {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		const url = this.modifyAnomalyUrl;

		return this.http.put(url, anomaly, options);
	}

	private getHttpOptions(contentType: string, responseType: string): object {
		const headers = new HttpHeaders().set('Content-Type', contentType);
		let options;
		switch (responseType) {
			case 'blob':
				options = {
					headers,
					responseType: 'blob' as const
				};
				break;
			case 'json':
			default:
				options = {
					headers,
					responseType: 'json' as const
				};
				break;
		}
		return options;
	}
}

