/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { NumberValidators } from 'app/shared/components/dry/validator/number-validators';

@Directive({
	selector: '[hasDecimal][ngModel]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: HasDecimalDirective, multi: true }
	]
})
export class HasDecimalDirective implements Validator {

	validator: ValidatorFn;

	constructor() {
		this.validator = NumberValidators.hasDecimal();
	}

	registerOnValidatorChange(fn: () => void): void { }

	validate(c: AbstractControl): ValidationErrors | null {
		return this.validator(c);
	}

}
