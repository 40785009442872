/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { NumberValidators } from 'app/shared/components/dry/validator/number-validators';

@Directive({
	selector: '[min][ngModel]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: MinValueValidatorDirective, multi: true }
	]
})
export class MinValueValidatorDirective implements Validator {

	validator: ValidatorFn;

	@Input('min') set minValue(minValue: number) {

		if (!minValue || isNaN(minValue))
			minValue = -Infinity;

		this.validator = NumberValidators.min(minValue);
	}

	registerOnValidatorChange(fn: () => void): void { }

	validate(c: AbstractControl): ValidationErrors | null {
		return this.validator(c);
	}
}
