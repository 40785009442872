import { PlatformLocation } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AccountService, ActionsHttpService, DocumentDetailsService, DocumentsService } from '@ctel/hubfe-commons';
import { CookiesForActionsService } from 'app/core/business/user/cookies-for-actions/cookies-for-actions.service';
import { AccountType } from 'app/core/common/account/accountType';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { ActionType } from 'app/entities/ui-config/action/action-type.enum';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMPTY } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

/**
 * Componente modal per inserimento firmatario e password per azione firma manuale
 */
@Component({
	selector: 'hub-sign-doc-modal',
	templateUrl: './sign-doc-modal.component.html',
	styleUrls: ['./sign-doc-modal.component.scss']
})
export class SignDocModalComponent implements OnInit {

	@Input() title: string;
	@Input() data: {
		payload: any;
		actionType: ActionType;
		actionUrl: string;
		refreshDocuments: boolean;
	};
	@Input() modal: BsModalRef;

	isErrorPwd = false;
	isErrorUser = false;
	docCount = 0;
	isSubmitLocked = false;

	constructor(
		private actionsHttpService: ActionsHttpService,
		private cookiesForActionsService: CookiesForActionsService,
		private documentsService: DocumentsService,
		private notificationService: NotificationService,
		private documentDetailsService: DocumentDetailsService,
		private accountService: AccountService,
		private location: PlatformLocation
	) {
		location.onPopState(() => {
			this.modal.hide();
			this.isSubmitLocked = false;
		});
	}

	unlockSubmitting() {
		this.isSubmitLocked = false;
	}

	onFormSubmit(form: NgForm) {
		//onFormSubmit viene eseguito N volte consecutivamente se causato da pressione continua del tasto INVIO
		//bloccarlo è necessario per farlo eseguire solo una volta, sbloccarlo in ogni caso alla fine della chiamata
		if (this.isSubmitLocked)
			return;

		const password = form.form.value.password;
		const user = form.form.value.user;

		if (password === '') {
			this.isErrorPwd = true;
			this.isErrorUser = user === '';
			return;
		}

		if (user === '') {
			this.isErrorUser = true;
			this.isErrorPwd = password === '';
			return;
		}

		this.isSubmitLocked = true;

		const body = this.data.payload;

		if (this.data.actionType === ActionType.MASSIVE) {
			// E' un azione massiva
			body['username'] = user;
			body['otp'] = password;
			body['docType'] = this.accountService.getCurrentAccount() === AccountType.PAYABLE ? 'PASSIVO' : 'ATTIVO';
		} else {

			body['signerUsername'] = user;
			body['otp'] = password;
			body['documentCycleTypeEnumType'] = 'ATTIVO';
		}

		const successAction = () => {
			this.modal.hide();
			this.notificationService.showSweetAlert(
				NotificationType.SUCCESS,
				'Richiesta di firma eseguita con successo',
				''
			);
			setTimeout(this.unlockSubmitting, 1000);
		};

		const errorAction$ = (err) => {
			this.modal.hide();
			if (err.type === ErrorTypes.HTTP_UNAUTHORIZED) {
				//this.router.navigate(['/unauthorized']).then();
			} else
				this.notificationService.showSweetAlert(
					NotificationType.ERROR,
					'Errore durante l\'esecuzione dell\'azione',
					''
				);

			setTimeout(this.unlockSubmitting, 1000);
			return EMPTY;
		};

		this.actionsHttpService.whenSignDocument(
			this.data.actionUrl,
			body
		).pipe(
			take(1),
			catchError(errorAction$)
		).subscribe(() => {
			successAction();
			if (this.data.refreshDocuments)
				this.documentsService.refreshDocuments();
			else
				this.documentDetailsService.sendRefreshDocumentDetails();

		});
	}

	ngOnInit(): void {
		this.documentsService.whenTotalDocuments()
			.pipe(
				take(1)
			)
			.subscribe(total => {
				let qty;

				if (this.data.actionType === ActionType.MASSIVE)
					// se massiva
					qty = total;
				else
					// se multipla
					qty = this.data.payload.documents.length;

				this.docCount = qty;
				// this.documentsLabel = `${qty} ${qty !== 1 ? 'documenti' : 'documento'}`;
			});
	}

	close() {
		this.modal.hide();
		this.unlockSubmitting();
	}
}
