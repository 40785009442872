<div class="preview h-100 pb-3">
  <tabset [justified]="true">
    <tab class="tabs">
      <ng-template tabHeading>
        <div class="tab-header">
          <b>Documento</b>
        </div>
      </ng-template>
      <!-- <gaw-gawmail-document-preview class="pdf-frame document-detail-body" [data]="data"> </gaw-gawmail-document-preview> -->
      <dry-pdf-viewer [documentAttachment]="documentAttachmentPec"></dry-pdf-viewer>
    </tab>
    <tab class="tabs">
      <ng-template tabHeading>
        <button class="tab-header btn-ghost" aria-label="Scarica PDF Pec" (click)="openPecMessagePdf()">
          <b>Testo PEC</b>
        </button>
      </ng-template>
      <dry-pdf-viewer [documentAttachment]="documentAttachmentMessagePec"></dry-pdf-viewer>
    </tab>
    <tab class="tabs">
      <ng-template tabHeading>
        <div class="tab-header document-detail-body">
          <b>Storico Stati</b>
        </div>
      </ng-template>
      <gaw-gawmail-status-history class="document-detail-body" [data]="data"></gaw-gawmail-status-history>
    </tab>
  </tabset>
</div>
