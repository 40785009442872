import { OrderField, SearchField, SelectField } from './work-item';

export interface WorkItemsSearchBody {

	returnArchived: boolean;
	returnProperties: boolean;
	selectFields: Array<SelectField>;
	searchFields: Array<SearchField>;
	orderFields: Array<OrderField>;
}
