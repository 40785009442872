import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConfigService } from 'app/core/common/config/config.service';
import { map } from 'rxjs';
import { DocumentNotesService } from '../public-api';
import { AddNotesComponent } from './components/add-notes/add-notes.component';
import { NotesListComponent } from './components/notes-list/notes-list.component';
import { DocumentNotesConfig } from './document-notes-config';
import { DOC_NOTES_CONFIG_TOKEN } from './document-notes-token';
import { DocumentNotesHttpService } from './services/document-notes-http.service';
import { DocumentNotesEffect } from './store/document-notes.effect';
import { documentNotesModuleFeatureKey, documentNotesModuleReducers } from './store/document-notes.reducer';

export const initDocumentNotes = (configService: ConfigService) => configService.whenAppConfig().pipe(
	map(config => config.docDetails.http.host),
	map(host => <DocumentNotesConfig>({
		e_get: `${host}/v1/DocumentNotes/Notes`,
		e_delete: `${host}/v1/DocumentNotes/Delete`,
		e_insert: `${host}/v1/DocumentNotes/Insert`,
		e_update: `${host}/v1/DocumentNotes/Update`
	}))
);

@NgModule({
	declarations: [NotesListComponent, AddNotesComponent],
	imports: [
		FormsModule,
		CommonModule,
		StoreModule.forFeature(documentNotesModuleFeatureKey, documentNotesModuleReducers),
		EffectsModule.forFeature([
			DocumentNotesEffect
		]),
	],
	exports: [
		AddNotesComponent,
		NotesListComponent
	],
	providers: [
		{
			provide: DOC_NOTES_CONFIG_TOKEN,
			useFactory: initDocumentNotes,
			deps: [ConfigService]
		}
	]
})
export class DocumentNotesModule {
	static forRoot(): ModuleWithProviders<DocumentNotesModule> {
		return {
			ngModule: DocumentNotesModule,
			providers: [
				DocumentNotesHttpService,
				DocumentNotesService,
			]
		};
	}
}
