import { Injectable } from '@angular/core';
import { FormError } from 'app/entities/invoice-pa/all-validation-errors/form-error';
// import { AllArrayConfigPagination } from 'app/pages/protected/invoice-pa/shared/form-array-pagination-config/form-array-pagination-config';
import { NGXLogger } from 'ngx-logger';
import { AllArrayConfigPagination } from './form-array-pagination-config';

@Injectable()
export class FormErrorNavigationService {
	controlElement = new Map<string, any>();

	private arrayPaginatorConfigurations = AllArrayConfigPagination;

	constructor(private logger: NGXLogger) { }

	/*
		NAVIGAZIONE ERRORI FORM REACTIVE/DYNAMIC
	 */

	/*
		1. Controllare che l'errore presenti degli erray
		2. Controllare che sia presente la configurazione per il paginatore dato il nome dell'array
		3. Prendere la configurazione e modificare la prioprietà actualPage
		4. Fare scroll dal form
		TODO aggiungere logica dell'impaginatore nel caso in cui itemsPerPage sia maggiore di 1
	*/

	public navigateToError(error: FormError) {
		if (error.templateDrivenForm)
			this.templateDrivenNavigationEngine(error);

		else {
			const form = error.errorForm;
			let nativeElement = (<any>form.get(error.errorNavigationStructure.errorPath)).nativeElement;
			// controllo se l'elemento non è in un array ed è già nel DOM
			if (!error.errorNavigationStructure.arraysInPath && nativeElement) {
				this.elementFocus(nativeElement);
				return;
			}
			// L'elemento e' in uno o più array paginati annidati
			error.errorNavigationStructure.arraysInPath.forEach(arrayStructure => {
				this.changePaginatorPage(arrayStructure.arrayName, arrayStructure.arrayIndex);
			});
			setTimeout(() => {
				nativeElement = (<any>form.get(error.errorNavigationStructure.errorPath)).nativeElement;
				// controllo se l'elemento con l'errore si trova all'interno di una modal
				this.checkIfErrorInModal(nativeElement);
				try {
					this.elementFocus(nativeElement);
				} catch {
					this.logger.error('Errore durante il focus dell\'elemento:', error);
				}
			});
		}
	}

	/*
		NAVIGAZIONE ERRORI FORM TEMPLATE DRIVEN
	 */
	public templateDrivenNavigationEngine(error: FormError) {
		// errorPath corrisponde al controlName completo. Non si può utilizzare controlName perchè esso è stato spilttato
		const element = document.getElementById(error.errorNavigationStructure.errorPath);
		setTimeout(() => {
			this.elementFocus(element);
		});
	}

	private checkIfErrorInModal(element: HTMLElement) {
		const id = element.id;
		const splittedId = id.split('-');
		// controllo se esiste l'indice 1. (indice 1 corrisponde al nome dell'id del bottone che si occupa di aprire la modal)
		if (splittedId[1])
			document.getElementById(splittedId[1]).click();

	}

	private elementFocus(element: HTMLElement) {
		element.scrollIntoView({
			behavior: 'smooth'
		});
		element.focus();
	}

	private changePaginatorPage(arrayName: string, arrayIndex: number) {
		this.arrayPaginatorConfigurations.filter(obj => {
			if (obj.formArrayName === arrayName)
				obj.pagination.actualPage = arrayIndex + 1;

		});
	}

}
