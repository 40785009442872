import { Injectable } from '@angular/core';
import { AllDocumentsCallback } from './callbacks/all-documents-callback';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';

@Injectable({
	providedIn: 'root'
})
export class MassiveActionsCatalog {

	public readonly exportExcelMassive = new ActionBuilder(MassiveActionCode.exportExcelMassive, ActionCode.DOWNLOAD_EXCEL)
		.setActionURL('/v1/ArchivioFiscale/documenti/esporta/massivo?TipoDocumento={format}')
		.setCallback((action: ActionObject, extraParams?: any) => this.allDocumentsCallback.exportXLSX(action, extraParams))
		.build();

	public readonly createPddMassive = new ActionBuilder(MassiveActionCode.createPddMassive, ActionCode.CREATE_PDD)
		.setActionURL('')
		.setCallback((action: ActionObject, extraParams?: any) => this.allDocumentsCallback.requestPDD(action, extraParams))
		.build();

	public readonly downloadPdfZipMassive = new ActionBuilder(MassiveActionCode.downloadPdfZipMassive, ActionCode.DOWNLOAD_PDF_ZIP)
		.setActionURL('')
		.build();
	constructor(private allDocumentsCallback: AllDocumentsCallback) { }

}

export enum MassiveActionCode {
	exportExcelMassive = 'exportExcelMassive',
	createPddMassive = 'createPddMassive',
	downloadPdfZipMassive = 'downloadPdfZipMassive'

}
