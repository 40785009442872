<div class="modal-header">
  <h4 class="modal-title">
    {{ title }}
  </h4>
  <button type="button" class="btn-ghost close" aria-label="Chiudi" (click)="bsCreateWorkItemModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="modal-body text-center">
    <table>
      <tr>
        <td class="form-cell">
          <ng-container *ngIf="token$ | async as token1">
            <ng-container *ngIf="createItemFormLink$ | async as formLink">
              <form #createForm [action]="formLink" target="create-form-target" method="POST">
                <input type="hidden" name="jwt" [value]="token1" />
              </form>
              <iframe #frame id="frame" name="create-form-target" width="100%" frameborder="0"> </iframe>
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="bsCreateWorkItemModalRef.hide()">Chiudi</button>
</div>
