import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppCommonModule } from 'app/core/common/app-common.module';
import { HomeFilterEffects } from '../../core/filters/store/home-filter.effects';
import { homeFilterModuleReducers } from '../../core/filters/store/home-filter.reducer';

export const homepageSearchModuleFeatureKey = 'homepageSearchModule';

export const homepageSearchModuleReducers = {
	homepageFilterState: homeFilterModuleReducers.homepageFilterState
};

@NgModule({
	imports: [
		CommonModule,
		AppCommonModule,
		StoreModule.forFeature(homepageSearchModuleFeatureKey, homepageSearchModuleReducers),
		EffectsModule.forFeature([
			HomeFilterEffects
		]),
	],
	declarations: [],
	exports: []
})
export class FiltersModule { }
