import { PlatformLocation } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActionsService } from '@ctel/hubfe-commons';
import { HistoryObject } from 'app/core/common/entities/documents/actions/history-item';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

/**
 * Componente modal per un'azione sul documento che mostra uno storico (modifiche o esiti)
 */
@Component({
	selector: 'hub-history-action-modal',
	templateUrl: './history-action-modal.component.html',
	styleUrls: ['./history-action-modal.component.scss']
})
export class HistoryActionModalComponent {
	@Input() modal: BsModalRef;

	public history$: Observable<HistoryObject[]>;

	constructor(
		private actionsService: ActionsService,
		private location: PlatformLocation
	) {
		location.onPopState(() => this.modal.hide());
		this.history$ = this.actionsService.whenHistory();
	}

}
