import { Injectable } from '@angular/core';
import { ConfigurationFunction } from 'app/entities/grafiche/configuration-function';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { DocSeriesHttpService } from './doc-series-http.service';
import { Company } from 'app/entities/companies/company';
import { DocClass } from 'app/entities/doc-class/doc-class.interface';
import { Classification } from 'app/entities/classification/classification';

@Injectable({
	providedIn: 'root',
})
export class DocSeriesService {
	private destroy$ = new Subject<void>();
	graphicServiceFunction$: ReplaySubject<ConfigurationFunction> = new ReplaySubject<ConfigurationFunction>(1);
	company: Company;

	constructor(private docSeriesHttpService: DocSeriesHttpService) { }

	getClassification(licenseId: string, siaCode: string): Observable<Classification[]> {
		return this.docSeriesHttpService.getClassification(licenseId, siaCode);
	}

	getDocClass(
		licenseId: string,
		siaCode: string,
		docClassCode: string
	): Observable<DocClass[]> {
		return this.docSeriesHttpService.getDocClass(
			licenseId,
			siaCode,
			docClassCode
		);
	}
}
