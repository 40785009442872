export class ColorList {

	public static getColorList(): Color[] {

		return [
			{
				name: 'red',
				hex: '#FF3366'
			},
			{
				name: 'blue',
				hex: '#0066CC'
			},
			{
				name: 'purple',
				hex: '#9966CC'
			},
			{
				name: 'orange',
				hex: '#FF6600'
			},
			{
				name: 'green',
				hex: '#009966'
			},
			{
				name: 'magenta',
				hex: '#f032e6'
			},
			{
				name: 'navy',
				hex: '#000080'
			},
			{
				name: 'black',
				hex: '#000000'
			},
			{
				name: 'yellow',
				hex: '#ffe119'
			},
			{
				name: 'mint',
				hex: '#5CB17B'
			},
			{
				name: 'lavender',
				hex: '#e6beff'
			},
			{
				name: 'teal',
				hex: '#008080'
			},
			{
				name: 'lime',
				hex: '#d2f53c'
			},
			{
				name: 'maroon',
				hex: '#800000'
			},
			{
				name: 'brown',
				hex: '#aa6e28'
			},
			{
				name: 'olive',
				hex: '#808000'
			},
			{
				name: 'cyan',
				hex: '#46f0f0'
			}
		];
	}

	public static getGradientColorList(): Color[] {
		// background-image: linear-gradient(to right top, #cf6518, #da6e22, #e4772b, #ef8034, #fa893d);
		return [
			{
				name: 'orange0',
				hex: '#cf6518'
			},
			{
				name: 'orange1',
				hex: '#e4772b'
			},
			{
				name: 'orange2',
				hex: '#fa893d'
			},
			{
				name: 'orange3',
				hex: '#f8a467'
			},
			{
				name: 'orange2',
				hex: '#fa893d'
			},
			{
				name: 'orange1',
				hex: '#e4772b'
			}
		];
	}
}

export interface Color {
	name: string;
	hex: string;
	label?: string;
}
