import { Injectable } from '@angular/core';
import { ActionBuilder } from '../../../../../../../src/app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from '../../../../../../../src/app/entities/ui-config/action/action-code.enum';

@Injectable({
	providedIn: 'root'
})
export class MultiActionsCatalog {
	public readonly removeMultiple = new ActionBuilder(MultiActionCode.removeMultiple, ActionCode.REMOVE)
	.build();
}

export enum MultiActionCode {
	removeMultiple = 'removeMultiple'
}
