import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { EventService } from 'app/core/common/event/event.service';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { EMPTY, ReplaySubject, Subject, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { LockStatus } from '../../../entities/work-item/lock-status.enum';
import { WorkItemAction } from '../../../entities/work-item/work-item-action';
import { WorkflowService } from '../../../services/workflow.service';
import { WorkflowEvent } from '../../../workflow-event';
import { IndirectActionExecution } from './workitem-activity-indirect-action-frame/indirect-action-execution.enum';

export enum ActivityStatus {
	DETAIL,
	INDIRECT_ACTION
}

@Component({
	selector: 'gaw-workitem-activity',
	templateUrl: './workitem-activity.component.html',
	styleUrls: ['./workitem-activity.component.scss']
})
export class WorkitemActivityComponent implements OnDestroy {
	@Output() closeModal = new EventEmitter<boolean>();

	public readonly status = ActivityStatus;

	// Identifica lo stato dell'activity
	activityStatus: ActivityStatus = ActivityStatus.DETAIL;
	indirectFormLink: string;

	private readonly action$ = new ReplaySubject<WorkItemAction>(1);
	private readonly destroy$ = new Subject<void>();

	constructor(
		private readonly notificationService: NotificationService,
		private readonly workflowService: WorkflowService,
		private readonly eventService: EventService,
		private readonly cdr: ChangeDetectorRef
	) {
		const whenItemNotLocked$ = (action) => this.workflowService.selectedWorkItem$.pipe(
			take(1),
			filter(wi => wi.lockStatus === LockStatus.ITEM_LIBERO),
			map(() => action)
		);

		// Esecuzione dell'azione diretta
		const executeDirectAction$ = this.action$.pipe(
			filter(action => action.direct),
			switchMap(whenItemNotLocked$),
			tap(action => {
				const execute$ = this.workflowService.executeAction(action, true).pipe(
					tap(res => {
						this.eventService.emit(WorkflowEvent.E_ACTION_COMPLETED);
						if (!res?.['stillInCharge'])
							this.closeModal.emit(true);
					}),
					catchError(() => {
						this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante l\'azione', '');
						return EMPTY;
					}),
				);

				this.notificationService.showSweetAlert(
					NotificationType.QUESTION,
					`Confermare azione ${action.description}?`,
					'',
					() => execute$.pipe(take(1)).subscribe());
			})
		);

		// Esecuzione dell'azione indiretta
		const executeIndirectAction$ = this.action$
			.pipe(
				filter(action => !action.direct),
				switchMap(action => this.workflowService.retrieveFormLink(action)),
				// Assegno alla variabile l'indirizzo da dare al frame
				tap(url => this.indirectFormLink = url),
				// Setto lo status dell'activity per l'esecuzione dell'azione indiretta
				tap(() => {
					this.detachDetectorChanges();
					this.activityStatus = ActivityStatus.INDIRECT_ACTION;
					this.reattachDetectorChanges();
				}),
				catchError(() => {
					this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante l\'esecuzione', '');
					return of(null);
				})
			);

		executeDirectAction$.pipe(
			takeUntil(this.destroy$)
		).subscribe();

		executeIndirectAction$.pipe(
			takeUntil(this.destroy$)
		).subscribe();
	}

	/**
	 * Rimango in ascolto dell'evento di esecuzione di un azione.
	 * @param action
	 */
	executeAction(action: WorkItemAction) {
		this.action$.next(action);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	indirectActionCompleted(event: {
		execute: IndirectActionExecution,
		stillInCharge: boolean
	}) {

		this.detachDetectorChanges();

		// Gestisco in base alle informazioni arrivate da BE
		switch (event.execute) {
			case IndirectActionExecution.SUCCESS:
				this.actionSucceded(event.stillInCharge);
				break;
			case IndirectActionExecution.FAILED:
				this.actionFailed();
				break;
			case IndirectActionExecution.ABORT:
				this.actionAbort();
				break;
		}

		this.reattachDetectorChanges();
	}

	private actionSucceded(stillInCharge: boolean) {
		// Notifico l'esecuzione di un azione
		this.eventService.emit(WorkflowEvent.E_ACTION_COMPLETED);

		if (stillInCharge)
			// Dopo l'esecuzione dell'azione torno allo stato di `Dettaglio`
			this.activityStatus = ActivityStatus.DETAIL;
		else
			// Notifico alla modal di chiudersi
			this.closeModal.emit(true);

	}

	private actionFailed() {
		// Dopo l'esecuzione dell'azione torno allo stato di `Dettaglio`
		this.activityStatus = ActivityStatus.DETAIL;
	}

	private actionAbort() {
		// Dopo l'esecuzione dell'azione torno allo stato di `Dettaglio`
		this.activityStatus = ActivityStatus.DETAIL;
	}

	private detachDetectorChanges() {
		if (this.cdr)
			this.cdr.detach();

	}

	private reattachDetectorChanges() {
		if (this.cdr) {
			this.cdr.detectChanges();
			this.cdr.reattach();
		}
	}

}
