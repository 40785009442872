import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { Barcode } from 'app/entities/barcode/barcode';
import { AppConfig } from 'app/entities/config/app-config';

@Injectable({
	providedIn: 'root'
})
export class BarcodeHttpService {
	private jsonUrl = '/mock-response.json';
	private readonly jsonContentType = 'application/json';

	private gawpassReadHost: string;
	private generateBarcode: string;
	constructor(
		private http: HttpClient,
		private configService: ConfigService
	) {
		this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}
	httpHostInit(appConfig: AppConfig) {
		/* ENDPOINTS GAWPASS --------------------------------------------------------------------------------------------------- */
		this.gawpassReadHost = appConfig.gawPassRead.http.host;

		this.generateBarcode = `${this.gawpassReadHost}/api/v1/Action/GenerateBarcode/{licenseId}/{siaCode}/{CodiceService}`;
	}

	whenBarcode(licenseId, siaCode, code, graphic, note, idScatola) {
		// const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.generateBarcode
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{CodiceService}', code);
		let body;

		if (idScatola)
			body = {
				IdScatola: idScatola,
			};
		else
			body = {
				Grafica: graphic,
			};

		if (note)
			body.note = note;

		return this.http.get<Barcode[]>(url, { params: body });
	}
}

