/* eslint-disable @typescript-eslint/no-unused-vars */
import { Type } from '@angular/core';
import { CheckboxCellAddition } from '../../checkbox-select-addition/checkbox-cell-addition';
import { GridApi } from '../grid-api';

export declare type RowStyle = { [klass: string]: unknown; };

export interface Options<T> {
	// Selection
	rowSelection: 'none' | 'single' | 'multi';
	checkboxAddCellComponent?: Type<CheckboxCellAddition>;
	// Filter
	filter: boolean;
	// Sorting
	sorting: boolean;
	// Click
	dblClick: boolean;
	// Func
	getRowId: (item: unknown) => number;
	setRowStyle: (item: unknown) => RowStyle;
	whenGridReady: (gridApi: GridApi<T>) => void;
}

export class GridOptions<T> implements Options<T> {
	rowSelection: 'none' | 'single' | 'multi' = 'none';
	checkboxAddCellComponent = undefined;
	filter = false;
	sorting = false;
	dblClick = true;
	getRowId = (item: number) => item;
	setRowStyle = (...args) => <RowStyle>{};
	whenGridReady = (...args): unknown => null;
}
