import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class FrLottiHttpService {
	frLottiHost: string;
	cancelMassiveUrl: string;
	private jsonContentType = 'application/json';
	private readonly jsonOptions: object;
	private checkMassiveUrl: string;
	private reasonConfirmUrl: string;
	private signUrl: string;

	constructor(
		private http: HttpClient,
		private configService: ConfigService
	) {
		this.configService.whenAppConfig().subscribe((appconfig: AppConfig) => {
			this.httpHostInit(appconfig);
		});
		this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');

	}

	httpHostInit(appconfig: AppConfig) {
		this.frLottiHost = appconfig.webConsOrchestrator.http.host;
		this.cancelMassiveUrl = `${this.frLottiHost}/v1/FirmaRemota/lotti/cancella/massiva`;
		this.checkMassiveUrl = `${this.frLottiHost}/v1/FirmaRemota/lotti/controllo/anomalie/massivo`;
		this.reasonConfirmUrl = `${this.frLottiHost}/v1/FirmaRemota/lotti/conferma/massiva`;
		this.signUrl = `${this.frLottiHost}/v1/FirmaRemota/lotti/firma/massiva`;
	}

	cancelMassive(body: string): Observable<void> {
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			body
		};
		return this.http.delete<void>(this.cancelMassiveUrl, options);
	}

	checkMassive(body: string): Observable<number> {
		return this.http.post<number>(this.checkMassiveUrl, body, this.jsonOptions);
	}

	reasonConfirm(body: string): Observable<void> {
		return this.http.post<void>(this.reasonConfirmUrl, body, this.jsonOptions);
	}

	sign(body: string): Observable<void> {
		return this.http.post<void>(this.signUrl, body, this.jsonOptions);

	}
}
