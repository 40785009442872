import { PlatformLocation } from '@angular/common';
import { AfterContentInit, Component, Input } from '@angular/core';
import { FilterActions, FilterState, IBucket, IFilter } from '@ctel/search-filter-store';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import ChangeFilterRequestedKind = FilterActions.ChangeFilterRequestedKind;

@Component({
	selector: 'gaw-fiscal-year-modal',
	templateUrl: './fiscal-year-modal.component.html',
	styleUrls: ['./fiscal-year-modal.component.scss']
})
export class FiscalYearModalComponent implements AfterContentInit {
	@Input() modal: BsModalRef;
	@Input() data: {
		filter: IFilter;
	};

	fiscalYear: IBucket[];
	selected: string;

	constructor(private location: PlatformLocation,
							private store: Store<FilterState>) {

		location.onPopState(() => this.modal.hide());
	}

	ngAfterContentInit() {
		this.fiscalYear = this.data.filter.configData.buckets;
		this.selected = this.data.filter.value.terms[0];
	}

	applyFilters() {

		this.modal.hide();
		this.store.dispatch(FilterActions.changeFilterRequested({
			kind: ChangeFilterRequestedKind.CheckBoxTermsSelected,
			metadata: this.data.filter.metadata,
			buckets: [this.selected]
		}));
	}
}
