import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { FavoriteSearchModalComponent } from 'app/core/common/modals/favorite-search-modal/favorite-search-modal.component';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { Company } from 'app/entities/companies/company';
import { Subject, takeUntil } from 'rxjs';

/**
 * Componente checkbox della riga documento nella lista documenti
 */
@Component({
	selector: 'gaw-save-favorite-search',
	templateUrl: 'save-favorite-search.component.html',
	styleUrls: ['save-favorite-search.component.scss'],
})
export class SaveFavoriteSearchComponent implements OnDestroy {

	@Input() service: string;
	private destroy$ = new Subject<void>();
	private company: Company;

	constructor(
		private notificationService: NotificationService,
		private companiesService: CompaniesService,
		private router: Router
	) {
		this.companiesService.whenCurrentCompany()
			.pipe(takeUntil(this.destroy$))
			.subscribe(company => this.company = company);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	openFavoriteSearchModal() {
		this.notificationService.showModal(NotificationType.GENERAL, {
			title: 'Salva la ricerca preferita',
			childComponent: FavoriteSearchModalComponent,
			childData: {
				service: this.service,
				url: this.router.url,
				licenseId: this.company.licenseId,
				siaCode: this.company.siaCode
			}
		});
	}
}
