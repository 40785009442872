import { Component, Input, OnChanges, SimpleChange } from '@angular/core';
import { Filter, IDetailsComponent, StatusHistory } from '@ctel/gaw-commons';
import { StatusHistoryService } from '@ctel/gawpost-commons';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
	selector: 'gaw-gawpost-status-history',
	templateUrl: './gawpost-status-history.component.html',
	styleUrls: ['./gawpost-status-history.component.scss'],
})
export class GAWPOSTStatusHistoryComponent implements OnChanges {
	@Input() data: IDetailsComponent;

	public faIcons = FaIcons;

	statusHistory$: Observable<StatusHistory[]>;
	fullPageError$: Observable<boolean>;
	statusHistoryError$: Observable<boolean>;
	historyPanelLoading = true;
	tileErrorPlaceholderErrorOption = { message: 'Errore imprevisto.' };

	private readonly defaultFilter: Filter[] = [];

	constructor(private statusHistoryService: StatusHistoryService) { }

	ngOnChanges(changes: { data: SimpleChange }) {
		if (!changes.data) return;

		this.statusHistory$ = this.statusHistoryService.whenStatusHistory(
			this.data.progSpool,
			this.data.progBusta,
			this.data.keys['idComunicazione22']
		);

		this.statusHistory$
			.pipe(take(1))
			.subscribe(() => this.historyPanelLoading = false);

		this.statusHistoryError$ = this.statusHistoryService.whenStatusHistoryError().pipe(
			tap((x: boolean) => {
				if (x)
					this.historyPanelLoading = false;
			})
		);
	}
}
