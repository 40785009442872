import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FeatureFlag, VersionObject } from '@ctel/versioning';
import { ConfigService } from 'app/core/common/config/config.service';
import { Observable } from 'rxjs';
import { map, share, take } from 'rxjs/operators';
import packageInfo from '../../../../../package.json';

@Component({
	selector: 'dry-version',
	templateUrl: './version.component.html'
})
export class VersionComponent implements OnInit {

	public appName = packageInfo.name;
	public versionObject: Observable<VersionObject>;

	// Feature flags
	public featureFlags$: Observable<FeatureFlag[]>;

	mockVersionObject = {
		version: '1.9.2',
		buildNumber: 'GAW30_61269_1.9.0-next_alfa',
		buildId: '61269',
		branchName: 'refs/heads/integration/1.9.0-next',
		sourceVersion: '77c38c56c1f96badbc4200cc9ab62883fa0959c2'
	};

	constructor(
		public httpClient: HttpClient,
		public configService: ConfigService
	) { }

	ngOnInit() {

		this.versionObject = this.httpClient.get<VersionObject>('../../assets/version/version.json')
			.pipe(
				take(1),
				share()
			);

		this.featureFlags$ = this.configService.whenAppConfig()
			.pipe(
				take(1),
				map(appConfig => [
					new FeatureFlag('showCardInConservazione', appConfig.featureFlags.showCardInConservazione),
					new FeatureFlag('loadingPlaceholderInDocList', appConfig.featureFlags.loadingPlaceholderInDocList),
					new FeatureFlag('newPsCredem', appConfig.featureFlags.newPSCredem)
				])
			);
	}

}
