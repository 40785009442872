import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { SingleDocumentCallback } from './callbacks/single-document-callback';

@Injectable({
	providedIn: 'root'
})
export class SingleActionsCatalog {

	public readonly cancel = new ActionBuilder(SingleActionsCode.Cancel, ActionCode.CANCEL)
		.setActionURL('/v1/FirmaRemota/documenti/cancella')
		.build();

	public readonly confirm = new ActionBuilder(SingleActionsCode.Confirm, ActionCode.CONFIRM)
		.build();

	public readonly download = new ActionBuilder(SingleActionsCode.Download, ActionCode.DOWNLOAD)
		.setActionURL('/v1/FirmaRemota/documenti/{progSpool}/{progBusta}/scarica')
		.build();

	constructor(private singleDocumentCallback: SingleDocumentCallback) { }
}

export enum SingleActionsCode {
	Cancel = 'cancel',
	Download = 'download',
	Confirm = 'confirm',
}
