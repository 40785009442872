<!--suppress XmlUnboundNsPrefix, XmlUnboundNsPrefix -->lUnboundNsPrefix -->lUnboundNsPrefix -->lUnboundNsPrefix -->lUnboundNsPrefix -->lUnboundNsPrefix -->
<content-loader
	[speed]=2
	[foregroundColor]="'#f3f3f3'"
	[backgroundColor]="'#ecebeb'"
>
	<svg:rect x="0" y="15" rx="4" ry="4" width="6" height="6.4"/>
	<svg:rect x="34" y="13" rx="6" ry="6" width="160" height="12"/>
	<svg:rect x="633" y="13" rx="6" ry="6" width="16.1" height="12"/>
	<svg:rect x="653" y="13" rx="6" ry="6" width="78" height="12"/>
	<svg:rect x="755" y="13" rx="6" ry="6" width="105" height="12"/>
	<svg:rect x="938" y="13" rx="6" ry="6" width="66.4" height="12"/>
	<svg:rect x="0" y="39" rx="6" ry="6" width="1060" height=".3"/>
</content-loader>
