import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CessionarioCommittente } from 'app/entities/invoice-pa/invoice-pa-model';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-change-cf-modal',
	templateUrl: './change-cf-modal.component.html',
	styleUrls: ['./change-cf-modal.component.scss']
})
export class ChangeCfModalComponent {
	@Input() data: {
		cessionarioCommittente: CessionarioCommittente,
		formCessionarioCommittente: UntypedFormGroup
	};
	@Input() modal: BsModalRef;

	changeCf() {
		this.data.formCessionarioCommittente.patchValue(this.data.cessionarioCommittente);
		this.close();
	}

	close() {
		this.modal.hide();
	}

}
