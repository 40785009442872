import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { HighlightService } from './highlight.service';

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: [],
	exports: []
})
export class HighlightModule {

	static forRoot(): ModuleWithProviders<HighlightModule> {
		return {
			ngModule: HighlightModule,
			providers: [
				HighlightService
			]
		};
	}
}
