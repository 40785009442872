/*
 * Public API Surface of document-notes
 */

export { AddNotesComponent } from './lib/components/add-notes/add-notes.component';
export { NotesListComponent } from './lib/components/notes-list/notes-list.component';
export { DocumentNotesModule } from './lib/document-notes.module';
export { DocumentNotesConfig } from './lib/document-notes-config';
export { DOC_NOTES_CONFIG_TOKEN } from './lib/document-notes-token';
export { DocumentNotesService } from './lib/services/document-notes.service';

// entities
export { StylingComponent } from './lib/entities/stylingComponent';
export { Note } from './lib/entities/note';

export {
	createNote,
	deleteNote,
	fetchDocumentNotes,
	restoreTextAreaValue,
	selectNote,
	setDocumentId,
	setMode,
	setNotes,
	setSorting,
	submitTextArea,
	updateNote
} from './lib/store/document-notes.actions';

export {
	getDocumentId,
	getDocumentNotes,
	getMode,
	getNotesRequestParams,
	getSelectedNote,
	getSorting,
	getState,
	selectDocumentNotesModuleState,
	selectDocumentNotesState
} from './lib/store/document-notes.selector';

export { DocumentNotesEffect } from './lib/store/document-notes.effect';

export {
	documentNotesModuleFeatureKey, documentNotesModuleReducers, DocumentNotesModulesState, documentNotesReducer,
	DocumentNotesState, initialState, reducer
} from './lib/store/document-notes.reducer';
