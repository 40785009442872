import { PropertyType } from './property-type.enum';

export interface Workflow {
	id: number;
	workflowSectionName: string;
	description: string;
	name: string;
	workflowName: string;
	color: string;
	canBeContainedIntoFolder: boolean;
	canCreateItems: boolean;
	standaloneCreation: boolean;
	detailsInMixedMode?: boolean;
	itemCreationLink: string;
	batchCreation: boolean;
	uiCreation: boolean;
	properties: WorkflowProperty[];
	docSeries: string;
	expiredItemDate?: number;
	// roles: Role[];
	// stages: Stage[];
}

export interface WorkflowProperty {
	name: string;
	description: string;
	type: PropertyType;
}

export interface Role {
	name: string;
	dynamic: boolean;
}

export interface Stage {
	name: string;
	description: string;
}
