/* eslint-disable @typescript-eslint/member-ordering */
import {
	ComponentRef,
	Directive,
	EmbeddedViewRef,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	TemplateRef,
	Type,
	ViewContainerRef
} from '@angular/core';
import { BaseLoadingPlaceholderComponent } from 'app/core/common/placeholder/loading-placeholder/base-loading-placeholder/base-loading-placeholder.component';
import { ComponentInjector } from 'app/core/common/utilities/component-injector';
import { Subject } from 'rxjs';

@Directive({
	selector: '[dryLoading]'
})
export class LoadingDirective implements OnDestroy, OnChanges {

	private destroy$ = new Subject<void>();

	/*----------------------------------------------
	 * Gestione del caricamento
	 *----------------------------------------------*/

	// Input
	@Input() private dryLoading: boolean;
	@Input() private dryLoadingComponent: Type<any>;
	@Input() private dryLoadingTemplate: TemplateRef<any>;
	@Input() private dryLoadingStyle: {
		[key: string]: string;
	};

	// Istanziazione componente di caricamento
	public baseLoadingComponentInstantiated = false;
	private baseLoadingComponent: ComponentRef<any>;

	/*----------------------------------------------
	 * Gestione del contenuto incorporato
	 *----------------------------------------------*/
	private embeddedComponent: EmbeddedViewRef<any>;

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
	) { }

	/**
	 * Tutti i cambiamenti di stato di questa classe vengono gestiti nella ngOnChanges
	 * @param changes
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if (!changes)
			return;

		if (changes['dryLoading'])

			if (changes['dryLoading'].currentValue === true) {

				this.instantiateBaseLoadingComponent(BaseLoadingPlaceholderComponent);

				if (this.embeddedComponent)
					this.embeddedComponent.destroy();

				ComponentInjector.insertComponent(this.viewContainer, this.baseLoadingComponent);
			} else {
				ComponentInjector.detachComponent(this.viewContainer, this.baseLoadingComponent);
				this.embeddedComponent = this.viewContainer.createEmbeddedView(this.templateRef);
			}

	}

	instantiateBaseLoadingComponent(template: Type<any>) {
		this.baseLoadingComponent = this.viewContainer.createComponent(template);
		this.baseLoadingComponent.instance.component = this.dryLoadingComponent;
		this.baseLoadingComponent.instance.template = this.dryLoadingTemplate;
		this.baseLoadingComponent.instance.style = this.dryLoadingStyle;
		this.baseLoadingComponentInstantiated = true;
	}

	ngOnDestroy(): void {
		this.viewContainer.clear();
		if (this.baseLoadingComponentInstantiated === true)
			this.baseLoadingComponent.destroy();

		this.destroy$.next();
		this.destroy$.complete();

	}

}
