import { Injectable } from '@angular/core';
import { UserService } from '@ctel/auth';
import { InfoByDocSeries } from '@ctel/gaw-commons';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CompanyCacheKeys } from 'app/core/business/companies/company-cache-keys.enum';
import { UiUserSessionService } from 'app/core/business/ui-user-session/ui-user-session.service';
import { ConfigService } from 'app/core/common/config/config.service';
import { Color, ColorList } from 'app/core/common/utilities/color-list';
import { Company } from 'app/entities/companies/company';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { CompaniesHttpService } from './companies-http.service';

/**
 * Servizio che gestisce lo stato delle aziende visibili all'utente
 */
@Injectable({
	providedIn: 'root',
})
export class CompaniesService {

	private docSeriesInfo$ = new BehaviorSubject<InfoByDocSeries[]>([]);
	private currentCompanyValue$ = new BehaviorSubject<Company>(null);
	private currentCompany$ = new ReplaySubject<Company>(1);
	private docSeriesInfoSub$ = new ReplaySubject<InfoByDocSeries[]>(1);
	private companies$ = new ReplaySubject<Company[]>(1);
	private docSeriesColors$ = new ReplaySubject<Color[]>(1);
	private loadingError$ = new BehaviorSubject<[boolean, any]>([false, null]);

	constructor(
		private companiesHttpService: CompaniesHttpService,
		private uiUserSessionService: UiUserSessionService,
		private cookieService: CookieService,
		private userService: UserService,
		public configService: ConfigService,
		public oidcConfigService: OidcSecurityService
	) {
		// NOTA: qui dobbiamo ascoltare tutti queste configurazioni prima di procedere.
		// Da qui in avanti si assume essere già tutto configurato.
		combineLatest([oidcConfigService.getConfiguration(), this.configService.whenDryConfig(), this.configService.whenAppConfig()]).pipe(
			tap(() => {
				this.refreshCompanies();
			})
		).subscribe();

		// Gestione degli elementi salvati
		this.getFirstSelectedCompany();
	}

	companyCompare = (c1: Company, c2: Company) => c1 && c2 && c1.siaCode === c2.siaCode && c1.licenseId === c2.licenseId;

	refreshCompanies() {
		this.initializeCompanies();
	}

	initializeCompanies() {
		this.companiesHttpService.whenCompanies().pipe(
			take(1),
			catchError((err: unknown) => {
				// imposto il valore dell'errore a true
				this.sendLoadingError(true, err);
				return of(null);
			})
		).subscribe(companies => {
			if (companies !== null) {
				// imposto il valore dell'errore a false
				this.sendLoadingError(false, null);
				this.sendCompanies(companies);
			}
		});
	}

	whenLoadingError(): Observable<[boolean, any]> {
		return this.loadingError$.asObservable();
	}

	sendLoadingError(value: boolean, context: any) {
		this.loadingError$.next([value, context]);
	}

	whenCompanies(): Observable<Company[]> {
		return this.companies$.asObservable();
	}

	sendCompanies(value: Company[]) {
		this.companies$.next(value);
	}

	whenCurrentCompany(): Observable<Company> {
		return this.currentCompany$.asObservable();
	}

	sendCurrentCompany(value: Company) {
		if (this.companyCompare(value, this.currentCompanyValue$.getValue()))
			return;

		this.currentCompany$.next(value);
		this.currentCompanyValue$.next(value);
		this.saveCompanyInSession(value);
	}

	whenDocSeriesInfo(): Observable<InfoByDocSeries[]> {
		return this.docSeriesInfoSub$.asObservable();
	}

	sendDocSeriesInfo(value: InfoByDocSeries[]) {
		this.docSeriesInfo$.next(value);
		this.docSeriesInfoSub$.next(value);
	}

	getDocSeriesInfo(): InfoByDocSeries[] {
		return this.docSeriesInfo$.value;
	}

	/**
	 * Da usarsi unicamente in caso di azione/evento al seguito del caricamento applicativo.
	 * Da non usarsi in questo ultimo caso, in quanto il valore del BehaviorSubject potrebbe
	 * ancora non essere presente.
	 * TODO: rifattorizza a stati la logica applicativa globale delle companies (ngrx).
	 */
	getCurrentCompanyValue(): Company {
		return this.currentCompanyValue$.value;
	}

	// ASSEGNO I COLORI ALLE SERIE DOCUMENTALI
	sendDocumentSeriesColors(labels: string[]) {
		const colorList = ColorList.getColorList();
		let newColorList = [];
		const docSeriesColors = [];
		let count = Math.ceil(labels.length / colorList.length);
		while (count >= 1) {
			newColorList = newColorList.concat(colorList);
			count--;
		}
		for (let i = 0; i < labels.length; i++) {
			const obj = newColorList[i];
			obj['label'] = labels[i];
			docSeriesColors.push(obj);
		}
		this.docSeriesColors$.next(docSeriesColors);
	}

	whenDocumentsSeriesColors(): Observable<Color[]> {
		return this.docSeriesColors$.asObservable();
	}

	private getFirstSelectedCompany() {

		const getCompanyCached$ = (companies: Company[]) => this.uiUserSessionService.getValue<Company>(CompanyCacheKeys.LATEST_COMPANY)
			.pipe(
				take(1),
				catchError(() => of(undefined)),
				// Controllo se il valore è presente nella lista
				map(cachedValue => companies.find(c => this.companyCompare(cachedValue, c))),
				// Se non trovo una corrispondenza setto il primo valore disponibile
				tap(val => this.sendCurrentCompany(val || companies[0]))
			);

		// const useCoockie$ = (companies: Company[]) => this.userService.getUser().pipe(
		// 	map(user => user.id),
		// 	filter(val => val !== undefined && companies.length > 0),
		// 	map(userId => JSON.parse(this.cookieService.get(userId + '_GAW30_LastSelectedCompany')) as Company),
		// 	tap(val => this.sendCurrentCompany(val || companies[0]))
		// );

		this.whenCompanies().pipe(
			take(1),
			// Vedo se ho salvato l'ultima azienda aperta
			switchMap(companies => getCompanyCached$(companies))
		).subscribe();
	}

	private saveCompanyInSession(company: Company): void {

		this.uiUserSessionService.setValue(CompanyCacheKeys.LATEST_COMPANY, company).pipe(
			take(1),
			catchError((err: unknown) => EMPTY)
		).subscribe();
	}
}
