import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

/**
 * Servizio che gestisce lo stato delle info relative al pdf
 */
@Injectable({
	providedIn: 'root'
})
export class DocumentInfoService implements OnDestroy {

	private destroy$ = new Subject<void>();
	private documentInfo$ = new BehaviorSubject<any>(null);
	private currentUrl$ = new BehaviorSubject<string>(null);
	private currentPdf$ = new ReplaySubject<any>(1);

	whenCurrentPdf(): Observable<any> {
		return this.currentPdf$.asObservable();
	}

	sendCurrentPdf(blob: any) {
		this.currentPdf$.next(blob);
	}

	whenDocumentInfo(): Observable<any[]> {
		return this.documentInfo$.asObservable();
	}

	getDocumentInfo(): any {
		return this.documentInfo$.value;
	}

	sendDocumentInfo(document: any) {
		this.documentInfo$.next(document);
	}

	getCurrentUrl(): string {
		return this.currentUrl$.value;
	}

	sendCurrentUrl(url: string) {
		this.currentUrl$.next(url);
	}

	resetDocumentInfo() {
		this.documentInfo$.next(null);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
