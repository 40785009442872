<content-loader [style]="{height: '40px', width: '1060px'}" [speed]=2 [foregroundColor]="'#d9d9d9'"
	[backgroundColor]="'#ecebeb'">
	<svg:rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
	<svg:rect x="40" y="13" rx="6" ry="6" width="300" height="9" />
	<svg:rect x="400" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="500" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="600" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="700" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="800" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="900" y="13" rx="6" ry="6" width="80" height="9" />

	<svg:rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
</content-loader>

<content-loader [style]="{height: '40px', width: '1060px'}" [speed]=2 [foregroundColor]="'#d9d9d9'"
	[backgroundColor]="'#ecebeb'">
	<svg:rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
	<svg:rect x="40" y="13" rx="6" ry="6" width="300" height="9" />
	<svg:rect x="400" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="500" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="600" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="700" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="800" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="900" y="13" rx="6" ry="6" width="80" height="9" />

	<svg:rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
</content-loader>

<content-loader [style]="{height: '40px', width: '1060px'}" [speed]=2 [foregroundColor]="'#d9d9d9'"
	[backgroundColor]="'#ecebeb'">
	<svg:rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
	<svg:rect x="40" y="13" rx="6" ry="6" width="300" height="9" />
	<svg:rect x="400" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="500" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="600" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="700" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="800" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="900" y="13" rx="6" ry="6" width="80" height="9" />

	<svg:rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
</content-loader>

<content-loader [style]="{height: '40px', width: '1060px'}" [speed]=2 [foregroundColor]="'#d9d9d9'"
	[backgroundColor]="'#ecebeb'">
	<svg:rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
	<svg:rect x="40" y="13" rx="6" ry="6" width="300" height="9" />
	<svg:rect x="400" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="500" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="600" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="700" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="800" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="900" y="13" rx="6" ry="6" width="80" height="9" />

	<svg:rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
</content-loader>

<content-loader [style]="{height: '40px', width: '1060px'}" [speed]=2 [foregroundColor]="'#d9d9d9'"
	[backgroundColor]="'#ecebeb'">
	<svg:rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
	<svg:rect x="40" y="13" rx="6" ry="6" width="300" height="9" />
	<svg:rect x="400" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="500" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="600" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="700" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="800" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="900" y="13" rx="6" ry="6" width="80" height="9" />

	<svg:rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
</content-loader>

<content-loader [style]="{height: '40px', width: '1060px'}" [speed]=2 [foregroundColor]="'#d9d9d9'"
	[backgroundColor]="'#ecebeb'">
	<svg:rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
	<svg:rect x="40" y="13" rx="6" ry="6" width="300" height="9" />
	<svg:rect x="400" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="500" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="600" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="700" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="800" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="900" y="13" rx="6" ry="6" width="80" height="9" />

	<svg:rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
</content-loader>

<content-loader [style]="{height: '40px', width: '1060px'}" [speed]=2 [foregroundColor]="'#d9d9d9'"
	[backgroundColor]="'#ecebeb'">
	<svg:rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
	<svg:rect x="40" y="13" rx="6" ry="6" width="300" height="9" />
	<svg:rect x="400" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="500" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="600" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="700" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="800" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="900" y="13" rx="6" ry="6" width="80" height="9" />

	<svg:rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
</content-loader>

<content-loader [style]="{height: '40px', width: '1060px'}" [speed]=2 [foregroundColor]="'#d9d9d9'"
	[backgroundColor]="'#ecebeb'">
	<svg:rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
	<svg:rect x="40" y="13" rx="6" ry="6" width="300" height="9" />
	<svg:rect x="400" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="500" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="600" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="700" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="800" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="900" y="13" rx="6" ry="6" width="80" height="9" />

	<svg:rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
</content-loader>

<content-loader [style]="{height: '40px', width: '1060px'}" [speed]=2 [foregroundColor]="'#d9d9d9'"
	[backgroundColor]="'#ecebeb'">
	<svg:rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
	<svg:rect x="40" y="13" rx="6" ry="6" width="300" height="9" />
	<svg:rect x="400" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="500" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="600" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="700" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="800" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="900" y="13" rx="6" ry="6" width="80" height="9" />

	<svg:rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
</content-loader>

<content-loader [style]="{height: '40px', width: '1060px'}" [speed]=2 [foregroundColor]="'#d9d9d9'"
	[backgroundColor]="'#ecebeb'">
	<svg:rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
	<svg:rect x="40" y="13" rx="6" ry="6" width="300" height="9" />
	<svg:rect x="400" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="500" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="600" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="700" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="800" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="900" y="13" rx="6" ry="6" width="80" height="9" />

	<svg:rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
</content-loader>

<content-loader [style]="{height: '40px', width: '1060px'}" [speed]=2 [foregroundColor]="'#d9d9d9'"
	[backgroundColor]="'#ecebeb'">
	<svg:rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
	<svg:rect x="40" y="13" rx="6" ry="6" width="300" height="9" />
	<svg:rect x="400" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="500" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="600" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="700" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="800" y="13" rx="6" ry="6" width="80" height="9" />
	<svg:rect x="900" y="13" rx="6" ry="6" width="80" height="9" />

	<svg:rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
</content-loader>