import { Injectable } from '@angular/core';
import { AllDocumentsCallback } from './callbacks/all-documents-callback';

@Injectable({
	providedIn: 'root'
})
export class MassiveActionsCatalog {

	constructor(private allDocumentsCallback: AllDocumentsCallback) {
	}

}

export enum MassiveActionCode {

}
