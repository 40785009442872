import { Location } from '@angular/common';
import { AfterContentInit, AfterViewChecked, Component, Injector, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ConfigService } from 'app/core/common/config/config.service';
import { HighlightService } from 'app/core/common/highlight/highlight.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoggerHttpService } from './core/common/user-logger/logger-http.service';
import { CompaniesService } from './core/business/companies/companies.service';
import { UserService } from '@ctel/auth';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html'
})
export class AppComponent implements AfterContentInit, OnDestroy, AfterViewChecked {

	private destroy$ = new Subject<void>();

	constructor(
		private loggerHttpService: LoggerHttpService,
		public title: Title,
		private configService: ConfigService,
		private fullScreenSpinnerService: FullScreenSpinnerService,
		private router: Router,
		private highlightService: HighlightService,
		private companiesService: CompaniesService,
		private userService: UserService,
		location: Location
	) {
		const appConfig$ = this.configService.whenAppConfig();
		const spinnerConfig$ = this.fullScreenSpinnerService.whenConfig();

		// Inizializza lo spinner sugli eventi di navigazione
		combineLatest([this.router.events, spinnerConfig$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([routeEvent, spinnerConfig]) => {
				if (spinnerConfig.activateOnRouteChange) {
					if (routeEvent instanceof NavigationStart) {
						this.fullScreenSpinnerService.loadingRequest();
					}

					if (routeEvent instanceof NavigationEnd) {
						this.fullScreenSpinnerService.loadedRequest();
						if (routeEvent.urlAfterRedirects.includes('gawcons')) {
									const body = {
										siaCode: this.companiesService.getCurrentCompanyValue().siaCode,
										licenseId: this.companiesService.getCurrentCompanyValue().licenseId,
										date: new Date().toLocaleString()
									};
									this.loggerHttpService.log2( routeEvent.urlAfterRedirects.split('/')[2], body);

						}
					}

					if (routeEvent instanceof NavigationCancel) {
						this.fullScreenSpinnerService.loadedRequest();
					}

					if (routeEvent instanceof NavigationError) {
						this.fullScreenSpinnerService.loadedRequest();
					}

				}
			});

		// Redirect sulla pagina 404 in caso non la trovi con gestione dell'errore
		this.router.events
			.pipe(takeUntil(this.destroy$))
			.subscribe(routeEvent => {
				if (routeEvent instanceof NavigationError) {
					location.go(routeEvent.url);
					router.navigate(['404'], { skipLocationChange: true }).then();
				}
			});

		// Inizializza lo spinner all'avvio dell'applicazione
		this.fullScreenSpinnerService.loadingRequest();

		// Imposta il titolo dell'applicazione
		appConfig$
			.pipe(takeUntil(this.destroy$))
			.subscribe((appConfig: AppConfig) => this.title.setTitle(appConfig.title));
	}

	ngAfterContentInit(): void {
		// Comunica allo spinner che l'app e' inizializzata
		this.fullScreenSpinnerService.loadedRequest();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	ngAfterViewChecked() {
		// Abilita la syntax highlighting nel progetto
		this.highlightService.highlightAll();
	}
}
