import { Component, Input } from '@angular/core';
import { DocumentAttachmentType } from 'app/shared/components/display-document/document-attachment-type';
import { DocumentAttachmentPdf } from '../document-attachment-pdf';
import { DocumentAttachmentXml } from '../document-attachment-xml';

@Component({
	selector: 'hub-tabbed-display-document',
	templateUrl: './tabbed-display-document.component.html',
	styleUrls: ['./tabbed-display-document.component.scss']
})
export class TabbedDisplayDocumentComponent {

	@Input() documentAttachments: (DocumentAttachmentXml | DocumentAttachmentPdf)[] = [];
	@Input() justified = true;
	public documentContentType = DocumentAttachmentType;

}
