import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { TilesMapping } from 'app/entities/ui-config/tiles-mapping';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CardsHttpService } from '../cards/cards-http.service';

/**
 * Servizio che gestisce le chiamate per ottenere varie configurazioni (es. colori stati per canale)
 */
@Injectable({
	providedIn: 'root'
})
export class ColorConfigService {

	private gedFattColors$ = new ReplaySubject<TilesMapping[]>(1);
	private gedPostColors$ = new ReplaySubject<TilesMapping[]>(1);
	private gedMailColors$ = new ReplaySubject<TilesMapping[]>(1);
	private gedPecColors$ = new ReplaySubject<TilesMapping[]>(1);
	private gedPassColors$ = new ReplaySubject<TilesMapping[]>(1);
	private gedPassPlusColors$ = new ReplaySubject<TilesMapping[]>(1);
	private gedEdiColors$ = new ReplaySubject<TilesMapping[]>(1);

	constructor(
		private cardsHttpService: CardsHttpService,
		private router: Router
	) {
		this.cardsHttpService.whenTilesMapping()
			.pipe(
				catchError((err: unknown) => {
					if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
						//this.router.navigate(['/unauthorized']).then();
					} else {
						// if (err.type === ErrorTypes.HTTP_NOT_FOUND) {
						// 	this.notificationService.showSweetAlert(
						// 		NotificationType.INFO,
						// 		'Storico non trovato',
						// 		'');
						// } else {
						// 	this.notificationService.showSweetAlert(
						// 		NotificationType.ERROR,
						// 		'Errore durante la richiesta. Contattare l\'assistenza', '');
						// }
					}
					return EMPTY;
				}),
			)
			.subscribe(
				result => {
					if (result) {
						const pec: TilesMapping[] = [];
						const mail: TilesMapping[] = [];
						const fatt: TilesMapping[] = [];
						const post: TilesMapping[] = [];
						const passplus: TilesMapping[] = [];
						const pass: TilesMapping[] = [];
						const edi: TilesMapping[] = [];

						for (let i = 0; i < result.length; i++)
							switch (result[i].tipoCanale) {
								case 35:
								case 36:
									fatt.push(result[i]);
									break;
								case 21:
									mail.push(result[i]);
									break;
								case 22:
									pec.push(result[i]);
									break;
								case 6:
									post.push(result[i]);
									break;
							}

						this.sendGedPecColorConfig(pec);
						this.sendGedMailColorConfig(mail);
						this.sendGedPostColorConfig(post);
						this.sendGedFattColorConfig(fatt);
						this.sendGedEdiColorConfig(edi);
						this.sendGedPassColorConfig(pass);
						this.sendGedPassPlusColorConfig(passplus);
					}
				}
			);
	}

	// colori stati per canale 35
	whenGedFattColorConfig(): Observable<TilesMapping[]> {
		return this.gedFattColors$.asObservable();
	}

	sendGedFattColorConfig(value: TilesMapping[]) {
		this.gedFattColors$.next(value);
	}

	// colori stati per canale 6
	whenGedPostColorConfig(): Observable<TilesMapping[]> {
		return this.gedPostColors$.asObservable();
	}

	sendGedPostColorConfig(value: TilesMapping[]) {
		this.gedPostColors$.next(value);
	}

	// colori stati per canale 21
	whenGedMailColorConfig(): Observable<TilesMapping[]> {
		return this.gedMailColors$.asObservable();
	}

	sendGedMailColorConfig(value: TilesMapping[]) {
		this.gedMailColors$.next(value);
	}

	// colori stati per canale 22
	whenGedPecColorConfig(): Observable<TilesMapping[]> {
		return this.gedPecColors$.asObservable();
	}

	sendGedPecColorConfig(value: TilesMapping[]) {
		this.gedPecColors$.next(value);
	}

	// colori stati per canale 18
	whenGedPassColorConfig(): Observable<TilesMapping[]> {
		return this.gedPassColors$.asObservable();
	}

	sendGedPassColorConfig(value: TilesMapping[]) {
		this.gedPassColors$.next(value);
	}

	// colori stati per canale 36
	whenGedPassPlusColorConfig(): Observable<TilesMapping[]> {
		return this.gedPassPlusColors$.asObservable();
	}

	sendGedPassPlusColorConfig(value: TilesMapping[]) {
		this.gedPassPlusColors$.next(value);
	}

	// colori stati per canale 10
	whenGedEdiColorConfig(): Observable<TilesMapping[]> {
		return this.gedEdiColors$.asObservable();
	}

	sendGedEdiColorConfig(value: TilesMapping[]) {
		this.gedEdiColors$.next(value);
	}

}
