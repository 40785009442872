import { IAction } from '@ctel/search-filter-store';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionCheck } from 'app/entities/ui-config/action/actions-configuration';
import { EMPTY, Observable } from 'rxjs';

export class ActionObject implements IAction {
	actionCode?: ActionCode;
	url = '';
	params?: unknown;
	code?: string;
	name?: string;
	icon?: string;
	type?: 'singleAction' | 'multipleAction' | 'massiveAction';
	order?: number;
	highlighted?: boolean;
	backgroundColorHex?: string;
	contentColorHex?: string;
	internalChecks?: ActionCheck;
	externalChecks?: ActionCheck[];
	disabledChecks?: [];
	services?: [];

	constructor(
		action?: {
			actionCode?: ActionCode,
			url?: string,
			params?: unknown,
			code?: string,
			name?: string,
			icon?: string,
			type?: 'singleAction' | 'multipleAction' | 'massiveAction',
			order?: number,
			highlighted?: boolean,
			backgroundColorHex?: string,
			contentColorHex?: string,
			internalChecks?: ActionCheck,
			externalChecks?: ActionCheck[],
			disabledChecks?: [],
			services?: [],
			execute?: (...params) => Observable<unknown>
		}
	) {
		this.actionCode = action?.actionCode;
		this.url = action?.url ?? '';
		this.params = action?.params;
		this.code = action?.code;
		this.name = action?.name;
		this.icon = action?.icon;
		this.type = action?.type;
		this.order = action?.order;
		this.highlighted = action?.highlighted;
		this.backgroundColorHex = action?.backgroundColorHex;
		this.contentColorHex = action?.contentColorHex;
		this.internalChecks = action?.internalChecks;
		this.externalChecks = action?.externalChecks;
		this.disabledChecks = action?.disabledChecks;
		this.services = action?.services;
		this.execute = action?.execute ?? this.execute;
	}

	execute: (...params) => Observable<unknown> = () => EMPTY;
}
