import { Component, ElementRef, Host, HostBinding, Inject, Input, Optional, SkipSelf, ViewChild, forwardRef } from '@angular/core';
import {
	AsyncValidator,
	AsyncValidatorFn, ControlContainer,
	NG_ASYNC_VALIDATORS,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	Validator,
	ValidatorFn
} from '@angular/forms';
import { CustomControlComponent } from '../custom-control.component';

@Component({
	selector: 'text-input',
	templateUrl: './text-input.component.html',
	styleUrls: ['./text-input.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => TextInputComponent),
		multi: true,
	}],
})
export class TextInputComponent extends CustomControlComponent<string> {

	@Input() parentElement: ElementRef;

	@HostBinding('attr.id') externalId = '';

	protected _maxlength = Infinity;
	protected _minlength = 0;

	constructor(
		@Optional() @Inject(NG_VALIDATORS) validators: Array<Validator | ValidatorFn>,
		@Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<AsyncValidator | AsyncValidatorFn>,
		@Optional() @Host() @SkipSelf() controlContainer: ControlContainer
	) {
		super(validators, asyncValidators, controlContainer);
	}

	get maxlength() {
		return this._maxlength;
	}

	get minlength() {
		return this._minlength;
	}

	get id() {
		return this._id;
	}

	@Input()
	set id(value: string) {
		this._id = value;
		this.externalId = null;
	}

	@Input() set maxlength(maxlength: number | string) {
		let max = typeof maxlength === 'string' ? Number(maxlength) : maxlength;
		if ((!max || isNaN(max)) && max !== 0)
			max = Infinity;

		this._maxlength = max;
	}

	@Input() set minlength(minlength: number | string) {
		let min = typeof minlength === 'string' ? Number(minlength) : minlength;
		if ((!min || isNaN(min)) && min !== 0)
			min = Infinity;

		this._minlength = min;
	}

	@ViewChild('el', { static: true }) set parentEl(childElRef: ElementRef) {
		if (this.parentElement)
			this.parentElement.nativeElement = childElRef.nativeElement;

	}

	onChangeUpdate() {
		super.updateChanges();
	}
}

