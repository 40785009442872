import { Observable } from 'rxjs';
import { RowAction } from './row-action';

export class AsyncRowAction extends RowAction {
	action: (row) => Observable<unknown>;

	constructor(
		name: string,
		icon: string,
		color = '',
		canExecute: ((params) => boolean),
		action: (row) => Observable<unknown>,
		public validation: ((params) => boolean) = (() => true)
	) {
		super(name, icon, color, canExecute, action);
	}
}
