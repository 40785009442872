import { AccountType } from 'app/core/common/account/accountType';
import { Metric } from '@ctel/gaw-commons';
import { SectionCode } from 'app/entities/ui-config/classification-code.enum';

export const receivableMetricsConfig: Metric[] = [
	{
		metadata: SectionCode.RICEVUTO,
		metricType: 'sum'
	},
	{
		metadata: SectionCode.INERROREPREELAB,
		metricType: 'sum'
	},
	{
		metadata: SectionCode.INFIRMA,
		metricType: 'sum'
	},
	{
		metadata: SectionCode.INSPEDIZIONE,
		metricType: 'sum'
	},
	{
		metadata: SectionCode.CONSEGNATO,
		metricType: 'sum'
	},
	{
		metadata: SectionCode.ANNULLATO,
		metricType: 'sum'
	},
	{
		metadata: SectionCode.INCONSERVAZIONE,
		metricType: 'sum'
	}
];

export const payableMetricsConfig: Metric[] =
	[
		{
			metadata: SectionCode.CP_RICEVUTO35,
			metricType: 'sum'
		},
		{
			metadata: SectionCode.CP_RICEVUTO18,
			metricType: 'sum'
		},
		{
			metadata: SectionCode.CP_RICEVUTO36,
			metricType: 'sum'
		},
		{
			metadata: SectionCode.CP_RICEVUTO10,
			metricType: 'sum'
		},
		{
			metadata: SectionCode.CP_NONACCOPPIATO,
			metricType: 'sum'
		},
		{
			metadata: SectionCode.CP_RICEVUTO,
			metricType: 'sum'
		},
		{
			metadata: SectionCode.CP_SQUADRATO,
			metricType: 'sum',
			filter: {
				metadata: 'hubfe_cp_idTipo',
				filterType: 'term',
				type: 'integer',
				configData: {},
				value: {
					term: '0'
				}

			}
		},
		{
			metadata: SectionCode.CP_ESPORTATO,
			metricType: 'sum'
		},
		{
			metadata: SectionCode.CP_REGISTRATO,
			metricType: 'sum'
		},
		{
			metadata: SectionCode.CP_INCONSERVAZIONE,
			metricType: 'sum'
		},
		{
			metadata: SectionCode.CP_SQUADRATOPOSTELAB,
			metricType: 'sum'
		},
		{
			metadata: SectionCode.CP_ATTESAACCETTRIFIUTO,
			metricType: 'sum'
		},
		{
			metadata: SectionCode.CP_ESCLUSAPROTOCOLLAZIONE,
			metricType: 'sum'
		}
	];

export const metricsConfig: Map<AccountType, Metric[]> = new Map([
	[AccountType.RECEIVABLE, receivableMetricsConfig],
	[AccountType.PAYABLE, payableMetricsConfig]
]);
