import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Province } from 'app/entities/invoice-pa/province/province';

@Injectable()
export class ProvinceHttpService {

	// endpoints
	private validation: string;
	private validationHost: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.validationHost = appConfig.gedInvoice.http.host;
		this.validation = `${this.validationHost}/v1/GedInvoice/Resources/Province`;
	}

	public getProvince() {

		const url = this.validation;

		return this.http.get<Province>(url);
	}
}
