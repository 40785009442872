import { Injectable } from '@angular/core';
import { SingleDocumentCallback } from './callbacks/single-document-callback';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';

@Injectable({
	providedIn: 'root'
})
export class SingleActionsCatalog {

	// Action URL GAWPEC
	public readonly printBarcode = new ActionBuilder(SingleActionsCode.printBarcode, ActionCode.PRINT_BARCODE)
		.setActionURL('/api/v1/Action/cancelonchannel')
		.build();

	public readonly editBox = new ActionBuilder(SingleActionsCode.editBox, ActionCode.EDIT_BOX)
		.setActionURL('/api/v1/Action/cancelonchannel')
		.build();

	public readonly checkAsReceived = new ActionBuilder(SingleActionsCode.checkAsReceived, ActionCode.CHECK_AS_RECEIVED)
		.setActionURL('/api/v1/Action/ChangeTrackingStatus')
		.build();

	public readonly checkAsForwarded = new ActionBuilder(SingleActionsCode.checkAsForwarded, ActionCode.CHECK_AS_FORWARDED)
		.setActionURL('/api/v1/Action/ChangeTrackingStatus')
		.build();

	public readonly checkAsDeleted = new ActionBuilder(SingleActionsCode.checkAsDeleted, ActionCode.CHECK_AS_DELETED)
		.setActionURL('/api/v1/Action/ChangeTrackingStatus')
		.build();

	public readonly checkAsRejected = new ActionBuilder(SingleActionsCode.checkAsRejected, ActionCode.CHECK_AS_REJECTED)
		.setActionURL('/api/v1/Action/ChangeTrackingStatus')
		.build();

	public readonly cancelStateSelection = new ActionBuilder(SingleActionsCode.cancelStateSelection, ActionCode.CANCEL_STATE_SELECTION)
		.setActionURL('/api/v1/Action/ChangeTrackingStatus')
		.build();

	public readonly spoolHistory = new ActionBuilder(SingleActionsCode.spoolHistory, ActionCode.SPOOL_HISTORY)
		.setActionURL('/api/v1/Action/ViewTrackingStatusHistory/{IdScatola}/{Sia}/{IdLicenza}')
		.build();

	public readonly openDetail = new ActionBuilder(SingleActionsCode.openDetail, ActionCode.OPEN_DETAIL)
		.build();

	public readonly openAnomalyDetail = new ActionBuilder(SingleActionsCode.openAnomalyDetail, ActionCode.OPEN_ANOMALY_DETAIL)
		.build();

	public readonly openBoxDetail = new ActionBuilder(SingleActionsCode.openBoxDetail, ActionCode.OPEN_BOX_DETAIL)
		.setActionURL('')
		.build();

	public readonly anomalyHistory = new ActionBuilder(SingleActionsCode.anomalyHistory, ActionCode.ANOMALY_HISTORY)
		.setActionURL('/api/v1/Anomalie/StoricoStatiAnomalie/{idSospeso}')
		.build();

	public readonly pdfDownload = new ActionBuilder(SingleActionsCode.pdfDownload, ActionCode.DOWNLOAD_PDF)
		.setActionURL('')
		.build();

	public readonly editAnomaly = new ActionBuilder(SingleActionsCode.editAnomaly, ActionCode.EDIT_ANOMALY)
		.setActionURL('')
		.build();

	public readonly uploadDocument = new ActionBuilder(SingleActionsCode.uploadDocument, ActionCode.UPLOAD_DOCUMENT)
		.setActionURL('')
		.build();

	public readonly cancelDocument = new ActionBuilder(SingleActionsCode.cancelDocument, ActionCode.CANCEL_DOCUMENT)
		.setActionURL('/api/v1/Anomalie/AnnullaDocumenti')
		.build();

	public readonly processDocument = new ActionBuilder(SingleActionsCode.processDocument, ActionCode.PROCESS_DOCUMENT)
		.setActionURL('/api/v1/Anomalie/ElaboraDocumenti')
		.build();
	constructor(private singleDocumentCallback: SingleDocumentCallback) { }

}

export enum SingleActionsCode {
	printBarcode = 'printBarcode',
	editBox = 'editBox',
	checkAsReceived = 'checkAsReceived',
	checkAsForwarded = 'checkAsForwarded',
	checkAsDeleted = 'checkAsDeleted',
	cancelStateSelection = 'cancelStateSelection',
	spoolHistory = 'spoolHistory',
	downloadDelivery = 'downloadDelivery',
	downloadMissedDelivery = 'downloadMissedDelivery',
	editAddressAndResend = 'editAddressAndResend',
	openDetail = 'openDetail',
	checkAsRejected = 'checkAsRejected',
	openAnomalyDetail = 'openAnomalyDetail',
	openBoxDetail = 'openBoxDetail',
	anomalyHistory = 'anomalyHistory',
	pdfDownload = 'pdfDownload',
	editAnomaly = 'editAnomaly',
	uploadDocument = 'uploadDocument',
	cancelDocument = 'cancelDocument',
	processDocument = 'processDocument',
}
