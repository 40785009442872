import { Observable } from 'rxjs';
import { AttachmentItem } from './attachment-item';

export type WorkitemActions = WorkitemAction[];

export interface WorkitemAction {
	label: string;
	icon: string;
	execute: (attachment: AttachmentItem) => Observable<unknown>;
	disable: (attachment: AttachmentItem) => Observable<unknown>;
}
