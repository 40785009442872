import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DocumentsSearchModule } from './documents-search/documents-search.module';
import { FiltersModule } from './filters/filters.module';

@NgModule({
	imports: [
		CommonModule,
		FiltersModule,
		DocumentsSearchModule
	]
})
export class HomeBusinessModule {
}
