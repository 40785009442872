export interface LottoDetail {
	details?: LottoDetailItem[][];
}

export interface LottoDetailItem {
	value?: string;
	headerLabel?: string;
	tooltipLabel?: string;
	type?: string;
}
