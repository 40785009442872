import { Injectable } from '@angular/core';
import { ProvinceHttpService } from './province-http.service';

@Injectable()
export class ProvinceService {

	constructor(private provinceService: ProvinceHttpService) { }

	public getProvince() {
		return this.provinceService.getProvince();
	}

}
