import { FormArrayPagination } from 'app/entities/invoice-pa/all-validation-errors/form-array-pagination';
import { BehaviorSubject } from 'rxjs';

export const DDTDataArrayPaginationConfig: FormArrayPagination = {
	pagination: {
		itemsPerPage: 1,
		actualPage: 1
	},
	isReady: new BehaviorSubject<boolean>(null),
	formArrayName: 'DatiDDT'
};

export const ContractDataArrayPaginationConfig: FormArrayPagination = {
	pagination: {
		itemsPerPage: 1,
		actualPage: 1
	},
	isReady: new BehaviorSubject<boolean>(null),
	formArrayName: 'DatiContratto'
};

// export const SalDataArrayPaginationConfig: FormArrayPagination = {
// 	pagination: {
// 		itemsPerPage: 1,
// 		actualPage: 1
// 	},
// 	isReady: new BehaviorSubject<boolean>(null),
// 	formArrayName: 'DatiSAL'
// };

export const OrderDataArrayPaginationConfig: FormArrayPagination = {
	pagination: {
		itemsPerPage: 1,
		actualPage: 1
	},
	isReady: new BehaviorSubject<boolean>(null),
	formArrayName: 'DatiOrdine'
};

export const ConventionDataArrayPaginationConfig: FormArrayPagination = {
	pagination: {
		itemsPerPage: 1,
		actualPage: 1
	},
	isReady: new BehaviorSubject<boolean>(null),
	formArrayName: 'DatiConvenzione'
};

export const ReceptionDataArrayPaginationConfig: FormArrayPagination = {
	pagination: {
		itemsPerPage: 1,
		actualPage: 1
	},
	isReady: new BehaviorSubject<boolean>(null),
	formArrayName: 'DatiRicezione'
};

export const ConnectedInvoicesDataArrayPaginationConfig: FormArrayPagination = {
	pagination: {
		itemsPerPage: 1,
		actualPage: 1
	},
	isReady: new BehaviorSubject<boolean>(null),
	formArrayName: 'DatiFattureCollegate'
};
export const SalDataArrayPaginationConfig: FormArrayPagination = {
	pagination: {
		itemsPerPage: 1,
		actualPage: 1
	},
	isReady: new BehaviorSubject<boolean>(null),
	formArrayName: 'DatiSAL'
};

export const DettaglioLineePaginationConfig: FormArrayPagination = {
	pagination: {
		itemsPerPage: 1,
		actualPage: 1
	},
	isReady: new BehaviorSubject<boolean>(null),
	formArrayName: 'DettaglioLinee'
};

export const DatiRiepilogoPaginationConfig: FormArrayPagination = {
	pagination: {
		itemsPerPage: 1,
		actualPage: 1
	},
	isReady: new BehaviorSubject<boolean>(null),
	formArrayName: 'DatiRiepilogo'
};

export const DatiPagamentoPaginationConfig: FormArrayPagination = {
	pagination: {
		itemsPerPage: 1,
		actualPage: 1
	},
	isReady: new BehaviorSubject<boolean>(null),
	formArrayName: 'DatiPagamento'
};

export const AllArrayConfigPagination: FormArrayPagination[] = [
	DDTDataArrayPaginationConfig,
	DettaglioLineePaginationConfig,
	ConnectedInvoicesDataArrayPaginationConfig,
	ConventionDataArrayPaginationConfig,
	ContractDataArrayPaginationConfig,
	OrderDataArrayPaginationConfig,
	SalDataArrayPaginationConfig,
	ReceptionDataArrayPaginationConfig,
	DatiRiepilogoPaginationConfig,
	DatiPagamentoPaginationConfig
];
