import { NotificationType } from '../notification-type';

export class SweetAlertInfo {

	constructor(
		public type: NotificationType,
		public title: string,
		public message: string,
		public confirmCallback?: () => void,
		public cancelCallback?: () => void,
		public allowOutsideClick?: boolean
	) { }

}
