import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { AppConfig } from 'app/entities/config/app-config';
import { ValidationInvoice } from 'app/entities/invoice-pa/validation/validation-invoice';

@Injectable()
export class XmlPaValidatorHttpService {

	// endpoints
	private validation: string;
	private validationHost: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
		private fullScreenSpinnerService: FullScreenSpinnerService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.validationHost = appConfig.gedInvoice.http.host;
		this.validation = `${this.validationHost}/v1/GedInvoice/Json/Validation`;
	}

	public validateInvoice(invoice: string, id_: string, sia_: string, grafica_: string) {
		const url_ = this.validation;
		const stringaDaInviare = invoice;

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			params: {
				id: id_,
				sia: sia_,
				grafica: grafica_
			}
		};

		return this.http.post<ValidationInvoice>(url_, stringaDaInviare, httpOptions);

	}
}
