<!-- Header -->
<div class="modal-header">
	<h5 class="flex-grow-1"><strong>{{title}}</strong></h5>
	<button type="button" class="btn-ghost close" aria-label="Close" (click)="close()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<!-- /Header -->

<ng-container *ngIf="customFooter; else noCustomFooter">
	<ng-container #childComponent></ng-container>
</ng-container>

<ng-template #noCustomFooter>
	<!-- Body -->
	<div class="modal-body">
		{{text}}
		<ng-container #childComponent></ng-container>
	</div>
	<!-- /Body -->

	<!-- Footer -->
	<div class="modal-footer">
		<button type="button" class="btn gaw-default-btn" (click)="close()">Chiudi</button>
	</div>
	<!-- /Footer -->
</ng-template>
