import { Injectable } from '@angular/core';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { Company } from 'app/entities/companies/company';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { EMPTY, Observable } from 'rxjs';
import { ScartoDocumentsService2 } from '../documents/documents.service';
import { ActionsHttpService } from './actions-http.service';
import { RemovePdaModalComponent } from '../../modals/remove-pda-modal/remove-pda-modal.component';
import { catchError, tap } from 'rxjs/operators';
import { DocumentKey } from '@ctel/gaw-commons';

/**
 * Servizio che gestisce l'esecuzione delle azioni sui documenti
 */
@Injectable({
	providedIn: 'root'
})
export class ScartoExecuteActionsService {

	currentCompany: Company;

	constructor(
		private actionsHttpService: ActionsHttpService,
		private notificationService: NotificationService,
		private documentsService: ScartoDocumentsService2,
		private companiesService: CompaniesService
	) {
		this.currentCompany = companiesService.getCurrentCompanyValue();
	}

	public whenLoadingAction(): Observable<boolean> {
		return this.actionsHttpService.whenLoadingAction();
	}

	// ESECUZIONE AZIONE SINGOLA
	public executeSingleAction(
		action: ActionObject,
		data: any
	) {
		const actionCode = action.actionCode;
		switch (actionCode) {
			case ActionCode.REMOVE:
				this.info(data.licenseId, data.siaCode, data.supportiIds);
				break;
		}
	}

	executeMultiAction(action: ActionObject, checkedKeysParsed: DocumentKey[]) {
		const siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
		const licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
		const actionCode = action.actionCode;
		const supportiIds = checkedKeysParsed.map(value => value.supportiIds).flat().map(value => +value);
		switch (actionCode) {
			case ActionCode.REMOVE:
				this.info(licenseId, siaCode, supportiIds);
				break;
		}
	}

	private info(licenseId: string, siaCode: string, supportiIds: number[]) {
		this.actionsHttpService.info(licenseId, siaCode, supportiIds).subscribe(
			{
				next: value => this.notificationService.showModal(NotificationType.CUSTOM, {
					title: 'Richiesta di scarto',
					childComponent: RemovePdaModalComponent,
					customFooter: true,
					disableClickOutside: true,
					childData: {
						info: value,
						supportiIds,
						action: this.request
					}
				}),
				error: err => this.notificationService.showSweetAlert(NotificationType.ERROR, '', 'Errore nella richiesta di scarto')
			}
		);
	}

	private request = (licenseId: string, siaCode: string, supportiIds: number[], accept: boolean) => {
		return this.actionsHttpService.request(licenseId, siaCode, supportiIds, accept).pipe(
			tap(value => {
				this.notificationService.showSweetAlert(NotificationType.SUCCESS, '',
					'La richiesta di scarto è stata completata con successo. Riceverà una PEC di conferma con allegata la richiesta di scarto ' +
					'(scaricabile anche dalla voce di menu Richieste di Scarto). Il documento di Richiesta di scarto dovrà essere stampato, ' +
					'completato, sottoscritto e inviato al nostro Responsabile del Servizio di Conservazione (RSC) tramite il pulsante ' +
					'"Invia richiesta di scarto firmata". Sarà nostra premura segnalarvi prontamente eventuali anomalie di compilazione tramite PEC ' +
					'e segnalarvi la variazione nella citata voce di menu "Richieste di Scarto".');
				this.documentsService.refreshDocuments();
			}),
			catchError(err => {
				this.notificationService.showSweetAlert(NotificationType.ERROR, '', 'Errore nella richiesta di scarto');
				return EMPTY;
			})
		);
	};
}
