import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { ErrorPlaceholderSize } from 'app/core/common/placeholder/error-placeholder/directives/error-placeholder-size.enum';
import { Options } from 'app/core/common/placeholder/error-placeholder/directives/options';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';

@Component({
	selector: 'hub-error-placeholder',
	templateUrl: './error-placeholder.component.html',
	styleUrls: ['./error-placeholder.component.scss']
})
export class ErrorPlaceholderComponent implements AfterViewInit {

	@ViewChild('componentContainer', { read: ViewContainerRef }) insertionPoint;
	@ViewChild('placeholderContainer') placeholderContainer: ElementRef;

	@Input() style: {
		[key: string]: string;
	} = {};

	public message = 'Errore durante il caricamento.';
	public retryButtonLabel = 'Riprova';
	public faIcons = FaIcons;
	public icon = `${this.faIcons.FAS_EXCLAMATION_TRIANGLE}`;
	public iconColor = '#aaaaaa';
	public buttonColor = '#0b56a1';
	public buttonTextColor = 'white';
	public size = ErrorPlaceholderComponent.convertToFontAwesomeSize(ErrorPlaceholderSize.XXXL);
	public onRetry;
	public iconFontSize: string;
	public textFontSize: string;
	public maxTextFontSize: string;
	public maxIconFontSize: string;
	public showText: boolean;
	public showTooltip = false;

	public isLoading = false;
	private readonly DEFAULT_FONT_SIZE = '23px';
	private readonly DEFAULT_ICON_SIZE = '5em';
	private applyDefaultSize: boolean;

	constructor(
		private changeDetectorRef: ChangeDetectorRef
	) { }

	@Input() set loading(load: boolean) {
		if (load === true || load === false)
			this.isLoading = load;

	}

	@Input() set options(options: Options) {

		if (options) {
			if (options.message)
				this.message = options.message;

			if (options.icon)
				this.icon = options.icon;

			if (options.iconColor)
				this.iconColor = options.iconColor;

			if (options.buttonColor)
				this.buttonColor = options.buttonColor;

			if (options.buttonTextColor)
				this.buttonTextColor = options.buttonTextColor;

			if (options.size)
				this.size = ErrorPlaceholderComponent.convertToFontAwesomeSize(options.size);

			if (options.onRetry)
				this.onRetry = options.onRetry;

			if (options.applyDefaultSize)
				this.applyDefaultSize = options.applyDefaultSize;

		}
	}

	public static convertToFontAwesomeSize(placeholderSize: ErrorPlaceholderSize) {
		const faIcons = FaIcons;
		switch (placeholderSize) {
			case ErrorPlaceholderSize.SMALL:
				return '';
			case ErrorPlaceholderSize.MEDIUM:
				return `${faIcons.FA_LG}`;
			case ErrorPlaceholderSize.LARGE:
				return `${faIcons.FA_2X}`;
			case ErrorPlaceholderSize.XL:
				return `${faIcons.FA_3X}`;
			case ErrorPlaceholderSize.XXL:
				return `${faIcons.FA_4X}`;
			case ErrorPlaceholderSize.XXXL:
				return `${faIcons.FA_5X}`;
		}
	}

	ngAfterViewInit() {
		// se applico il size di default
		if (this.applyDefaultSize) {
			this.showText = true;
			this.iconFontSize = this.DEFAULT_ICON_SIZE;
			this.textFontSize = this.DEFAULT_FONT_SIZE;
		} else {
			const containerWidth = (this.placeholderContainer.nativeElement as HTMLElement).offsetWidth;
			const containerHeight = (this.placeholderContainer.nativeElement as HTMLElement).offsetHeight;
			this.showText = containerWidth >= 300 && containerHeight > 100;
			// container normale
			if (containerHeight >= 150 && containerHeight <= 350) {
				this.iconFontSize = (containerHeight / 2).toString() + 'px';
				this.textFontSize = (containerHeight / 10).toString() + 'px';
			} else if (containerHeight < 150) {
				// container molto piccolo
				this.iconFontSize = (containerHeight / 2).toString() + 'px';
				this.textFontSize = '12px';
				this.showText = false;
				this.showTooltip = true;
			} else {
				// container molto grande
				this.iconFontSize = '150px';
				this.textFontSize = '30px';
			}
		}
		this.changeDetectorRef.detectChanges();
	}

	public retry() {
		this.onRetry();
	}

}
