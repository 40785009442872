import { Injectable } from '@angular/core';
import { FilterStatus } from '@ctel/gaw-commons';
import { FilterState, getFiltersStatusWithUserValues, SearchFilterService } from '@ctel/search-filter-store';
import { select, Store } from '@ngrx/store';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { Observable } from 'rxjs';

/**
 * Questa implementazione esiste unicamente per la nuova versione dei filtri che si
 * basa sugli stati applicativi (ngrx). Questa classe è quasi esclusivamente noop.
 */
@Injectable({
	providedIn: 'root'
})
export class FilterService2 extends SearchFilterService {
	private readonly filterStatus$;

	constructor(private store: Store<FilterState>) {
		super();
		this.filterStatus$ = this.store.pipe(select(getFiltersStatusWithUserValues({
			technicalFilters: [
				MetadataEnum.DATA_FATTURA,
				MetadataEnum.DATA_INSERIMENTO,
				MetadataEnum.DATA_RICEZIONE
			]
		})));
	}

	whenFilterValue(): Observable<FilterStatus> {
		return this.filterStatus$;
	}
}
