import { Injectable, OnDestroy } from '@angular/core';
import { FilterPayload, FilterStatus, HomeFilterRangeType } from '@ctel/gaw-commons';
import { Transfer, TransferService, TransferUtils } from '@ctel/transfer-manager';
import { AppErrorBuilder } from 'app/core/common/error/app-error-builder';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { ExcelFormatModalComponent } from 'app/core/common/modals/excel-format-modal/excel-format-modal.component';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { Channel, DocType, MassiveActionRequest, MassiveActionType } from 'app/entities/ui-config/action/massive-action-request';
import { SectionCode } from 'app/entities/ui-config/classification-code.enum';
import { DocumentsSeriesService } from 'app/modules/homepage/core/documents-search/documents-series/documents-series.service';
import { DocumentsService2 } from 'app/modules/homepage/core/documents-search/documents/documents.service';
import { FilterService2 } from 'app/modules/homepage/core/documents-search/filters/filter.service';
import { EMPTY, Observable, Subject, combineLatest, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ActionsHttpService } from '../actions-http.service';

/**
 * Callback delle azioni massive
 */
@Injectable({
	providedIn: 'root'
})
export class AllDocumentsCallback implements OnDestroy {

	private readonly _filterPayload$: Observable<FilterStatus>;
	private readonly _totalDocuments$: Observable<number>;
	private concurrentDownloads = 0;
	private destroy$ = new Subject<void>();

	constructor(
		private documentsService: DocumentsService2,
		private filterService: FilterService2,
		private notificationService: NotificationService,
		private documentsSeriesService: DocumentsSeriesService,
		private actionsHttpService: ActionsHttpService,
		private transferService: TransferService,
	) {
		this._totalDocuments$ = documentsService.whenTotalDocuments();
		this._filterPayload$ = filterService.whenFilterValue();
		this.transferService.whenTransfers().pipe(
			map(value => Object.values(value)),
			takeUntil(this.destroy$)
		)
			.subscribe(v => this.concurrentDownloads = v.filter(v => !TransferUtils.isComplete(v)).length);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	/**
	 * Funzione di esporta excel massivo
	 * @param action
	 * @param extraParams
	 */
	public exportXLSX(action: ActionObject, extraParams?: any): Observable<any> {

		if (!this.checkActionInput(action))
			throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
				.description('Azione non valida')
				.info('action', action)
				.build();

		return this._totalDocuments$
			.pipe(
				take(1),
				mergeMap(docCount => combineLatest([this._filterPayload$])
					.pipe(
						take(1),
						switchMap(([payload]) => {
							// Modal della scelta del formato dell'excel
							const showModal = () => {
								this.notificationService.showModal(
									NotificationType.GENERAL,
									{
										title: 'Esporta risultati in Excel',
										childComponent: ExcelFormatModalComponent,
										childData: {
											action,
											actionType: 'massive',
											// docSeriesDescription: this.documentsSeriesService.getDocSeriesLabel(),
											massivePayload: payload,
											service: 'GAWHOME',
											sectionCode: extraParams ? extraParams.sectionCode : null
										}
									}
								);
							};

							if (docCount > 50000)
								this.notificationService.showSweetAlert(
									NotificationType.QUESTION,
									'Numero massimo di documenti superato.',
									`Procedere all'esportazione dei primi 50000 documenti?
									\nL'operazione potrebbe richiedere alcuni minuti`,
									showModal);
							else
								showModal();

							return EMPTY;
						})
					)
				)
			);
	}

	/**
	 * Funzione di scarica zip PDF massiva
	 * @param action
	 * @param payload
	 * @param serviceId
	 * @param account
	 */
	public downloadPdfZip(action: ActionObject, payload, serviceId, account): Observable<any> {

		if (this.concurrentDownloads >= 2) {
			this.notificationService.showSweetAlert(
				NotificationType.INFO,
				'Attenzione',
				'Un massimo di due trasferimenti contemporanei sono supportati, nel caso di esportazione massiva');
			return EMPTY;
		}

		if (!this.checkActionInput(action))
			throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
				.description('Azione non valida')
				.info('action', action)
				.build();

		const saveZip = (response: Transfer, classification: string) => {
			// TODO: quando per le massive/multiple ci saranno dei filename sensati negli header,
			// si avrà tale filename già disponibile dentro r.name e non sarà più necessario fare un update
			// transfer.
			let fileName;
			if (response.name)
				fileName = response.name;
			else if (this.isFilterDateInPayload(payload, HomeFilterRangeType.GIORNO_PRECEDENTE))
				fileName = `CP_Fatture_Passive_del_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName2(1)}.zip`;
			else
				if (classification === SectionCode.RECEIVABLE)
					fileName = `CA_PDF_Aggregato_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName2()}.zip`;
				else if (classification === SectionCode.PAYABLE)
					fileName = `CP_PDF_Aggregato_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName2()}.zip`;
				else
					fileName = `CP_PDF_Aggregato_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName2()}.zip`;

			this.transferService.updateTransfer(response.key, fileName, FileSaverExtension.ZIP);
			if (TransferUtils.isHttpResponse(response.originatingEvent)) {
				const blob = new Blob([response.originatingEvent.body], { type: 'application/zip' });
				new FileSaver(blob).saveAs(fileName, FileSaverExtension.ZIP);
			} else
				throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
					.description('Oggetto evento originario non valido')
					.info('transfer', response)
					.build();

		};

		const downloadZip$ = (payload, serviceId, account) => {
			const body = this.buildPayloadForMassiveRequest(serviceId, payload, MassiveActionType.DOWNLOAD, 'Pdf', account);

			return combineLatest([this.actionsHttpService.whenPdfZip(action.url, body)])
				.pipe(
					tap(([response]) => saveZip(response, account)),
					catchError((err: unknown) => {
						if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
							//this.router.navigate(['/unauthorized']).then();
						}
						if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND) {
							this.notificationService.showSweetAlert(
								NotificationType.ERROR,
								'Non ci sono documenti da scaricare',
								'');

							return EMPTY;
						}
						if (err?.['type'] === ErrorTypes.HTTP_ERROR) {
							this.notificationService.showSweetAlert(
								NotificationType.ERROR,
								'Errore nel download di uno o più documenti',
								'');

							return EMPTY;
						}
						return throwError(() => err);
					})
				);
		};

		this.notificationService.showSweetAlert(
			NotificationType.QUESTION,
			'Attenzione:',
			`
				Il download potrebbe superare il limite consentito di 500MB.
				<br>
				Lo zip scaricato potrebbe non contenere tutti i documenti
			`,
			() => downloadZip$(payload, serviceId, account).pipe(take(1)).subscribe()
		);
	}

	/**
	 * Funzione di scarica zip XML massiva
	 * @param action
	 * @param payload
	 * @param serviceId
	 * @param account
	 */
	public downloadXmlZip(action: ActionObject, payload, serviceId, account): Observable<any> {

		if (this.concurrentDownloads >= 2) {
			this.notificationService.showSweetAlert(
				NotificationType.INFO,
				'Attenzione',
				'Un massimo di due trasferimenti contemporanei sono supportati, nel caso di esportazione massiva');
			return EMPTY;
		}

		if (!this.checkActionInput(action))
			throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
				.description('Azione non valida')
				.info('action', action)
				.build();

		const saveZip = (response: Transfer, classification: string) => {
			// TODO: quando per le massive/multiple ci saranno dei filename sensati negli header,
			// si avrà tale filename già disponibile dentro r.name e non sarà più necessario fare un update
			// transfer.
			let fileName;
			if (response.name)
				fileName = response.name;
			else if (this.isFilterDateInPayload(payload, HomeFilterRangeType.GIORNO_PRECEDENTE))
				fileName = `CP_Fatture_Passive_del_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName2(1)}.zip`;
			else
				if (classification === SectionCode.RECEIVABLE)
					fileName = `CA_XML_Aggregato_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName2()}.zip`;
				else if (classification === SectionCode.PAYABLE)
					fileName = `CP_XML_Aggregato_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName2()}.zip`;
				else
					fileName = `CP_XML_Aggregato_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName2()}.zip`;

			this.transferService.updateTransfer(response.key, fileName, FileSaverExtension.ZIP);
			if (TransferUtils.isHttpResponse(response.originatingEvent)) {
				const blob = new Blob([response.originatingEvent.body], { type: 'application/zip' });
				new FileSaver(blob).saveAs(fileName, FileSaverExtension.ZIP);
			} else
				throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
					.description('Evento originario non valido')
					.info('transfer', response)
					.build();

		};

		const downloadZip$ = (payload, serviceId, account) => {

			const body = this.buildPayloadForMassiveRequest(serviceId, payload, MassiveActionType.DOWNLOAD, 'Xml', account);

			return combineLatest([this.actionsHttpService.whenPdfZip(action.url, body)])
				.pipe(
					tap(([response]) => saveZip(response, account)),
					catchError((err: unknown) => {
						if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
							//this.router.navigate(['/unauthorized']).then();
						}
						if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND) {
							this.notificationService.showSweetAlert(
								NotificationType.ERROR,
								'Non ci sono documenti da scaricare',
								'');

							return EMPTY;
						}
						if (err?.['type'] === ErrorTypes.HTTP_ERROR) {
							this.notificationService.showSweetAlert(
								NotificationType.ERROR,
								'Errore nel download di uno o più documenti',
								'');

							return EMPTY;
						}
						return throwError(() => err);
					})
				);
		};

		// return combineLatest([this._filterPayload$, s])
		// 	.pipe(
		// 		take(1),
		// 		tap(([payload, serviceId]) => {

		this.notificationService.showSweetAlert(
			NotificationType.QUESTION,
			'Attenzione:',
			`
				Il download potrebbe superare il limite consentito di 500MB.
				<br>
				Lo zip scaricato potrebbe non contenere tutti i documenti
			`,
			() => downloadZip$(payload, serviceId, account).pipe(take(1)).subscribe()
		);
		// 	})
		// );
	}

	// Controllo sull'azione in input, ritorna true se l'url è valido
	// noinspection JSMethodCanBeStatic
	private checkActionInput(action): boolean {
		return !!(action && action.url);
	}

	// In caso di download dei file del "giorno precedente" bisogna scaricare uno .zip con nome differente
	// per capirlo devo controllare se la data del filtro è quella di ieri.
	// Se i filtri ci sono, prendo l'elemento che ha la chiave 'dateRangeType'
	// e controllo se quella chiave è valorizzata con HomeFilterRangeType.GIORNO_PRECEDENTE
	private isFilterDateInPayload(payload: any, homeFilterRangeType: HomeFilterRangeType): boolean {
		let dateRangeFound: Array<any>;
		if (!(payload.filters ?? []).isEmpty)
			dateRangeFound = (payload.filters ?? []).filter(f => f.dateRangeType === homeFilterRangeType);

		return dateRangeFound.length > 0;
	}

	// Elimina anche l'1.
	private formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName2(daysToRemove = 0): string {
		let currentDate = new Date();
		const offset = currentDate.getTimezoneOffset();
		const dir = offset >= 0 ? 1 : -1; // Se siam prima o dopo lo 0, bisogna aggiungere o sottrarre.
		currentDate = new Date(
			currentDate.getTime() + (dir * currentDate.getTimezoneOffset() * 60 * 1000) - (daysToRemove * 24 * 60 * 60 * 1000)
		);
		return currentDate.toISOString()
			.replace(/[\\.:-]/g, '')
			.replace('T', '_')
			.replace('Z', '');
	}

	// Crea il payload da passare alla richiste di azioni massive
	private buildPayloadForMassiveRequest(
		currentService: string, payload: FilterPayload, actionType: MassiveActionType, fileType: string, account
	): MassiveActionRequest {
		// const account = this.accountService.getCurrentAccount() === accountType.PAYABLE ? DocType.PASSIVO : DocType.ATTIVO;
		const service = this.getService(currentService, account);
		return <MassiveActionRequest>{
			elasticRequest: payload,
			action: actionType,
			docType: account,
			fileType,
			channel: service
		};
	}

	// noinspection JSMethodCanBeStatic
	private getService(serviceId: string, account: DocType): string {
		if (account === DocType.PASSIVO)
			return Channel.GEDPASSJOIN;

		if (serviceId === '0')
			return Channel.PREMULTI;

		if (serviceId === '06')
			return Channel.GEDPOST;

		if (serviceId === '21')
			return Channel.GEDMAIL;

		if (serviceId === '22')
			return Channel.GEDPEC;

		if (serviceId === '35')
			return Channel.GEDINVOICE;

	}

}
