import { Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[navigable][formControl], [navigable][formControlName]'
})
export class NativeElementControlDirective {

	@HostBinding('attr.id') externalId = '';

	private _id = '';

	constructor(
		private controlDir: NgControl,
		private host: ElementRef
	) { }

	get control() {
		return this.controlDir.control;
	}

	get id() {
		return this._id;
	}

	@Input()
	set id(value: string) {
		this._id = value;
		this.externalId = null;
	}

	public focus() {
		this.host.nativeElement.focus();
	}

}
