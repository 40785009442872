<div class="preview">
	<ng-container *ngIf="(newPSCredem$ | async) === true">
		<ng-container *ngIf="viewMode === 'MixedMode'; else fullFrame">
			<div class="workitem-preview">
				<gaw-workitem-detail></gaw-workitem-detail>
			</div>
			<div class="be-frame">
				<gaw-workitem-activity (closeModal)="closeModal($event)"></gaw-workitem-activity>
			</div>
		</ng-container>
		<ng-template #fullFrame>
			<div class="be-full-frame">
				<gaw-workitem-full-frame (closeModal)="closeModal($event)"></gaw-workitem-full-frame>
			</div>
		</ng-template>
	</ng-container>
	<ng-container *ngIf="(newPSCredem$ | async) === false">
		<div class="workitem-preview">
			<gaw-workitem-detail></gaw-workitem-detail>
		</div>
		<div class="be-frame">
			<gaw-workitem-activity (closeModal)="closeModal($event)"></gaw-workitem-activity>
		</div>
	</ng-container>
</div>
