import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe per la conversione di valori boolean in italiano (0-1 o true-false in Sì/No)
 */
@Pipe({
	name: 'booleanToItalian'
})
export class BooleanToItalianPipe implements PipeTransform {

	transform(value: boolean, valueTrue: string, valueFalse: string): string {
		if (value === true)
			return valueTrue;

		if (value === false)
			return valueFalse;

	}
}
