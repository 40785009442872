import { Injectable } from '@angular/core';
import { Copier } from 'app/core/common/utilities/copier';
import { InvoiceMultiValues } from 'app/entities/invoice-pa/invoice-multi-values';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { Nations } from './nazione';

@Injectable()
export class NationsService {

	/**
	 * Lista delle nazioni disponibili
	 */
	public nations$: InvoiceMultiValues[];
	public nationsSortedbyLabel$: Observable<InvoiceMultiValues[]>;
	public nationsSortedById$: Observable<InvoiceMultiValues[]>;
	public copyNations: Observable<InvoiceMultiValues[]>;
	// Aggiungo alla fine delle nazioni
	public fatturazioneItem: InvoiceMultiValues = {
		value: 'OO',
		label: 'FATTURAZIONE UTILITY',
		labelWithValue: 'FATTURAZIONE UTILITY'
	};

	constructor() {
		const nationList = Copier.deepCopy(Nations);
		this.nationsSortedById$ = of(nationList)
			.pipe(
				map(nations => this.orderNationsById(nations)),
				map(nations => this.modifyItalyPosition(nations)),
				share({ connector: () => new ReplaySubject<InvoiceMultiValues[]>(1) })
			);
		this.nationsSortedbyLabel$ = of(nationList)
			.pipe(
				map(nations => this.orderNationsByLabel(nations)),
				map(nations => this.modifyItalyPosition(nations)),
				share({ connector: () => new ReplaySubject<InvoiceMultiValues[]>(1) })
			);
	}

	modifyItalyPosition(nations: InvoiceMultiValues[]): InvoiceMultiValues[] {
		const nationsWithoutItaly = nations.filter(nation => nation.value !== 'IT');
		nationsWithoutItaly.push(this.fatturazioneItem);
		const italy: InvoiceMultiValues[] = Copier.deepCopy(Nations).filter(function (obj) {
			return obj.value === 'IT';
		});
		nationsWithoutItaly.unshift(italy[0]);
		return nationsWithoutItaly;
	}

	// ordinamento custom per la dropdown Nazione
	orderNationsByLabel(nations: InvoiceMultiValues[]): InvoiceMultiValues[] {
		nations.sort((a, b) => (a.labelWithValue.toLowerCase() > b.labelWithValue.toLowerCase()) ? 1 : -1);
		return nations;
	}

	orderNationsById(nations: InvoiceMultiValues[]): InvoiceMultiValues[] {
		nations.sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : -1);
		return nations;
	}

	public whenNationsSortedByName(): Observable<InvoiceMultiValues[]> {
		return this.nationsSortedbyLabel$;
	}

	public whenNationsSortedById(): Observable<InvoiceMultiValues[]> {
		return this.nationsSortedById$;
	}
}

