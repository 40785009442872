import { PlatformLocation } from '@angular/common';
import { Component, Input } from '@angular/core';
import { StatusNoteItem } from '@ctel/gaw-commons';
import { ActionsService2 } from '@ctel/gawedi-commons';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

/**
 * Componente modal per un'azione sul documento che mostra uno storico (modifiche o esiti)
 */
@Component({
	selector: 'gawedi-history-action-modal',
	templateUrl: './history-action-modal.component.html',
	styleUrls: ['./history-action-modal.component.scss']
})
export class HistoryActionModalComponent {
	@Input() modal: BsModalRef;

	public history$: Observable<StatusNoteItem[]>;

	constructor(
		private actionsService: ActionsService2,
		private location: PlatformLocation
	) {
		location.onPopState(() => this.modal.hide());
		this.history$ = this.actionsService.whenHistory();
	}

}
