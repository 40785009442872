import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable, ReplaySubject, takeUntil } from 'rxjs';
import { StatusNoteHistory } from '@ctel/gaw-commons';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';

@Injectable({
	providedIn: 'root'
})
export class StatusHistoryHttpService implements OnDestroy {
	private destroy$ = new ReplaySubject<void>(1);

	private gawpecHost!: string;
	private gawlottiHost!: string;
	private statusHistoryUrl!: string;
	private readonly jsonContentType = 'application/json';

	constructor(private http: HttpClient, private configService: ConfigService) {
		this.configService.whenAppConfig()
			.pipe(takeUntil(this.destroy$))
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.gawpecHost = appConfig.gawPecRead.http.host;
		this.gawlottiHost = appConfig.gawLottiRead.http.host;

		this.statusHistoryUrl = `${this.gawlottiHost}/api/v1/StoricoStati/Spool/{progSpool}/Channel/{tipoCanale}/Status`;
	}

	whenStatusHistory(progSpool: string, tipoCanale: string): Observable<StatusNoteHistory[]> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
		const url = this.statusHistoryUrl
			.replace('{progSpool}', progSpool)
			.replace('{tipoCanale}', tipoCanale);
		return this.http.get<StatusNoteHistory[]>(url, options);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
