/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Directive, Input, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';

@Directive({
	selector: '[composeValidators][ngModel]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: ComposeValidatorDirective, multi: true }
	]
})
export class ComposeValidatorDirective implements Validator, OnInit {

	@Input('composeValidators') validators: Array<Validator | ValidatorFn> = [];

	validator: ValidatorFn;

	ngOnInit(): void {
		this.validator = this.composeValidators(this.validators);
	}

	registerOnValidatorChange(fn: () => void): void { }

	validate(c: AbstractControl): ValidationErrors | null {
		if (typeof this.validator === 'function')
			return this.validator(c);

		return null;
	}

	private composeValidators(validators: Array<Validator | ValidatorFn>): AsyncValidatorFn | ValidatorFn {
		if (validators === null || validators.length === 0)
			return null;

		return Validators.compose(validators.map(v => this.normalizeValidator(v)));
	}

	private normalizeValidator(validator: Validator | ValidatorFn): ValidatorFn | AsyncValidatorFn {
		const func = (validator as Validator).validate.bind(validator);
		if (typeof func === 'function')
			return (c: AbstractControl) => func(c);
		else
			return <ValidatorFn | AsyncValidatorFn>validator;

	}

}
