import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ActionsConfigRequest, ConfigurationsGroup } from '../../entities/documents/actions/actions-configuration';
import { ActionsConfigurationHttpService } from '../../ui-configuration/actions-configuration/actions-configuration-http.service';

@Injectable({
	providedIn: 'root'
})
export class ActionsConfigurationService {

	constructor(
		public actionsConfigurationHttpService: ActionsConfigurationHttpService
	) { }
	public getSingleActionsByCard(
		sectionCode: string, licenseId: string, siaCode: string, body: ActionsConfigRequest
	): Observable<ConfigurationsGroup[]> {
		return this.actionsConfigurationHttpService.getSingleActions(sectionCode, licenseId, siaCode, body)
			.pipe(
				take(1),
				map(response => response.configurationsGroup)
			);
	}
	public getMultipleActionsByCard(
		sectionCode: string, licenseId: string, siaCode: string, body: ActionsConfigRequest[]
	): Observable<ConfigurationsGroup[]> {
		return this.actionsConfigurationHttpService.getMultipleActions(sectionCode, licenseId, siaCode, body)
			.pipe(
				take(1),
				map(response => response.configurationsGroup)
			);
	}

	public getMassiveActionsByCard(licenseId: string, siaCode: string, sectionCode: string): Observable<ConfigurationsGroup[]> {
		return this.actionsConfigurationHttpService.getMassiveActions(licenseId, siaCode, sectionCode)
			.pipe(
				take(1),
				map(response => response.configurationsGroup)
			);
	}

}
