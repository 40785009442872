import { Moment } from 'moment';

export interface DateRange {
	getStart(): Moment;

	getEnd(): Moment;
}

export class DateRange {
	private readonly _start: Moment;
	private readonly _end: Moment;

	constructor(start: Moment, end: Moment) {
		this._start = start;
		this._end = end;
	}

	getStart(): Moment {
		return this._start;
	}

	getEnd(): Moment {
		return this._end;
	}
}
