import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { UploadLottoMessage } from 'app/entities/invoice-pa/upload-lotto-message/upload-lotto-message';
import { Observable } from 'rxjs';
import { ValidationGawNotice } from '../../../../../entities/invoice-pa/validation/validation-gawnotice';

@Injectable()
export class UploadDocumentHttpService {

	private validation: string;
	private validationHost: string;
	private validationHostGedNotice: string;
	private resendDocumentUrl: string;
	private validationGedNoticeUrl: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.validationHost = appConfig.uploadDocument.http.host;
		this.validationHostGedNotice = appConfig.gawNoticeRead.http.host;
		this.validation = `${this.validationHost}/v1/Upload/UploadDocumentPa`;
		this.resendDocumentUrl = `${this.validationHost}/v1/Upload/SendCopiaAnalogica?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&documentId={documentId}&documentSeries={documentSeries}&channelToResend={channelToResend}&metadataMatching={metadataMatching}`;
		this.validationGedNoticeUrl = `${this.validationHostGedNotice}/v1/validate/validateMetadata`;
	}

	public uploadDocumentPa(id: string, sia: string, grafica: string, documentPa: string, gedInvoiceIsNotPresent, folderId?: string) {
		const url = !gedInvoiceIsNotPresent ? `${this.validationHost}/v1/Upload/UploadDocument` : this.validation;
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'text/json' }),
			params: { id, sia, grafica, metadataMatching: 'SequenceNumberMatchingKey' }
		};

		if (folderId)
			httpOptions.params['idFascicolo'] = folderId;

		return this.http.post<UploadLottoMessage>(url, documentPa, httpOptions);
	}

	public sendCopiaAnalogica(
		docHash: string,
		progSpool: string,
		progBusta: string,
		documentId: string,
		documentSeries: string,
		channelToResend: number,
		metadataMatching: 'SequenceNumberMatchingKey' | 'LabelMatchingKey',
		body: any): Observable<{ success: boolean, message: string }> {

		const url = this.resendDocumentUrl
			.replace('{docHash}', docHash === '' ? null : docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta)
			.replace('{documentId}', documentId)
			.replace('{documentSeries}', documentSeries)
			.replace('{channelToResend}', channelToResend.toString())
			.replace('{metadataMatching}', metadataMatching);

		return this.http.post<{ success: boolean, message: string }>(url, body, { headers: new HttpHeaders({ 'log': 'true' }) });
	}

	public validateGedNoticeDocument(documentPa: object): Observable<ValidationGawNotice[]> {
		const url = this.validationGedNoticeUrl;
		return this.http.post<ValidationGawNotice[]>(url, documentPa);
	}
}
