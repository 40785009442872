import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnauthorizedComponent } from './unauthorized.component';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		UnauthorizedComponent,
	],
	exports: [
		UnauthorizedComponent,
	]
})
export class UnauthorizedModule { }
