import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalContainerComponent } from './containers/modal-container/modal-container.component';
import { ModalType } from './modal-type.enum';

@Injectable()
export class ModalService {

	private bsModalRef: BsModalRef;

	constructor(
		private modalService: BsModalService
	) { }

	// TODO Data è stato cambiato da ModalInfo a any per poter passare un'activity instance. Va ragionato e rifattorizzato.
	public show(data: any) {

		let container;
		let modalOptions: ModalOptions;
		let initialStateData: Partial<Record<string, unknown>>;

		switch (data.type) {

			case ModalType.SIMPLE_LARGE:
				container = ModalContainerComponent;
				initialStateData = data.content;
				modalOptions = {
					class: 'modal-lg',
					initialState: initialStateData,
					ignoreBackdropClick: false
				};
				break;
			case ModalType.SIMPLE_LARGE_DISABLED_CLICK:
				container = ModalContainerComponent;
				initialStateData = data.content;
				modalOptions = {
					class: 'modal-lg',
					initialState: initialStateData,
					ignoreBackdropClick: true
				};
				break;
			case ModalType.EXTRA_LARGE:
				container = ModalContainerComponent;
				initialStateData = data.content;
				modalOptions = {
					class: 'modal-xl',
					initialState: initialStateData,
					ignoreBackdropClick: false
				};
				break;
			case ModalType.EXTRA_LARGE_DISABLED_CLICK:
				container = ModalContainerComponent;
				initialStateData = data.content;
				modalOptions = {
					class: 'modal-xl',
					initialState: initialStateData,
					ignoreBackdropClick: true
				};
				break;
			case ModalType.XXL:
				container = ModalContainerComponent;
				initialStateData = data.content;
				modalOptions = {
					class: 'modal-xxl',
					initialState: initialStateData,
					ignoreBackdropClick: true
				};
				break;
			case ModalType.SIMPLE:
			default:
				container = ModalContainerComponent;
				initialStateData = data.content;
				modalOptions = {
					initialState: initialStateData,
					ignoreBackdropClick: false
				};

				break;
		}

		this.bsModalRef = this.modalService.show(container, modalOptions);
	}
}
