import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { AppErrorBuilder, ErrorTypes } from 'app/core/common/error';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfigService } from './config.service';

export function configInit(configService: ConfigService) {
	return () =>
		// Qui ritorniamo una promessa che risolve solo dopo che entrambe le config per dry e app
		// sono state scaricate.
		// TODO: erroneamente è il configService che esegue i send() dei relativi observable.
		// Per coerenza, ed evitare problemi di semi inizializzazione, direi che dovrebbe essere questa promessa
		// a dover fare i due send(); quindi solo dopo che entrambi i config sono stati scaricati.
		new Promise((resolve, reject) => {
			const dryPromise = firstValueFrom(configService.whenDryConfig().pipe(take(1)));
			const appPromise = firstValueFrom(configService.whenAppConfig().pipe(take(1)));

			dryPromise
				.then(() => appPromise)
				.then((_) => resolve(_))
				.catch(err => {
					reject();
					throw new AppErrorBuilder(ErrorTypes.CONFIG_LOADING_ERROR)
						.cause(err)
						.description('Impossibile inizializzare DryConfig/AppConfig')
						.build();
				});
		});
}

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [],
	providers: [
		ConfigService,
		{
			provide: APP_INITIALIZER,
			useFactory: configInit,
			deps: [ConfigService],
			multi: true
		}
	]
})
export class ConfigModule {
	static forRoot(): ModuleWithProviders<ConfigModule> {
		return {
			ngModule: ConfigModule,
			providers: [
				ConfigService,
			]
		};
	}
}
