import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Note } from '../entities/note';
import { Sorting } from '../entities/sorting';
import * as DocumentNotesActions from '../store/document-notes.actions';
import { getDocumentId, getDocumentNotes, getMode, getSelectedNote, getSorting } from '../store/document-notes.selector';

@Injectable()
export class DocumentNotesService {

	constructor(
		private readonly store: Store
	) { }

	/** Setta nello stato gli identificativi del documento */
	public setDocumentId(progSpool: number, progBusta: number, idSerieDoc: number) {
		this.store.dispatch(DocumentNotesActions.setDocumentId(progSpool, progBusta, idSerieDoc));
	}

	/** Ritorna gli identificativi del documento presenti nello stato */
	public getDocumentId(): Observable<{ progSpool: number, progBusta: number }> {
		return this.store.pipe(
			select(getDocumentId),
		);
	}

	/** Setta nello stato la modalità delle note */
	public setMode(mode: 'new' | 'edit' | 'readonly') {
		this.store.dispatch(DocumentNotesActions.setMode(mode));
	}

	/** Ottiene la modalità presente nello stato */
	public getMode(): Observable<'new' | 'edit' | 'readonly'> {
		return this.store.pipe(
			select(getMode),
			map(res => res.mode)
		);
	}

	public getNotes(): Observable<Note[]> {
		return this.store.pipe(select(getDocumentNotes));
	}

	public selectNote(note: Note, userId: number) {
		// IMPORTANT: Se non faccio il parse ottengo un errore di stato non serializzabile
		this.store.dispatch(DocumentNotesActions.selectNote(JSON.parse(JSON.stringify(note)), userId));
	}

	public getSelectedNote(): Observable<Note> {
		return this.store.pipe(
			select(getSelectedNote),
			map(value => value.selectedNote)
		);
	}

	public getSorting(): Observable<Sorting> {
		return this.store.pipe(
			select(getSorting),
			map(val => val.sorting)
		);
	}

	public setSorting(sorting: Sorting) {
		this.store.dispatch(DocumentNotesActions.setSorting(sorting));
	}

	public submitMsg(msg?: string) {
		this.store.dispatch(DocumentNotesActions.submitTextArea(msg ? msg : ''));
	}

	public deleteNote(note: Note, progSpool: number, progBusta: number, idSerieDoc: number) {
		this.store.dispatch(DocumentNotesActions.deleteNote(note, progSpool, progBusta, idSerieDoc));
	}
}
