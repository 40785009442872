import { HomeFilterRangeType, HomeFilterUrl } from '@ctel/gaw-commons';
import { IFilter, IRouterStateUrl, selectReducerState } from '@ctel/search-filter-store';
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FilterBuilder } from 'app/constants/filters/filter-builder';
import { produce } from 'immer';
import * as Rison from 'rison-node';
import { documentsSearchModuleFeatureKey } from './feature-key';
import { HomeFilterState, HomeFiltersModuleState } from './home-filter.reducer';

export enum HOME_URI_STATE_TOKENS {
	HOME_FILTERS = 'hf'

}

export const selectHomeFilterModuleState = createFeatureSelector<HomeFiltersModuleState>(documentsSearchModuleFeatureKey);

export const selectHomeFilterState = createSelector(
	selectHomeFilterModuleState,
	(state: HomeFiltersModuleState) => state.homeFilterState
);

export const getHomeFilters = createSelector(
	selectHomeFilterState,
	(state: HomeFilterState) => state.filters
);

export const getCachedHomeFilters = createSelector(
	selectHomeFilterState,
	(state: HomeFilterState) => ({
		payable: state.cachedPayableHomeFilters,
		receivable: state.cachedReceivableHomeFilters,
	})
);

// TODO: stessa logica della library. Esponi quella.
export const getHomeFiltersUrl = createSelector(
	selectReducerState,
	(state: RouterReducerState<IRouterStateUrl>): HomeFilterUrl[] => {
		if (!state)
			return [];

		const queryParams = state.state.queryParams;
		if (!queryParams || !queryParams[HOME_URI_STATE_TOKENS.HOME_FILTERS])
			return [];

		const filterUrl: HomeFilterUrl[] = Rison.decode_uri(queryParams[HOME_URI_STATE_TOKENS.HOME_FILTERS]);

		return filterUrl.filter((filter: HomeFilterUrl) => {
			if (!filter.m || !filter.t)
				return false;

			return (filter.t === HomeFilterRangeType.CUSTOM && filter.v && filter.v.f && filter.v.to)
				|| (filter.t !== HomeFilterRangeType.CUSTOM && !filter.v);
		});
	}
);

export const getHomeFiltersWithUserValuesForSearch = createSelector(
	getHomeFilters,
	getHomeFiltersUrl,
	(allFilters: IFilter[], userFilter: HomeFilterUrl[],) =>

		// Qui uso immer per tornare l'oggetto clonato.
		produce(allFilters, draftFilters => {
			draftFilters.forEach((value: IFilter, index: number, array: IFilter[]) => {
				const filterUrlIndex = userFilter.findIndex(i => i.m === value.metadata);
				if (filterUrlIndex !== -1) {
					// Il metadato è presente, aggiorniamo il value del filtro attuale.
					const filterUrl: HomeFilterUrl = userFilter[filterUrlIndex];
					if (filterUrl.t !== HomeFilterRangeType.CUSTOM) {
						// Per i tipi non custom, mi ricalcolo i range.
						const built = FilterBuilder.generalHomeDateFilter(filterUrl.m, filterUrl.t);
						array[index].value.from = built.value.from;
						array[index].value.to = built.value.to;
					} else {
						array[index].value.from = filterUrl.v.f;
						array[index].value.to = filterUrl.v.to;
					}
				}
			});

			return draftFilters;
		})

);

export const getHomeFiltersStateWithUserValues = createSelector(
	selectHomeFilterState,
	getHomeFiltersWithUserValuesForSearch,
	(filterState: HomeFilterState, filtersWithValues: IFilter[]) => ({
		...filterState,
		filters: filtersWithValues
	})
);
