import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ErrorPlaceholderModule } from 'app/core/common/placeholder/error-placeholder/error-placeholder.module';
import { DisplayDocumentModule } from 'app/shared/components/display-document/display-document.module';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { WorkitemHistoryModule } from '../workitem-history/workitem-history.module';
import { DocumentAttachmentPreviewComponent } from './document-attachments/document-attachment-preview/document-attachment-preview.component';
import { DocumentAttachmentsListComponent } from './document-attachments/document-attachments-list/document-attachments-list.component';
import { DocumentPreviewComponent } from './document-preview/document-preview.component';
import { AttachmentIconPipe } from './shared/attachment-icon.pipe';
import { AttachmentListComponent } from './shared/attachment-list/attachment-list.component';
import { AttachmentPreviewComponent } from './shared/attachment-preview/attachment-preview.component';
import { StatusHistoryComponent } from './status-history/status-history.component';
import { UserAttachmentPreviewComponent } from './user-attachments/user-attachment-preview/user-attachment-preview.component';
import { UserAttachmentsListComponent } from './user-attachments/user-attachments-list/user-attachments-list.component';
import { WorkitemDetailRoutingModule } from './workitem-detail-routing.module';
import { WorkitemDetailComponent } from './workitem-detail.component';

@NgModule({
	imports: [
		CommonModule,
		DisplayDocumentModule,
		TabsModule,
		RouterModule,
		ErrorPlaceholderModule,
		WorkitemHistoryModule.forRoot(),
		// Routing
		WorkitemDetailRoutingModule,
		PipesModule,
	],
	declarations: [
		WorkitemDetailComponent,
		// Document Preview
		DocumentPreviewComponent,
		// Attachments
		AttachmentListComponent,
		AttachmentPreviewComponent,
		// Document attachments
		DocumentAttachmentsListComponent,
		DocumentAttachmentPreviewComponent,
		// User attachments
		UserAttachmentsListComponent,
		UserAttachmentPreviewComponent,
		// Storico
		StatusHistoryComponent,
		AttachmentIconPipe,
	],
	exports: [
		WorkitemDetailComponent,
		AttachmentPreviewComponent,
		// Document Preview
		DocumentPreviewComponent,
		// Document attachments
		DocumentAttachmentsListComponent,
		DocumentAttachmentPreviewComponent,
		// User attachments
		UserAttachmentsListComponent,
		UserAttachmentPreviewComponent,
		// Storico
		StatusHistoryComponent,
		AttachmentListComponent,
		// Modules
		WorkitemHistoryModule
	]
})
export class WorkitemDetailModule { }
