import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocSeriesColor, DocumentDetails, IDetailsComponent, Memoize, Metadata } from '@ctel/gaw-commons';
import {
	ActionsService2,
	DocumentDetailsService,
	ExecuteActionsService,
	SingleActionsCode
} from '@ctel/gawmail-commons';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { DocumentsSeriesService } from '../documents-search/documents-series/documents-series.service';

@Component({
	selector: 'gaw-gawmail-detail',
	templateUrl: './gawmail-detail.component.html',
	styleUrls: ['./gawmail-detail.component.scss'],
})
export class GawmailDetailComponent implements OnInit {
	@Input() data: IDetailsComponent;

	public singleActionsList$: Observable<ActionObject[]> = this.actionsService.whenSingleActionsList();
	public singleActionsList: ActionObject[];
	public actionLoadingError$: Observable<boolean>;
	public documentDetails$: Observable<DocumentDetails>;
	public metadatiCanale$: Observable<DocumentDetails>;
	public singleActionsCode = SingleActionsCode;
	public metadataEnum = MetadataEnum;
	public actionCode = ActionCode;
	public documentSeriesColors$: Observable<DocSeriesColor[]>;

	constructor(
		private executeActionsService: ExecuteActionsService,
		private actionsService: ActionsService2,
		private documentDetailsService: DocumentDetailsService,
		private documentSeriesService: DocumentsSeriesService,
		private route: ActivatedRoute
	) { }

	@Memoize()
	getDocLabel(metadataList: Metadata[]) {
		const docLabel = metadataList.find((m) => m.keyCode === this.metadataEnum.DOCLABEL);
		return docLabel && docLabel.value ? docLabel.value : 'Doc Label sconosciuta';
	}

	ngOnInit() {
		// OTTENGO I METADATI E LE AZIONI DEL DOCUMENTO
		this.documentDetails$ = this.documentDetailsService.whenDocumentDetails(
			this.data.licenseId,
			this.data.siaCode,
			this.data.docSeriesId,
			this.data.elasticId,
			true
		);
		this.documentDetails$.pipe(
			switchMap((details) =>
				this.actionsService.requestSingleActionsConfig(
					this.data.licenseId, this.data.siaCode, this.route.snapshot.params.section, details.keys
				)
			),
			take(1)
		).subscribe();

		// METADATI DOCUMENTO
		this.metadatiCanale$ = this.documentDetailsService.whenMetadatiCanale(
			this.data.licenseId,
			this.data.siaCode,
			this.data.docSeriesId,
			this.data.elasticId
		);

		// AZIONI DOCUMENTO
		this.singleActionsList$ = this.actionsService.whenSingleActionsList();

		// Colori Serie documentale
		this.documentSeriesColors$ = this.documentSeriesService.whenDocumentsSeriesColors();
	}

	// le azioni nel dettaglio coincidono con le azioni singole
	executeAction(action: ActionObject, keys: object) {
		// per il workflow salvo l'id serie doc reale (in caso venissi dalla all)
		const keysArray = Object.entries(keys).map(([key, value]) => ({ key, value }));
		for (const item of keysArray)
			if (item.key === 'ctelDocSeriesId')
				this.documentDetailsService.sendRealDocSeriesId('' + item.value);

		this.executeActionsService.executeSingleAction(action, keys, false);
	}
}
