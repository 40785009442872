import { ModalData } from './modal-data';
import { ModalType } from './modal-type.enum';

export class ModalInfo {

	constructor(
		public type: ModalType,
		public content: ModalData
	) { }
}
