import { Component, Host, HostBinding, Inject, Input, Optional, SkipSelf, forwardRef } from '@angular/core';
import {
	AsyncValidator,
	AsyncValidatorFn, ControlContainer,
	NG_ASYNC_VALIDATORS,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	Validator,
	ValidatorFn
} from '@angular/forms';
import { CustomControlComponent } from '../custom-control.component';

@Component({
	selector: 'number-input',
	templateUrl: './number-input.component.html',
	styleUrls: ['./number-input.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => NumberInputComponent),
		multi: true,
	}],
})
export class NumberInputComponent extends CustomControlComponent<string> {

	@Input() max: number;

	@HostBinding('attr.id') externalId = '';

	private _ID = '';

	constructor(
		@Optional() @Inject(NG_VALIDATORS) validators: Array<Validator | ValidatorFn>,
		@Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<AsyncValidator | AsyncValidatorFn>,
		@Optional() @Host() @SkipSelf() controlContainer: ControlContainer
	) {
		super(validators, asyncValidators, controlContainer);
	}

	get id() {
		return this._ID;
	}
	@Input()
	set id(value: string) {
		this._ID = value;
		this.externalId = null;
	}

	onChangeUpdate() {
		super.updateChanges();
	}
}
