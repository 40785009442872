import { Pipe, PipeTransform } from '@angular/core';
import { DocumentAttachmentPdf } from '../components/display-document/document-attachment-pdf';
import { DocumentAttachmentType } from '../components/display-document/document-attachment-type';
import { DocumentAttachmentXml } from '../components/display-document/document-attachment-xml';

@Pipe({
	name: 'asPdfDoc'
})
export class AsPdfDocPipe implements PipeTransform {

	transform(value: DocumentAttachmentXml | DocumentAttachmentPdf): DocumentAttachmentPdf | undefined {
		if (!value || value.type === DocumentAttachmentType.XML) return undefined;
		return value as DocumentAttachmentPdf;
	}

}
