import { PlatformLocation } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

/**
 * Componente modal di notifica
 */
@Component({
	selector: 'gaw-notification-modal',
	templateUrl: './notification-modal.component.html',
	styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnDestroy {

	@Input() title: string;
	@Input() data: {
		message: string;
	};
	@Input() modal: BsModalRef;
	private destroy$ = new Subject<void>();

	constructor(private location: PlatformLocation) {
		// gestione per il tasto indietro del browser (se la modal è aperta, la nasconde)
		location.onPopState(() => this.modal.hide());
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
