import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function CausaleValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value || !Array.isArray(control.value) || control.value.length === 0)
			return null;

		const errors = [];
		control.value.forEach((value, index) => {
			if (value.length < 1 || value.length > 200)
				errors.push(`${index + 1}`);

		});

		if (errors.length === 0)
			return null;

		return <ValidationErrors>{
			causaleInvalid: { errors }
		};
	};
}
