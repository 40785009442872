import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorModule } from 'app/core/common/error/error.module';
import { LandingModule } from '../landing/landing.module';
import { VersionModule } from '../error/version/version.module';
// import { AccessDeniedComponent } from './access-permission-denied/access-denied.component';

@NgModule({
	imports: [
		CommonModule,
		ErrorModule,
		LandingModule,
		VersionModule
	],
	declarations: [
		// AccessDeniedComponent
	],
	exports: [
		// AccessDeniedComponent
	],
	providers: []
})
export class PublicPagesModule {
}
