import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JsonToXmlHttpService } from './json-to-xml-http.service';

@Injectable()
export class JsonToXmlService {

	constructor(private jsonToXmlService: JsonToXmlHttpService) { }

	public convertJsonToXml(id, sia, invoice: string): Observable<string> {
		return this.jsonToXmlService.convertJsonToXml(id, sia, invoice);
	}

}
