/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative al dettaglio documento
 */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Transfer, TransferService, transferHandler } from '@ctel/transfer-manager';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionCrudResponse } from 'app/entities/ui-config/action/action-crud-response';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterPayload, HideShowDocumentsResponse } from '@ctel/gaw-commons';

@Injectable({
	providedIn: 'root'
})
export class ActionsHttpService implements OnDestroy {

	private jsonContentType = 'application/json';
	// host
	private documentGedHost: string;
	private documentsExportHost: string;
	private documenti: string;
	private documentiPreview: string;
	private asyncMassiveDownload: string;
	private readonly jsonOptions: object;
	private actionLoading$ = new BehaviorSubject<boolean>(false);

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
		private transferService: TransferService,
		private fullScreenSpinnerService: FullScreenSpinnerService
	) {
		this.configService.whenAppConfig()
			.pipe(takeUntil(this.destroy$))
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));

		this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json', null, true);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	httpHostInit(appConfig: AppConfig) {
		/* ENDPOINTS DOCUMENT GED ------------------------------------------------------------------------------------------------------- */
		this.documentGedHost = appConfig.docDetails.http.host;
		/* ENDPOINTS DOCUMENT EXPORT ---------------------------------------------------------------------------------------------------- */
		this.documentsExportHost = appConfig.documentsExport.http.host;
		/* ENDPOINT GawTre.Api.Documenti */
		this.documenti = appConfig.documenti.http.host;
		this.documentiPreview = `${this.documenti}/Fatture/{progSpool}/{progBusta}/allegati/stream`;
		this.asyncMassiveDownload = `${this.documenti}/Fatture/allegati/azioni/esportazione`;
		// tslint:disable-next-line:max-line-length
		// this.hasAttachments = `${this.docDetailsHost}/v1/Document/GEDINVOICE/HasAttachments?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&documentChannelType={account}`;
	}

	// AZIONI -------------------------------------------------------------------------------------------------------
	// SINGOLA: download pdf
	whenSinglePdf(actionUrl: string, progSpool: string, progBusta: string, docHash: string): Observable<Transfer> {
		const url = this.documentGedHost + actionUrl
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts).pipe(
					transferHandler(this.transferService)
				);
			})
		);
	}

	whenPreviewSinglePdf(actionUrl: string, progSpool: string, progBusta: string, docHash: string): Observable<unknown> {
		const url = this.documentGedHost + actionUrl
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		const options = CustomHttpOptions.getHttpOptionsObserveResponse(this.jsonContentType, 'blob', null, true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts);
			})
		);
	}
	// SINGOLA: download allegati
	whenAttachments(actionUrl: string, progSpool: string, progBusta: string, docHash: string): Observable<Transfer> {
		const url = this.documentGedHost + actionUrl
			.replace('{docHash}', docHash)
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts).pipe(
					transferHandler(this.transferService)
				);
			})
		);
	}

	// SINGOLA: edit chiavi
	whenEditKeys(actionUrl: string, body: unknown): Observable<unknown> {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json', null, true);
		const url = this.documentGedHost + actionUrl;
		return this.http.post<unknown>(url, body, options);
	}

	// SINGOLA: Scarica storico stati
	public downloadWFHistory(
		actionUrl: string,
		body: { progSpool: string, progBusta: string, idSerieDoc: number, license: string, codSia: string, companyDescription: string }
	): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.documentGedHost + actionUrl;
		const params = { ...body };
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				opts['params'] = params;
				return this.http.get<unknown>(url, opts).pipe(
					transferHandler(this.transferService)
				);
			})
		);

	}

	// SINGOLA: download xml
	whenSingleXml(actionUrl: string, progSpool: string, progBusta: string): Observable<Transfer> {
		const url = this.documenti + actionUrl
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts).pipe(
					transferHandler(this.transferService)
				);
			})
		);
	}
	// SINGOLA: preview xml
	whenPreviewXml(progSpool: string, progBusta: string): Observable<unknown> {
		const url = this.documentiPreview
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);
		const options = CustomHttpOptions.getHttpOptionsObserveResponse('application/xml', 'text', null, true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts);
			})
		);
	}

	// MULTI: download zip di documenti
	whenPdfZip(actionUrl: string, body: unknown): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.documentGedHost + actionUrl;
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.post<unknown>(url, body, opts).pipe(
					transferHandler(this.transferService)
				);
			})
		);
	}

	// MULTI: download xml di documenti
	whenMultiXml(actionUrl: string, body: any): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob');
		const url = this.documenti + actionUrl;
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.post<any>(url, body, opts).pipe(
					transferHandler(this.transferService)
				);
			})
		);
	}

	// MULTI: download pdf mergiati
	whenMergedPdf(actionUrl: string, body: unknown): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.documentGedHost + actionUrl;
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.post<unknown>(url, body, opts).pipe(
					transferHandler(this.transferService)
				);
			})
		);
	}

	// MULTI: download excel doc selezionati
	whenExcel(actionUrl: string, body: unknown, format?: 'xlsx' | 'xls' | 'csv', sectionCode?: string): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.documentsExportHost + actionUrl;
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				opts['params'] = {
					format
				};

				if (sectionCode)
					opts['params'].sectionCode = sectionCode;

				return this.http.post(url, body, opts).pipe(
					transferHandler(this.transferService)
				);
			})
		);
	}

	// MASSIVA: download excel di tutta la serie documentale/sezione
	whenExcelAll(actionUrl: string, body: unknown): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.documentsExportHost + actionUrl;
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.post(url, body, opts).pipe(
					transferHandler(this.transferService)
				);
			})
		);
	}

	// nascondi/mostra documenti
	public whenHideAndShowDocuments(actionUrl: string, body: unknown, hide: boolean): Observable<HideShowDocumentsResponse> {
		const url = this.documentGedHost + actionUrl
			.replace('{hide}', JSON.stringify(hide));
		return this.http.post(url, body, this.jsonOptions);
	}

	// nasdondi/mostra documenti massivo
	public whenHideAndShowDocumentsMassive(actionUrl: string, body: unknown, hide: boolean): Observable<ActionCrudResponse> {
		const url = this.documentGedHost + actionUrl
			.replace('{hide}', JSON.stringify(hide));
		return this.http.post<ActionCrudResponse>(url, body, this.jsonOptions);
	}

	public whenLoadingAction(): Observable<boolean> {
		return this.actionLoading$.asObservable();
	}

	public setLoadingAction(value: boolean) {
		this.actionLoading$.next(value);
	}

	whenMoveToTrash(actionUrl: string, body: unknown): Observable<unknown> {
		const url = this.documenti + actionUrl;
		return this.http.post<unknown>(url, body, this.jsonOptions);
	}

	asyncXMLMassiveDownload(elasticRequest: FilterPayload) : Observable <any>{
		return this.massiveDownload(elasticRequest, 'Xml')
	}

	private massiveDownload(elasticRequest: FilterPayload, fileType: string): Observable<any> {
		const url = this.asyncMassiveDownload;
		const body = { elasticRequest, fileType };
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap(fullscreenHeaders => {
				return this.http.post(url, body, { headers: fullscreenHeaders });
			})
		);
	}

	public whenDownloadPDF(actionUrl: string, progSpool: string, progBusta:string){
		const url = this.documenti + actionUrl.replace('{progSpool}', progSpool).replace('{progBusta}',progBusta);
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent('application/zip', 'blob', null, true);
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap((value) => {
				const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
				return this.http.get(url, opts).pipe(
					transferHandler(this.transferService)
				);
			})
		);
	}
}
