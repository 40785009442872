export interface PDDResponseModel {
	dataRichiesta: string;
	descrizione: string;
	flagAttestato: boolean;
	flagComunicazioniAde: boolean;
	flagIPDA: boolean;
	flagRDV: boolean;
	flagRicevuteAde: boolean;
	flagSuperimpronte: boolean;
	indirizzoDestinatario: string;
	totDocRichiestiPerPDD: number;
}
