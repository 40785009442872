<div class="preview h-100 pb-3">
	<tabset [justified]='true'>
		<tab class='tabs'>
			<ng-template tabHeading>
				<div class='tab-header'>
					<b>Documento</b>
				</div>
			</ng-template>
			<dry-pdf-viewer [documentAttachment]='documentAttachment'></dry-pdf-viewer>
			<ng-template #message>
				<div class='row'>
					<div class='col-12 text-center'>
						<h5>
							Formato non visualizzabile. E' possibile scaricare direttamente il file tramite le
							azioni a fianco
						</h5>
					</div>
				</div>
			</ng-template>
		</tab>
		<tab class='tabs'>
			<ng-template tabHeading>
				<div class='tab-header'>
					<b>Versioni precedenti</b>
				</div>
			</ng-template>
			<gaw-previous-version [data]='{ docDetails:data, action }'></gaw-previous-version>
		</tab>
	</tabset>
</div>
