import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SearchFilterStoreModule, documentStateReducer, filterStateReducer } from '@ctel/search-filter-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DocumentEffects } from './documents/store/document.effects';
import { extendedDocumentsSearchModuleReducers, extendedRelatedSectionDataModuleReducers } from './documents/store/document.extended';
import { documentsSearchModuleFeatureKey } from './filters/store/feature-key';
import { FilterEffects } from './filters/store/filter.effects';

export const documentsSearchModuleReducers = {
	filterState: filterStateReducer,
	documentState: documentStateReducer,
	extendedDocumentState: extendedDocumentsSearchModuleReducers.extendedDocumentState,
	extendedRelatedSectionDataState: extendedRelatedSectionDataModuleReducers.extendedRelatedSectionDataState
};

@NgModule({
	imports: [
		CommonModule,
		SearchFilterStoreModule.forRoot(null),
		StoreModule.forFeature(documentsSearchModuleFeatureKey, documentsSearchModuleReducers),
		EffectsModule.forFeature([
			FilterEffects,
			DocumentEffects
		])
	]
})
export class DocumentsSearchModule { }

