import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { TrackingStatusHistory } from '@ctel/gaw-commons';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class StatusHistoryHttpService implements OnDestroy {
	private destroy$ = new ReplaySubject<void>(1);

	private gawpassHost!: string;
	private statusHistoryUrl!: string;

	constructor(private http: HttpClient, private configService: ConfigService) {
		this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.gawpassHost = appConfig.gawPassRead.http.host;

		this.statusHistoryUrl = `${this.gawpassHost}/api/v1/Action/ViewTrackingStatusHistory/{IdScatola}/{Sia}/{IdLicenza}`;
	}

	whenStatusHistory(boxId: string, licenseId: string, siaCode: string): Observable<TrackingStatusHistory[]> {
		const url = this.statusHistoryUrl
			.replace('{IdScatola}', boxId)
			.replace('{Sia}', siaCode)
			.replace('{IdLicenza}', licenseId);
		return this.http.get<TrackingStatusHistory[]>(url);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
