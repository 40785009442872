import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { EMPTY, Observable, ReplaySubject, of } from 'rxjs';
import { catchError, delay, map, share, switchMap, take, tap } from 'rxjs/operators';
import { Attachment } from '../../../../../entities/ecm/ecm';
import { WorkitemActions } from '../../shared/action';
import { AttachmentItem } from '../../shared/attachment-item';
import { UserAttachmentService } from '../user-attachment.service';

@Component({
	selector: 'gaw-wf-user-attachment-preview',
	templateUrl: './user-attachment-preview.component.html',
	styleUrls: ['./user-attachment-preview.component.scss']
})
export class UserAttachmentPreviewComponent implements OnInit {
	@Input() attachmentId: string;
	@Output() goToList = new EventEmitter<void>();

	public faIcons = FaIcons;

	/** Allegato corrente */
	public attachment$: Observable<AttachmentItem>;
	actions: WorkitemActions = [
		{
			icon: `${this.faIcons.FAR_TRASH}`,
			label: '', disable: () => of(false),
			execute: (attachment) => this.deleteAttachment(attachment)
		},
	];

	/** Mi indica se nella lista allegati ho solo un elemento e se questo elemento ha a disposizione la preview */
	hasBackButton$: Observable<boolean>;

	private readonly outletName = 'workitem-detail';

	constructor(
		private readonly userAttachmentService: UserAttachmentService,
		private readonly notificationService: NotificationService
	) { }

	ngOnInit() {
		// Ottengo il contenuto dell'allegato
		const attachmentContent$ = (attachment: Attachment) => of(attachment).pipe(
			switchMap(att => this.userAttachmentService.downloadAttachment(att)),
			share({ connector: () => new ReplaySubject(1) })
		);

		// Ottengo l'allegato tramite id
		this.attachment$ = this.userAttachmentService.getAttachmentById(this.attachmentId).pipe(
			map(attachment => <AttachmentItem>{
				id: attachment.attachmentId,
				mimeType: attachment.values.mimeType,
				filename: attachment.values.fileName,
				content: attachmentContent$(attachment)
			})
		);

		this.hasBackButton$ = this.userAttachmentService.attachmentList$.pipe(
			map(list => list.length > 1)
		);
	}

	private deleteAttachment(attachment: AttachmentItem) {
		const deleteCallback$ = att => this.userAttachmentService.deleteAttachment(att.attachmentId).pipe(
			take(1),
			tap(() => this.userAttachmentService.refreshItems()),
			delay(500),
			tap(() => this.goToList.emit()),
			catchError(() => {
				this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante la cancellazione dell\'allegato', '');
				return EMPTY;
			}),
		);

		return this.userAttachmentService.getAttachmentById(attachment.id).pipe(
			take(1),
			map(deleteCallback$),
			tap(callback$ => {
				this.notificationService.showSweetAlert(NotificationType.QUESTION,
					'Sicuro di volere eliminare l\'allegato?',
					'',
					() => callback$.pipe(take(1)).subscribe());
			})
		);
	}
}
