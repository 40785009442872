export enum ExcelExtension {
	XLSX = '.xlsx',
	XLS = '.xls',
	CSV = '.csv',
	PDF = '.pdf',
	ZIP = '.zip',
	RTF = '.rtf'
}

export const ExcelExtensions: ExcelExtension[] = [
	ExcelExtension.XLSX,
	ExcelExtension.XLS,
	ExcelExtension.CSV,
	// ExcelExtension.ZIP,
	// ExcelExtension.PDF,
	// ExcelExtension.RTF
];
