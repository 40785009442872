import { AppErrorBuilder } from 'app/core/common/error/app-error-builder';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { EMPTY, Observable, of } from 'rxjs';

export class Action {
	public label: string;
	public icon: string;

	constructor(label: string, icon: string) {
		if (label === null || icon === null)
			throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
				.description('Parametri iniziali dell\'azione non validi')
				.info('label', label)
				.info('icon', icon)
				.build();

		this.label = label;
		this.icon = icon;
	}

	/**
	 * Setta la callback per l'esecuzione dello stato
	 * @param func
	 */
	setExecute(func: () => Observable<unknown>): Action {
		if (typeof func === 'function')
			this._execute = func;

		return this;
	}

	/**
	 * Setto la callback per determinare quando deve essere nascosta l'azione
	 * @param func
	 */
	setHidden(func: () => Observable<boolean>): Action {
		if (typeof func === 'function')
			this._hidden = func;

		return this;
	}

	/**
	 * Setto la callback per determinare quando deve essere disabilitata l'azione
	 * @param func
	 */
	setDisabled(func: () => Observable<boolean>): Action {
		if (typeof func === 'function')
			this._disabled = func;

		return this;
	}

	execute(): Observable<unknown> {
		return this._execute();
	}

	hidden(): Observable<boolean> {
		return this._hidden();
	}

	disabled(): Observable<boolean> {
		return this._disabled();
	}

	private _execute: () => Observable<unknown> = () => EMPTY;
	private _hidden: () => Observable<boolean> = () => of(false);
	private _disabled: () => Observable<boolean> = () => of(false);
}
