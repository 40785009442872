import { HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { FullScreenSpinnerConfig } from 'app/entities/config/common/full-screen-spinner-config';
import { DryConfig } from 'app/entities/config/dry-config';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { ConfigService } from '../../config/config.service';

@Injectable()
export class FullScreenSpinnerService implements OnDestroy {
	private destroy$ = new Subject<void>();

	private fullScreenSpinnerConfig = new ReplaySubject<FullScreenSpinnerConfig>(1);

	private loadingText$ = new BehaviorSubject<string>('');
	private loadingRequest$ = new BehaviorSubject<number>(0);

	constructor(private ngxSpinnerService: NgxSpinnerService, private configService: ConfigService) {
		this.whenConfig()
			.pipe(take(1))
			.subscribe((config) => this.spinnerInit(config));
	}

	loadingRequest() {
		this.sendLoadingRequest(this.loadingRequest$.getValue() + 1);
	}

	loadedRequest() {
		const actualValue = this.loadingRequest$.getValue();
		if (actualValue > 0)
			this.sendLoadingRequest(actualValue - 1);

	}

	resetLoadingRequests() {
		this.sendLoadingRequest(0);
	}

	whenLoadingRequest(): Observable<number> {
		return this.loadingRequest$.asObservable();
	}

	whenConfig(): Observable<FullScreenSpinnerConfig> {
		return this.fullScreenSpinnerConfig.asObservable();
	}

	public show() {
		this.ngxSpinnerService.show();
	}

	public hide() {
		this.ngxSpinnerService.hide();
	}

	whenLoadingText(): Observable<string> {
		return this.loadingText$.asObservable();
	}

	sendLoadingText(value: string) {
		this.loadingText$.next(value);
	}

	sendFullScreenSpinnerConfig(config: FullScreenSpinnerConfig) {
		this.fullScreenSpinnerConfig.next(config);
	}

	public initConfig() {
		this.configService
			.whenDryConfig()
			.pipe(
				map((config: DryConfig) => config.fullScreenSpinner),
				takeUntil(this.destroy$)
			)
			.subscribe((config) => this.sendFullScreenSpinnerConfig(config));
	}

	inhibitSpinnerHeaders(): Observable<HttpHeaders> {
		return this.whenConfig().pipe(
			map((config) => config.httpHeaderKey),
			map((headerKey) => new HttpHeaders().set(headerKey, 'true'))
		);
	}

	mergeSpinnerHeaders(options: object, spinnerHeaders: HttpHeaders): object {
		const onlyKey = spinnerHeaders.keys().length > 0 && spinnerHeaders.keys()[0];
		if (!onlyKey)
			return options;

		if (!options)
			return {
				headers: spinnerHeaders,
			};

		if (!options['headers'])
			return {
				...options,
				headers: spinnerHeaders,
			};

		return {
			...options,
			headers: (options['headers'] as HttpHeaders).set(onlyKey, spinnerHeaders.get(onlyKey)),
		};
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private sendLoadingRequest(value: number) {
		this.loadingRequest$.next(value);
	}

	private spinnerInit(config: FullScreenSpinnerConfig) {
		// Se ci sono delle richieste che stanno caricando
		const areThereLoadingRequest$ = this.whenLoadingRequest().pipe(
			map((loadingRequests) => loadingRequests > 0),
			distinctUntilChanged()
		);

		// Se passo dallo stato 'non ci sono richieste' => 'ci sono richieste' visualizzo istantaneamente lo spinner
		// areThereLoadingRequest$
		// 	.pipe(
		// 		filter(areThereLoadingRequests => areThereLoadingRequests === true)
		// 	)
		// 	.subscribe(() => this.show());

		// Se passo dallo stato 'ci sono richieste' => 'non ci sono richieste' aspetto a nascondere lo spinner, per evitare sfarfallii
		areThereLoadingRequest$
			.pipe(
				debounceTime(200),
				filter((areThereLoadingRequests) => areThereLoadingRequests === false)
			)
			.subscribe(() => this.hide());

		// Per ora dopo un certo tempo nascondo lo spinner perche' ci sono richieste http che non vengono terminate correttamente
		// e non vengono gestite (richieste cancellate dal browser) e non abbiamo una gestione completa degli errori HTTP
		areThereLoadingRequest$
			.pipe(debounceTime(config.maxLoadingMillis), takeUntil(this.destroy$))
			.subscribe((areThereLoadingRequests) => {
				if (areThereLoadingRequests)
					this.resetLoadingRequests();

			});
	}
}
