import { Component } from '@angular/core';

@Component({
	selector: 'hub-filter-type-placeholder',
	templateUrl: './filter-type-placeholder.component.html',
	styleUrls: ['./filter-type-placeholder.component.scss']
})
export class FilterTypePlaceholderComponent {

}
