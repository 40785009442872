import { Component, Optional, Self } from '@angular/core';
import { FormControlName, NgControl } from '@angular/forms';
import { CustomFormControlComponent } from '../../custom-form-control.component';

@Component({
	selector: 'app-text-form-control',
	templateUrl: './text-form-control.component.html',
	styleUrls: ['./text-form-control.component.scss']
})
export class TextFormControlComponent extends CustomFormControlComponent<string> {

	constructor(
		@Optional() @Self() public ngControl: NgControl,
		@Optional() public controlName: FormControlName
	) {
		super(ngControl, controlName);
	}
}
