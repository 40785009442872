import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { CessionarioCommittenteKeyValue } from 'app/core/common/actions/send-analogic-copy/cessionario-committente-key-value';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable } from 'rxjs';

@Injectable()
export class SendAnalogicCopyHttpService {

	private host: string;
	private cessionarioCommittente: string;

	constructor(
		private http: HttpClient,
		private configService: ConfigService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.host = appConfig.gedInvoice.http.host;

		this.cessionarioCommittente = `${this.host}/v1/GedInvoice/CessionarioCommittenteToGed`;
	}

	getCessionarioCommittente(progSpool: number, progBusta: number, hash: string): Observable<CessionarioCommittenteKeyValue[]> {

		const url = this.cessionarioCommittente;
		const params: HttpParams = new HttpParams()
			.set('progSpool', progSpool.toString())
			.set('progBusta', progBusta.toString())
			.set('hash', hash);

		return this.http.get<CessionarioCommittenteKeyValue[]>(url, {
			params
		});
	}
}
