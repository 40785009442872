<form [formGroup]="parentForm">
  <ng-container formArrayName="{{ field.formArrayName }}">
    <ng-container formGroupName="{{ i }}" *ngFor="let singleControl of formArray?.controls; let i = index">
      <div class="w-100 invoice-modal-subtitle">
        <p>{{ field.sectionName }}</p>
      </div>
      <div class="row">
        <div class="col-10 row px-0">
          <ng-container *ngFor="let input of field.fields">
            <div class="col-6 row px-0 align-items-center mb-2">
              <div class="col-6 align-items-center mb-2">
                <label [attr.for]="input.id + field.modalButtonId">{{ input.label }}</label>
              </div>
              <div class="col-6 align-items-center mb-2">
                <ng-container *ngIf="input.fieldType.selectList && input.fieldType.selectList.length > 0">
                  <app-select-form-control
                    id="{{ input.id + field.modalButtonId }}"
                    name="{{ input.id + field.modalButtonId }}"
                    navigable
                    formControlName="{{ input.formControlname }}"
                    [values]="input.fieldType.selectList"
                    [tooltip]="true"
                    objectId="value"
                    objectValue="label"
                  >
                  </app-select-form-control>
                </ng-container>
                <ng-container *ngIf="input.fieldType.text">
                  <app-text-form-control
                    id="{{ input.id + field.modalButtonId }}"
                    placeholder="{{ input.label }}"
                    name="{{ input.id + field.modalButtonId }}"
                    navigable
                    formControlName="{{ input.formControlname }}"
                  >
                  </app-text-form-control>
                </ng-container>
                <ng-container *ngIf="input.fieldType.number">
                  <app-number-form-control
                    id="{{ input.id + field.modalButtonId }}"
                    placeholder="{{ input.label }}"
                    name="{{ input.id + field.modalButtonId }}"
                    navigable
                    formControlName="{{ input.formControlname }}"
                    sanitize
                    [decimal]="input.fieldType.number.decimals"
                  >
                  </app-number-form-control>
                </ng-container>
                <ng-container *ngIf="input.fieldType.date">
                  <app-date-form-control
                    id="{{ input.id + field.modalButtonId }}"
                    placeholder="{{ input.label }}"
                    name="{{ input.id + field.modalButtonId }}"
                    navigable
                    formControlName="{{ input.formControlname }}"
                  >
                  </app-date-form-control>
                </ng-container>
                <ng-container *ngIf="input.fieldType.printValue">
                  {{ findLabelByValue(singleControl.get(input.formControlname)?.value, input.fieldType.selectList) }}
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-2 d-flex justify-content-center gap-2">
          <button class="btn-ghost p-0" aria-label="Aggiungi elemento" (click)="add()">
            <i class="invoice-modal-btn-icon" [ngClass]="faIcons.FAS_PLUS" aria-hidden="true"></i>
          </button>
          <button class="btn-ghost p-0" [attr.aria-label]="'Rimuovi elemento ' + i" (click)="deleteElement(i)">
            <i class="invoice-modal-btn-icon" [ngClass]="faIcons.FAS_MINUS" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</form>
