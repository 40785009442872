import { Component } from '@angular/core';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';

@Component({
	selector: 'dry-custom-placeholder',
	templateUrl: './simple-placeholder.component.html',
	styleUrls: ['./simple-placeholder.component.scss']
})
export class SimplePlaceholderComponent {

	public faIcons = FaIcons;

}
