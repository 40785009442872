import { Component, Input, OnInit } from '@angular/core';
import { AppError } from '../app-error';

@Component({
	selector: 'dry-modal-error',
	templateUrl: './error-display.component.html',
	styleUrls: ['./error-display.component.scss']
})
export class ErrorDisplayComponent implements OnInit {

	@Input() public data: AppError;

	public currentErrorDetail: AppError;
	public parentErrors: AppError[] = [];
	public showEncapsulatedError = false;

	ngOnInit() {
		this.appErrorInit();
	}

	appErrorInit() {
		this.currentErrorDetail = this.data;
		this.parentErrors = this.data.getParentErrors();
	}

	public setCurrentErrorDetail(error: AppError) {
		this.currentErrorDetail = error;
	}

	public isCurrentError(error: AppError) {
		return this.currentErrorDetail.incidentReportId === error.incidentReportId;
	}

}
