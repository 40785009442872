import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AdvancedTextSearchService, Filter, HomeFilter, HomeFilterRangeType, HomeFilterUrl } from '@ctel/gaw-commons';
import { FilterActions, IFilterUrlValue, getFilters, getRouterInfo } from '@ctel/search-filter-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { FilterBuilder } from 'app/constants/filters/filter-builder';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { AccountType } from 'app/core/common/account/accountType';
import { ErrorTypes } from 'app/core/common/error';
import { Copier } from 'app/core/common/utilities/copier';
import { SectionCode } from 'app/entities/ui-config/classification-code.enum';
import * as _ from 'lodash';
import * as Rison from 'rison-node';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getCurrentAccount } from '../../../account/store/account.selectors';
import { PayableAccountCardsService } from '../../../cards/payable-account-cards.service';
import { ReceivableAccountCardsService } from '../../../cards/receivable-account-cards.service';
import { SpoolCardsService } from '../../../cards/spool-cards.service';
import { DateDropdownsReceivableService } from '../../../date-dropdowns/date-dropdowns-receivable.service';
import { ReducedDocumentResponse } from '../../../entities/documents/documents-response';
import { UiConfigurationService } from '../../../ui-configuration/ui-configuration.service';
import { DocumentsHttpService } from '../../documents-http.service';
import { DocumentsService } from '../../documents/documents.service';
import {
	HOME_URI_STATE_TOKENS,
	getCachedHomeFilters,
	getHomeFiltersStateWithUserValues,
	getHomeFiltersUrl,
	selectHomeFilterState,
} from '../../filters/store/home-filter.selectors';
import { FilterService } from '../filter.service';
import {
	changeHomeDateFilterRequested,
	homeFiltersError,
	homeMetricsFetched,
	homeMetricsRequested,
	outsideLanding,
	resetHomeDateFilterPayload,
	switchHomeDateFilterAccount,
} from './home-filter.actions';
import { dataProtocollazioneCtelFilterConfigHome, timestampCtelRicezioneSdiFilterConfigHome } from './home-filter.reducer';
import { of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class HomeFilterEffects {

	navigationChangedOnQueryParamsChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ROUTER_NAVIGATION),
			withLatestFrom(
				this.store.pipe(select(getRouterInfo)),
				this.store.pipe(select(getCurrentAccount)),
				this.store.pipe(select(selectHomeFilterState)),
				this.store.pipe(select(getHomeFiltersUrl))
			),
			filter(
				([, routerInfo, , homeFilterState, ]) =>
					routerInfo &&
					routerInfo.state &&
					routerInfo.state.url.startsWith('/gawfe/home') &&
					!!homeFilterState.licenseId &&
					!!homeFilterState.siaCode
			),
			map(([, routerInfo, currentAccount, , homeFiltersUrl]) => ({
				queryParams: routerInfo.state.queryParams,
				currentAccount,
				homeFiltersUrl,
			})),
			distinctUntilChanged((x, y) => _.isEqual(x.queryParams, y.queryParams)),
			map((value) => {
				this.updateDefaultHomeFilters(value.homeFiltersUrl, value.currentAccount);

				return homeMetricsRequested();
			})
		)
	);

	onChangeHomeDateFilterRequested0$ = createEffect(() =>
		this.actions$.pipe(
			ofType(changeHomeDateFilterRequested),
			withLatestFrom(this.store.pipe(select(getFilters)), this.store.pipe(select(getCurrentAccount))),
			map(([, currentFilters, currentAccount]) => {
				const account = currentAccount === AccountType.PAYABLE ? SectionCode.PAYABLE : SectionCode.RECEIVABLE;
				const patchedFilters: Filter[] = this.documentsService.patchFiltersPayload(account, currentFilters);

				return FilterActions.patchFiltersRequested({ filters: patchedFilters });
			})
		)
	);

	onChangeHomeDateFilterRequested1$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(changeHomeDateFilterRequested),
				filter(({ payload }) => payload.section === SectionCode.RECEIVABLE),
				tap(({ payload }) => {
					// TODO: rifattorizza con ngrx.
					// Se sto aggiungendo un filtro data fattura, disattivo il conteggio degli spool.
					if (payload.homeFilters.find(v => v.metadata === MetadataEnum.DATA_FATTURA) /* && valueFrom !== ''*/)
						this.dateDropdownsReceivableService.sendIsInvoiceDateSelected(true);

					// Se c'è almeno una data inserimento, invio le date per il conteggio degli spool.
					const filterInsertion = payload.homeFilters.find(v => v.metadata === MetadataEnum.DATA_INSERIMENTO);
					if (filterInsertion) this.spoolCardsService.sendCurrentDates([filterInsertion.value.from, filterInsertion.value.to]);
				})
			),
		{ dispatch: false }
	);

	/**
	 * Effetto che gestisce il side effect di aver richiesto una modifica dei filtri,
	 * e per i quali è necessario fare routing usando i query params (che implicitamente
	 * vengono salvati nello stato del router).
	 */
	onChangeHomeDateFilterRequested2$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(changeHomeDateFilterRequested),
				filter(({ payload }) => payload.doNavigate),
				tap(({ payload }) => {
					const filtersQuery = HomeFilterEffects.updateFilterQueryParam(payload.homeFilters);
					const extras = HomeFilterEffects.getFiltersNavigationExtras(filtersQuery);
					extras.relativeTo = this.activatedRoute;
					this.router.navigate([], extras);
				})
			),
		{ dispatch: false }
	);

	onSwitchHomeDateFilter$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(switchHomeDateFilterAccount),
				withLatestFrom(this.store.pipe(select(getCurrentAccount)), this.store.pipe(select(getCachedHomeFilters))),
				filter(([{ payload },]) => payload.doNavigate),
				tap(([{ payload }, currentAccount, cachedFilters]) => {
					const filters = currentAccount === AccountType.PAYABLE ? cachedFilters.payable : cachedFilters.receivable;
					const filtersQuery = HomeFilterEffects.resetFilterQueryParam(filters);
					const extras = HomeFilterEffects.getFiltersNavigationExtras(filtersQuery);

					// Se l'action reca un url command, navigo verso di esso.
					// Diversamente considero la navigazione relativa alla rotta corrente (home).
					if (payload.urlCommands.length === 0)
						// Navigazione relativa alla home.
						extras.relativeTo = this.activatedRoute;
					else if (payload.urlCommands.indexOf('/home') !== -1 || payload.urlCommands.indexOf('/gawfe/documents/') !== -1) {
						// Navigazione eplicita verso home: elimino i filter params.
						// Navigazione esplicita verso una sezione: resetto i filter params.
						let filtersExtras: NavigationExtras = null;
						if (payload.urlCommands.indexOf('/home') !== -1) {
							filtersExtras = this.filterService.getFiltersNavigationExtras([], [], null, [], null, []);
							Object.keys(filtersExtras.queryParams).forEach((value) => {
								filtersExtras.queryParams[value] = null;
							});
						} else filtersExtras = this.filterService.getSectionFiltersNavigationExtras();

						extras.queryParams = {
							...extras.queryParams,
							...filtersExtras.queryParams,
						};
					}
					this.router.navigate(payload.urlCommands, extras);
				})
			),
		{ dispatch: false }
	);

	onResetHomeDateFilter$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(resetHomeDateFilterPayload),
				withLatestFrom(
					this.store.pipe(select(getRouterInfo)),
					this.store.pipe(select(getCurrentAccount)),
					this.store.pipe(select(getHomeFiltersUrl))
				),
				tap(([{ payload }, routerInfo, currentAccount, filtersUrl]) => {
					let homeFilters: HomeFilter[] = [];
					const defaultFilters =
						currentAccount === AccountType.PAYABLE
							? [timestampCtelRicezioneSdiFilterConfigHome]
							: [dataProtocollazioneCtelFilterConfigHome];

					if (payload.firstLoad && filtersUrl.length > 0)
						// Imposto i cached
						this.updateDefaultHomeFilters(filtersUrl, currentAccount);
					// Forzo fetch (se url uguale)
					else {
						// Casistica di cambio azienda o URL vuoto: resetto indipendentemente dall'URL.
						homeFilters = defaultFilters;
						filtersUrl = HomeFilterEffects.resetFilterQueryParam(homeFilters);
					}

					const extras = HomeFilterEffects.getFiltersNavigationExtras(filtersUrl);

					if (payload.firstLoad && filtersUrl.length > 0) extras.onSameUrlNavigation = 'reload';

					extras.relativeTo = this.activatedRoute;
					if (routerInfo && routerInfo.state && routerInfo.state.url.startsWith('/gawfe/home'))
						this.router.navigate([], { ...extras });

				})
			),
		{ dispatch: false }
	);

	outsideLanding$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(outsideLanding),
				withLatestFrom(
					this.store.pipe(select(getRouterInfo)),
					this.store.pipe(select(getCurrentAccount)),
					this.store.pipe(select(getHomeFiltersUrl)),
					this.store.pipe(select(getCachedHomeFilters))
				),
				tap(([, , currentAccount, filtersUrl, cachedHomeFilters]) => {
					const cachedFilters = currentAccount === AccountType.PAYABLE ? cachedHomeFilters.payable : cachedHomeFilters.receivable;

					if (filtersUrl.length > 0)
						// Imposto i cached
						this.updateDefaultHomeFilters(filtersUrl, currentAccount);
					// Forzo fetch (se url uguale)
					else filtersUrl = HomeFilterEffects.resetFilterQueryParam(cachedFilters);

					const extras = HomeFilterEffects.getFiltersNavigationExtras(filtersUrl);
					if (filtersUrl.length > 0) extras.onSameUrlNavigation = 'reload';
					extras.relativeTo = this.activatedRoute;
					this.router.navigate([], extras);
				})
			),
		{ dispatch: false }
	);

	onHomeFiltersFetched$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(homeMetricsFetched),
				tap(({ payload }) => {
					// TODO: rifattorizza mettendo questi valori in stato applicativo.
					// Aggiorniamo le metriche in /home.
					const reducedResponse: ReducedDocumentResponse = {
						metrics: Copier.deepCopy(payload.metrics),
						totalDocs: Copier.deepCopy(payload.totalDocs),
					};
					// salvo docIndex valori della cards (solo da /home).
					this.receivableAccountCardsService.sendSmallCardsValues(reducedResponse);
					this.receivableAccountCardsService.sendLargeCardsValues(reducedResponse);
					this.payableAccountCardsService.sendLargeCardsValues(reducedResponse);
				})
			),
		{ dispatch: false }
	);

	onMetricsRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(homeMetricsRequested),
			withLatestFrom(this.store.pipe(select(getHomeFiltersStateWithUserValues))),
			switchMap(([, homeFilterState]) => {
				this.documentsService.setLoadingDocs(true);
				return this.documentsHttpService.whenAllDocuments(JSON.stringify(homeFilterState)).pipe(
					tap(() => {
						this.documentsService.setErrorLoadingDocs(false);
						this.documentsService.setLoadingDocs(false);
						this.documentsService.setLoadingDocsOnSectionChange(false);
					}),
					map((documentResponse) => homeMetricsFetched(documentResponse.metrics, documentResponse.totalDocs)),
					catchError((err: unknown) => {
						if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
							//this.router.navigate(['/unauthorized']).then();
						} else {
							this.documentsService.setLoadingDocs(false);
							this.documentsService.setLoadingDocsOnSectionChange(false);
							this.documentsService.setErrorLoadingDocs(true);
						}
						return this.handleError(err);
					})
				);
			})
		)
	);

	onFavouritesFiltersRequested1$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(FilterActions.favouritesFiltersRequested),
				withLatestFrom(
					this.store.pipe(select(getRouterInfo)),
					this.store.pipe(select(getHomeFiltersUrl)),
					this.store.pipe(select(selectHomeFilterState))
				),
				filter(([, , , homeFilterState]) => !homeFilterState.licenseId || !homeFilterState.siaCode),
				tap(([, routerInfo, homeFiltersUrl, ]) => {
					// TODO: ci si dovrebbe basare sul selettore getAccountType.
					const accountType =
						routerInfo.state.params['accounttype'] === SectionCode.RECEIVABLE ? AccountType.RECEIVABLE : AccountType.PAYABLE;
					this.updateDefaultHomeFilters(homeFiltersUrl, accountType);
				})
			),
		{ dispatch: false }
	);

	onHomeAdvancedTextSearchMetadataRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FilterActions.advancedTextSearchMetadataRequested),
			withLatestFrom(
				this.store.pipe(select(getRouterInfo)),
				this.store.pipe(select(getCurrentAccount)),
				this.store.pipe(select(selectHomeFilterState))
			),
			filter(([, routerInfo]) => routerInfo.state.url.indexOf('gawfe/home') !== -1),
			switchMap(([, , currentAccount, homeFilterState]) =>
				this.uiConfigurationService
					.getRelatedData(
						homeFilterState.licenseId,
						homeFilterState.siaCode,
						currentAccount === AccountType.PAYABLE ? SectionCode.PAYABLE : SectionCode.RECEIVABLE
					)
					.pipe(
						switchMap((relatedSectionData) =>
							this.documentsService.whenMetadataDescriptions(relatedSectionData).pipe(
								map((docSeriesMetadataDesc) => {
									const sorted = this.advancedTextSearchService.sortTextSearchMetadata(
										docSeriesMetadataDesc,
										relatedSectionData
									);
									return FilterActions.advancedTextSearchMetadataFetched({ advancedTextSearch: sorted });
								}),
								catchError((error: unknown) => this.handleError(error))
							)
						),
						catchError((error: unknown) => this.handleError(error))
					)
			)
		)
	);
	constructor(
		protected actions$: Actions,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected store: Store<any>,
		protected documentsHttpService: DocumentsHttpService,
		protected documentsService: DocumentsService,
		protected payableAccountCardsService: PayableAccountCardsService,
		protected receivableAccountCardsService: ReceivableAccountCardsService,
		protected dateDropdownsReceivableService: DateDropdownsReceivableService,
		protected filterService: FilterService,
		protected spoolCardsService: SpoolCardsService,
		protected uiConfigurationService: UiConfigurationService,
		protected advancedTextSearchService: AdvancedTextSearchService
	) {}
	/**
	 * Resetta i filtri in URL con quelli selezionati dall'utente.
	 */
	static resetFilterQueryParam(chachedFilters: HomeFilter[]): HomeFilterUrl[] {
		return this.updateFilterQueryParam(chachedFilters);
	}

	static getFiltersNavigationExtras(filtersQuery: HomeFilterUrl[]): NavigationExtras {
		return {
			queryParams: {
				[HOME_URI_STATE_TOKENS.HOME_FILTERS]: Rison.encode_uri(filtersQuery)
			},
			queryParamsHandling: 'merge'
		} as NavigationExtras;
	}

	/**
	 * Aggiorna i filtri in URL con quelli selezionati dall'utente.
	 */
	private static updateFilterQueryParam(homeFilters: HomeFilter[]): HomeFilterUrl[] {
		const newFiltersUrl: HomeFilterUrl[] = [];
		homeFilters.forEach((homeFilter: HomeFilter) => {
			const newFilter = <HomeFilterUrl>{
				m: homeFilter.metadata,
				t: homeFilter.dateRangeType,
			};
			if (homeFilter.dateRangeType === HomeFilterRangeType.CUSTOM)
				newFilter.v = <IFilterUrlValue>{
					f: homeFilter.value.from,
					to: homeFilter.value.to,
				};

			newFiltersUrl.push(newFilter);
		});

		return newFiltersUrl;
	}

	private handleError(error: any) {
		return of(homeFiltersError(error));
	}

	private updateDefaultHomeFilters(filtersUrl: HomeFilterUrl[], currentAccount: AccountType) {
		// Mappo l'url in un HomeFilter[] e lo imposto come default.
		const reduced = filtersUrl.reduce<HomeFilter[]>((acc, value) => {
			if (
				!value.m ||
				!value.t ||
				(value.t === HomeFilterRangeType.CUSTOM && (!value.v || !value.v.f || !value.v.to)) ||
				(value.t !== HomeFilterRangeType.CUSTOM && value.v)
			)
				// Solo filtri data from/to con un dato range type sono accettati.
				return acc;

			acc.push(FilterBuilder.generalHomeDateFilter(value.m, value.t, value.v && value.v.f, value.v && value.v.to) as HomeFilter);
			return acc;
		}, []);

		if (reduced.length > 0) {
			const section = currentAccount === AccountType.PAYABLE ? SectionCode.PAYABLE : SectionCode.RECEIVABLE;
			this.store.dispatch(changeHomeDateFilterRequested(reduced, section, false));
		}
	}
}
