import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerHttpService } from './logger-http.service';

@Injectable()
export class LoggerHttpInterceptor implements HttpInterceptor {
	private interceptorName = 'user-logger-interceptor';

	constructor(private injector: Injector) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (request.headers.get('log')) {
			const loggerHttpService = this.injector.get(LoggerHttpService);
			loggerHttpService.log(request);
			request = request.clone({ headers: request.headers.delete('log') });
		}

		return next.handle(request);
	}
}
