import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { DryLoadingPlaceholderModule } from 'app/core/common/placeholder/loading-placeholder/dry-loading-placeholder.module';
import { ErrorPlaceholderModule } from './error-placeholder/error-placeholder.module';
import { LargePlaceholderComponent } from './large-placeholder/large-placeholder.component';
import { PlaceholderService } from './placeholder.service';
/* REMEMBER ME LIBRARY ??? */
import { PlaceholderDirective } from './directives/placeholder.directive';

@NgModule({
	imports: [
		CommonModule,
		ErrorPlaceholderModule,
		DryLoadingPlaceholderModule
	],
	declarations: [
		LargePlaceholderComponent,
		PlaceholderDirective
	]
})
export class PlaceholderModule {

	static forRoot(): ModuleWithProviders<PlaceholderModule> {
		return {
			ngModule: PlaceholderModule,
			providers: [
				PlaceholderService
			]
		};
	}
}
