import { Pipe, PipeTransform } from '@angular/core';
import { Extension, File } from 'app/core/common/utilities/file/file';

@Pipe({
	name: 'attachmentIcon'
})
export class AttachmentIconPipe implements PipeTransform {

	private iconsListPath = 'assets/icon/file/';

	transform(fileName: string): string {
		const extension = File.getExtension(fileName);
		return this.iconsListPath + this.getIconPath(extension);
	}

	private getIconPath(extension: Extension): string {
		switch (extension) {
			case Extension.PDF:
				return 'pdf_icon.png';
			case Extension.ODS:
			case Extension.OTS:
			case Extension.XLS:
			case Extension.XLSX:
				return 'excel_icon.png';
			case Extension.ODM:
			case Extension.ODT:
			case Extension.OTT:
			case Extension.DOC:
			case Extension.DOCX:
				return 'word_icon.png';
			case Extension.TXT:
				return 'doc_icon.png';
			case Extension.ZIP:
				return 'zip_icon.png';
			default:
				return 'file_icon.png';
		}
	}
}
