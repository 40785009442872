import { Injectable } from '@angular/core';
import { Column } from 'app/constants/column-configuration/ui-configuration-columns';
import { RelatedSectionData } from 'app/entities/sections/related-section-data';
import { DocumentsService2 } from 'app/modules/homepage/core/documents-search/documents/documents.service';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UiConfigurationHttpService } from './ui-configuration-http.service';

@Injectable({
	providedIn: 'root'
})
export class UiConfigurationService {

	constructor(
		public uiConfigurationHttpService: UiConfigurationHttpService,
		public documentsService: DocumentsService2,
		private logger: NGXLogger
	) { }

	public getRelatedData(licenseId: string, siaCode: string, sectionId: string): Observable<RelatedSectionData[]> {
		return this.uiConfigurationHttpService.getRelatedSearchData(licenseId, siaCode, sectionId);
	}

	public getSectionColumns(licenseId: string, siaCode: string, section: string): Observable<{
		primaryConfig: Column[],
		secondaryConfig: Column[]
	}> {
		return this.uiConfigurationHttpService.getSectionColumns(licenseId, siaCode, section)
			.pipe(
				take(1),
				map(response => response.configurationsGroup.columns),
				map(columns => {
					this.documentsService.setErrorLoadingDocs(false);
					let metadataServicesColumns = [...columns];
					// taglio l'array in modo da tenere solo i primi 3 metadati
					if (columns.length >= 3)
						metadataServicesColumns = columns.slice(0, 3);

					return {
						primaryConfig: columns,
						secondaryConfig: metadataServicesColumns
					};
				})
			);
	}
}
