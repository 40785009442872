import { Injectable } from '@angular/core';
import { FilterPayload, Metric } from '@ctel/gaw-commons';
import { Store } from '@ngrx/store';
import {
	accountFilter,
	amountFilterConfig,
	dataProtocollazioneCtelFilterConfig,
	docTypeFilterConfig,
	primaryServiceStatusConfig,
	status35FilterConfig,
} from 'app/constants/filters/filters-config';
import { receivableMetricsConfig } from 'app/constants/metrics/metrics';
import { metadataListConfig, uiConfigReceivable } from 'app/constants/ui-config/ui-config';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { AccountType } from 'app/core/common/account/accountType';
import { Copier } from 'app/core/common/utilities/copier';
import { Company } from 'app/entities/companies/company';
import { SectionCode } from 'app/entities/ui-config/classification-code.enum';
import { ServiceConfig } from 'app/entities/ui-config/ui-config';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AccountService } from '../account/account.service';
import { FilterService } from '../documents/filters/filter.service';
import { resetHomeDateFilterPayload } from '../documents/filters/store/home-filter.actions';
import { ClientServiceConfigurationResponse, Service } from '../entities/cards/client-configuration';
import { CardsHttpService } from './cards-http.service';

/**
 * Servizio che gestisce lo stato delle cards piccole e grandi del ciclo attivo
 */
@Injectable({
	providedIn: 'root'
})
export class ReceivableAccountCardsService {

	private services$ = new ReplaySubject<ClientServiceConfigurationResponse>(1);
	private smallCardsValues$ = new ReplaySubject<any>(1);
	private largeCardsValues$ = new ReplaySubject<any>(1);
	private servicesTiles$ = new BehaviorSubject<ServiceConfig[]>([]);
	private errorLoadingServices$ = new BehaviorSubject<boolean>(false);
	private firstLoad = true;

	constructor(
		private cardsHttpService: CardsHttpService,
		private companiesService: CompaniesService,
		private accountService: AccountService,
		private filterService: FilterService,
		private store: Store<any>
	) {
		this.initializeServices();
	}

	initializeServices() {
		this.companiesService.whenCurrentCompany()
			.pipe(
				distinctUntilChanged((x, y) => x.siaCode === y.siaCode),
				catchError(() => EMPTY),
				switchMap(currentCompany =>
					combineLatest([
						of(currentCompany),
						this.cardsHttpService.whenServices(currentCompany.licenseId, currentCompany.siaCode, 'ATTIVO')
					])
				))
			.subscribe({
				next: ([currentCompany, services]) => {
					this.setErrorLoadingServices(false);
					// canali configurati sul ciclo attivo per quell'azienda
					this.sendServices(services);
					// se sono sulla home
					if (this.accountService.getCurrentAccount() === AccountType.RECEIVABLE) {
						/*
							if (window.location.href.indexOf('/home') !== -1
								|| (window.location.href.indexOf('/documents/') !== -1 && window.location.href.indexOf('/details') !== -1)) {
								const payload = this.buildPayload(currentCompany);
								this.store.dispatch(resetHomeDateFilterPayload(payload, 'receivable', this.firstLoad));
								this.firstLoad = false;
							}
							*/
						const payload = this.buildPayload(currentCompany);
						this.store.dispatch(resetHomeDateFilterPayload(payload, 'receivable', this.firstLoad));
						this.firstLoad = false;
					}
				},
				error: catchError(() => {
					this.setErrorLoadingServices(true);
					return EMPTY;
				})
			});
	}

	whenServices(): Observable<ClientServiceConfigurationResponse> {
		return this.services$.asObservable();
	}

	public whenErrorLoadingServices(): Observable<boolean> {
		return this.errorLoadingServices$.asObservable();
	}

	public setErrorLoadingServices(value: boolean) {
		this.errorLoadingServices$.next(value);
	}

	// quando ho i canali, recupero la configurazione (costante) per disegnare la UI
	sendServices(services: ClientServiceConfigurationResponse) {
		this.services$.next(services);
		this.retreiveServicesTiles(services.tipoCanale);
	}

	// recupero la configurazione solo per i canali configurati sull'azienda
	retreiveServicesTiles(services: Service[]) {
		const config = Copier.deepCopy(uiConfigReceivable);
		const selectServices = [];
		services.forEach(function (service) {
			const selectedService = config.services.find(obj => ('' + obj.serviceId) === ('' + service.channelId));
			selectServices.push(selectedService);
		});
		this.sendServicesTiles(selectServices);
		this.filterService.sendServicesTiles(selectServices);
	}

	// configurazione dei canali configurati (ui-config)
	whenServicesTiles(): Observable<ServiceConfig[]> {
		return this.servicesTiles$.asObservable();
	}

	// configurazione dei canali configurati (ui-config)
	sendServicesTiles(servicesTiles: ServiceConfig[]) {
		this.servicesTiles$.next(servicesTiles);
	}

	// configurazione dei canali configurati (ui-config)
	getServicesTiles(): ServiceConfig[] {
		return this.servicesTiles$.value;
	}

	// totali nelle tiles piccole
	whenSmallCardsValues(): Observable<any> {
		return this.smallCardsValues$.asObservable();
	}

	// totali nelle tiles piccole
	sendSmallCardsValues(smallCardsValues: any) {
		this.smallCardsValues$.next(smallCardsValues);
	}

	// totali nelle tiles piccole
	whenLargeCardsValues(): Observable<any> {
		return this.largeCardsValues$.asObservable();
	}

	// totali nelle tiles piccole
	sendLargeCardsValues(smallCardsValues: any) {
		this.largeCardsValues$.next(smallCardsValues);
	}

	// costruisce payload per richiesta totali tiles piccole
	buildPayload(currentCompany: Company): FilterPayload {
		const tilesConfig = this.getServicesTiles();
		// piastrelle grandi
		const metrics: Metric[] = Copier.deepCopy(receivableMetricsConfig);
		// piastrelle piccole
		tilesConfig.forEach(function (service) {
			service.tiles.forEach(function (tile) {
				if (tile.metadata !== SectionCode.CP_SQUADRATO)
					metrics.push(
						{
							metadata: tile.metadata,
							metricType: 'sum'
						}
					);
				else
					// nel caso delle squadrate, devo richiedere una sum filtrata per togliere le copie dal conteggio
					metrics.push(
						{
							metadata: tile.metadata,
							metricType: 'sum',
							filter: {
								metadata: 'hubfe_cp_idTipo',
								filterType: 'term',
								type: 'integer',
								configData: {},
								value: {
									term: '0'
								}
							}
						}
					);

			});
		});

		// filtro di default per ciclo attivo e data inizio anno - oggi
		const filters = [
			Copier.deepCopy(status35FilterConfig),
			Copier.deepCopy(primaryServiceStatusConfig),
			Copier.deepCopy(docTypeFilterConfig),
			Copier.deepCopy(amountFilterConfig),
			Copier.deepCopy(accountFilter.get(AccountType.RECEIVABLE)),
			Copier.deepCopy(dataProtocollazioneCtelFilterConfig)
		];

		return {
			licenseId: currentCompany.licenseId,
			siaCode: currentCompany.siaCode,
			docSeriesId: 'ALL',
			filters,
			metrics,
			selectMetadata: [],
			search: {
				fullText: false,
				value: '',
				metadataList: metadataListConfig
			},
			paging: {
				offset: 0,
				docsPerPages: 0
			}
		};

	}
}
