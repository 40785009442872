/* eslint-disable @typescript-eslint/member-ordering */
import {
	ComponentRef,
	Directive,
	EmbeddedViewRef,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	TemplateRef,
	Type,
	ViewContainerRef
} from '@angular/core';
import { Options } from 'app/core/common/placeholder/error-placeholder/directives/options';
import { ErrorPlaceholderComponent } from 'app/core/common/placeholder/error-placeholder/templates/error-placeholder/error-placeholder.component';
import { ComponentInjector } from 'app/core/common/utilities/component-injector';
import { Subject } from 'rxjs';

@Directive({
	selector: '[hubPlaceholder]',
})
export class PlaceholderDirective implements OnDestroy, OnChanges {

	private destroy$ = new Subject<void>();

	@Input() public hubPlaceholder: boolean;

	/*----------------------------------------------
	 * Gestione dell'errore
	 *----------------------------------------------*/

	// Input
	private defaultErrorPlaceholder = ErrorPlaceholderComponent;
	@Input() public hubPlaceholderError: boolean;
	@Input() public hubPlaceholderErrorTemplate: Type<any> = this.defaultErrorPlaceholder;
	@Input() public hubPlaceholderErrorOptions: Options;

	// Istanziazione componente di errore
	public errorComponentInstantiated = false;
	private errorComponent: ComponentRef<ErrorPlaceholderComponent>;

	/*----------------------------------------------
	 * Gestione del caricamento
	 *----------------------------------------------*/

	// Input
	private defaultLoadingPlaceholder = ErrorPlaceholderComponent;
	@Input() public hubPlaceholderLoading: boolean;
	@Input() public hubPlaceholderLoadingTemplate: Type<any> = this.defaultLoadingPlaceholder;
	@Input() public hubPlaceholderLoadingOptions: Options;

	// Istanziazione componente di caricamento
	public loadingComponentInstantiated = false;
	private loadingComponent: ComponentRef<any>;

	/*----------------------------------------------
	 * Gestione del contenuto incorporato
	 *----------------------------------------------*/
	private embeddedComponent: EmbeddedViewRef<any>;

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
	) { }

	/**
	 * Tutti i cambiamenti di stato di questa classe vengono gestiti nella ngOnChanges
	 * @param changes
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if (!changes)
			return;

		if (changes['hubPlaceholderLoadingTemplate'])
			this.hubPlaceholderLoadingTemplate = changes['hubPlaceholderLoadingTemplate'].currentValue;

		if (changes['hubPlaceholderLoading'])

			if (changes['hubPlaceholderLoading'].currentValue === true) {

				this.instantiateLoadingComponent(this.hubPlaceholderLoadingTemplate);

				if (this.embeddedComponent)
					this.embeddedComponent.destroy();

				ComponentInjector.insertComponent(this.viewContainer, this.loadingComponent);
			} else {
				ComponentInjector.detachComponent(this.viewContainer, this.loadingComponent);
				this.embeddedComponent = this.viewContainer.createEmbeddedView(this.templateRef);
			}

	}

	instantiateLoadingComponent(template: Type<any>) {
		this.viewContainer.createComponent(template);
		this.loadingComponentInstantiated = true;
	}

	ngOnDestroy(): void {
		this.viewContainer.clear();
		this.destroy$.next();
		this.destroy$.complete();

	}
}
