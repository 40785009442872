import { Component } from '@angular/core';
import { NumericField } from 'app/shared/components/dry/field';
import { AbstractCellComponent } from '../abstract-cell.component';

@Component({
	templateUrl: './number-cell.component.html',
	styleUrls: ['./number-cell.component.scss']
})
export class NumberCellComponent extends AbstractCellComponent<NumericField> {
	value: number | string;
}
