import { FieldBuilder } from 'app/shared/components/dry/field/field-builder';
import { DateField } from 'app/shared/components/dry/field/specializations/date-field';

export class DateFieldBuilder extends FieldBuilder {

	constructor(id: string, name: string) {
		super(new DateField(id, name));
	}

	startFromToday(): DateFieldBuilder {
		(<DateField>this.field).startFromToday = true;
		return this;
	}

	format(format: string): DateFieldBuilder {
		(<DateField>this.field).format = format;
		return this;
	}
}
