import { Injectable, OnDestroy } from '@angular/core';
import { FilterStatus } from '@ctel/gaw-commons';
import {
	ActionsHttpService,
	DocumentsSeriesService,
	FRDocumentiDocumentsService2,
	FilterService2
} from '@ctel/gawcons-commons';
import { DocumentState, getRouterInfo } from '@ctel/search-filter-store';
import { Transfer, TransferService, TransferUtils } from '@ctel/transfer-manager';
import { Store, select } from '@ngrx/store';
import { AppError } from 'app/core/common/error';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { EMPTY, Observable, Subject, combineLatest, of } from 'rxjs';
import { count, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ConfirmMassiveRequest } from '../../../entities/confirm-massive-request';
import { FrDocumentiService } from '../../../services/fr-documenti.service';

/**
 * Callback delle azioni massive
 */
@Injectable({
	providedIn: 'root'
})
export class AllDocumentsCallback implements OnDestroy {

	private readonly _filterPayload$: Observable<FilterStatus>;
	private readonly _totalDocuments$: Observable<number>;
	private concurrentDownloads = 0;

	private destroy$ = new Subject<void>();

	constructor(
		private documentsService: FRDocumentiDocumentsService2,
		private filterService: FilterService2,
		private notificationService: NotificationService,
		private documentsSeriesService: DocumentsSeriesService,
		private actionsHttpService: ActionsHttpService,
		private transferService: TransferService,
		private frDocumentiService: FrDocumentiService,
		protected store: Store<DocumentState>
	) {
		this._totalDocuments$ = documentsService.whenTotalDocuments();
		this._filterPayload$ = filterService.whenFilterValue();
		this.transferService.whenTransfers().pipe(
			switchMap(value => of(Object.values<Transfer>(value)).pipe(
				mergeMap(value1 => value1),
				count(v => !TransferUtils.isComplete(v)),
				tap(v => this.concurrentDownloads = v)
			)),
			takeUntil(this.destroy$)
		).subscribe();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	cancelMassive(action: ActionObject, extraParams: unknown) {
		return this._totalDocuments$.pipe(
			take(1),
			mergeMap(() => combineLatest([this._filterPayload$, this.store.pipe(select(getRouterInfo))])
				.pipe(
					take(1),
					switchMap(([payload, routerInfo]) => {
						const progSpool = +routerInfo.state.params['progspool'];
						const body = { searchRequest: payload, progSpool };
						this.notificationService.showSweetAlert(NotificationType.QUESTION, 'Cancella',
							'Confermi la cancellazione massiva?', () => this.executeCancelMassive(JSON.stringify(body)));

						return EMPTY;
					})
				)
			)
		);

	}

	executeCancelMassive = (body: string) => {
		this.frDocumentiService.cancelMassive(body).subscribe(() => this.documentsService.refreshDocuments());
	};

	confirmMassive() {
		return this._totalDocuments$.pipe(
			take(1),
			mergeMap(() => combineLatest([this._filterPayload$, this.store.pipe(select(getRouterInfo))])
				.pipe(
					take(1),
					switchMap(([payload, routerInfo]) => {
						const body: ConfirmMassiveRequest = {
							searchRequest: payload,
							progSpool: routerInfo.state.params['progspool'],
							nota: 'Conferma automatica'
						};
						this.notificationService.showSweetAlert(NotificationType.QUESTION, 'Conferma',
							'Stai confermando documenti con anomalie, sei sicuro?',
							() => this.executeConfirmMassive(body)
								.pipe(take(1))
								.subscribe({
									next: () => {
										this.notificationService.showSweetAlert(NotificationType.SUCCESS, '',
											'Conferma dei documenti con anomalia avvenuta con successo');
										this.documentsService.refreshDocuments();
									},
									error: (error: unknown) => this.notificationService.showSweetAlert(NotificationType.ERROR, '',
										(error as AppError).additionalInfo.find(x => x.t === 'response')?.u)
								}));
						return EMPTY;
					})
				)
			)
		);
	}

	executeConfirmMassive(payload: ConfirmMassiveRequest): Observable<void> {
		return this.frDocumentiService.confirmMassive(payload);
	}
}
