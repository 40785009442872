import { Pipe, PipeTransform } from '@angular/core';
import { Copier } from 'app/core/common/utilities/copier';
import { Contact } from 'app/entities/contacts/contact';
import * as flatten from 'flat';

/**
 * Pipe che prende un array di oggetti, li appiattisce, cerca in tutti i values e restituisce gli item originali trovati
 */
@Pipe({
	name: 'filterSearch',
})
export class FilterSearchPipe implements PipeTransform {
	transform(items: Contact[], searchText: string): Contact[] {
		if (!items) return [];

		if (!searchText) return items;

		if (searchText.length < 2) return items;

		const itemsArray = Copier.deepCopy(items);
		let newItems = [];
		for (let i = 0; i < itemsArray.length; i++)
			newItems.push(flatten(itemsArray[i]));

		newItems = newItems.filter((item) =>
			Object.keys(item).some((key) => String(item[key]).toLowerCase().includes(searchText.toLowerCase()))
		);

		const unflattenItems = [];
		for (let i = 0; i < newItems.length; i++)
			unflattenItems.push(flatten.unflatten(Copier.deepCopy(newItems[i])));

		return unflattenItems;
	}
}
