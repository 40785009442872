<div class='row h-100'>
	<div class="col-md-10">
		<div class="details-title" *ngIf="documentDetails$ | async as documentDetails">
			<ng-container *ngIf="documentSeriesColors$ | async as documentSeriesColors">
				<ng-container *ngFor="let documentSeriesColor of documentSeriesColors">
					<ng-container
												*ngIf="documentSeriesColor.description.toLowerCase() === documentDetails.docSeriesDescription.toLowerCase()">
						<span class="badge doctype-label" [ngStyle]="{ 'background-color': documentSeriesColor.hex }">
							<span class="badge-text">{{ documentDetails.docSeriesDescription || documentDetails.docSeriesId }}</span>
						</span>
					</ng-container>
				</ng-container>
			</ng-container>

			<!-- <div class="t rs">{{ getRagioneSociale(documentDetails.metadataList) }},</div> -->
			<div class="t nd">n. {{ documentDetails.documentId }}</div>
			<!-- del
			<div class="t dd">{{ getDataDocumento(documentDetails.metadataList) | date }}</div> -->
		</div>
		<gaw-gawpost-details-main [data]="data"> </gaw-gawpost-details-main>
	</div>
	<div class="col-md-2">
		<ng-container *ngIf='singleActionsList$ | async as actions'>
			<ng-container *ngIf='documentDetails$ | async as documentDetails'>
				<h4 class='action px-0'>Azioni</h4>
				<div class='container1 results-content__actions_sidebars d-grid gap-2'>
					<ng-container *ngIf="documentDetails$ | async as documentDetails">
						<ng-container *ngFor="let action of actions">
							<button
											class="btn btn-primary btn-action"
											[ngClass]="{ yellow: action.highlighted === true }"
											type="button"
											(click)="executeAction(action, documentDetails.keys)">
								<i class="btn-action-sidebar" [ngClass]="[action.icon]" aria-hidden="true"></i>
								{{ action.name }}
							</button>
						</ng-container>
					</ng-container>
				</div>
			</ng-container>
		</ng-container>

		<h4 class="service-metadata mb-3">Metadati canale</h4>
		<div *ngIf="metadatiCanale$ | async as documentDetails" class="card document-details">
			<div class="card-body">
				<div *ngFor="let metadata of documentDetails.metadataList">
					<ng-container *ngIf="metadata.technical === true && metadata.keyCode === metadataEnum.DATA_RICEZIONE">
						<b>Data ricezione: </b>
						<span *ngIf="metadata.valueDisplay !== '' && metadata.valueDisplay !== null">{{ metadata.valueDisplay
							}}</span>
						<span *ngIf="metadata.valueDisplay === '' || metadata.valueDisplay === null">-</span>
					</ng-container>

					<ng-container *ngIf="metadata.technical === true && metadata.keyCode === metadataEnum.DATA_INSERIMENTO">
						<b>Data inserimento: </b>
						<span *ngIf="metadata.valueDisplay !== '' && metadata.valueDisplay !== null">{{ metadata.valueDisplay
							}}</span>
						<span *ngIf="metadata.valueDisplay === '' || metadata.valueDisplay === null">-</span>
					</ng-container>

					<ng-container *ngIf="metadata.technical === true && metadata.keyCode === 'descrizioneUltimoStato22'">
						<b>Ultimo stato: </b>
						<span *ngIf="metadata.valueDisplay !== '' && metadata.valueDisplay !== null">{{ metadata.valueDisplay
							}}</span>
						<span *ngIf="metadata.valueDisplay === '' || metadata.valueDisplay === null">-</span>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
