import { ComponentRef, ViewContainerRef } from '@angular/core';

export class ComponentInjector {
	public static detachComponent(viewContainerRef: ViewContainerRef, component: ComponentRef<any>): void {
		if (component) {
			const index = viewContainerRef.indexOf(component.hostView);
			viewContainerRef.detach(index);
		}
	}

	public static insertComponent(viewContainerRef: ViewContainerRef, component: ComponentRef<any>): void {
		viewContainerRef.insert(component.hostView);
	}

}
