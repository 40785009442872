import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TransferUtils } from '@ctel/transfer-manager';
import { Transfer } from '@ctel/transfer-manager/lib/entities/transfer';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { EMPTY, Observable } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { ActionsHttpService } from '../actions-http.service';

@Injectable({
	providedIn: 'root',
})
export class SingleDocumentCallback {
	constructor(private actionsHttpService: ActionsHttpService, private router: Router) { }

	downloadPDF(url: string, progSpool: string, progBusta: string, docHash: string): Observable<any> {
		return this.actionsHttpService.whenSinglePdf(url, progSpool, progBusta, docHash).pipe(
			catchError((err: unknown) => {
				if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
					//this.router.navigate(['/unauthorized']).then();
				}
				return EMPTY;
			}),
			take(1),
			tap((result) => {
				if (result !== null) {
					const r = result as Transfer;
					if (TransferUtils.isHttpResponse(r.originatingEvent)) {
						const blob = new Blob([r.originatingEvent.body], { type: 'application/pdf' });
						new FileSaver(blob).saveAs(r.name, FileSaverExtension.PDF);
					}
				}
			})
		);
	}

	downloadAttachments(url: string, idComunicazione: string): Observable<any> {
		return this.actionsHttpService.whenAttachments(url, idComunicazione).pipe(
			catchError((err: unknown) => {
				if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
					//this.router.navigate(['/unauthorized']).then();
				}
				return EMPTY;
			}),
			take(1),
			tap((result) => {
				if (result !== null) {
					const r = result as Transfer;
					if (TransferUtils.isHttpResponse(r.originatingEvent)) {
						const blob = new Blob([r.originatingEvent.body], { type: 'application/zip' });
						new FileSaver(blob).saveAs(r.name, FileSaverExtension.ZIP);
					}
				}
			})
		);
	}
}
