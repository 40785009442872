import * as memoizee_ from 'memoizee';
import { Observable, ReplaySubject } from 'rxjs';
import { share } from 'rxjs/operators';
const memoizee = memoizee_;

export function Memoize() {
	return (target: object, key: string, descriptor?: TypedPropertyDescriptor<(...args) => unknown>) => {
		if (!descriptor || !descriptor.value)
			throw new Error('Only put a @Memoize() decorator on a method or get accessor.');

		const oldFunction = descriptor.value;
		const newFunction = memoizee(oldFunction);
		descriptor.value = function () {
			return newFunction.apply(this);
		};
	};
}

export function MemoizeObservable(expiryy = 1) {
	return (target: object, key: string, descriptor?: TypedPropertyDescriptor<(...args) => unknown>) => {
		if (!descriptor || !descriptor.value)
			throw new Error('Only put a @MemoizeObservable() decorator only on services methods that returns Observables');

		const oldFunction = descriptor.value;
		const cacheMember = `${key}MemoizedCache`;
		target[cacheMember] = {} as { [key: string]: { cached: unknown, expiry: number } };

		descriptor.value = function (...args) {
			const hash = JSON.stringify(args); // Ugly, underperforming, but 0 deps and effective.
			if (target[cacheMember][hash] && Date.now() <= target[cacheMember][hash].expiry)
				return target[cacheMember][hash].cached;

			const returnedObservable = oldFunction.apply(this, args);
			if (!(returnedObservable instanceof Observable))
				throw new Error('Method decorated with @MemoizeObservable() decorator must return an Observable');

			target[cacheMember][hash] = {
				expiry: Date.now() + (expiryy * 60000),
				cached: returnedObservable.pipe(
					share({
						connector: () => new ReplaySubject(1),
						resetOnError: false,
						resetOnComplete: false,
						resetOnRefCountZero: false
					})
				)
			} as { cached: unknown, expiry: number };

			return target[cacheMember][hash].cached;
		};
	};
}
