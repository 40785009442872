/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, OnInit } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { TextValidators } from '../../text-validators';

@Directive({
	selector: '[bicType][ngModel], [bicType][formControlName]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: BicTypeValidatorDirective, multi: true }
	]
})
export class BicTypeValidatorDirective implements Validator, OnInit {

	validator: ValidatorFn;

	constructor() { }

	ngOnInit(): void {
		this.validator = TextValidators.bicTypeValidator();
	}

	registerOnValidatorChange(fn: () => void): void { }

	validate(c: AbstractControl): ValidationErrors | null {
		return this.validator(c);
	}

}
