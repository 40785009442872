<div class="notes-list row" *ngIf="hasNotes === true">
	<div *ngFor="let note of notes" [ngStyle]="style" [ngClass]="klass" class="col-12 note-box">
		<div class="note-header">
			<div class="info">
				<h4>
					{{note.nickName}} <br> <span>{{note.lastUpdateDate | date:'medium' }}</span>
				</h4>
			</div>
			<div class="action" *ngIf="user !== undefined">
				<i class="fa notes-list-view" [ngClass]="{'fa-pencil fas fa-pencil-alt': user === +note.authUserId,
											'far fa-eye': user !== +note.authUserId
										   }" (click)="onNoteClick(note)"></i>
				<ng-container *ngIf="user === +note.authUserId">
					<i class="fa fa-trash far fa-trash-alt notes-list-remove" (click)="onNoteDelete(note)"></i>
				</ng-container>
			</div>
		</div>
		<div class="note-body">
			<div class="note-info">{{note.note.length > 150 ? (note.note | slice:0:150)+'...' : note.note}}</div>
		</div>
	</div>
</div>
