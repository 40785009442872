import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthConfigService, IAuthConfig } from '@ctel/auth';
import { AppConfig } from 'app/entities/config/app-config';
import { DryConfig } from 'app/entities/config/dry-config';
import { environment } from 'environments/environment';
import { Observable, ReplaySubject, map } from 'rxjs';
import { AppErrorBuilder } from '../error/app-error-builder';
import { ErrorTypes } from '../error/error-types';

@Injectable({
	providedIn: 'root'
})
export class ConfigService extends AuthConfigService {

	private dryConfig$ = new ReplaySubject<DryConfig>(1);
	private appConfig$ = new ReplaySubject<AppConfig>(1);

	private httpClient: HttpClient;

	constructor(handler: HttpBackend) {
		super();

		this.httpClient = new HttpClient(handler);

		// TODO: i send() dovrebbero essere fatti dopo l'inizializzazione dell'app (nell'APP_INITIALIZER).
		this.initDryConfig(environment.dryConfigUrl);
		this.initAppConfig(environment.appConfigUrl);
	}

	// Punto di ingresso di inizializzazione di dry
	public initDryConfig(configUrl: string) {
		return this.loadConfig<DryConfig>(configUrl)
			.subscribe({
				next: config => this.sendDryConfig(config),
				error: (err: unknown) => {
					throw new AppErrorBuilder(ErrorTypes.CONFIG_LOADING_ERROR)
						.cause(err as Error)
						.description('Non è stato possibile caricare la configurazione del template Dry.' +
							'L\'applicazione non può essere caricata.')
						.info('configUrl', configUrl)
						.build();
				}
			});
	}

	// Punto di ingresso di inizializzazione della configurazione
	public initAppConfig(configUrl: string) {
		return this.loadConfig<AppConfig>(configUrl).subscribe({
			next: config => this.sendAppConfig(config),
			error: (err: unknown) => {
				throw new AppErrorBuilder(ErrorTypes.CONFIG_LOADING_ERROR)
					.cause(err as Error)
					.description('Non è stato possibile caricare la configurazione dell\'applicazione.' +
						'L\'applicazione non può essere caricata.')
					.info('appConfigUrl', configUrl)
					.build();
			}
		});
	}

	loadConfig<T>(configUrl: string): Observable<T> {
		return this.httpClient.get<T>(configUrl);
	}

	whenAppConfig(): Observable<AppConfig> {
		return this.appConfig$.asObservable();
	}

	sendAppConfig(appConfig: AppConfig) {
		this.appConfig$.next(appConfig);
	}

	whenDryConfig(): Observable<DryConfig> {
		return this.dryConfig$.asObservable();
	}

	sendDryConfig(dryConfig: DryConfig) {
		this.dryConfig$.next(dryConfig);
	}

	getAuthConfig(): Observable<IAuthConfig> {
		return this.dryConfig$.asObservable()
			.pipe(map(config => config.auth));
	}
}
