import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { SingleDocumentCallback } from './callbacks/single-document-callback';

@Injectable({
	providedIn: 'root',
})
export class SingleActionsCatalog {

	// Action URL GAWEDI
	public readonly visualizeMessageHistory =
		new ActionBuilder(SingleActionsCode.visualizeMessageHistory, ActionCode.SHOW_STATUS_HISTORY)
			.setActionURL('/api/v1/Action/ViewStatusHistory?ID_FILEOUTPUT={idFileOutput}')
			.build();

	public readonly pdfDownload =
		new ActionBuilder(SingleActionsCode.pdfDownload, ActionCode.DOWNLOAD_PDF)
			.setActionURL('/api/v1/Action/GetEdiDocument?IdLicenza={idLicenza}&ID_FILEOUTPUT={idFileOutput}&getPdf=true&download=true')
			.setCallback(([url, idLicenza, idFileOutput]) => this.singleDocumentCallback.downloadPDF(url, idLicenza, idFileOutput))
			.build();
	// Fine Action URL GAWEDI

	public readonly downloadEDI =
		new ActionBuilder(SingleActionsCode.downloadEDI, ActionCode.DOWNLOAD_EDI)
			.setActionURL('/api/v1/Action/GetEdiDocument?IdLicenza={idLicenza}&ID_FILEOUTPUT={idFileOutput}&getPdf=false&download=true')
			.build();

	constructor(private singleDocumentCallback: SingleDocumentCallback) { }
}

export enum SingleActionsCode {
	visualizeMessageHistory = 'visualizeMessageHistory',
	pdfDownload = 'pdfDownload',
	downloadEDI = 'downloadEDI'
}
