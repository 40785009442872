import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class MultiActionsCatalog {

	public readonly downloadMergedPdf = new ActionBuilder(MultiActionCode.downloadMergedPdf, ActionCode.DOWNLOAD_MERGED_PDF)
		.setActionURL('/v1/Document/DisplayMergedDocs')
		.build();

	public readonly downloadZip = new ActionBuilder(MultiActionCode.downloadZip, ActionCode.DOWNLOAD_ZIP)
		.setActionURL('/v1/Document/Scarica')
		.build();

	public readonly exportExcel = new ActionBuilder(MultiActionCode.exportExcel, ActionCode.DOWNLOAD_EXCEL)
		.setActionURL('/v2/documents/export/excel')
		.build();

}

export enum MultiActionCode {
	downloadMergedPdf = 'downloadMergedPdf',
	downloadZip = 'downloadZip',
	exportExcel = 'exportExcel',
}

