import { Injectable } from '@angular/core';
import { SingleDocumentCallback } from './callbacks/single-document-callback';
import { ActionBuilder } from '../../../../../../../src/app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from '../../../../../../../src/app/entities/ui-config/action/action-code.enum';

@Injectable({
	providedIn: 'root',
})
export class SingleActionsCatalog {
	public readonly cancelRequest = new ActionBuilder(SingleActionsCode.cancelRequest, ActionCode.CANCEL_REQUEST)
		.build();

	public readonly download = new ActionBuilder(SingleActionsCode.download, ActionCode.DOWNLOAD)
		.build();

	public readonly upload = new ActionBuilder(SingleActionsCode.upload, ActionCode.UPLOAD)
		.build();
}

export enum SingleActionsCode {
	cancelRequest = 'cancelRequest',
	download = 'download',
	upload = 'upload'
}
