import { Component, Input } from '@angular/core';
import { RefreshPlaceholderComponent } from 'app/core/common/placeholder/loading-placeholder/templates/refresh-placeholder/refresh-placeholder.component';
import { TableActionStatus, TableActionStatusEnum } from 'app/shared/components/table/actions/table-action-status';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
	selector: 'gaw-actions-bar',
	templateUrl: './actions-bar.component.html',
	styleUrls: ['./actions-bar.component.scss']
})
export class ActionsBarComponent {

	actions$: Observable<TableActionStatus[]>;

	public readonly ACTION_STATUS = TableActionStatusEnum;

	loadingTemplate = RefreshPlaceholderComponent;

	@Input() set actions(actions: TableActionStatus[]) {
		this.actions$ = of(actions);
	}

	executeAction(actionStatus: TableActionStatus) {

		actionStatus.status = TableActionStatusEnum.LOADING;

		actionStatus.action.execute()
			.pipe(
				take(1)
			)
			.subscribe({
				next: () => actionStatus.status = TableActionStatusEnum.LOADED,
				error: () => actionStatus.status = TableActionStatusEnum.ERROR,
				complete: () => actionStatus.status = TableActionStatusEnum.LOADED,
			});
	}

}
