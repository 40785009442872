export enum Sorting {
	ASCENDING,
	DESCENDING,
	NOT_SET
}

export interface Sort {
	columnId: string;
	order: Sorting;
}
