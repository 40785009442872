export interface DocumentDetails {
	documentId: string;
	docSeriesId: string;
	docSeriesDescription: string;
	metadataList: DetailsMetadata[];
	keys: object;
	tags: TagObject[];
}

export interface DetailsMetadata {
	value: string;
	valueDisplay: string;
	keyCode: string;
	technical: boolean;
	description: string;
	type: string;
}

export interface TagObject {
	description: string;
	id: string;
	keyCode: string;
	technical: boolean;
	type: string;
	value: string[];
	valueDisplay: string;
}
