import { Component, Input, OnInit } from '@angular/core';
import { IDetailsComponent } from '@ctel/gaw-commons';
import { DocumentAttachmentPdf } from 'app/shared/components/display-document/document-attachment-pdf';
import { DocumentDetailsService } from '../../documents-search/document-details/document-details.service';

@Component({
	selector: 'gaw-gawpost-details-main',
	templateUrl: './gawpost-details-main.component.html',
	styleUrls: ['./gawpost-details-main.component.scss'],
})
export class GAWPOSTDetailsMainComponent implements OnInit {
	@Input() data: IDetailsComponent;

	documentAttachmentPec: DocumentAttachmentPdf;
	documentAttachmentMessagePec: DocumentAttachmentPdf;

	constructor(private documentDetailsService: DocumentDetailsService) {}

	openPecMessagePdf() {
		// se è già caricato, non ripeto la chiamata
		if (this.documentAttachmentMessagePec)
			return;

		this.documentAttachmentMessagePec = new DocumentAttachmentPdf(
			'MESSAGGIO',
			this.documentDetailsService.whenPdf('GEDPEC', this.data.progSpool, this.data.progBusta, this.data.docHash, false, '53')
		);
	}

	ngOnInit() {
		this.documentAttachmentPec = new DocumentAttachmentPdf(
			'MESSAGGIO',
			this.documentDetailsService.whenPdf('GEDPEC', this.data.progSpool, this.data.progBusta, this.data.docHash, false, '50')
		);
	}
}
