import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormErrorNavigationService } from 'app/core/business/invoice-pa/create-edit-invoice/form-error-navigation/form-error-navigation.service';
import { InvoiceFieldsService } from 'app/core/business/invoice-pa/create-edit-invoice/invoice/invoice-fields/invoice-fields.service';
import { InvoiceFormService } from 'app/core/business/invoice-pa/create-edit-invoice/invoice/invoice-form/invoice-form.service';
import { InvoiceHttpService } from 'app/core/business/invoice-pa/create-edit-invoice/invoice/invoice-http.service';
import { CitiesService } from 'app/core/business/invoice-pa/create-edit-invoice/shared/city/cities.service';
import { InvoiceTypeService } from 'app/core/business/invoice-pa/create-edit-invoice/shared/invoice-type/invoice-type.service';
import { Base64Service } from './base64/base64.service';
import { CancelChannelHttpService } from './cancel-channel-edit/cancel-channel-http.service';
import { CancelChannelService } from './cancel-channel-edit/cancel-channel.service';
import { CausaliPagamentoHttpService } from './causali-pagamento/causali-pagamento-http.service';
import { CausaliPagamentoService } from './causali-pagamento/causali-pagamento.service';
import { CurrencyHttpService } from './currency/currency-http.service';
import { CurrencyService } from './currency/currency.service';
import { CustomerRegistryHttpService } from './customer-registry/customer-registry-http.service';
import { CustomerRegistryService } from './customer-registry/customer-registry.service';
import { EditInvoiceHttpService } from './edit-invoice/edit-invoice-http.service';
import { EditInvoiceService } from './edit-invoice/edit-invoice.service';
import { InvoiceModelsHttpService } from './invoice-models/invoice-models-http.service';
import { InvoiceModelsService } from './invoice-models/invoice-models.service';
import { InvoiceService } from './invoice/invoice.service';
import { JsonToXmlHttpService } from './json-to-xml/json-to-xml-http.service';
import { JsonToXmlService } from './json-to-xml/json-to-xml.service';
import { DatiCassaService } from './shared/dati-cassa/dati-cassa.service';
import { ModalitaPagamentoService } from './shared/modalita-pagamento/modalita-pagamento.service';
import { NationsHttpService } from './shared/nations/nations-http.service';
import { NationsService } from './shared/nations/nations.service';
import { NaturaService } from './shared/natura/natura.service';
import { RegimeFiscaleService } from './shared/regime-fiscale/regime-fiscale.service';
import { XmlParserService } from './shared/xml-parser/xml-parser.service';
import { UploadEditedHttpService } from './upload-edited-invoice/upload-edited-http.service';
import { UploadEditedService } from './upload-edited-invoice/upload-edited.service';
import { XmlPaValidatorHttpService } from './xml-pa-validator/xml-pa-validator-http.service';
import { XmlPaValidatorService } from './xml-pa-validator/xml-pa-validator.service';

@NgModule({
	imports: [
		CommonModule,
	],
	providers: [],
	exports: []
})
export class InvoicePaModule {

	static forRoot(): ModuleWithProviders<InvoicePaModule> {
		return {
			ngModule: InvoicePaModule,
			providers: [
				XmlParserService,
				CitiesService,
				RegimeFiscaleService,
				DatiCassaService,
				NationsService,
				NationsHttpService,
				XmlPaValidatorHttpService,
				XmlPaValidatorService,
				InvoiceTypeService,
				JsonToXmlHttpService,
				JsonToXmlService,
				ModalitaPagamentoService,
				NaturaService,
				EditInvoiceService,
				EditInvoiceHttpService,
				UploadEditedHttpService,
				UploadEditedService,
				CustomerRegistryService,
				CustomerRegistryHttpService,
				CurrencyService,
				CurrencyHttpService,
				CausaliPagamentoService,
				CausaliPagamentoHttpService,
				CancelChannelService,
				CancelChannelHttpService,
				Base64Service,
				InvoiceModelsService,
				InvoiceModelsHttpService,
				InvoiceService,
				FormErrorNavigationService,
				InvoiceFormService,
				InvoiceHttpService,
				InvoiceFieldsService
			]

		};
	}
}
