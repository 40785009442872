export interface BoxDetails {
	listaTrackingDetails: Spool[];
	note: string;
}

export interface Spool {
	nomeFlusso: string;
	nomeFileOriginale: string;
	numeroDocumenti: number;
	dataRicezione: string;
	elasticId: string;
}
