import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { KpiSectionsService } from 'app/modules/homepage/core/kpi-sections/kpi-sections.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, take, takeUntil } from 'rxjs';

/**
 * Componente modal per l'azione Visualizza storico esiti del passivo
 * visibile solo con ruolo dedicato (richiesta cliente SNAM)
 */
@Component({
	selector: 'gaw-edit-sections-modal',
	templateUrl: './edit-sections-modal.component.html',
	styleUrls: ['./edit-sections-modal.component.scss'],
})
export class EditSectionsModalComponent implements OnInit, OnDestroy {
	@Input() modal: BsModalRef;
	@Input() data: any;

	public faIcons = FaIcons;
	rowData = [];

	private destroy$ = new Subject<void>();

	constructor(private kpiSectionsService: KpiSectionsService) { }

	ngOnInit(): void {
		this.data.sections
			.pipe(takeUntil(this.destroy$))
			.subscribe(list => {
				this.rowData = list.map(item => ({
					...item,
					isActive: item.visible,
				}));
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	toggleVisibility(section) {
		const { siaCode, licenseId } = this.data.company;
		const update = this.rowData.map(value => {
			if (value.sectionId === section.sectionId)
				value.visible = !value.visible;

			return value;
		});
		this.kpiSectionsService.setKpiSections(licenseId, siaCode, update)
			.pipe(take(1))
			.subscribe(() => {
				this.kpiSectionsService.getKpiSections(licenseId, siaCode);
			});
	}

	dropped(event: CdkDragDrop<string[]>) {
		// modifico gli index dell'array in base all'ordinamento modificato
		moveItemInArray(this.rowData, event.previousIndex, event.currentIndex);
		// salvo l'ordinamento inviando al server tutto l'array
		const { siaCode, licenseId } = this.data.company;
		this.kpiSectionsService.setKpiSections(licenseId, siaCode, this.rowData)
			.pipe(take(1))
			.subscribe(() => {
				this.kpiSectionsService.getKpiSections(licenseId, siaCode);
			});
	}
}
