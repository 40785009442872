import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { WorkitemHistoryTableComponent } from './workitem-history-table.component';
import { WorkitemHistoryService } from './workitem-history.service';
import { PipesModule } from 'app/shared/pipes/pipes.module';

@NgModule({
	imports: [
		CommonModule,
		PopoverModule,
		FormsModule,
		BsDatepickerModule,
		PipesModule
	],
	declarations: [
		WorkitemHistoryTableComponent
	],
	exports: [
		WorkitemHistoryTableComponent
	]
})
export class WorkitemHistoryModule {
	public static forRoot(): ModuleWithProviders<WorkitemHistoryModule> {

		return {
			ngModule: WorkitemHistoryModule,
			providers: [
				WorkitemHistoryService
			]
		};
	}
}
