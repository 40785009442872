export class Comparer {
	public static deepCompare(p: any, q: any) {
		return (JSON.stringify(p) === JSON.stringify(q));
	}

	public static arrayCompare(a: Array<any>, b: Array<any>): boolean {
		if (a === b)
			return true;

		if (a === null || b === null)
			return false;

		if (a.length !== b.length)
			return false;

		for (let i = 0; i < a.length; ++i)
			if (a[i] !== b[i])
				return false;

		return true;
	}
}
