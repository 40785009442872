import { Injectable } from '@angular/core';
import { DocSeriesMetadataDesc } from '@ctel/gaw-commons';
import { GridColumn } from '@ctel/gaw-components';
import { RelatedSectionData } from 'app/entities/sections/related-section-data';
import { Details } from 'app/modules/details/entities/details';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { DocumentsService2 } from '../../public-api';
import { PDDRequestModel } from '../entities/PDD-request-model';
import { IGawconsDetailsComponentInterface } from '../entities/gawcons-details-component.interface';
import { GroupList } from '../entities/group-list';
import { PreviousVersion } from '../entities/previous-version';
import { RegistryList } from '../entities/registry-list';
import { ReportPayload } from '../entities/report-payload';
import { ArchivioFiscaleHttpService } from './archivio-fiscale-http.service';

@Injectable({
  providedIn: 'root',
})
export class ArchivioFiscaleService {
  private groups$ = new BehaviorSubject<GroupList>(new GroupList());
  private groupsNoAcl$ = new BehaviorSubject<GroupList>(new GroupList());
  private registries$ = new BehaviorSubject<RegistryList>(new RegistryList());
  private selectedRegistryId$ = new BehaviorSubject<number>(null);
  private actualFiscalYear$ = new BehaviorSubject<string>('');
  private registriesNoAcl$ = new BehaviorSubject<RegistryList>(new RegistryList());

  private groups: GroupList;
  private registries: RegistryList;
  private selectedRegistryId: number;
  private actualFiscalYear: string;
  private registriesQuadrature$ = new BehaviorSubject<object[]>([]);

  constructor(
    private archivioFiscaleHttpService: ArchivioFiscaleHttpService,
    public documentsService: DocumentsService2,
  ) {}

  getGroups(licenseId: string, siaCode: string, offset: number, docsPerPage: number, search?: string) {
    this.archivioFiscaleHttpService
      .whenGroups(licenseId, siaCode, offset, docsPerPage, search)
      .pipe(take(1))
      .subscribe({
        next: (value) => {
          if (offset > 0)
            value = value
              ? {
                  gruppi: [...this.groups.gruppi, ...value.gruppi],
                  totaleGruppi: value.totaleGruppi,
                }
              : { ...this.groups };

          this.groups = value;
          this.groups$.next(value);
        },
        error: () => {
          this.groups = { gruppi: [], totaleGruppi: 0 };
          this.groups$.next(this.groups);
        },
      });
  }

  whenGroups(): Observable<GroupList> {
    return this.groups$.asObservable();
  }

  getRegistries(
    licenseId: string,
    siaCode: string,
    idGroup: number,
    offset: number,
    docsPerPage: number,
    search?: string,
  ) {
    this.archivioFiscaleHttpService
      .whenRegistries(licenseId, siaCode, idGroup, offset, docsPerPage, search)
      .pipe(take(1))
      .subscribe({
        next: (value) => {
          if (offset > 0)
            value = value
              ? {
                  registri: [...this.registries.registri, ...value.registri],
                  totaleRegistri: value.totaleRegistri,
                }
              : { ...this.registries };

          this.registries = value;
          this.registries$.next(value);
        },
        error: () => EMPTY,
      });
  }

  whenRegistries(): Observable<RegistryList> {
    return this.registries$.asObservable();
  }

  getPreviousVersions(
    documentId: string,
    licenseId: string,
    siaCode: string,
    fiscalYear: string,
    registryId: string,
  ): Observable<PreviousVersion[]> {
    return this.archivioFiscaleHttpService.whenPreviousVersions(documentId, licenseId, siaCode, fiscalYear, registryId);
  }

  requestPDD(pddModel: PDDRequestModel): Observable<PDDRequestModel> {
    return this.archivioFiscaleHttpService.requestPDD(pddModel);
  }

  getColumns(registryId: string, licenseId: string, siaCode: string) {
    return this.archivioFiscaleHttpService.whenColumns(registryId, licenseId, siaCode).pipe(
      take(1),
      map((columns) => {
        let metadataServicesColumns = [...columns];
        // taglio l'array in modo da tenere solo i primi 3 metadati
        if (columns.length >= 3) metadataServicesColumns = columns.slice(0, 3);

        return {
          primaryConfig: columns,
          secondaryConfig: metadataServicesColumns,
        };
      }),
    );
  }

  getConfigurableColumns(registryId: string, licenseId: string, siaCode: string): Observable<GridColumn[]> {
    return this.archivioFiscaleHttpService.whenConfigurableColumns(registryId, licenseId, siaCode);
  }

  whenUpsertUserColumns(
    registryId: string,
    licenseId: string,
    siaCode: string,
    columns,
    replicaPersonalizzazione: boolean,
  ) {
    return this.archivioFiscaleHttpService.whenUpserColumns(
      registryId,
      licenseId,
      siaCode,
      columns,
      replicaPersonalizzazione,
    );
  }

  whenMetadataDescriptions(
    registryId: string,
    licenseId: string,
    siaCode: string,
    relatedSectionData: RelatedSectionData[],
  ): Observable<DocSeriesMetadataDesc[]> {
    return this.archivioFiscaleHttpService
      .whenMetadataDescriptions(registryId, licenseId, siaCode, relatedSectionData)
      .pipe(
        map((metadatas) =>
          metadatas.map((metadata) => ({
            metadato: metadata.keyCode,
            descrizioni: [metadata.descrizione],
          })),
        ),
      );
  }

  whenMetadati(
    documentId: string,
    licenseId: string,
    siaCode: string,
    fiscalYear: string,
    registryId: string,
  ): Observable<Details> {
    return this.archivioFiscaleHttpService.whenMetadati(documentId, licenseId, siaCode, fiscalYear, registryId);
  }

  setSelectedRegistryId(selectedRegistryId: number) {
    this.selectedRegistryId = selectedRegistryId;
    this.selectedRegistryId$.next(selectedRegistryId);
  }

  getSelectedRegistryId() {
    return this.selectedRegistryId;
  }

  whenPdf(data: IGawconsDetailsComponentInterface, download: boolean): Observable<Blob> {
    return this.archivioFiscaleHttpService.whenPdfZip(data, download);
  }

  setFiscalYear(fiscalYear: string) {
    this.actualFiscalYear = fiscalYear;
    this.actualFiscalYear$.next(fiscalYear);
  }

  getFiscalYear() {
    return this.actualFiscalYear;
  }

  getPdvDetails(idPdv: string, licenseId: string, siaCode: string, fiscalYear: string, registryId: string) {
    return this.archivioFiscaleHttpService
      .getPdvDetails(idPdv, licenseId, siaCode, fiscalYear, registryId)
      .pipe(map((value) => value.metadati));
  }

  getGroupsNoAcl(licenseId: string, siaCode: string, offset: number, docsPerPage: number, search?: string) {
    this.archivioFiscaleHttpService
      .whenGroupsNoAcl(licenseId, siaCode, offset, docsPerPage, search)
      .subscribe((value) => {
        if (offset > 0)
          value = value
            ? {
                gruppi: [...this.groupsNoAcl$.value.gruppi, ...value.gruppi],
                totaleGruppi: value.totaleGruppi,
              }
            : { ...this.groupsNoAcl$.value };

        this.groupsNoAcl$.next(value);
      });
  }

  getRegistriesNoAcl(
    licenseId: string,
    siaCode: string,
    idGroup: number,
    offset: number,
    docsPerPage: number,
    search?: string,
  ) {
    this.archivioFiscaleHttpService
      .whenRegistriesNoAcl(licenseId, siaCode, idGroup, offset, docsPerPage, search)
      .pipe(withLatestFrom(this.registries$))
      .subscribe({
        next: ([registriesNoAcl, registries]) => {
          if (offset > 0)
            registriesNoAcl = registriesNoAcl
              ? {
                  registri: [...registries.registri, ...registriesNoAcl.registri],
                  totaleRegistri: registriesNoAcl.totaleRegistri,
                }
              : { ...registries };

          this.registriesNoAcl$.next(registriesNoAcl);
        },
        error: () => this.registriesNoAcl$.next({ registri: [], totaleRegistri: 0 }),
      });
  }

  getAllRegistries(licenseId: string, siaCode: string) {
    return this.archivioFiscaleHttpService.getAllRegistries(licenseId, siaCode);
  }

  getConfiguredRegistries(idProfilo: string, idLicenza: string, codSia: string) {
    return this.archivioFiscaleHttpService.getConfiguredRegistries(idProfilo, idLicenza, codSia);
  }

  whenMetadatiFirmaRemotaDocumenti(progSpool: string, progBusta: string): Observable<Details> {
    return this.archivioFiscaleHttpService.whenMetadatiFirmaRemotaDocumenti(progSpool, progBusta);
  }

  getFiscalYearsQuadrature(licenseId: string, siaCode: string) {
    return this.archivioFiscaleHttpService.getFiscalYearsQuadrature(licenseId, siaCode);
  }

  getDocClassesQuadrature(licenseId: string, siaCode: string) {
    return this.archivioFiscaleHttpService.getDocClassesQuadrature(licenseId, siaCode);
  }

  getRegistriesQuadrature(licenseId: string, siaCode: string) {
    if (!this.registriesQuadrature$.value.length)
      this.archivioFiscaleHttpService
        .getRegistriesQuadrature(licenseId, siaCode)
        .subscribe((regs) => this.registriesQuadrature$.next(regs));

    return this.registriesQuadrature$.asObservable();
  }

  downloadReportQuadrature(licenseId: string, siaCode: string, fileFormat: string, body: ReportPayload) {
    return this.archivioFiscaleHttpService.downloadReportQuadrature(licenseId, siaCode, fileFormat, body);
  }

  downloadReportBuchiQuadrature(licenseId: string, siaCode: string, fileFormat: string, body: ReportPayload) {
    return this.archivioFiscaleHttpService.downloadReportBuchiQuadrature(licenseId, siaCode, fileFormat, body);
  }

  whenGroupsNoAcl(): Observable<GroupList> {
    return this.groupsNoAcl$;
  }

  whenRegistriesNoAcl() {
    return this.registriesNoAcl$;
  }

  getAllRegistriesByGroup(licenseId: string, siaCode: string, idGruppo: string) {
    return this.archivioFiscaleHttpService.getAllRegistriesByGroup(licenseId, siaCode, idGruppo);
  }

  getMetadata(idRegistro: number, licenseId: string, siaCode: string) {
    return this.archivioFiscaleHttpService.getMetadata(idRegistro, licenseId, siaCode);
  }
}
