import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, ReplaySubject } from 'rxjs';
import { filter, find, map, mergeMap, share, switchMap, take, tap } from 'rxjs/operators';
import { EcmService } from '../../../../ecm/ecm.service';
import { XmlPaAttachment } from '../../../../entities/ecm/ecm';
import { WorkflowService } from '../../../../services/workflow.service';

@Injectable({
	providedIn: 'root'
})
export class DocumentAttachmentService {

	/** Lista degli allegati al documento di un workitem */
	public readonly attachmentList$: Observable<XmlPaAttachment[]>;
	private readonly _attachmentListLoading$ = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly workflowService: WorkflowService,
		private readonly ecmService: EcmService,
	) {
		this.attachmentList$ = this.workflowService.selectedWorkItem$
			.pipe(
				filter(wi => wi !== undefined),
				tap(() => this._attachmentListLoading$.next(true)),
				map(workItem => ({
					'progSpool': workItem.properties.find(prop => prop.name === 'progSpool'),
					'progBusta': workItem.properties.find(prop => prop.name === 'progBusta')
				})),
				filter((val) => val.progSpool !== undefined && val.progBusta !== undefined),
				switchMap((val) => this.ecmService.whenXmlPaAttachmentsList(val.progSpool.value, val.progBusta.value)),
				tap(() => this._attachmentListLoading$.next(false)),
				share({ connector: () => new ReplaySubject<XmlPaAttachment[]>(1) })
			);
	}

	/**
	 * Observable che notifica quando la lista degli allegati al documento di un workitem sta caricando
	 * @return Observable<boolean>
	 */
	get attachmentListLoading$(): Observable<boolean> {
		return this._attachmentListLoading$.asObservable();
	}

	/**
	 * Ottiene il dettaglio dell'attachment dato l'id
	 * @param attachmentId
	 * @return Observable<Attachment>
	 */
	getAttachmentById(attachmentId: string): Observable<XmlPaAttachment> {
		return this.attachmentList$.pipe(
			mergeMap(array => from(array).pipe(
				find(a => a.idAllegato.toString() === attachmentId)
			)),
			filter(att => att !== undefined)
		);
	}

	downloadAttachment(attachment: XmlPaAttachment): Observable<Blob | string> {
		return this.ecmService.whenXmlPaAttachmentFile(attachment);
	}

	/**
	 * Ottiene il dettaglio dell'attachment dato l'id progSpool e progBusta
	 *
	 * */
	retrieveAttachment(attachment, progSpool: string, progBusta: string): Observable<XmlPaAttachment> {
		return this.ecmService.whenXmlPaAttachmentsList(progSpool, progBusta).pipe(
			take(1),
			tap(() => this._attachmentListLoading$.next(true)),
			map(
				attachmentList => attachmentList.find(prop => prop.idAllegato.toString() === attachment)),
			filter((val) => val !== undefined),
			tap(() => this._attachmentListLoading$.next(false)),
			share({ connector: () => new ReplaySubject<XmlPaAttachment>(1) })
		);
	}

	/**
	 * Ottiene la lista dei document attachment dato l'id progSpool e progBusta
	 *
	 * */
	attachmentsList(progSpool: string, progBusta: string): Observable<XmlPaAttachment[]> {
		return this.ecmService.whenXmlPaAttachmentsList(progSpool, progBusta).pipe(
			take(1),
			tap(() => this._attachmentListLoading$.next(true)),
			map((attachments: XmlPaAttachment[]) => attachments),
			tap(() => this._attachmentListLoading$.next(false)),
			share({ connector: () => new ReplaySubject<XmlPaAttachment[]>(1) })
		);
	}
}
