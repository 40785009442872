import { Injectable } from '@angular/core';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { MassiveActionsCatalog } from './massive-actions-catalog';
import { MultiActionsCatalog } from './multi-actions-catalog';
import { SingleActionsCatalog } from './single-actions-catalog';

@Injectable({
	providedIn: 'root'
})
export class ActionsByType {

	public readonly massiveActions: ActionObject[] = [
		this.massiveActionsCatalog.restoreFromTrash,
		this.massiveActionsCatalog.deleteFromTrash
	];

	public readonly multiActions: ActionObject[] = [
		this.multiActionsCatalog.restoreFromTrash,
		this.multiActionsCatalog.deleteFromTrash
	];

	public readonly singleActions: ActionObject[] = [
		this.singleActionsCatalog.restoreFromTrash,
		this.singleActionsCatalog.deleteFromTrash
	];

	constructor(
		private massiveActionsCatalog: MassiveActionsCatalog,
		private multiActionsCatalog: MultiActionsCatalog,
		private singleActionsCatalog: SingleActionsCatalog
	) { }

}
