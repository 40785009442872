import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { AuthService } from '@ctel/auth';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';

@Component({
	selector: 'gaw-access-denied',
	templateUrl: './access-denied.component.html',
	styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements  OnDestroy {
	public faIcons = FaIcons;

	constructor(
		private authService: AuthService,
		private renderer: Renderer2
	) {
		this.renderer.addClass(document.body, 'bg-full-screen-image');
	}

	logout() {
		this.authService.logout();
	}

	ngOnDestroy(): void {
		this.renderer.removeClass(document.body, 'bg-full-screen-image');
	}

}
