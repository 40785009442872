import { Component } from '@angular/core';
import { Field } from 'app/shared/components/dry/field';
import { Column } from '../..';
import { HeaderRenderer } from '../header-renderer';

@Component({
	templateUrl: './simple-header.component.html',
	styleUrls: ['./simple-header.component.scss']
})
export class SimpleHeaderComponent implements HeaderRenderer {
	column: Column<Field>;
}
