import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { DocumentsResponse, FiltersResponse } from '@ctel/gaw-commons';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative ai workflow
 */
@Injectable({
	providedIn: 'root'
})
export class DocumentsHttpService implements OnDestroy {

	private jsonContentType = 'application/json';
	// host
	private uiConfigHost: string;
	// endpoints
	private search: string;
	private favoriteFilters: string;
	private FRDocumentiHost: string;
	private headingUrl: string;

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
		public spinnerService: FullScreenSpinnerService
	) {
		this.configService.whenAppConfig().pipe(takeUntil(this.destroy$))
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	httpHostInit(appConfig: AppConfig) {

		this.FRDocumentiHost = appConfig.webConsOrchestrator.http.host;
		/* ENDPOINTS UI CONFIGURATION --------------------------------------------------------------------------------------------------- */
		this.uiConfigHost = appConfig.uiConfiguration.http.host;

		/* ENDPOINTS GAWCONS --------------------------------------------------------------------------------------------------- */

		this.favoriteFilters = `${this.uiConfigHost}/v1/favorites/ui/GAWCONS/filters?sectionCode={sectionCode}&licenseId={licenseId}&siaCode={siaCode}`;

		this.search = `${this.FRDocumentiHost}/v1/FirmaRemota/documenti/ricerca`;
		this.headingUrl = `${this.FRDocumentiHost}/v1/FirmaRemota/lotti/{progSpool}/intestazione`;

	}

	// FAVORITES FILTERS
	whenFavoriteFilters(sectionCode: string, licenseId: string, siaCode: string): Observable<FiltersResponse> {
		return this.configService.whenAppConfig().pipe(
			filter(value => value != null),
			switchMap(() => {
				const options = this.getHttpOptions(this.jsonContentType, 'json');
				const url = this.favoriteFilters
					.replace('{sectionCode}', sectionCode)
					.replace('{licenseId}', licenseId)
					.replace('{siaCode}', siaCode);

				return this.http.get<FiltersResponse>(url, options);
			})
		);
	}

	// RITORNA LISTA DOCUMENTI
	whenAllDocuments(body: string): Observable<DocumentsResponse> {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		const url = this.search;
		return this.http.post<DocumentsResponse>(url, body, options);
	}

	private getHttpOptions(contentType: string, responseType: string): object {
		const headers = new HttpHeaders().set('Content-Type', contentType);
		let options;
		switch (responseType) {
			case 'blob':
				options = {
					headers,
					responseType: 'blob' as const
				};
				break;
			case 'json':
			default:
				options = {
					headers,
					responseType: 'json' as const
				};
				break;
		}
		return options;
	}

	getHeading(progSpool: string): Observable<string> {
		const url = this.headingUrl
			.replace('{progSpool}', progSpool);
		return this.http.get<any>(url).pipe(
			map(data => data.intestazione)
		);
	}
}

