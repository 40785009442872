import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AccountType } from 'app/core/common/account/accountType';
import * as AccountActions from './store/account.actions';
import { AccountState } from './store/account.reducer';
import { getCurrentAccount } from './store/account.selectors';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * Servizio che gestisce lo stato dell'account (ciclo attivo o passivo)
 */
@Injectable({
	providedIn: 'root'
})
export class AccountService {

	private readonly currentAccount$: Observable<AccountType>;
	private currentAccountValue: AccountType;

	constructor(private store: Store<AccountState>) {
		this.currentAccount$ = this.store.pipe(select(getCurrentAccount));
		this.currentAccount$.pipe(
			tap(value => this.currentAccountValue = value)
		).subscribe();
	}

	// accordion aperto (valore CicloAttivo/CicloPassivo)
	whenCurrentAccount(): Observable<AccountType> {
		return this.currentAccount$;
	}

	sendCurrentAccount(value: AccountType) {
		this.store.dispatch(AccountActions.changeAccountType(value));
	}

	getCurrentAccount(): AccountType {
		return this.currentAccountValue;
	}
}
