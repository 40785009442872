import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
	selector: 'dry-code-block',
	templateUrl: './code-block.component.html',
	styleUrls: ['./code-block.component.scss']
})
export class CodeBlockComponent {

	@Input() public asyncCode$?: Observable<unknown>;
	@Input() public code?: string;
	@Input() public language? = 'language-json';

}
