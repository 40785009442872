import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ClientServiceConfigurationResponse } from '@ctel/hubfe-commons';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { ConfigurationFunction } from 'app/entities/grafiche/configuration-function';
import { DocGraphic } from 'app/entities/grafiche/doc-graphic';
import { EMPTY, Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { GraficaHttpService } from './grafica-http.service';

@Injectable({
	providedIn: 'root'
})
export class GraficaService {

	private destroy$ = new Subject<void>();
	graphicServiceFunction$: ReplaySubject<ConfigurationFunction> = new ReplaySubject<ConfigurationFunction>(1);

	constructor(
		private graficaService: GraficaHttpService,
		private companiesService: CompaniesService,
		private router: Router
	) {
		this.companiesService.whenCurrentCompany()
			.pipe(
				switchMap(company =>
					this.getConfigurationFunction(
						company.licenseId,
						company.siaCode,
						35, // GEDFATT
						'F_COPYANAG',
						'GEDINVOICE',
						'GEDANYWAY'
					).pipe(
						take(1)
					)
				)
			).subscribe({
				next: val => this.graphicServiceFunction$.next(val),
				error: (err: unknown) => this.graphicServiceFunction$.error(err)
			});
	}

	public getListaGrafiche(id: string, sia: string) {
		return this.graficaService.getGrafiche(id, sia)
			.pipe(
				catchError((err: unknown) => {
					if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
						//this.router.navigate(['/unauthorized']).then();
					}
					return EMPTY;
				})
			);
	}

	public getGraphicsList(id: string, sia: string): Observable<DocGraphic[]> {
		return this.graficaService.getGraphicsList(id, sia);
	}

	public getConfigurationFunction(
		id: string,
		sia: string,
		tipoCanale: number,
		funzione: string,
		servizio: string,
		contratto: string
	): Observable<ConfigurationFunction> {
		return this.graficaService.clientConfigurationFunction(id, sia, tipoCanale, funzione, servizio, contratto);
	}

	public getGraphicServiceFunction(): Observable<ConfigurationFunction> {
		return this.graphicServiceFunction$.asObservable();
	}

	public gawChannels(id: string, sia: string, grafica: string): Observable<ClientServiceConfigurationResponse> {
		return this.graficaService.gawChannels(id, sia, grafica);
	}

}
