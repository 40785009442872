import { Injectable, OnDestroy } from '@angular/core';
import { FilterStatus } from '@ctel/gaw-commons';
import { DocumentsService2, FilterService2, ScartoCheckboxService, ScartoService } from '@ctel/gawcons-commons';
import { Transfer, TransferService, TransferUtils } from '@ctel/transfer-manager';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, count, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ActionObject } from '../../../../../../../../src/app/entities/ui-config/action/action-object';
import { NotificationType } from '../../../../../../../../src/app/core/common/notification';
import { RemovePdaModalComponent } from '../../../modals/remove-pda-modal/remove-pda-modal.component';
import { NotificationService } from '../../../../../../../../src/app/core/common/notification/notification.service';

/**
 * Callback delle azioni massive
 */
@Injectable({
	providedIn: 'root'
})
export class AllDocumentsCallback implements OnDestroy {

	private readonly _filterPayload$: Observable<FilterStatus>;
	private readonly _totalDocuments$: Observable<number>;
	private concurrentDownloads = 0;

	private destroy$ = new Subject<void>();

	constructor(
		private documentsService: DocumentsService2,
		private filterService: FilterService2,
		private transferService: TransferService,
		private sacrtoService: ScartoService,
		private notificationService: NotificationService
	) {
		this._totalDocuments$ = documentsService.whenTotalDocuments();
		this._filterPayload$ = filterService.whenFilterValue();
		this.transferService.whenTransfers().pipe(
			switchMap(value => of(Object.values<Transfer>(value)).pipe(
				mergeMap(value1 => value1),
				count(v => !TransferUtils.isComplete(v)),
				tap(v => this.concurrentDownloads = v)
			)),
			takeUntil(this.destroy$)
		).subscribe();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	removeMassive(action: ActionObject, extraParams: unknown) {
		return this._totalDocuments$.pipe(
			take(1),
			mergeMap(() => combineLatest([this._filterPayload$])
				.pipe(
					take(1),
					switchMap(([payload]) => this.sacrtoService.validate(JSON.stringify(payload)).pipe(
						map(validateResponse => {
							return {
								payload, validateResponse
							};
						})
					)),
					switchMap(({ payload, validateResponse }) => {
						if (validateResponse.supportiScartabili > 0) {
							return this.sacrtoService.info(JSON.stringify(payload));
						} else {
							this.notificationService.showSweetAlert(NotificationType.ERROR, '', 'Non sono presenti supporti validi');
							return EMPTY;
						}
					}),
					tap({
							next: value => {
								if (value) {
									this.notificationService.showModal(NotificationType.CUSTOM, {
										title: 'Richiesta di scarto',
										childComponent: RemovePdaModalComponent,
										customFooter: true,
										disableClickOutside: true,
										childData: {
											info: value,
											action: this.request
										}
									});
								}
							}
						}
					),
					catchError(err => {
						this.notificationService.showSweetAlert(NotificationType.ERROR, '', 'Errore nella richiesta di scarto');
						return EMPTY;
					})
				)
			)
		);
	}

	request = (licenseId: string, siaCode: string, supportiIds: number[], accept: boolean) => this._filterPayload$.pipe(
		take(1),
		switchMap(payload =>  this.sacrtoService.request(JSON.stringify(payload), accept)),
		tap(value => {
			this.notificationService.showSweetAlert(NotificationType.SUCCESS, '',
				'La richiesta di scarto è stata completata con successo. Riceverà una PEC di conferma con allegata la richiesta di scarto ' +
				'(scaricabile anche dalla voce di menu Richieste di Scarto). Il documento di Richiesta di scarto dovrà essere stampato, ' +
				'completato, sottoscritto e inviato al nostro Responsabile del Servizio di Conservazione (RSC) tramite il pulsante ' +
				'"Invia richiesta di scarto firmata". Sarà nostra premura segnalarvi prontamente eventuali anomalie di compilazione tramite PEC ' +
				'e segnalarvi la variazione nella citata voce di menu "Richieste di Scarto".');
			this.documentsService.refreshDocuments();
		}),
		catchError(err => {
			this.notificationService.showSweetAlert(NotificationType.ERROR, '', 'Errore nella richiesta di scarto');
			return EMPTY;
		})
	);
}
