<!-- LISTA AZIONI-->
<div *hubLoading="actionsLoading; style: { height: '200px' }">
  <ng-container *ngIf="actions$ | async as actions">
    <div class="offset-2 col-md-8 margin-bottom-20 d-grid gap-2">
      <ng-container *ngIf="(isWorkItemLocked$ | async) === false; else itemLocked">
        <button
          *ngFor="let action of actions"
          class="btn btn-info action"
          type="button"
          (click)="executeWorkItemAction(action)"
        >
          {{ action.description }}
        </button>
      </ng-container>
      <ng-template #itemLocked>
        <!-- @formatter: off -->
        <span class="doc-not-found"
          >Azioni momentaneamente non disponibili, documento in attesa di conferma caricamento allegati.</span
        >
        <!-- @formatter: on -->
      </ng-template>
    </div>
  </ng-container>
</div>
