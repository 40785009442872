import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientServiceConfigurationResponse } from '@ctel/hubfe-commons';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { AppConfig } from 'app/entities/config/app-config';
import { ConfigurationFunction } from 'app/entities/grafiche/configuration-function';
import { DocGraphic } from 'app/entities/grafiche/doc-graphic';
import { Grafica } from 'app/entities/grafiche/grafica';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class GraficaHttpService {
	// endpoints
	private validation: string;
	private validationHost: string;
	private hubfeGraphicsEndpoint: string;
	private clientConfigurationHost: string;
	private functionEndpoint: string;
	private gawChannelsEndpoint: string;

	constructor(private http: HttpClient, public configService: ConfigService, public fullScreenSpinnerService: FullScreenSpinnerService) {
		this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.validationHost = appConfig.clientConfiguration.http.host;
		this.validation = `${this.validationHost}/v1/ClientConfiguration/AllGraphics`;
		this.clientConfigurationHost = appConfig.clientConfiguration.http.host;
		this.hubfeGraphicsEndpoint = `${this.clientConfigurationHost}/v1/ClientConfiguration/HubfeGraphics`;
		this.functionEndpoint = `${this.clientConfigurationHost}/v1/ClientConfiguration/Function`;
		this.gawChannelsEndpoint = `${this.clientConfigurationHost}/v1/ClientConfiguration/GawChannels`;
	}

	public getGrafiche(id_: string, sia_: string) {
		const url_ = this.validation;

		const httpOptions = {
			params: {
				id: id_,
				sia: sia_,
			},
		};

		return this.http.get<Grafica[]>(url_, httpOptions);
	}

	public getGraphicsList(license: string, siaCode: string): Observable<any> {
		const url = this.hubfeGraphicsEndpoint;
		const httpOptions = {
			params: {
				id: license,
				sia: siaCode,
			},
		};
		return this.http.get<DocGraphic[]>(url, httpOptions);
	}

	public clientConfigurationFunction(
		id: string,
		sia: string,
		tipoCanale: number,
		funzione: string,
		servizio: string,
		contratto: string
	): Observable<ConfigurationFunction> {
		const url = this.functionEndpoint;
		const httpOptions = {
			params: {
				id,
				sia,
				tipoCanale: tipoCanale.toString(),
				funzione,
				servizio,
				contratto,
			},
		};
		return this.http.get<ConfigurationFunction>(url, httpOptions);
	}

	public gawChannels(id: string, sia: string, grafica: string): Observable<ClientServiceConfigurationResponse> {
		const url = this.gawChannelsEndpoint;
		const httpOptions = {
			params: {
				id,
				sia,
				grafica,
			},
		};
		return this.http.get<ClientServiceConfigurationResponse>(url, httpOptions);
	}
}
