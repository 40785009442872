export interface User {
	/** Nome visualizzto */
	displayName: string;
	/** Email dell'utente */
	email: string;
	/** Nome dell'utente */
	firstName: string;
	/** Id dell'utente */
	id: number;
	/** Indica se l'utente è ancora attivo */
	isActive: boolean;
	/** Cognome dell'utente */
	lastName: string;
	/** Nickname dell'utente */
	nickName: string;
}
