import { Component, Input, Optional, Self } from '@angular/core';
import { FormControlName, NG_VALIDATORS, NgControl } from '@angular/forms';
import { DecimalUtilities } from 'app/core/common/utilities/number/decimal-utilities';
import { NumberValidators } from 'app/shared/components/dry/validator/number-validators';
import { CustomFormControlComponent } from '../../custom-form-control.component';

@Component({
	selector: 'app-number-form-control',
	templateUrl: './number-form-control.component.html',
	styleUrls: ['./number-form-control.component.scss'],
	providers: [
		{ provide: NG_VALIDATORS, useValue: NumberValidators.isNumber(), multi: true }
	]
})
export class NumberFormControlComponent extends CustomFormControlComponent<number> {

	@Input() decimal = 0;

	/** Indica se sanare il dato fino al valore decimale impostato*/
	private _sanitize: boolean;

	constructor(
		@Optional() @Self() public ngControl: NgControl,
		@Optional() public controlName: FormControlName,
	) {
		super(ngControl, controlName);
	}

	@Input() set sanitize(s: boolean | string) {

		if (s === null) {
			this._sanitize = false;
			return;
		}

		if (typeof s === 'boolean') {
			this._sanitize = s;
			return;
		}

		if (typeof s === 'string') {
			this._sanitize = true;
			return;
		}
	}

	sanitizeValue() {
		if (this._sanitize !== null && this.control) {
			const value = this.control.value;
			if (value !== null && DecimalUtilities.checkDecimalLength(value, this.decimal) < 0)
				this.control.setValue(parseFloat(value.toString()).toFixed(this.decimal));

		}

		super.onFocusOut();
	}
}
