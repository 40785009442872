<div class="notes-box px-3 py-4" *ngIf='boxDetails$ | async as boxDetails else noContent'>
	<ng-container *ngIf='boxDetails.note else noContent'>
		{{boxDetails.note}}
	</ng-container>
</div>

<ng-template #noContent>
	<div style='text-align: center'>
		<span><strong>Nessun Risultato</strong></span>
	</div>
</ng-template>
