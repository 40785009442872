import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { Channels } from 'app/entities/channels/channels';
import { AppConfig } from 'app/entities/config/app-config';
import { take } from 'rxjs/operators';

@Injectable()
export class ChannelsHttpService {

	// endpoints
	private validation: string;
	private validationHost: string;
	private clientConfigurationGedHost: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.clientConfigurationGedHost = appConfig.clientConfiguration.http.host;
		this.validationHost = appConfig.clientConfiguration.http.host;
		this.validation = `${this.validationHost}/v2/ClientConfiguration/HubfeMetadatiChannelConfig`;
	}

	public getChannelsGaw(id: string, sia: string, grafica: string) {
		const endpoint = `${this.clientConfigurationGedHost}/v2/ClientConfiguration/MetadataChannelsConfiguration`;

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			params: {
				id,
				sia,
				grafica,
				source: 'All'
			}
		};
		return this.http.get<Channels>(endpoint, httpOptions);
	}

	public getChannelsHub(id_: string, sia_: string, grafica_: string, channelType: string) {
		const url_ = this.validation;

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			params: {
				id: id_,
				sia: sia_,
				grafica: grafica_,
				documentChannelType: channelType
			}
		};

		// TODO ut82488 move to gaw30 business
		return this.http.get<Channels>(url_, httpOptions).pipe(
			take(1)
		);

	}

}
