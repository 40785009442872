<input class="form-control form-control-sm"
	   type="datetime-local"
	   name="{{name}}"
	   [id]="id"
	   [ngClass]="{
	   	'valid': dateTimeInputForm.value && dateTimeInputForm.valid && required
	   }"
       #dateTimeInputForm="ngModel" [(ngModel)]="value" (ngModelChange)="onChangeUpdate()"
	   [ngModelOptions]="ngModelOptions"
	   [popover]="InvalidFormMessage"
	   (focusin)="onFocusIn()"
	   (focusout)="onFocusOut()"
	   triggers=""
	   [required]="required"
	   [disabled]="disabled"
	   [readonly]="readonly"
	   [isDate]="format"
	   [composeValidators]="_validators"
	   [composeAsyncValidators]="_asyncValidators"/>
<ng-template #InvalidFormMessage>
	<ul *ngIf="dateTimeInputForm.invalid" class="invalid-form-msg">
		<ng-container *ngFor="let error of getErrorMessage(dateTimeInputForm)">
			<li>
				{{error}}
			</li>
		</ng-container>
	</ul>
</ng-template>
