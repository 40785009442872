import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { InvoiceFields } from 'app/entities/invoice-pa/invoice-fields';

@Component({
	selector: 'app-invoice-modal',
	templateUrl: './invoice-modal.component.html',
	styleUrls: ['./invoice-modal.component.scss']
})
export class InvoiceModalComponent {

	@Input() modalTitle: string;
	@Input() modalId: string;
	@Input() fields: InvoiceFields[];
	@Input() parentForm: UntypedFormGroup;

}
