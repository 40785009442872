import { Injectable } from '@angular/core';
import { Transfer, TransferUtils } from '@ctel/transfer-manager';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { AppError } from 'app/core/common/error/app-error';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { ExcelFormatModalComponent } from 'app/core/common/modals/excel-format-modal/excel-format-modal.component';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { ActionConfigItem } from 'app/entities/ui-config/action/actions-config';
import { saveAs } from 'file-saver';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { HistoryActionModalComponent } from '../../modals/history-action-modal.component/history-action-modal.component';
import { TrackingProviderModalComponent } from '../../modals/tracking-provider-modal.component/tracking-provider-modal.component';
import { CheckboxService } from '../checkbox/checkbox.service';
import { DocumentsSeriesService } from '../documents-series/documents-series.service';
import { ActionsHttpService } from './actions-http.service';
import { ActionsService2 } from './actions.service';

/**
 * Servizio che gestisce l'esecuzione delle azioni sui documenti
 */
@Injectable({
	providedIn: 'root',
})
export class ExecuteActionsService {
	public readonly gedPecEndpoint =
		'/v1/Document/GEDPEC/ModificaPec?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&indirizzoPec={mail}';
	private readonly gedMailEndpoint =
		'/v1/Document/GEDMAIL/ModificaMail?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&indirizzoMail={mail}';

	constructor(
		private actionsHttpService: ActionsHttpService,
		private notificationService: NotificationService,
		private actionsService: ActionsService2,
		private documentsSeriesService: DocumentsSeriesService,
		private checkboxService: CheckboxService,
		private companiesService: CompaniesService,
	) { }
	public whenLoadingAction(): Observable<boolean> {
		return this.actionsHttpService.whenLoadingAction();
	}

	// ESECUZIONE AZIONE SINGOLA
	public executeSingleAction(action: ActionObject | ActionConfigItem, keys: any) {
		let actionCode = '';
		let actionUrl = '';

		if (action instanceof ActionObject) {
			actionCode = action.actionCode;
			actionUrl = action.url;
		} else {
			actionCode = action.actionCode;
			actionUrl = action.actionUrl;
		}

		const basicPayload = {
			progSpool: keys.progSpool,
			progBusta: keys.progBusta,
			docHash: keys.hashDocKey,
			ctelElasticDocumentId: keys.ctelElasticDocumentId,
			idComunicazione22: keys.idComunicazione22,
			// TODO check keys after configuration
			latitude: keys.coordinateGPSLatitudine06,
			longitude: keys.coordinateGPSLongitudine06,
			indirizzoDestinatario06: keys.indirizzoDestinatario06,
			indirizzoRitorno06: keys.indirizzoRitorno06,

			// Parametri per Tracking Provider
			idComunicazione06: keys.idComunicazione06,
			codiceRecapitista06: keys.codiceRecapitista06,
			cultureId: keys.cultureId,
		};

		switch (actionCode) {
			// GAWPOST SINGLE ACTIONS
			case ActionCode.SHOW_STATUS_HISTORY:
				this.actionsHttpService.whenChangesHistory(
					actionUrl, basicPayload.progSpool, basicPayload.progBusta, basicPayload.idComunicazione06
				).pipe(
					take(1),
					catchError((err: unknown) => this.catchError(err as AppError))
				).subscribe((result) => {
					if (result) {
						let title = '';

						switch (actionCode) {
							case ActionCode.SHOW_STATUS_HISTORY:
								title = 'Storico stati';
								this.actionsService.sendHistory(result);
								this.notificationService.showModal(NotificationType.LARGE, {
									title,
									customFooter: false,
									childComponent: HistoryActionModalComponent,
									childData: basicPayload,
									disableClickOutside: false,
								});
								break;
						}
					} else
						this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Storico non trovato');

				});
				break;

			case ActionCode.DOWNLOAD_PDF:
				this.actionsHttpService.whenSinglePdf(actionUrl, keys.progSpool, keys.progBusta, keys.hashDocKey)
					.pipe(
						take(1),
						catchError((err: unknown) => this.catchError(err as AppError))
					).subscribe((result) => {
						if (result !== null) {
							const r = result as Transfer;
							if (TransferUtils.isHttpResponse(r.originatingEvent)) {
								const blob = new Blob([r.originatingEvent.body], { type: 'application/pdf' });
								new FileSaver(blob).saveAs(`${basicPayload.idComunicazione06}.principale`, FileSaverExtension.PDF);
							} else
								this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');

						} else
							this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');

					});
				break;

			case ActionCode.DOWNLOAD_ATTACHMENTS:
				this.actionsHttpService.whenAttachments(actionUrl, basicPayload.idComunicazione06)
					.pipe(
						take(1),
						catchError((err: unknown) => this.catchError(err as AppError))
					).subscribe((result) => {
						if (result !== null) {
							const r = result as Transfer;
							if (TransferUtils.isHttpResponse(r.originatingEvent)) {
								const blob = new Blob([r.originatingEvent.body], { type: 'application/pdf' });
								new FileSaver(blob).saveAs(`${basicPayload.idComunicazione06}.AllegatiAggiuntivi.pdf`, FileSaverExtension.PDF);
							} else
								this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');

						} else
							this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');

					});
				break;
			case ActionCode.TRACKING_PROVIDER: {
				const data = {
					idLicenza: this.companiesService.getCurrentCompanyValue().licenseId,
					codSia: this.companiesService.getCurrentCompanyValue().siaCode,
					idComunicazione: basicPayload.idComunicazione06,
					codiceRecapitista: basicPayload.codiceRecapitista06,
					cultureId: 'IT',
				};

				this.actionsHttpService.whenTrackingProvider(actionUrl, data)
					.pipe(
						take(1),
						catchError((err: unknown) => this.catchError(err as AppError))
					).subscribe((result) => {
						if (result) {
							let title = '';

							switch (actionCode) {
								case ActionCode.TRACKING_PROVIDER:
									title = 'Ricerca stato raccomandata';
									this.notificationService.showModal(NotificationType.LARGE, {
										title,
										customFooter: false,
										childComponent: TrackingProviderModalComponent,
										childData: result,
										disableClickOutside: false,
									});
									break;
							}
						} else
							this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Stato raccomandata non trovato');

					});
				break;
			}

			case ActionCode.VIEW_MAP: {
				const latitude = basicPayload.latitude;
				const longitude = basicPayload.longitude;
				const address = basicPayload.indirizzoDestinatario06;
				const zoom = '12';

				if (!latitude && !longitude && !address)
					this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Nessuna informazione relativa all\'indirizzo del destinatario');

				let googleUrl = 'https://www.google.com/maps/';
				if (latitude && longitude)
					googleUrl = `${googleUrl}@?api=1&map_action=map&center=${latitude},${longitude}&zoom=${zoom}`;
				else
					googleUrl = `${googleUrl}search/?api=1&query=${address}`;

				window.open(googleUrl);

				break;
			}

			default:
				console.warn('ACTION non configurata', actionCode);
				break;
		}
	}

	downloadFile(result, fileName) {
		if (result !== null) {
			const blob = new Blob([result], { type: 'application/octet-stream' });
			saveAs(blob, fileName);
		} else
			this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');

	}

	// ESECUZIONE AZIONI MULTIPLE
	public executeMultiAction(action: ActionObject, keys: any[], sectionCode?: string) {
		const docCount = keys.length;

		const actionCode = action.actionCode;
		// let actionCode = '';
		// let actionUrl = '';

		// if (action instanceof ActionObject) {
		// 	actionCode = action.actionCode;
		// 	actionUrl = action.url;
		// } else {
		// 	actionCode = action.actionCode;
		// 	actionUrl = action.actionUrl;
		// }

		switch (actionCode) {
			// massive/multi
			case ActionCode.DOWNLOAD_EXCEL: {
				// Modal della scelta del formato dell'excel
				const showModal = () => {
					this.notificationService.showModal(NotificationType.GENERAL, {
						title: 'Esporta risultati in Excel',
						customFooter: true,
						childComponent: ExcelFormatModalComponent,
						childData: {
							backButton: true,
							action,
							actionType: 'multiple',
							docSeriesDescription: this.documentsSeriesService.getDocSeriesLabel(),
							keys,
							service: 'GAWPOST',
							sectionCode,
						},
					});
				};

				if (docCount > 50000)
					this.notificationService.showSweetAlert(
						NotificationType.QUESTION,
						'Numero massimo di documenti superato.',
						'Procedere all\'esportazione dei primi 50000 documenti?',
						showModal
					);
				else
					showModal();

				break;
			}
		}
	}

	private catchError(err: AppError): Observable<null> {
		if (err.type === ErrorTypes.HTTP_UNAUTHORIZED)
			//this.router.navigate(['/unauthorized']).then();
			return of(null);
		else
			return of(null);

	}
}
