import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../../entities/config/app-config';
import { ConfigService } from '../../../common/config/config.service';
import { CustomHttpOptions } from '../../../common/utilities/custom-http-options';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PermissionHttpService {
	private profileHost: string;
	private permessiUrl: string;
	private jsonOptions: object;
	private readonly jsonContentType = 'application/json';

	constructor(private http: HttpClient, private configService: ConfigService) {
		this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
		this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
	}

	getPermissions(userId: string, licenseId: string, siaCode: string): Observable<string[]> {
		const url = this.permessiUrl.replace('{userId}', userId)
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<any>(url).pipe(
			map(value => value.permessi.map(perm => perm.codice)),
			catchError(() => of(null))
		);
	}

	private httpHostInit(appConfig: AppConfig) {
		this.profileHost = appConfig.profileRead.http.host;
		this.permessiUrl = `${this.profileHost}/v1/permessi/userId/{userId}/licenza/{licenseId}/sia/{siaCode}`;
	}
}
