import { IRouterStateUrl } from '@ctel/search-filter-store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

export const appReducers: ActionReducerMap<AppState> = {
	router: routerReducer
};

/**
 * Class definitoria dello stato applicativo globale.
 */
export interface AppState {
	router: RouterReducerState<IRouterStateUrl>;
}
