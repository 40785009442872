import { Component, OnDestroy } from '@angular/core';
import { NotificationEventTypes } from 'app/core/common/notification/notification-event-types';
// import { NotificationEventTypes } from '../event/event-type';
import { environment } from 'environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { EventData } from '../event/event-data';
import { EventService } from '../event/event.service';
import { ModalService } from './modal/modal.service';
import { SnackBarService } from './snackbar/snack-bar.service';
import { SweetAlertService } from './sweet-alert/sweet-alert.service';
import { ToastService } from './toast/toast.service';

@Component({
	selector: 'dry-notification-host',
	template: `
        <div>
            <ctel-snackbar [position]="'bottom-center'" [max]="3" [timeout]="6000"></ctel-snackbar>
        </div>`
})
export class NotificationHostComponent implements OnDestroy {

	private destroy$ = new Subject<void>();

	constructor(
		private eventService: EventService,
		private toastService: ToastService,
		private sweetAlertService: SweetAlertService,
		private modalService: ModalService,
		private snackbarService: SnackBarService
	) {

		// Notifications
		this.eventService.get(NotificationEventTypes.E_ALERT_TOAST)
			.pipe(takeUntil(this.destroy$))
			.subscribe(x => {
				if (!environment.production)
					this.onToastAlert(x);
			});
		this.eventService.get(NotificationEventTypes.E_ALERT_SWEET)
			.pipe(takeUntil(this.destroy$))
			.subscribe(x => this.onSweetAlert(x));
		this.eventService.get(NotificationEventTypes.E_ALERT_SWEET_CLOSE)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.onSweetAlertClose());
		this.eventService.get(NotificationEventTypes.E_MODAL)
			.pipe(takeUntil(this.destroy$))
			.subscribe(x => this.onNotificationModal(x));
		this.eventService.get(NotificationEventTypes.E_SNACKBAR)
			.pipe(takeUntil(this.destroy$))
			.subscribe(x => this.onSnackbar(x));
		this.eventService.get(NotificationEventTypes.E_SNACKBAR_CLOSE)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.onSnackbarClose());
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private onToastAlert(data: EventData): void {
		this.toastService.show(data.data);
	}

	private onSweetAlert(data: EventData): void {
		this.sweetAlertService.show(data.data);
	}

	private onSweetAlertClose(): void {
		this.sweetAlertService.close();
	}

	private onNotificationModal(data: EventData): void {
		this.modalService.show(data.data);
	}

	private onSnackbar(data: EventData): void {
		this.snackbarService.show(data.data);
	}

	private onSnackbarClose(): void {
		this.snackbarService.clear();
	}

}
