import { Component, Input } from '@angular/core';
import { FaIcons } from '../../../../../../../src/app/entities/fa-icons/fa-icons';
import * as _ from 'lodash';
import { Upload } from '../../../../../../../src/app/entities/drag-and-drop-upload/upload';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationType } from '../../../../../../../src/app/core/common/notification';
import { NotificationService } from '../../../../../../../src/app/core/common/notification/notification.service';
import { RichiesteScartoExecuteActionsService } from '@ctel/gawcons-commons';

@Component({
	selector: 'gaw-upload-request-modal',
	templateUrl: './upload-request-modal.component.html',
	styleUrls: ['./upload-request-modal.component.scss']
})
export class UploadRequestModalComponent {
	@Input() modal: BsModalRef;
	@Input() data: {
		rssId: string,
		licenseId: string,
		siaCode: string,
	};

	protected readonly faIcons = FaIcons;
	public currentUpload: Upload;
	fileName: string;
	file: File;
	fileLoaded: boolean;
	public classHovered = 'jumbotron uploadPage';
	public dropzoneActive = false;

	constructor(private notificationService: NotificationService, private executeAction: RichiesteScartoExecuteActionsService) {
	}


	handleDrop(fileList: FileList) {
		const filesIndex = _.range(fileList.length);
		_.each(filesIndex, (idx) => {
				this.currentUpload = new Upload(fileList[idx]);
			}
		);

		const file: File = fileList[0];
		if (!file)
			return Promise.resolve();

		this.createUpload(file);
	}

	private checkFile(file: File): boolean {

		// Check file extension
		if (this.checkFileExt(file, 'pdf'))
			return true;
		else {
			this.notificationService.showSweetAlert(
				NotificationType.ERROR, 'Il file deve avere estensione .pdf', '');
			return false;
		}
	}

	private checkFileExt(file: File, ext: string): boolean {
		const fileEx = file.name.split('.').pop().toLowerCase();
		return fileEx === ext;
	}

	dropzoneState($event: boolean) {
		if (!$event)
			this.classHovered = 'jumbotron uploadPage';
		else
			this.classHovered = 'jumbotron boxHovered';

		this.dropzoneActive = $event;
	}

	private createUpload(file: File): void {
		if (this.checkFile(file)) {
			this.fileName = file.name;
			this.file = file;
			this.fileLoaded = true;
		} else {
			this.fileName = undefined;
			this.file = undefined;
			this.fileLoaded = false;
		}
	}

	public onFileChange(e: unknown): Promise<void> {

		const file = e['target']?.files[0];
		if (!file)
			return Promise.resolve();

		this.createUpload(file);
	}

	removeUpload() {
		this.fileName = null;
		this.fileLoaded = false;
	}

	onUpload() {
		this.executeAction.uploadRequest(this.file, this.data.licenseId, this.data.siaCode, this.data.rssId).subscribe(value => this.modal.hide());
	}
}
