import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCommonModule } from 'app/core/common/app-common.module';
import { TableOfContentsComponent } from 'app/shared/components/table-of-contents/table-of-contents.component';

@NgModule({
	imports: [
		AppCommonModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule
	],
	declarations: [
		TableOfContentsComponent
	],
	exports: [
		TableOfContentsComponent
	]
})
export class TableOfContentsModule {
}
