import { PlatformLocation } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionsService, DocumentDetailsService } from '@ctel/hubfe-commons';
import { ErrorTypes } from 'app/core/common/error';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { HttpHeadersFilename } from 'app/core/common/utilities/http-headers-filename';
import { SectionCode } from 'app/entities/ui-config/classification-code.enum';
import { DocumentAttachmentPdf } from 'app/shared/components/display-document/document-attachment-pdf';
import 'moment/locale/it';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject, of } from 'rxjs';
import { catchError, first, take } from 'rxjs/operators';
import { ActionCrudResponse } from 'app/entities/ui-config/action/action-crud-response';

/**
 * Componente modal per visualizzazione pdf originale / da foglio di stile custom / da foglio di stile ade
 */
@Component({
	selector: 'hub-action-original-pdf-modal',
	templateUrl: './action-original-pdf-modal.component.html',
	styleUrls: ['./action-original-pdf-modal.component.scss']
})
export class ActionOriginalPdfModalComponent implements OnInit, OnDestroy {

	@Input() title: string;
	@Input() data: any;
	@Input() closeOnClickOutside: boolean;
	@Input() modal: BsModalRef;

	public isPdfOriginalOrCustomPresent$: Observable<ActionCrudResponse>;
	public isPdfOriginalOrCustomPresent: ActionCrudResponse = { esitoOK: false, message: '' };
	public originalPdf: DocumentAttachmentPdf = null;
	public adePdf: DocumentAttachmentPdf = null;

	private destroy$ = new Subject<void>();

	constructor(
		private documentDetailsService: DocumentDetailsService,
		private actionsService: ActionsService,
		private router: Router,
		private notificationService: NotificationService,
		private location: PlatformLocation,
	) {
		location.onPopState(() => this.modal.hide());
	}

	ngOnInit(): void {
		const account = this.data.account === 'payable' ? 'PASSIVO' : 'ATTIVO';
		// controllo se c'è il pdf originale o quello custom (solo per ciclo attivo, nel passivo c'è sempre)
		if (this.data.account === SectionCode.RECEIVABLE) {
			this.isPdfOriginalOrCustomPresent$ = this.actionsService.whenIsPdfOriginalOrCustomPresent(
				'GEDINVOICE',
				this.data.progSpool,
				this.data.progBusta,
				this.data.docHash,
				account
			).pipe(
				take(1)
			);

			this.isPdfOriginalOrCustomPresent$
				.pipe(
					take(1)
				)
				.subscribe(
					response => {
						// se c'è il pdf originale o quello custom, faccio la chiamata allo stesso endpoint del dettaglio documento
						if (response.esitoOK === true) {
							this.isPdfOriginalOrCustomPresent = { esitoOK: true, message: '' };
							this.originalPdf = new DocumentAttachmentPdf(
								'PDF ORIGINALE / DA FOGLIO DI STILE CUSTOM',
								this.documentDetailsService.whenPdfReceivable(
									'35', this.data.progSpool, this.data.progBusta, this.data.docHash, false, '50')
							);
						} else
							//	se non c'è il pdf originale o custom, faccio la chiamata per avere quello ade
							if (this.adePdf === null)
								this.adePdf = new DocumentAttachmentPdf(
									'PDF DA FOGLIO DI STILE ADE',
									this.actionsService.whenDisplayAdeDocument(account, 'GEDINVOICE', this.data.progSpool,
										this.data.progBusta, this.data.docHash, false)
								);

					}
				);
		} else {
			// per il passivo è sempre presente pdf originale o custom
			this.isPdfOriginalOrCustomPresent$ = new Observable(obs => {
				obs.next({ esitoOK: true, message: '' });
			});
			this.isPdfOriginalOrCustomPresent = { esitoOK: true, message: '' };
			this.originalPdf = new DocumentAttachmentPdf(
				'PDF ORIGINALE / DA FOGLIO DI STILE CUSTOM',
				this.documentDetailsService.whenPdfPayable(
					'35', this.data.progSpool, this.data.progBusta, this.data.docHash, false, '50')
			);
		}
	}

	// click seconda tab
	openAdePdf(): void {
		// se è il secondo tab faccio la chiamata
		if (this.isPdfOriginalOrCustomPresent.esitoOK === true) {
			if (this.adePdf === null) {
				const account = this.data.account === 'payable' ? 'PASSIVO' : 'ATTIVO';
				this.adePdf = new DocumentAttachmentPdf(
					'PDF DA FOGLIO DI STILE ADE',
					this.actionsService.whenDisplayAdeDocument(account, 'GEDINVOICE', this.data.progSpool,
						this.data.progBusta, this.data.docHash, false)
				);
			}
		} else
			// 	se è il primo tab al click non devo fare nulla
			return;

	}

	// download pdf ade
	downloadAdePdf(): void {
		const account = this.data.account === 'payable' ? 'PASSIVO' : 'ATTIVO';
		this.actionsService.whenDisplayAdeDocument(account, 'GEDINVOICE', this.data.progSpool,
			this.data.progBusta, this.data.docHash, true)
			.pipe(
				first(),
				catchError((err: unknown) => {
					if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND)
						return of(null);

					if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED)
						//this.router.navigate(['/unauthorized']).then();
						return of(null);

				}),
			)
			.subscribe(
				(result: any) => {
					if (result !== null) {
						const fileName = HttpHeadersFilename.getFilenameFromHttpHeaders(result);
						const blob = new Blob([result.body], { type: 'application/pdf' });
						new FileSaver(blob).saveAs(fileName, FileSaverExtension.PDF);
					} else
						this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');

				}
			);
	}

	// download pdf originale o custom
	downloadOriginalPdf(): void {
		// uso receivable perchè va bene anche per il passivo.
		if (this.data.account === SectionCode.RECEIVABLE)
			this.documentDetailsService.whenPdfReceivable(
				'35', this.data.progSpool, this.data.progBusta, this.data.docHash, true, '50')
				.pipe(
					first(),
					catchError((err: unknown) => {
						if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND)
							return of(null);

						if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED)
							//this.router.navigate(['/unauthorized']).then();
							return of(null);

					}),
				)
				.subscribe(
					(result:any) => {
						if (result !== null) {
							const fileName = HttpHeadersFilename.getFilenameFromHttpHeaders(result);
							const blob = new Blob([result.body], { type: 'application/pdf' });
							new FileSaver(blob).saveAs(fileName, FileSaverExtension.PDF);
						} else
							this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');

					}
				);
		else
			this.documentDetailsService.whenPdfPayable(
				'35', this.data.progSpool, this.data.progBusta, this.data.docHash, true, '50')
				.pipe(
					first(),
					catchError((err: unknown) => {
						if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND)
							return of(null);

						if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED)
							//this.router.navigate(['/unauthorized']).then();
							return of(null);

					}),
				)
				.subscribe(
					(result:any) => {
						if (result !== null) {
							const fileName = HttpHeadersFilename.getFilenameFromHttpHeaders(result);
							const blob = new Blob([result.body], { type: 'application/pdf' });
							new FileSaver(blob).saveAs(fileName, FileSaverExtension.PDF);
						} else
							this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');

					}
				);

	}

	ngOnDestroy() {
		this.originalPdf = null;
		this.adePdf = null;
		this.destroy$.next();
		this.destroy$.complete();
	}

}
