import { TableAction } from 'app/shared/components/table/actions/table-action';

export enum TableActionStatusEnum {
	LOADED,
	LOADING,
	ERROR
}

export class TableActionStatus {
	status: TableActionStatusEnum = TableActionStatusEnum.LOADED;

	constructor(public action: TableAction) {}
}
