import { PlatformLocation } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CardsHttpService, DocumentDetailsService, DocumentMetadata, Metadata } from '@ctel/hubfe-commons';
import { ChannelsInvoice } from 'app/constants/channels-invoice/channels-invoice';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { GraficaService } from 'app/core/business/grafiche/grafica.service';
import { ChannelsService } from 'app/core/business/invoice-pa/channels/channels.service';
import { EditInvoiceService } from 'app/core/business/invoice-pa/create-edit-invoice/edit-invoice/edit-invoice.service';
import { UploadDocumentService } from 'app/core/business/invoice-pa/upload-documents/upload-document-pa/upload-document.service';
import { CessionarioCommittenteKeyValue } from 'app/core/common/actions/send-analogic-copy/cessionario-committente-key-value';
import { SendAnalogicCopyHttpService } from 'app/core/common/modals/send-analogic-copy-modal/send-analogic-copy-http.service';
import { SendAnalogicCopyService } from 'app/core/common/modals/send-analogic-copy-modal/send-analogic-copy.service';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { Channels } from 'app/entities/channels/channels';
import { ConfigurationFunction } from 'app/entities/grafiche/configuration-function';
import { MetadataEntity } from 'app/entities/invoice-pa/edit-invoice/edit-invoice';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, from, of } from 'rxjs';
import { filter, find, map, mergeMap, reduce, share, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { SendAnalogicCopyData } from './send-analogic-copy-data';

@Component({
	templateUrl: './send-analogic-copy-modal.component.html',
	styleUrls: ['./send-analogic-copy-modal.component.scss'],
	providers: [SendAnalogicCopyService, SendAnalogicCopyHttpService]
})
export class SendAnalogicCopyModalComponent implements AfterViewInit, OnDestroy {
	@Input() modal: BsModalRef;

	data: SendAnalogicCopyData;

	// Lista dei metadati in base al canale selezionato
	metadataList$: Observable<MetadataEntity[]>;
	// Lista dei metadati della premulticanalità
	premultiMetadataList$: Observable<MetadataEntity[]>;
	// Lista dei canali disponibili
	channels$: Observable<{
		name: string,
		id: number,
	}[]>;
	getConfigurationFunction$: Observable<ConfigurationFunction>;
	selectedChannel$: BehaviorSubject<{
		name: string,
		id: number
	}> = new BehaviorSubject(null);

	private destroy$ = new Subject<void>();

	private readonly FUNCTION_NAME = 'F_COPYANAG';
	private readonly SERVICE = 'GEDINVOICE';
	private readonly CONTRACT = 'GEDANYWAY';

	constructor(
		private location: PlatformLocation,
		private editInvoiceService: EditInvoiceService,
		private companiesService: CompaniesService,
		private graficaService: GraficaService,
		private documentDetailsService: DocumentDetailsService,
		private uploadDocumentService: UploadDocumentService,
		private cardsHttpService: CardsHttpService,
		private channelsService: ChannelsService,
		private notificationService: NotificationService,
		private sendAnalogicCopyService: SendAnalogicCopyService
	) {
		location.onPopState(() => this.modal.hide());
	}

	ngAfterViewInit(): void {

		/* Logica spostata nel `ngAfterViewInit` per via dell'errore di `ExpressionChangedAfterItHasBeenCheckedError` */
		// Ottiene l'array di grafiche disponibili
		this.getConfigurationFunction$ = this.graficaService.getGraphicServiceFunction().pipe(
			share({ connector: () => new ReplaySubject<ConfigurationFunction>(1) })
		);

		// Lista di canali disponibili per l'Invio copia analogica
		this.channels$ = this.getConfigurationFunction$
			.pipe(
				switchMap(configFunc => from(configFunc.graphics)
					.pipe(
						mergeMap(graphic =>
							this.companiesService.whenCurrentCompany().pipe(
								take(1),
								switchMap(company =>
									this.cardsHttpService.whenChannels(company.licenseId, company.siaCode, graphic, 'ATTIVO')
										.pipe(
											take(1),
											map(clientServiceConfig => clientServiceConfig.tipoCanale),
											mergeMap(service => from(service)
												.pipe(
													map(channel => ({
														name: channel.channelName,
														id: channel.channelId
													}))
												)
											)
										)
								),
								reduce((acc, val) => [...acc, val], [])
							)
						)
					)
				),
				share({
					connector: () => new ReplaySubject<{
						name: string,
						id: number,
					}[]>(1)
				})
			);

		// Lista dei metadati della premulticanalità
		this.premultiMetadataList$ = this.editInvoiceService
			.getDati(this.data.documentId.docHash, Number(this.data.documentId.progSpool), Number(this.data.documentId.progBusta))
			.pipe(
				take(1),
				map(editInvoice => editInvoice.listChannelDocumentDataViewGed),
				mergeMap(channels => from(channels).pipe(
					find(c => c.channelGedView.channelName.headerLabel.toLowerCase() === 'premulticanalita'),
					filter(c => c !== undefined),
				)),
				map(c => c.metadataEntity),
			);

		// Lista dei metadati disponibili
		const metadatiChannelConfig$: Observable<Channels> = this.getConfigurationFunction$.pipe(
			take(1),
			mergeMap(config => from(config.graphics)
				.pipe(
					switchMap(grafic =>
						this.companiesService.whenCurrentCompany().pipe(
							take(1),
							switchMap(company =>
								this.channelsService.getChannelsHub(company.licenseId, company.siaCode, grafic, 'ATTIVO')
									.pipe(
										take(1)
									)
							)
						)
					)
				)
			),
			share({ connector: () => new ReplaySubject<Channels>(1) })
		);

		const cessCommittData$: Observable<CessionarioCommittenteKeyValue[]> = this.sendAnalogicCopyService.getCessionarioCommittente(
			Number(this.data.documentId.progSpool),
			Number(this.data.documentId.progBusta),
			this.data.documentId.docHash
		).pipe(
			share({ connector: () => new ReplaySubject<CessionarioCommittenteKeyValue[]>(1) })
		);

		// Estraggo i metadati in base al canale selezionato
		this.metadataList$ = metadatiChannelConfig$.pipe(
			switchMap(channelConfig => this.selectedChannel$
				.pipe(
					filter(selectedChannel => !!(selectedChannel && selectedChannel.name)),
					switchMap(selectedChannel => {
						// Se il canale è gedpost, faccio la richiesta per ottenere il cessionario committente
						if (
							selectedChannel
							&& selectedChannel.name
							&& selectedChannel.name.toLowerCase() === ChannelsInvoice.GEDPOST.toLowerCase()
						)
							return combineLatest([of(selectedChannel), cessCommittData$]);

						// Altrimenti ritorno un oggetto vuoto
						return combineLatest([of(selectedChannel), of([])]);
					}),
					mergeMap(([selectedChannel, cessCommData]) => from(channelConfig.listMetadatiGed)
						.pipe(
							// Prendo i metadati che fanno parte del canale selezionato
							filter(metadato =>
								metadato.channelReference.channelName.headerLabel.toLowerCase() === selectedChannel.name.toLowerCase()
							),
							// Se il canale è `GEDPOST` precompilo il form inserendo nel campo 'valore' il valore
							// effettivo
							map(metadato => {

								if (
									selectedChannel
									&& selectedChannel.name
									&& selectedChannel.name.toLowerCase() === ChannelsInvoice.GEDPOST.toLowerCase()) {
									const cessCommMetadato: CessionarioCommittenteKeyValue =
										cessCommData.find(data => data.nomeCampo === metadato.nomeCampo);
									metadato.valore = cessCommMetadato ? cessCommMetadato.value : '';
								}
								return metadato;
							}),
							reduce((acc, val) => [...acc, val], [])
						)
					)
				)
			)
		);

	}

	onSelectedChannel(value: string) {
		this.channels$
			.pipe(
				take(1),
				map(channels => {
					const channel = channels.find(c => c.name.toLowerCase() === value.toLowerCase());

					return channel ? channel : null;
				}),
			).subscribe(c => this.selectedChannel$.next(c));
	}

	onFormSubmit(form: NgForm) {

		combineLatest([this.premultiMetadataList$, this.metadataList$])
			.pipe(
				take(1),
				// Unisco i due array di 'MetadataEntity[]'
				map(([premultiMetadata, channelMetadata]) => [...premultiMetadata, ...channelMetadata]),
				// Creo uno stream dall'array
				mergeMap((metadata: MetadataEntity[]) => from(metadata)
					.pipe(
						// Creo un oggetto di tipo 'Metadata'
						map(m => {
							const metadato: Metadata = {};
							metadato[m.idChiave] = {
								nome: m.nomeCampo,
								valore: form.value[m.nomeCampo]
							};
							return metadato;
						}),
						reduce((acc, val) => {
							/* Creo un oggetto chiave-valore.
							La chiavi corrispondono alle chiavi di ogni singolo metadato. */
							Object.keys(val).forEach(key => {
								acc[key] = val[key];
							});
							return acc;
						}, {})
					)
				),
				switchMap((metadataObject: Metadata) =>
					combineLatest([this.getConfigurationFunction$, this.companiesService.whenCurrentCompany()])
						.pipe(
							take(1),
							map(([configFunc, company]) => <DocumentMetadata>{
								grafica: configFunc.graphics[0],
								id: company.licenseId,
								sia: company.siaCode,
								metadata: metadataObject
							})
						)
				),
				withLatestFrom(this.selectedChannel$),
				switchMap(([documentMetadata, selectedChannel]) => this.uploadDocumentService.sendCopiaAnalogica(
					this.data.documentId.docHash,
					this.data.documentId.progSpool,
					this.data.documentId.progBusta,
					this.data.documentId.ctelElasticDocumentId,
					this.data.documentId.ctelDocSeriesId,
					Number(selectedChannel.id),
					'SequenceNumberMatchingKey',
					documentMetadata).pipe(take(1))
				),
				take(1),
			).subscribe({
				next: response => {
					if (response.success) {
						this.notificationService.showSweetAlert(
							NotificationType.SUCCESS,
							'Azione eseguita correttamente',
							response.message);

						this.modal.hide();
					} else
						this.notificationService.showSweetAlert(
							NotificationType.ERROR,
							'Errore',
							response.message);

				},
				error: (err: unknown) => {
					this.notificationService.showSweetAlert(
						NotificationType.ERROR,
						'Errore',
						err?.['type'].toString());
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
