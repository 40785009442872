import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AnomalyStatusHistoryItem } from 'app/entities/anomalies/anomaly-status-history-item';
import { AnomaliesStatusHistoryHttpService } from './anomalies-status-history-http.service';

@Injectable({
	providedIn: 'root',
})
export class AnomaliesStatusHistoryService implements OnDestroy {
	private statusHistory$ = new ReplaySubject<AnomalyStatusHistoryItem[]>(1);
	private statusHistoryError$ = new BehaviorSubject<boolean>(false);
	private destroy$ = new ReplaySubject<void>(1);

	constructor(private historyHttpService: AnomaliesStatusHistoryHttpService) { }

	whenStatusHistory(idSospeso: string) {
		return this.historyHttpService.whenStatusHistory(idSospeso).pipe(
			catchError(() => {
				this.sendStatusHistoryError(true);
				return EMPTY;
			}),
			tap((statusHistory) => {
				this.sendStatusHistoryError(false);
				this.sendStatusHistory(statusHistory);
			})
		);
	}

	sendStatusHistory(statusHistory: AnomalyStatusHistoryItem[]) {
		this.statusHistory$.next(statusHistory);
	}

	sendStatusHistoryError(value: boolean) {
		this.statusHistoryError$.next(value);
	}

	whenStatusHistoryError(): Observable<boolean> {
		return this.statusHistoryError$.asObservable();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
