import { Component } from '@angular/core';

@Component({
	selector: 'app-document-list-placeholder',
	templateUrl: './document-list-placeholder.component.html',
	styleUrls: ['./document-list-placeholder.component.scss']
})
export class DocumentListPlaceholderComponent {

}
