import { EventEmitter, Injectable } from '@angular/core';
import { EventData } from './event-data';
import { EventDictionary } from './event-dictionary';
import { EventType } from './event-type';

@Injectable()
export class EventService {

	private events: EventDictionary;

	public emit(type: EventType, data?: EventData): void {
		const em = this.getEventEmitter(type);
		em.emit(data);
	}

	public get(type: EventType): EventEmitter<any> {
		return this.getEventEmitter(type);
	}

	private getEventEmitter(type: EventType): EventEmitter<any> {

		if (!this.events)
			this.events = new EventDictionary([]);

		let em = this.events.get(type.toString());

		if (!em) {
			em = new EventEmitter();
			this.events.add(type.toString(), em);
		}

		return em;
	}

}

