<div class="card">
	<div class="card-body card-status-history">
		<!-- Se data è presente, oppure se la lunghezza è zero ma ci sono filtri applicati, visualizzo la tabella -->
		<table class="document-table"
					 *ngIf="data && (data.length > 0 || data.length === 0  && filterColumn !== undefined); else NoHistory">
			<thead>
				<tr class="header-row">
					<th *ngFor="let field of fields"
							[popover]="useFilter && (!filterColumn || filterColumn && filterColumn === field.id) ? filterInput : null"
							#popover="bs-popover" [outsideClick]="true" placement="bottom" container="body">
						<span class="field" [ngClass]="{
															'pointer': useFilter
														}">
							{{field.name}}
							<ng-container *ngIf="useFilter">
								<i [class]="faIcons.FAS_FILTER" [ngClass]="{
																			'disabled': (field.id !== filterColumn && filterColumn !== undefined) || filterColumn === undefined
																	}" aria-hidden="true">
								</i>
							</ng-container>
						</span>
						<ng-template #filterInput>
							<form (ngSubmit)="setFilter(field.id, f, popover)" #f="ngForm">
								<div class="filter-input">
									<ng-container [ngSwitch]="field.type">
										<ng-container *ngSwitchCase="FIELD_TYPE.DATE">
											<label class="form-label" [attr.for]="field.id">
												<input type="text" class="form-control" id="{{ field.id }}" name="{{ field.id }}" ngModel
															 bsDatepicker #datePickerHtmlElement #datePickerModel="ngModel"
															 [(ngModel)]="formModel[field.id]" (ngModelChange)="f.ngSubmit.emit()" autocomplete="off"
															 [bsConfig]="{
														 dateInputFormat: field?.['format'],
														 containerClass: 'theme-blue',
														 showWeekNumbers: false
													}">
											</label>
										</ng-container>
										<ng-container *ngSwitchDefault>
											<label class="form-label" [attr.for]="field.id">
												<input type="text" class="form-control" autocomplete="off" name="{{ field.id }}"
															 [(ngModel)]="formModel[field.id]" />
											</label>
										</ng-container>
									</ng-container>
								</div>
								<button type="submit" class="btn btn-success gaw-button filter-button">
									<i [ngClass]="faIcons.FAS_CHECK" aria-hidden="true"></i>
								</button>
								<button type="button" class="btn btn-danger btn-cancel filter-button"
												(click)="cleanForm(field, f, popover)">
									<span class="icon-stack filter-remove">
										<i class="icon-stack-added" [ngClass]="faIcons.FAS_FILTER"></i>
										<i class="icon-stack-added" [ngClass]="faIcons.FAS_TIMES"></i>
									</span>
								</button>
							</form>
						</ng-template>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr class="table-row" *ngFor="let value of data">
					<td class="regular-cell" *ngFor="let field of fields">
						<ng-container [ngSwitch]="field.type">
							<ng-container *ngSwitchCase="FIELD_TYPE.DATE">
								<span>{{ value[field.id] | utcToLocalDate: field?.['format'] }}</span>
							</ng-container>
							<ng-container *ngSwitchDefault>
								<span>{{ value[field.id] }}</span>
							</ng-container>
						</ng-container>
					</td>
				</tr>
				<ng-template #NoData>
					<span>Nessun dato disponibile</span>
				</ng-template>
			</tbody>
		</table>
		<ng-template #NoHistory>
			<span class="no-history">Nessuno storico disponibile</span>
		</ng-template>
	</div>
</div>
