<div class="login-page">
	<ng-container *ngIf="isSupportedBrowser; else noModernBrowser">
	<div class="card login-card">
		<div class="card-body">
			<table>
				<tr class="main-row">
					<td class="logo-div">
						<img src="../../../../../assets/images/gaw-logo.svg" alt="" class="hub-logo">
					</td>

					<td class="right-div">
						<div class="text-div">
							Accesso al portale in corso
							<span class="first">.</span>
							<span class="second">.</span>
							<span class="third">.</span>
						</div>
					</td>
				</tr>
				<tr class="footer-row">
					<td [attr.colspan]="2" class="footer-div">Powered by CREDEMTEL</td>
				</tr>
			</table>
		</div>
	</div>
	</ng-container>
	<ng-template #noModernBrowser>
		<div class="card login-card old-browser-card">
			<div class="card-body old-browser-card-body">
				<table>
					<tr class="main-row">
						<td class="logo-div" style="text-align: center">
							<img src="../../../../../assets/images/hub-logo.svg" alt="" class="hub-logo" style="margin: auto; float: none">
						</td>
						<td class="right-div" style="padding: 0">
							<div class="text-div" style="padding: 20px; font-weight: normal; font-size: 16px">
								Gentile Cliente,<br><br>
								a partire dal 10 settembre 2020 il browser Internet Explorer non è più compatibile con
								il portale GAW a cui stai tentando di accedere.<br><br>
								Per poter continuare ad utilizzare il portale ti invitiamo a utilizzare
								uno dei seguenti browser: Google Chrome (<a
								href="https://www.google.com/intl/it_it/chrome/">https://www.google.com/intl/it_it/chrome/</a>),
								Microsoft Edge (sempre presente nei sistemi Windows 10) o Mozilla Firefox (<a
								href="https://www.mozilla.org/it/firefox/new/">https://www.mozilla.org/it/firefox/new/</a>).<br><br>
								Nel restare a sua completa disposizione per ogni richiesta di chiarimento ed assistenza cogliamo l'occasione per porgerle cordiali saluti.
							</div>
						</td>
					</tr>
					<tr class="footer-row">
						<td [attr.colspan]="2" class="footer-div" style="padding: 7px">Powered by CREDEMTEL</td>
					</tr>
				</table>
			</div>
		</div>
	</ng-template>
</div>
