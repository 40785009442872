import { PlatformLocation } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';

@Component({
	templateUrl: './action-confirmation-modal.component.html',
	styleUrls: ['./action-confirmation-modal.component.scss']
})
export class ActionConfirmationModalComponent {

	@Input() data: {
		action: any;
		actionParams?: any;
		docCount?: number;
		maxDocCount?: number;
		title: string;
	};
	@Input() modal: BsModalRef;

	constructor(
		private location: PlatformLocation,
	) {
		location.onPopState(() => this.modal.hide());
	}

	executeAction() {
		if (typeof this.data.action === 'function')
			this.data.action(this.data.actionParams).pipe(
				take(1)
			).subscribe();
		else
			this.data.action.pipe(
				take(1)
			).subscribe();

		this.modal.hide();
	}
}
