import { Injectable, OnDestroy } from '@angular/core';
import { Filter, FilterPayload, HomeFilter, Metric } from '@ctel/gaw-commons';
import {
	FilterState,
	SearchFilterService,
	getFiltersStatusWithUserValues
} from '@ctel/search-filter-store';
import { Store, select } from '@ngrx/store';
import {
	accountFilter,
	amountFilterConfig,
	deletedFlagPayableFilterConfig,
	docTypeFilterConfig,
	primaryServiceStatusConfig,
	status35FilterConfig,
	transmissionFormatFilterConfig
} from 'app/constants/filters/filters-config';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { metricsConfig } from 'app/constants/metrics/metrics';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { AccountType } from 'app/core/common/account/accountType';
import { Copier } from 'app/core/common/utilities/copier';
import { SectionCode } from 'app/entities/ui-config/classification-code.enum';
import { ServiceConfig } from 'app/entities/ui-config/ui-config';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { getCachedHomeFilters } from './store/home-filter.selectors';

/**
 * Servizio che si occupa dello stato dei filtri (payload per richiesta documenti).
 * Salva anche lo stato precedente dei filtri
 * e l'ultimo filtro toccato (colonna dei filtri nella pagina dei documenti)
 */
@Injectable({
	providedIn: 'root'
})
export class FilterService extends SearchFilterService implements OnDestroy {

	private destroy$ = new Subject<void>();

	private servicesTiles$ = new BehaviorSubject<ServiceConfig[]>([]);

	private readonly filterStatus$: Observable<FilterPayload>;
	private readonly homePayload$ = new ReplaySubject<FilterPayload>(1);
	private currentHomeFilterPayload: FilterPayload = null;

	private currentHomeFilterPayableValue: HomeFilter[];
	private currentHomeFilterReceivableValue: HomeFilter[];

	constructor(protected companiesService: CompaniesService, private store: Store<FilterState>) {
		super();
		this.filterStatus$ = this.store.pipe(select(getFiltersStatusWithUserValues({
			technicalFilters: [
				MetadataEnum.DATA_FATTURA,
				MetadataEnum.DATA_INSERIMENTO,
				MetadataEnum.DATA_RICEZIONE
			]
		})));
		this.homePayload$
			.pipe(
				tap(value => this.currentHomeFilterPayload = value)
			)
			.subscribe();

		const currentCachedHomeFilter$ = this.store.pipe(
			select(getCachedHomeFilters)
		);
		currentCachedHomeFilter$.pipe(
			tap((homeFilters) => {
				this.currentHomeFilterPayableValue = Copier.deepCopy(homeFilters.payable);
				this.currentHomeFilterReceivableValue = Copier.deepCopy(homeFilters.receivable);
			})
		).subscribe();
	}

	whenFilterPayload(): Observable<FilterPayload> {
		// NOTA: solo questo metodo si appoggia al refactor a stati, in quanto solo questo
		// viene utilizzato per la ricerca nei documenti.
		// Gli altri metodi che seguono continuano ad utllizzare la logica precedente in quanto
		// utilizzata per i payload di ricerca dalla home.
		return this.filterStatus$;
	}

	// Vecchia logica di reset del payload di ricerca lato /home.
	buildSwitchPayload(accountType: AccountType): any {

		const filters: Filter[] = [
			Copier.deepCopy(status35FilterConfig),
			Copier.deepCopy(primaryServiceStatusConfig),
			Copier.deepCopy(docTypeFilterConfig),
			Copier.deepCopy(amountFilterConfig),
			Copier.deepCopy(accountFilter.get(accountType)),
			Copier.deepCopy(transmissionFormatFilterConfig)
		];

		if (accountType === AccountType.RECEIVABLE)
			filters.push(...this.currentHomeFilterReceivableValue);
		else {
			filters.push(...this.currentHomeFilterPayableValue);
			filters.push(Copier.deepCopy(deletedFlagPayableFilterConfig));
		}

		const metrics: Metric[] = Copier.deepCopy(metricsConfig.get(accountType));

		if (accountType === AccountType.RECEIVABLE) {
			// piastrelle piccole
			const tilesConfig = this.getServicesTiles();
			tilesConfig.forEach(function (service) {
				service.tiles.forEach(function (tile) {
					if (tile.metadata !== SectionCode.CP_SQUADRATO)
						metrics.push(
							{
								metadata: tile.metadata,
								metricType: 'sum'
							}
						);
					else
						metrics.push(
							{
								metadata: tile.metadata,
								metricType: 'sum',
								filter: {
									metadata: 'hubfe_cp_idTipo',
									filterType: 'term',
									type: 'integer',
									configData: {},
									value: {
										term: '0'
									}
								}
							}
						);

				});
			});
		}

		return {
			filters,
			metrics
		};
	}

	// configurazione dei canali configurati (ui-config)
	sendServicesTiles(servicesTiles: ServiceConfig[]) {
		this.servicesTiles$.next(servicesTiles);
	}

	// configurazione dei canali configurati (ui-config)
	getServicesTiles(): ServiceConfig[] {
		return this.servicesTiles$.value;
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
