import { Filter, FullTextSearch, OrderBy, Paging } from '../filter/filter-payload';

export interface SearchRequest {
	docSeriesId?: number;
	licenseId: string;
	siaCode: string;
	filters: Filter[];
	search?: FullTextSearch;
	version?: string;
	paging?: Paging;
	orderBy?: OrderBy[];
}
