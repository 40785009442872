import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouteTransformerDirective } from './route-transformer.directive';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [RouteTransformerDirective],
	exports: [RouteTransformerDirective],
})
export class RoutingUtilityModule {
}
