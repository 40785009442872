import { Component, Input, OnInit } from '@angular/core';
import { DocumentAttachmentPdf } from 'app/shared/components/display-document/document-attachment-pdf';
import { IDetailsComponent } from '@ctel/gaw-commons';
import { DocumentDetailsService } from '../../../documents-search/document-details/document-details.service';

@Component({
	selector: 'gaw-gawpec-document-preview',
	templateUrl: './gawpec-document-preview.component.html',
	styleUrls: ['./gawpec-document-preview.component.scss'],
})
export class GAWPECDocumentPreviewComponent implements OnInit {
	@Input() data: IDetailsComponent;

	public documentAttachmentPdf: DocumentAttachmentPdf;

	constructor(private documentDetailsService: DocumentDetailsService) {}

	ngOnInit() {
		const documentPdf$ = this.documentDetailsService.retrieveDocumentPdf(
			this.data.licenseId,
			this.data.siaCode,
			this.data.docSeriesId,
			this.data.section,
			this.data.elasticId
		);
		this.documentAttachmentPdf = new DocumentAttachmentPdf('Preview', documentPdf$);
	}
}
