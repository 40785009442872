import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InfoResponseModel } from '../../entities/info-response-model';
import { Observable } from 'rxjs';

@Component({
	selector: 'gaw-remove-pda-modal',
	templateUrl: './remove-pda-modal.component.html',
	styleUrls: ['./remove-pda-modal.component.scss']
})
export class RemovePdaModalComponent {
	@Input() modal: BsModalRef;
	@Input() data: {
		info: InfoResponseModel,
		supportiIds: number[],
		action: (licenseId: string, siaCode: string, supportiIds: number[], accept : boolean) => Observable<void>
	};
	accept = false;

	executeAction() {
		this.data.action(this.data.info.idLicenza, this.data.info.codSia, this.data.supportiIds, this.accept).subscribe();
		this.modal.hide();
	}

	checkDisable() {
		return this.data.info.supportiDaEliminare === this.data.info.supportiInAltriRss;
	}
}
