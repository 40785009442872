import { EventType } from 'app/core/common/event/event-type';

export class WorkflowEvent {

	public static E_EXECUTE_ACTION = new EventType('E_EXECUTE_ACTION');
	public static E_NEW_WORKITEM = new EventType('E_NEW_WORKITEM');
	public static E_MODAL_CLOSE = new EventType('E_MODAL_CLOSE');
	public static E_ACTION_COMPLETED = new EventType('E_ACTION_COMPLETED');

	public static E_CLEAN_WORKITEM_SELECTION = new EventType('E_CLEAN_WORKITEM_SELECTION');
	public static E_SELECT_WORKITEM = new EventType('E_SELECT_WORKITEM');
}
