import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { ActionsHttpService } from '../actions-http.service';

@Injectable({
	providedIn: 'root'
})
export class SingleDocumentCallback {

	constructor(
		private actionsHttpService: ActionsHttpService,
		private notificationService: NotificationService,
		private router: Router
	) { }
}
