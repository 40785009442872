export interface DocumentMetadata {
	metadata: Metadata;
	id: string;
	sia: string;
	grafica: string;
}

export interface Metadata {
	[p: string]: {
		nome: string;
		valore: string;
	};
}
