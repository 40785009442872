import { Component, Input } from '@angular/core';

@Component({
	selector: 'hub-image-placeholder',
	templateUrl: './image-placeholder.component.html',
	styleUrls: ['./image-placeholder.component.scss']
})
export class ImagePlaceholderComponent {

	@Input() width: number;
	@Input() height: number;

}
