<div class="wrapper-main">
	<ng-sidebar-container style="min-height:100vh">
		<!-- COLLAPSABLE SIDE BAR -->
		<ng-sidebar *ngIf="showSidebar" [(opened)]="_opened" mode="push" [autoFocus]="false">
			<gaw-sidebar></gaw-sidebar>
		</ng-sidebar>

		<!-- MAIN PAGE CONTENT WRAPPER -->
		<div ng-sidebar-content #inner id="sidebar-content" class="h-100">
			<dry-progress-bar></dry-progress-bar>
			<div id="page-wrapper" class="h-100 container-fluid px-0">
				<div [ngClass]="route.includes('home') ? 'full-home-header' : 'fixed-margin'">
					<!-- TOP NAVBAR -->
					<!-- <div class="contenitore-header row"
							 [ngClass]="route.includes('home') || route.includes('help') ? '' : 'pointed-background'"> -->

					<!-- <app-search-panel *ngIf="route.includes('home') && !route.includes('gawfe/') && !route.includes('gaw30/')"></app-search-panel> -->
				</div>
				<!-- PANNELLO DI RICERCA VISIBILE SOLO NELLA HOME -->
				<!-- MAIN / ROUTES WRAPPER-->
				<router-outlet></router-outlet>
			</div>
		</div>
	</ng-sidebar-container>
</div>
