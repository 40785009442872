import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BlockUIModule } from '@ctel/block-ui';
import { PlaceholderModule } from '@ctel/placeholders';
import { TransferManagerModule } from '@ctel/transfer-manager';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppCommonModule } from 'app/core/common/app-common.module';
import { ErrorPlaceholderModule } from 'app/core/common/placeholder/error-placeholder/error-placeholder.module';
import { DryLoadingPlaceholderModule } from 'app/core/common/placeholder/loading-placeholder/dry-loading-placeholder.module';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RoutingUtilityModule } from '../../../core/common/utilities/routing/routing-utility.module';
import { CompaniesDropdownComponent } from '../companies-dropdown/companies-dropdown.component';
import { HeaderComponent } from './header.component';
import { UploadNotificationsComponent } from './upload-notifications/upload-notifications.component';

@NgModule({
	imports: [
		AppCommonModule,
		CommonModule,
		FormsModule,
		RouterModule,
		BlockUIModule,
		BsDropdownModule,
		TooltipModule,
		TransferManagerModule.forRoot(),
		PipesModule,
		ErrorPlaceholderModule,
		DryLoadingPlaceholderModule,
		ReactiveFormsModule,
		PlaceholderModule,
		RoutingUtilityModule,
		AngularSvgIconModule
	],
	declarations: [
		HeaderComponent,
		CompaniesDropdownComponent,
		UploadNotificationsComponent
	],
	exports: [
		HeaderComponent,
		CompaniesDropdownComponent,
		UploadNotificationsComponent
	]
})
export class HeaderModule {
}
