import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TopProgressBarService } from './top-progress-bar.service';

@Component({
	selector: 'dry-progress-bar',
	template: '<div class="progress-container" *ngIf="getVisibility()"><div class="progress"><div class="indeterminate"></div></div></div>',
	styleUrls: ['./top-progress-bar.component.scss']
})
export class TopProgressBarComponent implements OnDestroy {

	private visible = false;
	private destroy$ = new Subject<void>();

	constructor(
		private progressBar: TopProgressBarService
	) {
		progressBar.whenLoadingRequest()
			.pipe(takeUntil(this.destroy$))
			.subscribe((count) => {
				if (count > 0) this.showProgressBar();
				else this.hideProgressBar();
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	showProgressBar = () => this.visible = true;
	hideProgressBar = () => this.visible = false;

	getVisibility = () => this.visible;

}
