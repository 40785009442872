import { IDocument } from '@ctel/search-filter-store';
import { Filter, Metric } from '../filter/filter-payload';
import { Action } from './actions/action';
import { DisplayType } from './display-type.enum';
import { FullText } from './full-text';

export interface DocumentsResponse {
	docs: Document[];
	filters: Filter[];
	totalDocs: number;
	success?: boolean;
	statusCode?: number;
	metrics?: Metric[];
	error?: Error;
}

export interface Display {
	type: DisplayType;
	value: string;
	metadata: string;
	metadataDescription: string;
	highlights: string[];
	format?: string;
}

export interface DocumentViewModel extends Document {
	docSeriesDescriptionColor: string;
}

export interface Document extends IDocument {
	show?: boolean;
	children?: Document[];
	display: Display[];
	fullText: FullText;
	docSeriesDescription: string;
	keys: DocumentKey;
	actions: Action[];
	documentId: string;
	tags?: string[];
	isHidden?: boolean;
	hasNotes?: boolean;
	highlights?: Array<unknown>;
}

export interface DocumentKey {
	ctelDocSeriesId?: string;
	ctelElasticDocumentId?: string;
	hashDocKey?: string;
	idComunicazione35?: string;
	progBusta: string;
	progSpool: string;
	tipoCanale?: string;
	descTipoDocumento?: string;
	codice?: string;
	note?: string;
	dataUltimoStato?: string;
	ultimoStato?: string;
	idScatola?: string;
	stato?: string;
	hubfe_sezione?: string;
	supportiIds?: string[];
}
