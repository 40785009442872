import { Injectable } from '@angular/core';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { PayableCardsConfig, ReceivableCardsConfig } from '../entities/cards/receivable-cards-config';
import { CardsHttpService } from './cards-http.service';
import { PayableAccountCardsService } from './payable-account-cards.service';
import { ReceivableAccountCardsService } from './receivable-account-cards.service';

/**
 * Servizio che gestisce i parametri grafici delle cards
 */
@Injectable({
	providedIn: 'root'
})
export class CardsConfigService {

	private receivableCardsConfig$ = new ReplaySubject<ReceivableCardsConfig>(1);
	private payableCardsConfig$ = new ReplaySubject<PayableCardsConfig>(1);
	private numberOfPayableServices$ = new ReplaySubject<number>(1);
	private tileBlueOrOrange$ = new ReplaySubject<string>(1);
	private numberOfReceivableServices$ = new ReplaySubject<number>(1);
	private receivableServicesLabels$ = new ReplaySubject<string[]>(1);
	private payableServicesLabels$ = new ReplaySubject<string[]>(1);

	constructor(
		private receivableAccountCardsService: ReceivableAccountCardsService,
		private payableAccountCardsService: PayableAccountCardsService,
		private cardsHttpService: CardsHttpService,
		private companiesService: CompaniesService
	) {
		this.tileBlueOrOrange$.next('blue');
		this.receivableAccountCardsService.whenServices()
			.subscribe(clientConfigurationResponse => {
				this.sendReceivableCardsConfig(this.getReceivableCardsConfig(clientConfigurationResponse.tipoCanale.length));
				this.sendReceivableNumberOfServices(clientConfigurationResponse.tipoCanale.length);
				const labelArray = clientConfigurationResponse.tipoCanale.map(label => label.channelName);
				this.sendReceivableServicesLabels(labelArray);
			});
		this.payableAccountCardsService.whenServices()
			.subscribe(clientConfigurationResponse => {
				this.sendPayableCardsConfig(this.getPayableCardsConfig(clientConfigurationResponse.tipoCanale.length));
				this.sendPayableNumberOfServices(clientConfigurationResponse.tipoCanale.length);
				const labelArray = clientConfigurationResponse.tipoCanale.map(label => label.channelName);
				this.sendPayableServicesLabels(labelArray);
			});

		// se la tile Ricevuti nel passivo è orange (funzione autoexport attiva) o blue (non attiva)
		this.companiesService.whenCurrentCompany()
			.pipe(
				distinctUntilChanged(),
				switchMap(company => this.cardsHttpService.whenTileBlueOrOrange(company.licenseId, company.siaCode)),
				catchError((err: unknown) => {
					if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
						//this.router.navigate(['/unauthorized']).then();
					}
					return EMPTY;
				}),
				tap(
					result => {
						if (result) {
							const color: string = result.isFAutoExportActive === true ? 'orange' : 'blue';
							this.tileBlueOrOrange$.next(color);
						} else
							this.tileBlueOrOrange$.next('blue');

					}
				)
			)
			.subscribe();
	}

	// ATTIVO
	whenReceivableNumberOfServices(): Observable<number> {
		return this.numberOfReceivableServices$.asObservable();
	}

	sendReceivableNumberOfServices(value: number) {
		this.numberOfReceivableServices$.next(value);
	}

	whenReceivableCardsConfig(): Observable<ReceivableCardsConfig> {
		return this.receivableCardsConfig$.asObservable();
	}

	sendReceivableCardsConfig(value: ReceivableCardsConfig) {
		this.receivableCardsConfig$.next(value);
	}

	whenReceivableServicesLabels(): Observable<string[]> {
		return this.receivableServicesLabels$.asObservable();
	}

	sendReceivableServicesLabels(value: string[]) {
		this.receivableServicesLabels$.next(value);
	}

	// PASSIVO
	// numero di canali attivi per il cliente sul passivo
	whenPayableNumberOfServices(): Observable<number> {
		return this.numberOfPayableServices$.asObservable();
	}

	sendPayableNumberOfServices(value: number) {
		this.numberOfPayableServices$.next(value);
	}

	// settings vari delle tiles del passivo
	whenPayableCardsConfig(): Observable<PayableCardsConfig> {
		return this.payableCardsConfig$.asObservable();
	}

	sendPayableCardsConfig(value: PayableCardsConfig) {
		this.payableCardsConfig$.next(value);
	}

	whenTileBlueOrOrange(): Observable<string> {
		return this.tileBlueOrOrange$.asObservable();
	}

	sendTileBlueOrOrange(color: string) {
		this.tileBlueOrOrange$.next(color);
	}

	// labels per i canali abilitati per il cliente sul passivo
	whenPayableServicesLabels(): Observable<string[]> {
		return this.payableServicesLabels$.asObservable();
	}

	sendPayableServicesLabels(value: string[]) {
		this.payableServicesLabels$.next(value);
	}

	private getReceivableCardsConfig(numberOfServices: number) {
		let config: ReceivableCardsConfig;
		switch (numberOfServices) {
			case 1:
				config = {
					smallCardHeight: '304px',
					mediumCardHeight: '154px',
					largeCardHeight: '308px'
				};
				break;
			case 2:
				config = {
					smallCardHeight: '150px',
					mediumCardHeight: '158px',
					largeCardHeight: '316px'
				};
				break;
			case 3:
				config = {
					smallCardHeight: '100px',
					mediumCardHeight: '160px',
					largeCardHeight: '324px'
				};
				break;
			case 4:
				config = {
					smallCardHeight: '102px',
					mediumCardHeight: '214px',
					largeCardHeight: '434px'
				};
				break;
			case 5:
				config = {
					smallCardHeight: '80px',
					mediumCardHeight: '220px',
					largeCardHeight: '440px'
				};
				break;
			case 6:
				config = {
					smallCardHeight: '80px',
					mediumCardHeight: '264px',
					largeCardHeight: '528px'
				};
				break;
		}
		return config;
	}

	private getPayableCardsConfig(numberOfServices: number) {
		let config: PayableCardsConfig;
		switch (numberOfServices) {
			case 0:
				config = null;
				break;
			case 1:
				config = {
					largeCardHeight: '300px',
					serviceCardHeight: '300px'
				};
				break;
			case 2:
				config = {
					largeCardHeight: '250px',
					serviceCardHeight: '120px'
				};
				break;
			case 3:
				config = {
					largeCardHeight: '380px',
					serviceCardHeight: '120px'
				};
				break;
			case 4:
				config = {
					largeCardHeight: '470px',
					serviceCardHeight: '110px'
				};
				break;
			case 5:
				config = {
					largeCardHeight: '540px',
					serviceCardHeight: '100px'
				};
				break;
		}
		return config;
	}

}
