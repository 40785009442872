import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'boldSpan'
})
export class BoldSpanPipe implements PipeTransform {

	constructor(
		private sanitizer: DomSanitizer
	) { }

	transform(value: string, regex): string {
		if (!value || !regex)
			return;

		let regexClear = regex.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '');
		regexClear = regexClear.replace('!', '');
		return this.sanitize(this.replace(value, regexClear));
	}

	replace(str: string, regex) {
		return str.replace(new RegExp(`(${regex})`, 'gi'), '<b>$1</b>');
	}

	sanitize(str: string) {
		return this.sanitizer.sanitize(SecurityContext.HTML, str);
	}
}
