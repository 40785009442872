import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Filter, IDetailsComponent, ServiceType } from '@ctel/gaw-commons';
import { StatusHistoryService } from '@ctel/gawlotti-commons';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { Details } from 'app/modules/details/entities/details';
import { DetailsService } from 'app/modules/details/services/details.service';
import { Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { map, share, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
	selector: 'gaw-gawlotti-status-history',
	templateUrl: './gawlotti-status-history.component.html',
	styleUrls: ['./gawlotti-status-history.component.scss']
})
export class GawlottiStatusHistoryComponent implements OnDestroy, OnInit {
	@Input() data: IDetailsComponent;

	public faIcons = FaIcons;
	public details$: Observable<Details>;
	public headers: string[];

	statusHistory$: Observable<any>;
	fullPageError$: Observable<boolean>;
	statusHistoryError$: Observable<boolean>;
	historyPanelLoading = true;
	tileErrorPlaceholderErrorOption = { message: 'Errore imprevisto.' };
	private readonly defaultFilter: Filter[] = [];
	private destroy$ = new Subject<void>();
	private elasticId: string;
	private licenseId: string;
	private siaCode: string;
	private section: string;
	private doctype: string;
	private serviceType: ServiceType;
	private metadataLoading: boolean;
	private keys;

	constructor(
		private statusHistoryService: StatusHistoryService,
		private detailsService: DetailsService,
		private route: ActivatedRoute,
		private companiesService: CompaniesService
	) {

		// recupero parametri dall'url
		this.route.paramMap
			.pipe(takeUntil(this.destroy$))
			.subscribe((paramMap) => {
				this.elasticId = paramMap.get('elasticid');
				this.licenseId = paramMap.get('license');
				this.siaCode = paramMap.get('siacode');
				this.section = paramMap.get('section');
				this.doctype = paramMap.get('doctype');
				this.serviceType = <ServiceType>paramMap.get('serviceType');
			});

		const company$ = this.companiesService.whenCurrentCompany();
		this.details$ = combineLatest([company$]).pipe(
			map((combine) => combine[0]),
			switchMap((company) =>
				this.detailsService.whenDetails(company.licenseId, company.siaCode, this.elasticId, this.doctype, true, this.serviceType)
			),
			tap(() => {
				this.metadataLoading = false;
			}),
			share({ connector: () => new ReplaySubject<Details>(1) })
		);

	}

	getSpool(elasticId: string): string {
		return elasticId.split('_')[0];
	}

	ngOnInit(): void {
		this.keys = this.detailsService.getKeys();
		this.statusHistoryService.getStatusHistory(
			this.getSpool(this.elasticId),
			this.getTipoCanale(this.elasticId)
		);
		this.statusHistory$ = this.statusHistoryService.whenStatusHistory();

		this.statusHistory$.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.historyPanelLoading = false);

		this.statusHistoryError$ = this.statusHistoryService.whenStatusHistoryError()
			.pipe(
				tap((x: boolean) => {
					if (x)
						this.historyPanelLoading = false;
				})
			);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	getTipoCanale(elasticId: string) {
		return elasticId.split('_')[1];

	}
}
