export enum Order {
	ASCENDING = 'asc',
	DESCENDING = 'desc',
	NOT_SET = 'not_set'
}

export interface Ordering {
	field: string,
	order: Order
}
