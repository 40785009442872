import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
import 'moment/locale/it';
import { Observable, ReplaySubject } from 'rxjs';

/**
 * Servizio che gestisce i cookies relativi alle azioni eseguite dagli utenti
 */
@Injectable({
	providedIn: 'root'
})
export class CookiesForActionsService {

	private cookiesForActions$ = new ReplaySubject<any>(1);

	constructor(
		private cookieService: CookieService
	) { }

	public whenCurrentCookiesForAction(): Observable<any> {
		return this.cookiesForActions$;
	}

	public sendCurrentCookiesForAction(cookie: any) {
		this.cookiesForActions$.next(cookie);
	}

	// crea un cookie per quel doc
	public addDataToCurrentCookie(documentId: string, classification: string) {
		if (this.cookieService.get('HUBFE_CookiesForActions' + documentId + '_' + classification) !== undefined)
			this.cookieService.delete('HUBFE_CookiesForActions' + documentId + '_' + classification);

		const cookie = { documentId, classification };
		this.cookieService.set(
			'HUBFE_CookiesForActions' + documentId + '_' + classification, JSON.stringify(cookie),
			{ expires: this.addMinutes(4), secure: true});
		this.sendCurrentCookiesForAction(cookie);
	}

	private addMinutes(minutes: number): Date {
		const today = new Date();
		return moment(today).add(minutes, 'm').toDate();
	}
}
