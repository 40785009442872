import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SnackbarModule } from '@ctel/snackbar';
import { ComponentsModule } from 'app/shared/components/components.module';
import { ToastrModule } from 'ngx-toastr';
import { EventModule } from '../event/event.module';
import { AskConfirmDirective } from './ask-confirm.directive';
import { ModalEngineModule } from './modal/modal-engine.module';
import { NotificationHostComponent } from './notification-host.component';
import { SnackBarService } from './snackbar/snack-bar.service';
import { SweetAlertService } from './sweet-alert/sweet-alert.service';
import { ToastService } from './toast/toast.service';

@NgModule({
	imports: [
		CommonModule,
		EventModule,
		ComponentsModule,
		ToastrModule.forRoot({
			timeOut: 50000,
			positionClass: 'toast-top-right',
			preventDuplicates: true
		}),
		ModalEngineModule.forRoot(),
		SnackbarModule
	],
	declarations: [
		AskConfirmDirective,
		NotificationHostComponent,
	],
	exports: [
		AskConfirmDirective,
		NotificationHostComponent
	]
})
export class NotificationModule {

	static forRoot(): ModuleWithProviders<NotificationModule> {
		return {
			ngModule: NotificationModule,
			providers: [
				SweetAlertService,
				ToastService,
				SnackBarService
			]
		};
	}
}
