export interface EcmDocumentHierarchy {
	documentId: string;
	dossierId: string;
	values: Values;
	attachments: Attachment[];
}

export interface Values {
	technicalId: number;
	docSize: number;
	filePath: string;
	fileName: string;
	mimeType: string;
	docSeriesId: number;
}

export interface Attachment {
	attachmentId: string;
	documentId: string;
	values: Values;
}

export interface EcmDossierHierarchy {
	dossierId: string;
	docSeriesId: number;
	documents: EcmDocument[];
}

export interface EcmDocument {
	documentId: string;
	dossierId: string;
	values: Values;
	attachments: Attachment[];
}

// export interface EcmDossierHierarchy {
// 	dossierId: string;
// 	docSeriesId: number;
// 	documents: EcmDocumentHierarchy[];
// }

export interface XmlPaAttachmentsListResponse {
	attachments: XmlPaAttachment[];
}

export interface XmlPaAttachment {
	idAllegato: number;
	nomeAllegato: string;
	mimeType: string;
}
