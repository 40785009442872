import {DocumentPermissions} from './document-permissions.enum';

export class Permissions {
	public documentPermissions = new Set<DocumentPermissions>();

	constructor(documentPermissions: Set<DocumentPermissions>) {
		this.documentPermissions = documentPermissions;
	}

}
