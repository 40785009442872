<input class="form-check-input"
	   name="{{name}}"
	   [id]="id"
		 [attr.aria-label]="ariaLabel"
	   value="{{value}}"
	   [placeholder]="placeholder"
	   type="checkbox"
	   [ngStyle]="style"
	   [ngClass]="{
	   	'valid': control.value && control?.valid,
	   	'invalid': control?.invalid
	   }"
	   [popover]="InvalidFormMessage"
	   (focusin)="onFocusIn()"
	   (focusout)="onFocusOut()"
	   triggers=""
	   [formControl]="control"/>
<ng-template #InvalidFormMessage>
	<ul *ngIf="control.invalid">
		<ng-container *ngFor="let error of (controlErrors$ | async)">
			<li>
				{{error}}
			</li>
		</ng-container>
	</ul>
</ng-template>
