import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { pulseAnimation } from 'app/core/common/animations/pulse-animation';
import { Options } from 'app/core/common/placeholder/error-placeholder/directives/options';
import { PlaceholderService } from 'app/core/common/placeholder/placeholder.service';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { DocumentAttachmentXml } from 'app/shared/components/display-document/document-attachment-xml';
import { AbstractViewerComponent } from 'app/shared/components/display-document/viewers/abstract-viewer/abstract-viewer.component';
import { LoadingStatus } from '../pdf-viewer/loading-status.enum';
import { delay, map, switchMap, take } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import * as vkbeautify from 'vkbeautify';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';

@Component({
	selector: 'dry-xml-viewer',
	templateUrl: './xml-viewer.component.html',
	styleUrls: ['./xml-viewer.component.scss'],
	animations: [
		trigger('loadingError$', [
			transition(
				'* => *',
				useAnimation(pulseAnimation, {
					params: {
						timings: '200ms cubic-bezier(.11,.99,.83,.43)',
						scale: 1.1,
					},
				})
			),
		]),
	],
})
export class XmlViewerComponent extends AbstractViewerComponent<DocumentAttachmentXml> implements OnInit, OnDestroy {
	public truncatedFileWarning =
		'Attenzione, la dimensione del file XML eccede quella visualizzabile, per visualizzare l\'intero' + ' contenuto scaricare il file.';

	public faIcons = FaIcons;
	public prettyXmlContent: string;
	public xmlContentTooLong = false;

	public errorOptions: Options = {
		message: 'Documento non disponibile.',
		icon: `${this.faIcons.FAR_FILE_CODE}`,
		onRetry: () => this.refreshContent(),
		applyDefaultSize: true,
	};

	constructor(protected placeHolderService: PlaceholderService, private logger: NGXLogger,
		public fullScreenSpinnerService: FullScreenSpinnerService
	) {
		super(placeHolderService);
	}

	ngOnInit() {
		super.ngOnInit();

		// Quando arriva il contenuto del documento, lo mostro
		this.initDocumentContent();
	}

	protected initDocumentContent() {
		// Avvia il blocco della UI
		this.setLoadingStatus(LoadingStatus.loading);
		this.fullScreenSpinnerService.show();
		this.documentAttachment$
			.pipe(
				// Ritardo aggiunto per dare un feedback all'utente
				delay(300),
				switchMap((documentContent) => documentContent.content),
				take(1),
				map((content) => this.truncateIfTooLong(content)),
				map((content) => this.formatXml(content))
			)
			.subscribe({
				next: (result) => {
					this.prettyXmlContent = result as unknown as string;
					this.fullScreenSpinnerService.hide();

					// Sblocca la UI
					this.setLoadingStatus(LoadingStatus.loaded);
				},
				error: (err: unknown) => {
					this.fullScreenSpinnerService.hide();
					this.logger.error('Errore durante il caricamento dell\'allegato', err);
					this.setLoadingStatus(LoadingStatus.error);
				},
			});
	}

	private truncateIfTooLong(xmlContent) {
		const maxAllowedLength = 100000;
		if (xmlContent.length > maxAllowedLength) {
			this.logger.warn(`XML eccede la lunghezza massima consentita, l'output verra' tagliato a ${maxAllowedLength} caratteri.`);
			this.xmlContentTooLong = true;
			return xmlContent.substr(0, maxAllowedLength).toString().concat('\n*** ', this.truncatedFileWarning, ' ***');
		} else {
			this.xmlContentTooLong = false;
			return xmlContent;
		}
	}

	private formatXml (unformattedXml){
		return vkbeautify.xml(unformattedXml);
	}
}
