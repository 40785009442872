<!--PANEL HISTORY STATI CANALE-->
<div class="expedition-box px-3 py-4">
	<ng-container *ngIf='boxDetails$ | async as boxDetails else noContent'>
		<ng-container *ngIf='boxDetails.listaTrackingDetails.length>0 else noContent'>
			<table class='table table-responsive table-striped'>
				<thead>
					<tr>
						<th>
							Nome
						</th>
						<th>
							Nome file originale
						</th>
						<th>
							Data ricezione
						</th>
						<th>
							Numero documenti
						</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor='let spool of boxDetails.listaTrackingDetails'>
						<td>
							<a class='lotto-link'
								 (click)='goToLottoDetail(spool)'><span>{{ spool.nomeFlusso }}</span></a>
						</td>
						<td>
							<span>{{ spool.nomeFileOriginale }}</span>
						</td>
						<td>
							<span>{{ spool.dataRicezione | date }}</span>
						</td>
						<td>
							<span>{{ spool.numeroDocumenti }}</span>
						</td>
					</tr>
				</tbody>
			</table>
		</ng-container>
	</ng-container>
</div>

<ng-template #noContent>
	<div style='text-align: center'>
		<span><strong>Nessun Risultato</strong></span>
	</div>
</ng-template>
