<table *ngIf="history$ | async as history">
	<ng-container *ngIf="history.length === 0">
		Storico non disponibile per il documento selezionato.
	</ng-container>
	<ng-container *ngFor="let historyItem of history; let i = index">
		<tr *ngIf="i === 0">
			<ng-container *ngFor="let header of historyItem.storicoItem">
				<th>{{header.headerLabel}}</th>
			</ng-container>
		</tr>
		<tr>
			<ng-container *ngFor="let cell of historyItem.storicoItem">
				<td *ngIf="cell.type === 'datetime'">{{cell.value | isoDatetimeToDatetime}}</td>
				<td *ngIf="cell.type !== 'datetime'">{{cell.value}}</td>
			</ng-container>
		</tr>
	</ng-container>
</table>

