export interface DocumentDetails {
	documentId: string;
	docSeriesId: string;
	docSeriesDescription: string;
	metadataList: Metadata[];
	keys: object;
	tags: TagObject[];
}

export interface Metadata {
	value: string;
	valueDisplay: string;
	description: string;
	type: string;
	keyCode: string;
	valueAsString: string;
	technical: boolean;
}

export interface TagObject {
	description: string;
	id: string;
	keyCode: string;
	technical: boolean;
	type: string;
	value: string[];
	valueDisplay: string;
}

export interface DocSeriesMetadataDesc {
	metadato: string;
	descrizioni: string[];
}

export interface MetadataDescription {
	keyCode: string;
	descrizione: string;
}

export interface DocSeriesMetadataDescSearch {
	idsSerieDoc: string[];
	metadata: string[];
}
