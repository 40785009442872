/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative al dettaglio documento
 */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Transfer, transferHandler, TransferService } from '@ctel/transfer-manager';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { AppConfig } from 'app/entities/config/app-config';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomHttpOptions } from '../../../../../../../src/app/core/common/utilities/custom-http-options';

@Injectable({
	providedIn: 'root'
})
export class ActionsHttpService implements OnDestroy {
	private jsonContentType = 'application/json';
	// host
	private scartoHost: string;

	private actionLoading$ = new BehaviorSubject<boolean>(false);


	private destroy$ = new ReplaySubject<void>(1);
	private downloadUrl: string;
	private cancelRequestUrl: string;
	private uploadRequestUrl: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
		private transferService: TransferService,
		private fullScreenSpinnerService: FullScreenSpinnerService
	) {
		this.configService.whenAppConfig().pipe(takeUntil(this.destroy$))
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	httpHostInit(appConfig: AppConfig) {
		this.scartoHost = appConfig.webConsOrchestrator.http.host;
		this.downloadUrl = `${this.scartoHost}/v1/Scarti/rss/scarica/{idRss}`;
		this.cancelRequestUrl = `${this.scartoHost}/v1/Scarti/rss/annulla`;
		this.uploadRequestUrl = `${this.scartoHost}/v1/Scarti/rss/upload/{IdRss}`;

	}

	public whenLoadingAction(): Observable<boolean> {
		return this.actionLoading$.asObservable();
	}

	public setLoadingAction(value: boolean) {
		this.actionLoading$.next(value);
	}

	download(idRss: string): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.downloadUrl
			.replace('{idRss}', idRss);
		return this.http.get(url, options).pipe(
			transferHandler(this.transferService)
		);
	}

	cancelRequest(licenseId: string, siaCode: string, idRss: string): Observable<void> {
		const body = {
			idLicenza: licenseId,
			codSia: siaCode,
			idRss: +idRss
		};
		return this.http.put<void>(this.cancelRequestUrl, body);
	}

	uploadRequest(file: File, licenseId: string, siaCode: string, rssId: string): Observable<void> {
		const url = this.uploadRequestUrl.replace('{IdRss}', rssId);
		const formData: FormData = new FormData();
		formData.append('File', file);
		formData.append('IdLicenza', licenseId);
		formData.append('CodSia', siaCode);
		return this.http.put<void>(url,formData);
	}
}
