<div class="main-div">
  <div class="message-info">
    <div><strong>Numero massimo di documenti superato.</strong></div>
    <div>
      <strong>{{ data?.message }}</strong>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <button type="button" class="btn btn-success" id="massive-action-confirm-button" (click)="executeAction()">
        <strong>Conferma</strong>
      </button>
    </div>
  </div>
</div>
