import { Component, forwardRef, Host, Inject, Input, Optional, SkipSelf } from '@angular/core';
import { NumberInputComponent } from '../number-input/number-input.component';
import {
	AsyncValidator,
	AsyncValidatorFn, ControlContainer,
	NG_ASYNC_VALIDATORS,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	Validator,
	ValidatorFn
} from '@angular/forms';
import { DecimalUtilities } from 'app/core/common/utilities/number/decimal-utilities';

@Component({
	selector: 'decimal-input',
	templateUrl: './decimal-input.component.html',
	styleUrls: ['./decimal-input.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => DecimalInputComponent),
		multi: true,
	}],
})
export class DecimalInputComponent extends NumberInputComponent {

	@Input() sanitize = true;
	@Input() decimal = 0;

	constructor(
	@Optional() @Inject(NG_VALIDATORS) validators: Array<Validator | ValidatorFn>,
		@Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<AsyncValidator | AsyncValidatorFn>,
		@Optional() @Host() @SkipSelf() controlContainer: ControlContainer
	) {
		super(validators, asyncValidators, controlContainer);
	}

	sanitizeValue() {
		if (this.sanitize)
			if (this.value !== null && !isNaN(Number(this.value)))
				if (DecimalUtilities.checkDecimalLength(this.value, this.decimal) < 0)
					this.value = parseFloat(this.value.toString()).toFixed(this.decimal);

		super.onFocusOut();
	}

}
