import { Injectable } from '@angular/core';
import { ResendPaHttpService } from './resend-pa-http.service';

@Injectable()
export class ResendPaService {
	constructor(private resendPaService: ResendPaHttpService) {}

	public resendPa(
		documentPa: string,
		id: string,
		sia: string,
		grafica: string,
		progSpool: string,
		progBusta: string,
		ctelDocElastic: string,
		ctelDocId: string
	) {
		return this.resendPaService.uploadDocumentPa(id, sia, grafica, documentPa, progSpool, progBusta, ctelDocElastic, ctelDocId);
	}
}
