import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GawCommonsModule } from '@ctel/gaw-commons';
import { WorkflowModalModule } from './modals/workflow-modal/workflow-modal.module';

@NgModule({
	declarations: [],
	imports: [CommonModule, GawCommonsModule, WorkflowModalModule],
	exports: [WorkflowModalModule],
})
export class GawWorkflowModule {}
