import { Injectable, inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

/**
 * Guardia per controllare se l'utente è autenticato.
 *
 * Nel caso l'utente non sia autenticato verrà reindirizzato alla pagina di non autorizzato.
 */
@Injectable()
export class AuthGuardService {

	constructor(
		private authService: AuthService
	) { }

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.access();
	}

	canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
		return this.access();
	}

	private access() {
		return this.authService.isAuthorized()
			.pipe(
				map((isAuthorized: boolean) => {

					if (isAuthorized)
						return true;

					this.authService.navigateToUnauthorized();
					return false;
				})
			);
	}

}
export const canActivateAuth: CanActivateFn = () => inject(AuthGuardService).canActivate();

export const canActivateChildAuth: CanActivateChildFn = () => inject(AuthGuardService).canActivateChild();
