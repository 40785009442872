import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DocumentsSearchModule } from './documents-search/documents-search.module';
import { GawmailDetailComponent } from './gawmail-detail/gawmail-detail.component';
import { GawmailDetailsMainComponent } from './gawmail-detail/gawmail-details-main/gawmail-details-main.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { GawmailStatusHistoryComponent } from './gawmail-detail/gawmail-details-main/gawmail-status-history/gawmail-status-history.component';
import { ErrorPlaceholderModule, PlaceholderModule } from '@ctel/placeholders';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { GawmailDocumentPreviewComponent } from './gawmail-detail/gawmail-details-main/gawmail-document-preview/gawmail-document-preview.component';
import { DisplayDocumentModule } from 'app/shared/components/display-document/display-document.module';
import { DryComponentsModule } from 'app/shared/components/dry/dry-components.module';
import { HistoryActionModalComponent } from './modals/history-action-modal.component/history-action-modal.component';

@NgModule({
	declarations: [
		HistoryActionModalComponent,
		GawmailDetailComponent,
		GawmailDetailsMainComponent,
		GawmailStatusHistoryComponent,
		GawmailDocumentPreviewComponent
	],
	imports: [
		CommonModule,
		DryComponentsModule,
		DisplayDocumentModule,
		PipesModule,
		ErrorPlaceholderModule,
		PlaceholderModule,
		TabsModule,
		DocumentsSearchModule,
	],
	exports: [GawmailDetailComponent, GawmailDetailsMainComponent, GawmailStatusHistoryComponent, GawmailDocumentPreviewComponent],
})
export class GawmailCommonsModule {}
