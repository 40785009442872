import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArchivioFiscaleService } from '@ctel/gawcons-commons';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { DocumentAttachmentPdf } from 'app/shared/components/display-document/document-attachment-pdf';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { IGawconsDetailsComponentInterface } from '../../entities/gawcons-details-component.interface';

@Component({
	selector: 'gaw-archivio-fiscale-detail-main',
	templateUrl: './archivio-fiscale-detail-main.component.html',
	styleUrls: ['./archivio-fiscale-detail-main.component.scss']
})
export class ArchivioFiscaleDetailMainComponent implements OnInit, OnDestroy {

	@Input() data: IGawconsDetailsComponentInterface;
	@Input() action: ActionObject;

	documentAttachment: DocumentAttachmentPdf;
	active = false;

	private destroy$ = new Subject<void>();

	constructor(
		private route: ActivatedRoute,
		private archivioFiscaleService: ArchivioFiscaleService
	) { }

	ngOnInit() {
		this.route.params.pipe(
			tap(params => {
				this.data.registryId = params.registryId;
				this.data.documentId = params.elasticid;
				this.data.keys['annoFiscale'] = params.fiscalYear;
				this.documentAttachment = new DocumentAttachmentPdf(
					'MESSAGGIO',
					this.archivioFiscaleService.whenPdf(this.data, false)
				);
			}),
			takeUntil(this.destroy$)
		).subscribe();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

}
