import { Injectable } from '@angular/core';
import { SingleDocumentCallback } from './callbacks/single-document-callback';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';

@Injectable({
	providedIn: 'root',
})
export class SingleActionsCatalog {
	public readonly exportExcel = new ActionBuilder(SingleActionsCode.exportExcel, ActionCode.DOWNLOAD_EXCEL)
		.setActionURL('') // TODO
		.build();

	public readonly downloadPreservedDocument = new ActionBuilder(
		SingleActionsCode.downloadPreservedDocument,
		ActionCode.DOWNLOAD_PRESERVED_DOCUMENT
	)
		.setActionURL('/v1/documenti/scarica/{licenseId}/{siaCode}/{idDoc}/{idVersion}/{download}')
		.build();

	public readonly openDetail = new ActionBuilder(SingleActionsCode.openDetail, ActionCode.OPEN_DETAIL).build();

	public readonly openPreviousVersions = new ActionBuilder(
		SingleActionsCode.openPreviousVersions,
		ActionCode.OPEN_PREVIOUS_VERSIONS
	).build();

	public readonly createPdd = new ActionBuilder(SingleActionsCode.createPdd, ActionCode.CREATE_PDD).build();
	constructor(private singleDocumentCallback: SingleDocumentCallback) {}
}

export enum SingleActionsCode {
	downloadPreservedDocument = 'downloadPreservedDocument',
	downloadPdfZip = 'downloadPdfZip',
	exportExcel = 'exportExcel',
	openDetail = 'openDetail',
	openPreviousVersions = 'openPreviousVersions',
	createPdd = 'createPdd',
}
