import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { InvoiceFields } from 'app/entities/invoice-pa/invoice-fields';

@Component({
	selector: 'app-modal-section',
	templateUrl: './modal-section.component.html',
	styleUrls: ['./modal-section.component.scss']
})
export class ModalSectionComponent {

	@Input() field: InvoiceFields;
	@Input() parentForm: UntypedFormGroup;

}
