export interface ActionsConfigResponse {
	success: true;
	message: string;
	configurationsGroup: ConfigurationsGroup[];
	configurationLevel: string;
}

// per le multiple è un array di questi oggetti
export interface ActionsConfigRequest {
	progSpool: string;
	progBusta: string;
	serieDoc?: string;
	docMetadata: DocMetadata[];
}

export interface DocMetadata {
	keyCode: string;
	value: string;
}

export interface ConfigurationsGroup {
	code: string;
	name: string;
	icon?: string;
	type?: 'singleAction' | 'multipleAction' | 'massiveAction';
	order?: number;
	highlighted?: boolean;
	internalChecks?: ActionCheck;
	externalChecks?: ActionCheck[];
	disabledChecks?: [];
	services?: [];
}

export interface ActionCheck {
	code: string;
	checkType: string;
	order: number;
	valueType: string;
	value: string;
}

