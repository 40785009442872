import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Transfer, TransferUtils } from '@ctel/transfer-manager';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { ConfigService } from 'app/core/common/config/config.service';
import { ExcelFormatModalComponent } from 'app/core/common/modals/excel-format-modal/excel-format-modal.component';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { HttpHeadersFilename } from 'app/core/common/utilities/http-headers-filename';
import { AppConfig } from 'app/entities/config/app-config';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { IGawconsDetailsComponentInterface, PreviousVersionComponent } from '../../../public-api';
import { PDDRequestModel } from '../../entities/PDD-request-model';
import { PDDOutcomeModalComponent } from '../../modals/pdd-outcome-modal/pdd-outcome-modal.component';
import { PDDRequestModalComponent } from '../../modals/pdd-request-modal/pdd-request-modal.component';
import { ArchivioFiscaleService } from '../../services/archivio-fiscale.service';
import { PacchettiDiDistribuzioneService } from '../../services/pacchetti-di-distribuzione.service';
import { DocumentsSeriesService } from '../documents-series/documents-series.service';
import { ActionsHttpService } from './actions-http.service';
import { ActionsService2 } from './actions.service';

/**
 * Servizio che gestisce l'esecuzione delle azioni sui documenti
 */
@Injectable({
	providedIn: 'root'
})
export class ExecuteActionsService implements OnDestroy {

	private appConfig: AppConfig;
	private downloadAction?: ActionObject;
	private preservedStatus = '40';
	private previousYear = 2014;
	loading = false;

	private destroy$ = new ReplaySubject<void>(1);

	constructor(
		private actionsHttpService: ActionsHttpService,
		private notificationService: NotificationService,
		private actionsService: ActionsService2,
		private documentsSeriesService: DocumentsSeriesService,
		private companiesService: CompaniesService,
		confService: ConfigService,
		private pacchettiDiDistribuzioneService: PacchettiDiDistribuzioneService,
		private archivioFiscaleService: ArchivioFiscaleService,
		private route: ActivatedRoute
	) {
		confService.whenAppConfig().pipe(takeUntil(this.destroy$))
			.subscribe(value => this.appConfig = value);
		this.actionsService.whenSingleActionsList().pipe(takeUntil(this.destroy$))
			.subscribe(value => this.downloadAction = value?.find(value1 => value1.actionCode === ActionCode.DOWNLOAD_PRESERVED_DOCUMENT));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public whenLoadingAction(): Observable<boolean> {
		return this.actionsHttpService.whenLoadingAction();
	}

	// ESECUZIONE AZIONE SINGOLA
	public executeSingleAction(action: ActionObject, data: IGawconsDetailsComponentInterface | string | number | object) {
		let actionCode = '';
		let pddModel: PDDRequestModel;
		let showModal: () => unknown;

		actionCode = action.actionCode;

		switch (actionCode) {
			case ActionCode.DOWNLOAD_PRESERVED_DOCUMENT:
				this.actionsHttpService.whenPdfZip(action.url, data as IGawconsDetailsComponentInterface)
					.pipe(
						take(1),
						catchError(() => {
							this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
							return EMPTY;
						})
					).subscribe((result) => {
						if (result !== null) {
							const r = result as Transfer;
							const fileName = HttpHeadersFilename.getFilenameFromHttpHeaders(r.originatingEvent);
							if (TransferUtils.isHttpResponse(r.originatingEvent)) {
								const blob = new Blob([r.originatingEvent.body]);
								// TODO nome da confermare
								new FileSaver(blob).saveAs(fileName);
							} else
								this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');

						} else
							this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');

					});
				break;
			case ActionCode.DOWNLOAD_DELEGA:
				this.actionsHttpService.whenSinglePdf(data as string)
					.pipe(
						take(1),
						catchError((err: unknown) => {
							const reader = new FileReader();
							reader.addEventListener('loadend', (e) => {
								const error = JSON.parse(e.target.result.toString()).errors.DomainValidations[0];
								this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante il download', error);
							});
							reader.readAsText(err?.['additionalInfo'].find(x => x.t === 'response').u);
							return EMPTY;
						})
					).subscribe((result) => {
						if (result !== null) {
							const r = result as Transfer;
							const fileName = HttpHeadersFilename.getFilenameFromHttpHeaders(r.originatingEvent);
							if (TransferUtils.isHttpResponse(r.originatingEvent)) {
								const blob = new Blob([r.originatingEvent.body], { type: 'application/pdf' });
								// TODO nome da confermare
								new FileSaver(blob).saveAs(fileName, FileSaverExtension.PDF);
							} else
								this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');

						} else
							this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
					});
				break;

			case ActionCode.OPEN_PREVIOUS_VERSIONS:
				this.notificationService.showModal(NotificationType.CUSTOM, {
					title: 'Versioni Precedenti',
					childComponent: PreviousVersionComponent,
					customFooter: true,
					childData: { docDetails: data, action: this.downloadAction },
					disableClickOutside: false
				});
				break;

			case ActionCode.CREATE_PDD:
				if (!this.loading) {
					this.loading = true;
					const pddModel = this.getPDDModel([data['keys']]);
					pddModel.idRegistro = data['registryId'];
					pddModel.idLicenza = data['licenseId'];
					pddModel.codSia = data['siaCode'];
					this.pacchettiDiDistribuzioneService.validaDocumentiMultiplo(pddModel)
						.subscribe({
							next: value => {
								this.loading = false;
								this.notificationService.showModal(NotificationType.CUSTOM, {
									title: 'Invia richiesta pacchetto di distribuzione',
									childComponent: PDDRequestModalComponent,
									customFooter: true,
									childData: {
										execute: this.executeRequest,
										pddModel
									},
									disableClickOutside: false
								});
							},
							error: (error: unknown) => this.loading = false
						});
				}

				break;

			case ActionCode.DOWNLOAD_EXCEL:
				// Modal della scelta del formato dell'excel
				showModal = () => {
					this.notificationService.showModal(NotificationType.GENERAL, {
						title: 'Esporta risultati in Excel',
						childComponent: ExcelFormatModalComponent,
						childData: {
							keys: data['keys'],
							registryId: data['registryId'],
							licenseId: data['licenseId'],
							siaCode: data['siaCode'],
							action,
							actionType: 'single',
							docSeriesDescription: this.documentsSeriesService.getDocSeriesLabel(),
							service: 'GAWCONS',
							sectionCode: 'gcons_archivioFiscale'
						}
					});
				};
				showModal();
				break;

			default:
				console.warn('ACTION non configurata', actionCode);
				break;
		}
	}

	executeRequest = (pddModel: PDDRequestModel) => {
		this.pacchettiDiDistribuzioneService.richiestaPddMultipla(pddModel)
			.pipe(take(1))
			.subscribe(response => {
				this.notificationService.showModal(NotificationType.SUCCESS, {
					title: 'Esito richiesta pacchetto di distribuzione',
					childComponent: PDDOutcomeModalComponent,
					customFooter: true,
					childData: {
						pddModel: response
					},
					disableClickOutside: false
				});
			});
	};

	public executeMultiAction(action: ActionObject, keys: object[], sectionCode?: string) {
		const docCount = keys.length;
		const siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
		const licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
		const registryId = this.archivioFiscaleService.getSelectedRegistryId();
		const actionCode = action.actionCode;

		switch (actionCode) {
			// massive/multi
			case ActionCode.DOWNLOAD_EXCEL: {
				// Modal della scelta del formato dell'excel
				const showModal = () => {
					this.notificationService.showModal(NotificationType.GENERAL, {
						title: 'Esporta risultati in Excel',
						childComponent: ExcelFormatModalComponent,
						childData: {
							siaCode,
							licenseId,
							registryId,
							action,
							actionType: 'multiple',
							docSeriesDescription: this.documentsSeriesService.getDocSeriesLabel(),
							keys,
							service: 'GAWCONS',
							sectionCode
						}
					});
				};

				if (docCount > 50000)
					this.notificationService.showSweetAlert(
						NotificationType.QUESTION,
						'Numero massimo di documenti superato.',
						'Procedere all\'esportazione dei primi 50000 documenti?',
						showModal
					);
				else
					showModal();

				break;
			}
			case ActionCode.DOWNLOAD_PDF_ZIP:
				// TODO Da integrare
				break;
			case ActionCode.CREATE_PDD: {
				if (!this.loading) {
					this.loading = true;
					const noPreserved = !keys.find(value => value['stato'] === this.preservedStatus);
					if (noPreserved) {
						this.noPreservedDialog();
						this.loading = false;
						break;
					}
					const pddModel = this.getPDDModel(keys, docCount);
					pddModel.codSia = siaCode;
					pddModel.idLicenza = licenseId;
					pddModel.idRegistro = registryId.toString();

					this.pacchettiDiDistribuzioneService.validaDocumentiMultiplo(pddModel)
						.pipe(take(1))
						.subscribe(validationMessage => {
							this.loading = false;
							this.notificationService.showModal(NotificationType.CUSTOM, {
								title: 'Invia richiesta pacchetto di distribuzione',
								childComponent: PDDRequestModalComponent,
								customFooter: true,
								childData: {
									execute: this.executeRequest,
									validationMessage,
									pddModel
								},
								disableClickOutside: false
							});
						});
				}

				break;
			}
		}
	}

	private getPDDModel(keys: object[], docCount?: number) {
		const pddModel = new PDDRequestModel();

		const previous2014 = +keys[0]['annoFiscale'] <= this.previousYear;

		pddModel.documentForPdds = keys.map(value => ({
			idDocumento: +value['documentId']?.split('_')[0]
		}));
		pddModel.docCount = docCount;
		pddModel.previous2014 = previous2014;
		pddModel.annoFiscale = keys[0]?.['annoFiscale'];

		return pddModel;
	}

	private noPreservedDialog() {
		this.notificationService.showSweetAlert(NotificationType.ERROR, 'Impossibile eseguire l\'azione',
			// eslint-disable-next-line @stylistic/max-len
			'Tutti i documenti selezionati non risultano ancora conservati. Occorrerà attendere la loro conservazione prima di poter richiedere un Pacchetto di Distribuzione.');
	}

}
