// GENERAL INFO

import { PropertyType } from '../property-type.enum';
import { LockStatus } from './lock-status.enum';

export interface WorkflowAndItemInfo {
	workflowId: number;
	workflowName: string;
	workitemId: number;
	workItemStage: string;
}

// --------------------------------------------------- WORK-ITEM
export interface WorkItem {
	id: number;
	documentId: string;
	documentSeries: string;
	workflowName: string;
	stageName: string;
	properties: WorkItemProperty[];
	lastUpdate: LastUpdate;
	lockStatus: LockStatus;
	archived: boolean;
}

export interface WorkItemVM extends WorkItem {
	showAlert: boolean
}

export interface WorkItemProperty {
	name: string;
	value: string;
}

export interface LastUpdate {
	timestamp: string;
	user: string;
}

// --------------------------------------------------- REQUEST
export interface WorkItemsRequest {
	returnArchived: boolean;
	returnProperties: boolean;
	selectFields: SelectField[];
	searchFields: SearchField[];
	orderFields: OrderField[];
}

export interface SearchField {
	property: string;
	searchOperator: SearchOperator;
	value: string;
}

export enum SearchOperator {
	// noinspection JSUnusedGlobalSymbols
	EQUALS = 'Equals',
	LIKE = 'Like',
	NOT_LIKE = 'NotLike',
	GREATER_THAN = 'GreaterThan',
	LESS_THAN = 'LessThan',
	GREATER_OR_EQUAL_THAN = 'GreaterOrEqualThan',
	LESS_OR_EQUAL_THAN = 'LessOrEqualThan',
	IS_NULL = 'IsNull',
	IS_NOT_NULL = 'IsNotNull',
	IS_TRUE = 'IsTrue',
	IS_FALSE = 'IsFalse',
	INTERSECT = 'Intersect'
}

export interface OrderField {
	orderProperty: string;
	orderType: 'Ascending' | 'Descending';
}

export interface SelectField {
	column: string;
}

// --------------------------------------------------- RESPONSE

export interface WorkItemsResponse {
	total: number;
	workItems: WorkItem[];
}

// -------------------------------------------------- WORK ITEM CREATION REQUEST
export interface WorkItemCreationRequest {
	documentId: string;
	documentSeries: string;
	properties: WorkItemProperty[];
}

// -------------------------------------------------- WORK ITEM CREATION RESPONSE
export interface WorkItemCreationResponse {
	workItemId: number;
	workItem: WorkItem | null;
}

export interface WorkItemDetail {
	description: string;
	name: string;
	value: string;
	type: PropertyType;
}
