export class Pages {
	constructor(
		public name: string,
		public link: string,
		public homeFilter: boolean,
		public subtitle?: string,
		public url?: string,
		public service?: string
	) { }
}

export interface SectionsEndpoint {
	serviceId: string;
	serviceName: string;
	tiles: SectionsTilesEndpoint[];
}

export interface SectionsTilesEndpoint {
	name: string;
	link: string;
	subtitle: string;
	homeFilter: boolean;
	service: string;
}
