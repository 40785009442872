import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

@Injectable({
	providedIn: 'root'
})
export class HomeFilterEffects {

	constructor(
		protected actions$: Actions,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected store: Store<any>,
	) { }

}
