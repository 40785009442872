import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { StartsWithPipe } from 'app/shared/pipes/starts-with.pipe';
import { AlphabeticalOrderPipe } from './alphabetical-order.pipe';
import { AsFormControlPipe } from './as-form-control.pipe';
import { AsFormGroupPipe } from './as-form-group.pipe';
import { AsPdfDocPipe } from './as-pdf-doc.pipe';
import { AsXmlDocPipe } from './as-xml-doc.pipe';
import { BoldSpanPipe } from './bold-span.pipe';
import { BooleanToItalianPipe } from './boolean-to-italian.pipe';
import { ConvertdatePipe } from './convertdate.pipe';
import { CurrencyEurPipe } from './currency-eur.pipe';
import { DateShortPipe } from './date-short.pipe';
import { EnabledServicesFilterPipe } from './enabled-services-filter.pipe';
import { FilterFaqQuestionPipe } from './filter-faq-question.pipe';
import { FilterFaqPipe } from './filter-faq.pipe';
import { FilterSearchPipe } from './filter-search.pipe';
import { ForLoopPipe } from './for-loop.pipe';
import { HighlightSearchPipe } from './highlight-search.pipe';
import { IsoDateToDatePipe } from './iso-date-to-date.pipe';
import { IsoDatetimeToDatetimePipe } from './iso-datetime-to-datetime.pipe';
import { NumberLocalePipe } from './number-locale.pipe';
import { RemoveAsciiPipe } from './remove-ascii.pipe';
import { ShortenPipe } from './shorten.pipe';
import { SiNoPipe } from './si-no.pipe';
import { SpecialcharsPipe } from './specialchars.pipe';
import { ToLocaleStringPipe } from './to-locale-string.pipe';
import { UtcToLocalDatePipe } from './utc-to-local-date.pipe';
import { WordsPipe } from './words.pipe';

@NgModule({
	imports: [CommonModule],
	declarations: [
		UtcToLocalDatePipe,
		ForLoopPipe,
		IsoDateToDatePipe,
		SpecialcharsPipe,
		ConvertdatePipe,
		ShortenPipe,
		ToLocaleStringPipe,
		IsoDatetimeToDatetimePipe,
		CurrencyEurPipe,
		DateShortPipe,
		NumberLocalePipe,
		AlphabeticalOrderPipe,
		BooleanToItalianPipe,
		RemoveAsciiPipe,
		HighlightSearchPipe,
		FilterFaqPipe,
		FilterFaqQuestionPipe,
		BoldSpanPipe,
		StartsWithPipe,
		FilterSearchPipe,
		EnabledServicesFilterPipe,
		SiNoPipe,
		AsXmlDocPipe,
		AsPdfDocPipe,
		AsFormGroupPipe,
		AsFormControlPipe,
		WordsPipe
	],
	exports: [
		UtcToLocalDatePipe,
		ForLoopPipe,
		IsoDateToDatePipe,
		SpecialcharsPipe,
		ConvertdatePipe,
		ShortenPipe,
		ToLocaleStringPipe,
		IsoDatetimeToDatetimePipe,
		CurrencyEurPipe,
		DateShortPipe,
		NumberLocalePipe,
		AlphabeticalOrderPipe,
		BooleanToItalianPipe,
		RemoveAsciiPipe,
		HighlightSearchPipe,
		FilterFaqPipe,
		FilterFaqQuestionPipe,
		BoldSpanPipe,
		StartsWithPipe,
		FilterSearchPipe,
		EnabledServicesFilterPipe,
		SiNoPipe,
		AsXmlDocPipe,
		AsPdfDocPipe,
		AsFormGroupPipe,
		AsFormControlPipe,
		WordsPipe
	],
	providers: [
		UtcToLocalDatePipe,
		ForLoopPipe,
		IsoDateToDatePipe,
		SpecialcharsPipe,
		ConvertdatePipe,
		ShortenPipe,
		ToLocaleStringPipe,
		IsoDatetimeToDatetimePipe,
		DatePipe,
		CurrencyPipe,
		DecimalPipe,
		CurrencyEurPipe,
		DateShortPipe,
		NumberLocalePipe,
		BoldSpanPipe,
		StartsWithPipe,
		FilterSearchPipe,
		HighlightSearchPipe,
		SiNoPipe
	]
})
export class PipesModule {
}
