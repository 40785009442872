<div #placeholderContainer class="placeholder-container" [ngStyle]="style">

	<div class="error-content">

		<div class="error-icon" [ngStyle]="{'font-size': iconFontSize , 'color': iconColor}">
			<!-- icona senza tooltip -->
			<i *ngIf="!showTooltip; else showTooltipTemplate" class="error-icon" [ngClass]="icon"></i>
			<!-- icona con tooltip -->
			<ng-template #showTooltipTemplate>
				<span *ngIf="showTooltip"
					  class="current-company-name"
					  [tooltip]="message"
					  [tooltipAnimation]="true"
					  placement="auto">
					<i class="error-icon" [ngClass]="icon"></i>
				</span>
			</ng-template>
		</div>
		<!-- messaggio di errore-->
		<div *ngIf="showText">
			<p [ngStyle]="{'font-size': textFontSize, 'color': iconColor}">
				{{message}}
			</p>
		</div>
		<!-- retry button -->
		<div *ngIf="onRetry" class="mx-sm-3">
			<button class="btn btn-primary refresh-button"
					[ngStyle]="{'background': buttonColor, 'color': buttonTextColor}"
					[disabled]="isLoading"
					(click)="retry()">
					<i [class]="faIcons.FAS_REFRESH"
						[ngClass]="{ 'fa-icons': isLoading }">
					</i>
				{{retryButtonLabel}}
			</button>
		</div>
	</div>

</div>
