import { Injectable } from '@angular/core';
import {
	MassiveActionsConfigurationsGroup,
	MultipleActionsConfigurationsGroup,
	SingleActionsConfigurationsGroup
} from 'app/constants/actions/multiple-actions';
import { ActionsConfigurationHttpService } from 'app/core/common/ui-configuration/actions-configuration/actions-configuration-http.service';
import { ActionsConfigRequest, ConfigurationsGroup } from 'app/entities/ui-config/action/actions-configuration';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ActionsConfigurationService {

	constructor(public actionsConfigurationHttpService: ActionsConfigurationHttpService) { }
	public getSingleActionsByDocSeries(
		licenseId: string, siaCode: string, sectionCode: string, body: ActionsConfigRequest
	): Observable<ConfigurationsGroup[]> {
		return this.actionsConfigurationHttpService.getSingleActions(licenseId, siaCode, sectionCode, body)
			.pipe(
				take(1),
				map(response => response.configurationsGroup)
			);
	}

	public getSingleActionsByDefault(): Observable<ConfigurationsGroup[]> {
		return of(SingleActionsConfigurationsGroup);
	}

	public getMultipleActionsByDefault(): Observable<ConfigurationsGroup[]> {
		return of(MultipleActionsConfigurationsGroup);
	}

	public getMassiveActionsByDefault(): Observable<ConfigurationsGroup[]> {
		return of(MassiveActionsConfigurationsGroup);
	}

}
