<div class="card">
	<div class="card-header main-card-header">
		<div class="table-title">{{ title }}</div>
	</div>
	<!-- TODO: Feature flag - Esporta excel fatture attese -->
	<div class="table-actions">
		<gaw-actions-bar [actions]="tableActions$ | async"></gaw-actions-bar>
	</div>
	<div class="card-body">

		<form (ngSubmit)="formSubmit(f)" #f="ngForm">

			<button type="submit" class="hidden-submit-button"></button>

			<ng-container *ngIf="data$ | async as dataSource">
				<mat-table [dataSource]="dataSource" matSort matSortActive="created" matSortDisableClear matSortDirection="asc">
					<!--TEMPLATE DELLE COLONNE - l'ordine dei template non è importante-->

					<!-- For ID -->
					<ng-container *ngFor="let field of fields$ | async">
						<ng-container matColumnDef="{{field.id}}">

							<ng-container *ngIf="field.visible === true">
								<mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">

									<!-- Filtri di ricerca -->
									<ng-container *ngIf="useFilter">
										<div class="search-input-group mb-2">
											<!--<label for="{{ field.id }}"></label>-->
											<!--<input type="text" class="form-control input-header" id="{{ field.id }}"-->
											<!--name="{{ field.id }}"-->
											<!--ngModel>-->

											<ng-container [ngSwitch]="field.type">
												<ng-container *ngSwitchCase="fieldType.DATE">
													<label class="form-label" [attr.for]="field.id"></label>
													<input type="text" class="form-control" id="{{ field.id }}" name="{{ field.id }}" ngModel
																 #datePickerHtmlElement #dp="bsDatepicker" bsDatepicker
																 [bsConfig]="{ dateInputFormat: field?.['format'], containerClass: 'theme-blue', showWeekNumbers: false }"
																 (ngModelChange)="datePickerHtmlElement.focus()" autocomplete="off">
												</ng-container>

												<ng-container *ngSwitchCase="fieldType.CHECKBOX">
													<label class="form-label" [attr.for]="field.id"></label>
													<input type="checkbox" class="form-control" id="{{ field.id }}" name="{{ field.id }}"
																 ngModel>
												</ng-container>

												<ng-container *ngSwitchCase="fieldType.NUMBER">
													<label class="form-label" [attr.for]="field.id"></label>
													<input type="number" class="form-control" id="{{ field.id }}" name="{{ field.id }}"
																 ngModel>
												</ng-container>

												<ng-container *ngSwitchDefault>
													<label class="form-label" [attr.for]="field.id"></label>
													<input type="text" class="form-control" id="{{ field.id }}" name="{{ field.id }}"
																 ngModel>
												</ng-container>
											</ng-container>

										</div>
									</ng-container>

									<div class="column-label mb-2">
										{{field.name}}
									</div>

								</mat-header-cell>
								<ng-container *ngIf="dataSource.data && dataSource.data.length > 0">
									<mat-cell *matCellDef="let row" class="d-flex justify-content-center">
										{{row[field.id]}}
									</mat-cell>
								</ng-container>
							</ng-container>
						</ng-container>
					</ng-container>

					<!-- Row Actions Column -->
					<ng-container matColumnDef="{{ROW_ACTIONS_COLUMN_ID}}">
						<mat-header-cell *matHeaderCellDef></mat-header-cell>
						<ng-container *ngIf="dataSource.data && dataSource.data.length > 0">
							<mat-cell *matCellDef="let row">
								<div class="btn-group d-flex" role="group">
									<ng-container *ngFor="let action of rowActions">
										<button mat-icon-button type="button"
														class="d-flex align-items-center justify-content-center"
														(click)="executeAction(action, row);"
														color="{{action.color}}"
														tooltip="{{action.name}}"
														[tooltipAnimation]="true"
														placement="right">
											<i class="{{action.icon}}"></i>
										</button>
									</ng-container>
								</div>
							</mat-cell>
						</ng-container>
					</ng-container>

					<!--DEFINIZIONE DELL'ORDINAMENTO DELLE COLONNE-->
					<mat-header-row *matHeaderRowDef="fieldsOrder$ | async"></mat-header-row>

					<mat-row *matRowDef="let row; columns: fieldsOrder$ | async;"></mat-row>
				</mat-table>

				<ng-container *ngIf="!dataSource.data || dataSource.data.length < 1">
					<div class="no-item">
						<strong>{{noDataAvailable}}</strong>
					</div>
				</ng-container>
			</ng-container>
		</form>
	</div>

	<div class="card-footer">
		<ng-container *ngIf="usePagination">
			<div class="d-flex justify-content-center mt-2" *ngIf="(data$ | async)?.data?.length > 0">
				<app-pagination [itemsPerPage]="itemsPerPage" [totalItems]="totalItems" (pageChanged)="changePage($event)">
				</app-pagination>
			</div>
		</ng-container>
	</div>
</div>
