<div class="tab-box p-3">
	<!--PANEL HISTORY STATI CANALE-->
	<ng-template #error>
		<div class='error-message' [innerHTML]='errorMessage'></div>
	</ng-template>
	<ng-container *ngIf='documents$ | async as documents; else error'>
		<table class="table table-responsive table-striped">
			<thead>
				<tr>
					<th>
					</th>
					<th [style.width]='"25%"'>
						Progressivo lotto
					</th>
					<th [style.width]='"25%"'>
						Nome lotto
					</th>
					<th [style.width]='"25%"'>
						Data anomalia
					</th>
					<th [style.width]='"25%"'>
						Stato
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor='let document of documents'>
					<td>
						<ng-template #popoverTemplate class='popover-body'>
							<div class='popover-div'>
								<div *ngIf='showPopover'>
									<div *ngFor='let field of document.campi'>
										<span *ngIf='field.tipo === "S"'><b>{{field.label + ':'}} </b>{{field.value}}</span>
										<span *ngIf='field.tipo === "D"'><b>{{field.label + ':'}} </b>{{field.value | utcToLocalDate:
											"DD-MM-YYYY HH:mm" }}</span>
									</div>
								</div>
							</div>
						</ng-template>
						<div class='details-icon' [popover]='popoverTemplate' popoverTitle='Campi' #popoverDetails="bs-popover"
								 placement='auto' container='body' [outsideClick]='true' (click)='showDocDetails()'>
							<i [ngClass]="popoverDetails.isOpen ? faIcons.FAS_SEARCH_MINUS : faIcons.FAS_SEARCH_PLUS"></i>
						</div>
					</td>
					<td>
						<span>{{document.progSpool}}</span>
					</td>
					<td>
						<span>{{document.nomeFlusso}}</span>
					</td>
					<td>
						<span>{{document.dataAnomalia | utcToLocalDate: "DD-MM-YYYY HH:mm" }}</span>
					</td>
					<td>
						<span>{{document.descrizioneStato}}</span>
					</td>
				</tr>
			</tbody>
		</table>
	</ng-container>
</div>
