<div class='row'>
	<div class='col-md-10'>
		<div class='details-title' *ngIf='documentDetails'>
			<span class='badge doctype-label'
						[ngStyle]="{ 'background-color': color }">
				<span class='badge-text'>{{ documentDetails.keys['descTipoDocumento'] || documentDetails.docSeriesId }}</span>
			</span>
			<div class='t nd'>{{ docLabel }}</div>
		</div>
		<gaw-gawpass-details-main [data]='data' [boxDetails$]='boxDetails$'></gaw-gawpass-details-main>
	</div>
	<div class='col-md-2'>
		<ng-container *ngIf='singleActionsList$ | async as actions'>
			<ng-container *ngIf='documentDetails'>
				<h4 class='action px-0'>Azioni</h4>
				<div class='container1 results-content__actions_sidebars d-grid gap-2'>
					<ng-container *ngFor='let action of actions'>
						<button *ngIf='action.code !== singleActionsCode.openDetail && action.code !== singleActionsCode.spoolHistory'
										class='btn btn-primary btn-action'
										[ngClass]='{ yellow: action.highlighted === true }'
										type='button'
										(click)='executeAction(action, documentDetails.keys)'>
							<i class='btn-action-sidebar' [ngClass]='[action.icon]' aria-hidden='true'></i>
							{{ action.name }}
						</button>
					</ng-container>
				</div>
			</ng-container>
		</ng-container>
	</div>
</div>
