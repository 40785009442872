import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MetadataDescription } from '@ctel/gaw-commons';
import { GridColumn } from '@ctel/gaw-components';
import { ConfigService } from 'app/core/common/config/config.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { RelatedSectionData } from 'app/entities/sections/related-section-data';
import { Details } from 'app/modules/details/entities/details';
import { Observable, ReplaySubject, of, takeUntil } from 'rxjs';
import { PDDRequestModel } from '../entities/PDD-request-model';
import { Metadata, PDVDetailsResponse } from '../entities/PDV-details-response';
import { IGawconsDetailsComponentInterface } from '../entities/gawcons-details-component.interface';
import { Group } from '../entities/group';
import { GroupList } from '../entities/group-list';
import { PreviousVersion } from '../entities/previous-version';
import { RegistryList } from '../entities/registry-list';
import { ReportPayload } from '../entities/report-payload';
import { DocClass } from 'app/entities/doc-class/doc-class.interface';
import { Transfer, transferHandler, TransferService } from '@ctel/transfer-manager';

export interface ColumnOrchestratorObj {
	id: string;
	value: string;
}

@Injectable({
	providedIn: 'root'
})
export class ArchivioFiscaleHttpService implements OnDestroy {
	private archivioFiscaleHost: string;
	private ricercaGruppoMasterizzazioneUrl: string;
	private jsonContentType = 'application/json';
	private ricercaRegistriUrl: string;
	private versioniPrecedentiUrl: string;
	private columnsConfigurationUrl: string;
	private getColumnsUrl: string;
	private upsertColumnsUrl: string;
	private metadataDescUrl: string;
	private detailsUrl: string;
	private pdvDetailsUrl: string;
	private viewPdf: string;

	private destroy$ = new ReplaySubject<void>(1);
	private ricercaGruppoMasterizzazioneUrlNoAcl: string;
	private ricercaRegistriUrlNoAcl: string;
	private tuttiRegistriUrl: string;
	private tuttiRegistriInGruppoUrl: string;
	private registriConfiguratiUrl: string;
	private metadatiFRDocUrl: string;
	private metadataUrl: string;
	private fiscalYearsQuadratureUrl: string;
	private docClassesQuadratureUrl: string;
	private registriesQuadratureUrl: string;
	private downloadReportQuadratureUrl: string;
	private downloadReportBuchiQuadratureUrl: string;

	constructor(
		private http: HttpClient,
		private configService: ConfigService,
		private transferService: TransferService
	) {
		this.configService.whenAppConfig().pipe(takeUntil(this.destroy$))
			.subscribe((appconfig: AppConfig) => this.httpHostInit(appconfig));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	httpHostInit(appconfig: AppConfig) {
		this.archivioFiscaleHost = appconfig.webConsOrchestrator.http.host;
		this.columnsConfigurationUrl = `${this.archivioFiscaleHost}/v1/ArchivioFiscale/registro/{registryId}/colonneConfigurabili?IdLicenza={licenseId}&CodSia={siaCode}`;
		this.getColumnsUrl = `${this.archivioFiscaleHost}/v1/ArchivioFiscale/registro/{registryId}/colonne?IdLicenza={licenseId}&CodSia={siaCode}`;
		this.upsertColumnsUrl = `${this.archivioFiscaleHost}/v1/ArchivioFiscale/registro/{registryId}/colonne?IdLicenza={licenseId}&CodSia={siaCode}&ReplicaPersonalizzazione={replicaPersonalizzazione}`;
		this.ricercaGruppoMasterizzazioneUrl = `${this.archivioFiscaleHost}/v1/ArchivioFiscale/search/gruppi/{licenseId}/{siaCode}/{offset}/{docsPerPage}`;
		this.ricercaGruppoMasterizzazioneUrlNoAcl = `${this.archivioFiscaleHost}/v1/Configurazioni/search/gruppi/{licenseId}/{siaCode}/{offset}/{docsPerPage}/noAcl`;

		this.ricercaRegistriUrl = `${this.archivioFiscaleHost}/v1/ArchivioFiscale/search/registri/{licenseId}/{siaCode}/{offset}/{docsPerPage}`;
		this.versioniPrecedentiUrl = `${this.archivioFiscaleHost}/v1/ArchivioFiscale/documenti/versioniPrecedenti/{documentId}?IdLicenza={licenseId}&CodSia={siaCode}&AnnoFiscale={fiscalYear}&IdRegistro={registryId}`;
		this.metadataDescUrl = `${this.archivioFiscaleHost}/v1/ArchivioFiscale/registro/{registryId}/metadati/descrizioni`;
		this.detailsUrl = `${this.archivioFiscaleHost}/v1/ArchivioFiscale/documenti/{documentId}?IdLicenza={licenseId}&CodSia={siaCode}&AnnoFiscale={fiscalYear}&IdRegistro={registryId}`;
		this.viewPdf = `${this.archivioFiscaleHost}/v1/ArchivioFiscale/documenti/scarica/{idDoc}?IdLicenza={licenseId}&CodSia={siaCode}&AnnoFiscale={fiscalYear}&IdRegistro={registryId}&Download={download}`;
		this.pdvDetailsUrl = `${this.archivioFiscaleHost}/v1/PacchettiVersamento/{idPdv}?IdLicenza={licenseId}&CodSia={siaCode}&AnnoFiscale={fiscalYear}&IdRegistro={registryId}`;
		this.ricercaRegistriUrlNoAcl = `${this.archivioFiscaleHost}/v1/Configurazioni/search/registri/{licenseId}/{siaCode}/{groupId}/{offset}/{docsPerPage}/noAcl`;
		this.tuttiRegistriUrl = `${this.archivioFiscaleHost}/v1/Configurazioni/registri/{licenseId}/{siaCode}/noAcl`;
		this.tuttiRegistriInGruppoUrl = `${this.archivioFiscaleHost}/v1/Configurazioni/search/registri/{licenseId}/{siaCode}/{groupId}/noAcl`;
		this.metadatiFRDocUrl = `${this.archivioFiscaleHost}/v1/FirmaRemota/documenti/{progSpool}/{progBusta}/metadati`;
		this.metadataUrl = `${this.archivioFiscaleHost}/v1/Configurazioni/registri/{registryId}/metadati/{licenseId}/{siaCode}`;
		this.registriConfiguratiUrl = `${this.archivioFiscaleHost}/v1/Configurazioni/registri/profilo/{profileId}/{licenseId}/{siaCode}`;
		this.fiscalYearsQuadratureUrl = `${this.archivioFiscaleHost}/v1/Quadrature/anniFiscali/{licenseId}/{siaCode}`;
		this.docClassesQuadratureUrl = `${this.archivioFiscaleHost}/v1/Quadrature/classiDocumentali/{licenseId}/{siaCode}`;
		this.registriesQuadratureUrl = `${this.archivioFiscaleHost}/v1/Quadrature/registri/{licenseId}/{siaCode}`;
		this.downloadReportQuadratureUrl = `${this.archivioFiscaleHost}/v1/Quadrature/scarica/{licenseId}/{siaCode}?fileFormat={fileFormat}`;
		this.downloadReportBuchiQuadratureUrl = `${this.archivioFiscaleHost}/v1/Quadrature/scarica/buchi/{licenseId}/{siaCode}?fileFormat={fileFormat}`;
	}

	whenColumns(registryId: string, licenseId: string, siaCode: string): Observable<ColumnOrchestratorObj[]> {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		const url = this.getColumnsUrl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{registryId}', registryId);
		return this.http.get<ColumnOrchestratorObj[]>(url, options);
	}

	whenConfigurableColumns(registryId: string, licenseId: string, siaCode: string): Observable<GridColumn[]> {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		const url = this.columnsConfigurationUrl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{registryId}', registryId);
		const body = {
			'keyCodeMetadati': [...Array(41).keys()].slice(1).map(x => `campo${x}`)
		};
		return this.http.post<GridColumn[]>(url, body, options);

	}

	whenGroups(licenseId: string, siaCode: string, offset: number, docsPerPage: number, search?: string): Observable<GroupList> {
		const options = {
			headers: new HttpHeaders({
				'Content-Type': this.jsonContentType
			}), params: search ? { descrizioneGruppo: search } : null
		};
		const url = this.ricercaGruppoMasterizzazioneUrl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{offset}', offset.toString())
			.replace('{docsPerPage}', docsPerPage.toString());
		return this.http.get<GroupList>(url, options);
	}

	whenRegistries(
		licenseId: string,
		siaCode: string,
		idGruppo: number,
		offset: number,
		docsPerPage: number,
		descrizioneRegistro?: string
	): Observable<RegistryList> {
		let params= { idGruppo, descrizioneRegistro};
		if (!idGruppo)
			delete params.idGruppo;

		if (!descrizioneRegistro)
			delete params.descrizioneRegistro;

		const options = {
			headers: new HttpHeaders({
				'Content-Type': this.jsonContentType
			}), params
		};
		const url = this.ricercaRegistriUrl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{offset}', offset.toString())
			.replace('{docsPerPage}', docsPerPage.toString());
		return this.http.get<RegistryList>(url, options);
	}

	whenPreviousVersions(
		documentId: string,
		licenseId: string,
		siaCode: string,
		fiscalYear: string,
		registryId: string
	): Observable<PreviousVersion[]> {
		const url = this.versioniPrecedentiUrl
			.replace('{documentId}', documentId)
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{fiscalYear}', fiscalYear)
			.replace('{registryId}', registryId);
		const options = this.getHttpOptions(this.jsonContentType, 'json');

		return this.http.get<PreviousVersion[]>(url, options);
	}

	whenUpserColumns(registryId: string, licenseId: string, siaCode: string, columns, replicaPersonalizzazione:boolean) {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		const url = this.upsertColumnsUrl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{registryId}', registryId)
			.replace('{replicaPersonalizzazione}',replicaPersonalizzazione ? 'true' : 'false');
		return this.http.put(url, columns, options);
	}

	whenMetadataDescriptions(
		registryId: string,
		licenseId: string,
		siaCode: string,
		relatedSectionData: RelatedSectionData[]
	): Observable<MetadataDescription[]> {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		const url = this.metadataDescUrl
			.replace('{registryId}', registryId);
		const body = {
			idLicenza: licenseId,
			codiceSia: siaCode,
			keyCodeMetadati: relatedSectionData[0].textSearchMetadata
		};
		return this.http.post<MetadataDescription[]>(url, body, options);
	}

	whenMetadati(documentId: string, licenseId: string, siaCode: string, fiscalYear: string, registryId: string): Observable<Details> {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		const url = this.detailsUrl
			.replace('{documentId}', documentId)
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{registryId}', registryId)
			.replace('{fiscalYear}', fiscalYear);

		return this.http.get<Details>(url, options);
	}

	whenPdfZip(data: IGawconsDetailsComponentInterface, download: boolean) {
		const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'blob');
		const url = this.viewPdf
			.replace('{licenseId}', data.licenseId)
			.replace('{siaCode}', data.siaCode)
			.replace('{idDoc}', (data.documentId || data.elasticId).split('_')[0])
			.replace('{fiscalYear}', data.keys['annoFiscale'])
			.replace('{registryId}', data.registryId)
			.replace('{download}', `${download}`);
		return this.http.get<Blob>(url, options);
	}

	requestPDD(pddModel: PDDRequestModel): Observable<PDDRequestModel> {
		return of(pddModel);
	}

	getPdvDetails(idPdv: string, licenseId: string, siaCode: string, fiscalYear: string, registryId: string) {
		const url = this.pdvDetailsUrl
			.replace('{idPdv}', idPdv)
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{fiscalYear}', fiscalYear)
			.replace('{registryId}', registryId);
		return this.http.get<PDVDetailsResponse>(url);
	}

	whenGroupsNoAcl(licenseId: string, siaCode: string, offset: number, docsPerPage: number, search: string) {
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}), params: search ? { descrizioneGruppo: search } : null
		};
		const url = this.ricercaGruppoMasterizzazioneUrlNoAcl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{offset}', offset.toString())
			.replace('{docsPerPage}', docsPerPage.toString());
		return this.http.get<GroupList>(url, options);
	}

	getAllRegistries(licenseId, siaCode): Observable<Group[]> {
		const url = this.tuttiRegistriUrl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<Group[]>(url);
	}

	getAllRegistriesByGroup(licenseId: string, siaCode: string, idGruppo: string) {
		const url = this.tuttiRegistriInGruppoUrl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{groupId}', idGruppo);
		return this.http.get<RegistryList>(url);
	}

	getConfiguredRegistries(idProfilo: string, licenseId: string, siaCode: string) {
		const url = this.registriConfiguratiUrl
			.replace('{profileId}', idProfilo)
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<Group[]>(url);

	}

	whenMetadatiFirmaRemotaDocumenti(progSpool: string, progBusta: string) {
		const options = this.getHttpOptions(this.jsonContentType, 'json');
		const url = this.metadatiFRDocUrl
			.replace('{progSpool}', progSpool)
			.replace('{progBusta}', progBusta);

		return this.http.get<Details>(url, options);
	}

	whenRegistriesNoAcl(licenseId: string, siaCode: string, idGroup: number, offset: number, docsPerPage: number, search: string) {
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}), params: search ? { descrizioneRegistro: search } : null
		};
		const url = this.ricercaRegistriUrlNoAcl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{groupId}', idGroup.toString())
			.replace('{offset}', offset.toString())
			.replace('{docsPerPage}', docsPerPage.toString());
		return this.http.get<RegistryList>(url, options);
	}

	getMetadata(idRegistro: number, licenseId: string, siaCode: string) {
		const url = this.metadataUrl
			.replace('{registryId}', idRegistro.toString())
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<Metadata[]>(url);
	}

	getFiscalYearsQuadrature(licenseId: string, siaCode: string) {
		const url = this.fiscalYearsQuadratureUrl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<number[]>(url);
	}

	getDocClassesQuadrature(licenseId: string, siaCode: string) {
		const url = this.docClassesQuadratureUrl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<DocClass[]>(url);
	}

	getRegistriesQuadrature(licenseId: string, siaCode: string) {
		const url = this.registriesQuadratureUrl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<object[]>(url);
	}

	downloadReportQuadrature(licenseId: string, siaCode: string, fileFormat: string, body: ReportPayload): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.downloadReportQuadratureUrl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{fileFormat}', fileFormat);
		return this.http.post(url, body, options).pipe(
			transferHandler(this.transferService)
		);
	}

	downloadReportBuchiQuadrature(licenseId: string, siaCode: string, fileFormat: string, body: ReportPayload): Observable<Transfer> {
		const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
		const url = this.downloadReportBuchiQuadratureUrl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode)
			.replace('{fileFormat}', fileFormat);
		return this.http.post(url, body, options).pipe(
			transferHandler(this.transferService)
		);
	}

	private getHttpOptions(contentType: string, responseType: string): object {
		const headers = new HttpHeaders().set('Content-Type', contentType);
		let options;
		switch (responseType) {
			case 'blob':
				options = {
					headers,
					responseType: 'blob' as const
				};
				break;
			case 'json':
			default:
				options = {
					headers,
					responseType: 'json' as const
				};
		}
		return options;
	}
}
