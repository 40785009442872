<div class='modal-body'>
	<div class='main-div'>
		<div class='row'>
			<div class='col-12 confirmation'>

				<p><strong>Proseguire con l'azione
					"{{data?.title}}"?</strong></p>
			</div>
		</div>
		<div class='row'>
			<div class='col-12'>

				<label class='form-label' for='note'><strong>Note <span class='text-danger'>*</span></strong></label>
				<textarea id='note' class='form-control' rows='3' [(ngModel)]='notes'></textarea>
			</div>
		</div>
	</div>
</div>
<div class='modal-footer'>
	<button class='btn btn-success'
			[disabled]='!notes'
			id='action-confirm-button'
			(click)='executeAction()'><strong>Conferma</strong>
	</button>
	<button type='button' class='btn btn-danger' (click)='modal.hide()'>Annulla</button>
</div>
