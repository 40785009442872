import { HomeFilter, HomeFilterRangeType } from '@ctel/gaw-commons';
import { IFilter, IMetric, IOrderBy, IPaging } from '@ctel/search-filter-store';
import { Action, createReducer, on } from '@ngrx/store';
import {
	dataInserimento21FilterConfig,
	dataInserimento22FilterConfig,
	dataProtocollazioneCtelFilterConfig,
	dataRicezione06FilterConfig,
	dataSpedizione10FilterConfig,
	descrizioneStato06FilterConfig,
	descrizioneStato10FilterConfig,
	descrizioneStato21FilterConfig,
	descrizioneStato22FilterConfig,
	descrizioneStato39FilterConfig,
	gawconsFilterConfig,
	payableFilterConfig,
	receivableFilterConfig,
	timestampCtelRicezioneSdiFilterConfig,
	timestampHomepageFilterConfig
} from 'app/constants/filters/filters-config';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import * as HomeFilterActions from 'app/modules/homepage/core/filters/store/home-filter.actions';

export interface HomepageFiltersModuleState {
	homepageFilterState: HomepageFilterState;
}

export const homeFilterModuleReducers = {
	homepageFilterState
};

// export const homeFilterModuleFeatureKey = 'homeFilterModule';

/**
 * Reducer per lo stato.
 * @param state lo stato attuale
 * @param action la azione di mutamento dello stato
 */
export function homepageFilterState(state: HomepageFilterState | undefined, action: Action) {
	return _homeFilterReducer(state, action);
}

export const dataProtocollazioneCtelFilterConfigHome: HomeFilter = {
	...dataProtocollazioneCtelFilterConfig,
	dateRangeType: HomeFilterRangeType.ULTIMO_TRIMESTRE
};

export const timestampCtelRicezioneSdiFilterConfigHome: HomeFilter = {
	...timestampCtelRicezioneSdiFilterConfig,
	dateRangeType: HomeFilterRangeType.ULTIMO_TRIMESTRE
};

export const gaw30FilterConfigHome: HomeFilter = {
	...timestampHomepageFilterConfig,
	dateRangeType: HomeFilterRangeType.ULTIMO_TRIMESTRE
};

export const gawpecFilterConfigHome: HomeFilter[] = [
	{
		...dataInserimento22FilterConfig,
		dateRangeType: HomeFilterRangeType.ULTIMO_TRIMESTRE

	},
	descrizioneStato22FilterConfig

];
export const gawpostFilterConfigHome: HomeFilter[] = [
	{
		...dataRicezione06FilterConfig,
		dateRangeType: HomeFilterRangeType.ULTIMO_TRIMESTRE
	},
	descrizioneStato06FilterConfig
];

export const gawediFilterConfigHome: HomeFilter[] = [
	{
		...dataSpedizione10FilterConfig,
		dateRangeType: HomeFilterRangeType.ULTIMO_TRIMESTRE
	},
	descrizioneStato10FilterConfig
];

export const gawmailFilterConfigHome: HomeFilter[] = [
	{
		...dataInserimento21FilterConfig,
		dateRangeType: HomeFilterRangeType.ULTIMO_TRIMESTRE
	},
	descrizioneStato21FilterConfig
];

export const gawconsFilterConfigHome: HomeFilter[] = [{
	...gawconsFilterConfig,
	dateRangeType: HomeFilterRangeType.ULTIMO_TRIMESTRE
},
	gawconsFilterConfig
];

export const gawnoticeFilterConfigHome: HomeFilter[] = [
	{
		...dataProtocollazioneCtelFilterConfig,
		dateRangeType: HomeFilterRangeType.ULTIMO_TRIMESTRE
	},
	descrizioneStato39FilterConfig
];

export const gawFEFilterConfigHome: HomeFilter[] = [
	{
		...timestampHomepageFilterConfig,
		dateRangeType: HomeFilterRangeType.ULTIMO_TRIMESTRE
	},
	{
		...dataProtocollazioneCtelFilterConfig,
		dateRangeType: HomeFilterRangeType.ULTIMO_TRIMESTRE
	},
	{
		...payableFilterConfig
	},
	{
		...receivableFilterConfig
	}
];

/**
 * Model per lo stato.
 */
export interface HomepageFilterState {
	docSeriesId: string;
	licenseId: string;
	siaCode: string;
	filters: IFilter[];
	metrics?: IMetric[];
	selectMetadata?: string[];
	// search: IFullTextSearch;
	paging?: IPaging;
	orderBy?: IOrderBy[];
	gaw30HomeFilters: HomeFilter[];
	gawfeHomeFilters: HomeFilter[];
	gawpecHomeFilters: HomeFilter[];
	gawpostHomeFilters: HomeFilter[];
	gawediHomeFilters: HomeFilter[];
	gawmailHomeFilters: HomeFilter[];
	gawnoticeHomeFilters: HomeFilter[];
	gawconsHomeFilters: HomeFilter[];

}

/**
 * Definizione dello stato iniziale.
 */
const initialState: HomepageFilterState = {
	docSeriesId: 'ALL',
	licenseId: '',
	siaCode: '',
	filters: <IFilter[]>[],
	// search: <IFullTextSearch> {
	// fullText: true,
	// value: '',
	// metadataList: []
	// },
	paging: null,
	orderBy: null,
	metrics: null,
	selectMetadata: null,
	gaw30HomeFilters: <HomeFilter[]>[gaw30FilterConfigHome],
	gawfeHomeFilters: <HomeFilter[]>[...gawFEFilterConfigHome],
	gawpecHomeFilters: <HomeFilter[]>gawpecFilterConfigHome,
	gawpostHomeFilters: <HomeFilter[]>gawpostFilterConfigHome,
	gawediHomeFilters: <HomeFilter[]>gawediFilterConfigHome,
	gawmailHomeFilters: <HomeFilter[]>gawmailFilterConfigHome,
	gawnoticeHomeFilters: <HomeFilter[]>gawnoticeFilterConfigHome,
	gawconsHomeFilters: <HomeFilter[]>gawconsFilterConfigHome

};

const _onChangeHomeDateFilterRequested = (currentState, { payload }) => {
	const newState = {
		...currentState
	};

	if (payload.section === 'GAW30')
		newState.gaw30HomeFilters = [...payload.homeFilters];

	if (payload.section === 'GAWPEC')
		newState.gawpecHomeFilters = [...payload.homeFilters, { ...descrizioneStato22FilterConfig }];

	if (payload.section === 'GAWMAIL')
		newState.gawmailHomeFilters = [...payload.homeFilters, { ...descrizioneStato21FilterConfig }];

	if (payload.section === 'GAWPOST')
		newState.gawpostHomeFilters = [...payload.homeFilters, { ...descrizioneStato06FilterConfig }];

	if (payload.section === 'GAWEDI')
		newState.gawediHomeFilters = [...payload.homeFilters, { ...descrizioneStato10FilterConfig }];

	if (payload.section === 'GAWNOTICE')
		newState.gawnoticeHomeFilters = [...payload.homeFilters, { ...descrizioneStato39FilterConfig }];

	if (payload.section === 'GAWCONS')
		newState.gawconsHomeFilters = [...payload.homeFilters, { ...gawconsFilterConfig }];

	if (payload.section === 'GAWFE') {
		newState.gawfeHomeFilters = [{
			...payableFilterConfig
		},
			{
				...receivableFilterConfig
			}, ...payload.homeFilters];

		const newFilters = newState.filters.filter((value: IFilter) => value.metadata !== MetadataEnum.DATA_INSERIMENTO
			&& value.metadata !== payload.section
			&& value.metadata !== MetadataEnum.DATA_RICEZIONE
			&& value.metadata !== MetadataEnum.DATA_RICEZIONE_06
			&& value.metadata !== MetadataEnum.DATA_SPEDIZIONE_10
			&& value.metadata !== MetadataEnum.DATA_INSERIMENTO_22
			&& value.metadata !== MetadataEnum.DATA_INSERIMENTO_21
			&& value.metadata !== MetadataEnum.DATA_INSERIMENTO_39
			&& value.metadata !== MetadataEnum.DATA_FATTURA
			&& value.metadata !== MetadataEnum.DESCRIZIONE_STATO_22_KW
			&& value.metadata !== MetadataEnum.DESCRIZIONE_STATO_21_KW
			&& value.metadata !== MetadataEnum.DESCRIZIONE_STATO_10_KW
			&& value.metadata !== MetadataEnum.DESCRIZIONE_STATO_39_KW
			&& value.metadata !== MetadataEnum.DESCRIZIONE_STATO_06_KW);
		newState.filters = newFilters.concat([...payload.homeFilters]);
		Object.keys(newState).forEach(value => {
			if (newState[value] && typeof newState[value] !== 'string') {
				const metas = newState[value].map(meta => meta.metadata);
				newState[value] = newState[value].filter((filter, index) => index === metas.lastIndexOf(filter.metadata));
			}
		});

	}
	return newState;
};

// const _onRefreshHomeDateFilterPayload = (currentState, { payload } ) => {
// 	return produce<HomepageFilterState, HomepageFilterState>(currentState, draftState => {
// 		const filterPayload = payload.filterPayload;

// draftState.docSeriesId = filterPayload.docSeriesId;
// draftState.licenseId = filterPayload.licenseId;
// draftState.siaCode = filterPayload.siaCode;
// draftState.filters = filterPayload.filters;
// draftState.search = filterPayload.search;
// draftState.paging = filterPayload.paging;
// draftState.orderBy = filterPayload.orderBy;
// draftState.metrics = filterPayload.metrics;
// draftState.selectMetadata = filterPayload.selectMetadata;
// draftState.cachedReceivableHomeFilters = [dataProtocollazioneCtelFilterConfigHome];
// draftState.cachedPayableHomeFilters = [timestampCtelRicezioneSdiFilterConfigHome];
// 		return draftState;
// 	});
// };

const _homeFilterReducer = createReducer(
	initialState,
	on(HomeFilterActions.changeHomepageDateFilterRequested, _onChangeHomeDateFilterRequested)
	// on(HomeFilterActions.resetHomepageDateFilterPayload, _onRefreshHomeDateFilterPayload)
);
