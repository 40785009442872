<form #form="ngForm" *ngIf="anomalyDetails$ | async as anomalyDetails">
  <div class="tab-box p-3">
    <div class="contact-detail-container container-fluid">
      <div class="col-12">
        <div class="row">
          <div class="col-md-4">
            <label class="form-label" for="anomalia">Anomalia</label>
            <input
              type="text"
              class="form-control input-md"
              id="anomalia"
              name="anomalia"
              [(ngModel)]="anomalyDetails.anomalia"
              readonly
            />
          </div>

          <div class="col-md-4">
            <label class="form-label" for="progressivo-lotto">Progressivo lotto</label>
            <input
              type="text"
              class="form-control input-md"
              id="progressivo-lotto"
              name="progressivo-lotto"
              [(ngModel)]="anomalyDetails.progressivoLotto"
              readonly
            />
          </div>
          <div class="col-md-4">
            <label class="form-label" for="nome-lotto">Nome lotto</label>
            <input
              type="text"
              class="form-control input-md"
              id="nome-lotto"
              name="nome-lotto"
              [(ngModel)]="anomalyDetails.nomeLotto"
              readonly
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="form-label" for="progressivo">Progressivo</label>
            <input
              type="text"
              class="form-control input-md"
              id="progressivo"
              name="progressivo"
              [(ngModel)]="anomalyDetails.progressivo"
              readonly
            />
          </div>
          <div class="col-md-4">
            <label class="form-label" for="data-anomalia">Data anomalia</label>
            <input
              type="text"
              class="form-control input-md"
              id="data-anomalia"
              name="data-anomalia"
              [value]="anomalyDetails.dataAnomalia | utcToLocalDate : 'DD-MM-YYYY'"
              readonly
            />
          </div>
        </div>

        <hr style="margin-top: 35px" />

        <div class="row">
          <ng-container *ngFor="let field of anomalyDetails.campi">
            <div class="col-md-4">
              <label class="form-label" for="{{ field.idCampo }}">{{ field.label }}</label>
              <ng-container *ngIf="field.tipo === 'D'">
                <span class="far fa-question-circle question" tooltip="Formato ammesso aaaa-mm-gg"> </span>
                <span class="sr-only">Formato ammesso aaaa-mm-gg</span>
              </ng-container>
              <input
                *ngIf="field.tipo === 'D'; else noDate"
                type="text"
                class="form-control input-md"
                id="{{ field.idCampo }}D"
                name="{{ field.idCampo }}"
                [(ngModel)]="field.value"
                [readonly]="!edit"
                pattern="^\d{4}\-(0?[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01]|([1-9]))$"
              />
              <ng-template #noDate>
                <input
                  type="text"
                  class="form-control input-md"
                  id="{{ field.idCampo }}"
                  name="{{ field.idCampo }}"
                  [(ngModel)]="field.value"
                  [readonly]="!edit"
                />
              </ng-template>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end" *ngIf="edit">
      <button
        type="submit"
        class="btn btn-primary"
        (click)="save(anomalyDetails)"
        [disabled]="!form.valid || disableButton"
      >
        Salva
      </button>
    </div>
  </div>
</form>
