import { Injectable, OnDestroy } from '@angular/core';
import { FilterStatus } from '@ctel/gaw-commons';
import { Transfer, TransferService, TransferUtils } from '@ctel/transfer-manager';
import { AppErrorBuilder } from 'app/core/common/error/app-error-builder';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { ActionConfirmationModalComponent } from 'app/core/common/modals/action-confirmation-modal/action-confirmation-modal.component';
import { ExcelFormatModalComponent } from 'app/core/common/modals/excel-format-modal/excel-format-modal.component';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { TopProgressBarService } from 'app/core/common/spinner/top-progress-bar/top-progress-bar.service';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { MassiveActionRequest } from 'app/entities/ui-config/action/massive-action-request';
import { EMPTY, Observable, Subject, combineLatest, of } from 'rxjs';
import { catchError, count, delay, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DocumentsSeriesService } from '../../documents-series/documents-series.service';
import { DocumentsService2 } from '../../documents/documents.service';
import { FilterService2 } from '../../filters/filter.service';
import { ActionsHttpService } from '../actions-http.service';
import { AppError } from 'app/core/common/error';

// tslint:enaable:max-line-length

/**
 * Callback delle azioni massive
 */
@Injectable({
	providedIn: 'root'
})
export class AllDocumentsCallback implements OnDestroy {

	private readonly _filterPayload$: Observable<FilterStatus>;
	private readonly _totalDocuments$: Observable<number>;
	private concurrentDownloads = 0;

	private destroy$ = new Subject<void>();

	constructor(
		private documentsService: DocumentsService2,
		private filterService: FilterService2,
		private notificationService: NotificationService,
		private documentsSeriesService: DocumentsSeriesService,
		private actionsHttpService: ActionsHttpService,
		private transferService: TransferService,
		private progressBarService: TopProgressBarService
	) {
		this._totalDocuments$ = documentsService.whenTotalDocuments();
		this._filterPayload$ = filterService.whenFilterValue();
		this.transferService.whenTransfers().pipe(
			switchMap(value => of(Object.values<Transfer>(value)).pipe(
				mergeMap(value1 => value1),
				count(v => !TransferUtils.isComplete(v)),
				tap(v => this.concurrentDownloads = v),
				takeUntil(this.destroy$)
			))
		).subscribe();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	/**
	 * Funzione di esporta excel massivo
	 * @param action
	 */
	public exportXLSX(action: ActionObject, extraParams?: { sectionCode: unknown }): Observable<unknown> {

		if (this.concurrentDownloads >= 2) {
			this.notificationService.showSweetAlert(
				NotificationType.INFO,
				'Attenzione',
				'Un massimo di due trasferimenti contemporanei sono supportati, nel caso di esportazione massiva');
			return EMPTY;
		}

		if (!this.checkActionInput(action))
			throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
				.description('Azione non valida')
				.info('action', action)
				.build();

		return this._totalDocuments$
			.pipe(
				mergeMap(() => combineLatest([this._filterPayload$])
					.pipe(
						take(1),
						switchMap(([payload]) => {
							// Modal della scelta del formato dell'excel
							const showModal = () => {
								this.notificationService.showModal(
									NotificationType.GENERAL,
									{
										title: 'Esporta risultati in Excel',
										customFooter: true,
										childComponent: ExcelFormatModalComponent,
										childData: {
											backButton: true,
											action,
											actionType: 'massive',
											docSeriesDescription: this.documentsSeriesService.getDocSeriesLabel(),
											massivePayload: payload,
											service: 'GAW30',
											sectionCode: extraParams ? extraParams.sectionCode : null
										}
									}
								);
							};

							showModal();
							return EMPTY;
						})
					)
				),
				take(1)
			);
	}

	/**
	 * Funzione di nascondi documenti
	 * @param action
	 * @param hide
	 */
	public hideAndRestoreMassiveDocuments(action: ActionObject, hide: boolean): Observable<unknown> {

		if (!this.checkActionInput(action))
			throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
				.description('Azione non valida')
				.info('action', action)
				.build();

		const errorAction$ = () => {
			this.notificationService.showSweetAlert(
				NotificationType.ERROR,
				'Errore durante l\'esecuzione dell\'azione',
				''
			);
			return EMPTY;
		};

		const executeAction$ = payload => {
			const body = <MassiveActionRequest>{
				elasticRequest: payload
			};
			return this.actionsHttpService.whenHideAndShowDocumentsMassive(action.url, body, hide)
				.pipe(
					take(1),
					tap((response) => {
						// se l'azione è nascondi documenti
						if (response.esitoOK && hide)
							this.notificationService.showSweetAlert(
								NotificationType.SUCCESS,
								'Documenti nascosti con successo',
								response.message
							);
						else if (response.esitoOK && !hide)
							// se l'azione è ripristina documenti
							this.notificationService.showSweetAlert(
								NotificationType.SUCCESS,
								'Documenti ripristinati con successo',
								response.message
							);
						else
							this.notificationService.showSweetAlert(
								NotificationType.ERROR,
								'Errore durante l\'esecuzione dell\'azione',
								response.message
							);
					}),
					// TAM: DECOMMENTARE PER AZIONI MASSIVE
					delay(1000),
					tap(() => this.documentsService.refreshDocuments()),
					catchError(() => errorAction$())
				);
		};

		return this._totalDocuments$
			.pipe(
				mergeMap(docCount => this._filterPayload$
					.pipe(
						take(1),
						switchMap(filterPayload => {
							// se l'azione è nascondi documenti
							if (hide)
								this.notificationService.showModal(NotificationType.GENERAL, {
									title: 'Nascondi documenti',
									childComponent: ActionConfirmationModalComponent,
									childData: {
										action: executeAction$(filterPayload),
										title: 'Nascondi documenti',
										docCount,
										maxDocCount: 10000
									}
								});
							else
								// se l'azione è ripristina documenti
								this.notificationService.showModal(NotificationType.GENERAL, {
									title: 'Ripristina documenti',
									childComponent: ActionConfirmationModalComponent,
									childData: {
										action: executeAction$(filterPayload),
										title: 'Ripristina documenti',
										docCount,
										maxDocCount: 10000
									}
								});

							return EMPTY;
						})
					)
				),
				take(1)
			);
	}

	public moveToTrashMassive(action: ActionObject): Observable<unknown> {
		if (!this.checkActionInput(action))
			throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
				.description('Azione non valida')
				.info('action', action)
				.build();

		this.notificationService.showSweetAlert(
			NotificationType.QUESTION,
			'Sposta nel Cestino',
			'Vuoi spostare nel cestino tutti i documenti?',
			() => {
				this.progressBarService.pushRequest();
				this._totalDocuments$.pipe(
					mergeMap(() => this._filterPayload$
						.pipe(
							take(1),
							switchMap((filter) => this.actionsHttpService.whenMoveToTrash(action.url, filter)),
							catchError(() => {
								this.progressBarService.popRequest();
								this.notificationService.showSweetAlert(
									NotificationType.ERROR,
									'Sposta nel Cestino',
									'Si sono verificati dei problemi e non è stato possibile spostare i documenti nel Cestino',
								);
								return EMPTY;
							}),
							delay(1000),
							tap(() => {
								this.progressBarService.popRequest();
								this.documentsService.refreshDocuments();
								this.notificationService.showSweetAlert(
									NotificationType.INFO,
									'Sposta nel Cestino',
									'La procedura di cancellazione dei documenti selezionati potrebbe richiedere un po’ di tempo. ' +
									'Una volta terminata la procedura riceverai una notifica (campanella)',
								);
							}),
						)
					),
					take(1),
				).subscribe();
			}
		);

		return EMPTY;
	}
	private blockMoreThan2Request(): boolean {
		if (this.concurrentDownloads >= 2) {
			this.notificationService.showSweetAlert(
				NotificationType.INFO,
				'Attenzione',
				'Un massimo di due trasferimenti contemporanei sono supportati, nel caso di esportazione massiva');
			return true;
		}
		return false;
	}

	public asyncDownloadMassiveXML(){
		if (this.blockMoreThan2Request()) {
			return EMPTY;
		}

		const onConfirm = () => {
			return combineLatest([this._filterPayload$]).pipe(
				take(1),
				switchMap(([payload]) => {

					return this.actionsHttpService.asyncXMLMassiveDownload(payload).pipe(
						catchError((err: AppError) => {
							if (err.type === ErrorTypes.HTTP_NOT_FOUND) {
								this.notificationService.showSweetAlert(
									NotificationType.ERROR,
									'Uno o più documenti non trovati. Lo zip non verrà scaricato',
									'');
								return EMPTY;
							}

							this.notificationService.showSweetAlert(NotificationType.ERROR, `Errore durante l'esecuzione dell'azione`, '');
							return EMPTY;
						}),
						tap(() => {
							this.notificationService.showSweetAlert(
								NotificationType.INFO,
								'La richiesta di esportazione è stata presa in carico. Riceverai una notifica quando verrà completata.',
								''
							);
						})
					);
				})
			);
		};

		this.notificationService.showSweetAlert(
			NotificationType.QUESTION,
			'Attenzione:',
			`La richiesta potrebbe superare il limite consentito di 150.000 documenti o 2GB di dati.<br>
				Lo zip scaricato potrebbe non contenere tutti i documenti.
				In ogni caso l'operazione avverrà in differita e si riceverà una notifica e una email al termine del processo.`,
			() => onConfirm().pipe(take(1)).subscribe()
		);

		return EMPTY;
	}

	// Controllo sull'azione in input, ritorna true se l'url è valido
	// noinspection JSMethodCanBeStatic
	private checkActionInput(action): boolean {
		return !!(action && action.url);
	}
}
