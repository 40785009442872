import { Injectable } from '@angular/core';
import { DocumentState } from '@ctel/search-filter-store';
import { Store } from '@ngrx/store';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import {
	ActionsConfigRequest,
	ConfigurationsGroup,
	DocMetadata
} from 'app/entities/ui-config/action/actions-configuration';
import * as _ from 'lodash';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { catchError, take, tap } from 'rxjs/operators';
import {
	ActionsConfigurationService
} from '../../ui-configuration/actions-configuration/actions-configuration.service';
import { ActionsByType } from './actions-by-type';

/**
 * Questa implementazione esiste unicamente per la nuova versione dei filtri che si
 * basa sugli stati applicativi (ngrx).
 */
@Injectable({
	providedIn: 'root'
})
export class PdvActionsService2 {
	private singleActionsList$ = new ReplaySubject<ActionObject[]>(1);
	private actionLoadingError$ = new BehaviorSubject<boolean>(false);
	constructor(
		private actionsConfigurationService: ActionsConfigurationService,
		private store: Store<DocumentState>,
		private actionsByType: ActionsByType
	) { }

	sendLoadingActionError(value: boolean) {
		this.actionLoadingError$.next(value);
	}

	whenLoadingActionError(): Observable<boolean> {
		return this.actionLoadingError$.asObservable();
	}

	// azioni singole
	whenSingleActionsList(): Observable<ActionObject[]> {
		return this.singleActionsList$.asObservable();
	}

	sendSingleActionsList(actions: ActionObject[]) {
		this.singleActionsList$.next(actions);
	}

	// doc � facoltativo solo quando richiamiamo il metodo per ottenere l'endpoint per la visualizzazione anteprima del pdf nel dettaglio
	requestSingleActionsConfig(
		licenseId: string,
		siaCode: string,
		sectionCode: string,
		keys: any,
		doc?: Document
	): Observable<ConfigurationsGroup[]> {
		const newMetadataList: DocMetadata[] = [];

		Object.keys(keys).forEach(value => newMetadataList.push({
			keyCode: value,
			value: keys[value]
		})
		);

		const body: ActionsConfigRequest = {
			progSpool: keys.progSpool,
			progBusta: keys.progBusta,
			docMetadata: newMetadataList
		};

		return this.actionsConfigurationService.getSingleActionsByDocSeries(licenseId, siaCode, sectionCode, body).pipe(
			tap((config) => {
				this.sendSingleActionsList(this.mergeActionConfig(config, 'single'));
				this.sendLoadingActionError(false);
			}),
			catchError((err: unknown) => {
				this.sendLoadingActionError(true);
				return EMPTY;
			}),
			take(1)
		);
	}

	mergeActionConfig(configuration: ConfigurationsGroup[], actionsType: 'single' | 'multi' | 'massive'): ActionObject[] {
		let actionsCatalog = [];
		switch (actionsType) {
			case 'single':
				actionsCatalog = _.cloneDeep(this.actionsByType.singleActions) as ActionObject[];
				break;
		}

		const actionsList: ActionObject[] = [];
		if (configuration)
			for (let i = 0; i < configuration.length; i++) {
				const action: ActionObject = actionsCatalog.find((obj) => obj.code === configuration[i].code);
				if (action !== undefined) {
					Object.keys(configuration[i]).forEach((key) => {
						action[key] = configuration[i][key];
					});
					actionsList.push(action);
				}
			}

		return actionsList;
	}

	reset() {
		this.singleActionsList$.next(undefined);
	}
}
