/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { SelectValidators } from 'app/shared/components/dry/validator/select-validators';

@Directive({
	selector: '[selectValidator][ngModel]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: SelectValidatorDirective, multi: true }
	]
})
export class SelectValidatorDirective implements Validator, OnChanges {

	@Input('selectValidator') selectValues: Array<unknown>;
	@Input() useComplexObj = false;
	@Input() valueId = '';

	private validator: ValidatorFn;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes)
			if (this.useComplexObj)
				this.validator = SelectValidators.checkObject(this.selectValues, this.valueId);
			else
				this.validator = SelectValidators.checkPrimitiveValue(this.selectValues);
	}

	registerOnValidatorChange(fn: () => void): void { }

	validate(c: AbstractControl): ValidationErrors | null {
		return this.validator(c);
	}

}
