import { Injectable } from '@angular/core';
import { ActionBuilder } from '../../../../../../../src/app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from '../../../../../../../src/app/entities/ui-config/action/action-code.enum';

@Injectable({
	providedIn: 'root',
})
export class SingleActionsCatalog {
	public readonly remove = new ActionBuilder(SingleActionsCode.remove, ActionCode.REMOVE)
		.build();
}

export enum SingleActionsCode {
	remove = 'remove'
}
