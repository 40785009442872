import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'gaw-headerlayout',
  templateUrl: 'headerLayout.component.html',
  styleUrls: ['headerLayout.component.scss'],
})
export class HeaderLayoutComponent implements OnDestroy {
  flex = false;

  private destroy$ = new Subject<void>();

  constructor(private router: Router, private route: ActivatedRoute) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd || event instanceof Scroll),
        takeUntil(this.destroy$),
      )
      .subscribe(
        () =>
          (this.flex = new RegExp(/\/new(\?action=((data-entry(&id=(null|\d+)&idSerieDoc=\d+)?)|(upload)))?$/).test(
            this.router.routerState.snapshot.url,
          )),
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
