import { Injectable } from '@angular/core';
import { ActionsConfigRequest, ConfigurationsGroup } from 'app/entities/ui-config/action/actions-configuration';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SectionEnum } from 'app/entities/anomalies/section-enum';
import { ActionsConfigurationHttpService } from './actions-configuration-http.service';

@Injectable({
	providedIn: 'root'
})
export class ActionsConfigurationService {
	constructor(public actionsConfigurationHttpService: ActionsConfigurationHttpService) { }
	public getSingleActionsByDocSeries(
		licenseId: string,
		siaCode: string,
		boxId: string,
		sectionCode: SectionEnum,
		body: ActionsConfigRequest
	): Observable<ConfigurationsGroup[]> {
		return this.actionsConfigurationHttpService.getSingleActions(licenseId, siaCode, boxId, sectionCode, body).pipe(
			take(1),
			map((response) => response.configurationsGroup)
		);

	}
	public getMultipleActionsByDocSeries(
		licenseId: string,
		siaCode: string,
		sectionCode: string,
		body: ActionsConfigRequest[]
	): Observable<ConfigurationsGroup[]> {
		return this.actionsConfigurationHttpService.getMultipleActions(licenseId, siaCode, sectionCode, body).pipe(
			take(1),
			map((response) => response.configurationsGroup)
		);
	}

	public getMassiveActionsByDocSeries(licenseId: string, siaCode: string, sectionCode: string): Observable<ConfigurationsGroup[]> {
		return this.actionsConfigurationHttpService.getMassiveActions(licenseId, siaCode, sectionCode).pipe(
			take(1),
			map((response) => response.configurationsGroup)
		);
	}
}
