<div class="col-md-10">
  <h1>Cambio password</h1>
  <div class="change-password-container">
    <p>Ti ricordiamo alcune buone regole per la gestione delle password di accesso:</p>
    <ul>
      <li>Le password sono personali e non vanno condivise con nessuno, nemmeno con il personale di Credemtel</li>
      <li>
        Non scrivere le password e il loro nome utente su documenti che possono essere accessibili da chiunque (compresi
        fogli di carta chiusi in un cassetto)
      </li>
      <li>
        Ti consigliamo di inserire password più lunghe di n. 15 caratteri in quanto sono più complesse da carpire dai
        malintenzionati
      </li>
      <li>
        Non usare la stessa password per accedere a più portali web, in quanto se viene trafugata rischi che i
        malintenzionati possano accedere a tutti i portali in cui hai utilizzato la stessa password
      </li>
      <li>Credemtel non ti invierà mai alcuna richiesta di condivisione della password per email o per telefono</li>
      <li>
        Non inviare email in cui compaiono per esteso, sia il nome utente, sia la password corrispondente, a meno che
        l'email non sia criptata
      </li>
      <li>Fai attenzione alle email di <i>phishing</i></li>
      <li>
        Utilizza un password manager per conservare le tue password, ne esistono di gratuiti e sono molto validi (chiedi
        a chi segue l'infrastruttura informatica della tua azienda qualche esempio di password manager che potresti
        utilizzare)
      </li>
      <li>
        Puoi verificare la sicurezza della tua password utilizzando il seguente sito web (non riportare la password che
        intendi inserire, ma solamente una simile per avere un'idea della sua sicurezza):
        <a href="https://www.security.org/how-secure-is-my-password/"
          >https://www.security.org/how-secure-is-my-password/</a
        >
      </li>
    </ul>
    <button type="button" class="btn btn-primary" (click)="gotoChangePassword()">Vai al cambio password</button>
  </div>
</div>
