import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';

@Injectable({
	providedIn: 'root'
})
export class MultiActionsCatalog {

	public readonly signMultiple = new ActionBuilder(MultiActionCode.SignMultiple, ActionCode.SIGN)
		.setActionURL('/v2/documents/export/excel?clientId=GAWCONS')
		.build();

	public readonly cancelMultiple = new ActionBuilder(MultiActionCode.CancelMultiple, ActionCode.CANCEL)
		.setActionURL('')
		.build();

	public readonly confirmMultiple = new ActionBuilder(MultiActionCode.ConfirmMultiple, ActionCode.CONFIRM)
		.setActionURL('')
		.build();
}

export enum MultiActionCode {
	SignMultiple = 'signMultiple',
	CancelMultiple = 'cancelMultiple',
	ConfirmMultiple = 'confirmMultiple',
}
