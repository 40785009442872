<select class='form-control form-control-sm'
				[ngStyle]='style'
				[id]='id'
				[ngClass]="{
	   	'valid': control.value && control?.valid,
	   	'invalid': control?.invalid
	   }"
				[popover]='InvalidFormMessage'
				(focusin)='onFocusIn()'
				(focusout)='onFocusOut()'
				triggers=''
				[formControl]='control'>
	<option *ngIf='placeholder && !control?.value; else noPlaceholder'
					[value]='"" || null || undefined'
					[selected]=' value === control?.value'>{{placeholder}}</option>
	<ng-template #noPlaceholder>
		<option value=''></option>
	</ng-template>
	<ng-container *ngIf='tooltip; else noTooltip'>
		<ng-container *ngFor='let value of values'>
			<ng-container *ngIf='objectId && objectValue; else simpleObj'>
				<option [value]='value[objectId]'
								[selected]='value[objectId] === control?.value'
								title="{{value['labelWithValue']}}">
					{{value[objectValue]}}
				</option>
			</ng-container>
			<ng-template #simpleObj>
				<option [value]='value'
								[selected]=' value === control?.value'
								title="{{value['labelWithValue']}}">
					{{value}}
				</option>
			</ng-template>
		</ng-container>
	</ng-container>
	<ng-template #noTooltip>
		<ng-container *ngFor='let value of values'>
			<ng-container *ngIf='objectId && objectValue; else simpleObjWithoutTooltip'>
				<option [value]='value[objectId]'
								[selected]='value[objectId] === control?.value'>
					{{value[objectValue]}}
				</option>
			</ng-container>
			<ng-template #simpleObjWithoutTooltip>
				<option [value]='value'
								[selected]=' value === control?.value'>
					{{value}}
				</option>
			</ng-template>
		</ng-container>
	</ng-template>
</select>
<ng-template #InvalidFormMessage>
	<ul *ngIf='control.invalid' class="invalid-form-msg">
		<ng-container *ngFor='let error of (controlErrors$ | async)'>
			<li *ngIf="error">
				{{error}}
			</li>
		</ng-container>
	</ul>
</ng-template>
