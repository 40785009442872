import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { AppConfig } from 'app/entities/config/app-config';
import { CustomerRegistry } from 'app/entities/invoice-pa/customer-registry/customer-registry';
import { ListCf } from 'app/entities/invoice-pa/invoice-pa-autocomplete/list-cf';
import { PaOfficesList } from 'app/entities/invoice-pa/invoice-pa-autocomplete/pa-offices-list';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class CustomerRegistryHttpService {

	// endpoints
	private registry: string;
	private registryHost: string;
	private officePaInfo: string;
	private listOffices: string;
	private listCf: string;

	constructor(
		private http: HttpClient,
		public configService: ConfigService,
		private fullScreenSpinnerService: FullScreenSpinnerService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}

	httpHostInit(appConfig: AppConfig) {
		this.registryHost = appConfig.gedInvoice.http.host;
		this.registry = `${this.registryHost}/v1/GedInvoice/Customer/Registry`;
		this.officePaInfo = `${this.registryHost}/v1/GedInvoice/GetUfficiPAByCF/`;
		this.listOffices = `${this.registryHost}/v1/GedInvoice/GetUfficiPAByCodUni/`;
		this.listCf = `${this.registryHost}/v1/GedInvoice/GetCFListByCf/`;
	}

	public getRegistry(id: string, sia: string) {
		const url = this.registry;

		const httpOptions = {
			params: {
				id,
				sia,
			}
		};
		return this.http.get<CustomerRegistry>(url, httpOptions);
	}

	public getOfficePaByCf(cf: string): Observable<PaOfficesList[]> {
		const url = this.officePaInfo + cf + '/0/' + '250';
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap(headers => this.http.get<PaOfficesList[]>(url, {
				headers
			}))
		);
	}

	public getCfListByCf(cf: string): Observable<ListCf[]> {
		const url = this.listCf + cf + '/5';
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap(headers => this.http.get<ListCf[]>(url, {
				headers
			}))
		);
	}

	public getOfficePaByCodUni(officeCode: string): Observable<PaOfficesList[]> {
		const url = this.listOffices + officeCode + '/0/' + '5';
		return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
			switchMap(headers => this.http.get<PaOfficesList[]>(url, {
				headers
			}))
		);
	}

}
