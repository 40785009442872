/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { DateValidators } from 'app/shared/components/dry/validator/date-validators';

@Directive({
	selector: '[maxDate][ngModel]',
	providers: [
		{provide: NG_VALIDATORS, useExisting: MaxDateValidatorDirective, multi: true}
	]
})
export class MaxDateValidatorDirective implements Validator {

	private validator: ValidatorFn;

	@Input('maxDate') set maxValue(maxValue: string | Date | moment.Moment) {

		this.validator = DateValidators.maxDate(maxValue);
	}

	registerOnValidatorChange(fn: () => void): void {	}

	validate(c: AbstractControl): ValidationErrors | null {
		return this.validator(c);
	}

}
