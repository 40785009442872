import { Action, createReducer, on } from '@ngrx/store';
import { Note } from '../entities/note';
import { Sorting } from '../entities/sorting';
import * as DocumentNotesActions from './document-notes.actions';

export const documentNotesModuleFeatureKey = 'documentNotesModule';

export const documentNotesModuleReducers = {
	document_notes: documentNotesReducer
};

export interface DocumentNotesModulesState {
	document_notes: DocumentNotesState;
}

export interface DocumentNotesState {
	notes: Array<Note>;
	progSpool: number;
	progBusta: number;
	idSerieDoc: number;
	mode: 'new' | 'edit' | 'readonly';
	selectedNote: Note | undefined;
	sorting: Sorting;
}

const onSetDocumentId = (currentState: DocumentNotesState, { payload }) => ({
	...currentState,
	progSpool: payload.progSpool,
	progBusta: payload.progBusta,
	idSerieDoc: payload.idSerieDoc
});
const onFetchNotes = (currentState: DocumentNotesState, { payload }) => ({
	...currentState,
	notes: payload.notes
});
const onModeChange = (currentState: DocumentNotesState, { payload }) => ({
	...currentState,
	mode: payload.mode
});
const onNoteSelect = (currentState: DocumentNotesState, { payload }) => ({
	...currentState,
	selectedNote: payload.selectedNote
});
const restoreTextArea = (currentState: DocumentNotesState, { payload }) => ({
	...currentState,
	selectedNote: payload.selectedNote,
	mode: payload.mode
});
const onSortingChange = (currentState: DocumentNotesState, { payload }) => ({
	...currentState,
	sorting: payload.sorting
});

export const initialState: DocumentNotesState = {
	notes: [],
	progSpool: undefined,
	progBusta: undefined,
	idSerieDoc: undefined,
	mode: 'readonly',
	selectedNote: undefined,
	sorting: {
		type: 'DESC',
		field: 'Date'
	}
};

export const reducer = createReducer(
	initialState,
	on(DocumentNotesActions.setDocumentId, onSetDocumentId),
	on(DocumentNotesActions.setNotes, onFetchNotes),
	on(DocumentNotesActions.setMode, onModeChange),
	on(DocumentNotesActions.selectNote, onNoteSelect),
	on(DocumentNotesActions.restoreTextAreaValue, restoreTextArea),
	on(DocumentNotesActions.setSorting, onSortingChange)
);

export function documentNotesReducer(state: DocumentNotesState | undefined, action: Action) {
	return reducer(state, action);
}
