import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class MultiActionsCatalog {
}

export enum MultiActionCode {
}
