import { Injectable } from '@angular/core';

@Injectable()
export class Base64Service {

	private readonly base64 = require('hi-base64');

	public encode(stringToEncode) {
		return this.base64.encode(stringToEncode);
	}

	public decode(stringToDecode) {
		let stringDecoded: string;
		try {
			// decode utf-8
			stringDecoded = this.base64.decode(stringToDecode);
		} catch {
			// decode other
			stringDecoded = atob(stringToDecode);
		}
		return stringDecoded;
	}

	// non trasformo tutto il base64 in blob per questioni di performance ma in piccoli slice
	public base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++)
				byteNumbers[i] = slice.charCodeAt(i);

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}
}
