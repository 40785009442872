import { Component, Input } from '@angular/core';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';

@Component({
	selector: 'dry-application-error-placeholder',
	templateUrl: './application-error-placeholder.component.html',
	styleUrls: ['./application-error-placeholder.component.scss']
})
export class ApplicationErrorPlaceholderComponent {

	@Input() onRetry?: () => any;
	public faIcons = FaIcons;

	onRetryClick() {
		this.onRetry();
	}

}
