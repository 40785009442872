import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BlockUIModule } from '@ctel/block-ui';
import { ErrorPlaceholderModule, PlaceholderModule } from '@ctel/placeholders';
import { DisplayDocumentModule } from 'app/shared/components/display-document/display-document.module';
import { DryComponentsModule } from 'app/shared/components/dry/dry-components.module';
import { DirectivesModule } from 'app/shared/directives/directives.module';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UploadLottoZipModule } from 'app/modules/upload-lotto-zip/upload-lotto-zip.module';
import { AnomalyDetailComponent } from './anomaly-detail/anomaly-detail.component';
import { AnomalyDetailsMainComponent } from './anomaly-detail/anomaly-details-main/anomaly-details-main.component';
import { AnomalyDoublesByKeysComponent } from './anomaly-detail/anomaly-details-main/anomaly-doubles-by-keys/anomaly-doubles-by-keys.component';
import { AnomalyStatusHistoryComponent } from './anomaly-detail/anomaly-details-main/anomaly-status-history/anomaly-status-history.component';
import { StatusHistoryDetailsModalComponent } from './anomaly-detail/anomaly-details-main/anomaly-status-history/status-history-details-modal/status-history-details-modal.component';
import { ViewEditAnomalyComponent } from './anomaly-detail/anomaly-details-main/view-edit-anomaly/view-edit-anomaly.component';
import { DocumentsSearchModule } from './documents-search/documents-search.module';
import { GawpassDetailComponent } from './gawpass-detail/gawpass-detail.component';
import { GawpassDetailsMainComponent } from './gawpass-detail/gawpass-details-main/gawpass-details-main.component';
import { GawpassDocumentPreviewComponent } from './gawpass-detail/gawpass-details-main/gawpass-document-preview/gawpass-document-preview.component';
import { GawpassExpeditionComponent } from './gawpass-detail/gawpass-details-main/gawpass-expedition/gawpass-expedition.component';
import { GawpassNotesComponent } from './gawpass-detail/gawpass-details-main/gawpass-notes/gawpass-notes.component';
import { GawpassStatusHistoryComponent } from './gawpass-detail/gawpass-details-main/gawpass-status-history/gawpass-status-history.component';
import { AnomalyHistoryActionModalComponent } from './modals/anomaly-history-action-modal.component/anomaly-history-action-modal.component';
import { HistoryActionModalComponent } from './modals/history-action-modal.component/history-action-modal.component';
import { UploadDocumentModalComponent } from './upload-document-modal/upload-document-modal.component';

@NgModule({
	declarations: [
		HistoryActionModalComponent,
		GawpassDetailComponent,
		GawpassDetailsMainComponent,
		GawpassStatusHistoryComponent,
		GawpassDocumentPreviewComponent,
		GawpassExpeditionComponent,
		GawpassNotesComponent,
		AnomalyDetailComponent,
		AnomalyDetailsMainComponent,
		AnomalyStatusHistoryComponent,
		ViewEditAnomalyComponent,
		AnomalyDoublesByKeysComponent,
		StatusHistoryDetailsModalComponent,
		AnomalyHistoryActionModalComponent,
		UploadDocumentModalComponent,
	],
	imports: [
		CommonModule,
		DirectivesModule,
		DryComponentsModule,
		DisplayDocumentModule,
		PipesModule,
		ErrorPlaceholderModule,
		PlaceholderModule,
		TabsModule,
		DocumentsSearchModule,
		FormsModule,
		RouterModule,
		PopoverModule,
		TooltipModule,
		UploadLottoZipModule,
		BlockUIModule,
	],
	exports: [
		GawpassDetailComponent,
		GawpassDetailsMainComponent,
		GawpassStatusHistoryComponent,
		GawpassDocumentPreviewComponent,
		AnomalyDetailComponent,
		UploadDocumentModalComponent,
	],
})
export class GawpassCommonsModule { }
