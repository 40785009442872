import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PlaceholderModule } from 'app/core/common/placeholder/placeholder.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ButtonComponent } from './button/button.component';
import { CodeBlockComponent } from './code-block/code-block.component';
import { CodeMarkupBlockComponent } from './code-markup-block/code-markup-block.component';
import { ValidatorModule } from './validator/validator.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		PlaceholderModule,
		TooltipModule,
		ValidatorModule
	],
	declarations: [
		CodeBlockComponent,
		CodeMarkupBlockComponent,
		ButtonComponent

	],
	exports: [
		CodeBlockComponent,
		CodeMarkupBlockComponent,
		ButtonComponent
	]
})
export class DryComponentsModule {
}
