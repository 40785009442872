import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BridgeGedconsHttpService } from 'app/core/business/bridge-dnn/bridge-gedcons-http.service';
import { BridgeGedconsService } from 'app/core/business/bridge-dnn/bridge-gedcons.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	]
})
export class BridgeGedconsModule {
	static forRoot(): ModuleWithProviders<BridgeGedconsModule> {
		return {
			ngModule: BridgeGedconsModule,
			providers: [
				BridgeGedconsService,
				BridgeGedconsHttpService
			]
		};
	}
}
