import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeExtraEn from '@angular/common/locales/extra/en';
import localeExtraIt from '@angular/common/locales/extra/it';
import localeIt from '@angular/common/locales/it-CH';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { BlockUIModule } from '@ctel/block-ui';
import { CustomStateSerializer, routerFeatureKey } from '@ctel/search-filter-store';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GawReuseStrategy } from 'app/core/common/cache/cache-router-reuse.strategy';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppErrorBuilder, ErrorTypes } from 'app/core/common/error';
import { appReducers } from 'app/state/app.reducer';
import { environment } from 'environments/environment';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreBusinessModule } from './core/business/core-business.module';
import { FatturaElettronicaParserService } from './core/business/invoice-pa/invoice-pa-parser/fattura-elettronica-parser.service';
import { DryCommonModule } from './core/common/dry-common.module';
import { PublicPagesModule } from './modules/public/public-pages.module';
import { ComponentsModule } from './shared/components/components.module';
import { LayoutsModule } from './shared/layouts/layouts.module';
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { AuthorizationGuard } from './core/common/guards/authorization.guard';
import { PermissionGuard } from './core/common/guards/permission.guard';
import { HomeBusinessModule } from './modules/homepage/core/home-business.module';
import { AppAuthModule, AuthConfigService } from '@ctel/auth';
import { GawCommonsModule } from '@ctel/gaw-commons';
import { Gaw30CommonsModule } from '@ctel/gaw30-commons';
import { GawbiCommonsModule } from '@ctel/gawbi-commons';
import { GawBinCommonsModule } from '@ctel/gawbin-commons';
import { GawconsCommonsModule } from '@ctel/gawcons-commons';
import { GawediCommonsModule } from '@ctel/gawedi-commons';
import { GawlottiCommonsModule } from '@ctel/gawlotti-commons';
import { GawmailCommonsModule } from '@ctel/gawmail-commons';
import { GawpassCommonsModule } from '@ctel/gawpass-commons';
import { GawpecCommonsModule } from '@ctel/gawpec-commons';
import { GawpostCommonsModule } from '@ctel/gawpost-commons';
import { HubfeCommonsModule } from '@ctel/hubfe-commons';
import { ContentLoaderComponent } from '@ctel/placeholders';
import { SnackbarModule } from '@ctel/snackbar';
import { AbstractSecurityStorage, AuthModule, DefaultLocalStorageService, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SidebarModule } from 'ngx-angular-sidebar';
import { catchError, firstValueFrom, map, take } from 'rxjs';
import { NotificationAsyncActionsGuard } from './core/common/guards/notification-async-action.guard';
import { LoggerHttpService } from './core/common/user-logger/logger-http.service';
import { LoggerHttpInterceptor } from './core/common/user-logger/logger-interceptor.service';

registerLocaleData(localeIt, 'it', localeExtraIt);
registerLocaleData(localeEn, 'en', localeExtraEn);

function loggerInit(configService: ConfigService, logger: NGXLogger) {
	return (): Promise<unknown> => firstValueFrom(configService.whenDryConfig().pipe(
		take(1),
		map(config => config.logger),
		map(loggerConfig => logger.updateConfig(loggerConfig)),
		catchError((err: unknown) => {
			throw new AppErrorBuilder(ErrorTypes.CONFIG_LOADING_ERROR)
				.cause(err as Error)
				.description('Impossibile configurare il logger.')
				.build();
		})
	));
}

const loadOidcConfig = (configService: ConfigService) => new StsConfigHttpLoader(configService.whenDryConfig().pipe(
	map(config => config.auth.oidc.config),
));
/**
 * cleanStore è utilizzata per rimuovere le variabili nel localStorage
 * che erano necessarie per il corretto funzionamento dell'autenticazione OAuth OIDC
 * prima dell'aggiornamento ad Angular 16.
 * Questa pulizia garantisce che non ci siano residui di dati obsoleti o non più utilizzati,
 * evitando possibili conflitti o comportamenti imprevisti nelle nuove versioni dell'applicazione.
 */
const cleanStore = () => () => {
  for (let index = 0; index < localStorage.length; index++) {
    const name = localStorage.key(index);
	if (name === 'gaw_access_token_expires_at'
			|| name === 'gaw_authNonce'
			|| name === 'gaw_authStateControl'
			|| name === 'gaw_authWellKnownEndPoints'
			|| name === 'gaw_authnResult'
			|| name === 'gaw_authzData'
			|| name === 'gaw_session_state'
			|| name === 'gaw_storageSilentRenewRunning'
			|| name === 'gaw_userData')
      localStorage.removeItem(name);
  }
};

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		// Angular
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,
		// Plugins
		BlockUIModule.forRoot(),
		ModalModule.forRoot(),
		BsDropdownModule.forRoot(),
		CollapseModule.forRoot(),
		TooltipModule.forRoot(),
		PopoverModule.forRoot(),
		BsDatepickerModule.forRoot(),
		SidebarModule.forRoot(),
		TabsModule.forRoot(),
		SnackbarModule.forRoot(),
		PaginationModule.forRoot(),
		ContentLoaderComponent,
		StoreModule.forRoot(appReducers, {
			runtimeChecks: {
				strictStateImmutability: true,
				strictActionImmutability: true,
				strictStateSerializability: true,
				// Per abilitare questo, è necessario rendere tutte le azioni serializzabili.
				// In particolare AppError, nel caso di dei vari handleError negli Effects.
				// strictActionSerializability: true
			}
		}),
		EffectsModule.forRoot([]),

		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production, // Restrict extension to log-only mode,
			name: 'GAW Store DevTools'
		}),

		// Markdown renderer e impostazioni application-wide
		MarkdownModule.forRoot({
			loader: HttpClient,
			markedOptions: {
				provide: MarkedOptions,
				useValue: {
					gfm: true,
					breaks: false,
					pedantic: false,
					smartLists: true,
					smartypants: false,
				},
			}
		}),

		/* Logger
		*  !Attenzione, questa la configurazione di default, la vera configurazione per ambiente viene caricata dal file dry-config
		*  nel costruttore di app-component.
		*  Il colore cyan viene impostato per identificare i messaggi scritti prima dell'inizializzazione della configurazione,
		*  nei messaggi scritti dopo l'inizializzazione, il colore cyan non verra' mai utilizzato.
		*/
		LoggerModule.forRoot({
			level: NgxLoggerLevel.DEBUG,
			colorScheme: ['cyan', 'cyan', 'cyan', 'cyan', 'cyan', 'cyan', 'cyan']
		}),

		// Auth
		AuthModule.forRoot({
			loader: {
				provide: StsConfigLoader,
				useFactory: loadOidcConfig,
				deps: [ConfigService, APP_INITIALIZER]
			}
		}),

		// CoreModule,
		// Routing applicazione
		AppRoutingModule,
		StoreRouterConnectingModule.forRoot({
			stateKey: routerFeatureKey,
			routerState: RouterState.Minimal, // Deve essere minimal per la strictActionSerializability.
			serializer: CustomStateSerializer
		}),
		// Core - Business - Dry
		// RandomNumberModule.forRoot(),
		// Core - Common - Dry
		DryCommonModule,

		// Shared
		ComponentsModule,
		LayoutsModule,
		AngularSvgIconModule.forRoot(),

		// Pages - Public
		// LandingModule,
		PublicPagesModule,
		// PublicPagesModule,
		// core services
		CoreBusinessModule,

		HomeBusinessModule,

		// Crea fattura
		// CreateInvoiceModule,
		// EditInvoiceModule

		AppAuthModule.forRoot({
			provide: AuthConfigService,
			useExisting: ConfigService
		}),
		GawCommonsModule,
		Gaw30CommonsModule,
		HubfeCommonsModule,
		GawpecCommonsModule,
		GawpostCommonsModule,
		GawediCommonsModule,
		GawmailCommonsModule,
		GawlottiCommonsModule,
		GawpassCommonsModule,
		GawbiCommonsModule,
		GawconsCommonsModule,
		GawBinCommonsModule,
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'it' },
		FatturaElettronicaParserService,
		{
			provide: RouteReuseStrategy,
			useClass: GawReuseStrategy
		},
		{
			provide: APP_INITIALIZER,
			useFactory: loggerInit,
			multi: true,
			deps: [ConfigService, NGXLogger]
		},
		{
			provide: AbstractSecurityStorage,
			useClass: DefaultLocalStorageService
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoggerHttpInterceptor,
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: cleanStore,
			multi: true
		},
		AuthorizationGuard,
		PermissionGuard,
		NotificationAsyncActionsGuard,
		LoggerHttpService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
