import { Injectable, OnDestroy } from '@angular/core';
import { StatusNoteHistory } from '@ctel/gaw-commons';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { StatusHistoryHttpService } from './status-history-http.service';

@Injectable({
	providedIn: 'root'
})
export class StatusHistoryService implements OnDestroy {
	private statusHistory$ = new ReplaySubject<StatusNoteHistory[]>(1);
	private statusHistoryError$ = new BehaviorSubject<boolean>(false);
	private destroy$ = new ReplaySubject<void>(1);

	constructor(private historyHttpService: StatusHistoryHttpService) { }

	getStatusHistory(progSpool: string, tipoCanale: string) {
		this.historyHttpService.whenStatusHistory(progSpool, tipoCanale)
			.pipe(
				catchError(() => {
					this.sendStatusHistoryError(true);
					return EMPTY;
				}),
				tap((statusHistory) => {
					this.sendStatusHistoryError(false);
					this.sendStatusHistory(statusHistory);
				}),
				takeUntil(this.destroy$)
			).subscribe(
				value => this.sendStatusHistory(value)
			);
	}

	whenStatusHistory(): Observable<StatusNoteHistory[]> {
		return this.statusHistory$.asObservable();
	}

	sendStatusHistory(statusHistory: StatusNoteHistory[]) {
		this.statusHistory$.next(statusHistory);
	}

	sendStatusHistoryError(value: boolean) {
		this.statusHistoryError$.next(value);
	}

	whenStatusHistoryError(): Observable<boolean> {
		return this.statusHistoryError$.asObservable();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
