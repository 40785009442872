import { Field } from 'app/shared/components/dry/field/field';
import { FieldType } from 'app/shared/components/dry/field/field-type';

export class TextField extends Field {

	type = FieldType.TEXT;
	regex = '';

	constructor(id: string, name: string) {
		super(id, name);
	}

	setRegex(regex: string): TextField {
		this.regex = regex;
		return this;
	}

}
