import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable, ReplaySubject, of, takeUntil } from 'rxjs';
import { Responsabili } from '../entities/responsabili';
import mockActions from './mock-actions.json';

@Injectable({
	providedIn: 'root'
})
export class ResponsabiliHttpService implements OnDestroy {
	private archivioFiscaleHost: string;
	private responsabiliHost: string;
	private listaResponsabiliUrl: string;

	private destroy$ = new ReplaySubject<void>(1);

	constructor(private http: HttpClient,
		private configService: ConfigService) {
		this.configService.whenAppConfig().pipe(takeUntil(this.destroy$))
			.subscribe((appconfig: AppConfig) => this.httpHostInit(appconfig));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	httpHostInit(appconfig: AppConfig) {
		this.archivioFiscaleHost = appconfig.gawConsArchivioFiscaleRead.http.host;
		this.responsabiliHost = appconfig.webConsOrchestrator.http.host;
		this.listaResponsabiliUrl = `${this.responsabiliHost}/v1/Responsabili/lista/{licenseId}/{siaCode}`;
	}

	whenResponsabili(licenseId: string, siaCode: string): Observable<Responsabili[]> {
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		const url = this.listaResponsabiliUrl
			.replace('{licenseId}', licenseId)
			.replace('{siaCode}', siaCode);
		return this.http.get<Responsabili[]>(url, options);
	}

	getActions() {
		return of(mockActions);
	}
}
