import { Injectable } from '@angular/core';
import { FrLottiHttpService } from './fr-lotti-http.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
	providedIn: 'root'
})
export class FrLottiService {

	constructor(private frLottiHttp: FrLottiHttpService) {
	}

	cancelMassive(body: string): Observable<void> {
		return this.frLottiHttp.cancelMassive(body);
	}

	checkMassive(body: string): Observable<number> {
		return this.frLottiHttp.checkMassive(body);
	}

	reasonConfirm(body: string): Observable<void> {
		return this.frLottiHttp.reasonConfirm(body);
	}

	sign(body: string): Observable<void> {
		return this.frLottiHttp.sign(body);

	}
}
