import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';
import { Field } from './field';
import { Is } from './is.enum';

export class FieldBuilder {

	readonly field: Field;

	constructor(field: Field) {
		this.field = field;
	}

	build(): Field {
		return this.field;
	}

	name(name: string): FieldBuilder {
		this.field.name = name;
		return this;
	}

	id(id: string): FieldBuilder {
		this.field.id = id;
		return this;
	}

	visible(visible: boolean): FieldBuilder {
		this.field.visible = visible;
		return this;
	}

	filterable(filterable: boolean): FieldBuilder {
		this.field.filterable = filterable;
		return this;
	}

	sortable(sortable: boolean): FieldBuilder {
		this.field.sortable = sortable;
		return this;
	}

	notEditable(): FieldBuilder {
		return this.editable(Is.NOT_EDITABLE);
	}

	editableOnInsertOnly(): FieldBuilder {
		return this.editable(Is.EDITABLE_ON_INSERT_ONLY);
	}

	required(required: boolean): FieldBuilder {
		this.field.required = required;
		return this;
	}

	optional() {
		this.field.required = false;
		return this;
	}

	renderer(component: string): FieldBuilder {
		this.field.renderer = component;
		return this;
	}

	withValidator(validator: ValidatorFn): FieldBuilder {
		this.field.validators.push(validator);
		return this;
	}

	validators(validators: ValidatorFn[]): FieldBuilder {
		this.field.validators = validators;
		return this;
	}

	asyncValidators(asyncValidators: AsyncValidatorFn[]): FieldBuilder {
		this.field.asyncValidators = asyncValidators;
		return this;
	}

	withAsyncValidator(validator: AsyncValidatorFn) {
		this.field.asyncValidators.push(validator);
		return this;
	}

	helper(help: SafeHtml): FieldBuilder {
		this.field.helper(help);
		return this;
	}

	private editable(editable: Is): FieldBuilder {
		this.field.editable = editable;
		return this;
	}
}
