import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { AllDocumentsCallback } from './callbacks/all-documents-callback';

@Injectable({
	providedIn: 'root'
})
export class MassiveActionsCatalog {

	public readonly restoreFromTrash = new ActionBuilder(MassiveActionCode.restoreFromTrash, ActionCode.RESTORE_FROM_TRASH)
		.setActionURL('/Trash/command/RestoreFromTrash/massive?clientid=gawbin')
		.setCallback((action: ActionObject) => this.allDocumentsCallback.restoreFromTrashMassive(action))
		.build();

	public readonly deleteFromTrash = new ActionBuilder(MassiveActionCode.deleteFromTrash, ActionCode.DELETE_FROM_TRASH)
		.setActionURL('/Trash/command/DeleteFromTrash/massive?clientid=gawbin')
		.setCallback((action: ActionObject) => this.allDocumentsCallback.deleteFromTrashMassive(action))
		.build();

	constructor(public allDocumentsCallback: AllDocumentsCallback) { }

}

export enum MassiveActionCode {
	restoreFromTrash = 'restoreFromTrash',
	deleteFromTrash = 'deleteFromTrash'
}