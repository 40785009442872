import { Component } from '@angular/core';

@Component({
	selector: 'app-table-row-placeholder',
	templateUrl: './table-row-placeholder.component.html',
	styleUrls: ['./table-row-placeholder.component.scss']
})
export class TableRowPlaceholderComponent {

}
