import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'app/core/common/config/config.service';
import { Pages, SectionsEndpoint } from './pages';
import { Observable } from 'rxjs';
import { AppConfig } from 'app/entities/config/app-config';

@Injectable()
export class SearchPagesHttpService {

	private gawUserPortalServiceHost: string;
	private sectionsApi: string;

	constructor(
		private httpClient: HttpClient,
		public configService: ConfigService
	) {
		this.configService.whenAppConfig()
			.subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
	}
	public getSections(idlicenza, codsia, AreaCode): Observable<SectionsEndpoint[]> {
		const url = this.sectionsApi.replace('{IdLicenza}/{CodSia}/{AreaCode}', `${idlicenza}/${codsia}/${AreaCode}`);
		return this.httpClient.get<SectionsEndpoint[]>(url);
	}

	public getStaticPages(staticPageUrl: string): Observable<Pages[]> {
		return this.httpClient.get<Pages[]>(staticPageUrl);
	}
	private httpHostInit(appConfig: AppConfig) {
		this.gawUserPortalServiceHost = appConfig.gawUserPortalService.http.host;
		this.sectionsApi = `${this.gawUserPortalServiceHost}/api/v1/UserPortalServices/sections/{IdLicenza}/{CodSia}/{AreaCode}`;
	}

}
