<div class="access-denied-main">
	<div class="col-md-3"></div>

	<div class="col-md-6 card-container">
		<div class="card">
			<div class="card-header">
				<h4 class="title">Attenzione!</h4>
			</div>
			<div class="card-body">
				<h4 class="message">
					Il tuo utente non è abilitato all'utilizzo di GAW
				</h4>
				<div class="logout">
					<button type="submit" class="btn btn-danger logout-button" (click)="logout()">
						<i [ngClass]="faIcons.FAS_POWER_OFF"></i>
						LOGOUT
					</button>
				</div>
			</div>

		</div>
	</div>

	<div class="col-md-3"></div>

</div>

