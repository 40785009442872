import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventType, Router } from '@angular/router';
import { Navigation } from 'app/entities/navigation/navigation';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

/**
 * Componente che rappresenta un navigation item della sidebar
 */
@Component({
	selector: 'gaw-nav-item',
	templateUrl: 'nav-item.component.html',
	styleUrls: ['nav-item.component.scss']
})

export class NavItemComponent implements OnInit, OnDestroy {

	@Input() navigationItem: Navigation;
	isActive = false;
	private destroy$ = new Subject<void>();

	constructor(
		private router: Router,
		private locationUrl: Location
	) {
		router.events
			.pipe(
				filter(e => e.type === EventType.NavigationEnd),
				takeUntil(this.destroy$)
			)
			.subscribe(() =>
				setTimeout(
					() => {
						this.isActive = this.getUrl(this.navigationItem.url) === this.getUrl(locationUrl.path()) ||
							this.getUrl(this.navigationItem.url) === this.getUrl(router.url);

						if (this.isActive && this.getUrl(this.navigationItem.url) === 'gawfe')
							switch (this.navigationItem.description) {
								case 'GAWFE':
									this.isActive = locationUrl.path().split('/')[2].split('?')[0] === 'home' ||
										router.url.split('/')[2].split('?')[0] === 'home';
									break;
								case 'REGIME MINIMI':
									this.isActive = locationUrl.path().split('/').includes('regimeminimi') ||
										router.url.split('/').includes('regimeminimi');
									break;
							}
					},
					50
				)
			);
	}

	ngOnInit(): void {
		this.isActive = this.getUrl(this.navigationItem.url) === this.getUrl(this.locationUrl.path()) ||
			this.getUrl(this.navigationItem.url) === this.getUrl(this.router.url);

		if (this.isActive && this.getUrl(this.navigationItem.url) === 'gawfe')
			switch (this.navigationItem.description) {
				case 'GAWFE':
					this.isActive = this.locationUrl.path().split('/')[2].split('?')[0] === 'home' ||
						this.router.url.split('/')[2].split('?')[0] === 'home';
					break;
				case 'REGIME MINIMI':
					this.isActive = this.locationUrl.path().split('/').includes('regimeminimi') ||
						this.router.url.split('/').includes('regimeminimi');
					break;
			}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	getUrl(locationUrl: string) {
		if (locationUrl)
			return locationUrl.split('/')[1].split('?')[0];

		return '';
	}

	onMenuItemClick(navigation: Navigation) {
		if (navigation.isExternalLink) {
			const win = window.open(navigation.url, '_blank');
			win.focus();
		} else
			this.router.navigate([navigation.url]);

	}

}
