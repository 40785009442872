<div class='modal-body'>
	<div class='row'>
		<div class='col-12 message-container' *ngIf='data.validationMessage'>
			<span class='text-danger message-box'>{{data.validationMessage}}</span>
		</div>
		<div class='col-12 margin-bottom'>
			<label class='form-label' for='description'><strong>Descrizione per Pacchetto di Distribuzione<span
				class='text-danger'>*</span> <span *ngIf='data?.pddModel.docCount'> ({{data.pddModel.docCount}}
				documenti selezionati)</span> </strong></label>
			<textarea id='description' class='form-control' rows='3' [(ngModel)]='data.pddModel.descrizione'></textarea>
		</div>
		<div class='col-12'>
			<label class='form-label' for='description'><strong>Selezionare i documenti da includere nel
				PDD:</strong></label>
			<div class='row'>
				<div class='col-md-6'>
					<div class='checkbox'>
						<label for='attestato'>
							<input type='checkbox' value='' id='attestato' [(ngModel)]='data.pddModel.flagAttestato'>
							Attestato di conservazione
						</label>
					</div>
					<div class='checkbox'>
						<label for='indice'>
							<input type='checkbox' value='' id='indice' [(ngModel)]='data.pddModel.flagIPDA'>
							Indice pacchetto di archiviazione
						</label>
					</div>
					<div class='checkbox'>
						<label for='rapporto'>
							<input type='checkbox' value='' id='rapporto' [(ngModel)]='data.pddModel.flagRDV'>
							Rapporto di versamento
						</label>
					</div>
				</div>
				<div class='col-md-6' *ngIf='data?.pddModel.previous2014'>
					<div class='checkbox'>
						<label for='super'>
							<input type='checkbox' value='' id='super' [(ngModel)]='data.pddModel.flagSuperimpronte'>
							Super impronta Agenzia delle Entrate
						</label>
					</div>
					<div class='checkbox'>
						<label for='comunicazioni'>
							<input type='checkbox' value='' id='comunicazioni' [(ngModel)]='data.pddModel.flagComunicazioniAde'>
							Comunicazioni Agenzia delle Entrate
						</label>
					</div>
					<div class='checkbox'>
						<label for='ricevute'>
							<input type='checkbox' value='' id='ricevute' [(ngModel)]='data.pddModel.flagRicevuteAde'>
							Ricevute Agenzia delle Entrate
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class='modal-footer'>
		<button class='btn btn-success'
				id='action-confirm-button'
				[disabled]='!data.pddModel.descrizione'
				(click)='onSubmit()'
		><strong>Conferma</strong>
		</button>
		<button type='button' class='btn btn-danger' (click)='modal.hide()'>Annulla</button>
	</div>
