<div class="modal-body">
  <form [formGroup]="formGroup" class="row">
    <div class="col-md-4">
      <label class="form-label" for="username">
        <strong>Username <span class="text-danger">*</span></strong>
      </label>
      <app-text-form-control id="username" name="username" formControlName="username"></app-text-form-control>
    </div>
    <div class="col-md-4">
      <label class="form-label" for="password">
        <strong>Password <span class="text-danger">*</span></strong>
      </label>
      <app-text-form-control
        type="password"
        id="password"
        name="password"
        formControlName="password"
      ></app-text-form-control>
    </div>
    <div class="col-md-4">
      <label class="form-label" for="otp"><strong>OTP</strong></label>
      <app-text-form-control id="otp" name="otp" formControlName="otp"></app-text-form-control>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn gaw-btn btn-primary" (click)="onSign()" [disabled]="formGroup.invalid">
    Conferma
  </button>
</div>
