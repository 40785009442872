import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { ErrorHandlerService } from './error-handler/error-handler.service';
import { ErrorHttpInterceptor } from './error-http-interceptor.service';
import { ErrorService } from './error.service';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: []
})
export class ErrorModule {

	static forRoot(): ModuleWithProviders<ErrorModule> {
		return {
			ngModule: ErrorModule,
			providers: [
				ErrorService,
				{
					provide: HTTP_INTERCEPTORS,
					useClass: ErrorHttpInterceptor,
					multi: true
				},
				{
					// Global Error Handler
					provide: ErrorHandler,
					useClass: ErrorHandlerService
				}
			]
		};
	}
}
