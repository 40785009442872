import { Injectable } from '@angular/core';
import {
	AbstractDocumentEffects,
	DocumentActions,
	DocumentState,
	FilterActions,
	getFiltersStateWithUserValues,
	getRouterInfo,
} from '@ctel/search-filter-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DocumentsResponse } from '../../../entities/documents/documents-response';
import { ActionsConfigurationService } from '../../../ui-configuration/actions-configuration/actions-configuration.service';
import { ActionsService } from '../../actions/actions.service';
import { DocumentsHttpService } from '../../documents-http.service';
import { DocumentsService } from '../../documents/documents.service';

@Injectable({
	providedIn: 'root',
})
export class DocumentEffects extends AbstractDocumentEffects {
	/**
	 * Effetto che gestisce il side effect di aver ricevuto i nuovi documenti,
	 * e per i quali è necessario eventualmente raffinare i filtri.
	 */
	onDocumentsFetched$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DocumentActions.documentsFetched),
			withLatestFrom(this.store.pipe(select(getRouterInfo))),
			filter(([action,]) => !action.payload.chunked),
			filter(([, routerInfo]) => routerInfo.state.url.indexOf('gawfe/') !== -1),
			map(([{ payload }]) => FilterActions.refineFiltersRequested({ filters: payload.filters }))
		)
	);

	/**
	 * Effetto che gestisce il side effect di aver richiesto nuovi documenti.
	 */
	onFetchDocumentsRequired$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DocumentActions.fetchDocuments),
			mergeMap((payload) =>
				of(payload).pipe(
					withLatestFrom(
						this.store.pipe(
							select(
								getFiltersStateWithUserValues({
									fromFavourites: payload.payload.fromFavourites,
									technicalFilters: [
										MetadataEnum.DATA_FATTURA,
										MetadataEnum.DATA_INSERIMENTO,
										MetadataEnum.DATA_RICEZIONE,
									],
								})
							)
						),
					)
				)
			),
			filter(([, filterState]) => !filterState.requested),
			withLatestFrom(this.store.pipe(select(getRouterInfo))),
			filter(([[,], routerInfo]) => routerInfo.state.url.indexOf('gawfe/') !== -1),
			switchMap(([[, filterState],]) => {
				const chunked = filterState.paging && filterState.paging.offset > 0;
				this.documentsService.setLoadingDocs(true);
				return this.documentsHttpService.whenAllDocuments(JSON.stringify(filterState)).pipe(
					tap(() => {
						this.documentsService.setErrorLoadingDocs(false);
						this.documentsService.setLoadingDocs(false);
						this.documentsService.setLoadingDocsOnSectionChange(false);
						this.documentsService.setLoadingDocsAfterFilterApplication(false);
						this.documentsService.setLoadingDocsOnPaging(false);
					}),
					map((documentsResponse: DocumentsResponse) =>
						DocumentActions.documentsFetched(
							documentsResponse.docs,
							documentsResponse.totalDocs,
							documentsResponse.filters,
							documentsResponse.metrics,
							chunked
						)
					),
					catchError((error: unknown) => {
						this.documentsService.setLoadingDocs(false);
						this.documentsService.setLoadingDocsOnSectionChange(false);
						this.documentsService.setLoadingDocsAfterFilterApplication(false);
						this.documentsService.setLoadingDocsOnPaging(false);
						this.documentsService.setErrorLoadingDocs(true);
						return this.handleError(error);
					})
				);
			})
		)
	);

	/**
	 * Effetto che gestisce il side effect di aver richiesto le azioni massive su una data serie documentale.
	 */
	onFetchDocumentsActionsRequired$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DocumentActions.fetchDocumentsActions),
			withLatestFrom(this.store.pipe(select(getRouterInfo))),
			filter(([, routerInfo]) => routerInfo.state.url.indexOf('gawfe/') !== -1),
			switchMap(([, routerInfo]) => {
				this.actionsService.sendSingleActionsList([]);
				this.actionsService.sendMultiActionsList([]);
				return this.actionsConfigurationService.getMassiveActionsByCard(
						routerInfo.state.params['license'],
						routerInfo.state.params['siacode'],
						routerInfo.state.params['classification']
					)
					.pipe(
						map((config) => DocumentActions.documentActionsFetched(this.actionsService.mergeActionConfig(config, 'massive'))),
						catchError((error: unknown) =>
							// 						// Todo perchè accade questo errore? Karandip Singh - 02/03/2020
							// 						// this.documentsService.setLoadingDocs(false);
							// 						// this.documentsService.setErrorLoadingDocs(true);
							[DocumentActions.documentActionsFetched([]), DocumentActions.documentsError(error as { error: unknown })]
						)
					);
			})
		)
	);

	constructor(
		protected actions$: Actions,
		protected store: Store<DocumentState>,
		private documentsHttpService: DocumentsHttpService,
		private documentsService: DocumentsService,
		private actionsService: ActionsService,
		private actionsConfigurationService: ActionsConfigurationService
	) {
		super(actions$, store);
	}
}
